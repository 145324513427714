import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";

import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import "../ManageNewRegistration/layout.css";
import {
  Grid,
  Container,
  Typography,
  Button,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardActionArea,
  Chip,
  Avatar,
  AppBar,
  Dialog,
  Slide,
  IconButton,
  Toolbar,
  Paper,
} from "@mui/material";
import XLSX from "xlsx";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";


const options = {
  filterType: "multiselect",
  // filterOptions: {fullWidth: true},
  selectableRows: "none",
  setRowProps: (row) => {
    if (row[0] === "New") {
      return {
        style: { background: "snow" },
      };
    }
  },
  draggableColumns: { enabled: true },
  pagination: true,
  // resizableColumns: true,
  // responsive: "standard",
  fixedHeader: false,
};

export class EmployeeStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      vertical: "top",
      horizontal: "center",
      alertSnackbar: false,
      unique_cities: [],
      locationID: "",
      uniqueDateRanges: [],
      dateID: "",
      startDate: "",
      endDate: "",
      barCardDisplay: "none",
      statsDisplay1: "",
      statsDisplay2: "none",
      unique_project: [],
      projectName: [],
      userid: "",
      rowData: [],
      reportID: 1,
      StatsCategoryDropdown: [],
      rowDataStats: [],
      statsOverAll: [],
      statsOther: [],
      generatePalyslipModal: false,
      columns: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowData[dataIndex].EmployeeUrl}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowData[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "AttnDate",
          label: "Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "timing",
          label: "Timing",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "WorkShift",
          label: "Work Shift",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "AttnStatus",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusReal = this.state.rowData[dataIndex].AttnStatus;
              if (statusReal == 1) {
                return (
                  <div>
                    <Chip
                      color="success"
                      style={{
                        backgroundColor: "#2EB85C",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Present"
                    />
                  </div>
                );
              } else if (statusReal == 2) {
                return (
                  <div>
                    <Chip
                      color="warning"
                      style={{
                        backgroundColor: "#3399fe",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Half Day"
                    />
                  </div>
                );
              } else if (statusReal == 3) {
                return (
                  <div>
                    <Chip
                      color="secondary"
                      style={{
                        backgroundColor: "f9b114",
                        color: "white",
                        backgroundColor: "#a8af1d",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Sick Leave"
                    />
                  </div>
                );
              } else if (statusReal == 9) {
                return (
                  <div>
                    <Chip
                      color="info"
                      style={{
                        backgroundColor: "#4e5d72",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Yet To Get Attendance"
                    />
                  </div>
                );
              } else if (statusReal == 0) {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{
                        backgroundColor: "#e55353",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Absent"
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{ borderRadius: "4px", height: "25px" }}
                      label="Absent"
                    />
                  </div>
                );
              }
            },
          },
        },

        {
          name: "projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const pro = this.state.rowData[dataIndex];
              return (
                <div style={{ textAlign: "center" }}>{pro.ProjectCnt}</div>
              );
            },
          },
        },
      ],
      series: null,
      options: {
        title: {
          text: "Attendance View",
        },
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          formatter: function (val, opt) {
            console.log(opt);
            return Math.trunc(val) + "%";
            // return opt.w.config.series[opt.seriesIndex];
          },
          offsetX: 0,
        },
        colors: ["#2EB85C", "#e55353", "#3399fe"],
        labels: ["Present", "Absent", "Half Day"],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series2: null,
      options2: {
        title: {
          text: "Sick Leaves",
        },
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          formatter: function (val, opt) {
            // console.log(opt);
            return Math.trunc(val) + "%";
            // return opt.w.config.series[opt.seriesIndex];
          },
          offsetX: 0,
        },
        colors: ["#f9b114", "#4e5d72"],
        labels: ["Sick Leave", "Yet To Get Attendance"],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartData: null,

      options3: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Empolyees Attendance",
        },
        xaxis: {
          type: "datetime",
          categories: [
            "01-01-2011 GMT",
            "01/02/2011 GMT",
            "01/03/2011 GMT",
            "01/04/2011 GMT",
            "01/05/2011 GMT",
            "01/06/2011 GMT",
          ],
          labels: {
            formatter: function (val) {
              return val + "K";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "K";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      series3: [
        {
          name: "Day",
          data: [44, 55, 41, 67, 22, 43],
        },
        {
          name: "Night",
          data: [13, 23, 20, 8, 13, 27],
        },
      ],
      barChartModal: false,
      firstModal: false,
      barChartData: [],
      barChartColumns: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowData[dataIndex].EmployeeUrl}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowData[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "AttnDate",
          label: "Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "timing",
          label: "Timing",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "WorkShift",
          label: "Work Shift",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "AttnStatus",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusReal = this.state.rowData[dataIndex].AttnStatus;
              if (statusReal == 1) {
                return (
                  <div>
                    <Chip
                      color="success"
                      style={{
                        backgroundColor: "#2EB85C",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Present"
                    />
                  </div>
                );
              } else if (statusReal == 2) {
                return (
                  <div>
                    <Chip
                      color="warning"
                      style={{
                        backgroundColor: "#3399fe",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Half Day"
                    />
                  </div>
                );
              } else if (statusReal == 3) {
                return (
                  <div>
                    <Chip
                      color="secondary"
                      style={{
                        backgroundColor: "f9b114",
                        color: "white",
                        backgroundColor: "#a8af1d",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Sick Leave"
                    />
                  </div>
                );
              } else if (statusReal == 9) {
                return (
                  <div>
                    <Chip
                      color="info"
                      style={{
                        backgroundColor: "#4e5d72",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Yet To Get Attendance"
                    />
                  </div>
                );
              } else if (statusReal == 0) {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{
                        backgroundColor: "#e55353",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Absent"
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{ borderRadius: "4px", height: "25px" }}
                      label="Absent"
                    />
                  </div>
                );
              }
            },
          },
        },

        {
          name: "projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const pro = this.state.rowData[dataIndex];
              return (
                <div style={{ textAlign: "center" }}>{pro.ProjectCnt}</div>
              );
            },
          },
        },
      ],
      statsModal: false,
      statsData: [],
      statsColumn: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowDataStats[dataIndex].Picture}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowDataStats[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "EmployeeID",
          label: "EmployeeID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "City",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "terminationDate",
          label: "Termination Date",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return this.state.rowDataStats[dataIndex].terminationDate == null
                ? 0
                : moment(
                  this.state.rowDataStats[dataIndex].terminationDate
                ).format("MMM, DD, YYYY");
            },
          },
        },
        {
          name: "Salary",
          label: "Salary",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "AttnBasedSalary",
          label: "Attandance Based Salary",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "TotalDays",
          label: "Total Days",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "daysPresent",
          label: "Days Present",
          options: {
            filter: true,
            sort: true,
          },
        },
      ],
      rb: [],
      rb2: [],
      billingMonthPayslips: '',
      uniqueBillingMonthPayslips: [],
    };
    this.validator = new SimpleReactValidator();
  }

  delay = async (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  getUniqueDateRanges = async () => {
    await axios
      .get(
        `${Config.hostName}/ems/months`
      )
      .then(async (res) => {
        // console.log(res.data.data[0].startDate);
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueDateRanges: res.data.data,
            startDate: res.data.data[0].startDate,
            endDate: res.data.data[0].endDate,
            dateID: res.data.data[0].title
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUniqueBillingMonthPayslips = async () => {
    await axios
      .post(
        `${Config.hostName}/bms/dateRangeDropDownOnlyMonth`, {
        ccxref: 384
      }
      )
      .then(async (res) => {
        console.log(res.data)
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueBillingMonthPayslips: res.data.data,
          });
        }
      }).catch((err) => {
        alert(err);
      });
  };

  handleChangeBillingMonth = async (e) => {
    const value = e.target.value;
    await this.setState({
      billingMonthPayslips: value,
    })
    await axios
      .post(
        `${Config.hostName}/ems/monthlySalarySlipGetStats`, {
        salaryMonth: this.state.billingMonthPayslips.split(" ").join(""),
      }
      )
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          // await this.setState({
          //   uniqueBillingMonthPayslips: res.data.data,
          // });
        }
      }).catch((err) => {
        alert(err);
      });

  }


  async componentDidMount() {
    await this.setState({
      style: {},
    });
    this.getUniqueBillingMonthPayslips();
    await this.getUniqueDateRanges();
    console.log(this.props.auth.user.user_type);
    // await this.dashboardEmployeeStatsCategoryDropdown();
    // await this.dashboardEmployeeStats();
    await this.setState({
      style: {
        display: "none",
      },
    });
  }

  last3Salary = async (name, index) => {
    await this.setState({
      style: {},
    });

    const month = this.state.dateID;
    console.log(month.split(" ").join(""));
    console.log(index);

    const res = await axios
      .post(`${Config.hostName}/ems/onrollSalaryStats`, {
        SalaryMonth: month.split(" ").join(""),
        ReportID: index,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month}_${name}` + ".xlsx");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  }

  handleStatsCategoryDropdownCostCenterWithSalary = async () => {
    await this.setState({
      style: {},
    });

    await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStats`, {
        userid: this.state.userid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        locationID: this.state.locationID,
        reportID: 3,
      })
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.data.length > 0) {
          await this.setState({
            statsOverAll: res.data.data[0].category1,
            statsOther: res.data.data[0].category2,
          });
          await this.setState({
            style: {
              display: "none",
            },
          });
        } else {
          alert("No Records Found... !!");
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
        let listArr = [];
        let orderListArr = [];


        res.data.data[0].category1.map((value, key) => {
          let upperOffArr = 0;
          let lowerOffArr = 0;

          const newVal = value.OffRoll.split("-");

          console.log(newVal[0]);

          upperOffArr = newVal[0];
          lowerOffArr = newVal[1];

          listArr[key] = value;
          listArr[key]["offUpper"] = upperOffArr;
          listArr[key]["offLower"] = lowerOffArr;
        });

        res.data.data[0].category1.map((value, key) => {
          let upperOnArr = 0;
          let lowerOnArr = 0;
          const newVal = value.OnRoll.split("-");

          upperOnArr = newVal[0];
          lowerOnArr = newVal[1];

          listArr[key] = value;
          listArr[key]["onUpper"] = upperOnArr;
          listArr[key]["onLower"] = lowerOnArr;
        });

        res.data.data[0].category2.map((value, key) => {
          let upperOffArr = 0;
          let lowerOffArr = 0;

          const newVal = value.OffRoll.split("-");

          console.log(newVal[0]);

          upperOffArr = newVal[0];
          lowerOffArr = newVal[1];

          listArr[key] = value;
          listArr[key]["offUpper"] = upperOffArr;
          listArr[key]["offLower"] = lowerOffArr;
        });
        res.data.data[0].category2.map((value, key) => {
          let upperOnArr = 0;
          let lowerOnArr = 0;
          const newVal = value.OnRoll.split("-");

          upperOnArr = newVal[0];
          lowerOnArr = newVal[1];

          listArr[key] = value;
          listArr[key]["onUpper"] = upperOnArr;
          listArr[key]["onLower"] = lowerOnArr;
        });
        await this.setState({
          statsOther: listArr,
        });
        console.log(this.state.statsOverAll);
      })
      .catch((error) => {
        alert(error);
      });


  };

  handleChangeDate = async (e) => {
    await this.setState({
      style: {},
    });

    const id = e.target.value;
    // console.log(e.target.value, "id");
    if (e.target.value != 0) {
      let value = e.target.value;
      if (value == 2222) {
        await this.setState({
          dateID: e.target.value,
          startDate: "",
          endDate: "",
        });
        await this.toggle();
      } else {
        this.state.uniqueDateRanges.filter(async (eachDate, i) => {
          if (eachDate.title == id) {
            console.log(eachDate);
            await this.setState({
              dateID: eachDate.title,
              startDate: eachDate.startDate,
              endDate: eachDate.endDate,
            });
          }
        });
      }

      if (this.state.reportID == 3) {
        await this.handleStatsCategoryDropdownCostCenterWithSalary()
      }
      else {
        await this.dashboardEmployeeStats()
      }
      await this.setState({
        style: {
          display: "none",
        },
      });
    }
  };


  offRollSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    // const month = moment()
    //   .subtract(1, "month")
    //   .startOf("month")
    //   .format("MMMM");
    const month = this.state.dateID;
    console.log(month);

    const res = await axios
      .post(`${Config.hostName}/ems/offRollSalarySheet`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month} OffRoll Salaries` + ".xlsx");
          console.log(res.data, "clientBillingData");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  onRollSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    // const month = moment()
    //   .subtract(1, "month")
    //   .startOf("month")
    //   .format("MMMM");
    // console.log(month);
    const month = this.state.dateID;

    const res = await axios
      .post(`${Config.hostName}/ems/onRollSalarySheet`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month} OnRoll Salaries` + ".xlsx");
          console.log(res.data, "clientBillingData");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  relianceSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    // const month = moment()
    //   .subtract(1, "month")
    //   .startOf("month")
    //   .format("MMMM");
    // console.log(month);

    const month = this.state.dateID;

    const res = await axios
      .post(`${Config.hostName}/ems/relianceSalarySheet`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month} Reliance Salaries` + ".xlsx");
          console.log(res.data, "relianceSalarySheet");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  clientBillingData = async () => {
    await this.setState({
      style: {},
    });

    // const month = moment()
    //   .subtract(1, "month")
    //   .startOf("month")
    //   .format("MMMM");
    // console.log(month);

    const month = this.state.dateID;

    const res = await axios
      .post(`${Config.hostName}/ems/clientBillingData`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          await this.setState({
            rb: res.data.data,
          });
        } else {
          alert("No Data Found");
        }
      })
      .catch((error) => {
        alert(error);
      });

    const result = await axios
      .post(`${Config.hostName}/ems/relianceBillingAttendance`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          await this.setState({
            rb2: res.data.data,
          });
        } else {
          alert("No Data Found");
        }
      })
      .catch((error) => {
        alert(error);
      });

    // console.log(this.state.rb,this.state.rb2);

    if (this.state.rb != [] && this.state.rb2 != []) {
      var workSheet = XLSX.utils.json_to_sheet(this.state.rb);
      var workSheet2 = XLSX.utils.json_to_sheet(this.state.rb2);
      var workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Billing");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Attendance");
      XLSX.writeFile(workBook, `${month} Reliance Client Billing` + ".xlsx");
    }

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  dashboardEmployeeStats = async () => {
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStats`, {
        userid: this.state.userid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        locationID: this.state.locationID,
        reportID: 1,
      })
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.data.length > 0) {
          await this.setState({
            statsOverAll: res.data.data[0].category1,
            statsOther: res.data.data[0].category2,
          });
        } else {
          alert("No Records Found... !!");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  dashboardEmployeeStatsOverallDetails = async () => {
    await this.setState({
      style: {},
    });
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStatsDetails`, {
        reportID: 4,
        statusID: "",
        segment: '',
        isOnroll: '',
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            rowDataStats: res.data.data,
            statsData: res.data.data,
            statsModal: true,
          });
        } else {
          alert("No Records Found... !!");
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({
          style: {
            display: "none",
          },
        });
      });
  };


  dashboardEmployeeStatsDetails = async (statusID, segment, roll) => {
    await this.setState({
      style: {},
    });
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStatsDetails`, {
        reportID: this.state.reportID,
        statusID: this.state.reportID != 2 ? statusID : "",
        segment: segment,
        isOnroll: roll,
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            rowDataStats: res.data.data,
            statsData: res.data.data,
            statsModal: true,
          });
        } else {
          alert("No Records Found... !!");
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({
          style: {
            display: "none",
          },
        });
      });
  };

  dashboardEmployeeStatsCategoryDropdown = async () => {
    await axios
      .get(`${Config.hostName}/ems/dashboardEmployeeStatsCategoryDropdown`)
      .then((res) => {
        console.log(res.data.data);
        this.setState({
          StatsCategoryDropdown: res.data.data,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleStatsCategoryDropdown = async (e) => {
    await this.setState({
      style: {},
    });

    if (e.target.value != "") {
      await this.setState({
        reportID: e.target.value,
      });

      await axios
        .post(`${Config.hostName}/ems/dashboardEmployeeStats`, {
          userid: this.state.userid,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          locationID: this.state.locationID,
          reportID: e.target.value,
        })
        .then(async (res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            await this.setState({
              statsOverAll: res.data.data[0].category1,
              statsOther: res.data.data[0].category2,
            });
            await this.setState({
              style: {
                display: "none",
              },
            });
          } else {
            alert("No Records Found... !!");
          }

          let listArr = [];
          let orderListArr = [];

          if (e.target.value === 3) {
            res.data.data[0].category1.map((value, key) => {
              let upperOffArr = 0;
              let lowerOffArr = 0;

              const newVal = value.OffRoll.split("-");

              console.log(newVal[0]);

              upperOffArr = newVal[0];
              lowerOffArr = newVal[1];

              listArr[key] = value;
              listArr[key]["offUpper"] = upperOffArr;
              listArr[key]["offLower"] = lowerOffArr;
            });

            res.data.data[0].category1.map((value, key) => {
              let upperOnArr = 0;
              let lowerOnArr = 0;
              const newVal = value.OnRoll.split("-");

              upperOnArr = newVal[0];
              lowerOnArr = newVal[1];

              listArr[key] = value;
              listArr[key]["onUpper"] = upperOnArr;
              listArr[key]["onLower"] = lowerOnArr;
            });

            res.data.data[0].category2.map((value, key) => {
              let upperOffArr = 0;
              let lowerOffArr = 0;

              const newVal = value.OffRoll.split("-");

              console.log(newVal[0]);

              upperOffArr = newVal[0];
              lowerOffArr = newVal[1];

              listArr[key] = value;
              listArr[key]["offUpper"] = upperOffArr;
              listArr[key]["offLower"] = lowerOffArr;
            });
            res.data.data[0].category2.map((value, key) => {
              let upperOnArr = 0;
              let lowerOnArr = 0;
              const newVal = value.OnRoll.split("-");

              upperOnArr = newVal[0];
              lowerOnArr = newVal[1];

              listArr[key] = value;
              listArr[key]["onUpper"] = upperOnArr;
              listArr[key]["onLower"] = lowerOnArr;
            });
            await this.setState({
              statsOther: listArr,
            });
          } else {
            console.log("no split");
          }

          console.log(this.state.statsOverAll);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  handleFilter = async (e) => {

    const res = await axios
      .post(`${Config.hostName}/ems/generatePayslip`, {
        userID: "",
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        dateID: this.state.dateID,
        // startDate: "2022-11-01",
        // endDate: "2022-11-30",
      })
      .then(async (res) => {
        // if (res.data.data.length > 0) {
        //     let total = 0;
        //     await res.data.data.map(async (e, key) => {
        //         total += e.Total;
        //     })
        //     await this.setState({
        //         invoiceDetailsTotal: total,
        //         rowData: res.data.data,
        //     })
        // } else {
        //     await this.setState({
        //         alertSnackbar: true,
        //     })
        // }
        console.log(res);
        alert('Payslip Generated!!');
      })
      .catch(async (err) => {
        // await this.setState({
        //     alertSnackbar: true,
        // })
      });
  }

  removeSpacesFromKeys = async (data) => {
    return data.map(obj => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.trim()] = `${obj[key]}`;
      }
      return newObj;
    });
  };


  checkTemplateFileOnroll = async (data) => {
    const arr1 = [
      {
        "SalaryMonth": "Jan",
        "City": "New York",
        "FirstName": "John",
        "EmployeeID": "123",
        "Designation": "Manager",
        "DaysinTotal": "31",
        "DaysPresent": "27",
        "DOJ": "2022-01-01",
        "GrossSalary": "5000",
        "AttnBasedSalary": "4800",
        "Basic": "2000",
        "HRA": "1000",
        "Convience": "500",
        "Medical": "200",
        "LTA": "0",
        "DriverSalary": "0",
        "Petrol": "0",
        "Books": "0",
        "SpecialAllowance": "1100",
        "EE_PF": "240",
        "ER_PF": "240",
        "PT": "200",
        "GHI": "100",
        "TDS": "0",
        "TotalDeducations": "780",
        "NetPayout": "4020",
        'BeneficiaryAcctNo': 30,
        'BeneficiaryIFSC': 30,
        'Remarks': 30,
      }];
    const newData = await this.removeSpacesFromKeys(data);

    const arr1Keys = await Object.keys(arr1[0]);
    const arr2Keys = await Object.keys(newData[0]);
    console.log(arr2Keys, 'arr2keys');
    console.log(arr1Keys, 'arr1Keys');
    let result = true;

    let arr2 = [];
    await arr2Keys.map(async (e) => {
      let n = e.replace(/\s/g, '');
      arr2.push(n);
    })

    await arr1Keys.map(async (e) => {
      if (!arr2.includes(e)) {
        result = false;
      }
    });
    return result;

  }

  checkTemplateFileOffroll = async (data) => {
    const arr1 = [
      {
        SalaryMonth: "January",
        City: "New York",
        FirstName: "John",
        EmployeeID: "12345",
        Designation: "Manager",
        DaysinTotal: 31,
        DaysPresent: 26,
        GrossSalary: 5000,
        AttnBasedSalary: 4500,
        NetPayout: 3500,
        'BeneficiaryAcctNo': 30,
        'BeneficiaryIFSC': 30,
        'Remarks': 30,
      }];
    const arr1Keys = await Object.keys(arr1[0]);
    const arr2Keys = await Object.keys(data[0]);
    console.log(arr1Keys)
    console.log(arr2Keys)
    let result = true;

    let arr2 = [];
    await arr2Keys.map(async (e) => {
      let n = e.replace(/\s/g, '');
      arr2.push(n);
    })
    console.log(arr2);
    await arr1Keys.map(async (e) => {
      if (!arr2.includes(e)) {
        console.log(e);
        result = false;
      }
    });
    return result;

  }


  checkTemplateFileRils = async (data) => {
    const arr1 = [
      {
        'SalaryMonth': "January",
        'City': "New York",
        'FirstName': "John",
        'EmployeeID': "123",
        'Designation': "Manager",
        'DaysinTotal': 31,
        'DaysPresent': 25,
        'AttnBasedSalary': 50000,
        'Basic': 25000,
        'HRA': 10000,
        'Otherallowance': 5000,
        'adv_leaveEncash': 0,
        'Adv_bonus': 5000,
        'OT': 0,
        'EE_PF': 2000,
        'ER_PF': 2000,
        'EE_ESIC': 0,
        'ER_ESIC': 0,
        'ER_LWF': 1000,
        'PT': 200,
        'TotalDeducations': 4000,
        'NetPayout': 46000,
        'BeneficiaryAcctNo': 30,
        'BeneficiaryIFSC': 30,
        'Remarks': 30,
      }];
    const newData = await this.removeSpacesFromKeys(data);

    const arr1Keys = await Object.keys(arr1[0]);
    const arr2Keys = await Object.keys(newData[0]);
    console.log(arr1Keys, 'arr1')
    console.log(arr2Keys, 'arr2')
    let result = true;


    await arr1Keys.map(async (e) => {
      if (!arr2Keys.includes(e)) {
        console.log(e);
        result = false;
      }
    });
    return result;

  }




  generatePaySlip = async (data, typeID, sheetName) => {
    const salaryMonth = this.state.dateID;
    const formattedMonth = salaryMonth.replace(/\s/g, '');
    const newData = await this.removeSpacesFromKeys(data);
    console.log(newData);

    await newData.map(async (e) => {
      const excelSerialNumber = e.SalaryMonth;
      const date = new Date((excelSerialNumber - 25569) * 24 * 60 * 60 * 1000); // Convert Excel serial number to Unix timestamp
      const monthYearString = date.toLocaleDateString("en-US", { month: "short", year: "numeric" }).toUpperCase().replace("/", "");
      e.SalaryMonth = monthYearString.replace(" ", "");
    })

    console.log(JSON.stringify(newData));

    const res = await axios
      .post(`${Config.hostName}/ems/monthlyBulkUPload`, {
        billingMonth: formattedMonth,
        sheetName: sheetName,
        jdata: JSON.stringify(newData),
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {

        } else {
          alert("No Records Found... !!");
        }

      })
      .catch((error) => {
        alert(error);
      });

    return 1;
  }

  handleChangeAttachment = async (e) => {

    const file = e.target.files[0];
    await this.setState({
      style: {},
    });
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      console.log(sheetName, 'shetname');

      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet);
      if (sheetName === 'OnRoll') {
        const res = await this.checkTemplateFileOnroll(excelData);
        if (res == true) {
          await this.generatePaySlip(excelData, 1, 'OnRoll');
        } else {
          alert("Invalid template");
        }
      } else if (sheetName === 'Reliance') {
        const res = await this.checkTemplateFileRils(excelData);
        if (res == true) {
          await this.generatePaySlip(excelData, 2, 'Reliance');
        } else {
          alert("Invalid template");
        }
      } else if (sheetName === 'OffRoll') {
        const res = await this.checkTemplateFileOffroll(excelData);
        if (res == true) {
          await this.generatePaySlip(excelData, 3, 'OffRoll');
        } else {
          alert("Invalid template");
        }
      } else {
        alert("Invalid sheet name");
        
      }

    };
    reader.readAsArrayBuffer(file);
    await this.setState({
      style: {
        display: "none",
      },
    });

    
  }

  handleOpenGeneratePalyslip = async () => {
    await this.setState({
      generatePalyslipModal: true,
    });
  }

  handleCloseGeneratePalyslip = async () => {
    await this.setState({
      generatePalyslipModal: false,
    });
  }


  handleCloseStatsReport = async () => {
    await this.setState({
      statsModal: false,
    });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  render() {
    const { vertical, horizontal } = this.state;

    return (
      <div>
        <Container
          pt={3}
          style={{
            maxWidth: "inherit",
            background: "white",
            paddingBottom: "12%",
            marginTop: "7%",
          }}
        >
          <div id="cover-spin" style={this.state.style}></div>

          <Grid pt={3} container >

            <Grid item md={3} style={{ marginBottom: "5vh" }}>
              <FormControl
                variant="standard"
                sx={{ width: "19vh" }}
                style={{ marginLeft: "0px" }}
              >
                <InputLabel id="viewInvoiceBilling">Month</InputLabel>
                <Select
                  labelId="viewInvoiceBilling"
                  id="viewInvoiceBilling2"
                  value={this.state.dateID}
                  onChange={(e) => this.handleChangeDate(e)}
                  label="Month"
                  variant="standard"
                >
                  <MenuItem value="">
                    <em>Months</em>
                  </MenuItem>

                  {this.state.uniqueDateRanges.map((eachItem, key) => (
                    <MenuItem value={eachItem.title} key={key}>
                      {eachItem.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>


            </Grid>
            <Grid item md={4} className="mt-1" style={{ marginBottom: "5vh" }}>

              <Button
                color="primary"
                size="large"
                variant="contained"
                component="label"
                onChange={(e) => { this.handleChangeAttachment(e) }}
                style={{ color: "white", background: '#ea4345' }}

              >
                Upload Payslips
                <input
                  type="file"
                  hidden
                  accept=".xls,.xlsx"
                />
              </Button>
            </Grid>


            <Grid item md={5} className="mt-1" style={{ marginBottom: "5vh" }}>

              <Button
                color="primary"
                size="large"
                variant="contained"
                component="label"
                onClick={this.handleOpenGeneratePalyslip}
                style={{ color: "white", background: '#ea4345' }}

              >
                Generate Payslips
              </Button>
            </Grid>



            <Grid item md={2}>
              <Typography pt={1} style={{ color: "#333333" }} variant="h6">
                {" "}
                <b> Salary Sheets:</b>
              </Typography>
            </Grid>

            <Grid item md={10}>
              <Grid container>

                <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    className="hoverChip"
                    variant="outlined"
                    onClick={() => this.relianceSalarySheet()}
                  >
                    RS
                  </Button>
                </Grid>
                <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    variant="outlined"
                    className="hoverChip"
                    onClick={() => this.clientBillingData()}
                  >
                    RB
                  </Button>
                </Grid>

                <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    variant="outlined"
                    className="hoverChip"
                    onClick={() => this.onRollSalarySheet()}
                  >
                    OnS
                  </Button>
                </Grid>

                <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    className="hoverChip"
                    variant="outlined"
                    onClick={() => this.offRollSalarySheet()}
                  >
                    OfS
                  </Button>
                </Grid>




                {/* {this.props.auth.user.USERID == 1376  &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Onroll', 1)}
                    >
                      L3M_Onroll
                    </Button>
                  </Grid>
                } */}




                {this.props.auth.user.user_type == 'hr' &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Reliance', 2)}
                    >
                      L3M_Reliance
                    </Button>
                  </Grid>
                }



                {this.props.auth.user.user_type == 'hr' &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_OVERALL', 4)}
                    >
                      L3M_OVERALL
                    </Button>
                  </Grid>
                }

                {this.props.auth.user.user_type == 'hr' &&

                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Onroll', 1)}
                    >
                      L3M_Onroll
                    </Button>
                  </Grid>
                }

                {this.props.auth.user.user_type == 'hr' &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_OFFroll', 3)}
                    >
                      L3M_OFFroll
                    </Button>
                  </Grid>
                }


                {this.props.auth.user.USERID == 1376 &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Reliance', 2)}
                    >
                      L3M_Reliance
                    </Button>
                  </Grid>
                }



                {this.props.auth.user.USERID == 1376 &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_OVERALL', 4)}
                    >
                      L3M_OVERALL
                    </Button>
                  </Grid>
                }

                {this.props.auth.user.USERID == 1376 &&

                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Onroll', 1)}
                    >
                      L3M_Onroll
                    </Button>
                  </Grid>
                }

                {this.props.auth.user.USERID == 1376 &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_OFFroll', 3)}
                    >
                      L3M_OFFroll
                    </Button>
                  </Grid>
                }





              </Grid>
            </Grid>
          </Grid>

          <Grid mt={3}>
            <Grid item md={12}>
              <Card
                style={{
                  marginTop: "20px",
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography
                        component="h5"
                        style={{
                          lineHeight: 3.5,
                        }}
                      >
                        Employee Stats
                      </Typography>
                    </div>
                    <div>
                      <FormControl
                        variant="standard"
                        sx={{ width: "30vh" }}
                        style={{ marginLeft: "0px" }}
                      >
                        <InputLabel id="viewStatsCategory">
                          Stats Category
                        </InputLabel>
                        <Select
                          labelId="viewStatsCategory"
                          id="viewStatsCategory1"
                          value={this.state.reportID}
                          onChange={(e) => this.handleStatsCategoryDropdown(e)}
                          label="Stats Category"
                          variant="standard"
                        >
                          <MenuItem value="">
                            <em>Stats Category</em>
                          </MenuItem>

                          {this.state.StatsCategoryDropdown.map(
                            (eachItem, key) => (
                              <MenuItem value={eachItem.reportID} key={key}>
                                {eachItem.reporttype}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      {this.state.statsOverAll.map((overall, index) => (
                        <Card key={index}>
                          <CardActionArea
                            style={{
                              cursor: "default",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "antiquewhite",
                              }}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "600",
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.dashboardEmployeeStatsOverallDetails()
                                }
                              >
                                {overall.Segment}
                              </p>
                            </div>
                          </CardActionArea>
                          <CardContent>
                            {this.state.reportID !== 3 ? (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "2rem"
                                          : "2rem"
                                          }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          1
                                        )
                                      }
                                    >
                                      {overall.OnRoll}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  On Roll
                                </p>

                                <div
                                  style={{
                                    backgroundColor: "black",
                                    height: "1px",
                                    marginBottom: "20px",
                                  }}
                                ></div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "2rem"
                                          : "2rem"
                                          }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          0
                                        )
                                      }
                                    >
                                      {overall.OffRoll}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  Off Roll
                                </p>
                              </div>
                            ) : (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "2rem"
                                          : "2rem"
                                          }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          1
                                        )
                                      }
                                    >
                                      {overall.onUpper}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  On Roll
                                </p>

                                <h1
                                  style={{
                                    fontSize: `${this.state.reportID === 3
                                      ? "1rem"
                                      : "1rem"
                                      }`,
                                    textAlign: "center",
                                    color: "grey",

                                    fontFamily:
                                      "Roboto,Helvetica,Arial,sans-serif",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.dashboardEmployeeStatsDetails(
                                      overall.StatusID,
                                      overall.Segment,
                                      1
                                    )
                                  }
                                >
                                  ₹ {overall.onLower}
                                </h1>

                                <div
                                  style={{
                                    backgroundColor: "black",
                                    height: "1px",
                                    marginBottom: "20px",
                                  }}
                                ></div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "2rem"
                                          : "2rem"
                                          }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          0
                                        )
                                      }
                                    >
                                      {overall.offUpper}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  Off Roll
                                </p>

                                <h1
                                  style={{
                                    fontSize: `${this.state.reportID === 3
                                      ? "1rem"
                                      : "1rem"
                                      }`,
                                    textAlign: "center",
                                    color: "grey",

                                    fontFamily:
                                      "Roboto,Helvetica,Arial,sans-serif",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.dashboardEmployeeStatsDetails(
                                      overall.StatusID,
                                      overall.Segment,
                                      0
                                    )
                                  }
                                >
                                  ₹ {overall.offLower}
                                </h1>
                              </div>
                            )}
                          </CardContent>
                        </Card>
                      ))}
                    </Grid>
                    <Grid item xs={9}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          flexWrap: "wrap",
                        }}
                      >
                        {this.state.statsOther.map((stats, index) => (
                          <Card
                            style={{
                              width: "30%",
                              marginBottom: "10px",
                            }}
                            key={index}
                          >
                            <CardActionArea
                              style={{
                                cursor: "default",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#e1effb",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                    padding: "5px",
                                  }}
                                >
                                  {stats.Segment}
                                </p>
                              </div>
                            </CardActionArea>
                            <CardContent>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                {this.state.reportID !== 3 ? (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            fontSize: `${this.state.reportID === 3
                                              ? "12px"
                                              : "1.75rem"
                                              }`,
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              1
                                            )
                                          }
                                        >
                                          {stats.OnRoll}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                      }}
                                    >
                                      On Roll
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              1
                                            )
                                          }
                                        >
                                          {stats.onUpper}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      On Roll
                                    </p>
                                    <h3
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "12px"
                                          : "1.75rem"
                                          }`,
                                        color: "grey",
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          stats.StatusID,
                                          stats.Segment,
                                          1
                                        )
                                      }
                                    >
                                      ₹ {stats.onLower}
                                    </h3>
                                  </div>
                                )}

                                <div>
                                  <p
                                    style={{
                                      fontSize: "2rem",
                                    }}
                                  >
                                    |
                                  </p>
                                </div>

                                {this.state.reportID !== 3 ? (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            fontSize: `${this.state.reportID === 3
                                              ? "12px"
                                              : "1.75rem"
                                              }`,
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              0
                                            )
                                          }
                                        >
                                          {stats.OffRoll}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                      }}
                                    >
                                      Off Roll
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              0
                                            )
                                          }
                                        >
                                          {stats.offUpper}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      Off Roll
                                    </p>
                                    <h3
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "12px"
                                          : "1.75rem"
                                          }`,
                                        color: "grey",
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          stats.StatusID,
                                          stats.Segment,
                                          0
                                        )
                                      }
                                    >
                                      ₹ {stats.offLower}
                                    </h3>
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseStatsReport}
          open={this.state.statsModal}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "initial",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseStatsReport}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseStatsReport}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>

            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Update Status To: <b>{this.state.statusText}</b>
              </Typography> */}
            <MUIDataTable
              title={"Employee List"}
              data={this.state.statsData}
              columns={this.state.statsColumn}
              options={options}
            />
          </Container>
        </Dialog>


        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseGeneratePalyslip}
          open={this.state.generatePalyslipModal}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "initial",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseGeneratePalyslip}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseGeneratePalyslip}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>


            <div style={{ margin: "26px" }}>
              <Card>
                <CardContent>
                  <Typography variant="h5" color="inherit" component="div" style={{ textAlign: 'center' }}>
                    <b> Generate Payslips</b>
                  </Typography>
                  <br />

                  <Grid pt={1} container spacing={1}>
                    <Grid item md={3}>
                      <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                        <InputLabel id="demo-simple-select-standard2">Billing Month </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard2"
                          value={this.state.billingMonthPayslips}
                          onChange={(e) => { this.handleChangeBillingMonth(e) }}
                          variant="standard"
                        >
                          {this.state.uniqueBillingMonthPayslips.map((name, key) => (
                            <MenuItem key={key} value={name.billingMonth}>
                              {name.billingMonth}
                            </MenuItem>
                          ))}
                          {/* {this.state.uniqueDateRanges.map((eachItem, key) => (
                                        <MenuItem value={eachItem.billingMonth} key={key} >{eachItem.billingMonth}</MenuItem>
                                    ))} */}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={9} style={{ marginTop: '12px' }}>

                      <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        component="label"
                        onClick={this.handleOpenGeneratePalyslip}
                        style={{ color: "white", background: '#ea4345' }}

                      >
                        Process Payslips
                      </Button>
                    </Grid>

                    <Grid item md={12} style={{ marginTop: '3vh', marginBottom: '10vh' }}>
                      <Paper elevation={6}>
                        {/* <MUIDataTable
                                                    title={`Grand Total - ₹ ${new Intl.NumberFormat("en-IN").format(this.state.invoiceDetailsTotalEagle)}`}
                                                    data={this.state.rowDataEagleView}
                                                    columns={this.state.columnsInvDetailsEagle}
                                                    options={options}
                                                /> */}
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            {/* <MUIDataTable
              title={"Employee List"}
              data={this.state.statsData}
              columns={this.state.statsColumn}
              options={options}
            /> */}
          </Container>
        </Dialog>
      </div >
    );
  }
}

EmployeeStats.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(EmployeeStats);
