/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/*global google*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import Config from "../../../../config";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { Button } from "@material-ui/core";
// import { DeleteIcon } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import "../../../../assets/customCssFiles/loader-spinner.css";
import "../../../../assets/customCssFiles/driverTracking.css";
import { cardsData, vehiclesData } from "./driverTrackingCardsData";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import LocationSearchingRoundedIcon from "@material-ui/icons/LocationSearchingRounded";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import LeftCardTracking from "./TrackingComponents/LeftCardDrivers";
class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directions: [],
      routes: [],
      anotherRoutes: [],
      drivers: [],
      style: {},
      selectedPlace: "",

      inilat: "",
      inilng: "",
      routeno: 0,
      showingInfoWindow: false,
      activeMarker: {},

      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      alert_message: "",
      isRouteMarkerVisible: true,
      isDriverMarkerVisible: true,
      isRouteLineVisible: true,
      isPlayAnimation: false,

      dLat: "",
      dLng: "",
      position: {},
      driverName: "Driver",
      data: [],

      driverPosition: {},
      driverData: [],

      checkedArray: [],
      uniqueRouteNosMain: [],

      isCheckAll: false,

      filterStyle: { display: "none" },
      filters1OptionActive: false,
      filters2OptionActive: false,
      filters3OptionActive: false,
      filterOpen: false,
      playButtonText: "Play",
    };
  }

  driverTrack = async (data) => {
    await this.state.routes.map(async (route, index) => {
      const driverData = route.driverData;
      await this.setState({
        driverData: [...this.state.driverData, driverData],
      });
    });
    // console.log(this.state.driverData, "driverData");
  };

  displayShipments = async () => {
    // console.log(this.state.routes);

    await this.state.routes.map((route, index) => {
      const waypts = route.waypoints;
      const directionsService = new google.maps.DirectionsService();
      const origin = { lat: route.origin.lat, lng: route.origin.lng };
      const color = route.colors;
      const data = route.data;
      return directionsService.route(
        {
          origin: origin,
          destination: origin,
          // optimizeWaypoints: true,
          waypoints: waypts,
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            let res = [];
            // console.log(result, "result");

            res.push(result, color, data);
            this.setState({
              directions: [...this.state.directions, res],
            });
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    });
  };

  dataFunc = (data) => {
    let routes = [];
    // console.log(data);
    if (data) {
      this.setState({
        inilat: data.length > 0 ? Number(data[0].lat) : Number("17.3454"),
        inilng: data.length > 0 ? Number(data[0].long) : Number("78.3454"),
        position: {
          lat: data.length > 0 ? Number(data[0].lat) : Number("17.3454"),
          lng: data.length > 0 ? Number(data[0].long) : Number("78.3454"),
        },
      });

      data.forEach((eachLocation) => {
        let cond = routes.findIndex(
          (item) => item.VehicleID === eachLocation.VehicleID
        );

        if (cond === -1) {
          if (eachLocation.StopOrder === 0) {
            routes.push({
              VehicleID: eachLocation.VehicleID,
              checkedObj: {
                VehicleID: eachLocation.VehicleID,
                isChecked: false,
              },
              origin: {
                lat: Number(parseFloat(eachLocation.lat)),
                lng: Number(parseFloat(eachLocation.long)),
              },
              waypoints: [],
              colors: `
                                rgb(
                                    ${Math.floor(Math.random() * 255)},
                                    ${Math.floor(Math.random() * 255)},
                                    ${Math.floor(Math.random() * 255)}
                                    )
                                `,
              data: {
                StopOrder: eachLocation.StopOrder,
                StopName: eachLocation.StopName,
                trackingnumber: eachLocation.trackingnumber,
                shipmentDate: eachLocation.shipmentDate,
                ID: eachLocation.ID,
                status: eachLocation.Deliverstatus,
              },
              driverData: {
                DriverContact: eachLocation.DriverContact,
                DriverLat: eachLocation.DriverLat,
                Driverlong: eachLocation.Driverlong,
                DriverName: eachLocation.DriverName,
              },
            });
            let array = this.state.checkedArray;
            array.push({
              checkedObj: {
                VehicleID: eachLocation.VehicleID,
                isChecked: false,
              },
            });
            this.setState({
              checkedArray: array,
            });
          } else {
            routes.push({
              VehicleID: eachLocation.VehicleID,
              checkedObj: {
                VehicleID: eachLocation.VehicleID,
                isChecked: false,
              },
              origin: {},
              waypoints: [
                {
                  location: {
                    lat: parseFloat(eachLocation.lat),
                    lng: parseFloat(eachLocation.long),
                  },
                },
              ],
              colors: `rgb(
                                    ${Math.floor(Math.random() * 255)},
                                    ${Math.floor(Math.random() * 255)},
                                    ${Math.floor(Math.random() * 255)}
                                    )`,
              data: {
                StopOrder: eachLocation.StopOrder,
                StopName: eachLocation.StopName,
                trackingnumber: eachLocation.trackingnumber,
                shipmentDate: eachLocation.shipmentDate,
                ID: eachLocation.ID,
                status: eachLocation.Deliverstatus,
              },
              driverData: {
                DriverContact: eachLocation.DriverContact,
                DriverLat: eachLocation.DriverLat,
                Driverlong: eachLocation.Driverlong,
                DriverName: eachLocation.DriverName,
              },
            });
            let array = this.state.checkedArray;
            array.push({
              checkedObj: {
                VehicleID: eachLocation.VehicleID,
                isChecked: false,
              },
            });
            this.setState({
              checkedArray: array,
            });
          }
        } else {
          if (eachLocation.StopOrder === 0) {
            routes[cond].origin = {
              lat: Number(parseFloat(eachLocation.lat)),
              lng: Number(parseFloat(eachLocation.long)),
            };
            routes[cond].data = {
              StopOrder: eachLocation.StopOrder,
              StopName: eachLocation.StopName,
              trackingnumber: eachLocation.trackingnumber,
              shipmentDate: eachLocation.shipmentDate,
              ID: eachLocation.ID,
              status: eachLocation.Deliverstatus,
            };
            routes[cond].driverData = {
              DriverContact: eachLocation.DriverContact,
              DriverLat: eachLocation.DriverLat,
              Driverlong: eachLocation.Driverlong,
              DriverName: eachLocation.DriverName,
            };
          } else {
            routes[cond].waypoints.push({
              location: {
                lat: parseFloat(eachLocation.lat),
                lng: parseFloat(eachLocation.long),
              },
            });
            routes[cond].data = {
              // data: {
              StopOrder: eachLocation.StopOrder,
              StopName: eachLocation.StopName,
              trackingnumber: eachLocation.trackingnumber,
              shipmentDate: eachLocation.shipmentDate,
              ID: eachLocation.ID,
              status: eachLocation.Deliverstatus,

              // }
            };

            routes[cond].driverData = {
              // driverData: {
              DriverContact: eachLocation.DriverContact,
              DriverLat: eachLocation.DriverLat,
              Driverlong: eachLocation.Driverlong,
              DriverName: eachLocation.DriverName,
              // }
            };
          }
        }
      });

      this.setState({
        routes: routes,
      });
      // console.log(this.state.routes);
    } else {
      this.setState({
        inilat: Number(17.3417977),
        inilng: Number(78.5784007),
        position: {
          lat: Number(17.3417977),
          lng: Number(78.5784007),
        },
      });
    }
  };

  getInitialData = async (id, route) => {

    let royaloak = String(this.props.auth.user.CCID) + ",499";
    let paramsValue = this.props.auth.user.CCID == 4 ? royaloak : this.props.auth.user.CCID;
    const res = await axios.post(`${Config.hostName}/shipment/tracking/route/data/${id}/${paramsValue}`,
      {
        vehicles: "v2",
      }
    )
      .then(async (res) => {
        console.log(res.data);
        await this.setState({
          data: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getShipmentsForRoute = async () => {
    await this.getInitialData(1, "k");
  };

  getShipmentsAnimation = async () => {
    await this.getInitialData(0, "k");
  };

  componentDidMount = async () => {
    await this.setState({
      checkedArray: [],
      uniqueRouteNosMain: [],
      showingInfoWindow: false,
      activeMarker: null,
      data: [],
      style: {},
    });
    await this.displayRoutesNormal();
    let uniqueRouteNosBegin = this.state.checkedArray;
    await this.setState({
      uniqueRouteNosMain: uniqueRouteNosBegin,
    });
    await this.setState({
      style: { display: "none" },
    });
  };

  displayRoutesNormal = async () => {
    await this.setState({ directions: [], driverData: [] });
    await this.getShipmentsForRoute();
    await this.dataFunc(this.state.data);

    await this.displayShipments();
    await this.driverTrack(this.state.driverData);
  };

  displayAnimation = async () => {
    this.interval = setInterval(async () => {
      await this.setState({ directions: [], driverData: [] });
      await this.getShipmentsAnimation();
      await this.dataFunc(this.state.data);
      await this.displayShipments();
      await this.driverTrack();
      await this.setState({
        style: { display: "none" },
      });
    }, 15000);
  };

  playTheAnimation = async () => {
    try {
      await this.setState({ isPlayAnimation: !this.state.isPlayAnimation });
      if (this.state.isPlayAnimation) {
        return this.displayAnimation();
      } else {
        clearInterval(this.interval);
        return this.displayRoutesNormal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  onMarkerClick = async (driver) => {
    await this.setState({
      position: {
        lat: Number(driver.DriverLat),
        lng: Number(driver.Driverlong),
      },
      showingInfoWindow: true,
      driverName: driver.DriverName,
    });
  };

  onMapClicked = async (props) => {
    if (this.state.showingInfoWindow) {
      await this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  route = async (e) => {
    let value = e.target.value;
    let newRouteArray = this.state.uniqueRouteNosMain;
    if (value == "all") {
      await this.setState({
        isCheckAll: !this.state.isCheckAll,
      });
      newRouteArray.filter((arr) => {
        return (arr.checkedObj.isChecked = this.state.isCheckAll);
      });
      await this.setState({
        uniqueRouteNosMain: newRouteArray,
      });
    } else {
      newRouteArray.filter((arr) => {
        if (arr.checkedObj.VehicleID == value) {
          let checkVal = arr.checkedObj.isChecked;
          return (arr.checkedObj.isChecked = !checkVal);
        }
      });
      await this.setState({
        uniqueRouteNosMain: newRouteArray,
      });
    }
  };

  searchByRouteCheckBox = async () => {
    let newRouteArray = this.state.uniqueRouteNosMain;
    let VehicleIDsArray = "";
    let aop;
    newRouteArray.filter((arr) => {
      if (arr.checkedObj.isChecked) {
        let num = arr.checkedObj.VehicleID;
        VehicleIDsArray = VehicleIDsArray + num + ",";
      }
      aop = VehicleIDsArray.toString();
    });
    let str = aop.replace(/,\s*$/, "");
    await this.setState({ directions: [], driverData: [], checkedArray: [] });
    await this.getInitialData(1, str);
    await this.dataFunc(this.state.data);
    await this.displayShipments();
    await this.driverTrack(this.state.driverData);
    await this.setState({
      style: { display: "none" },
    });
  };

  onClickFilterButton = async (e) => {
    e.preventDefault();
    if (this.state.playButtonText === "Play") {
      await this.setState({
        playButtonText: "Pause",
      });
    } else {
      await this.setState({
        playButtonText: "Play",
      });
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return (this.state.data !== nextState.data);
  // }

  render() {
    let { style, filterStyle, filtersOptionStyle } = this.state;
    const GoogleMapExample = withGoogleMap((props) => (
      <div>
        <GoogleMap
          google={this.props.google}
          onClick={this.onMapClicked}
          style={{
            width: "100%",
            height: "600px",
            position: "absolute",
          }}
          className={"map"}
          initialCenter={{
            lat: Number(this.state.inilat),
            lng: Number(this.state.inilng),
          }}
          center={{
            lat: Number(this.state.inilat),
            lng: Number(this.state.inilng),
          }}
          zoom={8}
        >
          {/* {console.log(this.state.directions, "final")} */}
          {this.state.directions &&
            this.state.directions.map((item, index) => {
              let status = item[2].status;
              let icon1 = `http://maps.google.com/mapfiles/kml/pal2/icon18.png`;
              let icon2 = require("../../../../assets/customImages/icon-green-light.png");
              let icon4 = require("../../../../assets/customImages/icon-red-light.png");
              let icon3 = require("../../../../assets/customImages/icon-orange-out.png");
              return (
                <DirectionsRenderer
                  key={index}
                  directions={item[0]}
                  options={{
                    polylineOptions: {
                      strokeOpacity: 1,
                      strokeWeight: 4,
                      strokeColor: item[1],
                      visible: this.state.isRouteLineVisible,
                    },
                    markerOptions: {
                      title: String(item[2].StopOrder),
                      labelOrigin: { x: 10, y: 50 },
                      anchorPoint: new google.maps.Point(32, 65),
                      visible: this.state.isRouteMarkerVisible,
                      icon: {
                        url:
                          status === 0
                            ? icon1
                            : status === 1
                              ? icon2
                              : status === 2
                                ? icon3
                                : icon4,
                        scaledSize: new google.maps.Size(17, 17),
                      },
                    },
                  }}
                />
              );
            })}

          {this.state.driverData.map((driver, index) => {
            // console.log(driver, index);
            return (
              <Fragment>
                <Marker
                  animation={google.maps.Animation.DROP}
                  icon={{
                    url: require("../../../../assets/customImages/truck_tracking_@1x.png"),
                    scaledSize: new google.maps.Size(50, 50),
                  }}
                  visible={this.state.isDriverMarkerVisible}
                  // style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}
                  key={index}
                  position={{
                    lat: Number(driver.DriverLat),
                    lng: Number(driver.Driverlong),
                  }}
                  // name="My Marker"
                  name={
                    <div>
                      <p>
                        Name : {driver.DriverName}
                        <br />
                        Contact : {driver.DriverContact}
                      </p>
                    </div>
                  }
                  onClick={() => this.onMarkerClick(driver)}
                  labelOrigin={{ x: 10, y: 50 }}
                  anchorPoint={new google.maps.Point(32, 65)}
                  label={{
                    text: `${driver.DriverName}`,
                    fontFamily: "Arial",
                    fontSize: "20px",
                    color: "black",
                  }}
                ></Marker>
              </Fragment>
            );
          })}

          {this.state.showingInfoWindow && (
            <InfoWindow
              position={this.state.position}
              visible={this.state.showingInfoWindow}
            >
              <div>
                {" "}
                <span>{this.state.driverName}</span>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    ));

    return (
      <div>
        <div id="cover-spin" style={style}></div>
        <div className="driver-tracking-main-div" style={{ display: "flex" }}>
          <div
            className="driver-tracking-left-div overflow-auto"
            style={{
              height: "100vh",
              flex: 1,
            }}
          >
            <LeftCardTracking />
          </div>

          <div className="driver-tracking-right-div" style={{ flex: 2 }}>
            <div className="row ">
              <div className="col-xl-12">
                <div className="card-body">
                  <div className="row">
                    <button className="btn mw-100 p-1 m-1 driver-tracking-custom-button">
                      <span>
                        <ListRoundedIcon fontSize="small" color="disabled" />
                        &nbsp;
                      </span>
                      List View
                    </button>
                    <button
                      className="btn mw-100 p-1 m-1 driver-tracking-custom-button"
                      onClick={() =>
                        this.setState({
                          filterOpen: !this.state.filterOpen,
                        })
                      }
                    >
                      <span>
                        <FilterListIcon fontSize="small" color="disabled" />
                        &nbsp;
                      </span>
                      Filter
                    </button>
                    <button
                      className="btn mw-100 p-1 m-1 driver-tracking-custom-button"
                      onClick={(e) => { this.onClickFilterButton(e) }}
                    >
                      <span
                        className={
                          this.state.playButtonText === "Play"
                            ? "fa fa-play"
                            : "fa fa-square"
                        }
                        style={{ color: "#00000042" }}
                      >
                        &nbsp;
                      </span>
                      {this.state.playButtonText}
                    </button>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <GoogleMapExample
              containerElement={
                <div style={{ height: `75vh`, width: "100%" }} />
              }
              mapElement={<div style={{ height: `75vh` }} />}
              loadingElement={<div style={{ height: `75vh` }} />}
            />
          </div>

          <div
            className={`filter-div overflow-auto ${!this.state.filterOpen &&
              "filtersDivOpen"}`}
          >
            <div className="row m-0">
              <div className="col-12 mb-4">
                <span style={{ fontWeight: "600" }}>FILTERS</span>
                <span
                  className={`float-right fa fa-times mr-1  ${!this.state.filterOpen && "filtersDivOpen"}`}
                  onClick={() =>
                    this.setState({
                      filterOpen: !this.state.filterOpen,
                    })
                  }
                ></span>
              </div>
              <div
                className="col-12 pb-3 mb-4 driver-tracking-filter-custom-row w-100"
                onClick={() =>
                  this.setState({
                    filters1OptionActive: !this.state.filters1OptionActive,
                  })
                }
              >
                <span>Hide/Show filters</span>
                <span className="float-right fa fa-chevron-down mr-1"></span>
              </div>

              <div
                className={`col-12 mb-3 ${!this.state.filters1OptionActive &&
                  "filtersCardActive"}`}
              >
                <input
                  type="checkbox"
                  //   className="custom-control-input"
                  id="customSwitch1"
                  value={this.state.isRouteMarkerVisible}
                  onChange={(e) => {
                    this.setState({
                      isRouteMarkerVisible: !this.state.isRouteMarkerVisible,
                      showingInfoWindow: false,
                      activeMarker: null,
                    });
                  }}
                />
                <label className="ml-1" htmlFor="customSwitch1">
                  Hide Shipments
                </label>
                <br />
                <input
                  type="checkbox"
                  // className="custom-control-input"
                  id="customSwitch2"
                  value={this.state.isDriverMarkerVisible}
                  onChange={(e) => {
                    this.setState({
                      isDriverMarkerVisible: !this.state.isDriverMarkerVisible,
                      showingInfoWindow: false,
                      activeMarker: null,
                    });
                  }}
                />
                <label className="ml-1" htmlFor="customSwitch2">
                  Hide Drivers
                </label>
                <br />
                <input
                  type="checkbox"
                  id="customSwitch3"
                  value={this.state.isRouteLineVisible}
                  onChange={(e) => {
                    this.setState({
                      isRouteLineVisible: !this.state.isRouteLineVisible,
                      showingInfoWindow: false,
                      activeMarker: null,
                    });
                  }}
                />
                <label className="ml-1" htmlFor="customSwitch3">
                  Hide Routes
                </label>
                <br />
              </div>
              <div
                className="col-12 pb-3 mb-4 driver-tracking-filter-custom-row w-100"
                onClick={() =>
                  this.setState({
                    filters2OptionActive: !this.state.filters2OptionActive,
                  })
                }
              >
                <span>Filter by Vehicle</span>
                <span className="float-right fa fa-chevron-down mr-1"></span>
              </div>

              <div
                className={`col-12 mb-3 ${!this.state.filters2OptionActive &&
                  "filtersCardActive"}`}
              >
                {this.state.uniqueRouteNosMain.sort().map((route) => {
                  return (
                    <div>
                      <input type="checkbox" id={`vehicle${route.checkedObj.VehicleID}`} />
                      <label className="ml-1" htmlFor={`vehicle${route.checkedObj.VehicleID}`}>
                        {route.checkedObj.VehicleID}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-outline-info p-1 m-2 driver-tracking-reset-and-apply-button"
                >
                  Reset
                </button>
                <button
                  type="button"
                  class="btn btn-info p-1 m-2 driver-tracking-reset-and-apply-button"
                  onClick={() =>
                    this.setState({
                      filterOpen: !this.state.filterOpen,
                    })
                  }
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Map;
