/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, {
  Component
  // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Label, Input, Button } from "reactstrap";
// import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import moment from "moment";
import DateTimePicker from 'react-datetime-picker';
import "../../../../assets/customCssFiles/loader-spinner.css"
import Breadcrumb from "../../../common/breadcrumb.component";


class RouteAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      loading: false,
      unique_servicehours: [],
      unique_vendornames: [],
      unique_vehiclesizes: [],
      unique_vehiclenumbers: [],
      Locationid: "",
      ClientName: "",
      Segment: "",
      RouteCode: "",
      StartingPoint: "",
      Endingpoint: "",
      TripDate: new Date(),
      ServiceHours: "",
      ChargeType: "",
      ReportingTime: new Date(),
      IsOdometer: "",
      VendorName: "",
      VehicleSize: "",
      VehicleNumber: "",
      VendorCost: "",
      city: "",
      // startDate: new Date(),
      // startTripDate: new Date(),
      IsOdometerValue: ""
    };
    this.validator = new SimpleReactValidator();
  }

  async componentWillMount() {
    await this.setState({
      style: {}

    })
    let result = await axios.get(
      `${Config.hostName}/route-master/get/${this.props.match.params.RouteID}`
    );
    // console.log(result.data);
    let data = result.data.data;
    await this.setState({
      Locationid: data[0].Locationid,
      ClientName: data[0].ClientName,
      Segment: data[0].Segment,
      RouteCode: data[0].RouteCode,
      StartingPoint: data[0].StartingPoint,
      Endingpoint: data[0].Endingpoint,
      city: data[0].City
    });

    await axios
      .post(`${Config.hostName}/vms/get/data/vendors-by-location`,
        {
          locationId: data[0].Locationid,
          vendorName: "",
          vehicleSize: ""
        }
      )
      .then(res => {
        // console.log(res.data);

        this.setState({
          unique_vendornames: res.data.data,
          style: { display: 'none' }

        });
      })
      .catch(error => {
        console.log(error);
      });

  }

  changeVendorName = event => {
    this.setState({ unique_vehiclesizes: [], style: {} });
    event.preventDefault();
    this.setState({ VendorName: event.target.value });
    axios
      .post(`${Config.hostName}/vms/get/data/vehicle-sizes`, {
        vendorName: event.target.value,
        locationId: this.state.Locationid,
        vehicleSize: ""


      })
      .then(res => {
        // console.log(res.data);
        this.setState({
          unique_vehiclesizes: res.data.data,
          style: { display: 'none' }

        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  changeVehicleSize = event => {
    event.preventDefault();

    this.setState({ unique_vehiclenumbers: [], style: {} });
    this.setState({ VehicleSize: event.target.value });
    axios
      .post(`${Config.hostName}/vms/get/data/vehicle-numbers-by-location`, {
        vehicleSize: event.target.value,
        vendorName: this.state.VendorName,
        locationId: this.state.Locationid
      })
      .then(res => {
        // console.log(res.data);
        this.setState({
          unique_vehiclenumbers: res.data.data,
          style: { display: 'none' }

        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChangeTime = async datetime => {
    await this.setState({
      ReportingTime: datetime
    });
    // console.log(this.state.ReportingTime);
  };

  handleTripChange = async date => {
    await this.setState({
      TripDate: date
    });
  };

  onSubmit = async e => {
    e.preventDefault();

    if (this.validator.allValid()) {
      try {
        await this.setState({ loading: true });


        let TripDate = moment(this.state.TripDate).format("YYYY-MM-DD");
        let ReportingTime = moment(this.state.ReportingTime).format("YYYY-MM-DD HH:mm:ss");
        let vehicleSize = this.state.VehicleSize.replace("FT", "")
        // console.log(this.state, TripDate, ReportingTime);

        await axios.post(
          `${Config.hostName}/route-master/add/trip/adhoc`,
          {
            locationid: this.state.Locationid,
            clientName: this.state.ClientName,
            segment: this.state.Segment,
            routeCode: this.state.RouteCode,
            startingPoint: this.state.StartingPoint,
            endingpoint: this.state.Endingpoint,
            tripDate: TripDate,
            serviceHours: this.state.ServiceHours,
            chargeType: this.state.ChargeType,
            reportingTime: ReportingTime,
            isOdometerValue: this.state.IsOdometerValue,
            vendorName: this.state.VendorName,
            vehicleSize: vehicleSize,
            vehicleNumber: this.state.VehicleNumber,
            vendorCost: this.state.VendorCost

          }
        )
          .then(async (response) => {
            // console.log(response.data, "response");
            if (response.data.success === false) {
              await this.setState({ loading: false });

              this.props.setAlert(response.data.message, "danger");
            }
            else {
              await this.setState({ loading: false });

              this.props.setAlert("Adhoc Trip Added", "success");
              await this.props.history.push("/admin/tms/route-code");

            }

          }
          ).catch((error) => {
            console.log(error, 'error');

          }
          )

      } catch (error) {
        const errors = error.response.data.errors;
        if (errors) {
          errors.forEach(error => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleCheckOdometer = async () => {
    await this.setState({
      IsOdometer: !this.state.IsOdometer
    });
    await this.setState({
      // IsOdometer=true ? (IsOdometerValue = 1) : (IsOdometerValue = 0)
      IsOdometerValue: `${this.state.IsOdometer === true ? "1" : "0"}`
    });

  };

  render() {
    let { loading, style } = this.state;


    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;

    return (
      <div>
        <div id='cover-spin' style={style}>
        </div>
        <Breadcrumb title="Create Adhoc Trip" parent="Admin" />

        <div className="container-fluid" >
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />
                <nav
                  className="navbar navbar-light "
                  style={{ backgroundColor: "none" }}
                >
                  <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Locationid:</Label>
                        <Input
                          type="text"
                          name="Locationid"
                          readOnly
                          value={this.state.city}
                          id="Locationid"
                          onChange=""
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Client Name:</Label>
                        <Input
                          type="text"
                          readOnly
                          name="ClientName"
                          value={this.state.ClientName}
                          id="ClientName"
                        >
                          {/* <option value={""}>Select Client</option> */}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Segment:</Label>
                        <Input
                          type="text"
                          name="Segment"
                          readOnly
                          value={this.state.Segment}
                          id="Segment"
                        >
                          {/* <option value={""}>Select Segment</option> */}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Route Code:</Label>
                        <Input
                          type="text"
                          readOnly
                          name="RouteCode"
                          value={this.state.RouteCode}
                          id="RouteCode"
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>TripDate:</Label>
                        <Input
                          placeholder="Enter PAN Number"
                          name="TripDate"
                          type="date"
                          className="form-control"
                          value={this.state.TripDate}
                          onChange={event => {
                            this.setState({
                              TripDate: event.target.value
                            });
                          }}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Charge Type</Label>
                        <Input
                          type="select"
                          name="ChargeType"
                          value={this.state.ChargeType}
                          id="ChargeType"
                          placeholder="Enter Charge Type"
                          onChange={event => {
                            this.setState({ ChargeType: event.target.value });
                          }}
                        >
                          <option value={""} disabled selected >Select Charge Type</option>
                          <option value={"FOZ"}>FOZ</option>
                          <option value={"FWZ"}>FWZ</option>
                          <option value={"FAT"}>FAT</option>
                          <option value={"FBK"}>FAK</option>
                          <option value={"FBT"}>FATON</option>
                          <option value={"FBS"}>FAS</option>
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Service Hours</Label>
                        <Input
                          type="select"
                          name="ServiceHours"
                          value={this.state.ServiceHours}
                          id="ServiceHours"
                          onChange={event => {
                            this.setState({ ServiceHours: event.target.value });
                          }}
                        >
                          <option value={""} disabled selected >Select Service Hours</option>
                          <option value={4}>4</option>
                          <option value={6}>6</option>
                          <option value={8}>8</option>
                          <option value={12}>12</option>
                          <option value={24}>24</option>
                          <option value={48}>48</option>
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Vendor Name</Label>
                        <Input
                          type="select"
                          name="VendorName"
                          value={this.state.VendorName}
                          id="VendorName"
                          onChange={this.changeVendorName}
                        >
                          <option value={""} disabled selected  >Select Vendor Name</option>

                          {this.state.unique_vendornames.map(VendorName => (
                            <option value={VendorName.TransportName}>
                              {VendorName.TransportName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Vehicle Size</Label>
                        <Input
                          type="select"
                          name="VehicleSize"
                          value={this.state.VehicleSize}
                          id="VehicleSize"
                          onChange={this.changeVehicleSize}
                        >
                          <option value={""} disabled selected >Select Vehicle SIze</option>

                          {this.state.unique_vehiclesizes.map(VehicleSize => (
                            <option value={VehicleSize.VehicleSize}>
                              {VehicleSize.VehicleSize}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Vehicle Number</Label>
                        <Input
                          type="select"
                          name="VehicleNumber"
                          value={this.state.VehicleNumber}
                          id="VehicleNumber"
                          onChange={event => {
                            this.setState({
                              VehicleNumber: event.target.value
                            });
                          }}
                        >
                          <option value={""} disabled selected >Select Vehicle Number</option>

                          {this.state.unique_vehiclenumbers.map(
                            VehicleNumber => (
                              <option value={VehicleNumber.VehicleNumber}>
                                {VehicleNumber.VehicleNumber}
                              </option>
                            )
                          )}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Vendor Cost</Label>
                        <Input
                          type="number"
                          name="VendorCost"
                          placeholder="Enter Cost in Rs"
                          value={this.state.VendorCost}
                          id="VendorCost"
                          onChange={(e) => {
                            this.setState({
                              VendorCost: e.target.value
                            })
                          }}
                        >
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-4">
                      <FormGroup>
                        <Label>Reporting Time</Label>
                        <Input
                          placeholder="Enter PAN Number"
                          name="TripDate"
                          type="datetime-local"
                          className="form-control"
                          value={this.state.ReportingTime}
                          onChange={async (e) => {
                            console.log(e.target.value);
                            await this.setState({
                              ReportingTime: e.target.value
                            });
                          }}
                        ></Input>
                      </FormGroup>

                    </div>

                  </div>

                  <div>

                    <div className="col-sm-4 ">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={this.handleCheckOdometer}
                            checked={this.state.IsOdometer}
                          />{" "}
                          Is&nbsp;Odo</Label>

                      </FormGroup>

                    </div>

                    <div className="col-sm-4">
                      <Button
                        type="submit"
                        onClick={e => this.onSubmit(e)}
                        className="btn btn-primary"
                        disabled={loading}
                        style={{ marginTop: "1vh" }}

                      >
                        {loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {loading && <span>Processing ...</span>}
                        {!loading && <span>Create</span>}
                      </Button>
                    </div>

                  </div>

                </nav>

              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

RouteAddForm.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
