/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, {
  Component
  // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Input, Button } from "reactstrap";
// import {Link} from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import "../../../../assets/customCssFiles/loader-spinner.css"

class RouteAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      unique_locationids: [],
      unique_segments: [],
      unique_clients: [],
      unique_servicetypes: [],
      Locationid: "",
      Segment: "",
      ClientName: "",
      StartingPoint: "",
      Endingpoint: "",
      C_RouteCode: null,
      ServiceType: "",
      Lane: "",
      style: {}
    };
    this.validator = new SimpleReactValidator();
  }

  async componentDidMount() {
    let that = this;

    // get all the unique dates
    axios.get(`${Config.hostName}/unique/locations`)
      .then((res) => {
        // console.log(res.data);

        // save unique dates in component state object
        that.setState({
          unique_locationids: res.data.data,

          style: { display: 'none' }

        })
      })
      .catch((error) => {
        console.log(error)
      });
  }


  changeLocation = async event => {
    // console.log(event.target.value);

    this.setState({ style: {}, unique_segments: [], unique_clients: [] });
    event.preventDefault();
    await this.setState({ Locationid: event.target.value });
    let { Locationid } = this.state;
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: Locationid,
        clientName: ""
      })
      .then(res => {
        // console.log(res.data);
        this.setState({
          unique_clients: res.data.data,
          style: { display: 'none' }

        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  changeClient = async event => {
    // console.log(event.target.value);
    event.preventDefault();
    await this.setState({ style: {}, unique_segments: [], ClientName: event.target.value });
    let { ClientName, Locationid } = this.state;
    await axios.post(`${Config.hostName}/unique/segments`, {
      locationId: Locationid,
      clientName: ClientName
    })
      .then(res => {
        // console.log(res);
        this.setState({
          unique_segments: res.data.data,
          style: { display: 'none' }

        });
      })
      .catch(err => {
        console.log(err);
      });
    // console.log(this.state.unique_segments);
  };


  onSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      try {
        // eslint-disable-next-line
        const res = await axios.post(`${Config.hostName}/route-master/add/new/route`, {
          serviceType: this.state.ServiceType,
          clientName: this.state.ClientName,
          locationId: this.state.Locationid,
          cRouteCode: this.state.C_RouteCode,
          segment: this.state.Segment,
          startingPoint: this.state.StartingPoint,
          endingPoint: this.state.Endingpoint,
          lane: this.state.Lane
        }).then((response) => {
          // console.log(response.data, "response");
          if (response.data.success === false) {
            this.props.setAlert(response.data.message, "danger");
          }
          else {
            this.props.setAlert("Route Master Added", "success");
            this.props.history.push("/admin/tms/route-code");

          }

        }
        ).catch((error) => {
          console.log(error, 'error');
        })
      }
      catch (error) {
        const errors = error.response.data.errors;
        if (errors) {
          errors.forEach(error => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  render() {
    let style = this.state.style;

    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;

    return (
      <div>
        <div id='cover-spin' style={style}>
        </div>

        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />
                <nav
                  className="card-body "
                  style={{ backgroundColor: "none" }}
                >
                  <div style={{ paddingTop: "8px", width: "100%" }}>


                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="locationid"
                          value={this.state.Locationid}
                          id="locationid"
                          onChange={this.changeLocation}
                        >
                          <option value={""} disabled selected>Select Location</option>

                          {this.state.unique_locationids.map(locationid => (
                            <option value={locationid.Locationid}>
                              {locationid.name}

                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="clientName"
                          value={this.state.ClientName}
                          id="clientName"
                          onChange={this.changeClient}
                        >
                          <option value={""} disabled selected>Select Client</option>

                          {this.state.unique_clients.map(client => (
                            <option value={client.ClientName}>
                              {client.ClientName}
                            </option>
                          ))}
                          {/*<option value={0}>All Shipments</option>*/}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="Segment"
                          value={this.state.Segment}
                          id="Segment"
                          onChange={event => {
                            this.setState({ Segment: event.target.value });
                          }}
                        >
                          <option value={""} disabled selected>Select Segment</option>

                          {this.state.unique_segments.map(segment => (
                            <option value={segment.Segment}>
                              {segment.Segment}
                            </option>
                          ))}

                          {/* <option value={0}>All Segments</option> */}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="ServiceType"
                          value={this.state.ServiceType}
                          id="ServiceType"
                          onChange={event => {
                            this.setState({
                              ServiceType: event.target.value
                            });
                          }}
                        >
                          <option value={""} disabled selected>Select Service Type</option>
                          <option value={"Regular"}>Regular</option>
                          <option value={"Adhoc"}>Adhoc</option>
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="text"
                          name="C_RouteCode"
                          value={this.state.C_RouteCode}
                          id="C_RouteCode"
                          placeholder="Enter C Route Code (optional)"
                          onChange={event => {
                            this.setState({ C_RouteCode: event.target.value });
                          }}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="text"
                          name="StartingPoint"
                          value={this.state.StartingPoint}
                          id="StartingPoint"
                          placeholder="Enter Starting Point"
                          onChange={event => {
                            this.setState({
                              StartingPoint: event.target.value
                            });
                          }}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="text"
                          name="Endingpoint"
                          value={this.state.Endingpoint}
                          id="Endingpoint"
                          placeholder="Enter Ending Point"
                          onChange={event => {
                            this.setState({
                              Endingpoint: event.target.value,
                              Lane: `${this.state.StartingPoint} - ${event.target.value}`
                            });
                          }}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="text"
                          name="Lane"
                          // value={`${this.state.StartingPoint}-${this.state.Endingpoint}`}
                          value={this.state.Lane}

                          id="Lane"
                          placeholder="Enter Lane"
                          onChange={(event) => {
                            this.setState({ Lane: event.target.value });
                          }}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <Button
                        type="submit"
                        onClick={e => this.onSubmit(e)}
                        className="btn btn-primary"
                      >
                        Create
                        </Button>
                    </div>


                  </div>
                </nav>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RouteAddForm.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
