import React, { Component } from "react";
import axios from "axios";
import Config from "../../../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import { FormGroup, Input, Button, ModalBody, ModalHeader, Modal, ModalFooter, Table } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../assets/customCssFiles/tmshomepage.css";
import { defaults } from "react-chartjs-2";
import "../../../../assets/customCssFiles/loader-spinner.css"
import "../../../../assets/customCssFiles/tmsTripCount.css";
import AdhocImage from "../../../../assets/customImages/adhocTripCount.png";
import RegularImage from "../../../../assets/customImages/regularTripCount.png";
import AmazonImage from "../../../../assets/customImages/amazon.png";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { AgGridReact } from 'ag-grid-react';
import moment from "moment";


// import FlipkartImage from "../../../../assets/customImages/flipkart.png";
// import ViewDetailsTripCount from "./ViewDetailsTripCount";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import CloseIcon from '@material-ui/icons/Close';

defaults.global.defaultFontSize = "12";
defaults.global.defaultFontFamily = "Arial";
defaults.global.defaultFontColor = "#808080";
defaults.global.defaultFontWeight = "200";
defaults.global.defaultLetterSpacing = "2px";

class HomePage extends Component {

    state = {
        apiData: [],
        newApiData: [],
        apiDataNew: [],
        startDate: "",
        endDate: "",
        domLayout: "autoHeight",
        reportId: "",
        style: {},
        isData: false,
        unique_cities: [],
        modal: false,
        uniqueDateRanges: [],
        dateRangeSelected: "",
        statusKeys: ["Regular", "Adhoc"],
        locationId: "",
        widgetData: [],
        clientData: [],
        widgetDataNew: [],
        clientDataNew: [],
        uniqueClients: [],
        requiredClientData: [],
        unique_vrData: [],
        tmsExportData: [],
        tmsExportDataNew: [],
        tmsExportSummaryData: [],
        tmsExportSummaryDataNew: [],
        attendanceExportData: [],
        bmsExportData: [],
        bmsExportDataNew: [],
        vendorPaymentExportData: [],
        vendorPaymentExportDataNew: [],
        clientNameNew: '',
        locationIdNew: '',
        segmentNew: '',


        clientName: "",
        segment: "",
        vehicleNumber: "",
        tripId: "",
        uniqueSegmentsFilter: [],
        uniqueClientsFilter: [],

        locationSandeep: "",

        tripIDs: "",
        popupTableData: [],
        popupTableDataKeys: [],

        toggleTripModal: false,
        vrModal: false,
        columnDefs: [
            
            {
                headerName: "Vendor Name", field: "VendorName", sortable: true, filter: true
            },
            {
                headerName: "Client Name", field: "ClientName", sortable: true, filter: true
            },
            {
                headerName: "Segment", field: "Segment", sortable: true, filter: true
            },
            {
                headerName: "Service Type", field: "ServiceType", sortable: true, filter: true
            },
            {
                headerName: "Vehicle Number", field: "VehicleNumber", sortable: true, filter: true
            },
            {
                headerName: "Vehicle Size", field: "VehicleSize", sortable: true, filter: true
            },
            {
                headerName: "trip Count", field: "tripcnt", sortable: true, filter: true
            },
            {
                headerName: "Download", field: "Vendorid", sortable: true, filter: true,
                cellRendererFramework: (params) => {
                 
                    return (
                        <i
                        className="fa fa-download"
                        onClick={() => this.downlaodVendorFile(params.node.data.Vendorid, params.node.data.VendorName, params.node.data.LocationID, params.node.data.ClientName, params.node.data.Segment)}
                        style={{cursor:'pointer', marginRight: "5px" }}
                        />
                    )
                }
            },
        ],

    };


    toggle = async () => {
        await this.setState({
            modal: !this.state.modal,
        });
    };

    getUniqueCities = async () => {
        await axios
            .get(
                `${Config.hostName}/unique/locations`
            )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_cities: res.data.data,
                        locationId: res.data.data[0].Locationid,
                        locationSandeep: res.data.data[0].Locationid
                    });
                } else {
                    this.setState({
                        unique_cities: [],
                    });
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    getUniqueDateRanges = async () => {
        await axios
            .get(
                `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
            )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                        dateRangeSelected: res.data.data[0].title,
                        startDate: res.data.data[0].startDate,
                        endDate: res.data.data[0].endDate
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };

    getTripStatusReport = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationId,
                "clientName": this.state.clientName,
                "segment": this.state.segment,
                "vehicleNumber": this.state.vehicleNumber
            }
        ).then(async (response) => {
            console.log(response.data.data, "tmsExportData");
            await this.setState({
                tmsExportData: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };

    getTripStatusReportNew = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationIdNew,
                clientName: this.state.clientNameNew,
                segment: this.state.segmentNew,
                vehicleNumber: this.state.vehicleNumber
            }
        ).then(async (response) => {
            console.log(response.data.data, "tmsExportData");
            await this.setState({
                tmsExportDataNew: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };

    getTripStatsAttendanceReportNewMis = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsAttendanceReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationId,
                "clientName": this.state.clientName,
                "segment": this.state.segment,
                "vehicleNumber": this.state.vehicleNumber
            }
        ).then(async (response) => {
            await this.setState({
                attendanceExportData: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };

    getTripStatsVendorPaymentReportNewMis = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsVendorPaymentReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationId,
                "clientName": this.state.clientName,
                "segment": this.state.segment,
                "vehicleNumber": this.state.vehicleNumber
            }
        ).then(async (response) => {
            await this.setState({
                vendorPaymentExportData: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };
    getTripStatsVendorPaymentReportNewMisNew = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsVendorPaymentReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationIdNew,
                clientName: this.state.clientNameNew,
                segment: this.state.segmentNew,
                vehicleNumber: this.state.vehicleNumber
            }
        ).then(async (response) => {
            await this.setState({
                vendorPaymentExportDataNew: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };

    getTripStatsBmsReportNewMis = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsBmsReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationId,
                "clientName": this.state.clientName,
                "segment": this.state.segment,
                "vehicleNumber": this.state.vehicleNumber
            }
        ).then(async (response) => {
            console.log(response.data.data, 'bmsExport');
            await this.setState({
                bmsExportData: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };


    getTripStatsBmsReportNewMisNew = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsBmsReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationIdNew,
                clientName: this.state.clientNameNew,
                segment: this.state.segmentNew,
                vehicleNumber: this.state.vehicleNumber
            }
        ).then(async (response) => {
            console.log(response.data.data, 'bmsExport');
            await this.setState({
                bmsExportDataNew: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };
    getTripStatusSummaryReport = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsSummaryReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationId,
                "clientName": this.state.clientName,
                "segment": this.state.segment,
                "vehicleNumber": this.state.vehicleNumber
            }
        ).then(async (response) => {
            await this.setState({
                tmsExportSummaryData: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };

    getTripStatusSummaryReportNew = async () => {
        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsSummaryReportNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationIdNew,
                clientName: this.state.clientNameNew,
                segment: this.state.segmentNew,
                vehicleNumber: this.state.vehicleNumber
            }
        ).then(async (response) => {
            await this.setState({
                tmsExportSummaryDataNew: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };

    getDataMain = async () => {

        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsCountNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                "locationId": this.state.locationId,
                "clientName": this.state.clientName,
                "segment": this.state.segment,
                "vehicleNumber": this.state.vehicleNumber
            }
        )
            .then(async (res) => {
                const widgetData = [];
                const clientData = [];
                if (res.data.data.length > 0) {
                    res.data.data.filter((item) => {
                        if (item.Clientname === "All") {
                            console.log(item, "item")
                            return widgetData.push(item);
                        }
                        else {
                            return clientData.push(item);
                        }
                    })
                    console.log(res.data.data,'apidata');
                    await this.setState({
                        apiData: res.data.data,
                        widgetData: widgetData,
                        clientData: clientData
                    });
                } else {
                    await this.setState({
                        apiData: [],
                        widgetData: []
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };

    getDataMainNew = async () => {

        const res = await axios.post(
            `${Config.hostName}/tms/getTripStatsCountNewMis`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: this.state.locationIdNew,
                clientName: this.state.clientNameNew,
                segment: this.state.segmentNew,
                vehicleNumber: this.state.vehicleNumber
            }
        )
            .then(async (res) => {
                const widgetDataNew = [];
                const clientDataNew = [];
                if (res.data.data.length > 0) {
                    res.data.data.filter((item) => {
                        if (item.Clientname === "All") {
                            console.log(item, "item")
                            return widgetDataNew.push(item);
                        }
                        else {
                            return clientDataNew.push(item);
                        }
                    })
                    console.log(res.data.data,'apidata');
                    await this.setState({
                        apiDataNew: res.data.data,
                        widgetDataNew: widgetDataNew,
                        clientDataNew: clientDataNew
                    });
                } else {
                    await this.setState({
                        apiDataNew: [],
                        widgetDataNew: []
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };

    getClientWiseData = async () => {
        const { clientData } = this.state;
        const clientNamesUnique = [];
        const map = clientData.map(eachItem => {
            clientNamesUnique.push(eachItem.Clientname)
        });
        const requiredClientData = [];

        const filteredArray = clientNamesUnique.map(item => item)
            .filter((value, index, self) => self.indexOf(value) === index)
        filteredArray.map(fI => {
            requiredClientData.push({
                clientName: fI,
                data: []
            })
        })
        requiredClientData.filter(fItem => {
            clientData.map(item => {
                if (fItem.clientName == item.Clientname) {
                    const dat = fItem.data;
                    return dat.push(item)
                }
            })
        })
        await this.setState({
            uniqueClients: filteredArray,
            requiredClientData: requiredClientData
        })
    };

    getClientWiseDataNew = async () => {
        const { clientDataNew } = this.state;
        const clientNamesUniqueNew = [];
        const map = clientDataNew.map(eachItem => {
            clientNamesUniqueNew.push(eachItem.Clientname)
        });
        const requiredClientData = [];

        const filteredArray = clientNamesUniqueNew.map(item => item)
            .filter((value, index, self) => self.indexOf(value) === index)
        filteredArray.map(fI => {
            requiredClientData.push({
                clientName: fI,
                data: []
            })
        })
        requiredClientData.filter(fItem => {
            clientDataNew.map(item => {
                if (fItem.clientName == item.Clientname) {
                    const dat = fItem.data;
                    return dat.push(item)
                }
            })
        })
        await this.setState({
            uniqueClients: filteredArray,
            requiredClientData: requiredClientData
        })
    };

    componentDidMount = async () => {
        await this.setState({
            style: {}
        });
        await this.setStartEndDates();
        await this.getUniqueDateRanges();
        await this.getUniqueCities();
        // await this.getDataMain();
        // await this.getTripStatusReport();
        // await this.getTripStatusSummaryReport();
        // await this.getClientWiseData();
        await this.setState({
            isData: true,
            locationId: "",
            style: {
                display: "none"
            }
        });
    };

    handleChangeDateRange = async (e) => {
        let value = e.target.value;
        if (value == 2222) {
            await this.setState({
                dateRangeSelected: e.target.value,
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: eachDate.startDate,
                        endDate: eachDate.endDate,
                    });
                }
            });
        }
    };

    toggleCloseModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: "",
        });
    };

    toggleConfirmModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: 2222,
        });
    };

    setStartEndDates = async () => {
        const dateObj = new Date();
        const month = dateObj.getUTCMonth() + 1; //months from 1-12
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        // eslint-disable-next-line no-useless-concat
        // const iDate = year + "-" + month + "-" + "01";
        const newMon = String(month).length == 1 ? `0${month}` : month
        const newDate = String(day).length == 1 ? `0${day}` : day;
        // const iDate = `2020-12-01T00:00:00.000Z`
        const iDate = `${year}-${newMon}-01T00:00:00.000Z`
        // const mDate = year + "-" + month + "-" + day;
        const mDate = `${year}-${newMon}-${newDate}T23:59:00.000Z`
        await this.setState({
            startDate: iDate,
            endDate: mDate
            // startDate: "2020-12-01",
            // endDate: "2020-12-31"
        })
    };

    handleSearchShipment = async () => {
        console.log(this.state.locationSandeep, this.state.locationId);
        await this.setState({
            apiData: [],
            tmsExportData: [],
            tmsExportSummaryData: [],
            clientData: [],
            attendanceExportData: [],
            bmsExportData: [],
            vendorPaymentExportData: [],
            style: {}
        });
        await this.getDataMain();
        await this.getClientWiseData();
        await this.getTripStatusReport();
        await this.getTripStatusSummaryReport();
        await this.getTripStatsAttendanceReportNewMis();
        await this.getTripStatsVendorPaymentReportNewMis();
        await this.getTripStatsBmsReportNewMis();
        await this.setState({
            isData: true,
            style: {
                display: "none"
            }
        });
    };

    getvrTripSummaryByVendor = async (vendorID,LocationID,ClientName, Segment) => {
        const res = await axios.post(
            `${Config.hostName}/tms/vrTripSummaryByVendor`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: LocationID,
                clientName: ClientName,
                segment: Segment,
                vendorID: vendorID
            }
        ).then(async (response) => {
            await this.setState({
                tmsExportSummaryData: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };


    getvrGetTripStatsReport = async (vendorID,LocationID,ClientName, Segment) => {
        const res = await axios.post(
            `${Config.hostName}/tms/vrGetTripStatsReport`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                locationId: LocationID,
                clientName: ClientName,
                segment: Segment,
                vendorID: vendorID
            }
        ).then(async (response) => {
            await this.setState({
                tmsExportData: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    };

    downlaodVendorFile = async (vendorID,VendorName,LocationID,ClientName, Segment) => {
        console.log(vendorID,'rohan');
        if(vendorID){
            await this.getvrTripSummaryByVendor(vendorID,LocationID,ClientName, Segment);
            await this.getvrGetTripStatsReport(vendorID,LocationID,ClientName, Segment);
            const mainData = this.state.tmsExportData;

            const summaryData = this.state.tmsExportSummaryData;
            const wb = XLSX.utils.book_new();

            const ws = XLSX.utils.json_to_sheet(summaryData);
            XLSX.utils.book_append_sheet(wb, ws, "Summary");

            const uniqueVehicles = mainData.map((item) => item.VehicleNumber)
                .filter((value, index, self) => self.indexOf(value) === index);
    
            uniqueVehicles.map((uniqueVehicle) => {
                const indData = [];
                let sheetSeperator = "";
                mainData.filter((dataItem) => {
                    if (dataItem.VehicleNumber == uniqueVehicle) {
                        indData.push(dataItem);
                        sheetSeperator = dataItem.ServiceType;
                    }
                });
                const ws = XLSX.utils.json_to_sheet(indData);
                const sheetName = (sheetSeperator === "Adhoc") ? "A" : "R";
                XLSX.utils.book_append_sheet(wb, ws, `${sheetName}_${uniqueVehicle}`);
            });

            const nameToExcel = `${ClientName}_${Segment}_${this.state.startDate.slice(0,10)}_${this.state.endDate.slice(0,10)}_${VendorName}`;
            console.log(nameToExcel);
            XLSX.writeFile(wb, `${nameToExcel}.xlsx`);
        }else{
            alert('No records found');
        }
      
    };

    searchByTripIdApiCall = async () => {
        // searchTripsByTripIdNewMis
        const res = await axios.post(
            `${Config.hostName}/tms/searchTripsByTripIdNewMis`,
            {
                tripIDs: this.state.tripIDs
            }
        ).then(async (response) => {
            await this.setState({
                popupTableData: response.data.data
            })
        }).catch((error) => {
            alert(error)
        })
    }

    handleQuickSearchShipment = async () => {
        // 23925,23926,23927,24007
        console.log(this.state.tripIDs, typeof (this.state.tripIDs), this.state.tripIDs.length);
        if (this.state.tripIDs.length > 0) {
            await this.setState({
                popupTableData: [],
                popupTableDataKeys: [],
                style: {}
            });
            await this.searchByTripIdApiCall();
            const data = this.state.popupTableData;
            if (data.length > 0) {
                const keys = Object.keys(data[0]);
                console.log(this.state.tripIDs, this.state.popupTableData, keys);
                await this.toggleOpenTripModal();
                await this.setState({
                    popupTableDataKeys: keys,
                    style: {
                        display: "none"
                    }
                });
            }
            else {
                alert("No Trip Found");
                await this.setState({
                    style: {
                        display: "none"
                    }
                });
            }

        }
        else {
            alert("Please Enter TripId to search")
        }

    };

    handleExportExcel = async (e) => {
        e.preventDefault();
        const mainData = this.state.tmsExportData;
        const bmsExportData = this.state.bmsExportData;
        const vendorPaymentExportData = this.state.vendorPaymentExportData;
        const summaryData = this.state.tmsExportSummaryData;
        const attendanceData = this.state.attendanceExportData;
        const wb = XLSX.utils.book_new();
        const wa = XLSX.utils.json_to_sheet(attendanceData);
        XLSX.utils.book_append_sheet(wb, wa, "Attendance");
        //Trip Stats 
        const wt = XLSX.utils.json_to_sheet(this.state.apiData);
        XLSX.utils.book_append_sheet(wb, wt, "Trip Stats");
        const wv = XLSX.utils.json_to_sheet(vendorPaymentExportData);
        XLSX.utils.book_append_sheet(wb, wv, "Vendor Payments");
        const ws = XLSX.utils.json_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(wb, ws, "Summary");
        const wbm = XLSX.utils.json_to_sheet(bmsExportData);
        XLSX.utils.book_append_sheet(wb, wbm, "BMS");
        const uniqueVehicles = mainData.map((item) => item.VehicleNumber)
            .filter((value, index, self) => self.indexOf(value) === index);

        uniqueVehicles.map((uniqueVehicle) => {
            const indData = [];
            let sheetSeperator = "";
            mainData.filter((dataItem) => {
                if (dataItem.VehicleNumber == uniqueVehicle) {
                    indData.push(dataItem);
                    sheetSeperator = dataItem.ServiceType;
                }
            });
            const ws = XLSX.utils.json_to_sheet(indData);
            const sheetName = (sheetSeperator === "Adhoc") ? "A" : "R";
            XLSX.utils.book_append_sheet(wb, ws, `${sheetName}_${uniqueVehicle}`);
        });

        // const nameToExcel = "MIS_REPORT";
        const clientSelected = this.state.clientName;
        let locationSelected = "";
        const mapCity = this.state.unique_cities.map(city => {
            if (city.Locationid == this.state.locationId) {
                return locationSelected = city.name;
            }
        });
        const segmentSelected = this.state.segment;
        console.log(clientSelected, locationSelected, segmentSelected);
        const nameLocation = locationSelected ? `_${locationSelected}` : "";
        const nameSegment = segmentSelected ? `_${segmentSelected}` : "";
        const nameClient = clientSelected ? `_${clientSelected}` : "";
        const nameExcelTag = `${nameLocation}${nameClient}${nameSegment}`;
        const nameToExcel = "MIS_REPORT" + nameExcelTag;
        XLSX.writeFile(wb, `${nameToExcel}.xlsx`);
    };

    handleExportExcelTripSearch = async (e) => {
        e.preventDefault();
        await this.setState({
            style: {}
        });
        await this.getDataMainNew();
        await this.getClientWiseDataNew();
        await this.getTripStatusReportNew();
        await this.getTripStatusSummaryReportNew();
        await this.getTripStatsVendorPaymentReportNewMisNew();
        await this.getTripStatsBmsReportNewMisNew();
        await this.setState({
            style: {
                display: "none"
            }
        });
        const mainData = this.state.tmsExportDataNew;
        const bmsExportData = this.state.bmsExportDataNew;
        const vendorPaymentExportData = this.state.vendorPaymentExportDataNew;
        const summaryData = this.state.tmsExportSummaryDataNew;
        const wb = XLSX.utils.book_new();
        //Trip Stats 
        const wt = XLSX.utils.json_to_sheet(this.state.apiDataNew);
        XLSX.utils.book_append_sheet(wb, wt, "Trip Stats");
        const wv = XLSX.utils.json_to_sheet(vendorPaymentExportData);
        XLSX.utils.book_append_sheet(wb, wv, "Vendor Payments");
        const ws = XLSX.utils.json_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(wb, ws, "Summary");
        const wbm = XLSX.utils.json_to_sheet(bmsExportData);
        XLSX.utils.book_append_sheet(wb, wbm, "BMS");
        const uniqueVehicles = mainData.map((item) => item.VehicleNumber)
            .filter((value, index, self) => self.indexOf(value) === index);

        uniqueVehicles.map((uniqueVehicle) => {
            const indData = [];
            let sheetSeperator = "";
            mainData.filter((dataItem) => {
                if (dataItem.VehicleNumber == uniqueVehicle) {
                    indData.push(dataItem);
                    sheetSeperator = dataItem.ServiceType;
                }
            });
            const ws = XLSX.utils.json_to_sheet(indData);
            const sheetName = (sheetSeperator === "Adhoc") ? "A" : "R";
            XLSX.utils.book_append_sheet(wb, ws, `${sheetName}_${uniqueVehicle}`);
        });

        // const nameToExcel = "MIS_REPORT";
        const clientSelected = this.state.clientNameNew;
        let locationSelected = "";
        const mapCity = this.state.unique_cities.map(city => {
            if (city.Locationid == this.state.locationIdNew) {
                return locationSelected = city.name;
            }
        });
        const segmentSelected = this.state.segmentNew;
        console.log(clientSelected, locationSelected, segmentSelected);
        const nameLocation = locationSelected ? `_${locationSelected}` : "";
        const nameSegment = segmentSelected ? `_${segmentSelected}` : "";
        const nameClient = clientSelected ? `_${clientSelected}` : "";
        const nameExcelTag = `${nameLocation}${nameClient}${nameSegment}`;
        const nameToExcel = "MIS_REPORT" + nameExcelTag;
        XLSX.writeFile(wb, `${nameToExcel}.xlsx`);


    };

    handleChangeCity = async (e) => {
        e.preventDefault();

        await this.setState({ locationId: e.target.value, style: {}, uniqueSegmentsFilter: [], uniqueClientsFilter: [] });
        let { locationId } = this.state;
        await axios
            .post(`${Config.hostName}/unique/clients`, {
                locationId: locationId,
                clientName: ""
            })
            .then(res => {
                this.setState({
                    uniqueClientsFilter: res.data.data,
                    style: { display: 'none' }
                });
            })
            .catch(err => {
                alert(err);
            });
    };

    handleChangeClient = async event => {
        event.preventDefault();
        await this.setState({ style: {}, uniqueSegmentsFilter: [], clientName: event.target.value });
        let { clientName, locationId } = this.state;
        await axios.post(`${Config.hostName}/unique/segments`, {
            locationId: locationId,
            clientName: clientName
        })
            .then(async (res) => {
                await this.setState({
                    uniqueSegmentsFilter: res.data.data,
                    style: { display: 'none' }
                });
            })
            .catch(async (err) => {
                await this.setState({
                    style: { display: 'none' }
                });
                console.log(err)
                alert(err);
            });
    };

    handleVRChange = async (clientItem,e) => {
        e.preventDefault();
        console.log('rohan')
        await axios.post(`${Config.hostName}/tms/vrGetTripCntByVendor`, {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            locationId: clientItem.Locationid,
            clientName: clientItem.Clientname,
            segment: clientItem.Segment,
            vehicleNumber: this.state.vehicleNumber
        })
            .then(async (res) => {
                console.log(res.data);
                await this.setState({
                    unique_vrData: res.data.data,
                    vrModal: true,
                });
            })
            .catch(async (err) => {
                await this.setState({

                });
                console.log(err)
                alert(err);
            });
    };


    toggleCloseVRModal = async () => {
        await this.setState({
            vrModal: false,
        });
    }

    viewReqModalFunction = async (clientItem, serviceType) => {
        await this.setState({
            popupTableData: [],
            popupTableDataKeys: [],
            style: {}
        });
        const servicseType = serviceType;
        await this.setState({
            clientNameNew: clientItem.Clientname,
            locationIdNew: clientItem.Locationid,
            segmentNew: clientItem.Segment,
        });

        console.log(clientItem,'rohan');
        const res = await axios.post(
            `${Config.hostName}/tms/getTripsStatsViewDetailsTripCountNewMis`,
            {
                isProcessed: "",
                clientName: clientItem.Clientname,
                locationId: clientItem.Locationid,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                segment: clientItem.Segment,
                serviceType: servicseType,
                vehicleNumber: this.state.vehicleNumber
            }
        )
            .then(async (res) => {
                console.log(res.data.data);
                if (res.data.data.length > 0) {
                    this.setState({
                        popupTableData: res.data.data
                    });
                    const data = this.state.popupTableData;
                    console.log(data);
                    if (data.length > 0) {
                        const keys = Object.keys(data[0]);
                        await this.toggleOpenTripModal();
                        await this.setState({
                            popupTableDataKeys: keys,
                            style: {
                                display: "none"
                            }
                        });
                    }
                    else {
                        alert("No Data Found");
                        await this.setState({
                            style: {
                                display: "none"
                            }
                        });
                    }

                } else {
                    alert("No Data Found");
                    await this.setState({
                        style: {
                            display: "none"
                        }
                    });
                }
            }).catch((err) => {
                alert(err);
            });

        // if (viewReqData.length > 0) {
        //     const selectedRow = viewReqData[0]
        //     const billLinks = JSON.parse(selectedRow.Jdata).billLink
        //     await this.setState({

        //     })
        //     await this.toggleOpenViewRequestsModal();
        // }
        // else {
        //     await this.setState({
        //         style: { display: "none" },
        //     })
        //     await this.toggleOpenViewRequestsModal();
        // }

    }



    toggleOpenTripModal = async () => {
        await this.setState({
            toggleTripModal: true
        })
    }

    toggleCloseTripModal = async () => {
        await this.setState({
            toggleTripModal: false
        })
    }

    onClickOpenViewModal = async (clientItem, serviceType) => {
        await this.viewReqModalFunction(clientItem, serviceType);
    };

    render() {
        const { isData, style, modal, widgetData, uniqueClients, clientData, requiredClientData, toggleTripModal, popupTableDataKeys, popupTableData } = this.state;
        const defaultColDef = {
            flex: 1,
            minWidth: 100,
            // height: 100,
            resizable: true
        }
        return (
            <div style={{ height: "82vh" }}>
                <div id="cover-spin" style={style}> </div>

                <div className="container-fluid" style={{ marginBottom: "5vh" }}>
                    <div className="col-xl-12">

                        <nav
                            className="navbar navbar-light"
                            style={{ backgroundColor: "none" }}
                        >

                            <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="tripId"
                                            value={this.state.tripIDs}
                                            // style={{ borderColor: "#FF4848" }}
                                            style={{
                                                fontFamily: 'Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',
                                                fontVariantNumber: 'tabular-nums'
                                            }}
                                            id="tripId"
                                            placeholder="TripIDs"
                                            onChange={(e) => {
                                                this.setState({ tripIDs: e.target.value })
                                            }}
                                        >

                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <button
                                        type="submit"
                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                        onClick={this.handleQuickSearchShipment}
                                    >
                                        <i
                                            className="fa fa-search"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Quick View
                                    </button>
                                </div>



                                <div className="col-sm-2 ml-auto">
                                    {this.state.tmsExportData.length > 0 && (
                                        <button
                                            className={
                                                (!this.state.tmsExportData.length > 0 &&
                                                    !this.state.tmsExportData.length > 0) ?
                                                    'btn btn-danger w-100 overflow-hidden mb-3 px-auto disabled' :
                                                    'btn btn-danger w-100 overflow-hidden mb-3 px-auto'}
                                            onClick={this.handleExportExcel}
                                            disabled={!this.state.tmsExportData && !this.state.tmsExportData.length > 0}
                                        >
                                            <i
                                                className="fa fa-download"
                                                style={{ marginRight: "5px" }}
                                            />
                                            Export
                                        </button>
                                    )}
                                </div>

                            </div>
                            <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="dateRange"
                                            value={this.state.dateRangeSelected}
                                            id="dateRange"
                                            onChange={this.handleChangeDateRange}
                                        >
                                            <option className="date-range-tms-tripcount" value={""} disabled>
                                                Select Date
                                            </option>

                                            {this.state.uniqueDateRanges.map((eachItem, i) => (
                                                <option value={i} key={i}>
                                                    {eachItem.title}
                                                </option>
                                            ))}
                                            <option value={2222}>
                                                {/* <Button onClick={this.toggle}> */}
                                                Custom Date Range
                                                {/* </Button> */}
                                            </option>
                                            <div>
                                                <Modal
                                                    isOpen={modal}
                                                // toggle={this.toggle}
                                                >
                                                    <ModalHeader>Select Start and End date</ModalHeader>
                                                    <ModalBody>
                                                        <div className="row" style={{ minHeight: "1px" }}>
                                                            <div className="col float-left">
                                                                <div
                                                                    className="cal-datepicker"
                                                                    style={{ height: "100%" }}
                                                                >
                                                                    <div
                                                                        className="datepicker-here text-center"
                                                                        style={{ height: "100%" }}
                                                                        data-language="en"
                                                                    >
                                                                        <DatePicker
                                                                            height="400"
                                                                            placeholderText="Select Start Date"
                                                                            id="startDateInput"
                                                                            inline
                                                                            selectsRange
                                                                            selected={this.state.startDate}
                                                                            startDate={this.state.startDate}
                                                                            onChange={(date) => {
                                                                                this.setState({
                                                                                    startDate: date,
                                                                                });
                                                                            }}
                                                                            dateFormat="yyyy-MM-dd"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col float-left">
                                                                <div className="cal-datepicker">
                                                                    <div
                                                                        className="datepicker-here text-center"
                                                                        data-language="en"
                                                                    >
                                                                        <DatePicker
                                                                            height="400"
                                                                            id="endDateInput"
                                                                            inline
                                                                            placeholderText="Select End Date"
                                                                            selected={this.state.endDate}
                                                                            onChange={(date) => {
                                                                                this.setState({
                                                                                    endDate: date,
                                                                                });
                                                                            }}
                                                                            dateFormat="yyyy-MM-dd"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="info"
                                                            onClick={this.toggleConfirmModal}

                                                        >Confirm</Button>{" "}
                                                        <Button
                                                            color="danger"
                                                            onClick={this.toggleCloseModal}
                                                        >
                                                            Close
                                                        </Button>{" "}
                                                    </ModalFooter>
                                                </Modal>
                                            </div>
                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="city"
                                            value={this.state.locationId}
                                            // style={{ borderColor: "#FF4848" }}
                                            id="city"
                                            onChange={this.handleChangeCity}
                                        >
                                            <option value={""} disabled>
                                                Select City
                                            </option>

                                            {this.state.unique_cities.map((eachItem) => (
                                                <option value={eachItem.Locationid}>{eachItem.name}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="clientName"
                                            value={this.state.clientName}
                                            id="clientName"
                                            onChange={this.handleChangeClient}
                                        >
                                            <option value={""} disabled selected>Select Client</option>

                                            {this.state.uniqueClientsFilter.map(client => (
                                                <option value={client.ClientName}>
                                                    {client.ClientName}
                                                </option>
                                            ))}

                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="routeNo"
                                            value={this.state.segment}
                                            id="routeNo"
                                            onChange={event => {
                                                this.setState({ segment: event.target.value });
                                            }}
                                        >
                                            <option value={""} disabled selected>Select Segment</option>

                                            {this.state.uniqueSegmentsFilter.length > 0 && this.state.uniqueSegmentsFilter.map(segment => (
                                                <option value={segment.Segment}>
                                                    {segment.Segment}
                                                </option>
                                            ))}

                                            {/* <option value={0}>All Segments</option> */}
                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="vehicleNumber"
                                            value={this.state.vehicleNumber}
                                            // style={{ borderColor: "#FF4848" }}
                                            id="vehicleNumber"
                                            placeholder="Vehicle"
                                            style={{
                                                fontFamily: 'Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',
                                                fontVariantNumber: 'tabular-nums'
                                            }}
                                            onChange={(e) => {
                                                this.setState({ vehicleNumber: e.target.value })
                                            }}
                                        >

                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <button
                                        type="submit"
                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                        onClick={this.handleSearchShipment}
                                    >
                                        <i
                                            className="fa fa-search"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Search
                                    </button>
                                </div>


                            </div>

                        </nav>

                        {isData &&
                            <div>
                                <div className="container-fluid">
                                    <div className="row">
                                        {this.state.statusKeys.length > 0 && this.state.statusKeys.map((key, keyValue) => {
                                            return <div key={keyValue} className="col-sm-3 custom-card-widget-main-tms-tripcount" >

                                                <div
                                                    id={key} value={key}
                                                    className="card custom-card-widget-invoice-tms-tripcount"
                                                >
                                                    <div className="row" style={{ height: "100%" }}>
                                                        <div className="col-sm-12" style={{ height: "100%" }}>
                                                            {widgetData.length > 0 && widgetData.map((statusDataItem) => {
                                                                return <div className="trip-count-widget-main-container" style={{ height: "100%" }}>
                                                                    <div className="trip-count-widget-left-side">
                                                                        <div className="trip-count-widget-left-side-top">
                                                                            <img className="trip-count-widget-left-side-top-img" src={key == "Regular" ? RegularImage : AdhocImage} width="100" height="100" alt={key} />
                                                                        </div>
                                                                        <div className="trip-count-widget-left-side-bottom">
                                                                            <h6 className="text-secondary" style={{ textAlign: "center", fontWeight: "bolder", marginTop: "-5%", color: "#8b8c8c" }}>{key}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="trip-count-widget-right-side" style={{ color: (key == "Regular") ? "#2872ed" : "#2dbce8" }}>
                                                                        <span className="" style={{ margin: '-20px 1px', float: 'right', width: '28%', fontSize: "1.2rem", fontFamily: "Arial, Helvetica, sans-serif", fontWeight: "bold", color: "#8F00FF" }}>{key == "Regular" ? statusDataItem.R_yettoBill : statusDataItem.A_yettoBill}</span> <br />
                                                                        <span className="trip-count-widget-right-side-number">{key == "Regular" ? statusDataItem.R_TotalTrips : statusDataItem.A_TotalTrips}</span> <br />
                                                                        <span className="trip-count-widget-right-side-number-red">{key == "Regular" ? `(${statusDataItem.R_NotProcessedTrips})` : `(${statusDataItem.A_NotProcessedTrips})`}</span>
                                                                    </div>
                                                                </div>
                                                            })}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        })}
                                    </div>
                                </div>
                            </div>
                        }

                        {isData &&
                            <div>
                                <div className="container-fluid">
                                    <div className="row tms-trip-count-client-data-main-container">
                                        {requiredClientData.length > 0 && requiredClientData.map(client => {
                                            return (
                                                <div className="card tms-trip-count-client-data-main">
                                                    <div className="col-sm-12">
                                                        <div className="tms-trip-count-client-data-clientNameMain">
                                                            {/* <div className="tms-trip-count-client-data-clientLogo">
                                                                <img src={AmazonImage} alt="amazon" height="40" width="40" />
                                                            </div> */}
                                                            <div className="tms-trip-count-client-data-clientName">
                                                                {client.clientName}
                                                            </div>
                                                        </div>
                                                        <div className="row tms-trip-count-client-data-segments-main-container">
                                                            {client.data.length && client.data.map(clientItem => {
                                                                return (
                                                                    <div key={clientItem} className="col-sm-3 tms-trip-count-client-data-segments-main">
                                                                        <div className="tms-trip-count-client-data-segments-top">
                                                                            <div className="tms-trip-count-client-data-segments-top-left">
                                                                                <Link to={{
                                                                                    pathname: '/admin/tms/trip-count/view', state: {
                                                                                        isProcessed: "",
                                                                                        clientName: clientItem.Clientname,
                                                                                        locationId: clientItem.Locationid,
                                                                                        startDate: this.state.startDate,
                                                                                        endDate: this.state.endDate,
                                                                                        segment: clientItem.Segment,
                                                                                        serviceType: "Regular",
                                                                                        vehicleNumber: this.state.vehicleNumber
                                                                                    }
                                                                                }}>
                                                                                    <span className="tms-trip-count-client-data-segments-top-left-regularTrips">{clientItem.R_TotalTrips}</span>
                                                                                </Link>
                                                                                <span className="" style={{ margin: '-8px 1px', float: 'right', width: '29%', fontSize: "1.2rem", fontFamily: "Arial, Helvetica, sans-serif", fontWeight: "bold", color: "#8F00FF" }}> {clientItem.R_yettoBill}</span> <br />

                                                                                <Link to={{
                                                                                    pathname: '/admin/tms/trip-count/view', state: {
                                                                                        isProcessed: 0,
                                                                                        clientName: clientItem.Clientname,
                                                                                        locationId: clientItem.Locationid,
                                                                                        startDate: this.state.startDate,
                                                                                        endDate: this.state.endDate,
                                                                                        segment: clientItem.Segment,
                                                                                        serviceType: "Regular",
                                                                                        vehicleNumber: this.state.vehicleNumber
                                                                                    }
                                                                                }}>
                                                                                    <span className="tms-trip-count-client-data-segments-top-left-regularTripsNotProcessed">({clientItem.R_NotProcessedTrips})</span>
                                                                                </Link>

                                                                                <span className="vr" onClick={(e) => { this.handleVRChange(clientItem,e) }} style={{ cursor: 'pointer', float: 'right', width: '29%', fontSize: "1.0rem", fontFamily: "Arial, Helvetica, sans-serif", fontWeight: "bold", color: "rgb(255 0 18)" }}>VR</span> <br />

                                                                                <div style={{ textAlign: "center" }} >
                                                                                    <RemoveRedEyeIcon onClick={() => this.onClickOpenViewModal(clientItem, 'Regular')} />

                                                                                </div>
                                                                            </div>
                                                                            <div className="tms-trip-count-client-data-segments-top-right">
                                                                                <Link to={{
                                                                                    pathname: '/admin/tms/trip-count/view', state: {
                                                                                        isProcessed: "",
                                                                                        clientName: clientItem.Clientname,
                                                                                        locationId: clientItem.Locationid,
                                                                                        startDate: this.state.startDate,
                                                                                        endDate: this.state.endDate,
                                                                                        segment: clientItem.Segment,
                                                                                        serviceType: "Adhoc",
                                                                                        vehicleNumber: this.state.vehicleNumber
                                                                                    }
                                                                                }}>
                                                                                    <span className="tms-trip-count-client-data-segments-top-left-adhocTrips">{clientItem.A_TotalTrips}</span>
                                                                                </Link>
                                                                                <span className="" style={{ margin: '-8px 1px', float: 'right', width: '29%', fontSize: "1.2rem", fontFamily: "Arial, Helvetica, sans-serif", fontWeight: "bold", color: "#8F00FF" }}> {clientItem.A_yettoBill}</span> <br />

                                                                                <Link to={{
                                                                                    pathname: '/admin/tms/trip-count/view', state: {
                                                                                        isProcessed: 0,
                                                                                        clientName: clientItem.Clientname,
                                                                                        locationId: clientItem.Locationid,
                                                                                        startDate: this.state.startDate,
                                                                                        endDate: this.state.endDate,
                                                                                        segment: clientItem.Segment,
                                                                                        serviceType: "Adhoc",
                                                                                        vehicleNumber: this.state.vehicleNumber
                                                                                    }
                                                                                }}>
                                                                                    <span className="tms-trip-count-client-data-segments-top-left-adhocTripsNotProcessed" >
                                                                                        ({clientItem.A_NotProcessedTrips})
                                                                                    </span>
                                                                                </Link>

                                                                                <div style={{ textAlign: "center" }} >
                                                                                    <RemoveRedEyeIcon onClick={() => this.onClickOpenViewModal(clientItem, 'Adhoc')} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tms-trip-count-client-data-segments-bottom">
                                                                            <span className="tms-trip-count-client-data-segments-bottom-segmentName">Segment: {clientItem.Segment}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        }

                        <div>
                            {toggleTripModal && <Modal
                                size="lg"
                                style={{ maxWidth: '1600px', width: '80%' }}
                                centered={true}
                                isOpen={toggleTripModal}
                            >
                                <ModalFooter>

                                    <div className="col-sm-3">
                                        <span className="bms-view-req-modal-header-label">Quick View</span>
                                    </div>

                                    <div className="col-sm-3 ml-auto">
                                        <div className="row">


                                            <div className="col-sm-6">


                                                <button
                                                    className={
                                                        (!this.state.popupTableData.length > 0 &&
                                                            !this.state.popupTableData.length > 0) ?
                                                            'btn btn-danger w-100 overflow-hidden mb-3 px-auto disabled' :
                                                            'btn btn-danger w-100 overflow-hidden mb-3 px-auto'}
                                                    onClick={this.handleExportExcelTripSearch}
                                                    disabled={!this.state.popupTableData && !this.state.popupTableData.length > 0}
                                                >
                                                    <i
                                                        className="fa fa-download"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    Exports
                                                </button>
                                            </div>

                                            <div className="col-sm-6">
                                                <Button
                                                    color="danger"
                                                    onClick={this.toggleCloseTripModal}
                                                >
                                                    <CloseIcon />
                                                </Button>{" "}
                                            </div>

                                        </div>
                                    </div>

                                </ModalFooter>
                                <ModalBody>
                                    <div className="bms-view-req-modal-body">
                                        <div>
                                            <Table responsive className="styled-table">
                                                <thead>
                                                    <tr className="styled-table-header-row">
                                                        {popupTableDataKeys.map(headItem => {
                                                            return <th>{headItem}</th>
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {popupTableData.map(item =>
                                                        <tr className="styled-table-row">
                                                            {popupTableDataKeys.map(hkey => {
                                                                return <td>{item[hkey] ? item[hkey] : "-"}</td>
                                                            })}
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </Table>
                                        </div>
                                    </div>
                                </ModalBody>

                            </Modal>
                            }
                        </div>


                        <div>
                            {toggleTripModal && <Modal
                                size="lg"
                                style={{ maxWidth: '1600px', width: '80%' }}
                                centered={true}
                                isOpen={toggleTripModal}
                            >
                                <ModalFooter>

                                    <div className="col-sm-3">
                                        <span className="bms-view-req-modal-header-label">Quick View</span>
                                    </div>

                                    <div className="col-sm-6 ml-auto">
                                        <div className="row">

                                            <div className="col-sm-4">
                                                <Button
                                                    color="primary"

                                                >
                                                    Process
                                                </Button>{" "}
                                            </div>
                                            <div className="col-sm-4">

                                                <button
                                                    className={
                                                        (!this.state.popupTableData.length > 0 &&
                                                            !this.state.popupTableData.length > 0) ?
                                                            'btn btn-danger w-100 overflow-hidden mb-3 px-auto disabled' :
                                                            'btn btn-danger w-100 overflow-hidden mb-3 px-auto'}
                                                    onClick={this.handleExportExcelTripSearch}
                                                    disabled={!this.state.popupTableData && !this.state.popupTableData.length > 0}
                                                >
                                                    <i
                                                        className="fa fa-download"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    Export
                                                </button>
                                            </div>

                                            <div className="col-sm-4">
                                                <Button
                                                    color="danger"
                                                    onClick={this.toggleCloseTripModal}
                                                >
                                                    <CloseIcon />
                                                </Button>{" "}
                                            </div>

                                        </div>
                                    </div>

                                </ModalFooter>
                                <ModalBody>
                                    <div className="bms-view-req-modal-body">
                                        <div>
                                            <Table responsive className="styled-table">
                                                <thead>
                                                    <tr className="styled-table-header-row">
                                                        {popupTableDataKeys.map(headItem => {
                                                            if (headItem == "TripDate") {
                                                                return <th style={{ padding: "2px 28px" }} >{headItem}</th>
                                                            } else {
                                                                return <th>{headItem}</th>
                                                            }
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {popupTableData.map(item =>
                                                        <tr className="styled-table-row">
                                                            {popupTableDataKeys.map(hkey => {
                                                                return <td>{item[hkey] ? item[hkey] : "-"}</td>
                                                            })}
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </Table>
                                        </div>
                                    </div>
                                </ModalBody>

                            </Modal>
                            }
                        </div>

                        <div>
                            <Modal
                                size="lg"
                                style={{ maxWidth: '1600px', width: '80%' }}
                                centered={true}
                                isOpen={this.state.vrModal}
                            >
                                <ModalFooter>
                                <div className="col-sm-1">
                                </div>

                                    <div className="col-sm-2">
                                        <span className="bms-view-req-modal-header-label" style={{ fontWeight: '400' }}>
                                            <b style={{color:'red'}}> Start Date </b>: { moment(this.state.startDate).format("YYYY-MM-DD")} &nbsp;&nbsp;&nbsp;
                                            <b style={{color:'red'}}> End Date </b>: {moment(this.state.endDate).format("YYYY-MM-DD")}</span>
                                    </div>

                                    <div className="col-sm-3 ml-auto">
                                        <div className="row">


                                            <div className="col-sm-6">



                                            </div>

                                            <div className="col-sm-6">
                                                <Button
                                                    color="danger"
                                                    onClick={this.toggleCloseVRModal}
                                                >
                                                    <CloseIcon />
                                                </Button>{" "}
                                            </div>

                                        </div>
                                    </div>

                                </ModalFooter>
                                <ModalBody>
                                    <div className="bms-view-req-modal-body">
                                        <div
                                            className="ag-theme-balham"
                                            style={{
                                                height: '100%',
                                                width: '100%',

                                            }}
                                        >
                                            {this.state.unique_vrData.length > 0 && (
                                                <AgGridReact
                                                    columnDefs={this.state.columnDefs}
                                                    rowData={this.state.unique_vrData}
                                                    defaultColDef={defaultColDef}
                                                    onGridReady={this.onGridReady}
                                                    // rowStyle={{ background: 'yellow' }}
                                                    enableCellTextSelection={true}
                                                    gridAutoHeight={true}
                                                    domLayout={this.state.domLayout}
                                                    rowHeight={45}
                                                    // enableCellTextSelection={true}
                                                    suppressAutoSize={true}
                                                // getRowHeight={getRowHeight}
                                                // getRowStyle={(params) => {
                                                //     return {
                                                //         background: (params.data.IsVerified === true) ? "#bbf58c" :
                                                //             (params.data.IsVerified === false) ? "#f7a681" :
                                                //                 "white"
                                                //     }
                                                // }}
                                                >
                                                </AgGridReact>
                                            )}
                                        </div>
                                    </div>
                                </ModalBody>

                            </Modal>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
