import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import './layout.css';
import Select from '@mui/material/Select';
import { Snackbar, Paper, Modal, Box, Chip, Collapse, Alert, AlertTitle, Toolbar, AppBar, Dialog, Slide, Tooltip, IconButton, Grid, Container, Typography, TextField, Button, FormControl, MenuItem, InputLabel } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlusIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';

import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "./excel.jpg";
import FileDownloadOffTwoToneIcon from '@mui/icons-material/FileDownloadOffTwoTone';
import MUIDataTable from "mui-datatables";
import { Description } from "@material-ui/icons";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import moment from "moment";
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const style4 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style5 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: "auto",
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "806px",
        },
    },
};

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

const options = {
    filterType: 'multiselect',
    // filterOptions: {fullWidth: true},
    selectableRows: 'none',
    setRowProps: row => {
        if (row[0] === "New") {
            return {
                style: { background: "snow" }
            };
        }
    },
    draggableColumns: { enabled: true },
    pagination: true,
    // resizableColumns: true,
    // responsive: "standard",
    fixedHeader: false,
    downloadOptions: {
        filterOptions: {
            useDisplayedRowsOnly: true,
        }
    }
};



class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            selectedDate: new Date(),
            heading: 'Update',
            unique_states: [],
            stateValue: '',
            unique_clientNames: [],
            clientID: '',
            alertSnackbar: false,
            clientName: '',
            clientNameState: 'newClientAdd',
            clientNameDropDown: '',
            billingName: '',
            address: '',
            gst: '',
            submitButton: '',
            successText: '',
            alertState: false,
            uniqueDateRanges: [],
            dateRangeSelected: '',
            startDate: '',
            endDate: '',
            downloadFiles: [],
            openDownloadFilesModal: false,
            projectName: '',
            fullScreenModal: false,
            invxrefID: '',
            attachements: [{ Description: '', DocLink: '' }],
            projectNameAdd: '',
            totalexpense: '',
            vertical: 'top',
            horizontal: 'center',
            snackBar: false,
            commentColor: '',
            comment: '',
            invoiceStatusDD: [],
            CommentHistory: [],
            rowData: [],
            columns: [
                {
                    name: "clientName",
                    label: "Client Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "billingMonth",
                    label: "Billing Month",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "zonalManager",
                    label: "Zonal Manager",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "projectName",
                    label: "Project Name",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            const projectName = value;
                            const newArray = projectName.split(',');
                            return (
                                <>
                                    {
                                        newArray.map((e) => (
                                            <>
                                                {e} <br />                                    </>
                                        ))
                                    }
                                </>
                            )
                        }
                    }
                },
                {
                    name: "totalexpense",
                    label: "Total Expense",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <>
                                    {
                                        value ? value : 'N/A'
                                    }
                                </>
                            )
                        }
                    }
                },
                {
                    name: "Status",
                    label: "Status",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const statusName = value;
                            var colorNew = '';
                            return (
                                <>
                                    <FormControl sx={{ m: 1 }} style={{ width: '38ch' }} fullWidth>
                                        <InputLabel id="viewInvoiceMonth">Select Status</InputLabel>
                                        <Select
                                            labelId="viewInvoiceMonth"
                                            id="viewInvoiceMonth2"
                                            value={value.toLowerCase()}
                                            onChange={(e) =>
                                                this.handleChangeBillingStatus(
                                                    tableMeta.rowIndex, e.target.value
                                                )
                                            }
                                            label="Select Status"
                                            displayEmpty

                                        >
                                            {this.state.invoiceStatusDD.map((eachItem, key) => (
                                                <MenuItem value={eachItem.Status.toLowerCase()} key={key}>
                                                    {eachItem.Status}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </>
                            )
                        }
                    }
                },
                {
                    name: "createddate",
                    label: "Created Date",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRender: (value) => {
                            return (
                                // moment(value).format("DD-MM-YYYY hh:mm:ss")
                                moment(value).format("DD-MM-YYYY")
                            )
                        }
                    }
                },
                {
                    name: "misAttachments",
                    label: "Attachments",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value != '') {
                                return (
                                    <Tooltip title="Download">
                                        <Button
                                            // component={RouterLink}
                                            size="small"
                                            // to={{
                                            //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                                            //   orders: this.state.orders
                                            // }}
                                            onClick={(e) => this.handleDownloadAttachments(value, e)}

                                            style={{ color: 'black' }}
                                        // variant="outlined"
                                        >
                                            <FileCopyIcon />
                                        </Button>
                                    </Tooltip>
                                )
                            } else {
                                return (
                                    <Tooltip title="Download">
                                        <Button
                                            size="small"

                                            style={{ color: 'black' }}
                                        >
                                            <FolderOffOutlinedIcon />
                                        </Button>
                                    </Tooltip>
                                )
                            }


                        }
                    }
                },
                {
                    name: "invxrefID",
                    label: "Actions",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <div style={{ display: 'flex' }}>
                                    <Tooltip title="Comments">
                                        <Button
                                            size="small"
                                            onClick={(e) => this.handleOpenComentModal(dataIndex, e)}
                                            style={{ color: 'black' }}
                                        >
                                            <MapsUgcIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Update">
                                        <Button
                                            size="small"
                                            onClick={(e) => this.handleOpenStatuslModal(dataIndex, e)}
                                            style={{ color: 'black' }}
                                        >
                                            <EditIcon />

                                        </Button>
                                    </Tooltip>
                                </div>
                            )

                        }
                    }
                },
            ],


        };
        this.validator = new SimpleReactValidator();
    }

    delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }


    getNewRequests = async () => {
        // console.log(this.state.billingMonth.replaceAll(' ', ''));

        const res = await axios.post(
            `${Config.hostName}/billing/getMISGetDetails`,
            {
                billingMonth: this.state.dateRangeSelected.replaceAll(' ', '')
                // billingMonth: "JUN2022"
            }
        )
            .then(async (res) => {
                console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        rowData: res.data.data.map(eachItem => {
                            return {
                                CommentHistory: eachItem.CommentHistory,
                                Status: eachItem.Status,
                                billingMonth: eachItem.billingMonth,
                                zonalManager : eachItem.ZonalManager,
                                clientName: eachItem.clientName,
                                invxrefID: eachItem.invxrefID,
                                misAttachments: eachItem.misAttachments,
                                projectName: eachItem.projectName,
                                totalexpense: eachItem.totalexpense,
                                createddate: eachItem.createddate
                            }
                        })
                    });
                } else {
                    await this.setState({
                        alertSnackbar: true,
                        rowData: []
                    })
                    // console.log('No Recoords Found... !!');

                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    handleAlertCloseSnackBar = async () => {
        await this.setState({
            alertSnackbar: false
        })
    }

    getUniqueDateRanges = async () => {
        await axios
            .post(
                `${Config.hostName}/bms/dateRangeDropDownOnlyMonth`, {
                ccxref: 384
            }
            )
            .then(async (res) => {
                // console.log(res.data.data[0].billingMonth);
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                        dateRangeSelected: res.data.data[0].billingMonth
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };

    handleChangeDate = async (e) => {
        if (e.target.value != 0) {
            let value = e.target.value;
            if (value == 2222) {
                await this.setState({
                    dateRangeSelected: e.target.value,
                    startDate: "",
                    endDate: "",
                });
                await this.toggle();
            } else {
                this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                    if (eachDate.billingMonth === value) {
                        await this.setState({
                            dateRangeSelected: eachDate.billingMonth,
                            startDate: eachDate.startDate,
                            endDate: eachDate.endDate,
                        });
                        await this.getNewRequests();
                    }
                });
            }
        }
    }


    handleDownloadAttachments = async (value, e) => {
        // let linkArray = ["https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1657438471675_Book1.xlsx", "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1657392306013_AdharCard_Sample.jpg", "https://pdf-ptl-store.s3.ap-south-1.amazonaws.com/uploads/JUN2022_Smart%20Ship%20innovative%20Solutions%20Private%20Limited_8.pdf"]
        await this.setState({
            downloadFiles: JSON.parse(value),
            openDownloadFilesModal: true
        })
    }

    handleCloseDownloadFilesModal = async () => {
        await this.setState({
            downloadFiles: [],
            openDownloadFilesModal: false
        })
    }

    handleOpenStatuslModal = async (index, e) => {
        if (this.state.rowData[index].totalexpense != '') {
            await this.setState({
                totalexpense: this.state.rowData[index].totalexpense
            })
        }
        await this.setState({
            projectNameAdd: this.state.rowData[index].projectName
        })
        if (this.state.rowData[index].misAttachments != '') {
            const newaaray = JSON.parse(this.state.rowData[index].misAttachments);
            const list = newaaray;

            newaaray.map((e, key) => {
                let splitArray = e.Description.split('_');
                let lastElement = splitArray[splitArray.length - 1];
                console.log(lastElement);
                list[key]['Description'] = lastElement;
                list[key]['DocLink'] = e.DocLink;
            })
            await this.setState({
                attachements: list
            })
        } else {
            this.setState({
                attachements: [{ Description: "", DocLink: '' }],
            })
        }

        await this.setState({
            invxrefID: this.state.rowData[index].invxrefID,
            fullScreenModal: true
        })
    }

    handleCloseFullModal = async () => {
        await this.setState({
            invxrefID: "",
            fullScreenModal: false
        })
    }

    handleOpenComentModal = async (index, e) => {
        console.log(this.state.rowData[index], 'rohan');
        await this.setState({
            CommentHistory: JSON.parse(this.state.rowData[index].CommentHistory),
            openCommentModal: true,
            commentColor: "",
            invxrefID: this.state.rowData[index].invxrefID
        })
    }

    handleCloseComentModal = async () => {
        await this.setState({
            openCommentModal: false
        })
    }

    handleCloseSnackBar = async () => {
        await this.setState({
            snackBar: false,
        })
    }

    changeComments = async (e) => {
        e.preventDefault();
        let final = e.target.value;
        final = await final.replace(/[\W_]+/g, " ");
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;
        let error = format.test(final);
        if (error) {
            this.setState({
                commentColor: 'red'
            })
        }
        await this.setState({
            comment: final
        })
    }

    handleSubmitComment = async (e) => {
        e.preventDefault();

        await this.setState({
            snackBar: true,
        })
        const res = await axios.post(
            `${Config.hostName}/billing/updateContent`,
            {
                contentID: "9",
                content: `{"billingmonth":"${this.state.dateRangeSelected.replaceAll(' ', '')}","invxrefID":${this.state.invxrefID},"userID":"${this.props.auth.user.USERID}","notes":"${this.state.comment}"}`,
                invID: "",
            }
        )
            .then(async (res) => {
                console.log(res.data, 'Status');
                this.getNewRequests();
                await this.delay(300);
                this.setState({
                    comment: "",
                    openCommentModal: false,
                    invxrefID: '',
                })
            })
            .catch((error) => {
                alert(error);
            });

    }




    async componentDidMount() {
        this.setState({
            style: {}
        });
        await this.getUniqueDateRanges();
        await this.getStatusEagleView();
        await this.getNewRequests();

        this.setState({
            style: {
                display: "none"
            }
        });
    }

    addNewAttachment = async (e) => {

        if (this.state.attachements.length < 10) {
            await this.setState({
                attachements: [...this.state.attachements, { Description: ``, DocLink: '' },
                ]
            })
        }

    }

    removeNewAttachment = async (index, e) => {
        if (index != 0) {
            const list = this.state.attachements;
            list.splice(index, 1);
            await this.setState({
                attachements: list,
            })
        } else {
            const list = this.state.attachements;
            list[index]['Description'] = ``;
            list[index]['DocLink'] = '';
            await this.setState({
                attachements: list,
            })
        }
    }

    uploadFileAndGetUrl = async (formData) => {
        // console.log("uploading formdata")
        const fileData = await axios({
            method: "POST",
            url: `${Config.hostName}/unique/upload/any-file-name`,
            timeout: 3 * 60 * 1000,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            data: formData,
        });
        return fileData;
    }


    billLinkUploadFile = async (file, name) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = async (e) => {
            await this.setState({
                selectedFile: [reader.result],
            });
        }
        // console.log(url); // Would see a path?
        const fileType = file.type.substring(0, 5);
        await this.setState({ selectedFile: file, selectedFileType: fileType });
        const formData = new FormData();
        console.log(this.state.selectedFile.name.split('.').pop().toLowerCase());
        console.log(this.state.selectedFileType);
        formData.append(
            "media",
            this.state.selectedFile,
            `Mis_${this.state.dateRangeSelected}_${this.state.projectNameAdd.replaceAll(' ', '')}_${name}.${this.state.selectedFile.name.split('.').pop().toLowerCase()}`
        );
        // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
        // console.log(formData, "formData", fileType);
        const res = await this.uploadFileAndGetUrl(formData);
        const urlFromAws = res.data.url;
        return urlFromAws;
        // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
        // await this.getPanCardUrl(urlFromAws, file.name);


        // console.log(this.state.attachments, "data")
    }

    handleChangeAttachment = async (index, e) => {

        const list = this.state.attachements;
        if (e.target.name == 'Description') {
            if (list[index]['DocLink'] != '') {
                alert('First Delete the Uploaded Attachment!!');
            } else {
                list[index][e.target.name] = e.target.value;
            }
        } else {
            if (list[index]['Description'] != '') {
                const filesObj = e.target.files;
                const filesObjKeys = Object.keys(filesObj);
                console.log(filesObjKeys);
                const res = await this.billLinkUploadFile(filesObj[0], list[index]['Description']);
                console.log(res);
                list[index]['DocLink'] = res;
            } else {
                alert("Enter Document Name First");
            }
        }
        await this.setState({
            attachements: list,
        })
    }

    getStatusEagleView = async () => {
        const res = await axios
            .post(`${Config.hostName}/billing/invoiceStatusDD`, {

            })
            .then(async (res) => {
                await this.setState({
                    invoiceStatusDD: res.data.data
                })
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                    style: { display: "none" },

                })
            });
    }

    handleChangeBillingStatus = async (data, status) => {
        await this.setState({
            style: {},
        });

        if (window.confirm("Are you sure you want to update the status?")) {
            // Save it!
            const userData = localStorage.getItem('persist:root');
            const auth = JSON.parse(userData).auth;

            let StatusID = '';
            await this.state.invoiceStatusDD.map((each) => {
                if (each.Status.toLowerCase() == status.toLowerCase()) {
                    StatusID = each.StatusID;
                    return 1;
                }
            })

            console.log(StatusID);
            console.log(this.state.dateRangeSelected);
            console.log(JSON.parse(auth).USERID);
            console.log(this.state.rowData[data].invxrefID);


            const res = await axios
                .post(`${Config.hostName}/billing/updateMISDetails`, {
                    contentID: "8",
                    content1: `{"billingmonth":"${this.state.dateRangeSelected.replaceAll(' ', '')}","invxrefID":${this.state.rowData[data].invxrefID},"userID":"${this.props.auth.user.USERID}","statusID":"${StatusID}"}`,
                    content2: '',
                })
                .then(async (res) => {
                    if (res.data.success == true) {
                        console.log(res);
                        await this.setState({
                            successText: 'Status Updated',
                            alertState: true,
                        });
                    } else {
                        alert(res.data.message);
                    }
                    this.getNewRequests();
                    await this.delay(300);
                })
                .catch(async (err) => {
                    await this.setState({
                        alertSnackbar: true,
                    })
                });
        } else {
            // Do nothing!
            console.log('cancel');
        }
        await this.setState({
            style: { display: "none" },

        })

    }

    onSubmitModal = async (e) => {
        e.preventDefault();
        await this.setState({
            submitButton: 'Please wait...',
        });
        console.log(this.state.clientName);
        let attachementList = [];

        await this.state.attachements.map((item) => {
            attachementList.push({ "Description": `Msi_${this.state.dateRangeSelected.replaceAll(' ', '')}_${this.state.projectNameAdd.replaceAll(' ', '')}_${item.Description}`, "DocLink": item.DocLink })
        });
        console.log(attachementList);



        const res = await axios.post(
            `${Config.hostName}/billing/updateMISDetails`,
            {
                contentID: 1,
                content1: `{"billingmonth":"${this.state.dateRangeSelected.replaceAll(' ', '')}", "totalexpense":"${this.state.totalexpense}","invxrefID":"${this.state.invxrefID}"}`,
                content2: JSON.stringify(attachementList),
            }
        )
            .then(async (res) => {
                await this.getNewRequests();
                if (res.data.success != false) {
                    await this.setState({
                        successText: 'Successfully Updated',
                        alertState: true,
                        fullScreenModal: false,
                    });
                }

            })
            .catch((error) => {
                alert(error);
            });


    };

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        const { vertical, horizontal } = this.state;

        return (
            <div>

                <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '7%' }} >
                    <div id='cover-spin' style={this.state.style}></div>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={this.state.snackBar}
                        autoHideDuration={2000}
                        onClose={this.handleCloseSnackBar}
                        key={vertical + horizontal}
                    >
                        <Alert onClose={this.handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                            Comment Added Succesfully!
                        </Alert>
                    </Snackbar>

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={this.state.alertSnackbar}
                        autoHideDuration={5000}
                        onClose={this.handleAlertCloseSnackBar}
                        key={vertical + horizontal}
                    >
                        <Alert onClose={this.handleAlertCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                            No Records Found!
                        </Alert>
                    </Snackbar>

                    <Grid pt={3} container>
                        <Grid item md={12} >
                            <Collapse in={this.state.alertState}>
                                <Alert onClose={() => {
                                    this.setState({
                                        successText: '',
                                        alertState: false,
                                    })
                                }} severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    {this.state.successText}
                                </Alert>
                            </Collapse>
                        </Grid>



                        <Grid style={{ marginTop: '10px', float: 'left', textAlign: 'left' }} item md={12}>
                            <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                                <InputLabel id="viewInvoiceBilling">Billing Month</InputLabel>
                                <Select
                                    labelId="viewInvoiceBilling"
                                    id="viewInvoiceBilling2"
                                    value={this.state.dateRangeSelected}
                                    onChange={(e) => this.handleChangeDate(e)}
                                    label="Billing Month"
                                    variant="standard"
                                >
                                    <MenuItem value="">
                                        <em>Select Billing Month</em>
                                    </MenuItem>

                                    {this.state.uniqueDateRanges.map((eachItem, key) => (
                                        <MenuItem value={eachItem.billingMonth} key={key} >{eachItem.billingMonth}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>


                    </Grid>

                    <Grid mt={3}>
                        <Grid item md={8}>
                            <MUIDataTable
                                title={"Upload MIS"}
                                data={this.state.rowData}
                                columns={this.state.columns}
                                options={options}
                            />
                        </Grid>

                    </Grid>

                    <Modal
                        open={this.state.openDownloadFilesModal}
                        onClose={this.handleCloseDownloadFilesModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style4}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Download Attachments
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <Grid container>
                                    {
                                        this.state.downloadFiles.map((e, key) => (
                                            <>
                                                {e.DocLink.split('.').pop().toLowerCase() != "pdf" && e.DocLink.split('.').pop().toLowerCase() != "xlsx" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid item m={1} md={3}>
                                                            <img className="hoverImage" style={{ width: '100%' }} onClick={(event) => { window.open(e.DocLink) }} src={e.DocLink} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.DocLink.split('.').pop().toLowerCase() == "pdf" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid className="margin0" item m={1} md={3}>
                                                            <img onClick={(event) => { window.open(e.DocLink) }} style={{ width: '100%' }} className="bms-view-request-img" src={pdfIcon} alt="No Image" />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.DocLink.split('.').pop().toLowerCase() == "xlsx" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid item m={1} className="margin0" md={3}>
                                                            <img className="bms-view-request-img" onClick={(event) => { window.open(e.DocLink) }} src={excelIcon} alt="No Image" key={key} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                            </>
                                        ))
                                    }

                                </Grid>


                            </Typography>
                        </Box>
                    </Modal>

                    <Modal
                        open={this.state.openCommentModal}
                        onClose={this.handleCloseComentModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style5}>
                            <div className="row" style={{ marginBottom: "18px" }}>
                                <div className="col-sm-12">
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Comments
                                    </Typography>
                                </div>

                                <div className="col-sm-12 mt-3" style={{ maxHeight: '420px', minHeight: '300px' }}>
                                    <div style={{ height: '89%' }} className="table-responsive" >
                                        <table className="tableInvoice table">
                                            <tr>
                                                <th style={{ width: '65%' }}>
                                                    Comments
                                                </th>
                                                <th>
                                                    Commented By
                                                </th>
                                                <th>
                                                    Date
                                                </th>
                                            </tr>

                                            {this.state.CommentHistory != null &&
                                                this.state.CommentHistory.map((e, key) => (
                                                    <tr>
                                                        <td>
                                                            {JSON.parse(e).remarks}
                                                        </td>
                                                        <td>
                                                            {JSON.parse(e).updatedBy}
                                                        </td>
                                                        <td>
                                                            {JSON.parse(e).updateddate}
                                                        </td>
                                                    </tr>
                                                ))
                                            }


                                        </table>

                                    </div>
                                </div>

                                <form style={{ width: "100%" }} onSubmit={this.handleSubmitComment} method="POST">
                                    <div className="col-sm-12">
                                        <TextField sx={{ width: '100%' }}
                                            id="standard-multiline-static"
                                            label="Add Comment"
                                            rows={3}
                                            multiline
                                            value={this.state.comment}
                                            variant="outlined"
                                            onChange={(e) => {
                                                this.changeComments(e)
                                            }}
                                            required
                                        />
                                        <h6 style={{ color: this.state.commentColor, }}> <b> **No Special character like $%&@ and double space is not allowed </b></h6>

                                    </div>
                                    <div className="col-sm-12" style={{ textAlign: 'right' }}>
                                        <Button type='submit' size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} >
                                            Add
                                        </Button>
                                    </div>
                                </form>


                            </div>




                        </Box>
                    </Modal>

                </Container>


                <Dialog fullScreen
                    TransitionComponent={this.Transition}

                    onClose={this.handleCloseFullModal} open={this.state.fullScreenModal}
                >
                    {/* openFullModal */}
                    <Container maxWidth="xl" style={{ maxWidth: 'inherit', paddingLeft: '0px', paddingRight: '0px' }}>


                        <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleCloseFullModal}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                                <Button autoFocus color="inherit" onClick={this.handleCloseFullModal}>
                                    Close
                                </Button>
                            </Toolbar>
                        </AppBar>



                        {/* <Header order="rohan" /> */}
                        <Grid style={{ background: 'rgb(250 250 250)', }} container>
                            <Grid item md={12} style={{ padding: '0px 12px' }}>
                                <form onSubmit={this.onSubmitModal} method="POST">
                                    <Container pt={3} style={{ maxWidth: 'inherit', paddingBottom: "12%", marginTop: '2%' }} >
                                        {/* <Typography pt={2} style={{ textAlign: 'center', color: '#333333' }} variant="h4"></Typography> */}

                                        {/* <Paper elevation={6} style={{ padding: '10px' }}>

                                            <Typography pt={2} style={{ marginBottom: '10px' }} variant="h6"><b> Update:</b>


                                            </Typography>


                                        </Paper> */}






                                        <Paper elevation={8} style={{ marginTop: '36px', width: '98%' }}>

                                            <Grid pt={1} container>

                                                <Grid item md={12} m={1} style={{ fontSize: '15px', margin: '5px 0px' }} >
                                                    <Typography pt={2} style={{ textAlign: 'center', marginBottom: '10px' }} variant="h4"><b> UPDATE</b>


                                                    </Typography>
                                                </Grid>



                                                <Grid item m={1} md={12} >
                                                </Grid>

                                                <Grid item m={1} md={4} style={{ margin: '36px' }} >
                                                    <b style={{ fontSize: '15px', margin: '5px' }}> Total Expense :</b>

                                                </Grid>
                                                <Grid item m={1} md={6} style={{ margin: '36px' }}>
                                                    <TextField sx={{ width: '30ch' }} id="reqEmail" label=""
                                                        onChange={(e) => {
                                                            this.setState({
                                                                totalexpense: e.target.value
                                                            })
                                                        }}
                                                        value={this.state.totalexpense}
                                                        variant="standard"
                                                        required
                                                    />
                                                </Grid>
                                                <Typography pt={4} style={{ margin: '0px 27px', marginBottom: '18px' }} variant="h6"> <b> Attachments:</b></Typography>
                                                <Grid item md={12} >
                                                    <div style={{ padding: '0px 40px' }} className="table-responsive">
                                                        <table className="tableInvoice table">
                                                            <tr>
                                                                <th>
                                                                    SR No
                                                                </th>
                                                                <th>
                                                                    Document Name
                                                                </th>

                                                                <th>
                                                                    Attach / View
                                                                </th>

                                                                <th>
                                                                    Action
                                                                </th>

                                                            </tr>


                                                            {
                                                                this.state.attachements.map((eachItem, key) => (
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                {key + 1}.
                                                                            </td>
                                                                            <td style={{ width: '52%' }}>
                                                                                <TextField sx={{ width: '100%' }} id="Description" label=""
                                                                                    onChange={(e) => { this.handleChangeAttachment(key, e) }}
                                                                                    name='Description'
                                                                                    value={eachItem.Description}
                                                                                    variant="standard"
                                                                                    required

                                                                                />
                                                                            </td>

                                                                            <td style={{ textAlign: 'center' }}>
                                                                                {eachItem.Description != '' &&
                                                                                    <>
                                                                                        {eachItem.DocLink == '' ?
                                                                                            <Button
                                                                                                color="primary"
                                                                                                size="large"
                                                                                                variant="contained"
                                                                                                component="label"
                                                                                                onChange={(e) => { this.handleChangeAttachment(key, e) }}
                                                                                                style={{ color: "white" }}
                                                                                                className={this.state.color}
                                                                                            >
                                                                                                Upload
                                                                                                <input
                                                                                                    type="file"
                                                                                                    hidden
                                                                                                    accept="image/*,.pdf,.xls,.xlsx"
                                                                                                />
                                                                                            </Button>
                                                                                            :
                                                                                            <>
                                                                                                {eachItem.DocLink.split('.').pop().toLowerCase() != "pdf" && eachItem.DocLink.split('.').pop().toLowerCase() != "xlsx" &&
                                                                                                    <div style={{ width: '152px', marginLeft: 'auto', marginRight: 'auto' }}>
                                                                                                        <img style={{ width: '100%' }} src={eachItem.DocLink} />
                                                                                                    </div>
                                                                                                }

                                                                                                {eachItem.DocLink.split('.').pop().toLowerCase() == "pdf" &&
                                                                                                    <img className="bms-view-request-img" onClick={(e) => { window.open(eachItem.DocLink) }} src={pdfIcon} alt="No Image" key={key} />
                                                                                                }

                                                                                                {eachItem.DocLink.split('.').pop().toLowerCase() == "xlsx" &&
                                                                                                    <img className="bms-view-request-img" onClick={(e) => { window.open(eachItem.DocLink) }} src={excelIcon} alt="No Image" key={key} />
                                                                                                }

                                                                                            </>
                                                                                        }

                                                                                    </>
                                                                                }


                                                                            </td>




                                                                            <td>
                                                                                <DeleteIcon onClick={(e) => this.removeNewAttachment(key, e)} className="deleteIcon" />
                                                                            </td>


                                                                        </tr>
                                                                    </>
                                                                )

                                                                )}
                                                        </table>
                                                    </div>
                                                </Grid>

                                                <Grid item md={9}>

                                                </Grid>

                                                <Grid item md={3}>
                                                    {/* onClick={(e) => this.addNewItem(key, e)} */}
                                                    <div style={{ textAlign: "right", marginRight: '8px' }}>

                                                        <Button size="large" onClick={(e) => this.addNewAttachment(e)} variant="contained" style={{ marginRight: '26px', marginBottom: '20px', marginTop: "18px", color: "white" }} >
                                                            <PlusIcon />
                                                        </Button>
                                                    </div>
                                                </Grid>

                                                <Grid item md={9} >

                                                </Grid>
                                                <Grid item md={6} >

                                                </Grid>

                                                <Grid mt={2} item md={3} >
                                                    <Button type="submit" size="large" variant="contained" style={{ marginBottom: '18px', marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<SendIcon />}>
                                                        {this.state.heading}
                                                    </Button>
                                                </Grid>

                                            </Grid>
                                        </Paper>



                                    </Container>
                                </form>
                            </Grid>

                        </Grid>

                        <Modal
                            open={this.state.invoiceModal}
                            onClose={this.handleCloseInvoiceModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style2} style={{ textAlign: 'center' }}>
                                <Typography style={{ textAlign: 'left' }} id="modal-modal-title" variant="h6" component="h2">
                                    Invoice Generated:
                                </Typography>

                                <Button size="large"
                                    onClick={(e) => { window.open(this.state.invoiceUrl); }}
                                    variant="contained" style={{ marginBottom: '18px', marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<SendIcon />}>
                                    Download
                                </Button>
                            </Box>
                        </Modal>


                    </Container>

                </Dialog>


            </div>


        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(HomePage);
