/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "axios";
// import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import {
    FormGroup, Input, Button, Table, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
// import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import "../../../../assets/customCssFiles/calender.css"

// import EditIcon from '@material-ui/icons/Edit';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import "../../../../assets/customCssFiles/loader-spinner.css"
import "../../../../assets/customCssFiles/tableInvoice.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            modal: false,
            anotherModal: false,
            locations: [],
            unique_route: [],
            unique_dates: [],
            description: "",
            message: "",
            selected_date: "",
            style: {},
            tracking_number: "",
            search: false,
            userCcId: null,
            city: "",
            unique_cities: [],
            ccid: "",
            isTableData: false,
            uniqueDateRanges: [],
            dateRangeSelected: "",
            startDate: "",
            endDate: ""
            // modal: false,
        };
    }
    toggle = async () => {
        await this.setState({
            modal: !this.state.modal,
        });
        // this.setState({
        //   modal: !this.state.modal,
        // });
    };


    onClickLiveTrack = async (e) => {
        e.preventDefault();
        window.open(e.target.value, '_blank');
    }
    getUniqueDates = async () => {
        await axios.get(`${Config.hostName}/shipment/unique/date/${this.state.userCcId}`)
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_dates: res.data.data,
                    })
                }
                else {
                    this.setState({
                        unique_dates: [],
                    }
                    )
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    getUniqueCities = async () => {
        const result = await axios.get(`${Config.hostName}/shipment/get/unique-cities/${this.props.auth.user.CCID}`)
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_cities: res.data.data,
                    })
                    console.log(this.state.unique_cities);
                }
                else {
                    this.setState({ unique_dates: [] })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    getUniqueDateRanges = async () => {
        await axios
            .get(
                `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
            )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    componentWillMount = async () => {
        console.log(this.props.match.params.startDate, this.props.match.params.endDate, "params")
        if (this.props.match.params.startDate && this.props.match.params.endDate) {
            await this.setState({
                startDate: this.props.match.params.startDate,
                endDate: this.props.match.params.endDate
            })
        }
        await this.getUniqueCities();
        await this.getUniqueDateRanges();
        var result = await axios.post(
            `${Config.hostName}/royal-oak/get/dashboard/invoice/details`, {
            "ccid": this.props.auth.user.CCID,
            "invoiceStartDate": this.state.startDate,
            "invoiceEndDate": this.state.endDate,
            "search": this.state.tracking_number
        });
        const locations = result.data.data;
        await this.setState({
            search: false,
            locations: locations,
            userCcId: this.props.auth.user.CCID,
            isTableData: true,
            style: { display: 'none' }
        });
    }

    handleSearchShipment = async () => {
        console.log(this.state.startDate, this.state.endDate, this.state.dateRangeSelected)
        await this.setState({
            style: {},
            search: true
        })
        try {
            var result = await axios.post(
                `${Config.hostName}/royal-oak/get/dashboard/invoice/details`, {
                "ccid": this.props.auth.user.CCID,
                "invoiceStartDate": this.state.startDate,
                "invoiceEndDate": this.state.endDate,
                "search": this.state.tracking_number
            });
            const locations = result.data.data;
            await this.setState({
                search: false,
                locations: locations,
                userCcId: this.props.auth.user.CCID,
                isTableData: true,
                style: { display: 'none' }
            });
        } catch (e) {
            console.log(e);
        }
    }

    handleChangeCity = async (e) => {
        await this.setState({ style: {}, userCcId: e.target.value, ccid: e.target.value });

        await this.getUniqueDates();
    }

    handleChangeDateRange = async (e) => {
        console.log(e.target.value);
        let value = e.target.value;
        // console.log();2222
        if (value == 2222) {
            await this.setState({
                dateRangeSelected: e.target.value,
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    // console.log("true", eachDate);
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: eachDate.startDate,
                        endDate: eachDate.endDate,
                    });
                }
            });
        }
    };

    toggleCloseModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: "",
        });
    };

    toggleConfirmModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: 2222,
        });
    };


    render() {
        let modal = this.state.modal;

        let style = this.state.style;

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id='cover-spin' style={style}></div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>
                                        {this.props.auth.user.user_type === "ROSuperAdmin" ?
                                            <div className="col-sm-2">
                                                <FormGroup>
                                                    <Input
                                                        type="select"
                                                        name="city"
                                                        value={this.state.ccid}
                                                        id="city"
                                                        onChange={this.handleChangeCity}
                                                    >
                                                        <option value={""} disabled>Select Cities</option>

                                                        {this.state.unique_cities.map(eachItem => (
                                                            <option value={eachItem.ccid}>{eachItem.city}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </div>
                                            : ""}


                                        {/* <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="routeNo"
                                                    value={this.state.selected_date}
                                                    id="routeNo"
                                                    onChange={event => {
                                                        this.setState({ selected_date: event.target.value });
                                                    }}
                                                >
                                                    <option value={""} disabled>Select dates</option>

                                                    {this.state.unique_dates.map(date => (
                                                        <option value={date.Loaddate}>{date.Loaddate}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </div> */}

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="dateRange"
                                                    value={this.state.dateRangeSelected}
                                                    id="dateRange"
                                                    onChange={this.handleChangeDateRange}
                                                >
                                                    <option value={""} disabled>
                                                        Select Date Range
                      </option>

                                                    {this.state.uniqueDateRanges.map((eachItem, i) => (
                                                        <option value={i} key={i}>
                                                            {eachItem.title}
                                                        </option>
                                                    ))}
                                                    <option value={2222}>
                                                        {/* <Button onClick={this.toggle}> */}
                        Custom Date Range
                        {/* </Button> */}
                                                    </option>
                                                    <div>
                                                        <Modal
                                                            isOpen={modal}
                                                        // toggle={this.toggle}
                                                        >
                                                            <ModalHeader>Select Start and End date</ModalHeader>
                                                            <ModalBody>
                                                                <div className="row" style={{ minHeight: "1px" }}>
                                                                    <div className="col float-left">
                                                                        <div
                                                                            className="cal-datepicker"
                                                                            style={{ height: "100%" }}
                                                                        >
                                                                            <div
                                                                                className="datepicker-here text-center"
                                                                                style={{ height: "100%" }}
                                                                                data-language="en"
                                                                            >
                                                                                <DatePicker
                                                                                    height="400"
                                                                                    placeholderText="Select Start Date"
                                                                                    id="startDateInput"
                                                                                    inline
                                                                                    selectsRange
                                                                                    selected={this.state.startDate}
                                                                                    startDate={this.state.startDate}
                                                                                    onChange={(date) => {
                                                                                        this.setState({
                                                                                            startDate: date,
                                                                                        });
                                                                                    }}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col float-left">
                                                                        <div className="cal-datepicker">
                                                                            <div
                                                                                className="datepicker-here text-center"
                                                                                data-language="en"
                                                                            >
                                                                                <DatePicker
                                                                                    height="400"
                                                                                    id="endDateInput"
                                                                                    inline
                                                                                    placeholderText="Select End Date"
                                                                                    selected={this.state.endDate}
                                                                                    onChange={(date) => {
                                                                                        this.setState({
                                                                                            endDate: date,
                                                                                        });
                                                                                    }}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button color="info"
                                                                    onClick={this.toggleConfirmModal}

                                                                >Confirm</Button>{" "}
                                                                <Button
                                                                    color="danger"
                                                                    onClick={this.toggleCloseModal}
                                                                >
                                                                    Close
                            </Button>{" "}
                                                            </ModalFooter>
                                                        </Modal>
                                                    </div>
                                                </Input>
                                            </FormGroup>
                                        </div>


                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    placeholder="Search"
                                                    name="tracking_num"
                                                    value={this.state.tracking_number}
                                                    id="tracking_num"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            tracking_number: e.target.value
                                                        })
                                                    }}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                type="submit"
                                                // className="btn btn-primary"
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.handleSearchShipment}
                                            >
                                                Search
                      </button>
                                        </div>

                                        <div className="col-sm-2 ml-auto">
                                            {this.state.locations.length > 0 && (
                                                <CSVLink data={this.state.locations}>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                                                    // className="btn btn-primary"

                                                    >Export
                      </button>
                                                </CSVLink>
                                            )}

                                        </div>

                                    </div>

                                </nav>

                            </div>
                            {this.state.isTableData &&
                                <div style={{ width: "100%" }} >
                                    <div className="card-body" >
                                        <Table responsive className="styled-table">
                                            <thead>
                                                <tr className="styled-table-header-row">
                                                    <th >Invoice&nbsp;No</th>
                                                    <th >Invoice&nbsp;Date</th>
                                                    <th>
                                                        <tr>
                                                            <td className="item-details">Item&nbsp;No</td>
                                                            <td className="item-details-desc">Description</td>
                                                            {/* <td className="item-details">Boxes</td> */}
                                                        </tr>
                                                    </th>
                                                    <th >POD&nbsp;Link</th>
                                                    <th >cxName</th>
                                                    <th >cxNumber01</th>
                                                    <th >cxNumber02</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.locations.map(item =>
                                                    <tr className="styled-table-row">
                                                        <td >{item.invoiceno}</td>
                                                        <td >{item.invoicedate}</td>
                                                        {/* {console.log(item.itemdetails)} */}
                                                        <td >
                                                            {JSON.parse(item.itemdetails).map(eachItem => {
                                                                return <tr>
                                                                    <td className="item-details">{eachItem.ItemNumber}</td>
                                                                    <td className="item-details-desc">{eachItem.Description}</td>
                                                                    {/* <td className="item-details">{eachItem.BoxCnt}</td> */}
                                                                </tr>
                                                            })}
                                                        </td>
                                                        <td >
                                                            <Button
                                                                className="pod-button"
                                                                // color="danger"
                                                                value='https://logicarts-tms.s3.ap-south-1.amazonaws.com/DISPATCH_LIST_QRCODE_DEV1603391714491.pdf'
                                                                onClick={this.onClickLiveTrack}
                                                            >
                                                                Check&nbsp;POD
                                                            </Button>
                                                        </td>
                                                        <td >{item.cxName}</td>
                                                        <td >{item.cxNumber01}</td>
                                                        <td >{item.cxNumber02}</td>
                                                    </tr>

                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div style={{ margin: "10vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                </div>
            </div>
            // </div >
        );
    }

}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);