/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import totalCustomersBgImg from "../../../../assets/customImages/Total_customers.png";
import totalOrdersBgImg from "../../../../assets/customImages/Total_Orders.png";
import salesReportsBgImg from "../../../../assets/customImages/Sales_Reports.png";

class GeneralWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      modal: false,
      anotherModal: false,
      locations: [],
      selected_date: "",
      style: {},
      userCcId: null,
      ccid: "",
      unique_cities: [],
      totalCustomers: "",
      statusReport: [],
      startDate: "",
      endDate: "",
      isData: false,
      totalOrders: "",
      totalDispatched: "",
      totalReceived: "",
      totalOFD: "",
    };
  }

  setTimer = async () => {
    if (this.props.data.isData) {
      let obj = [{
        Cancelled: this.props.data.Cancelled,
        Delivered: this.props.data.Delivered,
        Rescheduled: this.props.dataRescheduled
      }];

      await this.setState({
        totalOrders: this.props.data.Orders,
        totalDispatched: this.props.data.Dispatch,
        totalReceived: this.props.data.Received,
        totalOFD: this.props.data.OFD,
        statusReport: obj
      });
      console.log(this.state.totalOrders);

    }
  }




  componentWillMount = async () => {
    console.log(this.props.data, "this.props.data");

    await this.setTimer()
  }

  cardImageStyle = {
    width: "100px",
    height: "100px",
    position: "absolute",
    top: "-20px",
    left: "10px",
    right: "0",
    borderRadius: "0.25rem",
    boxShadow: "0px 0px 3px 0px #888888",
  };

  cardTextStyle = {
    fontSize: "18px",
    height: "auto",
    marginTop: "65px",
    //marginLeft: "80px",
    // color: "#666666",
  };

  render() {


    return (
      <div>
        <div className="container-fluid">
          <div className="row">

            <div className="col-sm-6 col-xl-3 col-lg-6 mt-5">
              <div className="card">
                <div className="ecommerce-widget card-body">
                  <div className="row">
                    <div className="col-8">
                      <h4 className="total-num"><span className="counter"><CountUp end={this.state.totalOrders ? this.state.totalOrders : 0} /></span></h4>
                      <span>Total Orders</span>
                    </div>
                    <div className="col-4">
                      <div className="icon text-md-right">
                        <i className="icon-user"></i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6 mt-5">
              <div className="card">
                <div className="ecommerce-widget card-body">
                  <div className="row">
                    <div className="col-8">
                      <h4 className="total-num"><span className="counter"><CountUp end={this.state.totalDispatched ? this.state.totalDispatched : 0} /></span></h4>
                      <span>Total Dispatched</span>
                    </div>
                    <div className="col-4">
                      <div className="icon text-md-right">
                        <i className="icon-user"></i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6 mt-5">
              <div className="card">
                <div className="ecommerce-widget card-body">
                  <div className="row">
                    <div className="col-8">
                      <h4 className="total-num"><span className="counter"><CountUp end={this.state.totalReceived ? this.state.totalReceived : 0} /></span></h4>
                      <span>Total Received</span>
                    </div>
                    <div className="col-4">
                      <div className="icon text-md-right">
                        <i className="icon-user"></i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6 mt-5">
              <div className="card">
                <div className="ecommerce-widget card-body">
                  <div className="row">
                    <div className="col-8">
                      <h4 className="total-num"><span className="counter"><CountUp end={this.state.totalOFD ? this.state.totalOFD : 0} /></span></h4>
                      <span>Out For Delivery</span>
                    </div>
                    <div className="col-4">
                      <div className="icon text-md-right">
                        <i className="icon-user"></i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className=" col-sm-12 col-xl-6 col-lg-6 mt-5">
              <div className="card" style={{ borderRadius: "0.5rem" }}>
                <img src={salesReportsBgImg} style={this.cardImageStyle} alt="salesRport" />

                <div className="b-r-4 card-body" style={{ minHeight: "241px" }}>
                  <div
                    className="m-1"
                    style={{
                      height: "auto",
                    }}
                  >
                    <h5
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        height: "auto",
                        marginLeft: "100px",
                        color: "#808080",
                      }}
                    >
                      Status Report
                    </h5>
                    <div className="row mt-5">
                      {this.state.statusReport &&
                        this.state.statusReport.map((status, i) => {
                          return (
                            <div className="col-sm-12" style={{ display: "flex" }}>
                              {/* <div className="col-sm-12"> */}
                              <div className="col-sm-3" key={status.Cancelled}>
                                <span
                                  className="m-0 font-weight-bold"
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "16px",
                                    color: "#808080",
                                  }}
                                >
                                  Cancelled
                              </span>
                                <h4 className="txt mb-0">
                                  <span
                                    className="counter"
                                    style={{
                                      color: "#4d4d4d",
                                      fontFamily: "Open Sans",
                                      fontSize: "2rem",
                                    }}
                                  >
                                    <CountUp end={status.Cancelled ? status.Cancelled : 0} />
                                  </span>
                                </h4>
                              </div>
                              <div className="col-sm-3" key={status.Delivered}>
                                <span
                                  className="m-0 font-weight-bold"
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "16px",
                                    color: "#808080",
                                  }}
                                >
                                  Delivered
                             </span>
                                <h4 className="txt mb-0">
                                  <span
                                    className="counter"
                                    style={{
                                      color: "#4d4d4d",
                                      fontFamily: "Open Sans",
                                      fontSize: "2rem",
                                    }}
                                  >
                                    <CountUp end={status.Delivered ? status.Delivered : 0} />
                                  </span>
                                </h4>
                              </div>
                              <div className="col-sm-3" key={status.Rescheduled}>
                                <span
                                  className="m-0 font-weight-bold"
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "16px",
                                    color: "#808080",
                                  }}
                                >
                                  Rescheduled
                            </span>
                                <h4 className="txt mb-0">
                                  <span
                                    className="counter"
                                    style={{
                                      color: "#4d4d4d",
                                      fontFamily: "Open Sans",
                                      fontSize: "2rem",
                                    }}
                                  >
                                    <CountUp end={status.Rescheduled ? status.Rescheduled : 0} />
                                  </span>
                                </h4>
                              </div>
                            </div>
                            // </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

GeneralWidget.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(GeneralWidget);

// export default GeneralWidget;
