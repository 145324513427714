import React, { Component} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Card,
    Col,
    CardBody,
    CardTitle,
    CardText,
    Button,
    ModalFooter,
    Modal,
    ModalBody,
    Label,
    FormGroup,
    Input

} from "reactstrap";
import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux"
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
// import "../../../../assets/customCssFiles/viewVehiclesVms.css";
import VehicleIcon from "../../../../assets/customImages/newTruckTms.png";
import BankIcon from "../../../../assets/customImages/bankIconPms.png";
// import "../../../../assets/customCssFiles/pmsCreateReqPayee.css";
import "../../../../assets/customCssFiles/pmsViewBanks.css";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        position: 'relative',
        minHeight: 200,
        // margin: theme.spacing(1),
        '& > *': {
            margin: theme.spacing(1),
        },

    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },

}));


class RouteAddForm extends Component {

    state = {
        locations: [],
        Vendorid: "",
        Vehiclenumber: "",
        VehicleSize: "",
        RocNumber: "",
        ISGps: "",
        ISGpsValue: "",
        banks: [],
        style: {},
        isEditPayeeBankModalOpen: false,
        isAddPayeeBankModalOpen: false,
        isData: false,
        pmsId: "",

        editPayeeBankModalBankName: "",
        editPayeeBankModalAccountNumber: "",
        editPayeeBankModalIfsc: "",
        editPayeeBankModalBeneficiaryName: "",
        editPayeeBankModalIsPrimary: "",
        isEditPayeeBankModalPayeeBankId: "",

        addPayeeBankModalBankName: "",
        addPayeeBankModalAccountNumber: "",
        addPayeeBankModalIfsc: "",
        addPayeeBankModalBeneficiaryName: "",
        addPayeeBankModalIsPrimary: "",
        isAddPayeeBankModalPayeeBankId: ""
    };

    getData = async (value) => {
        let result = await axios.get(
            `${Config.hostName}/pms/get/payeeBank/dropdown/dashboard/${value}`
        );
        await this.setState({
            banks: result.data.data,
            style: { display: 'none' },
            isData: true
        })
        if (result.data.success === false) {
            await this.props.setAlert(`No Bank Details Found `, "danger");
            await this.props.history.push("/admin/pms/dashboard");
        }
        else
            await this.setState({
                banks: result.data.data
            });
    }

    async componentWillMount() {
        await this.setState({
            pmsId: this.props.match.params.pmsId
        })
        await this.getData(this.props.match.params.pmsId)
    }

    setAsDefault = async (value) => {
        await this.setState({
            style: {}
        });
        const banksObj = [];
        const map = this.state.banks.filter((bank) => {
            if (bank.payeebankID == value) {
                banksObj.push(bank)
            }
        });
        const prom = await Promise.all(map);
        await axios.post(`${Config.hostName}/pms/update/bank/payee`, {
            "pmsId": this.state.pmsId,
            "payeeBankId": value,
            "bankName": banksObj[0].bankname,
            "bankAccountNo": banksObj[0].AccountNumber,
            "ifsc": banksObj[0].IFSC,
            "beneficaryName": banksObj[0].beneficiaryName,
            "tds": "",
            "isPrimary": 1
        })
            .then(async res => {
                if (res.data.success == true) {
                    await this.getData(this.state.pmsId);
                    alert("Succesfully Updated");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert(res.data.message);
                }
            })
            .catch(async err => {
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
            });
    }

    editPayeeBankPayee = async (e) => {
        e.preventDefault()
        await this.setState({
            style: {}
        });

        await axios.post(`${Config.hostName}/pms/update/bank/payee`, {
            "pmsId": this.state.pmsId,
            "payeeBankId": this.state.isEditPayeeBankModalPayeeBankId,
            "bankName": this.state.editPayeeBankModalBankName,
            "bankAccountNo": this.state.editPayeeBankModalAccountNumber,
            "ifsc": this.state.editPayeeBankModalIfsc,
            "beneficaryName": this.state.editPayeeBankModalBeneficiaryName,
            "tds": "",
            "isPrimary": this.state.editPayeeBankModalIsPrimary
        })
            .then(async res => {
                if (res.data.success == true) {
                    await this.getData(this.state.pmsId);
                    await this.toggleCloseEditPayeeBankPayeeModal();
                    alert("Succesfully Updated");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    await this.toggleCloseEditPayeeBankPayeeModal();
                    alert(res.data.message);
                }
                await this.toggleCloseEditPayeeBankPayeeModal();
                await this.setState({
                    style: { display: "none" }
                })
            })
            .catch(async err => {
                await this.toggleCloseEditPayeeBankPayeeModal();
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
            });
    }

    toggleOpenEditPayeeBankPayeeModal = async (value) => {
        const banksObj = [];
        const map = this.state.banks.filter((bank) => {
            if (bank.payeebankID == value) {
                banksObj.push(bank)
            }
        });
        const prom = await Promise.all(map);

        await this.setState({
            isEditPayeeBankModalOpen: true,
            isEditPayeeBankModalPayeeBankId: value,
            editPayeeBankModalBankName: banksObj[0].bankname,
            editPayeeBankModalAccountNumber: banksObj[0].AccountNumber,
            editPayeeBankModalIfsc: banksObj[0].IFSC,
            editPayeeBankModalBeneficiaryName: banksObj[0].beneficiaryName,
            editPayeeBankModalIsPrimary: banksObj[0].IsPrimary
        })
    }

    toggleCloseEditPayeeBankPayeeModal = async () => {
        await this.setState({
            isEditPayeeBankModalOpen: false
        })
    }

    addPayeeBankPayee = async (e) => {
        e.preventDefault()
        await this.setState({
            style: {}
        });

        // })
        await axios.post(`${Config.hostName}/pms/add/bank/payee`, {
            "pmsId": this.state.pmsId,
            "bankName": this.state.addPayeeBankModalBankName,
            "bankAccountNo": this.state.addPayeeBankModalAccountNumber,
            "ifsc": this.state.addPayeeBankModalIfsc,
            "beneficaryName": this.state.addPayeeBankModalBeneficiaryName,
            "tds": "",
            "isPrimary": this.state.addPayeeBankModalIsPrimary ? 1 : 0
        })
            .then(async res => {
                if (res.data.success == true) {
                    await this.getData(this.state.pmsId);
                    await this.toggleCloseAddPayeeBankPayeeModal();
                    alert("Succesfully Updated");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    await this.toggleCloseAddPayeeBankPayeeModal();
                    alert(res.data.message);
                }
                await this.toggleCloseAddPayeeBankPayeeModal();
                await this.setState({
                    style: { display: "none" }
                })
            })
            .catch(async err => {
                await this.toggleCloseAddPayeeBankPayeeModal();
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
            });
    }

    toggleOpenAddPayeeBankPayeeModal = async () => {
        await this.setState({
            isAddPayeeBankModalOpen: true,
            addPayeeBankModalBankName: "",
            addPayeeBankModalAccountNumber: "",
            addPayeeBankModalIfsc: "",
            addPayeeBankModalBeneficiaryName: "",
            addPayeeBankModalIsPrimary: "",
            isAddPayeeBankModalPayeeBankId: ""
        })
    }

    toggleCloseAddPayeeBankPayeeModal = async () => {
        await this.setState({
            isAddPayeeBankModalOpen: false,
        })
    }

    handleCheckPrimary = async () => {

        await this.setState({
            addPayeeBankModalIsPrimary: !this.state.addPayeeBankModalIsPrimary
        });

    }

    render() {
        const { classes } = this.props;

        let style = this.state.style;
        const { isEditPayeeBankModalOpen, isData, isAddPayeeBankModalOpen } = this.state
        return (
            <div style={{ height: "82vh" }}>
                <div id="cover-spin" style={style}> </div>

                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                {/* <span>Total {isData && this.state.banks.length} Bank Accounts Found for {isData && this.state.banks[0].fullname}</span> */}

                                <div className="row pmsViewVehiclesMainCardContainer">
                                    {this.state.banks.map((location, index) => (

                                        <div key={index} className="card col-sm-5 pmsViewVehiclesMainCard">

                                            <div className="row ">

                                                <div className="pmsViewVehiclesMainCardRightView">
                                                    <span className="pmsPayeeAccountNo">A/C No : {location.AccountNumber}</span>
                                                    <span className="pmsPayeeAccountNo">IFSC : {location.IFSC}</span>
                                                    <span className="pmsPayeeAccountNo">Name : {location.fullname}</span>
                                                    <span className="pmsPayeeAccountNo">Beneficiary Name : {location.beneficiaryName}</span>
                                                    {/* <span className="pmsPayeeAccountNo">Payee Bank Id : {location.payeebankID}</span> */}
                                                    <span className="pmsPayeeAccountNo">Bank Name : {location.bankname}</span>
                                                    {/* <span className="pmsPayeeAccountNo">payeebankID : {location.payeebankID}</span> */}
                                                    <span className="pmsPayeeAccountNo">
                                                        Is Default : <span className="pmsPayeeAccountNo" style={{ color: location.IsPrimary == true ? "green" : "red" }}>
                                                            {location.IsPrimary == true ? "YES" : "NO"}
                                                        </span>

                                                    </span>
                                                    <span style={{ display: 'flex', flexDirection: 'row', paddingLeft: '2%' }}>
                                                        {/* <EditIcon color="danger" /> Edit */}


                                                        <span style={{ flex: 1 }}>
                                                            <Button
                                                                color="danger" size="small"
                                                                onClick={() => this.toggleOpenEditPayeeBankPayeeModal(location.payeebankID)}
                                                            // onClick={() => this.editPayeeBankPayeeModal(location.payeebankID)}

                                                            >
                                                                Edit
                                                        </Button>
                                                        </span>

                                                        <span style={{ flex: 2 }}>
                                                            {location.IsPrimary !== true &&
                                                                <Button color="danger" size="small" onClick={() => this.setAsDefault(location.payeebankID)}>
                                                                    Set As Default
                                                        </Button>
                                                            }
                                                        </span>

                                                    </span>

                                                </div>

                                            </div>

                                        </div>

                                    ))}

                                </div>

                            </div>
                            <Fab style={{ right: 40, bottom: 80, position: 'fixed' }} color="primary" aria-label="add" onClick={this.toggleOpenAddPayeeBankPayeeModal}>
                                <AddIcon />
                            </Fab>

                        </div>
                    </div>
                </div>

                {/* Modal For Edit Bank */}

                <div>
                    {isEditPayeeBankModalOpen && <Modal
                        size="lg"
                        style={{ maxWidth: '1600px', width: '70%' }}
                        centered={true}
                        isOpen={isEditPayeeBankModalOpen}
                    // toggle={this.toggleAddPayeeBankModal}
                    >
                        <ModalFooter>

                            <div className="col-sm-3 ml-auto">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Button
                                            color="danger"
                                            onClick={this.toggleCloseEditPayeeBankPayeeModal}
                                        >
                                            <CloseIcon />
                                        </Button>{" "}
                                    </div>
                                </div>
                            </div>


                        </ModalFooter>

                        <ModalBody>
                            <div className="bms-view-req-modal-body">

                                <form >
                                    <div
                                        className="col-lg-12 text-right"
                                        style={{ paddingTop: "4vh" }}
                                    >
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="grid">
                                            <div className="grid-body">
                                                <div className="item-wrapper">
                                                    <div className="row mb-3">
                                                        <div className="col-md-12 mx-auto">

                                                            <div className="form-row">
                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Bank Name</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            placeholder="Enter Bank Name"
                                                                            formControlName="addPayeeBankBankName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.editPayeeBankModalBankName}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    editPayeeBankModalBankName: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Account No</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            placeholder="Enter Account No"
                                                                            formControlName=" addPayeeBankAccountNumber"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.editPayeeBankModalAccountNumber}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    editPayeeBankModalAccountNumber: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="form-row">
                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>IFSC Code</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            placeholder="Enter IFSC Code"
                                                                            formControlName="addPayeeBankIfsc"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.editPayeeBankModalIfsc}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    editPayeeBankModalIfsc: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Benificary Name</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            placeholder="Enter Benificary Name"
                                                                            formControlName="addPayeeBankBeneficiaryName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.editPayeeBankModalBeneficiaryName}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    editPayeeBankModalBeneficiaryName: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-3">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-danger overflow-hidden mb-3 px-auto editPayeeBankModalSaveButton"
                                                                        onClick={e => this.editPayeeBankPayee(e)}
                                                                    >
                                                                        Save
                                                                 </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ModalBody>


                    </Modal>
                    }
                </div>


                {/* Modal For Add Bank */}

                <div>
                    {isAddPayeeBankModalOpen && <Modal
                        // size="lg"
                        style={{ maxWidth: '1600px', width: '60%' }}
                        centered={true}
                        isOpen={isAddPayeeBankModalOpen}
                    // toggle={this.toggleAddPayeeBankModal}
                    >
                        <ModalFooter>

                            <div className="col-sm-3">
                                <span className="bms-view-req-modal-header-label">PmsId : {this.state.pmsId}</span>
                            </div>

                            <div className="col-sm-3 ml-auto">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Button
                                            color="danger"
                                            onClick={this.toggleCloseAddPayeeBankPayeeModal}
                                        >
                                            <CloseIcon />
                                        </Button>{" "}
                                    </div>
                                </div>
                            </div>


                        </ModalFooter>

                        <ModalBody>
                            <div className="bms-view-req-modal-body">

                                <form >
                                    <div
                                        className="col-lg-12 text-right"
                                        style={{ paddingTop: "4vh" }}
                                    >
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="grid">
                                            <div className="grid-body">
                                                <div className="item-wrapper">
                                                    <div className="row mb-3">
                                                        <div className="col-md-12 mx-auto">


                                                            <div className="form-row">
                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Bank Name</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            placeholder="Enter Bank Name"
                                                                            formControlName="addPayeeBankBankName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.addPayeeBankModalBankName}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    addPayeeBankModalBankName: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Account No</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            placeholder="Enter Account No"
                                                                            formControlName=" addPayeeBankAccountNumber"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.addPayeeBankModalAccountNumber}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    addPayeeBankModalAccountNumber: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="form-row">
                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>IFSC Code</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            placeholder="Enter IFSC Code"
                                                                            formControlName="addPayeeBankIfsc"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.addPayeeBankModalIfsc}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    addPayeeBankModalIfsc: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Benificary Name</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            placeholder="Enter Benificary Name"
                                                                            formControlName="addPayeeBankBeneficiaryName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.addPayeeBankModalBeneficiaryName}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    addPayeeBankModalBeneficiaryName: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            onChange={this.handleCheckPrimary}
                                                                            // value={this.state.IsOdometer}
                                                                            checked={this.state.addPayeeBankModalIsPrimary}
                                                                        />{" "}
                                                                        Is Primary
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>

                                                            <button
                                                                type="submit"
                                                                className="btn btn-outline-primary btn-lg"
                                                                style={{ float: "right" }}
                                                                onClick={e => this.addPayeeBankPayee(e)}
                                                            >
                                                                Create
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ModalBody>


                    </Modal>
                    }
                </div>

            </div>
        );
    }
}

RouteAddForm.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

// export default connect(mapStateToProps, { setAlert })(RouteAddForm);

export default compose(
    connect(mapStateToProps, { setAlert }),
    withStyles(useStyles)
)(RouteAddForm);
