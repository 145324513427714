/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "axios";
// import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import { Button, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
// import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/loader-spinner.css"

class NoticeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      modal: false,
      locations: [],
      description: "",
      message: "",
      style: {},
      unique_locationids: [],
      anotherModal: false,
      Locationid: "",
      unique_segments: [],
      unique_clients: [],
      Segment: "",
      ServiceType: "",
      ClientName: "",
      Trip: "",
      page: 1,
      showText: false,
      columnDefs: [
        {
          headerName: "Route Code", field: "RouteCode", sortable: true, filter: true
        },
        {
          headerName: "From", field: "From", sortable: true, filter: true
        },
        {
          headerName: "To", field: "To", sortable: true, filter: true
        },
        {
          headerName: "Lane", field: "Lane", sortable: true, filter: true, editable: true,
        },
        {
          headerName: "Service Type", field: "ServiceType", sortable: true, filter: true, editable: true,
        },
        {
          headerName: "Create", field: "Create", editable: true,
          cellRendererFramework: (params) => {
            let data = params.node.data;
            return <Link to={`/admin/route-master/create-${data.ServiceType}-trip/` + data.RouteID}>
              {data.ServiceType == "Adhoc" ?
                <Button
                  color="primary"
                  onClick={() =>
                    this.setState({
                      modal: !this.state.modal
                    })
                  }
                // disabled={data.ServiceType == "Regular" ? true : false}
                >
                  Create
    </Button> : ""
              }
            </Link>
          },
        }
      ],
      rowData: [],
      activePage: 1,
      itemsCountPerPage: 50,
      totalItemsCount: 5000,
      pageRangeDisplayed: 5,
      search: false,
      domLayout: "autoHeight",

    };
  }



  handlePageChange = async (pageNumber) => {
    await this.setState({ activePage: pageNumber, style: {} });
    if (this.state.search === false)
      await this.componentWillMount();
    else
      await this.handleSearchShipment();
  }


  changeLocation = async event => {
    this.setState({ style: {}, unique_segments: [], unique_clients: [] });
    event.preventDefault();
    await this.setState({ Locationid: event.target.value });
    let { Locationid } = this.state;
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: Locationid,
        clientName: ""
      })
      .then(res => {
        this.setState({
          unique_clients: res.data.data,
          style: { display: 'none' }
        });
      })
      .catch(err => {
        alert(err);
      });
  };

  changeClient = async event => {
    event.preventDefault();
    await this.setState({ style: {}, unique_segments: [], ClientName: event.target.value });
    let { ClientName, Locationid } = this.state;
    await axios.post(`${Config.hostName}/unique/segments`, {
      locationId: Locationid,
      clientName: ClientName
    })
      .then(res => {
        this.setState({
          unique_segments: res.data.data,
          style: { display: 'none' }

        });
      })
      .catch(err => {
        alert(err);
      });
  };

  componentWillMount = async () => {
    await this.setState({ search: false, style: {} })

    await axios.get(`${Config.hostName}/unique/locations`)
      .then(async (res) => {
        await this.setState({ unique_locationids: res.data.data })
      })
      .catch((error) => {
        alert(error)
      });

    let { activePage } = this.state;

    await axios.get(`${Config.hostName}/route-master/get/all/${activePage}`)
      .then(async (result) => {
        await this.setState({
          locations: result.data.data
        });
        await this.setState({
          rowData: this.state.locations.map(eachItem => {
            return {
              RouteCode: eachItem.RouteCode,
              From: eachItem.StartingPoint,
              To: eachItem.Endingpoint,
              Lane: eachItem.Lane,
              ServiceType: eachItem.ServiceType,
              RouteID: eachItem.RouteID
            }
          }),
          style: { display: 'none' }

        });

      }).catch(error => {
        alert(error);
      })
  }

  handleSearchShipment = async () => {
    await this.setState({ search: true })
    let { activePage } = this.state;

    try {
      await this.setState({ style: {} })
      let result = await axios.post(
        `${Config.hostName}/route-master/search/${activePage}`,
        {
          locationId: this.state.Locationid,
          clientName: this.state.ClientName,
          segment: this.state.Segment
        }
      );
      await this.setState({
        locations: result.data.data
      });

      await this.setState({
        rowData: this.state.locations.map(eachItem => {
          return {
            RouteCode: eachItem.RouteCode,
            From: eachItem.StartingPoint,
            To: eachItem.Endingpoint,
            Lane: eachItem.Lane,
            ServiceType: eachItem.ServiceType,
            RouteID: eachItem.RouteID
          }
        }),
        style: { display: 'none' }

      });
    } catch (e) {
      alert(e);

    }

  }

  render() {
    let style = this.state.style;

    const defaultColDef = {
      flex: 1,
      minWidth: 90,
      resizable: true
    }

    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;


    return (
      <div>
        <div id='cover-spin' style={style}></div>

        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />

                <nav
                  className="navbar navbar-light "
                  style={{ backgroundColor: "none" }}
                >
                  <div className="row" style={{ paddingTop: "8px", width: "100%" }}>


                    <div className="col-sm-2">
                      <FormGroup>
                        <Input
                          type="select"
                          name="locationid"
                          value={this.state.Locationid}
                          id="locationid"
                          onChange={this.changeLocation}
                        >
                          <option value={""} disabled selected>Select Location</option>

                          {this.state.unique_locationids.length > 1 && this.state.unique_locationids.map(locationid => (

                            <option value={locationid.Locationid}>
                              {locationid.name}

                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <FormGroup>
                        <Input
                          type="select"
                          name="clientName"
                          value={this.state.ClientName}
                          id="clientName"
                          onChange={this.changeClient}
                        >
                          <option value={""} disabled selected>Select Client</option>

                          {this.state.unique_clients.map(client => (
                            <option value={client.ClientName}>
                              {client.ClientName}
                            </option>
                          ))}

                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <FormGroup>
                        <Input
                          type="select"
                          name="routeNo"
                          value={this.state.Segment}
                          id="routeNo"
                          onChange={event => {
                            this.setState({ Segment: event.target.value });
                          }}
                        >
                          <option value={""} disabled selected>Select Segment</option>

                          {this.state.unique_segments.map(segment => (
                            <option value={segment.Segment}>
                              {segment.Segment}
                            </option>
                          ))}

                          {/* <option value={0}>All Segments</option> */}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={this.handleSearchShipment}
                      >
                        Search
                      </button>
                    </div>

                    <div className="col-sm-2">
                      <Link to={"/admin/route-master/add-new"}>
                        <button
                          type="submit"
                          onClick={() =>
                            this.setState({
                              anotherModal: !this.state.anotherModal
                            })
                          }
                          className="btn btn-secondary"
                        >
                          Add New
                        </button>
                      </Link>
                    </div>



                  </div>
                </nav>

                <div className="card-body">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: '100%',
                      width: '100%',
                      minHeight: "70vh"
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      defaultColDef={defaultColDef}
                      onGridReady={this.onGridReady}
                      rowHeight={40}
                      enableCellTextSelection={true}
                      suppressAutoSize={true}
                      gridAutoHeight={true}
                      domLayout={this.state.domLayout}

                    >
                    </AgGridReact>
                  </div>
                  <div style={{ margin: "5vh auto", marginLeft: "25%", justifyContent: "center" }}>
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText='prev'
                      nextPageText='next'
                      firstPageText='first'
                      lastPageText='last'
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


}

NoticeAdd.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
