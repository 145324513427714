import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Form,
  Table,
} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import './layout.css';
import { Redirect, Link } from "react-router-dom";
import "../../../../assets/customCssFiles/loader-spinner.css";
import { connect } from "react-redux";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "../../../../assets/customCssFiles/bmsViewRequest.css";
import "../../../../assets/customCssFiles/bmsCreateReqPayee.css";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// import SaveIcon from '@material-ui/icons/Save';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import DeleteIcon from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import BankIcon from "../../../../assets/customImages/bankIconPms.png";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdhocImage from "../../../../assets/customImages/adhocTripCount.png";
import RegularImage from "../../../../assets/customImages/regularTripCount.png";
import AmazonImage from "../../../../assets/customImages/amazon.png";
import "../../../../assets/customCssFiles/tmsTripCount.css";
import "../../../../assets/customCssFiles/tmshomepage.css";
import SearchIcon from "@material-ui/icons/Search";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { AgGridReact } from "ag-grid-react";
import Tesseract from 'tesseract.js';


class RouteAddForm extends Component {
  state = {
    location: "",
    style: {},
    category: "",
    uniqueCategories: [],
    priority: "",
    totalAmount: null,
    remarks: "",
    items: [],
    attachments: [],
    contentId: "",
    content: undefined,
    bmsId: "",
    initialFormItems: [],
    selectedFile: null,
    selectedFileType: "",
    isAttachmentsLoading: false,
    uniqueItems: [],
    isChangeCategory: false,
    mainCategory: "",
    mainCategoryAnother: false,
    payeeDetails: [],
    bankAccountNumber: "",
    bankAccountHolderName: "",
    bankIfscCode: "",
    billingDate: "",
    ccXrefId: "",
    /** Drop Downs Data  */
    uniqueLocationsDropDown: [],
    uniqueClientsDropDown: [],
    uniqueSegmentsDropDown: [],
    uniquePayeeTypesDropDown: [],
    uniquePayeesDropDown: [],
    uniquePayeeBanksDropDown: [],
    /** Drop Downs Selected Value  */
    selectedLocationDropDown: "",
    selectedClientDropDown: "",
    selectedSegmentDropDown: "",
    selectedPayeeTypeDropDown: "",
    selectedPayeeDropDownName: "",
    selectedPayeeDropDown: "",
    selectedLocationPayeeDropDown: "",
    selectedPayeeBankDropDown: "",
    selectedPayeeBankAmountValue: "",

    selectedBanksList: [],
    isAddPayeeBankModalOpen: false,
    isEditPayeeBankModalOpen: false,
    isAddPayeeModalOpen: false,
    isEditPayeeBankAmountEditModalOpen: false,

    addPayeeBankBeneficiaryName: "",
    addPayeeBankAccountNumber: "",
    addPayeeBankBankName: "",
    addPayeeBankIfsc: "",
    addPayeeBankIsPrimary: false,
    addPayeeBankTds: "",
    isCreateButtonLoading: false,

    addPayeeName: "",
    addPayeeEmail: "",
    addPayeeContactNumber: "",
    addPayeeContactNumberAlternate: "",
    addPayeeIsCompany: false,
    addPayeeTds: "",
    addPayeeAttachments: [],
    addPayeeBeneficiaryName: "",
    addPayeeAccountNumber: "",
    addPayeeBankName: "",
    addPayeeIfsc: "",

    editBankModalDetails: [],

    stringVer: true,

    modal: false,
    dateRangeSelected: "",
    uniqueDateRanges: [],
    startDate: "",
    endDate: "",
    viewTripDetailsModal: false,
    viewTripDetailsModalLoading: false,
    isData: false,
    uniqueClients: [],
    requiredClientData: [],
    apiData: [],
    widgetData: [],
    clientData: [],
    vehicleNumber: "",
    statusKeys: ["Regular", "Adhoc"],
    addTripToBmsLoading: false,

    selectedTripModalLocation: "",
    selectedTripModalClient: "",
    selectedTripModalSegment: "",
    selectedTripModalVehicleNumber: "",
    uniqueLocationsDropDownTripModal: [],
    uniqueClientsDropDownTripModal: [],
    uniqueSegmentsDropDownTripModal: [],
    addPayeeBankIsCompany: 0,

    startDateTripModal: "",
    endDateTripModal: "",
    dateRangeSelectedTripModal: "",
    handleChangeDateRangeTripModal: "",
    toggleConfirmModalTripModal: false,
    toggleCloseModalTripModal: false,
    modalTripModal: false,
    styleTripModal: {},
    bmsCreateReqTripIds: "",
    billingMonth: "",
    selectedTripId: "",
    tripIdError: "Enter TripID's",
    color: "",
    viewTripDetailsModalNew: false,
    attachPanModal: false,
    panRead: false,
    panDisabled: true,

    panPmsID: '',
    tripIdData: [],
    columnDefs: [
      {
        headerName: "Client Name",
        field: "ClientName",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Segment",
        field: "Segment",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Service Type",
        field: "Servicetype",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Vehicle Number",
        field: "vehiclenumber",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Trip Cnt",
        field: "TripCnt",
        sortable: true,
        filter: true,
      },
    ],
    tripIDNEW: [],
    tripIdStrings: "",
    getid: null,

    panNumber: "",
    panImage: "",
    isPanUploaded: false,
    showPanDisplay: "none",
  };

  getUniqueDateRanges = async () => {
    await axios
      .post(`${Config.hostName}/bms/dateRangeDropDownOnlyMonth`, {
        ccxref: this.state.ccXrefId,
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueDateRanges: res.data.data,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  getUniqueLocationsDropDown = async () => {
    await axios
      .get(`${Config.hostName}/unique/locations`)
      .then(async (res) => {
        await this.setState({
          uniqueLocationsDropDown: res.data.data,
        });
      })
      .catch((error) => {
        alert("getUniqueLocationsDropDown : ", error);
      });
  };

  getUniqueClientsDropDown = async () => {
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: this.state.selectedLocationDropDown,
        clientName: "",
      })
      .then(async (res) => {
        // console.log(res.data);
        await this.setState({
          uniqueClientsDropDown: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  getUniqueSegmentsDropDown = async () => {
    await axios
      .post(`${Config.hostName}/unique/segments`, {
        locationId: this.state.selectedLocationDropDown,
        clientName: this.state.selectedClientDropDown,
      })
      .then((res) => {
        this.setState({
          uniqueSegmentsDropDown: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  getUniquePayeeTypesDropDown = async () => {
    await axios
      .get(`${Config.hostName}/pms/get/payeeType/dropdown`)
      .then(async (res) => {
        await this.setState({
          uniquePayeeTypesDropDown: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  getUniquePayeesDropDown = async () => {
    await axios
      .get(
        `${Config.hostName}/pms/get/payee/dropdown/${this.state.selectedLocationPayeeDropDown}/${this.state.selectedPayeeTypeDropDown}`
      )
      .then(async (res) => {
        await this.setState({
          uniquePayeesDropDown: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  getUniquePayeeBanksDropDown = async () => {
    await axios
      .get(
        `${Config.hostName}/pms/get/payeeBank/dropdown/${this.state.selectedPayeeDropDown}`
      )
      .then(async (res) => {
        await this.setState({
          uniquePayeeBanksDropDown: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  addPayeeBankApiCall = async () => {
    await this.setState({
      style: {},
    });
    await axios
      .post(`${Config.hostName}/pms/add/bank/payee`, {
        pmsId: this.state.selectedPayeeDropDown,
        bankName: this.state.addPayeeBankBankName,
        bankAccountNo: this.state.addPayeeBankAccountNumber,
        ifsc: this.state.addPayeeBankIfsc,
        beneficaryName: this.state.addPayeeBankIfsc,
        tds: this.state.addPayeeBankTds,
        isPrimary: this.state.addPayeeBankIsPrimary,
      })
      .then(async (res) => {
        if (res.data.success == true) {
          await this.toggleAddPayeeBankModal();
          await this.getUniquePayeeBanksDropDown();
          await this.setState({
            style: { display: "none" },
          });
          alert("Succesfully Added");
        } else {
          await this.setState({
            style: { display: "none" },
          });
          alert(res.data.message);
        }
      })
      .catch(async (err) => {
        await this.setState({
          style: { display: "none" },
        });
        alert(err);
        // alert(err);
      });
  };

  addPayeeApiCall = async () => {
    await this.setState({
      style: {},
    });

    const {
      addPayeeName,
      addPayeeEmail,
      addPayeeContactNumber,
      addPayeeContactNumberAlternate,
      addPayeeIsCompany,
      addPayeeTds,
      addPayeeAttachments,
      addPayeeBeneficiaryName,
      addPayeeAccountNumber,
      addPayeeBankName,
      addPayeeIfsc,
      selectedPayeeTypeDropDown,
      selectedLocationPayeeDropDown,
    } = this.state;



    if (this.state.panNumber!= '' && this.state.panImage != '') {
      await axios
        .post(`${Config.hostName}/pms/add/payee`, {
          payeeName: addPayeeName,
          payeeTypeId: selectedPayeeTypeDropDown,
          locationId: selectedLocationPayeeDropDown,
          bankName: addPayeeBankName,
          bankAccountNo: addPayeeAccountNumber,
          ifsc: addPayeeIfsc,
          beneficaryName: addPayeeBeneficiaryName,
          tds: addPayeeTds,
          isCompany: addPayeeIsCompany === false ? 0 : 1,
          email: addPayeeEmail,
          contactNumber: addPayeeContactNumber,
          contactNumber02: addPayeeContactNumberAlternate,
          attachments: '',
          jdata: `{"panNO":"${this.state.panNumber}","panURL":"${this.state.panImage}" }`,
        })
        .then(async (res) => {
          if (res.data.success == true) {
            await this.toggleAddPayeeModal();
            await this.getUniquePayeesDropDown();
            await this.setState({
              style: { display: "none" },
            });
            alert("Succesfully Added");
          } else {
            await this.setState({
              style: { display: "none" },
            });
            alert(res.data.message);
          }
        })
        .catch(async (err) => {
          await this.setState({
            style: { display: "none" },
          });
          alert(err);
          // alert(err);
        });
    } else {
      alert('PAN No. not correct!!');
      await this.setState({
        style: { display: "none" },
      });
    }

  };

  changeLocation = async (event) => {
    event.preventDefault();
    await this.setState({
      uniqueClientsDropDown: [],
      uniqueSegmentsDropDown: [],
      selectedLocationDropDown: event.target.value,
    });
    await this.getUniqueClientsDropDown();
    // await this.setState({ style: { display: "none" } });
  };

  changeClient = async (event) => {
    event.preventDefault();
    await this.setState({
      uniqueSegmentsDropDown: [],
      selectedClientDropDown: event.target.value,
    });
    await this.getUniqueSegmentsDropDown();
    // await this.setState({ style: { display: "none" } });
  };

  changeSegment = async (event) => {
    event.preventDefault();
    const value = event.target.value;
    await this.setState({ selectedSegmentDropDown: value, ccXrefId: value });
    await this.getUniqueDateRanges();

    // await this.setState({ style: { display: "none" } });
  };

  changeLocationPayee = async (event) => {
    event.preventDefault();
    await this.setState({
      // style: {},
      uniquePayeeTypesDropDown: [],
      uniquePayeesDropDown: [],
      uniquePayeeBanksDropDown: [],
      selectedLocationPayeeDropDown: event.target.value,
      selectedPayeeTypeDropDown: "",
      selectedPayeeDropDown: "",
    });
    await this.getUniquePayeeTypesDropDown();
    // await this.setState({ style: { display: "none" } });
  };

  changePayeeType = async (event) => {
    event.preventDefault();
    await this.setState({
      uniquePayeeBanksDropDown: [],
      uniquePayeesDropDown: [],
      selectedPayeeTypeDropDown: event.target.value,
      selectedPayeeDropDown: "",
    });
    await this.getUniquePayeesDropDown();
    // await this.setState({ style: { display: "none" } });
  };

  validatePayeePAN = async (pmsID) => {
    const res = await axios.get(
      `${Config.hostName}/pms/validatePayeePAN/${pmsID}`,
      {
      }
    )
      .then(async (res) => {
        console.log(res.data.data[0].panNO);
        console.log(res.data.data[0].panURL);

        if (res.data.data[0].panNO != null && res.data.data[0].panURL != null) {
          return 1;
        } else {
          return 0;
        }
      })
      .catch((error) => {
        alert(error);
        return error;
      });
    return res;
  }

  changePayee = async (event) => {
    event.preventDefault();
    const payeeValue = event.target.value;
    if (payeeValue == 88888) {
      await this.setState({
        isAddPayeeModalOpen: true,
      });
    } else {
      const res = await this.validatePayeePAN(payeeValue);
      console.log(res);
      if (res == 1) {
        this.setState({
          uniquePayeeBanksDropDown: [],
          selectedPayeeDropDown: payeeValue,
          selectedPayeeBankDropDown: "",
        });
        await this.getUniquePayeeBanksDropDown();
      } else {
        this.setState({
          attachPanModal: true,
          panPmsID: payeeValue

        })
        console.log('IamOUT')
      }

    }

  };

  changePayeeBank = async (event) => {
    event.preventDefault();
    const payeeBankValue = event.target.value;
    if (payeeBankValue == 99999) {
      await this.setState({
        isAddPayeeBankModalOpen: true,
      });
    } else {
      await this.setState({ selectedPayeeBankDropDown: payeeBankValue });
      // await this.getUniquePayeeBanksDropDown();
      // const { selectedBanksList, uniquePayeeBanksDropDown } = this.state;
      // uniquePayeeBanksDropDown.filter((bankItem, i) => {
      //     if (bankItem.payeebankID == payeeBankValue) {
      //         bankItem.amount = "";
      //         selectedBanksList.push(bankItem)
      //     }
      // })

      // await this.setState({
      //     selectedBanksList: selectedBanksList,
      //     style: { display: "none" }
      // }
      // );
    }
  };

  changePayeeBankAmountValue = async (event) => {
    event.preventDefault();
    const payeeBankAmountValue = event.target.value;
    console.log(payeeBankAmountValue);
    await this.setState({
      selectedPayeeBankAmountValue: payeeBankAmountValue,
    });
  };

  deletePayeeBank = async (value) => {
    // await this.setState({ style: {} });
    // await this.getUniquePayeeBanksDropDown();
    const { selectedBanksList } = this.state;
    const banKList = selectedBanksList.filter((bankItem, i) => {
      // if(bankItem.payeebankID==value){
      // return selectedBanksList.pop(bankItem);
      return bankItem.payeebankID != value;

      // }
    });
    await this.setState({
      selectedBanksList: banKList,
      // style: { display: "none" }
    });
  };

  editPayeeBank = async (value) => {
    const { selectedBanksList } = this.state;
    const reqObj = [];
    const banKList = selectedBanksList.filter((bankItem, i) => {
      // return bankItem.payeebankID != value;
      if (bankItem.payeebankID == value) {
        return reqObj.push(bankItem);
      }
    });
    await this.setState({
      isEditPayeeBankModalOpen: true,
      editBankModalDetails: reqObj,
      addPayeeBankBeneficiaryName: reqObj[0].fullname,
      addPayeeBankAccountNumber: reqObj[0].AccountNumber,
      addPayeeBankBankName: reqObj[0].bankname,
      addPayeeBankIfsc: reqObj[0].IFSC,
      addPayeeBankIsPrimary: reqObj[0].IsPrimary,
      addPayeeBankTds: reqObj[0].tds,
      selectedPayeeDropDown: reqObj[0].pmsid,
      selectedPayeeDropDownName: reqObj[0].fullname,
      selectedPayeeBankDropDown: value,
    });
  };

  editPayeeBankAmount = async (value) => {
    const { selectedBanksList } = this.state;
    const reqObj = [];
    const banKList = selectedBanksList.filter((bankItem, i) => {
      // return bankItem.payeebankID != value;
      if (bankItem.payeebankID == value) {
        return reqObj.push(bankItem);
      }
    });
    await this.setState({
      isEditPayeeBankAmountEditModalOpen: true,
      selectedPayeeDropDownName: reqObj[0].fullname,
      selectedPayeeBankAmountValue: reqObj[0].amount,
      selectedPayeeBankAmountValueObject: reqObj,
    });
  };

  createPayeeBank = async (e) => {
    e.preventDefault();
    await this.addPayeeBankApiCall();
  };

  createPayee = async (e) => {
    e.preventDefault();
    await this.addPayeeApiCall();
  };

  savePayeeBankApiCall = async () => {
    await this.setState({
      style: {},
    });
    const neededBank = this.state.selectedPayeeBankDropDown;
    await axios
      .post(`${Config.hostName}/pms/update/bank/payee`, {
        pmsId: this.state.selectedPayeeDropDown,
        payeeBankId: this.state.selectedPayeeBankDropDown,
        bankName: this.state.addPayeeBankBankName,
        bankAccountNo: this.state.addPayeeBankAccountNumber,
        ifsc: this.state.addPayeeBankIfsc,
        beneficaryName: this.state.addPayeeBankIfsc,
        tds: "",
        isPrimary: 0,
      })
      .then(async (res) => {
        if (res.data.success == true) {
          await this.setState({ style: {} });
          await this.getUniquePayeeBanksDropDown();
          const { selectedBanksList, uniquePayeeBanksDropDown } = this.state;
          const tempBankList = [];

          uniquePayeeBanksDropDown.filter((bankItem, i) => {
            if (bankItem.payeebankID == neededBank) {
              tempBankList.push(bankItem);
            }
          });

          selectedBanksList.filter((bank) => {
            if (bank.payeebankID == neededBank) {
              // return bank = tempBankList[0]
              return (
                (bank.AccountNumber = tempBankList[0].AccountNumber),
                (bank.IFSC = tempBankList[0].IFSC),
                (bank.IsPrimary = tempBankList[0].IsPrimary),
                // bank.amount = tempBankList[0].amount,
                (bank.bankname = tempBankList[0].bankname),
                (bank.fullname = tempBankList[0].fullname),
                (bank.payeebankID = tempBankList[0].payeebankID),
                (bank.pmsid = tempBankList[0].pmsid),
                (bank.tds = tempBankList[0].tds)
              );
            }
          });
          await this.setState({
            selectedBanksList: selectedBanksList,
          });
          await this.setState({
            style: { display: "none" },
            selectedPayeeBankDropDown: "",
          });
          await this.toggleEditPayeeBankModal();
          alert("Succesfully Updated");
        } else {
          await this.setState({
            style: { display: "none" },
          });
          alert(res.data.message);
        }
      })
      .catch(async (err) => {
        await this.setState({
          style: { display: "none" },
        });
        alert(err);
        // alert(err);
      });
  };

  savePayeeBankDetails = async (e) => {
    e.preventDefault();
    console.log("addPayeeBank");
    await this.savePayeeBankApiCall();
  };

  savePayeeBankAmountDetails = async (e) => {
    e.preventDefault();
    const {
      selectedBanksList,
      selectedPayeeBankAmountValueObject,
      selectedPayeeBankAmountValue,
    } = this.state;
    // console.log("addPayeeBank", selectedPayeeBankAmountValue, selectedBanksList, selectedPayeeBankAmountValueObject);
    const map = selectedBanksList.map((item) => {
      if (
        item.payeebankID == selectedPayeeBankAmountValueObject[0].payeebankID
      ) {
        // console.log(selectedPayeeBankAmountValue, "selectedPayeeBankAmountValue", item)
        return (item.amount = selectedPayeeBankAmountValue);
      }
    });
    const res = await Promise.all(map);
    // console.log(selectedBanksList, "selectedBanksList")
    await this.setState({
      selectedBanksList: selectedBanksList,
      isEditPayeeBankAmountEditModalOpen: false,
      selectedPayeeBankAmountValue: "",
    });
    // console.log(this.state.selectedBanksList, "this.state.selectedBanksList")
  };

  getUrlToAttachmentsAddPayee = async (url, name) => {
    const { addPayeeAttachments } = this.state;
    addPayeeAttachments.filter((attachment) => {
      if (attachment.name == name) {
        return (attachment.AttachmentUrl = url);
      }
    });
    await this.setState({
      addPayeeAttachments: addPayeeAttachments,
    });
  };

  uploadFileAndGetUrlAddPayee = async (formData) => {
    // console.log("uploading formdata")
    const fileData = await axios({
      method: "POST",
      url: `${Config.hostName}/unique/upload/any-file`,
      timeout: 3 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: formData,
    });
    return fileData;
  };

  singleUploadFileAddPayee = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({
      selectedFileAddPayee: file,
      selectedFileTypeAddPayee: fileType,
    });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFileAddPayee,
      this.state.selectedFileAddPayee.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrlAddPayee(formData);
    const urlFromAws = res.data.url;
    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    await this.getUrlToAttachmentsAddPayee(urlFromAws, file.name);
    const { addPayeeAttachments } = this.state;
    setTimeout(async () => {
      addPayeeAttachments.filter((att) => {
        if (att.name == file.name && att.isUploaded == false) {
          return (att.isUploaded = true);
        }
      });
      await this.setState({
        addPayeeAttachments: addPayeeAttachments,
      });
    }, 2000);
  };

  onFileUploadAddPayee = async (event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { addPayeeAttachments } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      addPayeeAttachments.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false,
      });
    });
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    await this.setState({
      addPayeeAttachments: addPayeeAttachments,
    });
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.singleUploadFileAddPayee(item);
    }
  };

  deleteAttachmentAddPayee = async (name) => {
    const { addPayeeAttachments } = this.state;
    // console.log(name, "deleteAttachment");
    addPayeeAttachments.filter((item) => {
      if (item.name == name) {
        const index = addPayeeAttachments.indexOf(item);
        if (index > -1) {
          addPayeeAttachments.splice(index, 1);
        }
      }
    });
    await this.setState({
      addPayeeAttachments: addPayeeAttachments,
    });
  };

  createNewPayeeSelectedBankCard = async (e) => {
    e.preventDefault();
    if (this.state.selectedPayeeBankDropDown.length == 0) {
      return alert("Please Select Bank Details to continue");
    }
    if (this.state.selectedPayeeBankAmountValue.length == 0) {
      return alert("Please Enter Amount to continue");
    } else {
      await this.setState({ style: {} });
      const {
        selectedBanksList,
        uniquePayeeBanksDropDown,
        selectedPayeeBankDropDown,
        selectedPayeeBankAmountValue,
      } = this.state;
      uniquePayeeBanksDropDown.filter((bankItem, i) => {
        if (bankItem.payeebankID == selectedPayeeBankDropDown) {
          bankItem.amount = selectedPayeeBankAmountValue;
          selectedBanksList.push(bankItem);
        }
      });

      await this.setState({
        selectedBanksList: selectedBanksList,
        uniquePayeeTypesDropDown: [],
        uniquePayeesDropDown: [],
        uniquePayeeBanksDropDown: [],
        /** Drop Downs Selected Value  */
        selectedPayeeTypeDropDown: "",
        selectedPayeeDropDownName: "",
        selectedPayeeDropDown: "",
        selectedLocationPayeeDropDown: "",
        selectedPayeeBankDropDown: "",
        selectedPayeeBankAmountValue: "",
        style: { display: "none" },
      });
    }
  };

  toggleAddPayeeBankModal = async () => {
    await this.setState({
      isAddPayeeBankModalOpen: !this.state.isAddPayeeBankModalOpen,
    });
  };

  toggleEditPayeeBankModal = async () => {
    await this.setState({
      isEditPayeeBankModalOpen: !this.state.isEditPayeeBankModalOpen,
    });
  };

  toggleEditPayeeBankAmountModal = async () => {
    await this.setState({
      isEditPayeeBankAmountEditModalOpen: !this.state
        .isEditPayeeBankAmountEditModalOpen,
      selectedPayeeBankAmountValue: "",
    });
  };

  toggleAddPayeeModal = async () => {
    await this.setState({
      isAddPayeeModalOpen: !this.state.isAddPayeeModalOpen,
      panDisabled: true
    });
  };

  toggleAttachPanModal = async () => {
    this.setState({
      attachPanModal: false,
      showPanDisplay: 'none',
      panImage: '',
      isPanUploaded: false,
      panNumber: false,
      panPmsID: '',
      panRead: false,
    });
  }

  handleCheckPrimary = async () => {
    await this.setState({
      addPayeeBankIsPrimary: !this.state.addPayeeBankIsPrimary,
    });
  };

  handleCheckCompany = async () => {
    await this.setState({
      addPayeeBankIsCompany: !this.state.addPayeeBankIsCompany,
    });
  };
  handleOpenViewTrips = async () => {
    await this.setState({
      isCreateButtonLoading: true,
    });
    await axios
      .post(`${Config.hostName}/bms/attachtmstrips`, {
        tripIDs: this.state.bmsCreateReqTripIds,
      })
      .then(async (res) => {
        if (res.data.success == false) {
          this.setState({});
        } else {
          await this.setState({
            tripIdData: res.data.data.map((eachItem) => {
              return {
                ClientName: eachItem.ClientName,
                Segment: eachItem.Segment,
                Servicetype: eachItem.Servicetype,
                vehiclenumber: eachItem.vehiclenumber,
                TripCnt: eachItem.TripCnt,
              };
            }),
            tripIDNEW: res.data.data,
          });
        }
      })
      .catch((err) => {
        this.setState({
          uniqueSegmentsDropDownTripModal: "",
          tripIdError: err,
          selectedTripId: "",
        });
      });

    await this.setState({
      viewTripDetailsModalNew: true,
      isCreateButtonLoading: false,
    });
  };

  toggleCloseViewTripDetailsNew = async () => {
    await this.setState({
      viewTripDetailsModalNew: false,
      isCreateButtonLoading: false,
    });
  };

  getCategoriesData = async () => {
    const data = await axios
      .post(`${Config.hostName}/bms/get/category-dropdown`, {
        locationId: this.props.auth.user.locationid,
        category: "",
      })
      .then(async (res) => {
        const data = res.data.data;
        const filteredArray = data
          .map((item) => item.category)
          .filter((value, index, self) => self.indexOf(value) === index);
        if (data.length > 0) {
          let newCategories = [];
          if (this.state.getid != null) {
            filteredArray.map((item) => {
              if (item == "Freight Expense") {
                newCategories.push(item);
              }
            });
          } else {
            filteredArray.map((item) => {
              if (item != "Freight Expense") {
                newCategories.push(item);
              }
            });
          }

          await this.setState({
            uniqueCategories: newCategories,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  changeCategoryAnotherAgain = async () => {
    const data = await axios
      .post(`${Config.hostName}/bms/get/category-dropdown`, {
        locationId: this.props.auth.user.locationid,
        category: this.state.category,
      })
      .then(async (res) => {
        const data = res.data.data;
        const filteredArray = data
          .map((item) => item.items)
          .filter((value, index, self) => self.indexOf(value) === index);
        if (data.length > 0) {
          await this.setState({
            uniqueItems: filteredArray,
            isChangeCategory: false,
            initialFormItems: [],
            mainCategoryAnother: true,
          });
          await this.addInitialItemRow();
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  changeCategory = async (e) => {
    if (this.state.mainCategoryAnother) {
      await this.setState({
        category: e.target.value,
        // isChangeCategory: true
      });
    } else {
      await this.setState({
        category: e.target.value,
        // isChangeCategory: false
      });
      await this.changeCategoryAnotherAgain();
    }
  };

  changePriority = async (e) => {
    await this.setState({
      priority: e.target.value,
    });
  };

  createRequestApiCall = async () => {
    // console.log(this.state.remarks, "this.state.remarks")
    let newItems = [];
    const initialFormItems = this.state.initialFormItems;
    initialFormItems.filter((item) => {
      newItems.push({
        item: item.item,
        amount: item.amount,
      });
    });
    const attachments = this.state.attachments;
    const newAttachments = [];
    attachments.map((attachment) => {
      newAttachments.push({
        AttachmentUrl: attachment.AttachmentUrl,
      });
    });
    const { payeeDetails, selectedBanksList } = this.state;
    // const requiredPayeeDetails = [];
    // selectedBanksList.map((bankIt => {
    //     const obj = {
    //         bankId: bankIt.payeebankID,
    //         amount: bankIt.amount
    //     }
    //     requiredPayeeDetails.push(obj)
    // }))
    // console.log(this.state.billingDate, "this.state.billingDate")

    await axios
      .post(`${Config.hostName}/bms/create/request`, {
        userId: this.props.auth.user.USERID,
        category: this.state.category,
        itemDetails: newItems,
        attachments: newAttachments,
        notes: this.state.remarks,
        totalAmount: this.state.totalAmount,
        priority: this.state.priority,
        payeeDetails: selectedBanksList,
        billingDate: this.state.billingDate,
        ccXrefId: this.state.ccXrefId,
        billingPeriodStartDate: this.state.startDate,
        billingPeriodEndDate: this.state.endDate,
        tripIds: this.state.bmsCreateReqTripIds,
        billingMonth: this.state.billingMonth,
      })
      .then(async (response) => {
        console.log(response, "response");

        if (response.data.success === false) {
          await this.setState({
            style: { display: "none" },
          });
          alert(response.data.message);
        } else {
          await this.props.setAlert("Succesfully Created", "success");
          await this.props.history.push("/admin/bms/requests/home");
          await this.setState({
            style: { display: "none" },
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  getInitialFormConditionValidation = async () => {
    const items = this.state.initialFormItems;
    const mapCheck = items.map(async (item) => {
      console.log(item.item, item.item.length, item.amount);
      if (item.item.length == 0 && item.amount == 0) {
        await this.setState({
          stringVer: false,
        });
      }
    });
    const promCheck = await Promise.all(mapCheck);
  };

  createNewRequest = async () => {
    await this.setState({ style: {} });

    if (!this.state.category) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Category is Required");
    }
    if (this.state.category == "Freight Expense") {
      if (this.state.bmsCreateReqTripIds == "") {
        await this.setState({
          style: { display: "none" },
        });
        return alert("TripId's Required");
      }
    }
    if (!this.state.priority) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Priority is Required");
    }
    if (!this.state.billingDate) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Billing Date is Required");
    }

    if (!this.state.remarks) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Remarks is Required");
    }
    if (!this.state.selectedSegmentDropDown) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Project Details is Required");
    }

    if (!this.state.billingMonth) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Billing Period is Required");
    }

    if (this.state.selectedBanksList.length == 0) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Payee Details are Mandatory to create a request");
    }
    if (this.state.initialFormItems.length == 0) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Min 1 Item Is required to create a request");
    }


    if (this.state.attachments == []) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Min 1 Attachment is required to create a request");
    }




    const items = this.state.initialFormItems;

    for (let i = 0; i < items.length; i++) {
      console.log(items[i], i, items[i].item.length, items[i].amount);
      if (items[i].item.length == 0) {
        // await this.setState({
        //     stringVer: false
        // })
        await this.setState({
          style: { display: "none" },
        });
        return alert("Invalid Item Details");
        // return;
      }
      if (items[i].amount == 0) {
        console.log("HIii");
        await this.setState({
          style: { display: "none" },
        });
        return alert("Invalid Item Details");
      }
      if (i == items.length) {
        console.log("HIii");
        return;
      }
    }

    // console.log("stringVer", this.state.stringVer)
    // if (this.state.stringVer == false) {
    //     await this.setState({
    //         style: { display: "none" }
    //     })
    //     return alert("Invalid Item Details")
    // }
    const totalPayeeAmount = await this.getTotalPayeeAmount();
    if (this.state.totalAmount != totalPayeeAmount) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Amount You Spent is mismatching with Payable Amount");
    } else {
      await this.createRequestApiCall();
    }
  };

  getTotalPayeeAmount = async () => {
    const { selectedBanksList } = this.state;
    let totalAmount = 0;
    const map = selectedBanksList.map((item) => {
      // console.log(item, 'item');
      totalAmount = totalAmount + Number(item.amount);
    });
    const prom = await Promise.all(map);
    console.log(totalAmount, "totalAmount");
    return totalAmount;
  };

  addInitialItemRow = async () => {
    const itemsArr = this.state.initialFormItems;
    const newRowObject = {
      id: 1,
      item: "",
      amount: "",
      isDisabled: true,
      itemDropDownName: "",
    };
    itemsArr.push(newRowObject);
    await this.setState({
      initialFormItems: itemsArr,
    });
  };

  componentWillMount = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("tripid");
    // console.log(id);
    let idTrip = "";
    if (id != null) {
      idTrip = id;
      await this.setState({
        getid: idTrip,
        bmsCreateReqTripIds: idTrip,
      });
    }

    // console.log(idTrip);
    await this.getCategoriesData();
    await this.getUniqueLocationsDropDown();
    await this.getUniqueLocationsDropDownTripModal();

    // await this.getUniquePayeeTypesDropDown();
    await this.addInitialItemRow();
    await this.setState({
      style: { display: "none" },
      styleTripModal: { display: "none" },
    });
  };

  addNewItem = async () => {
    const itemsArr = this.state.initialFormItems;
    const newRowObject = {
      id: itemsArr.length + 1,
      item: "",
      amount: 0,
      isDisabled: true,
      itemDropDownName: "",
    };
    itemsArr.push(newRowObject);
    await this.setState({
      initialFormItems: itemsArr,
    });
  };

  formControlChangeItemName = async (id, e) => {
    const value = e.target.value;
    const { initialFormItems } = this.state;
    // console.log(value, id, "formName");
    initialFormItems.filter((item) => {
      if (item.id == id) {
        return (item.item = value);
      }
    });
    await this.setState({
      initialFormItems: initialFormItems,
    });
  };

  getTotalAmount = async () => {
    const { initialFormItems } = this.state;
    let totalAmount = 0;
    const map = initialFormItems.map((item) => {
      // console.log(item, 'item');
      totalAmount = totalAmount + Number(item.amount);
    });
    const prom = await Promise.all(map);
    await this.setState({
      totalAmount: totalAmount,
    });
    // console.log(this.state.totalAmount, "this.state.totalAmount");
  };

  formControlChangeItemAmount = async (id, e) => {
    const value = e.target.value;
    let { initialFormItems } = this.state;
    // console.log(value, id, "formAmount");
    initialFormItems.filter((item) => {
      if (item.id == id) {
        return (item.amount = Number(value));
      }
    });
    await this.getTotalAmount();
    await this.setState({
      initialFormItems: initialFormItems,
    });
  };

  deleteExtraItem = async (id) => {
    const { initialFormItems } = this.state;
    // console.log(id, "deleteExtraItem");
    if (initialFormItems.length > 1) {
      initialFormItems.filter((item) => {
        if (item.id == id) {
          const index = initialFormItems.indexOf(item);
          if (index > -1) {
            initialFormItems.splice(index, 1);
          }
        }
      });
      await this.setState({
        initialFormItems: initialFormItems,
      });
      await this.getTotalAmount();
    } else {
      await this.props.setAlert("Min 1 Item is required", "danger");
    }
  };

  getUrlToAttachments = async (url, name) => {
    const { attachments } = this.state;
    attachments.filter((attachment) => {
      if (attachment.name == name) {
        return (attachment.AttachmentUrl = url);
      }
    });
    await this.setState({
      attachments: attachments,
    });
  };

  uploadFileAndGetUrl = async (formData) => {
    // console.log("uploading formdata")
    const fileData = await axios({
      method: "POST",
      url: `${Config.hostName}/unique/upload/any-file`,
      timeout: 3 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: formData,
    });
    console.log(fileData);
    return fileData;
  };

  singleUploadFile = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;
    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    await this.getUrlToAttachments(urlFromAws, file.name);
    const { attachments } = this.state;
    setTimeout(async () => {
      attachments.filter((att) => {
        if (att.name == file.name && att.isUploaded == false) {
          return (att.isUploaded = true);
        }
      });
      await this.setState({
        attachments: attachments,
      });
    }, 4000);
    // console.log(this.state.attachments, "data")
  };

  onFileUpload = async (event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { attachments } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      attachments.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false,
      });
    });
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    await this.setState({
      attachments: attachments,
    });
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.singleUploadFile(item);
    }
  };

  deleteAttachment = async (name) => {
    const { attachments } = this.state;
    // console.log(name, "deleteAttachment");
    attachments.filter((item) => {
      if (item.name == name) {
        const index = attachments.indexOf(item);
        if (index > -1) {
          attachments.splice(index, 1);
        }
      }
    });
    await this.setState({
      attachments: attachments,
    });
  };

  updatePayeePan = async (e) => {
    e.preventDefault();
    if (this.state.panImage == '') {
      alert('Upload Pan Image First');
    } else {
      await axios
        .post(`${Config.hostName}/pms/update/payee`, {
          pmsId: this.state.panPmsID,
          fullName: '',
          pmsTypeId: '',
          locationId: '',
          isCompany: '',
          email: '',
          contactNumber: '',
          contactNumber02: '',
          attachments: '',
          isActive: '',
          jdata: `{"panNO":"${this.state.panNumber}","panURL":"${this.state.panImage}"}`,
          contentID: '2'
        })
        .then(async (response) => {
          console.log(response, "response");

          if (response.data.success === true) {
            alert('Pan Card Added !!');
            this.setState({
              attachPanModal: false,
              showPanDisplay: 'none',
              panImage: '',
              isPanUploaded: false,
              panNumber: false,
              panRead: false,

            });

            this.setState({
              uniquePayeeBanksDropDown: [],
              selectedPayeeDropDown: this.state.panPmsID,
              selectedPayeeBankDropDown: "",
            });
            await this.getUniquePayeeBanksDropDown();
          } else {

          }
        })
        .catch((err) => {
          alert(err);
        });



    }

  }

  onChangeItemDropDown = async (e, item) => {
    // console.log(e.target.value, item);
    const { initialFormItems } = this.state;
    initialFormItems.filter((itemForm, i) => {
      if (itemForm.id == item.id) {
        // console.log("id matched");
        if (e.target.value == "Others") {
          // console.log("others");
          return (
            (itemForm.item = ""),
            (itemForm.isDisabled = false),
            (itemForm.itemDropDownName = "Others")
          );
        } else {
          return (
            (itemForm.item = e.target.value),
            (itemForm.isDisabled = true),
            (itemForm.itemDropDownName = e.target.value)
          );
        }
      }
    });
    // console.log(initialFormItems, 'initialFormItems')
    await this.setState({
      initialFormItems: initialFormItems,
    });
  };

  handleChangeDateRange = async (e) => {
    // console.log(e.target.value);
    let value = e.target.value;
    // console.log();2222
    if (value == 2222) {
      await this.setState({
        dateRangeSelected: e.target.value,
        startDate: "",
        endDate: "",
      });
      await this.toggle();
    } else {
      this.state.uniqueDateRanges.filter(async (eachDate, i) => {
        if (i == value) {
          // console.log("true", eachDate);
          await this.setState({
            dateRangeSelected: e.target.value,
            startDate: eachDate.startDate,
            endDate: eachDate.endDate,
            billingMonth: eachDate.billingMonth,
          });
        }
      });
    }
  };

  toggle = async () => {
    await this.setState({
      modal: !this.state.modal,
    });
  };

  setStartEndDates = async () => {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    // eslint-disable-next-line no-useless-concat
    const newMon = String(month).length == 1 ? `0${month}` : month;
    const newDate = String(day).length == 1 ? `0${day}` : day;
    // const iDate = `2020-12-01T00:00:00.000Z`
    // const mDate = year + "-" + month + "-" + day;
    const mDate = `${year}-${newMon}-${newDate}T23:59:00.000Z`;
    const iDate = `${year}-${newMon}-01T00:00:00.000Z`;

    await this.setState({
      startDate: iDate,
      endDate: mDate,
      // startDate: "2020-12-01",
      // endDate: "2020-12-31"
    });
  };

  toggleCloseModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: "",
    });
  };

  toggleConfirmModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: 2222,
    });
  };

  getDataMain = async () => {
    const res = await axios
      .post(`${Config.hostName}/tms/getTripStatsCountNewMis`, {
        startDate: this.state.startDateTripModal,
        endDate: this.state.endDateTripModal,
        locationId: this.state.selectedTripModalLocation,
        clientName: this.state.selectedTripModalClient,
        segment: this.state.selectedTripModalSegment,
        vehicleNumber: this.state.selectedTripModalVehicleNumber,
      })
      .then(async (res) => {
        const widgetData = [];
        const clientData = [];
        console.log(res.data.data);

        if (res.data.data.length > 0) {
          res.data.data.filter((item) => {
            if (item.Clientname === "All") {
              console.log(item, "item");
              return widgetData.push(item);
            } else {
              return clientData.push(item);
            }
          });
          await this.setState({
            apiData: res.data.data,
            widgetData: widgetData,
            clientData: clientData,
          });
        } else {
          await this.setState({
            apiData: [],
            widgetData: [],
            clientData: [],
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  changeRemark = async (e) => {
    e.preventDefault();
    await this.setState({ remarks: e.target.value });
    let { remarks } = this.state;
    let final = this.state.remarks;
    final = final.replace(/[\W_]+/g, " ");
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let error = format.test(final);
    if (error) {
      this.setState({
        color: "red",
      });
    }
    await this.setState({
      remarks: final,
    });
    console.log(this.state.remarks);
    // this.setState({
    //     remarks: e.target.value.replace(/[\W_]+/g," ")
    // })
  };

  getClientWiseData = async () => {
    const { clientData } = this.state;
    if (clientData.length > 0) {
      const clientNamesUnique = [];
      const map = clientData.map((eachItem) => {
        clientNamesUnique.push(eachItem.Clientname);
      });
      const requiredClientData = [];

      const filteredArray = clientNamesUnique
        .map((item) => item)
        .filter((value, index, self) => self.indexOf(value) === index);
      filteredArray.map((fI) => {
        requiredClientData.push({
          clientName: fI,
          data: [],
        });
      });
      requiredClientData.filter((fItem) => {
        clientData.map((item) => {
          if (fItem.clientName == item.Clientname) {
            const dat = fItem.data;
            return dat.push(item);
          }
        });
      });
      await this.setState({
        uniqueClients: filteredArray,
        requiredClientData: requiredClientData,
      });
    } else {
      await this.setState({
        uniqueClients: [],
        requiredClientData: [],
      });
    }
  };

  toggleOpenViewTripDetails = async () => {
    // console.log(this.state.viewTripDetailsModal);
    await this.setState({
      viewTripDetailsModalLoading: true,
    });

    await this.setState({
      viewTripDetailsModal: true,
      viewTripDetailsModalLoading: false,
    });
  };

  toggleCloseViewTripDetails = async () => {
    await this.setState({
      viewTripDetailsModal: false,
    });
  };

  attachTrips = async () => {
    const {
      selectedTripModalLocation,
      selectedTripModalClient,
      selectedTripModalSegment,
      selectedTripModalVehicleNumber,
    } = this.state;
    // console.log(selectedTripModalLocation, selectedTripModalClient, selectedTripModalSegment, selectedTripModalVehicleNumber)
    await this.setState({
      addTripToBmsLoading: true,
    });
    const myArray = this.state.selectedTripId.split(",");
    await this.setState({
      viewTripDetailsModal: false,
      addTripToBmsLoading: false,
      bmsCreateReqTripIds: myArray,
    });
    alert("Trips attached Succesfully");
  };

  getUniqueLocationsDropDownTripModal = async () => {
    await axios
      .get(`${Config.hostName}/unique/locations`)
      .then(async (res) => {
        await this.setState({
          uniqueLocationsDropDownTripModal: res.data.data,
        });
      })
      .catch((error) => {
        alert("getUniqueLocationsDropDownTripModal : ", error);
      });
  };

  getUniqueClientsDropDownTripModal = async () => {
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: this.state.selectedTripModalLocation,
        clientName: "",
      })
      .then(async (res) => {
        // console.log(res.data);
        await this.setState({
          uniqueClientsDropDownTripModal: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  getUniqueSegmentsDropDownTripModal = async () => {
    await axios
      .post(`${Config.hostName}/unique/segments`, {
        locationId: this.state.selectedTripModalLocation,
        clientName: this.state.selectedTripModalClient,
      })
      .then((res) => {
        this.setState({
          uniqueSegmentsDropDownTripModal: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  changeLocationTripModal = async (event) => {
    event.preventDefault();
    await this.setState({
      uniqueClientsDropDownTripModal: [],
      uniqueSegmentsDropDownTripModal: [],
      selectedTripModalLocation: event.target.value,
      selectedTripModalClient: "",
      selectedTripModalSegment: "",
    });
    await this.getUniqueClientsDropDownTripModal();
    // await this.setState({ style: { display: "none" } });
  };

  changeClientTripModal = async (event) => {
    event.preventDefault();
    await this.setState({
      uniqueSegmentsDropDownTripModal: [],
      selectedTripModalClient: event.target.value,
      selectedTripModalSegment: "",
    });
    await this.getUniqueSegmentsDropDownTripModal();
    // await this.setState({ style: { display: "none" } });
  };

  changeSegmentTripModal = async (event) => {
    event.preventDefault();
    const value = event.target.value;
    await this.setState({ selectedTripModalSegment: value });
    // await this.setState({ style: { display: "none" } });
  };

  handleSearchTripModal = async () => {
    await this.setState({
      styleTripModal: {},
    });

    if (this.state.selectedTripId.trim() == "") {
      this.setState({
        tripIdError: "Please Enter TripId's to search",
        selectedTripId: "",
      });
    } else {
      await axios
        .post(`${Config.hostName}/bms/attachtmstrips`, {
          tripIDs: this.state.selectedTripId,
        })
        .then((res) => {
          if (res.data.success == false) {
            this.setState({
              tripIdError: res.data.message,
              uniqueSegmentsDropDownTripModal: "",
              selectedTripId: "",
            });
          } else {
            this.setState({
              uniqueSegmentsDropDownTripModal: res.data.data,
              tripIdError: "",
            });
          }
        })
        .catch((err) => {
          this.setState({
            uniqueSegmentsDropDownTripModal: "",
            tripIdError: err,
            selectedTripId: "",
          });
        });
    }

    // await this.getDataMain();
    // await this.getClientWiseData();
    // this.toggleCloseViewTripDetails();
    await this.setState({
      styleTripModal: { display: "none" },
    });
  };

  toggleTripModal = async () => {
    await this.setState({
      modalTripModal: !this.state.modalTripModal,
    });
  };

  setStartEndDatesTripModal = async () => {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    // eslint-disable-next-line no-useless-concat
    const newMon = String(month).length == 1 ? `0${month}` : month;
    const newDate = String(day).length == 1 ? `0${day}` : day;
    // const iDate = `2020-12-01T00:00:00.000Z`
    // const mDate = year + "-" + month + "-" + day;
    const mDate = `${year}-${newMon}-${newDate}T23:59:00.000Z`;
    const iDate = `${year}-${newMon}-01T00:00:00.000Z`;

    await this.setState({
      startDateTripModal: iDate,
      endDateTripModal: mDate,
      // startDate: "2020-12-01",
      // endDate: "2020-12-31"
    });
  };

  toggleCloseModalTripModal = async () => {
    await this.toggleTripModal();
    await this.setState({
      dateRangeSelectedTripModal: "",
    });
  };

  toggleConfirmModalTripModal = async () => {
    await this.toggleTripModal();
    await this.setState({
      dateRangeSelectedTripModal: 2222,
    });
  };

  handleChangeDateRangeTripModal = async (e) => {
    // console.log(e.target.value);
    let value = e.target.value;
    // console.log();2222
    if (value == 2222) {
      await this.setState({
        dateRangeSelectedTripModal: e.target.value,
        startDateTripModal: "",
        endDateTripModal: "",
      });
      await this.toggleTripModal();
    } else {
      this.state.uniqueDateRanges.filter(async (eachDate, i) => {
        if (i == value) {
          // console.log("true", eachDate);
          await this.setState({
            dateRangeSelectedTripModal: e.target.value,
            startDateTripModal: eachDate.startDate,
            endDateTripModal: eachDate.endDate,
          });
        }
      });
    }
  };

  deleteAttachedTripIds = async () => {
    await this.setState({
      bmsCreateReqTripIds: "",
      selectedTripModalLocation: "",
      selectedTripModalClient: "",
      selectedTripModalSegment: "",
      selectedTripModalVehicleNumber: "",
      uniqueLocationsDropDownTripModal: [],
      uniqueClientsDropDownTripModal: [],
      uniqueSegmentsDropDownTripModal: [],
    });
  };

  isValidPanCardNo = async (panCardNo) => {
    // Regex to check valid
    // PAN Number
    let regex = new RegExp(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/);

    // if PAN Number
    // is empty return false
    if (panCardNo == null) {
      return 0;
    }

    // Return true if the PAN NUMBER
    // matched the ReGex
    if (regex.test(panCardNo) == true) {
      return 1;
    }
    else {
      return 0;
    }
  }

  getPanNumber = async (file) => {
    const data2 = await Tesseract.recognize(
      `${file}`,
      'eng',
      { logger: m => console.log(m) }
    ).then(async ({ data: { text } }) => {
      // console.log(text);
      // alert(text);

      let text1 = [];
      let final = ['e'];

      let n = text.split('\n').map(async (e) => {
        let s = e;
        s = s.replace(/^\s+|\s+$/gm, '');
        s = s.replace(/\s+$/gm, '');
        s = s.replace(/^\s+/gm, '');
        text1.push(s);
      });

      let filtered = [];
      await text1.map(async (e) => {
        if (e != '') {
          filtered.push(e);
        }
      })

      let panNo = await filtered.map(async (e) => {
        console.log(e.split(' '));
        await e.split(' ').map(async (e) => {
          if (e.length >= 10) {
            // console.log(e);
            let isPan = await this.isValidPanCardNo(e);
            if (isPan) {
              final[0] = e;
              // console.log(e);
              return e;
            }
          }

        })
      });
      return final[0];
    })
    if (data2 != 'e') {
      return data2;
    } else {
      return false;
    }


    // const worker = createWorker({
    //   logger: m => console.log(m)
    // });

    // (async () => {
    //   await worker.load();
    //   await worker.loadLanguage('eng');
    //   await worker.initialize('eng');
    //   const { data: { text } } = await worker.recognize(PANNN);
    //   console.log(text);
    //   await worker.terminate();
    // })();

  };

  delay = async (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  uploadPan = async (e) => {
    const filesObj = e.target.files;
    console.log(filesObj[0]);
    console.log(filesObj[0].name);
    const formData = new FormData();
    formData.append("media", filesObj[0], `${filesObj[0].name}`);

    await this.setState({
      isPanUploaded: false,
      showPanDisplay: "block",
      panRead: false,
    });



    const fileURL = await this.uploadPanFileAndGetUrl(formData);
    await this.delay(1000);
    const data = await this.getPanNumber(fileURL);
    console.log(data);
    if (data) {
      await this.setState({
        panNumber: data,
        isPanUploaded: true,
        panImage: fileURL,
      })
    } else {
      await this.setState({
        panNumber: '',
        isPanUploaded: true,
        showPanDisplay: 'none',
        panRead: true,
        // panDisabled: false,
        // panImage: fileURL,
      })
    }



  };

  uploadPanFileAndGetUrl = async (formData) => {
    // console.log("uploading formdata")
    const fileData = await axios({
      method: "POST",
      url: `${Config.hostName}/unique/upload/any-file`,
      timeout: 3 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: formData,
    });

    return fileData.data.url;
  };

  deletePanDetails = async () => {
    await this.setState({
      panImage: "",
      panNumber: "",
      isPanUploaded: false,
      showPanDisplay: "none",
    });
  };

  render() {
    const closeBtn = (
      <div className="col-sm-1 ml-auto">
        <button
          type="submit"
          // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
          className="btn btn-danger overflow-hidden  px-auto"
          onClick={this.toggleCloseViewTripDetails}
        >
          <CloseIcon />
        </button>
      </div>
    );

    const defaultColDef = {
      flex: 1,
      minWidth: 100,
      // height: 100,
      resizable: true,
    };

    const {
      style,
      isAddPayeeBankModalOpen,
      isAddPayeeModalOpen,
      initialFormItems,
      attachments,
      selectedBanksList,
      isChangeCategory,
      addPayeeAttachments,
      isEditPayeeBankModalOpen,
      isEditPayeeBankAmountEditModalOpen,
      selectedPayeeDropDownName,
      modal,
      viewTripDetailsModal,
      widgetData,
      requiredClientData,
      viewTripDetailsModalLoading,
      addTripToBmsLoading,
      modalTripModal,
      styleTripModal,
      isCreateButtonLoading,
    } = this.state;
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;

    return (
      <div>
        <div>
          <div id="cover-spin" style={style}></div>
          <div className="container-fluid">
            <div className="edit-profile">
              <div className="row ">
                <div className="col-xl-12">
                  <Alert />
                  <nav
                    className="card-body "
                    style={{ backgroundColor: "none" }}
                  >
                    <div style={{ paddingTop: "8px", width: "100%" }}>
                      <div className="row ">
                        <div className="col-sm-3">
                          <FormGroup>
                            <Input
                              type="select"
                              name="category"
                              required
                              value={this.state.category}
                              id="category"
                              onChange={this.changeCategory}
                            >
                              <option value={""} disabled selected>
                                Select Category
                              </option>

                              {this.state.uniqueCategories.map(
                                (categoryItem) => (
                                  <option value={categoryItem}>
                                    {categoryItem}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </div>

                        <div className="col-sm-3">
                          <FormGroup>
                            <Input
                              required
                              type="select"
                              name="priority"
                              value={this.state.priority}
                              id="priority"
                              onChange={this.changePriority}
                            >
                              <option value={""} disabled selected>
                                Select Priority
                              </option>

                              <option value="P1 (Today)">P1-Today</option>
                              <option value="P2 (3 Days)">P2-3 Days</option>
                              <option value="P3 (1 Week)">P3-1 Week</option>
                            </Input>
                          </FormGroup>
                        </div>

                        <div className="col-sm-3">
                          <Form inline>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                              {/* <Input */}
                              <Label for="billingDate" className="mr-sm-2">
                                Tnxn Date
                              </Label>
                              <Input
                                type="date"
                                name="billingDate"
                                id="billingDate"
                                placeholder="Select Billing Date"
                                value={this.state.billingDate}
                                onChange={(e) => {
                                  this.setState({
                                    billingDate: e.target.value,
                                  });
                                }}
                              />

                              {/* <input
                                                            type="date"
                                                            value={this.state.billingDate}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    billingDate: e.target.value,
                                                                });
                                                            }}
                                                            className="form-control billingDateCalenderDate"
                                                            placeholder="Select Billing Date"
                                                        /> */}
                              {/* </Input> */}
                            </FormGroup>
                          </Form>
                        </div>
                      </div>

                      {/* Project Details */}

                      <div className="bms-create-req-items-main">
                        <div className="bms-create-req-items-main-label">
                          <span className="bms-create-req-items-label">
                            Project Details :{" "}
                          </span>
                        </div>

                        <div className="row ">
                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="locationid"
                                value={this.state.selectedLocationDropDown}
                                id="locationid"
                                onChange={this.changeLocation}
                              >
                                <option value={""} disabled selected>
                                  Select Location
                                </option>

                                {this.state.uniqueLocationsDropDown.length >
                                  1 &&
                                  this.state.uniqueLocationsDropDown.map(
                                    (locationid) => (
                                      <option value={locationid.Locationid}>
                                        {locationid.name}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </FormGroup>
                          </div>

                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="clientName"
                                value={this.state.selectedClientDropDown}
                                id="clientName"
                                onChange={this.changeClient}
                              >
                                <option value={""} disabled selected>
                                  Select Client
                                </option>

                                {this.state.uniqueClientsDropDown.map(
                                  (client) => (
                                    <option value={client.ClientName}>
                                      {client.ClientName}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </div>

                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="routeNo"
                                value={this.state.selectedSegmentDropDown}
                                id="routeNo"
                                onChange={this.changeSegment}
                              >
                                <option value={""} disabled selected>
                                  Select Segment
                                </option>

                                {this.state.uniqueSegmentsDropDown.map(
                                  (segment) => (
                                    <option value={segment.CCxrefID}>
                                      {segment.Segment}
                                    </option>
                                  )
                                )}

                                {/* <option value={0}>All Segments</option> */}
                              </Input>
                            </FormGroup>
                          </div>

                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="dateRange"
                                value={this.state.dateRangeSelected}
                                id="dateRange"
                                onChange={this.handleChangeDateRange}
                              >
                                <option
                                  className="bms-dashboaord-date-range"
                                  value={""}
                                  disabled
                                >
                                  Select Billing Period
                                </option>

                                {this.state.uniqueDateRanges.map(
                                  (eachItem, i) => (
                                    <option value={i} key={i}>
                                      {eachItem.billingMonth}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </div>
                        </div>
                      </div>

                      {/* Item Details */}

                      <div className="bms-create-req-items-main">
                        <div className="bms-create-req-items-main-label">
                          <span className="bms-create-req-items-label">
                            Item Details :{" "}
                          </span>
                        </div>

                        <div className="form-row">
                          {initialFormItems.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="form-group col-md-12 col-md-12 row showcase_row_area"
                              >
                                <div className="col-md-5">
                                  <FormGroup>
                                    <Input
                                      type="select"
                                      name="item"
                                      required
                                      value={item.itemDropDownName}
                                      onChange={(e) =>
                                        this.onChangeItemDropDown(e, item)
                                      }
                                    >
                                      <option value={""} disabled selected>
                                        Select Item
                                      </option>

                                      {this.state.uniqueItems.map((Item) => (
                                        <option value={Item}>{Item}</option>
                                      ))}
                                    </Input>
                                  </FormGroup>
                                </div>

                                <div className="col-md-3 showcase_text_area">
                                  <input
                                    placeholder="Name"
                                    formControlName={item.id}
                                    type="text"
                                    className="form-control"
                                    value={item.item}
                                    disabled={item.isDisabled}
                                    onChange={(e) =>
                                      this.formControlChangeItemName(item.id, e)
                                    }
                                  />
                                </div>

                                <div className="col-md-2 showcase_text_area">
                                  <input
                                    placeholder="Amount"
                                    formControlName={item.id}
                                    type="number"
                                    className="form-control"
                                    value={item.amount}
                                    onChange={(e) =>
                                      this.formControlChangeItemAmount(
                                        item.id,
                                        e
                                      )
                                    }
                                  />
                                </div>
                                {i + 1 === initialFormItems.length && (
                                  <div className="col-sm-1 ml-auto">
                                    <button
                                      type="submit"
                                      // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                                      className="btn btn-success overflow-hidden mb-3 px-auto"
                                      onClick={this.addNewItem}
                                    >
                                      <AddIcon />
                                    </button>
                                  </div>
                                )}

                                <div className="col-sm-1 ml-auto">
                                  <button
                                    type="submit"
                                    // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                                    className="btn btn-danger overflow-hidden mb-3 px-auto"
                                    onClick={() =>
                                      this.deleteExtraItem(item.id)
                                    }
                                  >
                                    <CloseIcon />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {/* Trip Details */}

                      <div>
                        {this.state.getid == null &&
                          this.state.category == "Freight Expense" && (
                            <div className="row ">
                              <div className="bms-create-req-attachments-main">
                                <span className="bms-create-req-attachments-label">
                                  Trip Details :{" "}
                                </span>

                                <button
                                  type="submit"
                                  className="btn btn-danger overflow-hidden mb-3 px-auto"
                                  onClick={this.toggleOpenViewTripDetails}
                                >
                                  {viewTripDetailsModalLoading && (
                                    <i
                                      className="fa fa-refresh fa-spin"
                                      style={{ marginRight: "5px" }}
                                    />
                                  )}

                                  {!viewTripDetailsModalLoading && (
                                    <i
                                      className="fa fa-th"
                                      style={{ marginRight: "5px" }}
                                    />
                                  )}
                                  {viewTripDetailsModalLoading && (
                                    <span>Processing ...</span>
                                  )}
                                  {!viewTripDetailsModalLoading && (
                                    <span>Attach Trips</span>
                                  )}
                                </button>
                              </div>
                            </div>
                          )}

                        {this.state.getid != null && (
                          <div className="row ">
                            <div className="bms-create-req-attachments-main">
                              <span className="bms-create-req-attachments-label">
                                Trip Details :{" "}
                              </span>
                              <button
                                type="submit"
                                className="btn btn-danger overflow-hidden mb-3 px-auto"
                                onClick={this.handleOpenViewTrips}
                              >
                                {isCreateButtonLoading && (
                                  <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                  />
                                )}

                                {!isCreateButtonLoading && (
                                  <i
                                    className="fa fa-th"
                                    style={{ marginRight: "5px" }}
                                  />
                                )}

                                {isCreateButtonLoading && (
                                  <span>Processing ...</span>
                                )}
                                {!isCreateButtonLoading && (
                                  <span>View Attach Trips</span>
                                )}
                              </button>
                            </div>
                          </div>
                        )}

                        <div className="row">
                          <div className="bms-create-request-attachments">
                            {this.state.bmsCreateReqTripIds.length > 0 && (
                              <h6>
                                <span className="badge badge-warning">
                                  {this.state.bmsCreateReqTripIds.length}
                                </span>
                                <span className="badge badge-success">
                                  Trips are attached successfully
                                </span>
                                {/* <span>
                                                                    <i
                                                                        class="fa fa-times-circle"
                                                                        aria-hidden="true"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={this.deleteAttachedTripIds}
                                                                    >
                                                                    </i>
                                                                </span> */}
                              </h6>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Attachments Details */}

                      <div>
                        <div className="row ">
                          <div className="bms-create-req-attachments-main">
                            <span className="bms-create-req-attachments-label">
                              Attachments :{" "}
                            </span>

                            <label className="custom-file-upload">
                              {/* <button
                                                                type="submit"
                                                                className="btn bms-attachments-card-button ml-auto"
                                                            > */}
                              <input
                                name="Upload"
                                type="file"
                                className="bms-attachments-card-button ml-auto"
                                multiple
                                onChange={this.onFileUpload}
                              />
                              <CloudUploadIcon /> Upload
                              {/* </button> */}
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="bms-create-request-attachments">
                            {attachments.length > 0 &&
                              attachments.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="row bms-create-request-attachments-main-card"
                                  >
                                    <div
                                      key={i}
                                      className="bms-attachments-card"
                                    >
                                      <span className="bms-attachments-card-item">
                                        <div className="bms-attachments-card-item-file-name-main">
                                          <span className="bms-attachments-card-item-file-name">
                                            {item.name}
                                          </span>
                                          <span
                                            className="bms-attachments-card-button-delete"
                                            onClick={() =>
                                              this.deleteAttachment(item.name)
                                            }
                                          >
                                            <DeleteIcon />
                                          </span>
                                        </div>

                                        {!item.isUploaded && (
                                          <span className="bms-attachments-card-progress-bar-loader">
                                            <LinearProgress />
                                          </span>
                                        )}

                                        {item.isUploaded && (
                                          <span className="bms-attachments-card-item-file-success-status">
                                            File Uploaded Succesfully
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      {/** Payee Details */}

                      <div className="bms-create-req-items-main">
                        <div className="bms-create-req-items-main-label">
                          <span className="bms-create-req-items-label">
                            Payee Details :{" "}
                          </span>
                        </div>

                        <div className="row ">
                          {/** Select Payee Location */}
                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="locationid"
                                value={this.state.selectedLocationPayeeDropDown}
                                id="locationid"
                                onChange={this.changeLocationPayee}
                              >
                                <option value={""} disabled selected>
                                  Select Payee Location
                                </option>

                                {this.state.uniqueLocationsDropDown.length >
                                  1 &&
                                  this.state.uniqueLocationsDropDown.map(
                                    (locationid) => (
                                      <option value={locationid.Locationid}>
                                        {locationid.name}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </FormGroup>
                          </div>
                          {/** Select Payee Type */}
                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="payeeType"
                                value={this.state.selectedPayeeTypeDropDown}
                                id="payeeType"
                                onChange={this.changePayeeType}
                              >
                                <option value={""} disabled selected>
                                  Select Payee Type
                                </option>
                                {this.state.uniquePayeeTypesDropDown.map(
                                  (payeeTypeItem) => (
                                    <option value={payeeTypeItem.PMStypeID}>
                                      {payeeTypeItem.PMSType}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </div>
                          {/** Select Payee Name*/}
                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="payee"
                                value={this.state.selectedPayeeDropDown}
                                id="payee"
                                onChange={this.changePayee}
                              >
                                <option value={""} disabled selected>
                                  Select Payee Name{" "}
                                </option>
                                {this.state.selectedPayeeTypeDropDown.length >
                                  0 && (
                                    <option
                                      value={88888}
                                      style={{
                                        fontSize: "2rem",
                                        color: "orange",
                                      }}
                                    >
                                      {" "}
                                      + Add Payee{" "}
                                    </option>
                                  )}
                                {this.state.uniquePayeesDropDown.map(
                                  (payeeItem) => (
                                    <option value={payeeItem.PMSID}>
                                      {payeeItem.fullName}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </div>
                          {/** Select Payee Bank */}
                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="payeeBank"
                                value={this.state.selectedPayeeBankDropDown}
                                id="payeeBank"
                                onChange={this.changePayeeBank}
                              >
                                <option value={""} disabled selected>
                                  Select Payee Bank
                                </option>
                                {this.state.uniquePayeeBanksDropDown.map(
                                  (payeeBankItem) => (
                                    <option
                                      key={payeeBankItem.payeebankID}
                                      value={payeeBankItem.payeebankID}
                                      className="pmsPayeeBankDropdownValue"
                                    >
                                      {payeeBankItem.bankname}&nbsp;-&nbsp;
                                      {payeeBankItem.AccountNumber}
                                      {/* <span><EditIcon /></span> */}
                                    </option>
                                  )
                                )}
                                {this.state.selectedPayeeDropDown.length >
                                  0 && (
                                    <option value={99999}>
                                      {" "}
                                      + Add Payee Bank{" "}
                                    </option>
                                  )}
                              </Input>
                            </FormGroup>
                          </div>
                          {/** Enter Amount */}
                          <div className="col-sm-3 showcase_text_area">
                            <input
                              placeholder="Enter Amount"
                              formControlName={
                                this.state.selectedPayeeBankAmountValue
                              }
                              type="number"
                              className="form-control"
                              value={this.state.selectedPayeeBankAmountValue}
                              onChange={this.changePayeeBankAmountValue}
                            />
                          </div>
                          {/** Add Payee Details Button */}
                          <div className="col-sm-3 ml-5">
                            <button
                              type="submit"
                              className="btn btn-danger overflow-hidden mb-3 px-auto createNewPayeeSelectedBankCard"
                              onClick={(e) =>
                                this.createNewPayeeSelectedBankCard(e)
                              }
                            >
                              <AddIcon /> Add
                            </button>
                          </div>
                        </div>

                        <div className="row bms-create-req-payee-details-card-view-main-container">
                          {selectedBanksList.length > 0 &&
                            selectedBanksList.map((bankListItem, i) => {
                              return (
                                <div
                                  key={i}
                                  className="card col-sm-4 bmsCreateReqPayeeDetailsCardView"
                                >
                                  {/* BankId: {bankListItem.payeebankID} */}
                                  <div className="bmsCreateReqPayeeDetailsCardViewLeft">
                                    <img
                                      className="bmsCreateReqPayeeDetailsCardViewLeftBankIcon"
                                      src={BankIcon}
                                      alt={i}
                                      width="50"
                                      height="60"
                                    />
                                  </div>

                                  <div className="bmsCreateReqPayeeDetailsCardViewRight">
                                    <div className="bmsViewReqPayeeDetailsCardViewRightName">
                                      <span className="bmsViewReqPayeeDetailsCardViewRightNameValue">
                                        Name:{" "}
                                        {bankListItem.fullname
                                          ? bankListItem.fullname
                                          : " - "}
                                      </span>
                                    </div>

                                    <div className="bmsViewReqPayeeDetailsCardViewRightBenefeciaryName">
                                      <span className="bmsViewReqPayeeDetailsCardViewRightBenefeciaryNameValue">
                                        Benefeciary Name:{" "}
                                        {bankListItem.beneficiaryName
                                          ? bankListItem.beneficiaryName
                                          : " - "}
                                      </span>
                                    </div>

                                    <div className="bmsCreateReqPayeeDetailsCardViewRightAccountNumber">
                                      <span className="bmsCreateReqPayeeDetailsCardViewRightAccountNumberValue">
                                        A/C No:{" "}
                                        {bankListItem.AccountNumber
                                          ? bankListItem.AccountNumber
                                          : " - "}
                                      </span>
                                    </div>
                                    <div className="bmsCreateReqPayeeDetailsCardViewRightBankName">
                                      <span className="bmsCreateReqPayeeDetailsCardViewRightBankNameValue">
                                        Bank :{" "}
                                        {bankListItem.bankname
                                          ? bankListItem.bankname
                                          : " - "}
                                      </span>
                                    </div>
                                    <div className="bmsCreateReqPayeeDetailsCardViewRightIfsc">
                                      <span className="bmsCreateReqPayeeDetailsCardViewRightIfscValue">
                                        IFSC :{" "}
                                        {bankListItem.IFSC
                                          ? bankListItem.IFSC
                                          : " - "}
                                      </span>
                                    </div>

                                    {/* <div className="bmsCreateReqPayeeDetailsCardViewRightBillingDate">
                                                                    <span className="bmsCreateReqPayeeDetailsCardViewRightBillingDateValue">
                                                                        Bill Date : {bankListItem.BillingDate ? bankListItem.BillingDate : " - "}
                                                                    </span>
                                                                </div> */}

                                    <div className="bmsCreateReqPayeeDetailsCardViewRightAmount">
                                      <span className="bmsCreateReqPayeeDetailsCardViewRightAmountValue">
                                        Amount :{" "}
                                        {bankListItem.amount
                                          ? bankListItem.amount
                                          : " - "}
                                      </span>
                                      <span
                                        className="bmsCreateReqPayeeDetailsCardViewRightMostEditIconAmount"
                                        onClick={() =>
                                          this.editPayeeBankAmount(
                                            bankListItem.payeebankID
                                          )
                                        }
                                      >
                                        <EditIcon
                                          color="secondary"
                                          fontSize="small"
                                        />
                                      </span>
                                    </div>
                                  </div>

                                  <div className="bmsCreateReqPayeeDetailsCardViewRightMost">
                                    <div
                                      className="bmsCreateReqPayeeDetailsCardViewRightMostEditIcon"
                                      onClick={() =>
                                        this.editPayeeBank(
                                          bankListItem.payeebankID
                                        )
                                      }
                                    >
                                      <EditIcon color="danger" />
                                    </div>
                                    <div
                                      className="bmsCreateReqPayeeDetailsCardViewRightMostCloseIcon"
                                      onClick={() =>
                                        this.deletePayeeBank(
                                          bankListItem.payeebankID
                                        )
                                      }
                                    >
                                      <CloseIcon color="danger" />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      <AvForm>
                        {/* Total Amount */}
                        <div className="col-md-4">
                          <AvField
                            name="totalAmount"
                            label="Total Amount"
                            value={this.state.totalAmount}
                            onChange={(e) => {
                              this.setState({
                                totalAmount: e.target.value,
                              });
                            }}
                            type="number"
                            disabled
                            labelClass="bms-create-req-total-amount"
                            placeholder="Total Amount"
                          />
                        </div>

                        {/* Remarks */}
                        <div className="col-md-12">
                          <AvField
                            name="Remarks"
                            label="Remarks"
                            value={this.state.remarks}
                            onChange={(e) => {
                              this.changeRemark(e);
                            }}
                            style={{
                              height: "20vh",
                            }}
                            labelClass="bms-create-req-remarks"
                            type="textarea"
                            placeholder="Please Enter Remarks"
                          />
                          <h6 style={{ color: this.state.color }}>
                            {" "}
                            <b>
                              {" "}
                              **No Special character like $%&@ and double space
                              is not allowed{" "}
                            </b>
                          </h6>
                        </div>

                        <div className="col-sm-2 ml-auto mt-3">
                          <button
                            type="submit"
                            // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                            className="btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                            onClick={this.createNewRequest}
                          >
                            Create
                          </button>
                        </div>
                      </AvForm>

                      {/* isChangeCategory */}

                      <div>
                        <Modal
                          size="lg"
                          // style={{ maxWidth: '1600px', width: '70%' }}
                          centered={true}
                          isOpen={isChangeCategory}
                        >
                          <ModalHeader>
                            Do you want to Change the Category ? It will clear
                            all Items you entered
                          </ModalHeader>
                          <ModalFooter>
                            <Button
                              color="success"
                              onClick={this.changeCategoryAnotherAgain}
                            >
                              Yes
                            </Button>{" "}
                            <Button
                              color="danger"
                              onClick={async () => {
                                await this.setState({
                                  isChangeCategory: false,
                                });
                              }}
                            >
                              No
                            </Button>{" "}
                          </ModalFooter>
                        </Modal>
                      </div>



                      <div>
                        <Modal
                          // size="lg"
                          style={{ maxWidth: "1600px", width: "70%" }}
                          centered={true}
                          // isOpen={true}
                          isOpen={this.state.attachPanModal}
                        // toggle={this.toggleAddPayeeBankModal}
                        >
                          <ModalFooter style={{ width: '100% !important', display: 'block' }}>
                            <div className="row m-3">
                              <div className="col-sm-9" style={{ textAlign: 'left' }}>
                                <span className="bms-view-req-modal-header-labelAddPayee" style={{ color: 'black' }}>
                                  PAN card not found
                                </span>
                                <div class="invalid-feedback" style={{ display: 'block', fontSize: '11px', fontWeight: '500', marginTop: '-6px', color: 'red' }}>
                                  *Upload PAN card to proceed
                                </div>
                              </div>

                              <div className="col-sm-3" style={{ textAlign: 'right' }}>
                                <Button
                                  color="danger"
                                  onClick={this.toggleAttachPanModal}
                                >
                                  <CloseIcon />
                                </Button>
                              </div>
                            </div>
                          </ModalFooter>

                          <ModalBody>

                            <div className="container">

                              <div className="row mb-3">

                                <div className="col-sm-12 mb-3">
                                  <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-3 col-form-label textForm">PAN Card :</label>


                                    {this.state.showPanDisplay ==
                                      "none" ? (
                                      <div class="col-sm-5" style={{ textAlign: "left" }}>
                                        <label className="custom-file-upload material-like-btn">
                                          <input
                                            name="Upload"
                                            type="file"
                                            accept="image/*"
                                            className="bms-attachments-card-button ml-auto"
                                            multiple
                                            onChange={
                                              this.uploadPan
                                            }
                                            required
                                          />
                                          <CloudUploadIcon />{" "}
                                          Upload
                                        </label>
                                        {this.state.panRead == true &&
                                          <div class="invalid-feedback" style={{ display: 'block', fontSize: '14px', fontWeight: '500' }}>
                                            <span>*</span> Please try again with <b> Clear Image.</b>
                                          </div>
                                        }
                                      </div>
                                    ) : (
                                      <div className="col-sm-8">
                                        <div class="row">
                                          {this.state
                                            .isPanUploaded ===
                                            true && (
                                              <div class="col-sm-5">
                                                <div style={{ width: '230px' }}>
                                                  <img
                                                    src={this.state.panImage}
                                                    style={{ width: "100%", height: "auto" }} />
                                                </div>
                                              </div>
                                            )}

                                          <div class="col-sm-2 alignDeleteButton mt-2">
                                            {this.state
                                              .isPanUploaded ===
                                              false ? (
                                              <span className="bms-attachments-card-progress-bar-loader">
                                                <LinearProgress />
                                              </span>
                                            ) : <span className="bms-attachments-card-button-delete" style={{ marginTop: "22%", marginLeft: "5%", }}>
                                              <DeleteIcon onClick={this.deletePanDetails} />
                                            </span>}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {/* <div class="col-sm-2 mt-2">
                                        <span className="bms-attachments-card-progress-bar-loader">
                                          <LinearProgress />
                                        </span>
                                      </div> */}

                                  </div>
                                </div>



                                {
                                  this.state.panNumber != '' &&
                                  <div className="col-sm-12 mb-3">
                                    <div class="form-group row">
                                      <label for="staticEmail" class="col-sm-3 col-form-label textForm">PAN No. :</label>
                                      <div class="col-sm-5">
                                        <input type="text"
                                          value={this.state.panNumber}
                                          disabled={true}
                                          class="form-control textForm" required style={{ marginLeft: '0px' }} id="staticEmail" placeholder="Enter Pancard No" />
                                      </div>
                                    </div>
                                  </div>
                                }



                                <div className="col-sm-12" style={{ textAlign: 'right' }}>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-lg"
                                    onClick={(e) =>
                                      this.updatePayeePan(e)
                                    }
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>

                            </div>
                          </ModalBody>
                        </Modal>
                      </div>



                      <div>
                        <Modal
                          size="lg"
                          style={{ maxWidth: "1600px", width: "80%" }}
                          centered={true}
                          isOpen={this.state.viewTripDetailsModalNew}
                        >
                          <ModalFooter>
                            <div className="col-sm-2">
                              <Button
                                color="danger"
                                onClick={this.toggleCloseViewTripDetailsNew}
                              >
                                <CloseIcon />
                              </Button>{" "}
                            </div>
                          </ModalFooter>
                          <ModalBody>
                            <div className="row" style={{ marginLeft: "3%" }}>
                              <div
                                className="col-sm-4"
                                style={{ textAlign: "left" }}
                              >
                                TripID's Details :
                              </div>
                              <div className="col-sm-3">
                                <button
                                  type="submit"
                                  className="btn btn-danger overflow-hidden mb-3 px-0"
                                  style={{ width: "92px" }}
                                  onClick={this.toggleCloseViewTripDetailsNew}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                            <div className="bms-view-req-modal-body">
                              <div>
                                <div className="card-body">
                                  <div
                                    className="ag-theme-balham"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                    }}
                                  >
                                    <AgGridReact
                                      columnDefs={this.state.columnDefs}
                                      rowData={this.state.tripIdData}
                                      defaultColDef={defaultColDef}
                                      onGridReady={this.onGridReady}
                                      enableCellTextSelection={true}
                                      gridAutoHeight={true}
                                      domLayout={this.state.domLayout}
                                      rowHeight={45}
                                      suppressAutoSize={true}
                                    ></AgGridReact>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>

                      {/* Modal For Add Bank */}

                      <div>
                        {isAddPayeeBankModalOpen && (
                          <Modal
                            // size="lg"
                            // style={{ maxWidth: '1600px', width: '70%' }}
                            centered={true}
                            isOpen={isAddPayeeBankModalOpen}
                          // toggle={this.toggleAddPayeeBankModal}
                          >
                            <ModalFooter>
                              <div className="col-sm-3">
                                <span className="bms-view-req-modal-header-label">
                                  PmsId : {this.state.selectedPayeeDropDown}
                                </span>
                              </div>
                              <div className="col-sm-3 ml-auto">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <Button
                                      color="danger"
                                      onClick={this.toggleAddPayeeBankModal}
                                    >
                                      <CloseIcon />
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>
                            </ModalFooter>

                            <ModalBody>
                              <div className="bms-view-req-modal-body">
                                <form>
                                  <div
                                    className="col-lg-12 text-right"
                                    style={{ paddingTop: "4vh" }}
                                  ></div>
                                  <div className="col-lg-12">
                                    <div className="grid">
                                      <div className="grid-body">
                                        <div className="item-wrapper">
                                          <div className="row mb-3">
                                            <div className="col-md-12 mx-auto">
                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Bank Name</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Bank Name"
                                                      formControlName="addPayeeBankBankName"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankBankName
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankBankName:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Account No</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Account No"
                                                      formControlName=" addPayeeBankAccountNumber"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankAccountNumber
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankAccountNumber:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>IFSC Code</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter IFSC Code"
                                                      formControlName="addPayeeBankIfsc"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankIfsc
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankIfsc:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>
                                                      Benificary Name
                                                    </label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Benificary Name"
                                                      formControlName="addPayeeBankBeneficiaryName"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankBeneficiaryName
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankBeneficiaryName:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              {/* <div className="col-sm-4">
                                                                                            <FormGroup check>
                                                                                                <Label check>
                                                                                                    <Input
                                                                                                        type="checkbox"
                                                                                                        onChange={this.handleCheckPrimary}
                                                                                                        // value={this.state.IsOdometer}
                                                                                                        checked={this.state.addPayeeBankIsPrimary}
                                                                                                    />{" "}
                                                                                                    Is Primary
                                                                                                </Label>
                                                                                            </FormGroup>
                                                                                        </div> */}

                                              <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-lg"
                                                style={{ float: "right" }}
                                                onClick={(e) =>
                                                  this.createPayeeBank(e)
                                                }
                                              >
                                                Create
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </ModalBody>
                          </Modal>
                        )}
                      </div>

                      {/* Modal For Add Payee */}

                      <div>
                        {isAddPayeeModalOpen  && (
                          <Modal
                            // size="lg"
                            style={{ maxWidth: "1600px", width: "70%" }}
                            centered={true}
                            isOpen={isAddPayeeModalOpen}
                          // toggle={this.toggleAddPayeeBankModal}
                          >
                            <ModalFooter>
                              <div className="col-sm-3">
                                <span className="bms-view-req-modal-header-labelAddPayee">
                                  Add Payee Details
                                </span>
                                {/* <span className="bms-view-req-modal-header-label">PmsTypeId : {this.state.selectedPayeeTypeDropDown}</span> */}
                              </div>
                              <div className="col-sm-3 ml-auto">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <Button
                                      color="danger"
                                      onClick={this.toggleAddPayeeModal}
                                    >
                                      <CloseIcon />
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>
                            </ModalFooter>

                            <ModalBody>
                              <div className="bms-view-req-modal-body" style={{ fontFamily: 'sans-serif' }}>

                                <div
                                  className="col-lg-12 text-right"
                                  style={{ paddingTop: "4vh" }}
                                ></div>
                                <div className="col-lg-12">
                                  <div className="grid">
                                    <div className="grid-body">
                                      <div className="item-wrapper">
                                        <form onSubmit={this.createPayee} method="POST">
                                          <div className="row mb-3">
                                            <div className="col-md-12 mx-auto">
                                              {/** Name, Email */}
                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Name</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Name"
                                                      formControlName="addPayeeName"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state.addPayeeName
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeName:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Email</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Email"
                                                      formControlName="addPayeeEmail"
                                                      type="email"
                                                      className="form-control"
                                                      value={
                                                        this.state.addPayeeEmail
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeEmail:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              {/** Contact, Contact2 */}
                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Mobile</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Mobile No"
                                                      formControlName="addPayeeContactNumber"
                                                      type="number"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeContactNumber
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeContactNumber:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Contact</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Alternate Mobile No"
                                                      formControlName="addPayeeContactNumberAlternate"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeContactNumberAlternate
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeContactNumberAlternate:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-sm-6 mb-3" style={{ marginRight: '-30px' }}>
                                                  <div class="form-group row">
                                                    <label for="staticEmail" class="col-sm-3 col-form-label textForm2">PAN Card</label>
                                                    {this.state.showPanDisplay ==
                                                      "none" ? (
                                                      <div class="col-sm-8" style={{ textAlign: "left" }}>
                                                        <label className="custom-file-upload material-like-btn">
                                                          <input
                                                            name="Upload"
                                                            type="file"
                                                            accept="image/*"
                                                            className="bms-attachments-card-button ml-auto"
                                                            multiple
                                                            onChange={
                                                              this.uploadPan
                                                            }
                                                            required
                                                          />
                                                          <CloudUploadIcon />{" "}
                                                          Upload
                                                        </label>
                                                        {this.state.panRead == true &&
                                                          <div class="invalid-feedback" style={{ display: 'block', fontSize: '14px', fontWeight: '500' }}>
                                                            <span>*</span> Please try again with <b> Clear Image.</b>
                                                          </div>
                                                        }
                                                      </div>
                                                    ) : (
                                                      <div className="col-sm-8">
                                                        <div class="row">
                                                          {this.state
                                                            .isPanUploaded ===
                                                            true && (
                                                              <div class="col-sm-10">
                                                                <div style={{ width: '230px' }}>
                                                                  <img
                                                                    src={this.state.panImage}
                                                                    style={{ width: "100%", height: "auto" }} />
                                                                </div>
                                                              </div>
                                                            )}

                                                          {this.state
                                                            .isPanUploaded ===
                                                            false ? (
                                                            <div class="col-sm-2 alignDeleteButton mt-2">
                                                              <span className="bms-attachments-card-progress-bar-loader">
                                                                <LinearProgress />
                                                              </span>
                                                            </div>
                                                          ) :
                                                            <div class="col-sm-2 alignDeleteButton mt-2">
                                                              <span className="bms-attachments-card-button-delete" style={{ marginTop: "22%", marginLeft: "5%", }}>
                                                                <DeleteIcon onClick={this.deletePanDetails} />
                                                              </span>
                                                            </div>
                                                          }
                                                        </div>
                                                      </div>
                                                    )}

                                                  </div>
                                                </div>



                                                <div className="col-sm-6 mb-3">
                                                  <div class="form-group row">
                                                    <label for="staticEmail" class="col-sm-2 col-form-label textForm2">PAN No. </label>
                                                    <div class="col-sm-9">
                                                      <input type="text"
                                                        value={this.state.panNumber}
                                                        onChange={(event) => {
                                                          this.setState({
                                                            panNumber:
                                                              event.target.value,
                                                          });
                                                        }}
                                                        required
                                                        disabled={this.state.panDisabled}
                                                        class="form-control textForm2"  style={{ marginLeft: '0px' }} id="staticEmail" placeholder="Enter Pancard No" />
                                                     
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>



                                              {/** Bank Details */}
                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Bank Name</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Bank Name"
                                                      formControlName="addPayeeBankName"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankName
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankName:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Account No</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Account No"
                                                      formControlName=" addPayeeAccountNumber"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeAccountNumber
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeAccountNumber:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              {/** Bank Details */}
                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>IFSC Code</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter IFSC Code"
                                                      formControlName="addPayeeIfsc"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state.addPayeeIfsc
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeIfsc:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>
                                                      Benificary Name
                                                    </label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Benificary Name"
                                                      formControlName="addPayeeBeneficiaryName"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBeneficiaryName
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBeneficiaryName:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      required
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-sm-4">
                                                <FormGroup check>
                                                  <Label check>
                                                    <Input
                                                      type="checkbox"
                                                      onChange={
                                                        this.handleCheckCompany
                                                      }
                                                      // value={this.state.IsOdometer}
                                                      checked={
                                                        this.state.addPayeeBankIsCompany
                                                      }
                                                    />{" "}
                                                    IsCompany
                                                  </Label>
                                                </FormGroup>
                                              </div>

                                              <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-lg"
                                              >
                                                Add
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </ModalBody>
                          </Modal>
                        )}
                      </div>

                      {/* Modal For Edit Bank */}

                      <div>
                        {isEditPayeeBankModalOpen && (
                          <Modal
                            size="lg"
                            style={{ maxWidth: "1600px", width: "70%" }}
                            centered={true}
                            isOpen={isEditPayeeBankModalOpen}
                          // toggle={this.toggleAddPayeeBankModal}
                          >
                            <ModalFooter>
                              <div className="col-sm-3">
                                <span className="bms-view-req-modal-header-label">
                                  Payee : {selectedPayeeDropDownName}
                                </span>
                              </div>
                              <div className="col-sm-3 ml-auto">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <Button
                                      color="danger"
                                      onClick={this.toggleEditPayeeBankModal}
                                    >
                                      <CloseIcon />
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>
                            </ModalFooter>

                            <ModalBody>
                              <div className="bms-view-req-modal-body">
                                <form>
                                  <div
                                    className="col-lg-12 text-right"
                                    style={{ paddingTop: "4vh" }}
                                  ></div>
                                  <div className="col-lg-12">
                                    <div className="grid">
                                      <div className="grid-body">
                                        <div className="item-wrapper">
                                          <div className="row mb-3">
                                            <div className="col-md-12 mx-auto">
                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Bank Name</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Bank Name"
                                                      formControlName="addPayeeBankBankName"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankBankName
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankBankName:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Account No</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Account No"
                                                      formControlName=" addPayeeBankAccountNumber"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankAccountNumber
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankAccountNumber:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>IFSC Code</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter IFSC Code"
                                                      formControlName="addPayeeBankIfsc"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankIfsc
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankIfsc:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>
                                                      Benificary Name
                                                    </label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Benificary Name"
                                                      formControlName="addPayeeBankBeneficiaryName"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .addPayeeBankBeneficiaryName
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          addPayeeBankBeneficiaryName:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                {/* <div className="col-sm-6">
                                                                                                <FormGroup check>
                                                                                                    <Label check>
                                                                                                        <Input
                                                                                                            type="checkbox"
                                                                                                            onChange={this.handleCheckPrimary}
                                                                                                            // value={this.state.IsOdometer}
                                                                                                            checked={this.state.addPayeeBankIsPrimary}
                                                                                                        />{" "}
                                                                                                    Is Primary
                                                                                                </Label>
                                                                                                </FormGroup>
                                                                                            </div> */}

                                                <div className="col-sm-3">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-danger overflow-hidden mb-3 px-auto editPayeeBankModalSaveButton"
                                                    onClick={(e) =>
                                                      this.savePayeeBankDetails(
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Save
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </ModalBody>
                          </Modal>
                        )}
                      </div>

                      {/* Modal For Edit Bank */}

                      <div>
                        {isEditPayeeBankAmountEditModalOpen && (
                          <Modal
                            size="lg"
                            style={{ maxWidth: "1600px", width: "70%" }}
                            centered={true}
                            isOpen={isEditPayeeBankAmountEditModalOpen}
                          // toggle={this.toggleAddPayeeBankModal}
                          >
                            <ModalFooter>
                              <div className="col-sm-3">
                                <span className="bms-view-req-modal-header-label">
                                  Payee : {selectedPayeeDropDownName}
                                </span>
                              </div>
                              <div className="col-sm-3 ml-auto">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <Button
                                      color="danger"
                                      onClick={
                                        this.toggleEditPayeeBankAmountModal
                                      }
                                    >
                                      <CloseIcon />
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>
                            </ModalFooter>

                            <ModalBody>
                              <div className="bms-view-req-modal-body">
                                <form>
                                  <div
                                    className="col-lg-12 text-right"
                                    style={{ paddingTop: "4vh" }}
                                  ></div>
                                  <div className="col-lg-12">
                                    <div className="grid">
                                      <div className="grid-body">
                                        <div className="item-wrapper">
                                          <div className="row mb-3">
                                            <div className="col-md-12 mx-auto">
                                              <div className="form-row">
                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Amount</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Account No"
                                                      formControlName=" addPayeeBankAccountNumber"
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        this.state
                                                          .selectedPayeeBankAmountValue
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          selectedPayeeBankAmountValue:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-md-3">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-danger overflow-hidden mb-3 px-auto editPayeeBankModalSaveButton"
                                                    onClick={(e) =>
                                                      this.savePayeeBankAmountDetails(
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Save
                                                  </button>
                                                </div>
                                              </div>

                                              {/* <div className="row">

                                                                                            <div className="col-sm-3">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-danger overflow-hidden mb-3 px-auto editPayeeBankModalSaveButton"
                                                                                                    onClick={e => this.savePayeeBankAmountDetails(e)}
                                                                                                >
                                                                                                    Save
                                                                                        </button>

                                                                                            </div>
                                                                                        </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </ModalBody>
                          </Modal>
                        )}
                      </div>

                      {/* Modal For TMS Trip Modal */}

                      <div>
                        {viewTripDetailsModal && (
                          <Modal
                            size="lg"
                            style={{
                              maxWidth: "1500px",
                              width: "80%",
                            }}
                            centered={true}
                            isOpen={viewTripDetailsModal}
                          >
                            <ModalHeader
                              toggle={this.toggleCloseViewTripDetails}
                              close={closeBtn}
                            >
                              <div className="col-sm-3">
                                <span className="bms-view-req-modal-header-label">
                                  Trip Details
                                </span>
                              </div>
                            </ModalHeader>
                            {/* <ModalFooter>

                                                        <div className="col-sm-3">
                                                            <span className="bms-view-req-modal-header-label">Trip Details</span>
                                                        </div>

                                                        <div className="col-sm-3 ml-auto">
                                                            <div className="row">

                                                                <div className="col-sm-6">
                                                                    <Button
                                                                        color="danger"
                                                                        onClick={this.toggleCloseViewTripDetails}
                                                                    >
                                                                        <CloseIcon />
                                                                    </Button>{" "}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </ModalFooter> */}

                            <ModalBody
                              style={{ minHeight: "85vh", height: "100%" }}
                            >
                              <div id="cover-spin" style={styleTripModal}></div>

                              <div className="container-fluid">
                                <div className="row ml-5">
                                  <div className="col-sm-8">
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="vehicleNumber"
                                        value={this.state.selectedTripId}
                                        // style={{ borderColor: "#FF4848" }}
                                        id="vehicleNumber"
                                        placeholder="TripID’s (comma separated)"
                                        style={{
                                          fontFamily:
                                            "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                                          fontVariantNumber: "tabular-nums",
                                        }}
                                        onChange={(e) => {
                                          this.setState({
                                            selectedTripId: e.target.value,
                                          });
                                        }}
                                      ></Input>
                                    </FormGroup>
                                  </div>

                                  <div className="col-sm-2">
                                    <button
                                      type="submit"
                                      // className="btn btn-secondary"
                                      className="btn btn-danger overflow-hidden mb-3 px-auto"
                                      onClick={this.handleSearchTripModal}
                                    >
                                      <SearchIcon />
                                      Search
                                    </button>
                                  </div>
                                </div>

                                <div style={{ minHeight: "50vh" }}>
                                  <div
                                    className="row"
                                    style={{ padding: "39px" }}
                                  >
                                    {this.state
                                      .uniqueSegmentsDropDownTripModal !=
                                      "" && (
                                        <Table
                                          responsive
                                          className="styled-table"
                                        >
                                          <thead>
                                            <tr className="styled-table-header-row">
                                              <th>ClientName</th>
                                              <th>Segment</th>
                                              <th>Servicetype</th>
                                              <th>vehiclenumber</th>
                                              <th>TripCnt</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state
                                              .uniqueSegmentsDropDownTripModal !=
                                              "" &&
                                              Object.values(
                                                this.state
                                                  .uniqueSegmentsDropDownTripModal
                                              ).map((note, i) => (
                                                <tr className="styled-table-row">
                                                  <td>{note.ClientName}</td>
                                                  <td>{note.Segment}</td>
                                                  <td>{note.Servicetype}</td>
                                                  <td>{note.vehiclenumber}</td>
                                                  <td>{note.TripCnt}</td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </Table>
                                      )}
                                    {this.state.tripIdError ===
                                      "Enter TripID's" ? (
                                      <h4 style={{ fontFamily: "system-ui" }}>
                                        {this.state.tripIdError}
                                      </h4>
                                    ) : (
                                      <h4>{this.state.tripIdError}</h4>
                                    )}
                                  </div>
                                </div>

                                <div className="row">
                                  {this.state.uniqueSegmentsDropDownTripModal
                                    .length && (
                                      <button
                                        style={{ margin: "auto" }}
                                        type="submit"
                                        className="btn btn-danger overflow-hidden mb-3 px-auto"
                                        onClick={this.attachTrips}
                                        disabled={addTripToBmsLoading}
                                      >
                                        {addTripToBmsLoading && (
                                          <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                          />
                                        )}

                                        {!addTripToBmsLoading && (
                                          <i
                                            className="fa fa-floppy-o"
                                            style={{ marginRight: "5px" }}
                                          />
                                        )}
                                        {addTripToBmsLoading && (
                                          <span>Saving ...</span>
                                        )}
                                        {!addTripToBmsLoading && (
                                          <span>Save</span>
                                        )}
                                      </button>
                                    )}
                                </div>
                              </div>
                            </ModalBody>
                          </Modal>
                        )}
                      </div>
                    </div>
                  </nav>
                  <div style={{ marginBottom: "10vh" }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RouteAddForm.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
