import React, { Component } from "react";
import axios from "axios";
// import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import { Button, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
// import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/loader-spinner.css"



class NoticeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      modal: false,
      locations: [],
      description: "",
      message: "",
      style: {},
      unique_locationids: [],
      unique_vendors: [],
      anotherModal: false,
      Locationid: "",
      VendorName: "",
      Contact2: "",
      Contact3: "",
      showText: false,
      columnDefs: [
        {
          headerName: "ID", field: "ID", sortable: true, filter: true
        },
        {
          headerName: "Vendor Name", field: "VendorName", sortable: true, filter: true
        },
        {
          headerName: "Mobile", field: "Mobile", sortable: true, filter: true
        },
        {
          headerName: "Add Vehicle", field: "AddVehicle", editable: true,
          cellRendererFramework: (params) => {
            return <Link to={"/admin/vms/vehicles/add/" + params.node.data.ID}>
              <Button
                color="primary"
                onClick={() =>
                  this.setState({
                    modal: !this.state.modal
                  })
                }
              >
                Add Vehicle
        </Button>
            </Link>
          }
        },
        {
          headerName: "Show Vehicle", field: "ShowVehicle", editable: true,
          cellRendererFramework: (params) => {
            return <Link to={"/admin/vms/show/vehicles/" + params.node.data.ID}>
              <Button
                color="success"
                onClick={() =>
                  this.setState({
                    anotherModal: !this.state.anotherModal
                  })
                }
              >
                Show Vehicle
        </Button>
            </Link>
          }
        }


      ],
      rowData: [],
      activePage: 1,
      itemsCountPerPage: 30,
      totalItemsCount: 2000,
      pageRangeDisplayed: 5,
      domLayout: "autoHeight"
    };
  }

  async componentDidMount() {
    let that = this;

    // get all the unique dates
    axios.get(`${Config.hostName}/unique/locations`)
      .then((res) => {
        console.log(res.data);

        // save unique dates in component state object
        that.setState({ unique_locationids: res.data.data })
      })
      .catch((error) => {
        console.log(error)
      });
  }


  handlePageChange = async (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    await this.setState({ activePage: pageNumber, style: {} });
    await this.componentWillMount();
  }

  componentWillMount = async () => {
    let { activePage } = this.state;
    var result = await axios.get(`${Config.hostName}/vms/vehicles/vendor-details/${activePage}`);
    console.log(result.data);

    await this.setState({
      locations: result.data.data,
      style: { display: 'none' }
    });
    await this.setState({
      rowData: this.state.locations.map(eachItem => {
        return {
          ID: eachItem.VendorID,
          VendorName: eachItem.TransportName,
          Mobile: eachItem.ContactNumber
        }
      })
    });
  }

  handleSearchShipment = async () => {
    try {
      await this.setState({ style: {} })
      let result = await axios.post(`${Config.hostName}/vms/vehicles/search`, {
        locationId: this.state.Locationid,
        vendorName: this.state.VendorName
      });
      await this.setState({
        locations: result.data.data
      });

      await this.setState({
        rowData: this.state.locations.map(eachItem => {
          return {
            ID: eachItem.VendorID,
            VendorName: eachItem.TransportName,
            Mobile: eachItem.ContactNumber
          }
        }),
        style: { display: 'none' }

      });
    } catch (e) {
      console.log(e);

    }

  }

  change = event => {
    this.setState({ style: {}, unique_vendors: [] });
    event.preventDefault();
    this.setState({ Locationid: event.target.value });
    axios
      .get(`${Config.hostName}/vms/get/unique-vendors/${event.target.value}`)
      .then(res => {
        console.log(res.data);
        this.setState({
          unique_vendors: res.data.data,
          style: { display: 'none' }

        });
      })
      .catch(err => {
        console.log(err);
      });
  };


  render() {
    let style = this.state.style;

    const defaultColDef = {
      flex: 1,
      minWidth: 90,
      resizable: true
    }

    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;


    return (
      <div>
        <div id='cover-spin' style={style}></div>
        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />
                <nav
                  className="navbar navbar-light "
                  style={{ backgroundColor: "none" }}
                >
                  <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="locationid"
                          value={this.state.Locationid}
                          id="locationid"
                          // onChange={async event => {
                          //   await this.setState({
                          //     Locationid: event.target.value
                          //   });
                          // }}

                          onChange={this.change}

                        >
                          <option value={""} disabled selected >Select Location</option>

                          {this.state.unique_locationids.map(locationid => (
                            <option value={locationid.Locationid}>
                              {locationid.name}

                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="VendorName"
                          value={this.state.VendorName}
                          id="VendorName"
                          onChange={event => {
                            this.setState({ VendorName: event.target.value });
                          }}
                        >
                          <option value={""} disabled selected  >Select Vendor</option>

                          {this.state.unique_vendors.map(vendor => (
                            <option value={vendor.TransportName}>
                              {vendor.TransportName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <button
                        // type="submit"
                        // className="btn btn-secondary"
                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                        onClick={this.handleSearchShipment}
                      >
                        Search
                      </button>
                    </div>

                  </div>
                </nav>

                <div className="card-body">

                  <div
                    className="ag-theme-balham"
                    style={{
                      height: '100%',
                      width: '100%'
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      defaultColDef={defaultColDef}
                      enableCellTextSelection={true}
                      rowHeight={40}
                      domLayout={this.state.domLayout}
                      // enableCellTextSelection={true}
                      suppressAutoSize={true}
                      gridAutoHeight={true}
                    >
                    </AgGridReact>
                  </div>

                  <div style={{ margin: "10vh auto", marginLeft: "25%", justifyContent: "center" }}>
                    {/* <div style={{ margin: "5vh auto", marginLeft: "25%", justifyContent: "center" }}> */}

                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText='prev'
                      nextPageText='next'
                      firstPageText='first'
                      lastPageText='last'
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}

        </div>
      </div>
    );
  }


}

NoticeAdd.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
