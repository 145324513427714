/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from "react";
import axios from "axios";
import Config from "../../../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import "../../../../assets/customCssFiles/calender.css";
import CountUp from "react-countup";
import "../../../../assets/customCssFiles/bmsViewReqPayee.css";
import "../../../../assets/customCssFiles/bmsDashboard.css";
// import "../../../../assets/customCssFiles/tableInvoice.css";
import { AgGridReact } from "ag-grid-react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // FormGroup,
  Input,
  Table,
  Card,
  CardText,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import "../../../../assets/customCssFiles/loader-spinner.css";
import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "../../../../assets/customImages/excel.jpg";
import CloseIcon from "@material-ui/icons/Close";
import CategoryIcon from "@material-ui/icons/Category";
import PersonIcon from "@material-ui/icons/Person";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SearchIcon from "@material-ui/icons/Search";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import DeleteIcon from "@material-ui/icons/Delete";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BankIcon from "../../../../assets/customImages/bankIconPms.png";

import FilterListIcon from "@material-ui/icons/FilterList";

import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { saveAs } from "file-saver";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { StepperComponent } from "./VerticalStepper";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiData: [],
      startDate: "",
      endDate: "",
      reportId: "",
      style: {},
      tableStyle: { display: "none" },
      isData: false,
      isOpen: false,
      isGraphData: false,
      userCcId: "",
      unique_cities: [],
      ccid: "",
      uniqueDateRanges: [],
      dateRangeSelected: "",
      modal: false,
      widgetData: [],
      statusKeys: [],
      statusCode: "",
      showGallery: false,
      tableModal: false,
      imagesTableModal: [],
      isImageAttachmentModal: false,
      imageUrlModal: "",
      imageAttachmentModal: "",
      viewRequestsModal: "",
      isViewRequestPageData: false,
      modalData: [],
      modalImagesArray: [],
      uniqueStatuses: [],
      status: "",
      bmsId: "",
      categoryWidget: "",
      toggleValue: "",
      search_val: "",
      isCheckedToggleSwitch: false,
      columnDefs: [
        {
          headerName: "Bms Id",
          field: "bmsid",
          sortable: true,
          filter: true,
          minWidth: 50,
          maxWidth: 100,
          width: 50,
        },
        // {
        //     headerName: "City", field: "city", sortable: true, filter: true
        // },
        {
          headerName: "Category",
          field: "category",
          sortable: true,
          filter: true,
        },
        {
          // Payment Done

          headerName: "Status",
          field: "statuscode",
          sortable: true,
          filter: true,
          cellRendererFramework: (params) => {
            const status = params.node.data.statuscode;
            const StatusColor =
              status === "Payment Done"
                ? "text-success"
                : status === "Rejected"
                ? "text-danger"
                : "";
            return (
              <span className={StatusColor} style={{ fontWieght: "bold" }}>
                {status}
              </span>
            );
          },
        },
        {
          headerName: "Last Updated By",
          field: "StatusUpdatedby",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Status Date",
          field: "StatusDate",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Total Amount",
          field: "totalAmount",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Requestor",
          field: "Requestor",
          sortable: true,
          filter: true,
        },

        {
          headerName: "Priority",
          field: "priority",
          sortable: true,
          filter: true,
        },
        {
          headerName: "View",
          field: "View",
          cellRendererFramework: (params) => {
            return (
              <Button
                color="primary"
                onClick={() =>
                  this.onClickOpenViewReqModal(params.node.data.bmsid)
                }
              >
                <ViewComfyIcon /> Views
              </Button>
            );
          },
        },
      ],
      domLayout: "autoHeight",
      rowData: [],
      activePage: 1,
      isExportLoading: false,
      exportData: [],
      isSelectWidget: false,
      rotation: 0,
      notesMain: [],
      isModalDataStepper: false,
      modalDataStepper: [],
      payeeDetails: null,
      payeekey: null,
    };
  }

  toggle = async () => {
    await this.setState({
      modal: !this.state.modal,
    });
  };

  setStartEndDates = async () => {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    // eslint-disable-next-line no-useless-concat
    const newMon = String(month).length == 1 ? `0${month}` : month;
    const newDate = String(day).length == 1 ? `0${day}` : day;
    // const iDate = `2020-12-01T00:00:00.000Z`
    // const mDate = year + "-" + month + "-" + day;
    const mDate = `${year}-${newMon}-${newDate}T23:59:00.000Z`;
    const iDate = `${year}-${newMon}-01T00:00:00.000Z`;

    await this.setState({
      startDate: iDate,
      endDate: mDate,
      // startDate: "2020-12-01",
      // endDate: "2020-12-31"
    });
  };

  getData = async () => {
    const res = await axios
      .post(`${Config.hostName}/bms/get/details/dashboard`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        // console.log(res.data, "api data");
        if (res.data.data.length > 0) {
          await this.setState({
            apiData: res.data.data,
            rowData: res.data.data.map((eachItem) => {
              return {
                city: eachItem.city,
                category: eachItem.category,
                itemDetails: eachItem.itemDetails,
                attachments: eachItem.attachments,
                notes: eachItem.notes,
                statusid: eachItem.statusid,
                statuscode: eachItem.statuscode,
                StatusDate: eachItem.StatusDate,
                totalAmount: eachItem.totalAmount,
                Requestor: eachItem.Requestor,
                bmsid: eachItem.bmsid,
                priority: eachItem.priority,
                StatusUpdatedby: eachItem.StatusUpdatedby,
                StatusHistory: eachItem.StatusHistory,
              };
            }),
          });
        } else {
          await this.setState({
            apiData: [],
            rowData: [],
          });
        }
      })
      .catch((err) => {
        // alert(err);
        console.log(err);
      });
  };

  getWidgetData = async () => {
    await axios
      .post(`${Config.hostName}/bms/get/request-status/dashboard`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        // console.log(res.data, "widget data")
        const dataResp = res.data.data;
        if (dataResp.length > 0) {
          const keys = [];
          const map = dataResp.map((dataItem) => {
            keys.push(...Object.keys(dataItem));
          });
          const prom = await Promise.all(map);
          const newKeys = [...new Set(keys)];
          const index = newKeys.indexOf("Category");
          if (index !== -1) {
            newKeys.splice(index, 1);
          }
          // console.log(newKeys)
          let countTotal = 0;
          let sumTotal = 0;
          const mapRes = dataResp.map((dataItem) => {
            if (dataItem.Category == "Count") {
              newKeys.map((newKey) => {
                countTotal = countTotal + dataItem[newKey];
              });
              return (dataItem.Total = countTotal);
            }
            if (dataItem.Category == "Sum") {
              newKeys.map((newKey) => {
                sumTotal = sumTotal + dataItem[newKey];
              });
              return (dataItem.Total = sumTotal);
            }
          });
          const promRes = await Promise.all(mapRes);
          // console.log(dataResp)

          await this.setState({
            statusKeys: [...newKeys, "Total"],
            widgetData: dataResp,
            isData: true,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  getCategoryWidgetData = async () => {
    await axios
      .post(`${Config.hostName}/bms/get/category/request-status/dashboard`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        // console.log(res.data, "categry widget data")
        const dataResp = res.data.data;
        if (dataResp.length > 0) {
          const keys = [];
          const map = dataResp.map((dataItem) => {
            keys.push(...Object.keys(dataItem));
          });
          const prom = await Promise.all(map);
          const newKeys = [...new Set(keys)];
          const index = newKeys.indexOf("Category");
          if (index !== -1) {
            newKeys.splice(index, 1);
          }
          // console.log(newKeys)
          let countTotal = 0;
          let sumTotal = 0;
          const mapRes = dataResp.map((dataItem) => {
            if (dataItem.Category == "Count") {
              newKeys.map((newKey) => {
                countTotal = countTotal + dataItem[newKey];
              });
              return (dataItem.Total = countTotal);
            }
            if (dataItem.Category == "Sum") {
              newKeys.map((newKey) => {
                sumTotal = sumTotal + dataItem[newKey];
              });
              return (dataItem.Total = sumTotal);
            }
          });
          const promRes = await Promise.all(mapRes);
          // console.log(dataResp)

          await this.setState({
            statusKeys: [...newKeys, "Total"],
            widgetData: dataResp,
            isData: true,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  getAllData = async () => {
    if (this.state.isCheckedToggleSwitch) {
      // console.log("hi", this.state.isCheckedToggleSwitch)
      await this.getData();
      await this.getCategoryWidgetData();
      await this.setState({
        isData: true,
      });
    } else {
      // console.log("bi", this.state.isCheckedToggleSwitch)
      await this.getData();
      await this.getWidgetData();
      await this.setState({
        isData: true,
      });
    }

    // console.log(this.state.apiData, "data");
  };

  handleRefreshData = async () => {
    await this.setState({
      style: {},
      apiData: [],
      widgetData: [],
      isData: false,
    });
    await this.getAllData();
    await this.setState({
      style: { display: "none" },
    });
  };

  getUniqueDateRanges = async () => {
    await axios
      .get(
        `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
      )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueDateRanges: res.data.data,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  componentWillMount = async () => {
    await this.setState({
      userCcId: this.props.auth.user.CCID,
      style: {},
    });
    await this.setStartEndDates();
    await this.getAllData();
    await this.getUniqueDateRanges();
    await this.setState({
      style: { display: "none" },
    });
  };

  handleSearchShipment = async () => {
    await this.setState({
      style: {},
      apiData: [],
      widgetData: [],
      isData: false,
    });
    await this.getAllData();
    await this.setState({
      style: { display: "none" },
    });
  };

  handleSearchShipmentByID = async () => {
    await this.setState({
      style: {},
      isData: false,
    });
    const res = await axios
      .post(`${Config.hostName}/bms/getBmsSearchDetails`, {
        bmsIDs: this.state.search_val,
      })
      .then(async (res) => {
        // console.log(res.data, "api data");
        if (res.data.data.length > 0) {
          await this.setState({
            apiData: res.data.data,
            rowData: res.data.data.map((eachItem) => {
              return {
                city: eachItem.city,
                category: eachItem.category,
                itemDetails: eachItem.itemDetails,
                attachments: eachItem.attachments,
                notes: eachItem.notes,
                statusid: eachItem.statusid,
                statuscode: eachItem.statuscode,
                StatusDate: eachItem.StatusDate,
                totalAmount: eachItem.totalAmount,
                Requestor: eachItem.Requestor,
                bmsid: eachItem.bmsid,
                priority: eachItem.priority,
                StatusUpdatedby: eachItem.StatusUpdatedby,
                StatusHistory: eachItem.StatusHistory,
              };
            }),
            style: { display: "none" },
          });
        } else {
          await this.setState({
            apiData: [],
            rowData: [],
            style: { display: "none" },
          });
        }
      })
      .catch((err) => {
        // alert(err);
        console.log(err);
      });
  };

  handleChangeDateRange = async (e) => {
    // console.log(e.target.value);
    let value = e.target.value;
    // console.log();2222
    if (value == 2222) {
      await this.setState({
        dateRangeSelected: e.target.value,
        startDate: "",
        endDate: "",
      });
      await this.toggle();
    } else {
      this.state.uniqueDateRanges.filter(async (eachDate, i) => {
        if (i == value) {
          // console.log("true", eachDate);
          await this.setState({
            dateRangeSelected: e.target.value,
            startDate: eachDate.startDate,
            endDate: eachDate.endDate,
          });
        }
      });
    }
  };

  toggleCloseModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: "",
    });
  };

  toggleConfirmModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: 2222,
    });
  };

  getDataByStatusCode = async () => {
    // /bms/get/status-code/details/dashboard

    const res = await axios
      .post(`${Config.hostName}/bms/get/status-code/details/dashboard`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        statusCode: this.state.statusCode,
      })
      .then(async (res) => {
        // console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            apiData: res.data.data,
            rowData: res.data.data.map((eachItem) => {
              return {
                city: eachItem.city,
                category: eachItem.category,
                itemDetails: eachItem.itemDetails,
                attachments: eachItem.attachments,
                notes: eachItem.notes,
                statusid: eachItem.statusid,
                statuscode: eachItem.statuscode,
                StatusDate: eachItem.StatusDate,
                totalAmount: eachItem.totalAmount,
                Requestor: eachItem.Requestor,
                bmsid: eachItem.bmsid,
                priority: eachItem.priority,
                StatusUpdatedby: eachItem.StatusUpdatedby,
              };
            }),
          });
        } else {
          await this.setState({
            apiData: [],
            rowData: [],
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  getDataByCategory = async () => {
    // console.log(this.state.startDate, this.state.endDate);
    const res = await axios
      .post(`${Config.hostName}/bms/get/category/details/dashboard`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        category: this.state.categoryWidget,
      })
      .then(async (res) => {
        // console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            apiData: res.data.data,
            rowData: res.data.data.map((eachItem) => {
              return {
                city: eachItem.city,
                category: eachItem.category,
                itemDetails: eachItem.itemDetails,
                attachments: eachItem.attachments,
                notes: eachItem.notes,
                statusid: eachItem.statusid,
                statuscode: eachItem.statuscode,
                StatusDate: eachItem.StatusDate,
                totalAmount: eachItem.totalAmount,
                Requestor: eachItem.Requestor,
                bmsid: eachItem.bmsid,
                priority: eachItem.priority,
                StatusUpdatedby: eachItem.StatusUpdatedby,
              };
            }),
          });
        } else {
          await this.setState({
            apiData: [],
            rowData: [],
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onDivClickTableUpdate = async (value) => {
    if (this.state.isCheckedToggleSwitch) {
      // console.log(value, "valueeeeee");
      await this.setState({
        categoryWidget: value,
        tableStyle: {},
        // statusCode: ""
      });
      if (value == "Total") {
        await this.getData();
        await this.setState({
          tableStyle: { display: "none" },
          isSelectWidget: false,
        });
      } else {
        await this.getDataByCategory();
        await this.setState({
          tableStyle: { display: "none" },
          isSelectWidget: true,
        });
      }
    } else {
      // console.log(value, "valueeeeee");
      await this.setState({
        statusCode: value,
        // categoryWidget: "",
        tableStyle: {},
      });
      if (value == "Total") {
        await this.getData();
        await this.setState({
          tableStyle: { display: "none" },
          isSelectWidget: false,
        });
      } else {
        await this.getDataByStatusCode();
        await this.setState({
          tableStyle: { display: "none" },
          isSelectWidget: true,
        });
      }
    }
  };

  toggleTable = async () => {
    // console.log(this.state.imagesTableModal, "this.state.imagesTableModal");
    await this.setState({
      tableModal: !this.state.tableModal,
    });
  };

  toggleTableModal = async (data) => {
    // console.log(data, "toggleTableModaldata");
    await this.setState({
      imagesTableModal: JSON.parse(data.attachments),
    });
    await this.toggleTable();
  };

  toggleCloseTableModal = async () => {
    await this.toggleTable();
    await this.setState({
      imagesTableModal: [],
    });
  };

  downloadImageUrl = async (e) => {
    // e.preventDefault();
    // console.log(e.target.value, "e.target.value");
    // console.log(e, "e.target.value");
    window.open(e, "_blank");
  };

  getStatusDropDown = async () => {
    const data = await axios
      .get(`${Config.hostName}/bms/get/status/dropdownNew/${this.state.bmsId}`)
      .then(async (res) => {
        const data = res.data.data;
        if (data.length > 0) {
          await this.setState({
            uniqueStatuses: data,
            payeeDetails: data[0].payeekey,
          });
        } else {
          await this.setState({
            uniqueStatuses: [],
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  toggleViewRequestsModal = async () => {
    await this.setState({
      viewRequestsModal: !this.state.viewRequestsModal,
    });
  };

  toggleOpenViewRequestsModal = async () => {
    await this.setState({
      viewRequestsModal: true,
      isViewRequestPageData: true,
    });
    // await this.toggleViewRequestsModal();
  };

  toggleCloseViewRequestsModal = async () => {
    await this.toggleViewRequestsModal();
    // await this.setState({
    //     imagesTableModal: []
    // });
  };

  viewReqModalFunction = async (bmsId) => {
    const viewReqData = [];
    await this.state.apiData.filter((item) => {
      if (item.bmsid == bmsId) {
        return viewReqData.push(item);
      }
    });
    if (viewReqData.length > 0) {
      const modalPageData = viewReqData[0];

      const imagesArray =
        modalPageData.attachments.length > 0
          ? JSON.parse(modalPageData.attachments)
          : modalPageData.attachments;
      const san = modalPageData.notes.replace(/\n/g, "<br>");
      const man = JSON.parse(san);

      let StatusHistory = `[${modalPageData.StatusHistory}]`;
      console.log(JSON.parse(StatusHistory));

      await this.setState({
        modalData: modalPageData,
        bmsId: bmsId,
        modalImagesArray: imagesArray,
        category: modalPageData.category,
        notesMain: man,
        modalDataStepper: StatusHistory ? JSON.parse(StatusHistory) : [],
        isModalDataStepper: true,
      });
      // console.log(this.state.notesMain);
      await this.getStatusDropDown();

      await this.toggleOpenViewRequestsModal();
    } else {
      await this.setState({
        style: { display: "none" },
        isModalDataStepper: false,
      });
      await this.toggleOpenViewRequestsModal();
    }
  };

  getStatusDropDownUpdate = async (bmsid) => {
    const res = await axios
      .post(`${Config.hostName}/bms/getstatusdropDownUpdate`, {
        userId: this.props.auth.user.USERID,
        bmsId: bmsid,
      })
      .then(async (res) => {
        console.log(res.data, "Status");
        await this.setState({
          uniqueStatuses: res.data.data,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  onClickOpenViewReqModal = async (bmsId) => {
    // await this.getApiDetailsData();
    await this.viewReqModalFunction(bmsId);
    await this.getStatusDropDownUpdate(bmsId);
  };

  updateContentApi = async (contentId, data) => {
    // console.log(contentId, JSON.parse(data), "contentId, data");
    // console.log(contentId, JSON.stringify(data), "contentId, data");
    // console.log(contentId, data, "contentId, data");
    await axios
      .post(`${Config.hostName}/bms/update/content`, {
        bmsId: this.state.bmsId,
        category: this.state.category ? this.state.category : null,
        totalAmount: this.state.totalAmount ? this.state.totalAmount : null,
        contentId: contentId,
        content: data,
        userId: this.props.auth.user.USERID,
      })
      .then(async (response) => {
        if (response.data.success === false) {
          await this.setState({
            style: { display: "none" },
          });
          alert(response.data.message);
        } else {
          if (this.state.isSelectWidget) {
            // console.log("isSwlect Widgrtt", this.state.isSelectWidget)
            if (this.state.isCheckedToggleSwitch) {
              // console.log("cat", this.state.isCheckedToggleSwitch)
              await this.getCategoryWidgetData();
              await this.getDataByCategory();
              await this.viewReqModalFunction(this.state.bmsId);
            } else {
              // console.log("status", this.state.isCheckedToggleSwitch)
              await this.getWidgetData();
              await this.getDataByStatusCode();
              await this.viewReqModalFunction(this.state.bmsId);
            }
          } else {
            // console.log("isSwlect widgrt noooo", this.state.isSelectWidget)
            if (this.state.isCheckedToggleSwitch) {
              // console.log("cat", this.state.isCheckedToggleSwitch)
              await this.getCategoryWidgetData();
              await this.getAllData();
              await this.viewReqModalFunction(this.state.bmsId);
            } else {
              // console.log("status", this.state.isCheckedToggleSwitch)
              await this.getWidgetData();
              await this.getAllData();
              await this.viewReqModalFunction(this.state.bmsId);
            }
          }
          await this.setState({
            style: { display: "none" },
            status: "",
            remarks: "",
          });
          if (!this.state.modalData) {
            alert("Something Failed");
          }
          alert("Succesfully Updated");
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  updateRequestRemarks = async (contentId) => {
    await this.setState({ style: {} });
    if (!this.state.remarks) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Remarks field should not be empty");
    }
    const data = contentId == 4 ? this.state.remarks : "";
    await this.updateContentApi(contentId, data);
  };

  updateRequestStatus = async (contentId) => {
    await this.setState({ style: {} });
    if (!this.state.status) {
      await this.setState({
        style: { display: "none" },
      });
      return alert("Status should not be empty");
    }
    const data = contentId == 5 ? this.state.status : "";
    // console.log(contentId, data, data.length, "contentId, data");
    await this.updateContentApi(contentId, data);
    // await this.getWidgetData();
    await this.setState({
      status: "",
      viewRequestsModal: !this.state.viewRequestsModal,
    });
  };

  changeStatus = async (e) => {
    await this.setState({
      status: e.target.value,
    });
  };

  updateRequest = async (contentId) => {
    if (contentId == 4) {
      await this.updateRequestRemarks(contentId);
    }
    if (contentId == 5) {
      await this.updateRequestStatus(contentId);
    }
  };

  toggleOpenImageAttachmentModal = async () => {
    await this.setState({
      imageAttachmentModal: !this.state.imageAttachmentModal,
    });
  };

  onClickLiveTrack = async (value) => {
    window.open(value, "_blank");
  };

  openImageAttachmentModal = async (url) => {
    // console.log(url, "url");
    if (url.length > 0) {
      if (
        url
          .split(".")
          .pop()
          .toLowerCase() != "pdf" &&
        url
          .split(".")
          .pop()
          .toLowerCase() != "xlsx"
      ) {
        await this.setState({
          isImageAttachmentModal: !this.state.isImageAttachmentModal,
          imageUrlModal: url,
        });
        await this.toggleOpenImageAttachmentModal();
      } else {
        window.open(url, "_blank");
      }
    }
  };

  closeImageAttachmentModal = async () => {
    await this.toggleOpenImageAttachmentModal();
    await this.setState({
      isImageAttachmentModal: !this.state.isImageAttachmentModal,
      imageUrlModal: "",
      rotation: 0,
    });
  };

  handleChangeToggleSwitch = async (event) => {
    // console.log(event.target.checked)
    //true Category
    //false Status
    //default false Status
    await this.setState({
      isCheckedToggleSwitch: event.target.checked,
    });
  };

  getExcelReport = async () => {
    await this.setState({
      isExportLoading: true,
    });
    const res = await axios
      .post(
        `${Config.hostName}/bms/get/excel/report/dashboard`,
        {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          category: this.state.isCheckedToggleSwitch
            ? this.state.categoryWidget
            : "",
          statusCode: this.state.isCheckedToggleSwitch
            ? ""
            : this.state.statusCode,
        },
        {
          responseType: "blob",
        }
      )
      .then(async (res) => {
        // console.log(res.data);
        const pdfBlob = new Blob([res.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // console.log(pdfBlob, "pdf");
        saveAs(pdfBlob, `BMS_REPORT${moment(new Date()).format("MMDD")}.xlsx`);
        await this.setState({
          exportData: res.data.data,
          isExportLoading: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  render() {
    let modal = this.state.modal;
    let {
      style,
      startDate,
      endDate,
      widgetData,
      tableStyle,
      showGallery,
      tableModal,
      isImageAttachmentModal,
      imageAttachmentModal,
      viewRequestsModal,
      isViewRequestPageData,
      modalData,
      modalImagesArray,
      isExportLoading,
      rotation,
      isModalDataStepper,
      modalDataStepper,
    } = this.state;

    const defaultColDef = {
      flex: 1,
      minWidth: 90,
      resizable: true,
    };

    const AntSwitch = withStyles(() => ({
      checked: {},
    }))(Switch);
    return (
      <div className="main-home-page" style={{ paddingBottom: "60px" }}>
        <div id="cover-spin" style={style}></div>

        <div className="container-fluid">
          <div className="col-xl-12">
            <nav
              className="navbar navbar-light "
              style={{ backgroundColor: "none" }}
            >
              <div className="row" style={{ paddingTop: "8px", width: "100%" }}>
                <div className="col-sm-3">
                  <FormGroup>
                    <Input
                      type="text"
                      name="search_val"
                      value={this.state.search_val}
                      // style={{ borderColor: "#FF4848" }}
                      style={{
                        fontFamily:
                          "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                        fontVariantNumber: "tabular-nums",
                      }}
                      id="search_val"
                      placeholder="BMSID"
                      onChange={(e) => {
                        this.setState({ search_val: e.target.value });
                      }}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-2">
                  <button
                    type="submit"
                    className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                    // style={{ backgroundColor: "#FF4848", color: "white" }}
                    onClick={this.handleSearchShipmentByID}
                  >
                    <i
                      className="fa fa-search"
                      style={{ marginRight: "5px" }}
                    />
                    Search
                  </button>
                </div>

                <div className="col-sm-7"></div>

                <div className="col-sm-3">
                  <FormGroup>
                    <Input
                      type="select"
                      name="dateRange"
                      value={this.state.dateRangeSelected}
                      id="dateRange"
                      onChange={this.handleChangeDateRange}
                    >
                      <option
                        className="bms-dashboaord-date-range"
                        value={""}
                        disabled
                      >
                        Select Date Range
                      </option>

                      {this.state.uniqueDateRanges.map((eachItem, i) => (
                        <option value={i} key={i}>
                          {eachItem.title}
                        </option>
                      ))}
                      <option value={2222}>
                        {/* <Button onClick={this.toggle}> */}
                        Custom Date Range
                        {/* </Button> */}
                      </option>
                      <div>
                        <Modal
                          isOpen={modal}
                          // toggle={this.toggle}
                        >
                          <ModalHeader>Select Start and End date</ModalHeader>
                          <ModalBody>
                            <div className="row" style={{ minHeight: "1px" }}>
                              <div className="col float-left">
                                <div
                                  className="cal-datepicker"
                                  style={{ height: "100%" }}
                                >
                                  <div
                                    className="datepicker-here text-center"
                                    style={{ height: "100%" }}
                                    data-language="en"
                                  >
                                    <DatePicker
                                      height="400"
                                      placeholderText="Select Start Date"
                                      id="startDateInput"
                                      inline
                                      selectsRange
                                      selected={this.state.startDate}
                                      startDate={this.state.startDate}
                                      onChange={(date) => {
                                        this.setState({
                                          startDate: date,
                                        });
                                      }}
                                      dateFormat="yyyy-MM-dd"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col float-left">
                                <div className="cal-datepicker">
                                  <div
                                    className="datepicker-here text-center"
                                    data-language="en"
                                  >
                                    <DatePicker
                                      height="400"
                                      id="endDateInput"
                                      inline
                                      placeholderText="Select End Date"
                                      selected={this.state.endDate}
                                      onChange={(date) => {
                                        this.setState({
                                          endDate: date,
                                        });
                                      }}
                                      dateFormat="yyyy-MM-dd"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="info"
                              onClick={this.toggleConfirmModal}
                            >
                              Confirm
                            </Button>{" "}
                            <Button
                              color="danger"
                              onClick={this.toggleCloseModal}
                            >
                              Close
                            </Button>{" "}
                          </ModalFooter>
                        </Modal>
                      </div>
                    </Input>
                  </FormGroup>
                </div>

                <div className="col-sm-3 ">
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>Status</Grid>
                      <Grid item>
                        <AntSwitch
                          checked={this.state.isCheckedToggleSwitch}
                          onChange={this.handleChangeToggleSwitch}
                          name="isCheckedToggleSwitch"
                        />
                      </Grid>
                      <Grid item>Category</Grid>
                    </Grid>
                  </Typography>
                </div>

                <div className="col-sm-2">
                  <button
                    type="submit"
                    className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                    // style={{ backgroundColor: "#FF4848", color: "white" }}
                    onClick={this.handleSearchShipment}
                  >
                    <i
                      className="fa fa-search"
                      style={{ marginRight: "5px" }}
                    />
                    Search
                  </button>
                </div>

                <div className="col-sm-2">
                  <button
                    type="submit"
                    className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                    // style={{ backgroundColor: "#FF4848", color: "white" }}
                    onClick={this.handleRefreshData}
                  >
                    <i
                      className="fa fa-refresh"
                      style={{ marginRight: "5px" }}
                    />
                    Refresh
                  </button>
                </div>

                <div className="col-sm-2 ml-auto">
                  {this.state.apiData.length > 0 && (
                    // <CSVLink data={this.state.exportData}>
                    <button
                      type="submit"
                      className={
                        isExportLoading
                          ? "btn btn-danger w-100 overflow-hidden mb-3 px-auto disabled"
                          : "btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                      }
                      // className="btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                      disabled={isExportLoading}
                      onClick={this.getExcelReport}
                    >
                      {isExportLoading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}

                      {!isExportLoading && (
                        <i
                          className="fa fa-file-excel-o"
                          style={{ marginRight: "5px" }}
                        />
                      )}

                      {isExportLoading && <span>Processing ...</span>}
                      {!isExportLoading && (
                        <span>
                          {/* fa-file-excel-o */}
                          Export
                        </span>
                      )}
                    </button>
                    // </CSVLink>
                  )}
                </div>
              </div>
            </nav>
            {/* </div> */}
          </div>

          {/* {this.state.isData && <GeneralWidget data={widgetData} />} */}
          {this.state.isData && (
            <div>
              <div className="container-fluid ml-5">
                <div className="row">
                  {this.state.statusKeys.map((key, keyValue) => {
                    return (
                      <div
                        key={keyValue}
                        className="col-sm-2 col-lg-2 custom-card-widget-main"
                      >
                        {/* <button id={key} value={key} onClick={(e) => this.onDivClickTableUpdate(e)}> */}
                        <div
                          id={key}
                          value={key}
                          onClick={() => this.onDivClickTableUpdate(key)}
                          className="card custom-card-widget-invoice"
                        >
                          <div className="ecommerce-widget card-body">
                            <div className="row">
                              <div className="col-sm-12">
                                {widgetData.map((statusDataItem) => {
                                  if (statusDataItem.Category == "Count") {
                                    return (
                                      <div>
                                        <h5 className="counter-category-count-bms">
                                          {/* <CountUp end={statusDataItem[String(key)] ? statusDataItem[String(key)] : 0} /> */}
                                          {statusDataItem[String(key)]
                                            ? statusDataItem[String(key)]
                                            : 0}
                                        </h5>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                {widgetData.map((statusDataItem) => {
                                  if (statusDataItem.Category == "Sum") {
                                    return (
                                      <div>
                                        <h3 className="counter-category-sum-bms">
                                          ₹
                                          {statusDataItem[String(key)]
                                            ? statusDataItem[String(key)]
                                            : 0}
                                        </h3>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6
                          className="text-secondary"
                          style={{
                            textAlign: "center",
                            fontWeight: "bolder",
                            marginTop: "-5%",
                          }}
                        >
                          {key}
                        </h6>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {/* {this.state.apiData.length > 0 && */}
          <div>
            <div id="cover-spin" style={tableStyle}></div>

            <div
              className="ag-theme-balham"
              style={{
                height: "100%",
                width: "100%",
                minHeight: "500px",
              }}
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={this.state.rowData}
                defaultColDef={defaultColDef}
                enableCellTextSelection={true}
                rowHeight={50}
                domLayout={this.state.domLayout}
                // enableCellTextSelection={true}
                suppressAutoSize={true}
                gridAutoHeight={true}
              ></AgGridReact>
            </div>
          </div>
          {/* } */}

          <div>
            {isViewRequestPageData && (
              <Modal
                size="lg"
                style={{ maxWidth: "1600px", width: "80%" }}
                centered={true}
                isOpen={viewRequestsModal}
              >
                <ModalFooter>
                  <div className="col-sm-2">
                    <span
                      className="bms-view-req-modal-header-label"
                      style={{
                        fontFamily:
                          "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                        fontVariantNumber: "tabular-nums",
                      }}
                    >
                      BMS ID : {this.state.bmsId}
                    </span>
                  </div>
                  <div className="col-sm-2">
                    <span
                      className="bms-view-req-modal-header-label"
                      style={{
                        fontFamily:
                          "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                        fontVariantNumber: "tabular-nums",
                      }}
                    >
                      Txn Date : {modalData.BillingDate}
                    </span>
                  </div>
                  {modalData.billingMonth && (
                    <div className="col-sm-2">
                      <span
                        className="bms-view-req-modal-header-label"
                        style={{
                          fontFamily:
                            "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                          fontVariantNumber: "tabular-nums",
                        }}
                      >
                        Billing Month : {modalData.billingMonth}
                      </span>
                    </div>
                  )}
                  {modalData.BillingPeriodStartDate &&
                    modalData.BillingPeriodEndDate && (
                      <div className="col-sm-3">
                        <span
                          className="bms-view-req-modal-header-label"
                          style={{
                            fontFamily:
                              "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                            fontVariantNumber: "tabular-nums",
                          }}
                        >
                          Billing Period :{" "}
                          {moment(modalData.BillingPeriodStartDate).format(
                            "MMM Do YY"
                          )}{" "}
                          -{" "}
                          {moment(modalData.BillingPeriodEndDate).format(
                            "MMM Do YY"
                          )}
                        </span>
                      </div>
                    )}
                  <div className="col-sm-2">
                    <Button
                      color="danger"
                      onClick={this.toggleCloseViewRequestsModal}
                    >
                      <CloseIcon />
                    </Button>{" "}
                  </div>
                </ModalFooter>
                <ModalBody>
                  <div className="bms-view-req-modal-body">
                    <div>
                      <p
                        style={{
                          fontSize: "1.3rem",
                         
                          fontWeight: 700,
                        }}
                      >
                       Project: <span style={{
                         color: "red",
                       }}>
                        {modalData.city}_{modalData.ProjectName}
                        </span>
                      </p>
                      <div className="row bmsViewReqFirstRow" style={{
                        justifyContent: "space-evenly"
                      }}>
                        <div className="col-sm-3 bmsViewReqFirstRowItems">
                          <Card>
                            <CardBody>
                              {/* <CategoryIcon /> Category <br /> {data.category} */}
                              <div className="bmsViewReqFirstRowCardBodyMain">
                                <div className="bmsViewReqFirstRowCardBodyIcon">
                                  <span>
                                    <CategoryIcon />
                                  </span>
                                </div>
                                <div className="bmsViewReqFirstRowCardBodyNameMain">
                                  <span className="bmsViewReqFirstRowCardBodyValue">
                                    {modalData.category}
                                  </span>
                                  <br />
                                  <span className="bmsViewReqFirstRowCardBodyLabel">
                                    Category
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        <div className="col-sm-3 bmsViewReqFirstRowItems">
                          <Card>
                            <CardBody>
                              {/* <AccountBalanceWalletIcon /> Category <br /> {data.category} */}
                              <div className="bmsViewReqFirstRowCardBodyMain">
                                <div className="bmsViewReqFirstRowCardBodyIcon">
                                  {/* <span><i className="icofont icofont-cur-rupee" ></i></span> */}
                                  <span>
                                    <AccountBalanceWalletIcon />
                                  </span>
                                </div>
                                <div className="bmsViewReqFirstRowCardBodyNameMain">
                                  <span className="bmsViewReqFirstRowCardBodyValue">
                                    {modalData.totalAmount}
                                  </span>
                                  <br />
                                  <span
                                    className="bmsViewReqFirstRowCardBodyLabel"
                                    style={{
                                      fontFamily:
                                        "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                                      fontVariantNumber: "tabular-nums",
                                    }}
                                  >
                                    Total&nbsp;Amount
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        <div className="col-sm-3 bmsViewReqFirstRowItems">
                          <Card>
                            <CardBody>
                              {/* <CategoryIcon /> Category <br /> {data.category} */}
                              <div className="bmsViewReqFirstRowCardBodyMain">
                                <div className="bmsViewReqFirstRowCardBodyIcon">
                                  <span className="bmsViewReqFirstRowCardBodyIconRupee">
                                    <PersonIcon />
                                  </span>
                                </div>
                                <div className="bmsViewReqFirstRowCardBodyNameMain">
                                  <span className="bmsViewReqFirstRowCardBodyValue">
                                    {modalData.Requestor}
                                  </span>
                                  <br />
                                  <span className="bmsViewReqFirstRowCardBodyLabel">
                                    Requestor
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        {/* <div className="col-sm-3 bmsViewReqFirstRowItems">
                          <Card>
                            <CardBody>
                              <div className="bmsViewReqFirstRowCardBodyMain">
                                <div className="bmsViewReqFirstRowCardBodyIcon">
                                  <span className="bmsViewReqFirstRowCardBodyIconRupee">
                                    <PersonIcon />
                                  </span>
                                </div>
                                <div className="bmsViewReqFirstRowCardBodyNameMain">
                                  <span className="bmsViewReqFirstRowCardBodyValue">
                                    {modalData.ProjectName}
                                  </span>
                                  <br />
                                  <span className="bmsViewReqFirstRowCardBodyLabel">
                                    Project
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div> */}
                      </div>

                      <div className="row bmsViewReqNewCardsForStatusMain">
                        <div className="row bmsViewReqNewCardsForStatusFirst">
                          <div className="row bmsViewReqSecondRowItemDetails">
                            <span className="bms-view-request-items">
                              Items :{" "}
                            </span>
                            <Table responsive className="styled-table">
                              <thead>
                                <tr className="styled-table-header-row">
                                  <th>Item&nbsp;Name</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(modalData.itemDetails).map(
                                  (item) => (
                                    <tr className="styled-table-row">
                                      <td>{item.item}</td>
                                      <td>{item.amount}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>

                          <div className="row bmsViewReqThirdRowAttachments">
                            <span className="bms-view-request-attachments">
                              Attachments :{" "}
                            </span>
                            <br />
                          </div>

                          <div className="row bmsViewReqFourthRowGallery">
                            <div className="bms-view-request-main-div">
                              {modalImagesArray.length > 0 &&
                                modalImagesArray.map((imageUrl, i) => {
                                  return (
                                    <div
                                      className="bms-open-attachment-modal-image"
                                      onClick={() =>
                                        this.openImageAttachmentModal(
                                          imageUrl.AttachmentUrl
                                        )
                                      }
                                    >
                                      {imageUrl.AttachmentUrl &&
                                        imageUrl.AttachmentUrl.split(".")
                                          .pop()
                                          .toLowerCase() != "pdf" &&
                                        imageUrl.AttachmentUrl.split(".")
                                          .pop()
                                          .toLowerCase() != "xlsx" && (
                                          <img
                                            className="bms-view-request-img"
                                            src={imageUrl.AttachmentUrl}
                                            alt="No Image"
                                            key={i}
                                          />
                                        )}
                                      {imageUrl.AttachmentUrl &&
                                        imageUrl.AttachmentUrl.split(".")
                                          .pop()
                                          .toLowerCase() == "pdf" && (
                                          <img
                                            className="bms-view-request-img"
                                            src={pdfIcon}
                                            alt="No Image"
                                            key={i}
                                          />
                                        )}
                                      {imageUrl.AttachmentUrl &&
                                        imageUrl.AttachmentUrl.split(".")
                                          .pop()
                                          .toLowerCase() == "xlsx" && (
                                          <img
                                            className="bms-view-request-img"
                                            src={excelIcon}
                                            alt="No Image"
                                            key={i}
                                          />
                                        )}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          <div className="row bmsViewReqThirdRowAttachments">
                            <span className="bms-view-request-attachments">
                              Trip Ids :{" "}
                            </span>
                            <br />
                          </div>

                          <div className="row bmsViewReqFourthRowGallery">
                            <div className="bms-view-request-main-div">
                              {modalData.TripIDs &&
                                modalData.TripIDs.length && (
                                  <span className="bms-view-trip-details-tripids">
                                    {modalData.TripIDs}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="row bmsViewReqNewCardsForStatusSecond">
                          <div className="bmsViewReqNewCardsForStatusSecondMain">
                            <Card className="bmsViewReqNewCardsForStatusSecondMainCard">
                              <CardBody>
                                <div className="bmsViewReqNewCardsForStatusBodyMain">
                                  <div className="bmsViewReqNewCardsForStatusBodyFirst">
                                    {/* {JSON.parse(modalData.StatusHistory).map(stat =>
                                                                        <div>
                                                                            <span>{stat.SH}</span><br />
                                                                        </div>

                                                                    )} */}
                                    {isModalDataStepper &&
                                      modalDataStepper.length > 0 && (
                                        <StepperComponent
                                          data={modalDataStepper}
                                        />
                                      )}
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      </div>

                      {/** Payee Details */}
                      {JSON.parse(modalData.PayeeDetails) && (
                        <div className="bms-view-req-items-main">
                          <div className="bms-view-req-items-main-label">
                            <span className="bms-view-req-items-label">
                              Payee Details :{" "}
                            </span>
                          </div>

                          <div className="row bms-view-req-payee-details-card-view-main-container">
                            {JSON.parse(modalData.PayeeDetails) &&
                              JSON.parse(this.state.payeeDetails).map(
                                (bankListItem, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="card col-sm-4 bmsViewReqPayeeDetailsCardView"
                                    >
                                      <div className="bmsViewReqPayeeDetailsCardViewLeft">
                                        <img
                                          className="bmsViewReqPayeeDetailsCardViewLeftBankIcon"
                                          src={BankIcon}
                                          alt={i}
                                          width="50"
                                          height="60"
                                        />
                                      </div>

                                      <div className="bmsViewReqPayeeDetailsCardViewRight">
                                        <div className="bmsViewReqPayeeDetailsCardViewRightName">
                                          <span className="bmsViewReqPayeeDetailsCardViewRightNameValue">
                                            Name:{" "}
                                            {bankListItem.fullname
                                              ? bankListItem.fullname
                                              : " - "}
                                          </span>
                                        </div>

                                        <div className="bmsViewReqPayeeDetailsCardViewRightBenefeciaryName">
                                          <span className="bmsViewReqPayeeDetailsCardViewRightBenefeciaryNameValue">
                                            Benefeciary Name:{" "}
                                            {bankListItem.beneficiaryName
                                              ? bankListItem.beneficiaryName
                                              : " - "}
                                          </span>
                                        </div>

                                        <div className="bmsViewReqPayeeDetailsCardViewRightAccountNumber">
                                          <span className="bmsViewReqPayeeDetailsCardViewRightAccountNumberValue">
                                            A/C No:{" "}
                                            {bankListItem.AccountNumber
                                              ? bankListItem.AccountNumber
                                              : " - "}
                                          </span>
                                        </div>
                                        <div className="bmsViewReqPayeeDetailsCardViewRightBankName">
                                          <span className="bmsViewReqPayeeDetailsCardViewRightBankNameValue">
                                            Bank :{" "}
                                            {bankListItem.bankname
                                              ? bankListItem.bankname
                                              : " - "}
                                          </span>
                                        </div>
                                        <div className="bmsViewReqPayeeDetailsCardViewRightIfsc">
                                          <span className="bmsViewReqPayeeDetailsCardViewRightIfscValue">
                                            IFSC :{" "}
                                            {bankListItem.IFSC
                                              ? bankListItem.IFSC
                                              : " - "}
                                          </span>
                                        </div>

                                        <div className="bmsViewReqPayeeDetailsCardViewRightAmount">
                                          <span className="bmsViewReqPayeeDetailsCardViewRightAmountValue">
                                            Amount :{" "}
                                            {bankListItem.amount
                                              ? bankListItem.amount
                                              : " - "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      )}

                      <div className="row bmsViewReqFifthRowRemarks">
                        <span className="bms-view-request-remarks">
                          Remarks :{" "}
                        </span>
                        <Table responsive className="styled-table">
                          <thead>
                            <tr className="styled-table-header-row">
                              <th>Date</th>
                              <th>Note</th>
                              <th>Commented&nbsp;By</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.notesMain.map((note) => (
                              <tr className="styled-table-row">
                                <td>{note.notedate}</td>
                                <td>{note.Comments}</td>
                                <td>{note.Commentedby}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      <AvForm>
                        <div className="row bmsViewReqSixthRowAddRemarks">
                          <div className="col-sm-10">
                            <AvField
                              name="Remarks"
                              // label="Remarks"
                              value={this.state.remarks}
                              onChange={(e) => {
                                this.setState({
                                  remarks: e.target.value,
                                });
                              }}
                              type="textarea"
                              placeholder="Remarks"
                            />
                          </div>
                          <div className="col-sm-2">
                            <Button
                              // type="submit"
                              color="info"
                              // className="btn btn-primary w-100 overflow-hidden mb-3 px-auto"
                              value={3}
                              onClick={() => this.updateRequest(4)}
                            >
                              Add Note
                            </Button>
                          </div>
                        </div>
                      </AvForm>

                      {this.state.uniqueStatuses.length > 0 ? (
                        <div className="row bmsViewReqSixthRowStatusUpdate">
                          <div className="col-sm-3">
                            <FormGroup>
                              <Input
                                type="select"
                                name="status"
                                required
                                value={this.state.status}
                                id="status"
                                onChange={this.changeStatus}
                              >
                                <option value={""} disabled selected>
                                  Select Status
                                </option>

                                {this.state.uniqueStatuses.map((statusItem) => (
                                  <option value={statusItem.StatusID}>
                                    {statusItem.description}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </div>
                          <div className="col-sm-2 mr-auto">
                            <button
                              type="submit"
                              // className="btn btn-secondary"
                              className="btn btn-danger overflow-hidden mb-3 px-auto"
                              lj
                              onClick={() => this.updateRequest(5)}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            )}
          </div>

          <div>
            {isImageAttachmentModal && (
              <Modal
                size="lg"
                style={{ width: "70%", maxWidth: "100%" }}
                centered={true}
                isOpen={imageAttachmentModal}
              >
                <ModalFooter>
                  <div className="col-sm-3 ">
                    <Button
                      color="disabled"
                      onClick={() =>
                        this.setState({
                          rotation: this.state.rotation - 90,
                        })
                      }
                    >
                      <RotateLeftIcon />
                    </Button>{" "}
                    <Button
                      color="disabled"
                      onClick={() =>
                        this.setState({
                          rotation: this.state.rotation + 90,
                        })
                      }
                    >
                      <RotateRightIcon />
                    </Button>{" "}
                  </div>

                  <div className="col-sm-2 ml-auto">
                    <Button
                      color="danger"
                      onClick={this.closeImageAttachmentModal}
                    >
                      <CloseIcon />
                    </Button>{" "}
                  </div>
                </ModalFooter>{" "}
                <ModalBody>
                  <div className="bms-view-image-url-modal">
                    <img
                      src={this.state.imageUrlModal}
                      style={{ transform: `rotate(${rotation}deg)` }}
                      alt="No Image"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    onClick={this.closeImageAttachmentModal}
                  >
                    Close
                  </Button>{" "}
                </ModalFooter>
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
