/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import axios from "axios";
import Config from "../../../../config";
import {
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
    Marker,
    InfoWindow,
} from "react-google-maps";
import { Button } from "@material-ui/core";
import { FormGroup, Input, Label } from "reactstrap";
// import { DeleteIcon } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import "../../../../assets/customCssFiles/loader-spinner.css";
import "../../../../assets/customCssFiles/driverTracking.css";
import { cardsData, vehiclesData } from "./GpsTrackingCardsData";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import LocationSearchingRoundedIcon from "@material-ui/icons/LocationSearchingRounded";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import VehicleIcon from "../../../../assets/customImages/truck.png";
import DriverIcon from "../../../../assets/customImages/driver.png";
import OdometerIcon from "../../../../assets/customImages/odometer.png";
import LocationIcon from "../../../../assets/customImages/location.png";
import StatusIcon from "../../../../assets/customImages/status.png";
import EditIcon from "../../../../assets/customImages/editGpsTrackingDashboard.png";
import AddIcon from "../../../../assets/customImages/addGpsTrackingDashboard.png";
import EditDeleteIcon from "../../../../assets/customImages/editOrDeleteGpsTrackingDashboard.png";
import GetAppIcon from '@material-ui/icons/GetApp';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import ListIcon from '@material-ui/icons/List';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import SortIcon from '@material-ui/icons/Sort';

export default class leftCardDrivers extends Component {
    render() {
        return (
            <div className="gpsTrackingDashboardFunKeysMain">
                <div className="gpsTrackingDashboardFunKeysSearchBox">
                    {/* <FormGroup>
                        <Input
                            type="search"
                            name="search"
                            id="exampleSearch"
                            className="gpsTrackingDashboardFunKeysSearchBoxFormInput"
                            placeholder="Search by Vehicle No"
                        />
                    </FormGroup> */}
                    {/* <div className="input-group">
                        <input className="form-control py-2 border-right-0 border" type="search" value="search" id="example-search-input" />
                        <span className="input-group-append">
                            <button className="btn btn-outline-secondary border-left-0 border" type="button">
                                <i className="fa fa-search"></i>
                            </button>
                        </span>
                    </div> */}
                    <div className="input-group">
                        <input className="form-control py-2 border-right-0 border gpsTrackingDashboardFunKeysSearchBoxFormInput" type="search" id="example-search-input"
                            // className="gpsTrackingDashboardFunKeysSearchBoxFormInput"
                            placeholder="Search by Vehicle No"


                        />
                        <span className="input-group-append">
                            <div className="input-group-text bg-transparent"><i className="fa fa-search"></i></div>
                        </span>
                    </div>
                </div>

                <div className="gpsTrackingDashboardFunKeysListView">
                    <FormGroup>
                        <button className="btn gpsTrackingDashboardFunKeysListViewButton">
                            <span className="gpsTrackingDashboardFunKeysListViewButtonIcon">
                                <ListIcon color="disabled" />
                        &nbsp;
                      </span>
                            <span className="gpsTrackingDashboardFunKeysListViewButtonText">List View</span>
                        </button>
                    </FormGroup>
                </div>

                <div className="gpsTrackingDashboardFunKeysFilters">
                    <FormGroup>
                        <button className="btn gpsTrackingDashboardFunKeysFiltersButton">
                            <span className="gpsTrackingDashboardFunKeysFiltersButtonIcon">
                                <FilterListIcon color="disabled" />
                        &nbsp;
                      </span>
                            <span className="gpsTrackingDashboardFunKeysFiltersButtonText">Filters</span>

                        </button>

                    </FormGroup>
                </div>

                <div className="gpsTrackingDashboardFunKeysDownload">
                    <FormGroup>
                        <button className="btn gpsTrackingDashboardFunKeysDownloadButton">
                            <span className="gpsTrackingDashboardFunKeysDownloadButtonIcon">
                                <GetAppIcon color="disabled" />
                        &nbsp;
                      </span>
                            <span className="gpsTrackingDashboardFunKeysDownloadButtonText">Download</span>

                        </button>
                    </FormGroup>
                </div>

            </div >
        )
    }
}
