import React, {
    Component
    // ,Fragment
} from "react";
import axios from "axios";
// import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import {
    Table,
    // Button, FormGroup, Tooltip,Input
} from "reactstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";

import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import "../../../../assets/customCssFiles/loader-spinner.css"

class ShipmentTracking extends Component {
    state = {
        drivers: [],
        style: {}
    };

    componentDidMount = () => {
        axios.get(`${Config.hostName}/shipment/tracking-shipment/${this.props.auth.user.CCID}`)
            .then((res) => {
                if (res.data.success) {
                    console.log(res.data.success);


                    let data = res.data.data
                    let drivers = []

                    data.forEach((eachLocation) => {
                        let cond = drivers.findIndex(item => item.driverName === eachLocation.driverName)

                        if (cond === -1) {
                            drivers.push({
                                driverName: eachLocation.driverName,
                                locations: [{
                                    trackingNumber: eachLocation.trackingNumber,
                                    ActualDeliveredStatus: eachLocation.ActualDeliveredStatus,
                                    StopOrder: eachLocation.StopOrder,
                                    deliveryInTime: eachLocation.deliveryIntime,
                                    deliveryOutTime: eachLocation.deliveryOuttime,
                                    routeNo: eachLocation.routeno,
                                    deliverySlot: eachLocation.deliverySlot,
                                    deliverySlotColor: eachLocation.deliverySlotColor,
                                    deliveryStatusColor: eachLocation.deliveryStatusColor,
                                    cSignUrl: eachLocation.csignurl
                                }]
                            })
                        }
                        else {
                            drivers[cond].locations.push({
                                trackingNumber: eachLocation.trackingNumber,
                                ActualDeliveredStatus: eachLocation.ActualDeliveredStatus,
                                StopOrder: eachLocation.StopOrder,
                                deliveryInTime: eachLocation.deliveryIntime,
                                deliveryOutTime: eachLocation.deliveryOuttime,
                                routeNo: eachLocation.routeno,
                                deliverySlot: eachLocation.deliverySlot,
                                deliverySlotColor: eachLocation.deliverySlotColor,
                                deliveryStatusColor: eachLocation.deliveryStatusColor,
                                cSignUrl: eachLocation.csignurl

                            })
                        }
                    })

                    this.setState({ drivers: drivers, style: { display: 'none' } })
                    // res.data[0].map((item) => {
                    // })
                    // console.log(this.state.drivers)

                }
                else {
                    this.setState({ drivers: [], style: { display: 'none' } })
                    this.props.setAlert(res.data.message, "danger");
                }

            }).catch((error) => {
                console.log(error)
            });
    }

    getData = () => {
        return
    }

    render() {
        let { style } = this.state;
        // let i;
        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;

        const Container = styled.div`
            text-transform : capitalize;
            font-size: 1.4rem;
            background: transparent;
            text-align: center;
            border: 0.05rem solid black;
            color : white;
            border-radius: 0.5rem;
            padding: 0.2rem 0.5rem;
            cursor:pointer;
            margin: 0.2rem 0.5rem 0.2rem 0;
            transition:all 0.5s ease-in-out;
            `;

        const StyledReactTooltip = styled(ReactTooltip)`
            background-color: white !important;
            color: black !important;
            box-shadow: 0px 2px 20px lightgray;
            pointer-events: none;
            &:after {
                border-top-color: white !important;
            }
            `;

        return (
            <div>
                <div id='cover-spin' style={style}>
                </div>

                {/* < Breadcrumb link="ImportantDates" parent="Admin" /> */}

                <div className="container-fluid"
                // style={{
                //     position: "fixed",
                //     top: "0",
                //     zIndex: "1"
                // }}
                >
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                {/* <div className="card-header">
                                    <div className="row">
                                        <div
                                            className="col-sm-12">
                                            <h4
                                                style=
                                                {{
                                                    position: "sticky",
                                                    top: "0"
                                                }}
                                                className="card-link mb-0">Tracking Details</h4>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="card-body"
                                    style={{
                                        overflowX: "scroll", height: "auto", marginBottom: "10vh"
                                    }}
                                >
                                    <Table >
                                        <thead>
                                            <tr>
                                                <th style={{ overflow: "auto" }}>Driver Name</th>
                                                <th style={{ textAlign: "center", marginLeft: "5px" }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.drivers.map((driver, i) => {
                                                    return (
                                                        <div>
                                                            <tr
                                                                style={{
                                                                    display: "flex",
                                                                    overflowX: "scroll",

                                                                }}
                                                            >
                                                                <th style={{
                                                                    width: "150px",
                                                                    whiteSpace: "nowrap"
                                                                }} key={i}>{driver.driverName}</th>
                                                                <div
                                                                >
                                                                    {
                                                                        driver.locations.map((location, index) => {
                                                                            return (
                                                                                < td
                                                                                    key={index} >
                                                                                    < Container
                                                                                        style={{
                                                                                            backgroundColor: `${location.deliveryStatusColor}`,
                                                                                            color: location.ActualDeliveredStatus === 0 ? "#A9A9A9" : "white",
                                                                                            border: `4px solid ${location.deliverySlotColor}`,

                                                                                        }}
                                                                                        data-tip data-for={location.trackingNumber} >
                                                                                        <p>Stop{location.StopOrder}</p>
                                                                                    </Container>

                                                                                    <StyledReactTooltip id={location.trackingNumber} type="info">
                                                                                        <span>Tracking Id : {location.trackingNumber} </span><br />
                                                                                        <span>Route : {location.routeNo} </span><br />
                                                                                        <span style={{ marginBottom: "2vh" }}>Scheduled Time : {`${location.deliveryInTime}-${location.deliveryOutTime}`} </span><br />
                                                                                        {location.cSignUrl ? <span
                                                                                        ><img
                                                                                                style={{ border: "5px solid #1C6EA4", borderRadius: "26px" }}
                                                                                                src={location.cSignUrl} alt="Customer Sign" width="200" height="50" />
                                                                                        </span>
                                                                                            : <span></span>}

                                                                                    </StyledReactTooltip>
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </tr>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* </tr> */}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

ShipmentTracking.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(ShipmentTracking);
