/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "axios";
import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import { FormGroup, Input } from "reactstrap";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/calender.css"

import EditIcon from '@material-ui/icons/Edit';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import "../../../../assets/customCssFiles/loader-spinner.css"


class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            modal: false,
            anotherModal: false,
            locations: [],
            unique_route: [],
            unique_dates: [],
            description: "",
            message: "",
            selected_date: "",
            style: {},
            tracking_number: "",
            columnDefs: [
                {
                    headerName: (
                        this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor")
                        ? "Order No" : "Tracking No",
                    field:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor")

                            ? "OrderNo" : "trackingNumber", sortable: true, filter: true, maxWidth: 120
                },
                {
                    headerName: "Scheduled Date", field: "EstimatedDelivery", sortable: true, filter: true, maxWidth: 140
                },
                {
                    headerName: "Customer Name", field: "CustomerName", sortable: true, filter: true, maxWidth: 260
                },
                {
                    headerName: "Customer No", field: "CustomerNumber", sortable: true, filter: true,
                    maxWidth: 200

                },
                {

                    headerName:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?

                            "Item No" : "", field:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?
                            "ItemNo" : "", sortable: true, filter: true,
                    maxWidth: 190

                },
                {
                    headerName:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?


                            "Qty" : "", field:

                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?

                            "Qty" : "", sortable: true, filter: true,
                    maxWidth: 80
                },
                {
                    // headerName: "Full Address", field: "FullAddress", sortable: true, filter: true,

                    headerName:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin") ?

                            "Status Date" : "Full Address", field:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin") ?

                            "statusDate" : "FullAddress", sortable: true, filter: true,
                    maxWidth:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin") ?

                            200 : 400

                    // rowSpan: "2"
                },

                {
                    headerName:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?

                            "Status" : "Postal",
                    field:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?

                            "Status" : "Postal", sortable: true, filter: true,
                    maxWidth: 220,
                    minWidth: 220,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.Status;
                        const StatusColor = params.node.data.StatusColor;
                        return (
                            <button type="button" className="btn " style={{ backgroundColor: `${StatusColor}` }} >{status}</button>
                        )
                    }

                },
                {
                    headerName: "Edit", field: "Edit", editable: true, maxWidth: 100,
                    cellRendererFramework: (params) => {
                        return <Link to={"/admin/shipment/" + params.node.data.ID}>
                            <Button
                                color="primary"
                                onClick={() =>
                                    this.setState({
                                        modal: !this.state.modal
                                    })
                                }
                            >
                                <EditIcon />
                            </Button>
                        </Link>
                    }
                },
                {
                    headerName:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?

                            "View More" : "", field:
                        (this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?

                            "ViewMore" : "", editable: true,
                    maxWidth: 140,

                    cellRendererFramework: (params) => {
                        return <Link to={"/admin/shipment/table-view/show-more/" + params.node.data.trackingNumber}>

                            {(this.props.auth.user.user_type === "ROAdmin" || this.props.auth.user.user_type === "ROSuperAdmin" || this.props.auth.user.user_type === "ROSupervisor") ?


                                <Button
                                    color="primary"
                                    onClick={() =>
                                        this.setState({
                                            anotherModal: !this.state.anotherModal
                                        })
                                    }
                                >
                                    More
                            </Button> : ""}
                        </Link>
                    }
                }
            ],
            rowData: [],
            activePage: 1,
            itemsCountPerPage: 50,
            totalItemsCount: 5000,
            pageRangeDisplayed: 5,
            page: 1,
            search: false,
            userCcId: null,
            city: "",
            unique_cities: [],
            ccid: ""

        };
    }


    handlePageChange = async (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        await this.setState({ activePage: pageNumber, style: {} });
        if (this.state.search === false)
            await this.componentWillMount();
        else
            await this.handleSearchShipment();
    }

    getUniqueDates = async () => {
        await axios.get(`${Config.hostName}/shipment/unique/date/${this.state.userCcId}`)
            .then(async (res) => {
                // console.log(res.data);

                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_dates: res.data.data,
                        // selected_date: res.data.data[0].Loaddate,
                        style: { display: 'none' }
                    })
                    console.log(this.state.unique_dates);

                }
                else {
                    this.setState({ unique_dates: [], style: { display: 'none' } })

                }
            })
            .catch((error) => {
                console.log(error)
            });
    }



    componentWillMount = async () => {
        let { activePage } = this.state;

        var result = await axios.get(`${Config.hostName}/shipment/get/all/data/table-view/${activePage}/${this.props.auth.user.CCID}`);
        // console.log(result.data, "data");

        await this.setState({
            search: false,
            locations: result.data.data,
            userCcId: this.props.auth.user.CCID
        });
        await this.setState({
            rowData: this.state.locations.map(eachItem => {
                return {
                    ID: eachItem.id,
                    trackingNumber: eachItem.tracking_num,
                    OrderNo: eachItem.Orderno,
                    CustomerName: eachItem.customer_name,
                    EstimatedDelivery: eachItem.estimate_arrival_date,
                    FullAddress: eachItem.customer_address_line,
                    Postal: eachItem.postal,
                    CustomerNumber: eachItem.phone,
                    ItemNo: eachItem.itemno,
                    Qty: eachItem.qty,
                    Edit: eachItem.id,
                    Status: eachItem.status,
                    StatusId: eachItem.StatusId,
                    StatusColor: eachItem.StatusColor,
                    statusDate: moment(eachItem.statusDate).format("YYYY-MM-DD")

                }
            }),

        });

        if (this.state.userCcId == "3" || this.state.userCcId == "4") {
            await this.getUniqueDates();
        }



        await axios.get(`${Config.hostName}/shipment/get/unique-cities/${this.state.userCcId}`)
            .then(async (res) => {
                // console.log(res.data);

                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_cities: res.data.data,
                        // city: res.data.data[0].city,
                        style: { display: 'none' }
                    })
                    console.log(this.state.unique_cities);

                }
                else {
                    this.setState({ unique_dates: [], style: { display: 'none' } })

                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    handleSearchShipment = async () => {
        let { activePage } = this.state;
        // console.log(this.state.tracking_number, this.state.city, this.state.userCcId);
        // if (this.state.tracking_number) {

        await this.setState({
            style: {},
            search: true
        })
        try {
            let result =
                await axios.post(
                    `${Config.hostName}/shipment/search/table-view/${activePage}/${this.state.userCcId}`, {
                    trackingNumber: this.state.tracking_number,
                    date: this.state.selected_date
                }
                );
            // console.log(result.data.data, "result.data.data");

            await this.setState({
                locations: result.data.data
            });

            await this.setState({
                rowData: this.state.locations.map(eachItem => {
                    return {
                        ID: eachItem.id,
                        OrderNo: eachItem.Orderno,
                        trackingNumber: eachItem.tracking_num,
                        CustomerName: eachItem.customer_name,
                        EstimatedDelivery: eachItem.estimate_arrival_date,
                        FullAddress: eachItem.full_address,
                        Postal: eachItem.postal,
                        CustomerNumber: eachItem.phone,
                        ItemNo: eachItem.itemno,
                        Qty: eachItem.qty,
                        Edit: eachItem.id,
                        Status: eachItem.status,
                        StatusId: eachItem.StatusId,
                        StatusColor: eachItem.StatusColor,
                        statusDate: moment(eachItem.statusDate).format("YYYY-MM-DD")
                    }

                }),
                style: { display: 'none' }

            });
        } catch (e) {
            console.log(e);
        }
        // }
        // else {
        //     this.props.setAlert("Please Enter Tracking Number", "danger");
        // }

    }

    handleChangeCity = async (e) => {
        await this.setState({ style: {}, userCcId: e.target.value, ccid: e.target.value });

        await this.getUniqueDates();
    }


    render() {
        let style = this.state.style;
        // console.log(this.state.userCcId);

        const defaultColDef = {
            flex: 1,
            minWidth: 70,
            resizable: true,
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id='cover-spin' style={style}></div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>
                                        {this.props.auth.user.user_type === "ROSuperAdmin" ?
                                            <div className="col-sm-2">
                                                <FormGroup>
                                                    <Input
                                                        type="select"
                                                        name="city"
                                                        value={this.state.ccid}
                                                        id="city"
                                                        onChange={this.handleChangeCity}
                                                    >
                                                        <option value={""} disabled>Select Cities</option>

                                                        {this.state.unique_cities.map(eachItem => (
                                                            <option value={eachItem.ccid}>{eachItem.city}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </div>
                                            : ""}


                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="routeNo"
                                                    value={this.state.selected_date}
                                                    id="routeNo"
                                                    onChange={event => {
                                                        this.setState({ selected_date: event.target.value });
                                                    }}
                                                >
                                                    <option value={""} disabled>Select dates</option>

                                                    {this.state.unique_dates.map(date => (
                                                        <option value={date.Loaddate}>{date.Loaddate}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    placeholder="Order No"
                                                    name="tracking_num"
                                                    value={this.state.tracking_number}
                                                    id="tracking_num"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            tracking_number: e.target.value
                                                        })
                                                    }}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                type="submit"
                                                // className="btn btn-primary"
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.handleSearchShipment}
                                            >
                                                Search
                      </button>
                                        </div>

                                        <div className="col-sm-2 ml-auto">
                                            {this.state.locations.length > 0 && (
                                                <CSVLink data={this.state.locations}>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                                                    // className="btn btn-primary"

                                                    >Export
                      </button>
                                                </CSVLink>
                                            )}

                                        </div>

                                    </div>

                                </nav>

                            </div>

                            <div className="card-body" style={{ height: "100vh" }}>

                                <div
                                    className="ag-theme-balham"
                                    style={{
                                        height: '85vh',
                                        width: '100%'
                                    }}
                                >
                                    <AgGridReact
                                        columnDefs={this.state.columnDefs}
                                        rowData={this.state.rowData}
                                        defaultColDef={defaultColDef}
                                        animateRows={true}
                                        rowHeight={40}
                                        enableCellTextSelection={true}
                                        suppressAutoSize={true}


                                    >
                                    </AgGridReact>
                                </div>
                                <div style={{ margin: "5vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                                        onChange={this.handlePageChange.bind(this)}
                                        prevPageText='prev'
                                        nextPageText='next'
                                        firstPageText='first'
                                        lastPageText='last'
                                        itemClass="page-item"
                                        linkClass="page-link"

                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            // </div >
        );
    }

}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);