import React, {
  Component
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from "reactstrap";
// import {Link} from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";

class RouteAddForm extends Component {
  state = {
    location: "",
    Vendorid: "",
    Vehiclenumber: "",
    VehicleSize: "",
    RocNumber: "",
    ISGps: "",
    ISGpsValue: "",
    vehicleSizesDropDown: []
  };

  handleCheckPrimary = async () => {
    await this.setState({
      ISGps: !this.state.ISGps
    });
    await this.setState({
      // IsOdometer=true ? (IsOdometerValue = 1) : (IsOdometerValue = 0)
      ISGpsValue: `${this.state.ISGps === true ? "1" : "0"}`
    });
    console.log(this.state.ISGps);
    console.log(this.state.ISGpsValue);
  };

  getUniqueVehicleSizes = async () => {
    await axios.get(`${Config.hostName}/vms/get/unique/vehicle/sizes/dropdown`)
      .then(async (res) => {
        await this.setState({ vehicleSizesDropDown: res.data.data })
      })
      .catch((error) => {
        // console.log(error)
        alert("getUniqueLocationsDropDown : ", error);
      });
  }


  // {{URI}}/vms/get/unique/vehicle/sizes/dropdown

  async componentWillMount() {
    let result = await axios.get(
      `${Config.hostName}/vms/vehicles/${this.props.match.params.VendorID}`
    );
    console.log(result.data);
    await this.getUniqueVehicleSizes();
    await this.setState({
      Vendorid: result.data.data[0].VendorID
    });
  }

  onSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      try {
        // eslint-disable-next-line
        const res = await axios.post(
          `${Config.hostName}/vms/vehicles/add/new-vehicle`,

          {
            vendorId: this.state.Vendorid,
            vehicleNumber: this.state.Vehiclenumber,
            vehicleSize: this.state.VehicleSize,
            rocNumber: this.state.RocNumber,
            isGpsValue: this.state.ISGpsValue
          }).then((response) => {
            console.log(response.data, "response");
            if (response.data.success === false) {
              this.props.setAlert(response.data.message, "danger");
            }
            else {
              this.props.setAlert("New Vehicle Added", "success");
            }
          }).then(() => {
            this.props.history.push("/admin/vms/vehicles");
          })
          .catch((error) => {
            console.log(error, 'error');
          })
      } catch (error) {
        const errors = error.response.data.errors;
        if (errors) {
          errors.forEach(error => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;

    return (
      <div>
        {/*Container-fluid starts*/}
        <Breadcrumb link="ImportantDates" parent="Admin" />
        {/*Container-fluid Ends*/}

        {/*Container-fluid starts*/}
        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />
                <div className="card">

                  <div className="card-header bg-primary">
                    <div className="row">
                      <div className="col-sm-12">
                        <h4 className="card-link mb-0">Add Vehicle Form</h4>
                      </div>
                    </div>
                  </div>


                  <form>
                    <div className="col-lg-12 text-right" style={{ marginTop: "10vh" }}></div>
                    <div className="col-lg-12">
                      <div className="grid">
                        <div className="grid-body">
                          <div className="item-wrapper">
                            <div className="row mb-3">
                              <div className="col-md-12 mx-auto">
                                <div className="form-row">
                                  <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                    <div className="col-md-3 showcase_text_area">
                                      <label>Vendor ID</label>
                                    </div>
                                    <div className="col-md-9 showcase_text_area">
                                      <input
                                        // placeholder="Enter PAN Number"
                                        formControlName="Vendorid"
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        defaultValue={this.state.Vendorid}
                                      // onChange={event => {
                                      //     this.setState({ Vendorid: event.target.value });
                                      // }}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-md-6 row showcase_row_area">
                                    <div className="col-md-3 showcase_text_area">
                                      <label>Vehicle No * </label>
                                    </div>
                                    <div className="col-md-9 showcase_text_area">
                                      <input
                                        placeholder="Enter Vehicle Number"
                                        formControlName="Vehiclenumber"
                                        type="text"

                                        className="form-control"
                                        value={this.state.Vehiclenumber}
                                        onChange={event => {
                                          this.setState({
                                            Vehiclenumber: event.target.value
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-row">
                                  <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                    <div className="col-md-3 showcase_text_area">
                                      <label>Vehicle Size * </label>
                                    </div>
                                    <div className="col-md-9 showcase_text_area">
                                      <Input
                                        placeholder="Enter Vehicle Size"
                                        formControlName="VehicleSize"
                                        type="select"
                                        className="form-control"
                                        value={this.state.VehicleSize}
                                        onChange={event => {
                                          this.setState({
                                            VehicleSize: event.target.value
                                          });
                                        }}
                                      >
                                        <option value={""} disabled selected  >Select Vehicle Size</option>
                                        {this.state.vehicleSizesDropDown.length > 0 && this.state.vehicleSizesDropDown.map(vendor => (
                                          <option value={vendor.vehicleSizeValue}>
                                            {vendor.vehicleSize}
                                          </option>
                                        ))}
                                      </Input>

                                    </div>
                                  </div>

                                  <div className="form-group col-md-6 row showcase_row_area">
                                    <div className="col-md-3 showcase_text_area">
                                      <label>Roc Number</label>
                                    </div>
                                    <div className="col-md-9 showcase_text_area">
                                      <input
                                        placeholder="Enter Roc Number"
                                        formControlName=" RocNumber"
                                        type="text"
                                        className="form-control"
                                        value={this.state.RocNumber}
                                        onChange={event => {
                                          this.setState({
                                            RocNumber: event.target.value
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-4">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        onChange={this.handleCheckPrimary}
                                        // value={this.state.IsOdometer}
                                        checked={this.state.ISGps}
                                      />{" "}
                                      Is GPS
                                  </Label>
                                  </FormGroup>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-outline-primary btn-lg"
                                  style={{ float: "right" }}
                                  onClick={e => this.onSubmit(e)}
                                >
                                  Add Vehicle
                              </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RouteAddForm.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
