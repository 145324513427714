/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    FormGroup, Input, Button, ModalHeader, Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
// import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../common/Alert";
import { setAlert } from "../../../redux/actions/alert.actions";
import Config from "../../../config";
import { AgGridReact } from 'ag-grid-react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { StepperComponent } from "./stepper";
// import { Link } from "react-router-dom";

import "../../../assets/customCssFiles/loader-spinner.css"


class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            locations: [],
            link: "",
            modal: false,
            anotherModal: false,
            unique_route: [],
            unique_dates: [],
            message: "",
            selected_date: "",
            tracking_number: "",
            columnDefs: [


                {
                    headerName: "Tracking Number", field: "trackingId", sortable: true, filter: true
                },
                {
                    headerName: "City", field: "city", sortable: true, filter: true
                },
                // {
                //     headerName: "Scheduled Date", field: "customerCode", sortable: true, filter: true, maxWidth: 140
                // },
                {
                    headerName: "Customer Name", field: "customerName", sortable: true, filter: true
                },
                // {
                //     headerName: "Customer No", field: "ead", sortable: true, filter: true, maxWidth: 200
                // },

                {
                    headerName: "Order No", field: "orderNo", sortable: true, filter: true
                },
                {
                    headerName: "Mobile", field: "phone01", sortable: true, filter: true
                },
                {
                    headerName: "Load Date", field: "loadDate", sortable: true, filter: true
                },
                {
                    headerName: "Vehicle", field: "dispatchVehicleNo", sortable: true, filter: true
                },
                {
                    headerName: "View More", editable: true,

                    cellRendererFramework: (params) => {
                        let id = params.node.data.trackingId
                        return <Button
                            color="primary"
                            value={id}
                            onClick={(e) => this.clickForViewMore(e)}
                        >
                            More
                            </Button>
                    }
                }
            ],
            rowData: [],
            activePage: 1,
            itemsCountPerPage: 50,
            totalItemsCount: 5000,
            pageRangeDisplayed: 5,
            page: 1,
            search: false,
            userCcId: null,
            unique_cities: [],
            ccid: "",
            domLayout: "autoHeight",
            viewMoreArray: [],
            formData: {},
            itemDetails: "",
            trackingId: "",
            city: "",
            customerCode: "",
            customerName: "",
            ead: "",
            fullAddress: "",
            loadDate: "",
            orderNo: "",
            phone01: "",
            phone02: "",
            scheduledDate: "",
            trackingHistory: "",
            trackingUrl: "",
            searchApi: "",
            searchForm: ""
        };
    }

    clickForViewMore = async (e) => {
        // console.log(e.target.value, "id");
        const value = e.target.value;
        let viewMoreArray = [];
        this.state.locations.map((item) => {
            if (item.TrackingID == value) {
                viewMoreArray.push(item);
            }
        })
        await this.setState({
            viewMoreArray: viewMoreArray
        })

        const mapFun = viewMoreArray.map(async (eachItem) => {
            const itemDetails = JSON.parse(eachItem.ItemDetails)

            await this.setState({
                itemDetails: itemDetails[0],
                trackingId: eachItem.TrackingID,
                city: eachItem.city,
                customerCode: eachItem.customerCode,
                customerName: eachItem.customerName,
                ead: eachItem.ead,
                fullAddress: eachItem.fullAddress,
                loadDate: eachItem.loadDate,
                orderNo: eachItem.orderno,
                phone01: eachItem.phone01,
                phone02: eachItem.phone02,
                scheduledDate: eachItem.scheduledDate,
                trackingHistory: eachItem.trackingHistory,
                trackingUrl: eachItem.trackingurl
            })
            await this.toggle();
        })
        const dataGet = await Promise.all(mapFun);
        // console.log(this.state.viewMoreArray);


    }
    // LCR5660202103920706

    getDataFromApi = async () => {
        var result = await axios.post(`${Config.hostName}/tracking/get/data/cms/dashboard/shipment-tracking`, {
            search: this.state.searchApi
        });
        let dataObj = result.data.data
        console.log(dataObj, "data");
        await this.setState({
            locations: dataObj
        });
        await this.setState({
            rowData: this.state.locations.map(eachItem => {
                return {
                    trackingId: eachItem.TrackingID,
                    city: eachItem.city,
                    customerName: eachItem.customerName,
                    orderNo: eachItem.orderno,
                    phone01: eachItem.phone01,
                    loadDate: eachItem.loadDate,
                    dispatchVehicleNo: eachItem.dispatchVehicleNo
                }
            }),
            style: { display: "none" }
        });
    }

    componentWillMount = async () => {
        // await this.getDataFromApi();
        await this.setState({
            style: { display: "none" }

        })

    }

    toggle = async () => {
        await this.setState({
            anotherModal: !this.state.anotherModal,
        });
    };

    toggleCloseModal = async () => {
        await this.toggle();

    };

    toggleConfirmModal = async () => {
        await this.toggle();

    };

    handleSearchShipment = async () => {
        await this.setState({
            style: {}
        })
        await this.getDataFromApi();

    }

    render() {
        let { style, anotherModal } = this.state;
        // console.log(this.state.userCcId);

        const defaultColDef = {
            flex: 1,
            minWidth: 70,
            resizable: true,
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id="cover-spin" style={style}></div>
                <div className="container-fluid">
                    <div className="col-xl-12">
                        <nav
                            className="navbar navbar-light "
                            style={{ backgroundColor: "none" }}
                        >
                            <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                <div className="col-sm-3">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            placeholder="Enter Tracking No / Mobile / Name "
                                            name="searchForm"
                                            value={this.state.searchForm}
                                            id="searchForm"
                                            onChange={(e) => {
                                                this.setState({
                                                    searchForm: e.target.value,
                                                    searchApi: e.target.value
                                                })
                                            }}
                                        >
                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-3">
                                    <button
                                        type="submit"
                                        className="btn btn-info"
                                        onClick={this.handleSearchShipment}
                                    >
                                        Search
                      </button>
                                </div>

                            </div>
                        </nav>

                    </div>

                    <div className="card-body" style={{
                        // height: "90vh",
                        // maxHeight: ""
                    }}>

                        <div
                            className="ag-theme-balham"
                            style={{
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                defaultColDef={defaultColDef}
                                animateRows={true}
                                rowHeight={40}
                                enableCellTextSelection={true}
                                suppressAutoSize={true}
                                gridAutoHeight={true}
                                domLayout={this.state.domLayout}

                            >
                            </AgGridReact>
                        </div>
                        <div style={{ margin: "10vh auto", marginLeft: "25%", justifyContent: "center" }}>

                        </div>
                    </div>


                    <div >
                        <Modal
                            style={{ width: "100%" }}
                            isOpen={anotherModal}
                        >
                            <ModalHeader>Tracking Information</ModalHeader>
                            <ModalBody>
                                <div className="row" style={{ minHeight: "1px" }}>
                                    <div className="container-fluid">
                                        <div className="edit-profile">
                                            <div className="row ">
                                                <div className="col-xl-12">
                                                    <Alert />


                                                    <div>
                                                        <div >
                                                            <h5>Tracking History</h5>
                                                            {this.state.trackingHistory ?
                                                                <StepperComponent props={this.state.trackingHistory} />
                                                                : ""
                                                            }
                                                        </div>

                                                        <div className="card-body">


                                                            <div className="form-row">

                                                                <div className="form-group col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Name</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter Contact No"
                                                                            formControlName="customerName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.customerName}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    customerName: event.target.value
                                                                                });
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Mobile No</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter State"
                                                                            formControlName="phone01"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.phone01}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    phone01: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Alt Mobile No</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter Mobile No"
                                                                            formControlName="phone02"
                                                                            type="number"
                                                                            required
                                                                            className="form-control"
                                                                            value={this.state.phone02}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    phone02: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="form-row">

                                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Quantity</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter Qty"
                                                                            formControlName="Qty"
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={this.state.itemDetails.Qty}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    Qty: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Estd Arr Date</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter Comments"
                                                                            formControlName="ead"
                                                                            // type="datetime-local"
                                                                            type="date"
                                                                            className="form-control"
                                                                            value={this.state.ead}
                                                                            onChange={event => {
                                                                                // console.log(event.target.value);

                                                                                this.setState({
                                                                                    ead: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>

                                                                <div className="form-group col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>BP Code</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter Comments"
                                                                            formControlName="customerCode"
                                                                            // type="datetime-local"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.customerCode}
                                                                            onChange={event => {
                                                                                // console.log(event.target.value);

                                                                                this.setState({
                                                                                    customerCode: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    {/* </div> */}



                                                                    {/* </div> */}

                                                                </div>

                                                            </div>


                                                            <div className="form-row">

                                                                <div className="form-group col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Order No</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter order No"
                                                                            formControlName="orderNo"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.orderNo}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    orderNo: event.target.value
                                                                                });
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Item No</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter State"
                                                                            formControlName="phone01"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.itemDetails.ItemNumber}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    phone01: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                                    <div className="col-md-3 showcase_text_area">
                                                                        <label>Total Boxes</label>
                                                                    </div>
                                                                    <div className="col-md-9 showcase_text_area">
                                                                        <input
                                                                            disabled
                                                                            placeholder="Enter Mobile No"
                                                                            formControlName="phone02"
                                                                            type="number"
                                                                            required
                                                                            className="form-control"
                                                                            value={this.state.itemDetails.BoxCnt}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    phone02: event.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <AvForm>
                                                                <div className="col-md-12">
                                                                    <AvField
                                                                        disabled
                                                                        name="description"
                                                                        label="Description"
                                                                        value={this.state.itemDetails.Description}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                description: e.target.value
                                                                            })
                                                                        }}
                                                                        type="textarea"
                                                                        placeholder="description"
                                                                    />
                                                                </div>
                                                            </AvForm>

                                                            <AvForm>
                                                                <div className="col-md-12">
                                                                    <AvField
                                                                        disabled
                                                                        name="fullAddress"
                                                                        label="Customer Address"
                                                                        value={this.state.fullAddress}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                fullAddress: e.target.value
                                                                            })
                                                                        }}
                                                                        type="textarea"
                                                                        placeholder="fullAddress"
                                                                    />
                                                                </div>
                                                            </AvForm>


                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="danger"
                                    onClick={this.toggleCloseModal}
                                >
                                    Close
                            </Button>{" "}
                            </ModalFooter>
                        </Modal>
                    </div>

                </div>
            </div>
        );
    }

}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);