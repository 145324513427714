import React, { Component } from "react";
import axios from "axios";
import GeneralWidget from "./GeneralWidget";
import Config from "../../../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import { FormGroup, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../assets/customCssFiles/tmshomepage.css";
import { defaults } from "react-chartjs-2";
import BarStackGraphByCity from "./graphs/barStackByCity";
import CmtGraph from "./graphs/cmtGraph";
import ByClientGraph from "./graphs/byClient";
import BySegmentGraph from "./graphs/bySegment";
import "../../../../assets/customCssFiles/loader-spinner.css"

// defaults.global.defaultFontSize = "12";
defaults.global.defaultFontFamily = "Arial";
defaults.global.defaultFontColor = "#808080";
defaults.global.defaultFontWeight = "200";
defaults.global.defaultLetterSpacing = "2px";


class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiData: [
        {
          totalTrips: [],//1
          currentMonthTrips: [],//2
          currentMonthTrendByServiceType: [],//3 //current month trend graph
          totalTripsByCity: [],//4 //bycity graph
          totalTripsByClient: [],//5
          totalTripsBySegment: [],//6
          totalVendors: [],//91
          totalVehicles: [],//92
        },
      ],
      startDate: "",
      endDate: "",
      reportId: "",
      style: {},
      isData: false,
      userCcId: "",
      unique_cities: [],
      ccid: "",
      isCmtGraphData: false,
      isBarGraphByCityData: false,
      isTotalTripsByClientData: false,
      isTotalTripsBySegmentData: false,
    };
  }

  getData = async (reportId) => {
    // console.log(this.state.ccid);
    await axios.post(
      `${Config.hostName}/tms/get/data/dashboard/${reportId}/${this.state.ccid}`,
      {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
    )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          let data = this.state.apiData;

          if (reportId === 1) {
            data[0].totalTrips.push(res.data.data);
          }
          if (reportId === 2) {
            data[0].currentMonthTrips.push(res.data.data);
          }
          if (reportId === 3) {
            data[0].currentMonthTrendByServiceType.push(res.data.data);
            await this.setState({
              isCmtGraphData: true
            })
          }
          if (reportId === 4) {
            data[0].totalTripsByCity.push(res.data.data);
            await this.setState({
              isBarGraphByCityData: true
            })
          }
          if (reportId === 5) {
            data[0].totalTripsByClient.push(res.data.data);
            await this.setState({
              isTotalTripsByClientData: true
            })

          }
          if (reportId === 6) {
            data[0].totalTripsBySegment.push(res.data.data);
            await this.setState({
              isTotalTripsBySegmentData: true
            })
          }
          if (reportId === 91) {
            data[0].totalVendors.push(res.data.data);
          }
          if (reportId === 92) {
            data[0].totalVehicles.push(res.data.data);
          }
          await this.setState({
            apiData: data,
          });
        } else {
          await this.setState({
            apiData: [
              {
                totalTrips: [],//1
                currentMonthTrips: [],//2
                currentMonthTrendByServiceType: [],//3
                totalTripsByCity: [],//4
                totalTripsByClient: [],//5
                totalTripsBySegment: [],//6
                totalVendors: [],//91
                totalVehicles: [],//92
              }
            ]
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  getAllData = async () => {
    await this.getData(1);
    await this.getData(2);
    await this.getData(3);
    await this.getData(4);
    await this.getData(5);
    await this.getData(6);
    await this.getData(91);
    await this.getData(92);
    await this.setState({
      isData: true,
      style: {
        display: "none"
      }
    });
  };

  getUniqueCities = async () => {
    await axios
      .get(
        `${Config.hostName}/unique/locations`
      )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_cities: res.data.data,
          });
        } else {
          this.setState({
            unique_dates: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  componentWillMount = async () => {
    await this.setState({
      ccid: "11,2,28,26,3,15,5,42,33,30,7,17,32,9,40,34,1,36,43,16,24,39,37,31,12,13,35,38,22,14,19,25,41,27,44,23,10,8,18,21,29,4,6,20"
    });
    await this.getAllData();
    await this.getUniqueCities();

  };

  handleSearchShipment = async () => {
    await this.setState({
      apiData: [
        {
          totalTrips: [],//1
          currentMonthTrips: [],//2
          currentMonthTrendByServiceType: [],//3 //current month trend graph
          totalTripsByCity: [],//4 //bycity graph
          totalTripsByClient: [],//5
          totalTripsBySegment: [],//6
          totalVendors: [],//91
          totalVehicles: [],//92
        },
      ],
      isData: false,
      isCmtGraphData: false,
      isBarGraphByCityData: false,
      isTotalTripsByClientData: false,
      isTotalTripsBySegmentData: false,
    });
    await this.getAllData();
  };

  handleChangeCity = async (e) => {

    await this.setState({ userCcId: e.target.value, ccid: e.target.value });
  };

  render() {
    const { isData, style, isCmtGraphData, isBarGraphByCityData, isTotalTripsByClientData, isTotalTripsBySegmentData } = this.state;
    const {
      totalTrips,
      totalVendors,
      totalVehicles,
      currentMonthTrendByServiceType,
      totalTripsByCity,
      totalTripsByClient,
      totalTripsBySegment
    } = this.state.apiData[0];
    const propsData = {
      totalTrips,
      totalVendors,
      totalVehicles,
      isData,
      currentMonthTrendByServiceType
    };

    return (
      <div className="main-home-page" style={{ paddingBottom: "60px" }}>
        {/*Container-fluid starts*/}
        <div id="cover-spin" style={style}> </div>

        <div className="container-fluid">
          <div className="col-xl-12">

            <nav
              className="navbar navbar-light "
              style={{ backgroundColor: "none" }}
            >
              <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                <div className="col-sm-2">
                  <FormGroup>
                    <Input
                      type="select"
                      name="city"
                      value={this.state.ccid}
                      // style={{ borderColor: "#FF4848" }}
                      id="city"
                      onChange={this.handleChangeCity}
                    >
                      <option value={""} disabled>
                        Select Cities
                      </option>
                      
                      
                      <option value={this.state.unique_cities.map((eachItem) => 
                        eachItem.Locationid
                       )}>
                        Select All Cities
                      </option>

                      {this.state.unique_cities.map((eachItem) => (
                        <option value={eachItem.Locationid}>{eachItem.name}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>

                <div className="col-sm-2">
                  <FormGroup>
                    <DatePicker
                      style={{ width: "100% !important" }}
                      placeholderText="Select Start Date"
                      id="startDateInput"
                      selected={this.state.startDate}
                      onChange={(date) => {
                        this.setState({
                          startDate: date,
                        });
                      }}
                      dateFormat="yyyy-MM-dd"
                    />
                  </FormGroup>
                </div>

                <div className="col-sm-2">
                  <FormGroup>
                    <DatePicker
                      placeholderText="Select End Date"
                      selected={this.state.endDate}
                      onChange={(date) => {
                        this.setState({
                          endDate: date,
                        });
                      }}
                      dateFormat="yyyy-MM-dd"
                    />
                  </FormGroup>
                </div>

                {/* <div className="col-sm-2">
                  <FormGroup>
                    <Input
                      type="text"
                      name="timeline"
                      placeholder="Select Timeline"
                    // value={this.state.ccid}
                    // style={{ borderColor: "#FF4848" }}
                    // id="city"
                    // onChange={this.handleChangeCity}
                    ></Input>
                  </FormGroup>
                </div>
             */}
                <div className="col-sm-2">
                  <button
                    type="submit"
                    className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                    // style={{ backgroundColor: "#FF4848", color: "white" }}
                    onClick={this.handleSearchShipment}
                  >
                    Search
                  </button>
                </div>

              </div>
            </nav>
            {/* </div> */}
          </div>

          {this.state.isData && <GeneralWidget data={propsData} />}

          <div className="col-xl-12 col-lg-12 ">

            {isCmtGraphData &&
              <CmtGraph data={currentMonthTrendByServiceType} />
            }
          </div>

          <div className="col-xl-12 col-lg-12 ">
            {isBarGraphByCityData && <BarStackGraphByCity data={totalTripsByCity[0]} />}
          </div>

          <div className=" col-xl-12 col-lg-12">
            {isTotalTripsByClientData && <ByClientGraph data={totalTripsByClient[0]} />}
          </div>

          <div className=" col-xl-12 col-lg-12">
            {isTotalTripsBySegmentData && <BySegmentGraph data={totalTripsBySegment[0]} />}
          </div>

        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
