import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Table, Button, FormGroup, Form, Label, Col, Input } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import TextField from '@material-ui/core/TextField';

import moment from 'moment';
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../../common/Alert";
import { setAlert } from "../../../../../redux/actions/alert.actions";
import Config from "../../../../../config";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import "../../../../../assets/customCssFiles/loader-spinner.css"

class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            loading: false,
            style: {},
            TripID: "",
            TripDate: "",
            ServiceType: "",
            ClientName: "",
            Segment: "",
            RouteCode: "",
            ServiceHrs: "",
            AssignedLineno: "",
            SubTripNo: "",
            ODOStarturl: "",
            ODOEndurl: "",
            Firstname: "",

            IsVerified: "",

            //14 non editable
            //need to update
            //21 editable

            ChargeType: "",
            ReportingTime: "",
            InDateTime: "",
            OutDateTime: "",
            IsReplacement: "",
            IsSplittrip: "",

            Noload: "",
            Vendorid: "",
            // VendorName: "",
            // VehicleSize: "",
            // VehicleNumber: "",
            DriverName: "",

            DriverContact: "",
            ODOStart: "",
            ODOEnd: "",
            TotalKms: "",
            TotalTons: "",
            TollAmount: "",
            HaltingCharges: "",
            ShipmentCount: "",
            Remarks: "",
            AdvPay: "",
            AdvPayDate: "",
            DieselCost: "",

            unique_vendornames: [],
            unique_vehiclesizes: [],
            unique_vehiclenumbers: [],
            Locationid: "",
            VendorName: "",
            VehicleSize: "",
            VehicleNumber: "",

            City: ""

        };
        this.validator = new SimpleReactValidator();

    }

    getVendorNamesByLocationId = async () => {
        await axios
            .post(`${Config.hostName}/vms/get/data/vendors-by-location`,
                {
                    locationId: this.state.Locationid,
                    vendorName: "",
                    vehicleSize: ""
                }
            )
            .then(async res => {
                await this.setState({
                    unique_vendornames: res.data.data,
                    style: { display: 'none' }
                });
                await this.updateVendorNameInitially()
            })
            .catch(error => {
                console.log(error);
            });
    }

    updateVendorNameInitially = async () => {
        await axios
            .post(`${Config.hostName}/vms/get/data/vehicle-sizes`, {
                vendorName: this.state.VendorName,
                locationId: this.state.Locationid,
                vehicleSize: ""


            })
            .then(async res => {
                // console.log(res.data);
                await this.setState({
                    unique_vehiclesizes: res.data.data,
                    style: { display: 'none' }

                });
                await this.updateVehicleSizeInitially()
            })
            .catch(err => {
                console.log(err);
            });
    }

    updateVehicleSizeInitially = async () => {
        await axios
            .post(`${Config.hostName}/vms/get/data/vehicle-numbers-by-location`, {
                vehicleSize: this.state.VehicleSize,
                vendorName: this.state.VendorName,
                locationId: this.state.Locationid
            })
            .then(async res => {
                // console.log(res.data);
                await this.setState({
                    unique_vehiclenumbers: res.data.data,
                    style: { display: 'none' }

                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentWillMount = async (props) => {
        let id = Number(this.props.id)
        let result = await axios.get(
            `${Config.hostName}/tms/get/details/info/${id}`
        );
        // console.log(result.data);
        if (result.data.success) {
            let data = result.data.data[0];

            await this.setState({
                style: { display: 'none' },

                locations: data,
                ChargeType: data.ChargeType,
                ReportingTime: data.ReportingTime,
                InDateTime: data.InDateTime,
                OutDateTime: data.OutDateTime,
                IsReplacement: data.IsReplacement,
                IsSplittrip: data.IsSplittrip,
                Noload: data.Noload,
                Vendorid: data.Vendorid,
                VendorName: data.VendorName,
                VehicleSize: data.VehicleSize,
                VehicleNumber: data.VehicleNumber,
                DriverName: data.DriverName,
                DriverContact: data.DriverContact,
                ODOStart: data.ODOStart,
                ODOEnd: data.ODOEnd,
                TotalKms: data.TotalKms,
                TotalTons: data.TotalTons,
                TollAmount: data.TollAmount,
                HaltingCharges: data.HaltingCharges,
                ShipmentCount: data.ShipmentCount,
                Remarks: data.Remarks,
                AdvPay: data.AdvPay,
                AdvPayDate: data.AdvPayDate,
                DieselCost: data.DieselCost,
                Locationid: data.Locationid,
                City: data.City

            });
            await this.getVendorNamesByLocationId()
        }
        else {
            await this.setState({
                style: { display: 'none' },
            })

        }

    }

    changeVendorName = event => {
        this.setState({ unique_vehiclesizes: [], style: {} });
        event.preventDefault();
        this.setState({ VendorName: event.target.value });
        axios
            .post(`${Config.hostName}/vms/get/data/vehicle-sizes`, {
                vendorName: event.target.value,
                locationId: this.state.Locationid,
                vehicleSize: ""


            })
            .then(res => {
                // console.log(res.data);
                this.setState({
                    unique_vehiclesizes: res.data.data,
                    style: { display: 'none' }

                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    changeVehicleSize = event => {
        event.preventDefault();

        this.setState({ unique_vehiclenumbers: [], style: {} });
        this.setState({ VehicleSize: event.target.value });
        axios
            .post(`${Config.hostName}/vms/get/data/vehicle-numbers-by-location`, {
                vehicleSize: event.target.value,
                vendorName: this.state.VendorName,
                locationId: this.state.Locationid
            })
            .then(res => {
                // console.log(res.data);
                this.setState({
                    unique_vehiclenumbers: res.data.data,
                    style: { display: 'none' }

                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    onSubmit = async e => {
        e.preventDefault();

        let data = this.state.locations;


        if (this.validator.allValid()) {
            try {
                // eslint-disable-next-line
                await this.setState({ loading: true });
                await axios.post(`${Config.hostName}/tms/update/trip/trip-info/${data.TripID}`, {
                    chargeType: this.state.ChargeType,
                    reportingTime: moment(this.state.ReportingTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    inDateTime: moment(this.state.InDateTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    outDateTime: moment(this.state.OutDateTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    isReplacement: this.state.IsReplacement,
                    isSplitTrip: this.state.IsSplittrip,
                    noLoad: this.state.Noload,
                    vendorId: this.state.Vendorid,
                    vendorName: this.state.VendorName,
                    vehicleSize: this.state.VehicleSize,
                    vehicleNumber: this.state.VehicleNumber,
                    driverName: this.state.DriverName,
                    driverContact: this.state.DriverContact,
                    odoStart: this.state.ODOStart,
                    odoEnd: this.state.ODOEnd,
                    totalKms: this.state.TotalKms,
                    totalTons: this.state.TotalTons,
                    tollAmount: this.state.TollAmount,
                    haltingCharges: this.state.HaltingCharges,
                    shipmentCount: this.state.ShipmentCount,
                    remarks: this.state.Remarks,
                    AdvPay: this.state.AdvPay,
                    AdvPayDate: this.state.AdvPayDate,
                    DieselCost: this.state.DieselCost,
                })
                    .then(async (response) => {
                        // console.log(response.data, "response");
                        if (response.data.success === false) {
                            await this.setState({ loading: false });

                            this.props.setAlert(response.data.message, "danger");

                        }
                        else {
                            await this.setState({ loading: false });

                            this.props.setAlert("Trip Info Updated", "success");
                            await this.componentWillMount();

                        }

                    }
                    ).catch((error) => {
                        console.log(error, 'error');

                    }
                    )
            }
            catch (error) {
                const errors = error.response.this.state.errors;
                if (errors) {
                    errors.forEach(error => this.props.setAlert(error.msg, "danger"));
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        let item = this.state.locations;
        let { loading, style } = this.state;

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;

        return (
            <div>
                <div id='cover-spin' style={style}> </div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <div className="card" style={{ marginTop: "5vh" }}>
                                    {/* <div className="card height-equal"> */}
                                    <div className="card-header">
                                        <div className="row">
                                            <span className="col-sm-4">Trip&nbsp;ID : {item.TripID}</span>
                                            <span className="col-sm-4">Trip&nbsp;Date : {item.TripDate}</span>
                                            <span className="col-sm-4">Service&nbsp;Type : {item.ServiceType}</span>
                                            {/* <span className="col-sm-4">City : {
                                                item.Locationid === 1 ? "Hyderabad" :
                                                    item.Locationid === 2 ? "Bangalore"
                                                        : "Chennai"

                                            }</span> */}
                                            <span className="col-sm-4">City: {item.City}</span>
                                            <span className="col-sm-4"> Client&nbsp;Name : {item.ClientName}</span>
                                            <span className="col-sm-4">Segment : {item.Segment}</span>
                                            <span className="col-sm-4">Route&nbsp;Code : {item.RouteCode}</span>
                                            <span className="col-sm-4">Service&nbsp;Hrs : {item.ServiceHrs}</span>
                                            <span className="col-sm-4">Assigned&nbsp;Line&nbsp;No : {item.AssignedLineno}</span>
                                            {/* <span className="col-sm-4">Sub&nbsp;Trip&nbsp;No : {item.SubTripNo}</span> */}
                                            <span className="col-sm-4">Created&nbsp;By : {item.Firstname}</span>
                                            <span className="col-sm-4"> Charge&nbsp;Type : {item.ChargeType}</span>

                                        </div>
                                    </div>

                                    <div className="card-body">

                                        <AvForm >
                                            <div className="form-row">

                                                <div className="col-md-4">
                                                    <TextField
                                                        id="datetime-local"
                                                        label="Reporting Time"
                                                        type="datetime-local"
                                                        value={moment(this.state.ReportingTime).format("YYYY-MM-DDTHH:mm")}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                ReportingTime: e.target.value
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    {/* <AvField name="InDateTime"
                                                        label="In Date Time"
                                                        value={moment(this.state.InDateTime).format("YYYY-MM-DD hh:mm")}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                InDateTime: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="In Date Time"
                                                    /> */}
                                                    <TextField
                                                        id="datetime-local"
                                                        label="In Date Time"
                                                        type="datetime-local"
                                                        value={moment(this.state.InDateTime).format("YYYY-MM-DDTHH:mm")}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                InDateTime: e.target.value
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <TextField
                                                        id="datetime-local"
                                                        label="Out Date Time"
                                                        type="datetime-local"
                                                        value={moment(this.state.OutDateTime).format("YYYY-MM-DDTHH:mm")}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                OutDateTime: e.target.value
                                                            })
                                                        }}

                                                        InputLabelProps={{
                                                            shrink: true,

                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </div>
                                                <br />
                                                <div className="col-md-4">
                                                    <AvField name="ChargeType"
                                                        label="Charge Type"
                                                        value={this.state.ChargeType}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                ChargeType: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="Charge Type"
                                                    />
                                                </div>

                                                <div className="col-md-4">
                                                    <AvField name="IsReplacement"
                                                        label="Is Replacement"
                                                        value={this.state.IsReplacement}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                IsReplacement: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="Is Replacement"
                                                    />
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <AvField name="IsSplittrip"
                                                        label="Is Split Trip"
                                                        value={this.state.IsSplittrip}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                IsSplittrip: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="Is Splittrip"
                                                    />
                                                </div> */}
                                                <div className="col-md-4">
                                                    <AvField name="Noload"
                                                        label="No load"
                                                        value={this.state.Noload}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                Noload: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="No load"
                                                    />
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <AvField name="Vendorid"
                                                        label="Vendor ID"
                                                        value={this.state.Vendorid}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                Vendorid: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="Vendor id"
                                                    />
                                                </div> */}

                                                <div className="col-sm-4">
                                                    <FormGroup>
                                                        <Label>Vendor Name</Label>
                                                        <Input
                                                            type="select"
                                                            name="VendorName"
                                                            value={this.state.VendorName}
                                                            id="VendorName"
                                                            onChange={this.changeVendorName}
                                                        >
                                                            <option value={""} disabled selected  >Select Vendor Name</option>

                                                            {this.state.unique_vendornames.map(VendorName => (
                                                                <option value={VendorName.TransportName}>
                                                                    {VendorName.TransportName}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-sm-4">
                                                    <FormGroup>
                                                        <Label>Vehicle Size</Label>
                                                        <Input
                                                            type="select"
                                                            name="VehicleSize"
                                                            value={this.state.VehicleSize}
                                                            id="VehicleSize"
                                                            onChange={this.changeVehicleSize}
                                                        >
                                                            <option value={""} disabled selected >Select Vehicle SIze</option>

                                                            {this.state.unique_vehiclesizes.map(VehicleSize => (
                                                                <option value={VehicleSize.VehicleSize}>
                                                                    {VehicleSize.VehicleSize}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-sm-4">
                                                    <FormGroup>
                                                        <Label>Vehicle Number</Label>
                                                        <Input
                                                            type="select"
                                                            name="VehicleNumber"
                                                            value={this.state.VehicleNumber}
                                                            id="VehicleNumber"
                                                            onChange={event => {
                                                                this.setState({
                                                                    VehicleNumber: event.target.value
                                                                });
                                                            }}
                                                        >
                                                            <option value={""} disabled selected >Select Vehicle Number</option>

                                                            {this.state.unique_vehiclenumbers.map(
                                                                VehicleNumber => (
                                                                    <option value={VehicleNumber.VehicleNumber}>
                                                                        {VehicleNumber.VehicleNumber}
                                                                    </option>
                                                                )
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                {/* <div className="col-md-4">
                                                    <AvField name="DriverName"
                                                        label="Driver Name"
                                                        value={this.state.DriverName}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                DriverName: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="Driver Name"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <AvField name="DriverContact"
                                                        label="Driver Contact"
                                                        value={this.state.DriverContact}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                DriverContact: e.target.value
                                                            })

                                                        }}
                                                        type="text"
                                                        placeholder="DriverContact"
                                                    />
                                                </div> */}
                                                <div className="col-md-4">
                                                    <AvField name="ODOStart"
                                                        label="ODO Start"
                                                        value={this.state.ODOStart}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                ODOStart: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="ODOStart"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <AvField name="ODOEnd"
                                                        label="ODO End"
                                                        value={this.state.ODOEnd}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                ODOEnd: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="ODOEnd"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <AvField name="TotalKms"
                                                        label="Total Kms"
                                                        value={this.state.TotalKms}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                TotalKms: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="TotalKms"
                                                    />
                                                </div>


                                                <div className="col-md-4">
                                                    <AvField name="DieselCost"
                                                        label="Diesel Cost"
                                                        value={this.state.DieselCost}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                DieselCost: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="DieselCost"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <AvField name="AdvPay"
                                                        label="Adv Pay"
                                                        value={this.state.AdvPay}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                AdvPay: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="AdvPay"
                                                    />
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <AvField name="AdvPayDate"
                                                        label="AdvPay Date"
                                                        value={this.state.AdvPayDate}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                AdvPayDate: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="AdvPayDate"
                                                    />
                                                </div> */}
                                                <div className="col-md-4">
                                                    <TextField
                                                        id="datetime-local"
                                                        label="Adv Pay Date"
                                                        type="datetime-local"
                                                        value={moment(this.state.AdvPayDate).format("YYYY-MM-DDTHH:mm")}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                AdvPayDate: e.target.value
                                                            })
                                                        }}

                                                        InputLabelProps={{
                                                            shrink: true,

                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </div>
                                                <br />
                                                <div className="col-md-4">
                                                    <AvField name="TotalTons"
                                                        label="Total Tons"
                                                        value={this.state.TotalTons}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                TotalTons: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="TotalTons"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <AvField name="TollAmount"
                                                        label="Toll Amount"
                                                        value={this.state.TollAmount}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                TollAmount: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="TollAmount"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <AvField name="HaltingCharges"
                                                        label="Halting Charges"
                                                        value={this.state.HaltingCharges}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                HaltingCharges: e.target.value
                                                            })

                                                        }}
                                                        type="text"
                                                        placeholder="Halting Charges"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <AvField name="ShipmentCount"
                                                        label="Shipment Count"
                                                        value={this.state.ShipmentCount}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                ShipmentCount: e.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        placeholder="Shipment Count"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <AvField
                                                        name="Remarks"
                                                        label="Remarks"
                                                        value={this.state.Remarks}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                Remarks: e.target.value
                                                            })
                                                        }}
                                                        type="textarea"
                                                        placeholder="Remarks"
                                                    />
                                                </div>
                                                <button
                                                    type="submit"
                                                    class="btn btn-outline-primary btn-lg"
                                                    style={{ float: "right" }}
                                                    onClick={e => this.onSubmit(e)}
                                                    disabled={loading}

                                                >
                                                    {loading && (
                                                        <i
                                                            className="fa fa-refresh fa-spin"
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                    )}
                                                    {loading && <span>Processing ...</span>}
                                                    {!loading && <span>Update</span>}

                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);