/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/*global google*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import Config from "../../../../config";
import { withGoogleMap, GoogleMap, DirectionsRenderer, Marker, InfoWindow } from "react-google-maps";
import { Button } from "@material-ui/core";
// import { DeleteIcon } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';
import "../../../../assets/customCssFiles/loader-spinner.css"

class Map extends Component {
    constructor(props) {
        super(props)
        this.state = {
            directions: [],
            routes: [],
            anotherRoutes: [],
            drivers: [],
            style: {},
            selectedPlace: "",

            inilat: "",
            inilng: "",
            routeno: 0,
            showingInfoWindow: false,
            activeMarker: {},

            alert: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            alert_message: "",
            isRouteMarkerVisible: true,
            isDriverMarkerVisible: true,
            isRouteLineVisible: true,
            isPlayAnimation: false,

            dLat: "",
            dLng: "",
            position: {},
            driverName: "Driver",
            data: [],

            driverPosition: {},
            driverData: [],

            checkedArray: [],
            uniqueRouteNosMain: [],

            isCheckAll: false
        }
    };


    driverTrack = async (data) => {
        await this.state.routes.map(async (route, index) => {
            const driverData = route.driverData;
            await this.setState({
                driverData: [...this.state.driverData, driverData]
            })

        })
        // console.log(this.state.driverData, "driverData");

    }

    displayShipments = async () => {
        // console.log(this.state.routes);

        await this.state.routes.map((route, index) => {
            const waypts = route.waypoints;
            const directionsService = new google.maps.DirectionsService();
            const origin = { lat: route.origin.lat, lng: route.origin.lng };
            const color = route.colors;
            const data = route.data;
            return directionsService.route(
                {
                    origin: origin,
                    destination: origin,
                    // optimizeWaypoints: true,
                    waypoints: waypts,
                    travelMode: google.maps.TravelMode.DRIVING,
                    unitSystem: google.maps.UnitSystem.METRIC
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        let res = [];
                        // console.log(result, "result");

                        res.push(result, color, data)
                        this.setState({
                            directions: [...this.state.directions, res],
                        });

                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        })

    }

    dataFunc = (data) => {
        let routes = [];
        // console.log(data);
        if (data) {

            this.setState({
                inilat: (data.length > 0) ? Number(data[0].lat) : Number("17.3454"),
                inilng: (data.length > 0) ? Number(data[0].long) : Number("78.3454"),
                position: {
                    lat: (data.length > 0) ? Number(data[0].lat) : Number("17.3454"),
                    lng: (data.length > 0) ? Number(data[0].long) : Number("78.3454"),
                }
            })


            data.forEach((eachLocation) => {
                let cond = routes.findIndex(item => item.VehicleID === eachLocation.VehicleID)

                if (cond === -1) {
                    if (eachLocation.StopOrder === 0) {
                        routes.push({
                            VehicleID: eachLocation.VehicleID,
                            checkedObj: {
                                VehicleID: eachLocation.VehicleID,
                                isChecked: false
                            },
                            origin: {
                                lat: Number(parseFloat(eachLocation.lat)),
                                lng: Number(parseFloat(eachLocation.long))
                            },
                            waypoints: [],
                            colors:
                                `
                                rgb(
                                    ${Math.floor(Math.random() * 255)},
                                    ${Math.floor(Math.random() * 255)},
                                    ${Math.floor(Math.random() * 255)}
                                    )
                                `,
                            data: {
                                StopOrder: eachLocation.StopOrder,
                                StopName: eachLocation.StopName,
                                trackingnumber: eachLocation.trackingnumber,
                                shipmentDate: eachLocation.shipmentDate,
                                ID: eachLocation.ID,
                                status: eachLocation.Deliverstatus
                            },
                            driverData: {

                                DriverContact: eachLocation.DriverContact,
                                DriverLat: eachLocation.DriverLat,
                                Driverlong: eachLocation.Driverlong,
                                DriverName: eachLocation.DriverName
                            }
                        });
                        let array = this.state.checkedArray;
                        array.push({
                            checkedObj: {
                                VehicleID: eachLocation.VehicleID,
                                isChecked: false
                            },
                        });
                        this.setState({
                            checkedArray: array
                        });
                    }
                    else {
                        routes.push({
                            VehicleID: eachLocation.VehicleID,
                            checkedObj: {
                                VehicleID: eachLocation.VehicleID,
                                isChecked: false
                            },
                            origin: {},
                            waypoints: [{
                                location: {
                                    lat: (parseFloat(eachLocation.lat)),
                                    lng: (parseFloat(eachLocation.long))
                                }
                            }],
                            colors:
                                `rgb(
                                    ${Math.floor(Math.random() * 255)},
                                    ${Math.floor(Math.random() * 255)},
                                    ${Math.floor(Math.random() * 255)}
                                    )`,
                            data: {
                                StopOrder: eachLocation.StopOrder,
                                StopName: eachLocation.StopName,
                                trackingnumber: eachLocation.trackingnumber,
                                shipmentDate: eachLocation.shipmentDate,
                                ID: eachLocation.ID,
                                status: eachLocation.Deliverstatus

                            },
                            driverData: {

                                DriverContact: eachLocation.DriverContact,
                                DriverLat: eachLocation.DriverLat,
                                Driverlong: eachLocation.Driverlong,
                                DriverName: eachLocation.DriverName
                            }
                        });
                        let array = this.state.checkedArray;
                        array.push({
                            checkedObj: {
                                VehicleID: eachLocation.VehicleID,
                                isChecked: false
                            },
                        });
                        this.setState({
                            checkedArray: array
                        });

                    }
                }
                else {
                    if (eachLocation.StopOrder === 0) {
                        routes[cond].origin = {
                            lat: Number(parseFloat(eachLocation.lat)),
                            lng: Number(parseFloat(eachLocation.long))
                        }
                        routes[cond].data = {
                            StopOrder: eachLocation.StopOrder,
                            StopName: eachLocation.StopName,
                            trackingnumber: eachLocation.trackingnumber,
                            shipmentDate: eachLocation.shipmentDate,
                            ID: eachLocation.ID,
                            status: eachLocation.Deliverstatus
                        }
                        routes[cond].driverData = {

                            DriverContact: eachLocation.DriverContact,
                            DriverLat: eachLocation.DriverLat,
                            Driverlong: eachLocation.Driverlong,
                            DriverName: eachLocation.DriverName
                        }
                    }
                    else {
                        routes[cond].waypoints.push({
                            location: {
                                lat: (parseFloat(eachLocation.lat)),
                                lng: (parseFloat(eachLocation.long))
                            }

                        })
                        routes[cond].data = {
                            // data: {
                            StopOrder: eachLocation.StopOrder,
                            StopName: eachLocation.StopName,
                            trackingnumber: eachLocation.trackingnumber,
                            shipmentDate: eachLocation.shipmentDate,
                            ID: eachLocation.ID,
                            status: eachLocation.Deliverstatus

                            // }

                        }

                        routes[cond].driverData = {
                            // driverData: {
                            DriverContact: eachLocation.DriverContact,
                            DriverLat: eachLocation.DriverLat,
                            Driverlong: eachLocation.Driverlong,
                            DriverName: eachLocation.DriverName
                            // }

                        }
                    }
                }
            })

            this.setState({
                routes: routes
            })
            // console.log(this.state.routes);
        }

        else {
            this.setState({
                inilat: Number(17.3417977),
                inilng: Number(78.5784007),
                position: {
                    lat: Number(17.3417977),
                    lng: Number(78.5784007),
                }
            })

        }
    }

    apiCall = async (id, route) => {
        // console.log(route);

        await this.setState({
            style: {}
        })
        let royaloak = String(this.props.auth.user.CCID) + ",499";
        let paramsValue = (this.props.auth.user.CCID == 4) ? royaloak : this.props.auth.user.CCID;
        // console.log(paramsValue);

        await axios.post(`${Config.hostName}/shipment/tracking/route/data/${id}/${paramsValue}`,
            {
                vehicles: route
            })
            .then(async (res) => {
                await this.setState({
                    data: res.data.data
                })
            }).catch((error) => {
                console.log(error)
            });
    }

    getShipmentsForRoute = async () => {
        await this.apiCall(1, "k");
    }

    getShipmentsAnimation = async () => {
        await this.apiCall(0, "k");
    }

    componentDidMount = async () => {
        await this.setState({
            checkedArray: [],
            uniqueRouteNosMain: [],
            showingInfoWindow: false,
            activeMarker: null,
            style: {}
        })
        // console.log(this.props.auth.user);

        await this.displayRoutesNormal();
        let uniqueRouteNosBegin = this.state.checkedArray;
        await this.setState({
            uniqueRouteNosMain: uniqueRouteNosBegin
        })
        await this.setState({
            style: { display: 'none' }
        })
    }

    displayRoutesNormal = async () => {
        await this.setState({ directions: [], driverData: [] })
        await this.getShipmentsForRoute();
        await this.dataFunc(this.state.data);

        await this.displayShipments();
        await this.driverTrack(this.state.driverData);

    }

    displayAnimation = async () => {
        this.interval = setInterval(async () => {
            await this.setState({ directions: [], driverData: [] })
            await this.getShipmentsAnimation();
            await this.dataFunc(this.state.data);
            await this.displayShipments();
            await this.driverTrack();
            await this.setState({
                style: { display: 'none' }
            })
        }, 15000)


    }

    playTheAnimation = async () => {
        try {
            await this.setState({ isPlayAnimation: !this.state.isPlayAnimation })
            if (this.state.isPlayAnimation) {
                return this.displayAnimation();
            } else {
                clearInterval(this.interval)
                return this.displayRoutesNormal();
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    onMarkerClick = async (driver) => {
        // console.log(driver, "props");

        await this.setState({
            position: {
                lat: Number(driver.DriverLat),
                lng: Number(driver.Driverlong)
            },
            showingInfoWindow: true,
            driverName: driver.DriverName

        });
        // console.log(this.state.showingInfoWindow);
    }

    onMapClicked = async props => {
        // console.log(this.state.showingInfoWindow);

        if (this.state.showingInfoWindow) {
            await this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    route = async (e) => {
        let value = e.target.value
        // console.log(value);
        let newRouteArray = this.state.uniqueRouteNosMain;
        // eslint-disable-next-line eqeqeq
        if (value == "all") {
            await this.setState({
                isCheckAll: !this.state.isCheckAll
            })
            newRouteArray.filter(arr => {
                return arr.checkedObj.isChecked = this.state.isCheckAll
            })
            await this.setState({
                uniqueRouteNosMain: newRouteArray
            })
            // console.log(this.state.uniqueRouteNosMain);

        }
        else {
            // eslint-disable-next-line array-callback-return
            newRouteArray.filter(arr => {
                // eslint-disable-next-line eqeqeq
                if (arr.checkedObj.VehicleID == value) {
                    let checkVal = arr.checkedObj.isChecked
                    return arr.checkedObj.isChecked = !checkVal
                }
            })
            await this.setState({
                uniqueRouteNosMain: newRouteArray
            })
            // console.log(this.state.uniqueRouteNosMain);

        }
    }

    searchByRouteCheckBox = async () => {
        let newRouteArray = this.state.uniqueRouteNosMain;
        let VehicleIDsArray = "";
        let aop;
        // eslint-disable-next-line array-callback-return
        newRouteArray.filter(arr => {
            if (arr.checkedObj.isChecked) {
                let num = arr.checkedObj.VehicleID
                VehicleIDsArray = VehicleIDsArray + num + ","
            }
            aop = VehicleIDsArray.toString()
        })
        let str = aop.replace(/,\s*$/, "");

        // console.log(str, "ohio");
        // console.log(this.state.checkedArray, this.state.uniqueRouteNosMain);

        await this.setState({ directions: [], driverData: [], checkedArray: [] })
        await this.apiCall(1, str)
        await this.dataFunc(this.state.data);
        await this.displayShipments();
        await this.driverTrack(this.state.driverData);
        await this.setState({
            style: { display: 'none' }
        })
        // console.log(this.state.checkedArray, this.state.uniqueRouteNosMain);

    }


    render() {
        let { style } = this.state;
        const GoogleMapExample = withGoogleMap(props => (
            <div>

                <GoogleMap
                    google={this.props.google}

                    // onMouseout={this.onMapClicked}
                    onClick={this.onMapClicked}
                    style={{
                        width: "100%",
                        height: "600px",
                        position: "absolute"
                    }}
                    className={"map"}
                    initialCenter={{
                        lat: Number(this.state.inilat),
                        lng: Number(this.state.inilng)
                    }}
                    center={{
                        lat: Number(this.state.inilat),
                        lng: Number(this.state.inilng)
                    }}
                    zoom={8}
                >
                    {/* {console.log(this.state.directions, "final")} */}
                    {(this.state.directions) &&
                        this.state.directions.map((item, index) => {

                            let status = item[2].status;
                            let icon1 = `http://maps.google.com/mapfiles/kml/pal2/icon18.png`;
                            let icon2 = require('../../../../assets/customImages/icon-green-light.png');
                            let icon4 = require('../../../../assets/customImages/icon-red-light.png');
                            let icon3 = require('../../../../assets/customImages/icon-orange-out.png');
                            return <DirectionsRenderer
                                key={index}
                                directions={item[0]}
                                options={{
                                    polylineOptions: {
                                        strokeOpacity: 1,
                                        strokeWeight: 4,
                                        strokeColor: item[1],
                                        visible: this.state.isRouteLineVisible
                                    },
                                    markerOptions: {
                                        title: String(item[2].StopOrder),
                                        // label: String(item[2].data.StopOrder),
                                        labelOrigin: { x: 10, y: 50 },
                                        anchorPoint: new google.maps.Point(32, 65),
                                        visible: this.state.isRouteMarkerVisible,
                                        icon: {
                                            url: (status === 0) ? icon1 :
                                                (status === 1) ? icon2 :
                                                    (status === 2) ? icon3 :
                                                        icon4
                                            ,
                                            scaledSize: new google.maps.Size(17, 17),
                                        }
                                    }
                                }}
                            />
                        })
                    }

                    {this.state.driverData.map((driver, index) => {
                        // console.log(driver, index);
                        return (
                            <Fragment>
                                <Marker
                                    animation={google.maps.Animation.DROP}
                                    icon={{
                                        url: require('../../../../assets/customImages/truck_tracking_@1x.png'),
                                        scaledSize: new google.maps.Size(50, 50)

                                    }}
                                    visible={this.state.isDriverMarkerVisible}
                                    // style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}
                                    key={index}
                                    position={{ lat: Number(driver.DriverLat), lng: Number(driver.Driverlong) }}
                                    // name="My Marker"
                                    name={
                                        <div>
                                            <p>
                                                Name :{" "}
                                                {driver.DriverName}
                                                <br />
                                                Contact : {driver.DriverContact}
                                            </p>
                                        </div>
                                    }

                                    // shape={google.maps.SymbolPath.CIRCLE}
                                    // shape={google.maps.MarkerShape}
                                    // path={new google.maps.SymbolPath.FORWARD_OPEN_ARROW}
                                    // title={driver.DriverName}
                                    onClick={() => this.onMarkerClick(driver)}
                                    labelOrigin={{ x: 10, y: 50 }}
                                    anchorPoint={new google.maps.Point(32, 65)}
                                    label={{
                                        text: `${driver.DriverName}`,
                                        fontFamily: "Arial",
                                        fontSize: "20px",
                                        color: "black"
                                    }}
                                >

                                </Marker>
                            </Fragment>
                        )
                    })}

                    {this.state.showingInfoWindow &&
                        <InfoWindow
                            position={this.state.position}
                            visible={this.state.showingInfoWindow}>
                            <div> <span>{this.state.driverName}</span></div>
                        </InfoWindow>
                    }

                </GoogleMap>


            </div>
        ));

        return (
            <div>

                <div id='cover-spin' style={style}>
                </div>

                <div className="row ">
                    <div className="col-xl-12">
                        {/* <div className="card"> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12" style={{ display: "flex" }}>

                                    <div className="col-sm-3" style={{ flex: 1 }}>
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customSwitch1"
                                                value={this.state.isRouteMarkerVisible}
                                                onChange={(e) => {
                                                    this.setState({
                                                        isRouteMarkerVisible: !this.state.isRouteMarkerVisible,
                                                        showingInfoWindow: false,
                                                        activeMarker: null

                                                    })
                                                }}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customSwitch1">
                                                Hide Shipments
                                                </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-3" style={{ flex: 1 }}>
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customSwitch2"
                                                value={this.state.isDriverMarkerVisible}
                                                onChange={(e) => {
                                                    this.setState({
                                                        isDriverMarkerVisible: !this.state.isDriverMarkerVisible,
                                                        showingInfoWindow: false,
                                                        activeMarker: null
                                                    })
                                                }}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customSwitch2">
                                                Hide Drivers
                                                </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-3" style={{ flex: 1 }}>
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customSwitch3"
                                                value={this.state.isRouteLineVisible}
                                                onChange={(e) => {
                                                    this.setState({
                                                        isRouteLineVisible: !this.state.isRouteLineVisible,
                                                        showingInfoWindow: false,
                                                        activeMarker: null
                                                    })
                                                }}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customSwitch3">
                                                Hide Routes
                                                </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-3" style={{ flex: 1 }}>
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customSwitch4"
                                                value={this.state.isPlayAnimation}
                                                onChange={(e) => {
                                                    this.playTheAnimation()
                                                }
                                                }
                                            // onClick={this.playTheAnimation()}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customSwitch4">
                                                Show By Routes
                                                </label>
                                        </div>
                                    </div>

                                </div>
                                <br />
                                <br />

                                <div className=" col-sm-12" style={{ display: "flex", width: "100%" }}>
                                    <div style={{ flex: 4, display: "flex", overflowX: "scroll", width: "100%", marginTop: "1vh" }}>
                                        {this.state.uniqueRouteNosMain.sort().map(route => {
                                            // console.log(route.checkedObj.VehicleID, 'ohio')
                                            return (
                                                <div className="col-sm-1" style={{ flex: 1, whiteSpace: "nowrap" }}>
                                                    <div className="form-check">
                                                        <input className="form-check-input form-control-label-lg"
                                                            type="checkbox"
                                                            checked={route.checkedObj.isChecked}
                                                            value={route.checkedObj.VehicleID}
                                                            onClick={this.route}
                                                        />
                                                        <label className="form-check-label" for={route.checkedObj.VehicleID} >
                                                            {route.checkedObj.VehicleID}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="col-sm-1" style={{ flex: 1, whiteSpace: "nowrap" }}>
                                            <div className="form-check">
                                                <input className="form-check-input form-control-label-lg"
                                                    type="checkbox" value="all" onClick={this.route}
                                                    checked={this.state.isCheckAll}
                                                />
                                                <label className="form-check-label" for="all" >
                                                    All
                                                        </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2" style={{ flex: 1 }}>
                                        {/* <Button aria-label="delete" color="primary" onClick={this.searchByRouteCheckBox}>
                                            search
                                            <SearchIcon />
                                        </Button> */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            // className={classes.button}
                                            startIcon={<SearchIcon />}
                                            onClick={this.searchByRouteCheckBox}
                                        >
                                            Search
      </Button>
                                        {/* <button type="button" className="badge badge-pill badge-info" onClick={this.searchByRouteCheckBox}>Search</button> */}
                                    </div>

                                </div>



                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>

                <GoogleMapExample
                    containerElement={<div style={{ height: `75vh`, width: "100%" }} />}
                    mapElement={<div style={{ height: `75vh` }} />}
                    loadingElement={<div style={{ height: `75vh` }} />}
                />
            </div>
        );
    }
}

export default Map;
