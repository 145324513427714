/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import Tab from '@material-ui/core/Tab';
import PaymentIcon from '@material-ui/icons/Payment';
import AirportShuttleOutlinedIcon from '@material-ui/icons/AirportShuttleOutlined';
import Tabs from '@material-ui/core/Tabs';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import TripInfo from './ShowMore/TripInfo';
import TripPayment from './ShowMore/TripPayment';
import TripPricing from './ShowMore/TripPricing';
import Approve from './ShowMore/Approve';
import { FormGroup, Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Config from "../../../../config";
import axios from "axios";
import "../../../../assets/customCssFiles/loader-spinner.css"


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3} paddingBottom={0}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


function LinkTab(props) {
    return (
        <Tab
            icon="i"
            label="TRIP"
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}


export default class ShowMoreTripDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            TripID: '',
            style: {},
            ID: this.props.match.params.TripID,
            IsVerified: false,
            newId: this.props.match.params.TripID
        }
    }



    componentDidMount = async () => {
        await this.hi();
    }


    hi = async () => {
        console.log(this.state.ID, 'ID');
        await this.setState({
            value: ""
        })
        let { ID } = this.state;
        await this.setState({
            TripID: ID,
            value: 0,
        })
        await axios.get(
            `${Config.hostName}/tms/get/details/info/${this.state.TripID}`).then(async (result) => {
                if (result.data.success) {
                    await this.setState({
                        IsVerified: result.data.data[0].IsVerified,
                        style: { display: 'none' }
                    });
                }
                else {
                    await this.setState({
                        style: { display: 'none' },
                        TripID: this.state.newId
                    });
                    await this.forceUpdate();
                    alert(result.data.message)
                }
            }).catch(err => {
                alert(err);
                console.log(err);
            })

    }


    handleSearchShipment = async (event) => {
        event.preventDefault()
        await this.setState({
            style: {},
            value: "",
        })
        try {
            let id = this.state.ID;
            await this.setState({
                TripID: id,
                value: 0,
                style: { display: 'none' }
            })
            this.forceUpdate();
        } catch (e) {
            console.log(e);

        }

    }


    render() {
        let { style, value, IsVerified } = this.state;



        return (
            <Fragment>
                <div id='cover-spin' style={style}></div>

                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                {/* <Label className="col-sm-3">TripID</Label> */}
                                                <Input
                                                    // className="col-sm-3"
                                                    type="number"
                                                    placeholder="TripID"
                                                    name="TripID"
                                                    value={this.state.ID}
                                                    id="TripID"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            ID: e.target.value
                                                        })
                                                    }}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={e => this.hi(e)}
                                            >
                                                Search
                                            </button>
                                        </div>

                                    </div>
                                </nav>

                                <div className="card-body">

                                    {/* <AppBar style={{ height: "0" }} position="d"> */}
                                    <Tabs
                                        variant="fullWidth"
                                        value={value}
                                        key={value}
                                        onChange={
                                            (event, value) => {
                                                this.setState({
                                                    value: value
                                                })
                                            }
                                        }
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        aria-label="icon label tabs example"
                                    >
                                        <LinkTab icon={<AirportShuttleOutlinedIcon />} label="TRIP" href="/drafts" {...a11yProps(0)} />
                                        <LinkTab icon={<i className="icofont icofont-cur-rupee" ></i>} label="PRICING" href="/trash" {...a11yProps(1)} />
                                        <LinkTab icon={<PaymentIcon />} label="PAYMENT" href="/spam" {...a11yProps(2)} />
                                        <LinkTab icon={<CheckCircleOutlineOutlinedIcon />} label="APPROVE" href="/span" {...a11yProps(3)} />

                                    </Tabs>
                                    {/* </AppBar> */}
                                    <TabPanel value={value} index={0} >
                                        <TripInfo id={this.state.TripID} />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <TripPricing id={this.state.TripID} />
                                    </TabPanel>
                                    <TabPanel value={value} index={2}  >
                                        <TripPayment id={this.state.TripID} />
                                    </TabPanel>
                                    <TabPanel value={value} index={3}  >
                                        <Approve id={this.state.TripID} />
                                    </TabPanel>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

}
