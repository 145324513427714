import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "../app.css";
import App from "../components/app";

import Error404 from "../components/pages/error-404";
import ResetPassword from "../components/pages/reset-password";
import AdminLogin from "../components/pages/admin-login";
import Dashboard from "../components/pages/dashboard";
import LandingPage from "../components/pages/landing";
import TableView from "../components/admin-pages/FTSD/TableView/TableView";
import UploadFileRO from "../components/admin-pages/FTSD/UploadFileRO";
import ViewMoreTableView from "../components/admin-pages/FTSD/TableView/ViewMore";
import PostalCodeForm from "../components/admin-pages/FTSD/TableView/PostalCodeForm";
import MapView from "../components/admin-pages/FTSD/MapView/MapViewUi";
import RouteMapping from "../components/admin-pages/FTSD/RouteMapping/RouteMapping";
import RouteUpdateForm from "../components/admin-pages/FTSD/RouteMapping/RouteUpdateForm";
import RouteAddForm from "../components/admin-pages/FTSD/RouteMapping/RouteAddForm";
import DriverTracking from "../components/admin-pages/FTSD/Tracking/DriverTracking";
import ShipmentTracking from "../components/admin-pages/FTSD/Tracking/ShipmentTracking";
import RouteCode from "../components/admin-pages/TMS/RouteCode/RouteCode";
import DriverDetails from "../components/admin-pages/TMS/DriverDetails/TripMaster";
// import FuelIntent from "../components/admin-pages/TMS/FuelIntent/FuelIntent";
import TripMaster from "../components/admin-pages/TMS/TripMaster/TripMaster";
import ShowMoreTripDetails from "../components/admin-pages/TMS/TripMaster/ShowMoreTripDetails";
import Vendors from "../components/admin-pages/VMS/Vendors/Vendors";
import Vehicles from "../components/admin-pages/VMS/Vehicles/Vehicles";
import AddRouteForm from "../components/admin-pages/TMS/RouteCode/AddRouteForm";
import CreateAdhocTrip from "../components/admin-pages/TMS/RouteCode/CreateAdhocTrip";
import AddVendorForm from "../components/admin-pages/VMS/Vendors/AddVendorForm";
import EditVendorForm from "../components/admin-pages/VMS/Vendors/EditVendorForm";
import ShowVendorForm from "../components/admin-pages/VMS/Vendors/ShowVendorForm";
import AddVehicleForm from "../components/admin-pages/VMS/Vehicles/AddVehicleForm";
import ShowVehicles from "../components/admin-pages/VMS/Vehicles/ShowVehicles";
import MapAttendance from "../components/admin-pages/EMS/MapAttendance";

//Employee management
import RegisterEmployee from "../components/admin-pages/EMS/Employee/RegisterEmployee";
import ManageNewRegistration from "../components/admin-pages/EMS/ManageNewRegistration/ManageNewRegistration";
import ManagerView from "../components/admin-pages/EMS/ManagerView/ManagerView";
import ReportingTeam from "../components/admin-pages/EMS/ManagerView/ReportingTeam";

import AttendanceDashboard from "../components/admin-pages/EMS/Attendance/Dashboard/HomePage";

import TableAttendance from "../components/admin-pages/EMS/TableAttendance";
import PrivateRoute from "./PrivateRoute";

import HomePageDashBoard from "../components/admin-pages/FTSD/DashBoard/HomePage";
import TmsDashBoard from "../components/admin-pages/TMS/DashBoard/HomePage";

import TableViewCms from "../components/admin-pages/CMS/TableViewCms";
import ShipmentTrackingCms from "../components/admin-pages/CMS/ShipmentTracking";

import VehicleSeggSorting from "../components/admin-pages/FTSD/VehicleSeggregation/Sorting";
import VehicleSeggRouting from "../components/admin-pages/FTSD/VehicleSeggregation/Routing";

import DriverTrackingFake from "../components/admin-pages/FTSD/Tracking/DriverTrackingFake";

import InvoiceTableView from "../components/admin-pages/FTSD/RODashboard/InvoiceTableView";
import TmsNewUi from "../components/admin-pages/TMS_NEW/TmsNewUi";
import TmsClients from "../components/admin-pages/TMS_NEW/Components/Clients";

//BMS
import BMSDashboard from "../components/admin-pages/BMS/Dashboard/HomePage";
import BMSPayee from "../components/admin-pages/BMS/Payee/HomePage";
import PMSDashboard from "../components/admin-pages/BMS/Dashboard/PmsHomePage";
import PmsPayeePrimaryBanks from "../components/admin-pages/BMS/Dashboard/PmsPayeePrimaryBanks";
import ShowBanksPms from "../components/admin-pages/BMS/Dashboard/ShowBanksPms";
import BMSRequestsPage from "../components/admin-pages/BMS/CreateRequest/HomePage";
import BMSCreateNewRequest from "../components/admin-pages/BMS/CreateRequest/CreateNewRequest";
import BMSEditRequest from "../components/admin-pages/BMS/CreateRequest/EditRequest";

import BMSManagerView from "../components/admin-pages/BMS/CreateRequest/ManagerView";
import BMSTripMasterView from "../components/admin-pages/BMS/TripMaster/TripMaster";

import GpsTracking from "../components/admin-pages/FTSD/GpsTracking/GpsTracking";
// import GpsTracking from "../components/admin-pages/GPS_TRACKING/GpsTracking";

import TripCountHomePage from "../components/admin-pages/TMS/TripCount/HomePage";
import TripCountViewDetailsPage from "../components/admin-pages/TMS/TripCount/ViewDetailsTripCount";

//HR
import HRDashboard from "../components/admin-pages/HR/Dashboard/HomePage";
import HRManagerView from "../components/admin-pages/HR/ManagerView/HomePage";

//Regular Trip
import RegularTrip from "../components/admin-pages/TMS/ManageRegularTrip/HomePage";
import FuelIntent from "../components/admin-pages/TMS/FuelIntent/FuelIntent";

//Billing
import ClientRegistration from "../components/admin-pages/Billing/ClientRegistration/HomePage";
import ClientManagement from "../components/admin-pages/Billing/ClientManagement/HomePage";
import Invoice from "../components/admin-pages/Billing/Invoice/HomePage";
import UploadMIS from "../components/admin-pages/Billing/UploadMIS/HomePage";
import GeoFencing from "../components/admin-pages/GeoFencing/Dashboard/HomePage";
import EmployeeStats from "../components/admin-pages/EMS/EmployeeStats/EmployeeStats";
import BillingDashboard from "../components/admin-pages/Billing/Dashboard/BillingDashboard";
import CostManagement from "../components/admin-pages/CostManagement/CostManagement";
import RouteManagement from "../components/admin-pages/TMS/RouteManagement/HomePage";

// const Error404 = lazy(() => import("../components/pages/error-404"));
// const ResetPassword = lazy(() => import("../components/pages/reset-password"));
// const AdminLogin = lazy(() => import("../components/pages/admin-login"));
// const Dashboard = lazy(() => import("../components/pages/dashboard"));
// const TableView = lazy(() =>
//   import("../components/admin-pages/FTSD/TableView/TableView")
// );
// const UploadFileRO = lazy(() =>
//   import("../components/admin-pages/FTSD/UploadFileRO")
// );
// const ViewMoreTableView = lazy(() =>
//   import("../components/admin-pages/FTSD/TableView/ViewMore")
// );
// const PostalCodeForm = lazy(() =>
//   import("../components/admin-pages/FTSD/TableView/PostalCodeForm")
// );
// const MapView = lazy(() =>
//   import("../components/admin-pages/FTSD/MapView/MapViewUi")
// );
// const RouteMapping = lazy(() =>
//   import("../components/admin-pages/FTSD/RouteMapping/RouteMapping")
// );
// const RouteUpdateForm = lazy(() =>
//   import("../components/admin-pages/FTSD/RouteMapping/RouteUpdateForm")
// );
// const RouteAddForm = lazy(() =>
//   import("../components/admin-pages/FTSD/RouteMapping/RouteAddForm")
// );
// const DriverTracking = lazy(() =>
//   import("../components/admin-pages/FTSD/Tracking/DriverTracking")
// );
// const ShipmentTracking = lazy(() =>
//   import("../components/admin-pages/FTSD/Tracking/ShipmentTracking")
// );
// const RouteCode = lazy(() =>
//   import("../components/admin-pages/TMS/RouteCode/RouteCode")
// );
// const DriverDetails = lazy(() =>
//   import("../components/admin-pages/TMS/DriverDetails/TripMaster")
// );
// const FuelIntent = lazy(() =>
//   import("../components/admin-pages/TMS/FuelIntent/FuelIntent")
// );
// const TripMaster = lazy(() =>
//   import("../components/admin-pages/TMS/TripMaster/TripMaster")
// );
// const ShowMoreTripDetails = lazy(() =>
//   import("../components/admin-pages/TMS/TripMaster/ShowMoreTripDetails")
// );
// const Vendors = lazy(() =>
//   import("../components/admin-pages/VMS/Vendors/Vendors")
// );
// const Vehicles = lazy(() =>
//   import("../components/admin-pages/VMS/Vehicles/Vehicles")
// );
// const AddRouteForm = lazy(() =>
//   import("../components/admin-pages/TMS/RouteCode/AddRouteForm")
// );
// const CreateAdhocTrip = lazy(() =>
//   import("../components/admin-pages/TMS/RouteCode/CreateAdhocTrip")
// );
// const AddVendorForm = lazy(() =>
//   import("../components/admin-pages/VMS/Vendors/AddVendorForm")
// );
// const EditVendorForm = lazy(() =>
//   import("../components/admin-pages/VMS/Vendors/EditVendorForm")
// );
// const ShowVendorForm = lazy(() =>
//   import("../components/admin-pages/VMS/Vendors/ShowVendorForm")
// );
// const AddVehicleForm = lazy(() =>
//   import("../components/admin-pages/VMS/Vehicles/AddVehicleForm")
// );
// const ShowVehicles = lazy(() =>
//   import("../components/admin-pages/VMS/Vehicles/ShowVehicles")
// );
// const MapAttendance = lazy(() =>
//   import("../components/admin-pages/EMS/MapAttendance")
// );

// //Employee management
// const RegisterEmployee = lazy(() =>
//   import("../components/admin-pages/EMS/Employee/RegisterEmployee")
// );
// const ManageNewRegistration = lazy(() =>
//   import(
//     "../components/admin-pages/EMS/ManageNewRegistration/ManageNewRegistration"
//   )
// );
// const ManagerView = lazy(() =>
//   import("../components/admin-pages/EMS/ManagerView/ManagerView")
// );
// const ReportingTeam = lazy(() =>
//   import("../components/admin-pages/EMS/ManagerView/ReportingTeam")
// );
// const AttendanceDashboard = lazy(() =>
//   import("../components/admin-pages/EMS/Attendance/Dashboard/HomePage")
// );
// const TableAttendance = lazy(() =>
//   import("../components/admin-pages/EMS/TableAttendance")
// );
// const HomePageDashBoard = lazy(() =>
//   import("../components/admin-pages/FTSD/DashBoard/HomePage")
// );
// const TmsDashBoard = lazy(() =>
//   import("../components/admin-pages/TMS/DashBoard/HomePage")
// );
// const TableViewCms = lazy(() =>
//   import("../components/admin-pages/CMS/TableViewCms")
// );
// const ShipmentTrackingCms = lazy(() =>
//   import("../components/admin-pages/CMS/ShipmentTracking")
// );
// const VehicleSeggSorting = lazy(() =>
//   import("../components/admin-pages/FTSD/VehicleSeggregation/Sorting")
// );
// const VehicleSeggRouting = lazy(() =>
//   import("../components/admin-pages/FTSD/VehicleSeggregation/Routing")
// );
// const DriverTrackingFake = lazy(() =>
//   import("../components/admin-pages/FTSD/Tracking/DriverTrackingFake")
// );
// const InvoiceTableView = lazy(() =>
//   import("../components/admin-pages/FTSD/RODashboard/InvoiceTableView")
// );
// const TmsNewUi = lazy(() =>
//   import("../components/admin-pages/TMS_NEW/TmsNewUi")
// );
// const TmsClients = lazy(() =>
//   import("../components/admin-pages/TMS_NEW/Components/Clients")
// );

// //BMS
// const BMSDashboard = lazy(() =>
//   import("../components/admin-pages/BMS/Dashboard/HomePage")
// );
// const BMSPayee = lazy(() =>
//   import("../components/admin-pages/BMS/Payee/HomePage")
// );
// const PMSDashboard = lazy(() =>
//   import("../components/admin-pages/BMS/Dashboard/PmsHomePage")
// );
// const PmsPayeePrimaryBanks = lazy(() =>
//   import("../components/admin-pages/BMS/Dashboard/PmsPayeePrimaryBanks")
// );
// const ShowBanksPms = lazy(() =>
//   import("../components/admin-pages/BMS/Dashboard/ShowBanksPms")
// );
// const BMSRequestsPage = lazy(() =>
//   import("../components/admin-pages/BMS/CreateRequest/HomePage")
// );
// const BMSCreateNewRequest = lazy(() =>
//   import("../components/admin-pages/BMS/CreateRequest/CreateNewRequest")
// );
// const BMSEditRequest = lazy(() =>
//   import("../components/admin-pages/BMS/CreateRequest/EditRequest")
// );
// const BMSManagerView = lazy(() =>
//   import("../components/admin-pages/BMS/CreateRequest/ManagerView")
// );
// const BMSTripMasterView = lazy(() =>
//   import("../components/admin-pages/BMS/TripMaster/TripMaster")
// );
// const GpsTracking = lazy(() =>
//   import("../components/admin-pages/GPS_TRACKING/GpsTracking")
// );
// const TripCountHomePage = lazy(() =>
//   import("../components/admin-pages/TMS/TripCount/HomePage")
// );
// const TripCountViewDetailsPage = lazy(() =>
//   import("../components/admin-pages/TMS/TripCount/ViewDetailsTripCount")
// );

// //HR
// const HRDashboard = lazy(() =>
//   import("../components/admin-pages/HR/Dashboard/HomePage")
// );
// const HRManagerView = lazy(() =>
//   import("../components/admin-pages/HR/ManagerView/HomePage")
// );

// //Regular Trip
// const RegularTrip = lazy(() =>
//   import("../components/admin-pages/TMS/ManageRegularTrip/HomePage")
// );

// //Billing
// const ClientRegistration = lazy(() =>
//   import("../components/admin-pages/Billing/ClientRegistration/HomePage")
// );
// const Invoice = lazy(() =>
//   import("../components/admin-pages/Billing/Invoice/HomePage")
// );
// const UploadMIS = lazy(() =>
//   import("../components/admin-pages/Billing/UploadMIS/HomePage")
// );
// const GeoFencing = lazy(() =>
//   import("../components/admin-pages/GeoFencing/Dashboard/HomePage")
// );
// const EmployeeStats = lazy(() =>
//   import("../components/admin-pages/EMS/EmployeeStats/EmployeeStats")
// );
// const BillingDashboard = lazy(() =>
//   import("../components/admin-pages/Billing/Dashboard/BillingDashboard")
// );

class appRouter extends Component {
  render() {
    return (
      <div className="app-router-main-logicarts">
        <Router basename={"/"}>
          <ScrollContext>
            {/* <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/`}
                            component={LandingPage}
                        /> */}

            <Suspense fallback={<div id="cover-spin"></div>}>
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={AdminLogin}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/login`}
                  component={AdminLogin}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/reset-password/:userName`}
                  component={ResetPassword}
                />

                <App>
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/dashboard`}
                    component={Dashboard}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/upload/royal-oak/file`}
                    component={UploadFileRO}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/home/dashboard`}
                    component={HomePageDashBoard}
                  />
                  {/* <PrivateRoute
                                    exact
                                    path={`${process.env.PUBLIC_URL}/admin/dashboard/widget`}
                                    component={GeneralWidget}
                                /> */}
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipments/map-view`}
                    component={MapView}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipments/vehicle-seggregation/sorting`}
                    component={VehicleSeggSorting}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipments/vehicle-seggregation/routing`}
                    component={VehicleSeggRouting}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipments/table-view`}
                    component={TableView}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipment/table-view/show-more/:id`}
                    component={ViewMoreTableView}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipments/tracking/shipment-tracking`}
                    component={ShipmentTracking}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipments/tracking/driver-tracking`}
                    component={DriverTracking}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/billing/client-registration`}
                    component={ClientRegistration}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/billing/client-management`}
                    component={ClientManagement}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/route-master`}
                    component={RouteManagement}
                  />




                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/billing/invoice`}
                    component={Invoice}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/billing/upload-mis`}
                    component={UploadMIS}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/billing/dashboard`}
                    component={BillingDashboard}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/geofencing/dashboard`}
                    component={GeoFencing}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/route-mapping/add-new`}
                    component={RouteAddForm}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipment/:id`}
                    component={PostalCodeForm}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/route-mapping/update/:id`}
                    component={RouteUpdateForm}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/route-mapping`}
                    component={RouteMapping}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/route-code`}
                    component={RouteCode}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/dashboard`}
                    component={TmsDashBoard}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/manage-regular-trip`}
                    component={RegularTrip}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/driver-details`}
                    component={DriverDetails}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/fuel-intent`}
                    component={FuelIntent}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/cost-management`}
                    component={CostManagement}
                  />

                  {/* <PrivateRoute
                                    exact
                                    path={`${process.env.PUBLIC_URL}/admin/tms/fuel-intent-2`}
                                    component={FuelIntent2}
                                /> */}

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/trip-master`}
                    component={TripMaster}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/trip-count`}
                    component={TripCountHomePage}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/trip-count/view`}
                    component={TripCountViewDetailsPage}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/trip-master/show-more/:TripID`}
                    component={ShowMoreTripDetails}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/vms/vendors`}
                    component={Vendors}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/vms/vendors/add-vendor`}
                    component={AddVendorForm}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/vms/vendors/edit-vendor/:VendorID`}
                    component={EditVendorForm}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/vms/vendors/show-vendor/:VendorID`}
                    component={ShowVendorForm}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/vms/vehicles`}
                    component={Vehicles}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/vms/show/vehicles/:VendorID`}
                    component={ShowVehicles}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/vms/vehicles/add/:VendorID`}
                    component={AddVehicleForm}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/route-master/add-new`}
                    component={AddRouteForm}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/route-master/create-adhoc-trip/:RouteID`}
                    component={CreateAdhocTrip}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/ems/register-employee`}
                    component={RegisterEmployee}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/ems/manage-employees`}
                    component={ManageNewRegistration}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/ems/employees-stats`}
                    component={EmployeeStats}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/ems/manager-view`}
                    component={ManagerView}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/ems/reporting-team`}
                    component={ReportingTeam}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/ems/attendance-dashboard`}
                    component={AttendanceDashboard}
                  />

                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/ems/map-attendance`}
                    component={MapAttendance}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/ems/table-attendance`}
                    component={TableAttendance}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/cms/vehicle-tracking`}
                    component={TableViewCms}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/cms/shipment-tracking`}
                    component={ShipmentTrackingCms}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/shipments/tracking/driver-tracking/testing`}
                    component={DriverTrackingFake}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/royal-oak/invoice/:startDate/:endDate`}
                    component={InvoiceTableView}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/new/ui`}
                    component={TmsNewUi}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/tms/new/ui/:city`}
                    component={TmsClients}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/bms/dashboard`}
                    component={BMSDashboard}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/bms/payee`}
                    component={BMSPayee}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/bms/trip-master`}
                    component={BMSTripMasterView}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/hr/dashboard`}
                    component={HRDashboard}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/hr/manager-view`}
                    component={HRManagerView}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/pms/dashboard`}
                    component={PMSDashboard}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/pms/primary/banks`}
                    component={PmsPayeePrimaryBanks}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/pms/view/banks/:pmsId`}
                    component={ShowBanksPms}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/bms/requests/home`}
                    component={BMSRequestsPage}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/bms/add/new-request`}
                    component={BMSCreateNewRequest}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/bms/edit/request/:bmsId`}
                    component={BMSEditRequest}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/bms/manager/view`}
                    component={BMSManagerView}
                  />
                  <PrivateRoute
                    exact
                    path={`${process.env.PUBLIC_URL}/admin/gps/tracking/dashboard`}
                    component={GpsTracking}
                  />
                </App>
                <Route component={Error404} />
              </Switch>
            </Suspense>
          </ScrollContext>
        </Router>
      </div>
    );
  }
}

export default appRouter;
