import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import Config from "../../../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import { Form, FormGroup, Input, Button, ModalBody, ModalFooter, Modal, Label } from "reactstrap";
import "../../../../assets/customCssFiles/tmshomepage.css";
import "../../../../assets/customCssFiles/loader-spinner.css"
import "../../../../assets/customCssFiles/tmsTripCount.css";
import "../../../../assets/customCssFiles/tripCountTmsViewDetails.css";
import VehicleIcon from "../../../../assets/customImages/newTruckTms.png";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { AgGridReact } from 'ag-grid-react';
import CloseIcon from '@material-ui/icons/Close';



class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiData: [],
            style: {},
            propsData: {},
            isData: false,
            isCheckedAll: false,
            isProcessButtonLoading: false,
            isCreateButtonLoading: false,
            viewTripDetailsModal: false,
            tripIdData: [],
            columnDefs: [
                {
                    headerName: "Client Name", field: "ClientName", sortable: true, filter: true
                },
                {
                    headerName: "Segment", field: "Segment", sortable: true, filter: true
                },
                {
                    headerName: "Service Type", field: "Servicetype", sortable: true, filter: true
                },
                {
                    headerName: "Vehicle Number", field: "vehiclenumber", sortable: true, filter: true
                },
                {
                    headerName: "Trip Cnt", field: "TripCnt", sortable: true, filter: true
                },
            ],
            addTripToBmsLoading: 0,
            tripIDNEW: [],
            tripIdStrings: '',
        };
    }

    getDataMain = async () => {
        console.log(this.state.propsData);
        const res = await axios.post(
            `${Config.hostName}/tms/getTripsStatsViewDetailsTripCountNewMis`,
            this.state.propsData
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    const newData = res.data.data.map(item => {
                        item.isChecked =
                            // item.IsProcessed == 1 ? true : 
                            false
                        return item
                    })
                    await this.setState({
                        apiData: newData
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    }

    componentWillMount = async () => {
        await this.setState({
            propsData: this.props.location.state,
        })
        await this.getDataMain();
        console.log(this.state.apiData);
        await this.setState({
            isData: true,
            style: { display: "none" }
        })
    };


    handleCreateBMS = async () => {
        const tripIds = this.state.apiData.map(item => {
            if (item.isChecked) {
                return item.TripID
            }
        }).filter(Boolean)
        if (tripIds.length == 0) return alert("Please select trips to process")
        await this.setState({
            isCreateButtonLoading: true
        })
        const tripIdStrings = tripIds.length > 0 && tripIds.toString()
        console.log(tripIdStrings);
        await axios.post(`${Config.hostName}/tms/validateTMSTrips`, {
            tripIDs: tripIdStrings
        })
            .then(async (response) => {
                console.log(response)
                if (response.data.success === false) {
                    await this.setState({
                        style: { display: "none" },
                        isCreateButtonLoading: false,
                    })
                    alert(response.data.message);
                }
                else {
                    await axios
                        .post(`${Config.hostName}/bms/attachtmstrips`, {
                            tripIDs: tripIdStrings,
                        })
                        .then(async (res) => {
                            if (res.data.success == false) {
                                this.setState({
                                    tripIdError: res.data.message,
                                    uniqueSegmentsDropDownTripModal: '',
                                    selectedTripId: '',
                                });
                            alert(res.data.message);
                            } else {
                                await this.setState({
                                    tripIdData: res.data.data.map(eachItem => {
                                        return {
                                            ClientName: eachItem.ClientName,
                                            Segment: eachItem.Segment,
                                            Servicetype: eachItem.Servicetype,
                                            vehiclenumber: eachItem.vehiclenumber,
                                            TripCnt: eachItem.TripCnt,
                                        }
                                    }),
                                    tripIdStrings: tripIdStrings,
                                    tripIDNEW: res.data.data,
                                    tripIdError: ''
                                });
                            }

                        })
                        .catch(err => {
                            this.setState({
                                uniqueSegmentsDropDownTripModal: '',
                                tripIdError: err,
                                selectedTripId: '',

                            });
                        });
                    // window.location.href = `/admin/bms/add/new-request?tripid=${tripIdStrings}`;

                    await this.setState({
                        viewTripDetailsModal: true,
                        style: { display: "none" },
                        isCreateButtonLoading: false
                    })
                }
            }).catch((err) => {
                alert(err);
            });

    }

    toggleCloseViewTripDetails = async () => {
        await this.setState({
            viewTripDetailsModal: false,
        });
    }

    onSubmit = async () => {
        const tripIds = this.state.apiData.map(item => {
            if (item.isChecked) {
                return item.TripID
            }
        }).filter(Boolean)
        if (tripIds.length == 0) return alert("Please select trips to process")
        await this.setState({
            isProcessButtonLoading: true
        })
        const tripIdStrings = tripIds.length > 0 && tripIds.toString()
        console.log(tripIdStrings);
        await axios.post(`${Config.hostName}/tms/verifyNProcessTrips`, {
            tripIDs: tripIdStrings
        })
            .then(async (response) => {
                if (response.data.success === false) {
                    await this.setState({
                        style: { display: "none" },
                        isProcessButtonLoading: false
                    })
                    alert(response.data.message);
                }
                else {
                    await this.componentWillMount()
                    alert("Succesfully Processed");
                    await this.setState({
                        style: { display: "none" },
                        isProcessButtonLoading: false
                    })
                }
            }).catch((err) => {
                alert(err);
            });
    }

    route = async (e) => {
        let value = e.target.value
        console.log(value);
        let newData = this.state.apiData;
        if (value == "all") {
            await this.setState({
                isCheckedAll: !this.state.isCheckedAll
            })
            newData.filter(arr => {
                return arr.isChecked = this.state.isCheckedAll
            })
            await this.setState({
                apiData: newData
            })
        }
        else {
            // eslint-disable-next-line array-callback-return
            newData.filter(arr => {
                // eslint-disable-next-line eqeqeq
                if (arr.TripID == value) {
                    let checkVal = arr.isChecked
                    return arr.isChecked = !checkVal
                }
            })
            await this.setState({
                apiData: newData
            })
        }
    }

    render() {
        const closeBtn = <div className="col-sm-1 ml-auto">
            <button
                type="submit"
                // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                className="btn btn-danger overflow-hidden  px-auto"
                onClick={this.toggleCloseViewTripDetails}
            >
                <CloseIcon />

            </button>

        </div>
        const { isData, style, apiData, isProcessButtonLoading, isCreateButtonLoading, viewTripDetailsModal } = this.state;
        const defaultColDef = {
            flex: 1,
            minWidth: 100,
            // height: 100,
            resizable: true
        }
        return (
            <div style={{ height: "82vh" }}>
                <div id="cover-spin" style={style}> </div>
                <div className="tripCountTmsViewDetailsSuper">
                    <div
                        className="tripCountTmsViewDetailsSuperSaiyan"
                    >
                        <div className="row ml-5" >
                            <div className="col-sm-2 mt-2">
                                <FormGroup
                                    check
                                    inline
                                >
                                    <Input type="checkbox"
                                        checked={this.state.isCheckedAll}
                                        onClick={this.route}
                                        value="all"
                                    />
                                    <Label check>
                                        Select All
                                    </Label>
                                </FormGroup>
                            </div>
                            <div className="col-sm-2">
                                <button
                                    style={{ margin: "auto" }}
                                    type="submit"
                                    className="btn btn-danger overflow-hidden mb-3 px-auto"
                                    onClick={this.onSubmit}
                                >
                                    {isProcessButtonLoading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}

                                    {!isProcessButtonLoading && (
                                        <i
                                            className="fa fa-floppy-o"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {isProcessButtonLoading && <span>Processing ...</span>}
                                    {!isProcessButtonLoading && <span>Process</span>}
                                </button>
                            </div>

                            <div className="col-sm-2">
                                <button
                                    style={{ margin: "auto" }}
                                    type="submit"
                                    className="btn btn-danger overflow-hidden mb-3 px-auto"
                                    onClick={this.handleCreateBMS}
                                >

                                    {isCreateButtonLoading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}

                                    {!isCreateButtonLoading && (
                                        <PlaylistAddIcon />

                                    )}


                                    {isCreateButtonLoading && <span>Processing ...</span>}
                                    {!isCreateButtonLoading && <span>Create BMS</span>}

                                </button>
                            </div>
                        </div>

                        <div className="row tripCountTmsViewDetailsUltraMain">
                            {isData && apiData.map((Item, key) => {
                                return (
                                    <div className="col-sm-5 card tripCountTmsViewDetailsMain" key={key}>
                                        <div className="tripCountTmsViewDetailsTop">
                                            <div className="tripCountTmsViewDetailsTopTripIdDetails">
                                                <input
                                                    type="checkbox"
                                                    checked={Item.isChecked}
                                                    onClick={this.route}
                                                    value={Item.TripID}
                                                    style={{ margin: "10px" }}
                                                />
                                                <span className="tripCountTmsViewDetailsTopTripId" >TRIP ID :</span>
                                                <Link to={`${process.env.PUBLIC_URL}/admin/tms/trip-master/show-more/${Item.TripID}`} >
                                                    <span className="tripCountTmsViewDetailsTopTripIdValue">&nbsp;{Item.TripID}</span>
                                                </Link>
                                            </div>

                                            <div className="tripCountTmsViewDetailsTopTripDateDetails">
                                                <span className="tripCountTmsViewDetailsTopTripDate" >Trip Date :</span>
                                                <span className="tripCountTmsViewDetailsTopTripDateValue">{Item.TripDate}</span>
                                            </div>

                                        </div>

                                        <div className="tripCountTmsViewDetailsBottom">
                                            <div className="tripCountTmsViewDetailsBottomLeft">
                                                <img className="tripCountTmsViewDetailsBottomLeftVehicleIcon" src={VehicleIcon} alt={Item.vehicleNumber} width="100" height="60" />
                                            </div>
                                            <div className="tripCountTmsViewDetailsBottomRight">
                                                <div className="tripCountTmsViewDetailsBottomRightRouteCode">
                                                    <span className="tripCountTmsViewDetailsBottomRightRouteCodeValue">
                                                        Route Code: {Item.RouteCode}
                                                    </span>
                                                </div>
                                                <div className="tripCountTmsViewDetailsBottomRightVehicleSize">
                                                    <span className="tripCountTmsViewDetailsBottomRightVehicleSizeValue">
                                                        Vehicle Size: {Item.VehicleSize}
                                                    </span>
                                                </div>
                                                <div className="tripCountTmsViewDetailsBottomRightVehicleNumber">
                                                    <span className="tripCountTmsViewDetailsBottomRightVehicleNumberValue">
                                                        Vehicle Number: {Item.VehicleNumber}
                                                    </span>
                                                </div>
                                                <div className="tripCountTmsViewDetailsBottomRightServiceHours">
                                                    <span className="tripCountTmsViewDetailsBottomRightServiceHoursValue">
                                                        Service Hrs: {Item.ServiceHrs}
                                                    </span>
                                                </div> <div className="tripCountTmsViewDetailsBottomRightServiceHours">
                                                    <span className="tripCountTmsViewDetailsBottomRightServiceHoursValue">
                                                        IsProcessed:  <span className={`${Item.IsProcessed == 1 ? "text-success" : "text-danger"} `}>
                                                            {Item.IsProcessed == 1 ? "YES" : "NO"}
                                                        </span>
                                                    </span>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                );
                            })}
                        </div>

                    </div>


                    <div>
                        <Modal
                            size="lg"
                            style={{ maxWidth: '1600px', width: '80%' }}
                            centered={true}
                            isOpen={this.state.viewTripDetailsModal}
                        >
                            <ModalFooter>

                                <div className="col-sm-2">
                                    <Button
                                        color="danger"
                                        onClick={this.toggleCloseViewTripDetails}
                                    >
                                        <CloseIcon />
                                    </Button>{" "}
                                </div>

                            </ModalFooter>
                            <ModalBody>
                                <div className="row" style={{ marginLeft: '3%' }}>
                                    <div className="col-sm-4" style={{ textAlign: 'left' }}>
                                        TripID's Details :
                                    </div>
                                    <div className="col-sm-3">
                                        <Link to={`/admin/bms/add/new-request?tripid=${this.state.tripIdStrings}`}>
                                            <button
                                                type="submit"
                                                className="btn btn-danger overflow-hidden mb-3 px-0"
                                                style={{ width: '112px' }}

                                            >

                                                Create BMS
                                            </button>
                                        </Link>
                                    </div>
                                </div>


                                <div className="bms-view-req-modal-body">


                                    <div>
                                        <div className="card-body">

                                            <div
                                                className="ag-theme-balham"
                                                style={{
                                                    height: '100%',
                                                    width: '100%',

                                                }}
                                            >

                                                <AgGridReact
                                                    columnDefs={this.state.columnDefs}
                                                    rowData={this.state.tripIdData}
                                                    defaultColDef={defaultColDef}
                                                    onGridReady={this.onGridReady}
                                                    enableCellTextSelection={true}
                                                    gridAutoHeight={true}
                                                    domLayout={this.state.domLayout}
                                                    rowHeight={50}
                                                    suppressAutoSize={true}
                                                >
                                                </AgGridReact>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </ModalBody>

                        </Modal>
                    </div>

                </div>
            </div>
        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
