/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import './TripMaster.css';
import axios from "axios";
import PropTypes from "prop-types";
import { Button, FormGroup, Input, Label, Card, CardImg, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Config from "../../../config";
import Breadcrumb from "../../common/breadcrumb.component";
import Alert from "../../common/Alert";
import { setAlert } from "../../../redux/actions/alert.actions";
import "../../../assets/customCssFiles/tripMasterNewUi.css"
import "../../../assets/customCssFiles/loader-spinner.css"


class NoticeAdd extends Component {
    state = {
        link: "",
        modal: false,
        locations: [],
        uniqueCities: [
            {
                "Locationid": 1,
                "name": "Hyderabad",
                "cityurl": "https://logicarts-tms.s3.ap-south-1.amazonaws.com/cityLogos/charminar.png"
            },
            {
                "Locationid": 2,
                "name": "Bangalore",
                "cityurl": "https://logicarts-tms.s3.ap-south-1.amazonaws.com/cityLogos/bangalore.png"
            },
            {
                "Locationid": 3,
                "name": "Chennai",
                "cityurl": "https://logicarts-tms.s3.ap-south-1.amazonaws.com/cityLogos/chennai.png"
            },
            {
                "Locationid": 4,
                "name": "Vijayawada",
                "cityurl": "https://logicarts-tms.s3.ap-south-1.amazonaws.com/cityLogos/vijayawada.png"
            },
            {
                "Locationid": 5,
                "name": "Coimbatore",
                "cityurl": "https://logicarts-tms.s3.ap-south-1.amazonaws.com/cityLogos/Coimbatore.png"
            }
        ],
        city: ""
    };



    getUniqueCities = async () => {
        const data = await axios.get(`${Config.hybridHostName}/common/locations`)
            .then(res => {
                console.log(res.data);

                this.setState({ uniqueCities: res.data.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    async componentDidMount() {
        // await this.getUniqueCities();
        this.setState({ style: { display: "none" } });
    }


    render() {
        let { style } = this.state

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id='cover-spin' style={style}></div>
                <Breadcrumb link="ImportantDates" parent="Admin" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                </nav>
                                <div>
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>
                                        {this.state.uniqueCities.map(city => {
                                            return <div className="col-sm-3 card-city">
                                                <Link to={`/admin/tms/new/ui/${city.Locationid}`}>
                                                    <Card className="card-main-city">
                                                        <CardImg className="card-img-city" src={city.cityurl} alt={city.name} />
                                                        <CardText className="card-text-city">{city.name}</CardText>
                                                    </Card>
                                                </Link>
                                            </div>
                                        })}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
