import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
// import { Form, FormGroup, Label, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config"
import { AvForm, AvField } from 'availity-reactstrap-validation';
// import moment from "moment";
import { StepperComponent } from "./stepper";
import "../../../../assets/customCssFiles/loader-spinner.css"


class ViewMoreTableView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            location: "",
            cxName: "",
            cxNumber01: "",
            cxNumber02: "",
            ItemNumber: "",
            description: "",
            Qty: "",
            CBM: "",
            TotalCBM: "",
            pincode: "",
            ead: "",
            fulladdress: "",
            lat: "",
            long: "",
            Orderno: "",
            BPCode: "",
            trackingurl: "",
            trackingHistory: "",
        };
        this.validator = new SimpleReactValidator();
    }

    componentWillMount = async () => {
        // console.log("hi");
        // console.log(this.props.match.params.id);


        var result = await axios.get(
            `${Config.hostName}/shipment/show-more/table-view/${this.props.match.params.id}/${this.props.auth.user.CCID}`
        );
        // console.log(moment(result.data.data[0].schedule_time.substring(0, 11)).format("DD/MM/YYYY"));
        // console.log(result.data);
        let dataObj = result.data.data[0]
        // console.log(dataObj, "data");


        await this.setState({
            location: dataObj,

            cxName: dataObj.cxName,
            cxNumber01: dataObj.mobile,
            fulladdress: dataObj.fulladdress,
            cxNumber02: dataObj.telNo,

            ItemNumber: dataObj.ItemNumber,
            description: dataObj.description,
            Qty: dataObj.Qty,
            CBM: dataObj.CBM,
            TotalCBM: dataObj.TotalCBM,
            pincode: dataObj.pincode,
            ead: dataObj.estimatedDelivery,
            BPCode: dataObj.BPCode,
            trackingurl: dataObj.trackingurl,

            Orderno: dataObj.Orderno,
            lat: dataObj.lat,
            long: dataObj.long,
            trackingHistory: dataObj.trackingHistory,

            style: { display: "none" }

        })


    }



    render() {
        let style = this.state.style;

        return (
            <div style={{ height: "100vh" }}>
                <div id='cover-spin' style={style}>
                </div>
                <Breadcrumb title="Order Details" parent="Table View" />
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="row">
                                <Alert />

                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            {
                                                (this.props.auth.user.user_type === "ROAdmin" ||
                                                    this.props.auth.user.user_type === "ROSuperAdmin" ||
                                                    this.props.auth.user.user_type === "ROSupervisor"


                                                ) ?
                                                    <p>Order No :
                                               &nbsp; <strong>{this.state.location.Orderno}</strong>
                                                    </p> :
                                                    <p>Tracking No :
                                               &nbsp; <strong>{this.state.location.tracking_num}</strong>
                                                    </p>}
                                        </div>
                                        <div className="card-header">
                                            <h5>Tracking History</h5>
                                            {this.state.trackingHistory ?
                                                <StepperComponent props={this.state.trackingHistory} />
                                                : ""
                                            }
                                        </div>

                                        <div className="card-body">


                                            <div className="form-row">

                                                <div className="form-group col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Name</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            disabled
                                                            placeholder="Enter Contact No"
                                                            formControlName="cxName"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.cxName}
                                                            onChange={event => {
                                                                this.setState({
                                                                    cxName: event.target.value
                                                                });
                                                            }}

                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Mobile</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            disabled
                                                            placeholder="Enter State"
                                                            formControlName="cxNumber01"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.cxNumber01}
                                                            onChange={event => {
                                                                this.setState({
                                                                    cxNumber01: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Tel No</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            disabled
                                                            placeholder="Enter Mobile No"
                                                            formControlName="cxNumber02"
                                                            type="number"
                                                            required
                                                            className="form-control"
                                                            value={this.state.cxNumber02}
                                                            onChange={event => {
                                                                this.setState({
                                                                    cxNumber02: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-row">

                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Quantity</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            disabled
                                                            placeholder="Enter PAN Number"
                                                            formControlName="Qty"
                                                            type="number"
                                                            className="form-control"
                                                            value={this.state.Qty}
                                                            onChange={event => {
                                                                this.setState({
                                                                    Qty: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Estd Arr Date</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            disabled
                                                            placeholder="Enter Comments"
                                                            formControlName="ead"
                                                            // type="datetime-local"
                                                            type="date"
                                                            className="form-control"
                                                            value={this.state.ead}
                                                            onChange={event => {
                                                                // console.log(event.target.value);

                                                                this.setState({
                                                                    ead: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="form-group col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>BPCode</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            disabled
                                                            placeholder="Enter Comments"
                                                            formControlName="BPCode"
                                                            // type="datetime-local"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.BPCode}
                                                            onChange={event => {
                                                                // console.log(event.target.value);

                                                                this.setState({
                                                                    BPCode: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                    {/* </div> */}



                                                    {/* </div> */}

                                                </div>

                                            </div>

                                            <AvForm>
                                                <div className="col-md-9">
                                                    <AvField
                                                        disabled
                                                        name="description"
                                                        label="Description"
                                                        value={this.state.description}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                description: e.target.value
                                                            })
                                                        }}
                                                        type="textarea"
                                                        placeholder="description"
                                                    />
                                                </div>
                                            </AvForm>

                                            <AvForm>
                                                <div className="col-md-12">
                                                    <AvField
                                                        disabled
                                                        name="fulladdress"
                                                        label="Customer Address"
                                                        value={this.state.fulladdress}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                fulladdress: e.target.value
                                                            })
                                                        }}
                                                        type="textarea"
                                                        placeholder="fulladdress"
                                                    />
                                                </div>
                                            </AvForm>


                                        </div>



                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ViewMoreTableView.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(ViewMoreTableView);
