/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Input, Button } from "reactstrap";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../common/Alert";
import { setAlert } from "../../../redux/actions/alert.actions";
import Config from "../../../config";
import { AgGridReact } from 'ag-grid-react';
import "../../../assets/customCssFiles/loader-spinner.css"


class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            modal: false,
            anotherModal: false,
            locations: [],
            unique_route: [],
            uniqueClientSegments: [],
            description: "",
            message: "",
            selectedClientSegment: "",
            style: {},
            tracking_number: "",
            columnDefs: [
                {
                    headerName: "City", field: "city", sortable: true, filter: true, maxWidth: 70
                },
                {
                    headerName: "Client", field: "client", sortable: true, filter: true, maxWidth: 140
                },
                {
                    headerName: "Driver Name", field: "name", sortable: true, filter: true, maxWidth: 200
                },
                {
                    headerName: "Driver No", field: "mobile", sortable: true, filter: true, maxWidth: 180
                },
                {
                    headerName: "Vehicle No", field: "vehicleNumber", sortable: true, filter: true, maxWidth: 190
                },
                {
                    headerName: "Status", field: "dutyStatus", sortable: true, filter: true, maxWidth: 120,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.dutyStatus;
                        const StatusColor = status === "ON" ? "text-success" : "text-danger";
                        return (
                            <span className={StatusColor} >{status}</span>
                        )
                    }
                },
                {
                    headerName: "Stopped Since", field: "stoppedSince", sortable: true, filter: true, maxWidth: 190
                },
                // {
                //     headerName: "Tracking Url", field: "trackingUrl", sortable: true, filter: true,
                // },
                {
                    headerName: "Last Updated", field: "lastUpdated", sortable: true, filter: true,
                },
                {
                    headerName: "Remarks", field: "remark", sortable: true, filter: true,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.remark;
                        const StatusColor = status ? "text-danger" : ""
                        return (
                            <span className={StatusColor} >{status}</span>
                        )
                    }
                },
                {
                    headerName: "Track", field: "trackingUrl", editable: true,
                    maxWidth: 140, minWidth: 140,

                    cellRendererFramework: (params) => {
                        return params.node.data.trackingUrl ? <Button
                            color="primary"
                            value={params.node.data.trackingUrl}
                            onClick={this.onClickLiveTrack}
                        >
                            Live Track
                        </Button> : ""
                        // <a href={params.node.data.trackingUrl}>{params.node.data.trackingUrl ? "Live Track" : ""}</a>

                    }
                },
                // {
                //     headerName: "Current Location", field: "address", sortable: true, filter: true,
                //     cellRendererFramework: (params) => {
                //         console.log(params.node.data.address);

                //         const status = params.node.data.address;
                //         const StatusColor = status ? "text-info" : ""
                //         return (
                //             <span className={StatusColor} >{status}</span>
                //         )
                //     }
                // }


            ],
            rowData: [],
            activePage: 1,
            itemsCountPerPage: 50,
            totalItemsCount: 5000,
            pageRangeDisplayed: 5,
            page: 1,
            search: false,
            userCcId: null,
            city: "",
            unique_cities: [],
            ccid: "",
            ccXrefId: "",
            searchApi: "",
            domLayout: "autoHeight",
        };
    }

    getUniqueClientSegments = async () => {
        await axios.get(`${Config.hostName}/tracking/get/client-segments/cms/table-view`)
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueClientSegments: res.data.data
                    })
                }
                else {
                    this.setState({ uniqueClientSegments: [] })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }
    getLiveLocation = async (trackingurl) => {
        let address;
        // if (trackingurl) {
        //     const latLngString = trackingurl.substr(trackingurl.lastIndexOf("=") + 1);

        //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLngString}&key=${Config.geoCodeApiKey}`;
        //     const data = await axios.get(url);
        //     // console.log(data, "gdata");
        //     address = data.data.results[1].formatted_address;
        //     console.log(address, "address");
        //     return address;
        // }
        // else {
        //     return address = "";
        // }
        return address;
    }

    doSomeFun = async (data) => {
        const res = data.filter(async item => {
            const add = await this.getLiveLocation(item.trackingurl);
            // console.log(add, "add");

            return item.adds = add
        })
        return res;

    }

    onClickLiveTrack = async (e) => {
        e.preventDefault();
        window.open(e.target.value, '_blank');
    }


    getApiData = async () => {
        const result = await axios.post(`${Config.hostName}/tracking/get/data/cms/table-view`, {
            "ccxrefId": this.state.ccXrefId,
            "search": this.state.searchApi
        });
        const data = result.data.data;
        const res = await this.doSomeFun(data);
        console.log(res, "res");

        if (res) {

            await this.setState({
                search: false,
                locations: res,
                userCcId: this.props.auth.user.CCID
            });
            // console.log("hi");

            await this.setState({

                rowData: this.state.locations.map(eachItem => {
                    return {
                        city: eachItem.city,
                        client: eachItem.Client,
                        name: eachItem.name,
                        mobile: eachItem.mobile,
                        vehicleNumber: eachItem.vehicleNumber,
                        dutyStatus: eachItem.dutyStatus ? "ON" : "OFF",
                        stoppedSince: eachItem.stoppedSince,
                        trackingUrl: eachItem.trackingurl,
                        lastUpdated: eachItem.lastupdated,
                        remark: eachItem.remark,
                        address: eachItem.adds
                    }
                }),

            });
        }
    }

    componentWillMount = async () => {
        await this.setState({
            style: {}
        })
        await this.getApiData();
        await this.getUniqueClientSegments();
        await this.setState({
            style: { display: "none" }
        })
    }

    handleSearchShipment = async () => {
        await this.setState({
            style: {}
        })
        await this.getApiData();
        await this.setState({
            style: { display: "none" }
        })
    }

    render() {
        let { style, options } = this.state;
        // console.log(this.state.userCcId);

        const defaultColDef = {
            flex: 1,
            minWidth: 70,
            resizable: true,
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id="cover-spin" style={style}></div>


                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                {/* <div className="card"> */}
                                <nav className="navbar navbar-light " >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="ccXrefId"
                                                    value={this.state.ccXrefId}
                                                    id="ccXrefId"
                                                    onChange={event => {
                                                        this.setState({ ccXrefId: event.target.value });
                                                    }}
                                                    list={this.state.uniqueClientSegments}
                                                >
                                                    <option value={""} disabled>Select Client Segment</option>

                                                    {this.state.uniqueClientSegments.map(item => (
                                                        <option value={item.CCXrEFID}>{item.Client}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>

                                            {/* <Autocomplete
                                                    // onUpdateInput={this.handleUpdateInput}
                                                    value={this.state.selectedClientSegment}
                                                    onChange={this.updateState}
                                                    // id="combo-box-demo"
                                                    options={this.state.uniqueClientSegments}
                                                    getOptionLabel={(option) => option.Client}
                                                    // renderInput={(params) =>
                                                    //     <TextField {...params} label="Select" variant="outlined" />
                                                    // }
                                                    disableClearable
                                                    autoSelect={true}
                                                    id="limoSelect"
                                                    // onChange={this.updateState}
                                                    // value={this.state.inputVal}
                                                    // options={this.state.options}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Type In Content"
                                                            id="limoText"
                                                            value=""
                                                            autoSelect={true}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputProps={{ ...params.InputProps, type: "search" }}
                                                        />
                                                    )}
                                                /> */}
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Vehicle No/ Name"
                                                    name="searchApi"
                                                    value={this.state.searchApi}
                                                    id="searchApi"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            searchApi: e.target.value
                                                        })
                                                    }}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                type="submit"
                                                className="btn btn-info"
                                                onClick={this.handleSearchShipment}
                                            >
                                                Search
                      </button>
                                        </div>

                                        <div className="col-sm-2">
                                            {this.state.locations.length > 0 && (
                                                <CSVLink data={this.state.locations}>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-info"

                                                    >Export
                      </button>
                                                </CSVLink>
                                            )}

                                        </div>

                                    </div>
                                </nav>

                                {/* </div> */}

                                <div className="card-body" style={{
                                    // height: "90vh",
                                    // maxHeight: ""
                                }}>

                                    <div
                                        className="ag-theme-balham"
                                        style={{
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={this.state.rowData}
                                            defaultColDef={defaultColDef}
                                            animateRows={true}
                                            rowHeight={40}
                                            enableCellTextSelection={true}
                                            suppressAutoSize={true}
                                            gridAutoHeight={true}
                                            domLayout={this.state.domLayout}

                                        >
                                        </AgGridReact>
                                    </div>
                                    <div style={{ margin: "10vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                        {/* <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                                            onChange={this.handlePageChange.bind(this)}
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        /> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);