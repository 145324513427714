const dateObj = new Date();
const month = dateObj.getUTCMonth() + 1; //months from 1-12
const day = dateObj.getUTCDate();
const year = dateObj.getUTCFullYear();
// eslint-disable-next-line no-useless-concat
const iDate = year + "-" + month + "-" + "01";
const mDate = year + "-" + month + "-" + day;

export const MENUITEMS = [
  // {
  //   path: "/admin/",
  //   title: "FTSD",
  //   type: "sub",
  //   children: [
  //     {
  //       path: "/admin/home/dashboard",
  //       title: "Dashboard",
  //       type: "link",
  //       icon: "bar-chart",
  //     },
  //     {
  //       path: "/admin/shipments/table-view",
  //       title: "Table View",
  //       type: "link",
  //       icon: "panel",
  //     },
  //     {
  //       path: `/admin/royal-oak/invoice/${iDate}/${mDate}`,
  //       title: "Invoice",
  //       type: "link",
  //       icon: "printer",
  //     },
  //     {
  //       path: "/admin/upload/royal-oak/file",
  //       title: "Generate BarCode",
  //       type: "link",
  //       icon: "printer",
  //     },
  //     {
  //       path: "/admin/shipments/map-view",
  //       title: "Map View",
  //       type: "link",
  //       icon: "map-alt",
  //     },
  //     {
  //       path: "/admin/route-mapping",
  //       title: "Route Mapping",
  //       type: "link",
  //       icon: "blackboard",
  //     },
  //     {
  //       path: "/admin/shipments/vehicle-seggregation/sorting",
  //       title: "Sorting",
  //       type: "link",
  //       icon: "blackboard",
  //     },
  //     {
  //       path: "/admin/shipments/vehicle-seggregation/routing",
  //       title: "Routing",
  //       type: "link",
  //       icon: "blackboard",
  //     },
  //     {
  //       path: "/admin/shipments/tracking/shipment-tracking",
  //       title: "Shipment Tracking",
  //       type: "link",
  //       icon: "layers",
  //     },
  //     {
  //       path: "/admin/shipments/tracking/driver-tracking",
  //       title: "Driver Tracking",
  //       type: "link",
  //       icon: "location-pin",
  //     },
  //     {
  //       path: "/admin/shipments/tracking/driver-tracking/testing",
  //       title: "Tracking Dev",
  //       type: "link",
  //       icon: "location-pin",
  //     },
  //     {
  //       path: "/admin/tms/new/ui",
  //       title: "TMS New",
  //       type: "link",
  //       icon: "layers",
  //     },
  //   ],
  // },
  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      {
        path: "/admin/tms/route-code",
        title: "Route Code",
        type: "link",
        icon: "panel",
      },
      {
        path: "/admin/tms/trip-master",
        title: "Trip Master",
        type: "link",
        icon: "layers",
      },
      // {
      //   path: "/admin/tms/dashboard",
      //   title: "DashBoard",
      //   type: "link",
      //   icon: "bar-chart",
      // },
      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },
      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },


    ],
  },
  {
    path: "/admin/bms",
    title: "BMS",
    type: "sub",
    children: [
      {
        path: "/admin/bms/dashboard",
        title: "Dashboard",
        type: "link",
        icon: "blackboard",
      },
      // {
      //   path: "/admin/bms/payee", title: "BMSPayee", type: "link", icon: "location-arrow"
      // },
      // { path: "/admin/shipments/vehicle-seggregation/routing", title: "Routing", type: "link", icon: "blackboard" }
    ],
  },
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/attendance-dashboard",
        title: "Attendance Dashboard",
        type: "link",
        icon: "bar-chart",
      },

      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "layout-list-large-image",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
      {
        path: "/admin/ems/manage-employees",
        title: "Manage Employees",
        type: "link",
        icon: "location-arrow",
      },

      {
        path: "/admin/ems/employees-stats",
        title: "Employees Stats",
        type: "link",
        icon: "bar-chart",
      },
      {
        path: "/admin/ems/map-attendance",
        title: "Map View",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/ems/table-attendance",
        title: "Table View",
        type: "link",
        icon: "layers",
      },
      {
        path: "/admin/geofencing/dashboard",
        title: "GeoFencing",
        type: "link",
        icon: "map-alt",
      },
    ],
  },


  {
    path: "/admin/billing",
    title: "BILLING",
    type: "sub",
    children: [
      {
        path: "/admin/billing/dashboard",
        title: "Dashboard",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/billing/client-registration",
        title: "Client Registration",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/billing/client-management",
        title: "Client Management",
        type: "link",
        icon: "layout",
      },
      {
        path: "/admin/billing/invoice",
        title: "Invoice",
        type: "link",
        icon: "credit-card",
      },
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },

      {
        path: "/admin/billing/primary/banks",
        title: "Payee Central",
        type: "link",
        icon: "credit-card",
      },


    ],
  },

  {
    path: "/admin/vms",
    title: "VMS",
    type: "sub",
    children: [
      {
        path: "/admin/vms/vendors",
        title: "Vendors",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/vms/vehicles",
        title: "Vehicles",
        type: "link",
        icon: "truck",
      },
    ],
  },

  // {
  //   path: "/admin/cms",
  //   title: "CMS",
  //   type: "sub",
  //   children: [
  //     {
  //       path: "/admin/cms/vehicle-tracking",
  //       title: "Vehicle Tracking",
  //       type: "link",
  //       icon: "location-arrow",
  //     },
  //     {
  //       path: "/admin/cms/shipment-tracking",
  //       title: "Shipment Tracking",
  //       type: "link",
  //       icon: "truck",
  //     },
  //     {
  //       path: "/admin/shipments/vehicle-seggregation/routing",
  //       title: "Routing",
  //       type: "link",
  //       icon: "blackboard",
  //     },
  //   ],
  // },


  // {
  //   path: "/admin/hr",
  //   title: "HR Connect",
  //   type: "sub",
  //   children: [
  //     {
  //       path: "/admin/hr/dashboard",
  //       title: "My HR Connect",
  //       type: "link",
  //       icon: "blackboard",
  //     },
  //     // {
  //     //   path: "/admin/hr/manager-view", title: "Manager View", type: "link", icon: "location-arrow"
  //     // },
  //     // { path: "/admin/shipments/vehicle-seggregation/routing", title: "Routing", type: "link", icon: "blackboard" }
  //   ],
  // },


  {
    path: "/admin/gps",
    title: "GPS",
    type: "sub",
    children: [
      {
        path: "/admin/gps/tracking/dashboard",
        title: "GPS TRACKING",
        type: "link",
        icon: "location-arrow",
      },
    ]
  },

  // { path: "/admin/tms/driver-details", title: "Driver Details", type: "link" },

  // { path: "/admin/tms/fuel-intent-2", title: "Fuel Intent 2", type: "link", }
];

export const SUPERVISORMENUITEMS = [
  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      {
        path: "/admin/tms/route-code",
        title: "Route Code",
        type: "link",
        icon: "panel",
      },
      // {
      //   path: "/admin/tms/dashboard",
      //   title: "DashBoard",
      //   type: "link",
      //   icon: "bar-chart",
      // },
      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },
      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },


    ],
  },
  {
    path: "/admin/bms",
    title: "BMS",
    type: "sub",
    children: [
      {
        path: "/admin/bms/dashboard",
        title: "Dashboard",
        type: "link",
        icon: "blackboard",
      },
      // {
      //   path: "/admin/bms/payee", title: "BMSPayee", type: "link", icon: "location-arrow"
      // },
      // { path: "/admin/shipments/vehicle-seggregation/routing", title: "Routing", type: "link", icon: "blackboard" }
    ],
  },
  {
    path: "/admin/vms",
    title: "VMS",
    type: "sub",
    children: [
      {
        path: "/admin/vms/vendors",
        title: "Vendors",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/vms/vehicles",
        title: "Vehicles",
        type: "link",
        icon: "truck",
      },
    ],
  },
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },
];

export const FTSDMENUITEMS = [
  // {
  //   path: "/admin/shipments/table-view",
  //   title: "Table View",
  //   type: "link",
  //   icon: "panel",
  // },
  // { path: "/admin/shipments/map-view", title: "Map View", type: "link", icon: "map-alt" },
  // {
  //   path: "/admin/shipments/vehicle-seggregation/sorting",
  //   title: "Vehicle Segregation",
  //   type: "link",
  //   icon: "truck",
  // },
  // {
  //   path: "/admin/route-mapping",
  //   title: "Route Mapping",
  //   type: "link",
  //   icon: "blackboard",
  // },
  // {
  //   path: "/admin/shipments/vehicle-seggregation", title: "Vehicle Seggregation", type: "sub", icon: "panel",
  //   children: [
  //     { path: "/admin/shipments/vehicle-seggregation/sorting", title: "Sorting", type: "link", icon: "blackboard" },
  //     { path: "/admin/shipments/vehicle-seggregation/routing", title: "Routing", type: "link", icon: "blackboard" },
  //   ]
  // },
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },

  {
    path: "/admin/shipments/tracking",
    title: "Tracking",
    type: "sub",
    icon: "location-arrow",
    children: [
      {
        path: "/admin/shipments/tracking/shipment-tracking",
        title: "Shipment Tracking",
        type: "link",
        icon: "layers",
      },
      {
        path: "/admin/shipments/tracking/driver-tracking",
        title: "Driver Tracking",
        type: "link",
        icon: "location-pin",
      },
    ],
  },
  // {
  //   path: "/admin/tms/cost-management",
  //   title: "Cost Management",
  //   type: "link",
  //   icon: "cloud-up"
  // }
];

export const ROYALOAKSFTSDMENUITEMS = [
  // {
  //   path: "/admin/home/dashboard",
  //   title: "Dashboard",
  //   type: "link",
  //   icon: "bar-chart",
  // },
  // {
  //   path: "/admin/shipments/table-view",
  //   title: "Table View",
  //   type: "link",
  //   icon: "panel",
  // },
  // {
  //   path: `/admin/royal-oak/invoice/${iDate}/${mDate}`,
  //   title: "Invoice",
  //   type: "link",
  //   icon: "printer",
  // },
  // {
  //   path: "/admin/upload/royal-oak/file",
  //   title: "Generate BarCode",
  //   type: "link",
  //   icon: "printer",
  // },
  // { path: "/admin/shipments/map-view", title: "Map View", type: "link", icon: "map-alt" },
  // {
  //   path: "/admin/shipments/tracking",
  //   title: "Tracking",
  //   type: "sub",
  //   icon: "location-arrow",
  //   children: [
  //     {
  //       path: "/admin/shipments/tracking/shipment-tracking",
  //       title: "Shipment Tracking",
  //       type: "link",
  //       icon: "layers",
  //     },
  // { path: "/admin/shipments/tracking/driver-tracking", title: "Driver Tracking", type: "link", icon: "location-pin" }
  // ],
  // },
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },
  // {
  //   path: "/admin/tms/cost-management",
  //   title: "Cost Management",
  //   type: "link",
  //   icon: "cloud-up"
  // },


];

export const ROYALOAKSUPERVISORMENUITEMS = [
  // {
  //   path: "/admin/home/dashboard",
  //   title: "Dashboard",
  //   type: "link",
  //   icon: "bar-chart",
  // },
  // {
  //   path: "/admin/shipments/table-view",
  //   title: "Table View",
  //   type: "link",
  //   icon: "panel",
  // },
  // {
  //   path: "/admin/upload/royal-oak/file",
  //   title: "Generate BarCode",
  //   type: "link",
  //   icon: "printer",
  // },
  // { path: "/admin/shipments/map-view", title: "Map View", type: "link", icon: "map-alt" },
  // { path: "/admin/shipments/ftsd-seggregation", title: "Vehicle Segregation", type: "link", icon: "blackboard" },
  // {
  //   path: "/admin/shipments/vehicle-seggregation",
  //   title: "Vehicle Seggregation",
  //   type: "sub",
  //   icon: "panel",
  //   children: [
  //     {
  //       path: "/admin/shipments/vehicle-seggregation/sorting",
  //       title: "Sorting",
  //       type: "link",
  //       icon: "blackboard",
  //     },
  //     {
  //       path: "/admin/shipments/vehicle-seggregation/routing",
  //       title: "Routing",
  //       type: "link",
  //       icon: "blackboard",
  //     },
  //   ],
  // },
  // {
  //   path: "/admin/shipments/tracking",
  //   title: "Tracking",
  //   type: "sub",
  //   icon: "location-arrow",
  //   children: [
  //     {
  //       path: "/admin/shipments/tracking/shipment-tracking",
  //       title: "Shipment Tracking",
  //       type: "link",
  //       icon: "layers",
  //     },
  //     // { path: "/admin/shipments/tracking/driver-tracking", title: "Driver Tracking", type: "link", icon: "location-pin" }
  //   ],
  // },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },

  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },
  // {
  //   path: "/admin/tms/cost-management",
  //   title: "Cost Management",
  //   type: "link",
  //   icon: "cloud-up"
  // },

];

export const CMSMENUITEMS = [
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },

  {
    path: "/admin/cms/vehicle-tracking",
    title: "Vehicle Tracking",
    type: "link",
    icon: "location-arrow",
  },
  {
    path: "/admin/cms/shipment-tracking",
    title: "Shipment Tracking",
    type: "link",
    icon: "truck",
  },
  {
    path: "/admin/shipments/vehicle-seggregation/routing",
    title: "Routing",
    type: "link",
    icon: "blackboard",
  },
  // {
  //   path: "/admin/tms/cost-management",
  //   title: "Cost Management",
  //   type: "link",
  //   icon: "cloud-up"
  // },

];

export const BMSMENUITEMS = [
  {
    path: "/admin/bms/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "blackboard",
  },

  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
      {
        path: "/admin/ems/employees-stats",
        title: "Employees Stats",
        type: "link",
        icon: "location-arrow",
      },
    ],
  },
  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/dashboard",
        title: "Dashboard",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/billing/client-registration",
        title: "Client Registration",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/billing/client-management",
        title: "Client Management",
        type: "link",
        icon: "layout",
      },
      {
        path: "/admin/billing/invoice",
        title: "Invoice",
        type: "link",
        icon: "credit-card",
      },
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },

      {
        path: "/admin/billing/primary/banks",
        title: "Payee Central",
        type: "link",
        icon: "credit-card",
      },


    ],
  },


  // {
  //   path: "/admin/billing",
  //   title: "PMS Master",
  //   type: "sub",
  //   children: [
  //     // {
  //     //   path: "/admin/pms/dashboard",
  //     //   title: "Payee Details",
  //     //   type: "link",
  //     //   icon: "user",
  //     // },

  //   ],
  // },



  {
    path: "/admin/bms/payee",
    title: "BMSPayee",
    type: "link",
    icon: "location-arrow",
  },

  {
    path: "/admin/bms/requests/home",
    title: "BMS",
    type: "link",
    icon: "layers",
  },
  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      // {
      //   path: "/admin/tms/route-code",
      //   title: "Route Code",
      //   type: "link",
      //   icon: "panel",
      // },
      {
        path: "/admin/tms/trip-master",
        title: "Trip Master",
        type: "link",
        icon: "layers",
      },
      // {
      //   path: "/admin/tms/dashboard",
      //   title: "DashBoard",
      //   type: "link",
      //   icon: "bar-chart",
      // },
      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },
      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },

    ],
  },


];

export const MIS2 = [
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },

  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      {
        path: "/admin/tms/trip-master",
        title: "Trip Master",
        type: "link",
        icon: "layers",
      },
      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },

      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },

    ],
  },


];

export const MIS = [
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },
  {
    path: "/admin/bms/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "blackboard",
  },
  {
    path: "/admin/bms/payee",
    title: "BMSPayee",
    type: "link",
    icon: "location-arrow",
  },
  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      {
        path: "/admin/tms/trip-master",
        title: "Trip Master",
        type: "link",
        icon: "layers",
      },

      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },
      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },


    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },


];

export const LC_EMPLOYEE_MENU_ITEMS = [
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },
  {
    path: "/admin/bms/requests/home",
    title: "BMS",
    type: "link",
    icon: "wallet",
  },

  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      // {
      //   path: "/admin/tms/route-code",
      //   title: "Route Code",
      //   type: "link",
      //   icon: "panel",
      // },
      // {
      //   path: "/admin/tms/trip-master",
      //   title: "Trip Master",
      //   type: "link",
      //   icon: "layers",
      // },
      // {
      //   path: "/admin/tms/dashboard",
      //   title: "DashBoard",
      //   type: "link",
      //   icon: "bar-chart",
      // },
      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },
      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },

    ],
  },

  // { path: "/admin/tms/route-code", title: "Route Code", type: "link", icon: "panel" },
  // { path: "/admin/vms/vendors", title: "Vendors", type: "link", icon: "user" },
  // { path: "/admin/vms/vehicles", title: "Vehicles", type: "link", icon: "truck" }
];

export const LC_MANAGER_MENU_ITEMS = [
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },
  {
    path: "/admin/bms/requests/home",
    title: "BMS",
    type: "link",
    icon: "layers",
  },
  {
    path: "/admin/bms/manager/view",
    title: "Manager View",
    type: "link",
    icon: "blackboard",
  },
  {
    path: "/admin/tms/trip-count",
    title: "TMS",
    type: "link",
    icon: "blackboard",
  },
  {
    path: "/admin/tms/manage-regular-trip",
    title: "Manage Regular Trip",
    type: "link",
    icon: "map-alt",
  },
  {
    path: "/admin/tms/route-code",
    title: "Route Code",
    type: "link",
    icon: "panel",
  },
  {
    path: "/admin/tms/cost-management",
    title: "Cost Management",
    type: "link",
    icon: "money"
  },
  { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },

  // { path: "/admin/vms/vendors", title: "Vendors", type: "link", icon: "user" },
  // { path: "/admin/vms/vehicles", title: "Vehicles", type: "link", icon: "truck" }
];

export const HR_MENU_ITEMS = [
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/attendance-dashboard",
        title: "Attendance Dashboard",
        type: "link",
        icon: "bar-chart",
      },
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },

      {
        path: "/admin/ems/manage-employees",
        title: "Manage Employees",
        type: "link",
        icon: "location-arrow",
      },
      {
        path: "/admin/ems/employees-stats",
        title: "Employees Stats",
        type: "link",
        icon: "location-arrow",
      },
      {
        path: "/admin/ems/map-attendance",
        title: "Map View",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/ems/table-attendance",
        title: "Table View",
        type: "link",
        icon: "layers",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },
  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      {
        path: "/admin/tms/route-code",
        title: "Route Code",
        type: "link",
        icon: "panel",
      },
      {
        path: "/admin/tms/trip-master",
        title: "Trip Master",
        type: "link",
        icon: "layers",
      },
      // {
      //   path: "/admin/tms/dashboard",
      //   title: "DashBoard",
      //   type: "link",
      //   icon: "bar-chart",
      // },
      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },
      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },


    ],
  },

];

export const TMSMENUITEMS = [
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },



  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },
  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      {
        path: "/admin/tms/route-code",
        title: "Route Code",
        type: "link",
        icon: "panel",
      },
      // {
      //   path: "/admin/tms/trip-master",
      //   title: "Trip Master",
      //   type: "link",
      //   icon: "layers",
      // },
      // {
      //   path: "/admin/tms/dashboard",
      //   title: "DashBoard",
      //   type: "link",
      //   icon: "bar-chart",
      // },
      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },
      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },

    ],
  },





];

export const LC_MANAGER_MENU_ITEMS_TMS = [
  {
    path: "/admin/ems",
    title: "EMS",
    type: "sub",
    children: [
      {
        path: "/admin/ems/register-employee",
        title: "Register Employee",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/manager-view",
        title: "Manager View",
        type: "link",
        icon: "user",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },
    ],
  },

  {
    path: "/admin/billing",
    title: "Billing",
    type: "sub",
    children: [
      {
        path: "/admin/billing/upload-mis",
        title: "Upload MIS",
        type: "link",
        icon: "cloud-up",
      },
    ],
  },
  {
    path: "/admin/bms/requests/home",
    title: "BMS",
    type: "link",
    icon: "layers",
  },
  {
    path: "/admin/bms/manager/view",
    title: "Manager View",
    type: "link",
    icon: "blackboard",
  },
  {
    path: "/admin/hr",
    title: "HR Connect",
    type: "sub",
    children: [
      {
        path: "/admin/hr/dashboard",
        title: "My HR Connect",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/hr/manager-view",
        title: "Manager View",
        type: "link",
        icon: "location-arrow",
      },
      {
        path: "/admin/ems/reporting-team",
        title: "Reporting Team",
        type: "link",
        icon: "list-ol",
      },


      // { path: "/admin/shipments/vehicle-seggregation/routing", title: "Routing", type: "link", icon: "blackboard" }
    ],
  },
  {
    path: "/admin/tms",
    title: "TMS",
    type: "sub",
    children: [
      {
        path: "/admin/tms/route-code",
        title: "Route Code",
        type: "link",
        icon: "panel",
      },
      {
        path: "/admin/tms/trip-master",
        title: "Trip Master",
        type: "link",
        icon: "layers",
      },
      // {
      //   path: "/admin/tms/dashboard",
      //   title: "DashBoard",
      //   type: "link",
      //   icon: "bar-chart",
      // },
      {
        path: "/admin/tms/trip-count",
        title: "TripCount",
        type: "link",
        icon: "blackboard",
      },
      {
        path: "/admin/tms/manage-regular-trip",
        title: "Manage Regular Trip",
        type: "link",
        icon: "map-alt",
      },
      {
        path: "/admin/tms/cost-management",
        title: "Cost Management",
        type: "link",
        icon: 'money'
      },
      { path: "/admin/tms/fuel-intent", title: "Fuel Intent", type: "link", icon: "menu" },

    ],
  },
  // { path: "/admin/vms/vendors", title: "Vendors", type: "link", icon: "user" },
  // { path: "/admin/vms/vehicles", title: "Vehicles", type: "link", icon: "truck" }
];
