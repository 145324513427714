/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import "../../../../../assets/customCssFiles/tmshomepage.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../../redux/actions/alert.actions";
import CanvasJSReact from "../../../../../assets/canvas/canvasjs.react";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                type: "pie",
                showInLegend: true,
                toolTipContent: "{indexLabel} : {y} Trips",
                // yValueFormatString: "#,##0,,.## Million",
                legendText: "{indexLabel}",
                dataPoints: []
            }
        };
    }

    componentWillMount = async () => {
        let dataPoints = [];
        const { data } = this.state;
        const apiData = this.props.data;
        apiData.map((dataItem, i) => {
            let item = {};
            item.y = dataItem.tripCount;
            item.indexLabel = dataItem.city + "-" + dataItem.Segment;
            dataPoints.push(item)
        })
        // console.log(dataPoints, "dataPoints");
        data.dataPoints = dataPoints;
        await this.setState({
            data: data
        })
    }

    render() {
        let data = [];
        data.push(this.state.data)
        const options = {
            theme: "light2",
            title: {
                text: "By Segment"
            },
            data: data
        }
        return (
            <div className="main-home-page" >
                <div className="container-fluid">
                    <div className="row" style={{ height: "100%" }}>
                        <div className="col-xl-12 col-lg-12 mt-5">
                            <div className="card">
                                <CanvasJSChart options={options} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
