import React, { Component } from "react";
import { withScriptjs } from "react-google-maps";
import Map from './DriverTrackingMap'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config"


class DriverTracking extends Component {
    render() {
        const MapLoader = withScriptjs(Map);
        return (
            <MapLoader
                auth={this.props.auth}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${String(Config.apiKey)}`}
                loadingElement={<div style={{ height: `80vh`, width: "100%" }} />}
            />
        )
    }
}



DriverTracking.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(DriverTracking);
