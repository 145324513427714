import React, { Component } from "react";
import axios from "axios";
import Config from "../../../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import { GoogleMap, LoadScript, Marker, Polyline, DirectionsRenderer } from '@react-google-maps/api';
import Autocomplete from "react-google-autocomplete";

const google = window.google;

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {},
            markers: [
                { lat: 28.620491, lng: 77.0679584 },
                { lat: 29.3963428, lng: 76.8939089 },
                { lat: 29.6852406, lng: 76.9533267 },
                { lat: 30.3721234, lng: 76.7423215 }
            ],
            distances: [],
            directions: null,
        };
    }
    getDistance = (points) => {
        const R = 6371; // Radius of the earth in km
        const distances = [];
        for (let i = 0; i < points.length - 1; i++) {
            const lat1 = points[i].lat;
            const lng1 = points[i].lng;
            const lat2 = points[i + 1].lat;
            const lng2 = points[i + 1].lng;
            const dLat = ((lat2 - lat1) * Math.PI) / 180; // deg2rad below
            const dLng = ((lng2 - lng1) * Math.PI) / 180;
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos((lat1 * Math.PI) / 180) *
                Math.cos((lat2 * Math.PI) / 180) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = R * c; // Distance in km
            console.log(lat1, lat2, 'jljlkjlj')
            distances.push(distance);
        }
        return distances;
    };


    componentDidMount = async () => {
        await this.setState({
            style: {
                display: "none"
            }
        });
        window.addEventListener('load', this.loadMap);
    };

    loadMap = () => {
        // Check if the google.maps namespace is available
        console.log('ffsfd');
        if (window.google && window.google.maps) {
            // Create a new DirectionsService instance
            //   directionsService = new window.google.maps.DirectionsService();
            // Call a function to use the DirectionsService instance
            //   this.calculateRoute();

            const distances = this.getDistance(this.state.markers);
            this.setState({ distances: distances });
            /*global google*/
            const directionsService = new window.google.maps.DirectionsService();
            // const d = new window.google.maps.LatLng('37.7683909618184', '-122.51089453697205')       
            const origin = 'San Francisco, CA';
            const destination = 'Los Angeles, CA';

            directionsService.route({
                origin: origin,
                destination: destination,
                waypoints: [
                    { location: "36.156400, -115.166191" },
                    // { location: "Monterey" },
                ],
                travelMode: window.google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    console.log(result, 'fsdjk');
                    var legs = result.routes[0].legs;
                    var totalDistance = 0;
                    var totalDuration = 0;
                    for (var i = 0; i < legs.length; ++i) {
                        totalDistance += legs[i].distance.value;
                        totalDuration += legs[i].duration.value;
                    }
                    console.log((totalDistance / 1000).toFixed(2), ' KMS');
                    this.setState({
                        directions: result
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        } else {
            // Retry loading the map if the namespace is not available
            setTimeout(this.loadMap, 100);
        }
    }



    render() {

        const { markers, distances } = this.state;
        const path = this.state.markers.map((location) => ({
            lat: location.lat,
            lng: location.lng,
        }));
        return (
            <div style={{ height: "82vh" }}>
                <div id="cover-spin" style={this.state.style}> </div>

                <div className="container-fluid" style={{ marginBottom: "5vh" }}>
                    <div className="row">
                        <div className="col-12 mt-5">
                            <ul>
                                {distances.map((distance, index) => (
                                    <li key={index}>Distance {index + 1}: {distance.toFixed(2)} km</li>
                                ))}
                            </ul>
                            <Autocomplete
                                apiKey={Config.apiKey}
                                onPlaceSelected={(place) => {
                                    console.log(place);
                                }}
                            />;
                        </div>
                        <div className="col-12 mt-5">
                            <LoadScript googleMapsApiKey={Config.apiKey}>
                                <GoogleMap
                                    mapContainerStyle={{ height: '400px', width: '800px' }}
                                    zoom={10}
                                    center={markers[0]}
                                >
                                    {markers.map((marker, index) => (
                                        <Marker key={index} position={marker} />
                                    ))}
                                    <ul>
                                        {distances.map((distance, index) => (
                                            <li key={index}>Distance {index + 1}: {distance.toFixed(2)} km</li>
                                        ))}
                                    </ul>
                                    <Polyline path={path} options={{ strokeColor: "#ff0000", strokeWeight: 5 }} />
                                    {this.state.directions && <DirectionsRenderer directions={this.state.directions} />}

                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
