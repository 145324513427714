import React, {
  Component
  // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from "reactstrap";
// import {Link} from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";

class RouteAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      unique_locationids: [],
      Locationid: "",
      Firstname: "",
      Lastname: "",
      VendorName: "",
      Mobile: "",
      Contact2: "",
      Contact3: "",
      AadharNum: "",
      PanNumber: "",
      Comments: "",
      BankName: "",
      AccountNo: "",
      IFSC: "",
      BenificaryName: "",
      IsPrimary: "",
      IsPrimaryValue: ""
    };
    this.validator = new SimpleReactValidator();
  }


  async componentWillMount() {
    let result = await axios.get(
      `${Config.hostName}/vms/vendors/${this.props.match.params.VendorID}`
    );
    console.log(result.data[0]);
    let data = result.data.data[0]

    await this.setState({
      Locationid: data.Locationid,
      Firstname: data.firstname,
      Lastname: data.lastname,
      VendorName: data.TransportName,
      Mobile: data.Mobile,
      Contact2: data.Contact2,
      Contact3: data.Contact3,
      AadharNum: data.aadhar_num,
      PanNumber: data.pan_num,
      Comments: data.Comments,
      BankName: data.BankName,
      AccountNo: data.AccountNumber,
      IFSC: data.IFSC,
      BenificaryName: data.BenificaryName,
      IsPrimaryValue: data.IsPrimary
    });
  }

  handleCheckPrimary = async () => {
    await this.setState({
      IsPrimary: !this.state.IsPrimary
    });
    await this.setState({
      // IsOdometer=true ? (IsOdometerValue = 1) : (IsOdometerValue = 0)
      IsPrimaryValue: `${this.state.IsPrimary === true ? "1" : "0"}`
    });
    console.log(this.state.IsPrimary);
    console.log(this.state.IsPrimaryValue);
  };

  onSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      try {
        // eslint-disable-next-line
        const res = await axios.post(
          `${Config.hostName}/vms/update/vendor/${this.props.match.params.VendorID}`,
          {
            locationId: this.state.Locationid,
            firstName: this.state.Firstname,
            lastName: this.state.Lastname,
            vendorName: this.state.VendorName,
            mobile: this.state.Mobile,
            contact2: this.state.Contact2,
            contact3: this.state.Contact3,
            aadharNum: this.state.AadharNum,
            panNumber: this.state.PanNumber,
            comments: this.state.Comments,
            bankName: this.state.BankName,
            accountNo: this.state.AccountNo,
            ifsc: this.state.IFSC,
            benificaryName: this.state.BenificaryName,
            isPrimaryValue: this.state.IsPrimaryValue
          }
        ).then((response) => {
          console.log(response, "response");
          if (response.data.name) {
            this.props.setAlert(response.data.parent.message, "danger");
          }
          else {
            this.props.setAlert(" Vendor Updated", "success");
          }

        }
        ).catch((error) => {
          console.log(error, 'error');

        }
        )
        this.props.history.push("/admin/vms/vendors");
        // console.log("Route added");
      } catch (error) {
        const errors = error.response.data.errors;
        if (errors) {
          errors.forEach(error => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;

    return (
      <div>
        {/*Container-fluid starts*/}
        <Breadcrumb link="ImportantDates" parent="Admin" />
        {/*Container-fluid Ends*/}

        {/*Container-fluid starts*/}
        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <h4 className="card-link mb-0">Edit Vendor Form</h4>
                      <br />
                    </div>
                    <br />
                  </div>
                </div>

                <div className="card-options">
                  <a
                    href="/#"
                    className="card-options-collapse"
                    data-toggle="card-collapse"
                  >
                    <i className="fe fe-chevron-up"></i>
                  </a>
                  <a
                    href="/#"
                    className="card-options-remove"
                    data-toggle="card-remove"
                  >
                    <i className="fe fe-x"></i>
                  </a>
                </div>
                <br />

                <form>
                  <div
                    className="col-lg-12 text-right"
                    style={{ paddingTop: "10vh" }}
                  ></div>
                  <div className="col-lg-12">
                    <div className="grid">
                      <div className="grid-body">
                        <div className="item-wrapper">
                          <div className="row mb-3">
                            <div className="col-md-12 mx-auto">
                              <div className="form-row">
                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Location ID</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      formControlName="dataType"
                                      readOnly
                                      value={
                                        this.state.Locationid === 1 ? "Hyderabad" :
                                          this.state.Locationid === 2 ? "Bangalore"
                                            : "Chennai"
                                      }
                                      id="locationid"
                                      // onChange={event => {
                                      //     this.setState({ Locationid: event.target.value });
                                      // }}
                                      className="custom-select"
                                    >
                                      {/* <option hidden>Select Location id</option>

                                                                            {this.state.unique_locationids.map(locationid => (
                                                                                <option value={locationid.LocationID}>{locationid.LocationID}</option>
                                                                            ))} */}
                                    </input>
                                  </div>
                                </div>

                                <div className="form-group col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>First Name</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      readOnly
                                      // placeholder="Enter Vendor First Name"
                                      formControlName="Firstname"
                                      type="text"
                                      className="form-control"
                                      value={this.state.Firstname}
                                    // onChange={event => {
                                    //     this.setState({ Firstname: event.target.value });
                                    // }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Last Name</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      readOnly
                                      // placeholder="Enter Vendor Last Name"
                                      formControlName="lastname"
                                      type="text"
                                      className="form-control"
                                      value={this.state.lastname}
                                    // onChange={event => {
                                    //     this.setState({ lastname: event.target.value });
                                    // }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Vendor Name</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      // placeholder="Enter Vendor Name (optional)"
                                      formControlName="VendorName"
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      value={this.state.VendorName}
                                      onChange={event => {
                                        this.setState({
                                          VendorName: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Mobile Number</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter Mobile No"
                                      formControlName="Mobile"
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      value={this.state.Mobile}
                                      onChange={event => {
                                        this.setState({
                                          Mobile: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Contact Number</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter Contact No"
                                      formControlName="Contact2"
                                      type="text"
                                      className="form-control"
                                      value={this.state.Contact2}
                                      onChange={event => {
                                        this.setState({
                                          Contact2: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Alternate Contact</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter Alternate Contact No"
                                      formControlName="Contact3"
                                      type="text"
                                      className="form-control"
                                      value={this.state.Contact3}
                                      onChange={event => {
                                        this.setState({
                                          Contact3: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Aadhar Number</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter Aadhar Number"
                                      formControlName="AadharNum"
                                      type="text"
                                      className="form-control"
                                      value={this.state.AadharNum}
                                      onChange={event => {
                                        this.setState({
                                          AadharNum: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>PAN Number</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter PAN Number"
                                      formControlName="PanNumber"
                                      type="text"
                                      className="form-control"
                                      value={this.state.PanNumber}
                                      onChange={event => {
                                        this.setState({
                                          PanNumber: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Comments</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter Comments"
                                      formControlName="Comments"
                                      type="text"
                                      className="form-control"
                                      value={this.state.Comments}
                                      onChange={event => {
                                        this.setState({
                                          Comments: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Bank Name</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter Bank Name"
                                      formControlName="BankName"
                                      type="text"
                                      className="form-control"
                                      value={this.state.BankName}
                                      onChange={event => {
                                        this.setState({
                                          BankName: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Account No</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter Account No"
                                      formControlName=" AccountNo"
                                      type="text"
                                      className="form-control"
                                      value={this.state.AccountNo}
                                      onChange={event => {
                                        this.setState({
                                          AccountNo: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>IFSC Code</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter IFSC Code"
                                      formControlName="IFSC"
                                      type="text"
                                      className="form-control"
                                      value={this.state.IFSC}
                                      onChange={event => {
                                        this.setState({
                                          IFSC: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-md-6 row showcase_row_area">
                                  <div className="col-md-3 showcase_text_area">
                                    <label>Benificary Name</label>
                                  </div>
                                  <div className="col-md-9 showcase_text_area">
                                    <input
                                      placeholder="Enter Benificary Name"
                                      formControlName="BenificaryName"
                                      type="text"
                                      className="form-control"
                                      value={this.state.BenificaryName}
                                      onChange={event => {
                                        this.setState({
                                          BenificaryName: event.target.value
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-4">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      disabled
                                      type="checkbox"
                                      defaultChecked="1"
                                    // onChange={this.handleCheckPrimary}
                                    // // value={this.state.IsOdometer}
                                    // checked={this.state.IsPrimary}
                                    />{" "}
                                    Is Primary
                                  </Label>
                                </FormGroup>
                              </div>

                              <button
                                type="submit"
                                className="btn btn-outline-primary btn-lg"
                                style={{ float: "right" }}
                                onClick={e => this.onSubmit(e)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RouteAddForm.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
