import React, { Component } from "react";
import axios from "axios";
import Config from "../../../config";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PlusIcon from "@mui/icons-material/Add";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import { Slide, Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./CostManagement.css";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  DirectionsRenderer,
  Autocomplete,
} from "@react-google-maps/api";
import DeleteIcon from "@mui/icons-material/Delete";
import { borderRightColor } from "@mui/system";

const google = window.google;

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: "#f44336",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const options = {
  filterType: "multiselect",
  // filterOptions: {fullWidth: true},
  selectableRows: "none",
  setRowProps: (row) => {
    if (row[0] === "New") {
      return {
        style: { background: "snow" },
      };
    }
  },
  draggableColumns: { enabled: true },
  pagination: true,
  // resizableColumns: true,
  // responsive: "standard",
  fixedHeader: false,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export class CostManagement extends Component {
  constructor(props) {
    super(props);

    this.autocomplete = null;

    this.onLoad = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);

    this.state = {
      distances: [],
      distance: 0,
      markers: [{ lat: 12.9889434, lng: 77.687139 }],
      directions: null,
      link: "",
      modal: false,
      locations: [],
      description: "",
      message: "",
      style: {},
      addNewAutoComplete: [1],
      autocompleteIds: [0, 1],
      finalArray: [
        { lat: "", lng: "", nickName: "" },
        { lat: "", lng: "", nickName: "" },
      ],
      unique_locationids: [],
      isVendorRate: "",
      isClientRate: "",
      anotherModal: false,
      Locationid: "",
      unique_segments: [],
      unique_clients: [],
      Segment: "",
      ClientName: "",
      Trip: "",
      ccxrefID: "",
      openFullModal: false,
      openFullModalClient: false,
      isChecked: false,
      clientRateID: "",
      vendorRateID: "",
      vendorNameData: [],
      vendorName: "",
      clientRatecardID: "",
      userID: "",
      form_Locationid: "",
      form_ClientName: "",
      form_CCxrefID: "",
      unique_form_clients: [],
      unique_form_segments: [],
      segmentName: "",
      ServiceType: "ADHOC",
      routeCode: 0,
      unique_routes: [],
      unique_vendorNames: [],
      vendorName: "",
      unique_vehicleSizes: [],
      vehicleSize: "",
      chargetype: "",
      effStartDate: "",
      effEndDate: "",
      form_clientCost: {
        clientCost: 0,
        fixedkms: 0,
        toll: 0,
        haltingCharges: 0,
        extraTonsCost: 0,
        fixedTons: 0,
        extraHrsCost: 0,
        extraKmsCost: 0,
      },
      isCPS: 1,
      target_haltingCharges: 0,
      target_extraTonsCost: 0,
      target_fixedTons: 0,
      target_extraHrsCost: 0,
      target_extraKmsCost: 0,
      target_fixedKms: 0,
      target_fixedCost: 0,
      commision: { 10: "outlined", 15: "outlined" },
      openFullModalRoute: false,
      searchResult: "",
      isLoad: false,
      isClientRoute: false,
      form_commision: "",
      startPoint: "",
      endPoint: "",
      estdistance: 0,

      columns: [
        {
          name: "ClientRatecardID",
          label: "Client Rate Card ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "ClientName",
          label: "Client",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Segment",
          label: "Segment",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "ServiceType",
          label: "Service Type",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "VehicleSize",
          label: "Vehicle Size",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "ServiceHrs",
          label: "Fixed Hrs",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "EstMargin",
          label: "Est. Margin (%)",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <span> {value.toFixed(2)}</span>
              )
          }
           
          },
        },
        {
          name: "FixedCost",
          label: "Fixed Cost",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "T_FixedCost",
          label: "Target Fixed  Cost",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "FixedKms",
          label: "Fixed Kms",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "T_FixedKms",
          label: "Target Fixed  Kms",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "ExtraKmsCost",
          label: "Extra Kms Cost",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "T_ExtraKmsCost",
          label: "Target Extra Kms Cost",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "ExtraHrCost",
          label: "Extra Hrs Cost",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "T_ExtraHrCost",
          label: "Target Extra Hr Cost",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "ClientRatecardID",
          label: "Action",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <Button
                  onClick={() =>
                    this.handleOpenClientModal("update", dataIndex)
                  }
                >
                  <EditIcon /> Edit
                </Button>
              );
            },
          },
        },
      ],
      rowData: [],
      value: 0,
      vendorRowData: [],
      vendorColumn: [
        {
          name: "VendorRatecardID",
          label: "Vendor Rate Card ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "ClientName",
          label: "Client Name",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "Segment",
          label: "Segment",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "VehicleSize",
          label: "Vehicle Size",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "VehicleNumber",
          label: "Vehicle Number",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return this.state.vendorRowData[dataIndex].VehicleNumber == null
                ? "-"
                : this.state.vendorRowData[dataIndex].VehicleNumber;
            },
          },
        },
        {
          name: "TransportName",
          label: "Transport Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "VendorID",
          label: "Vendor ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "ChargeType",
          label: "Charge Type",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "ServiceType",
          label: "Service Type",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "ServiceHrs",
          label: "ServiceHrs",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "FixedCost",
          label: "Fixed Cost",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "T_FixedCost",
          label: "Target Fixed Cost",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return this.state.vendorRowData[dataIndex].T_FixedCost == null
                ? "-"
                : this.state.vendorRowData[dataIndex].T_FixedCost;
            },
          },
        },
        {
          name: "FixedKms",
          label: "Fixed Kms",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "T_FixedKms",
          label: "Target Fixed Kms",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return this.state.vendorRowData[dataIndex].T_FixedKms == null
                ? "-"
                : this.state.vendorRowData[dataIndex].T_FixedKms;
            },
          },
        },
        {
          name: "ExtraKmsCost",
          label: "Extra Kms Cost",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "T_ExtraKmsCost",
          label: "Target Extra Kms Cost",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return this.state.vendorRowData[dataIndex].T_ExtraKmsCost == null
                ? "-"
                : this.state.vendorRowData[dataIndex].T_ExtraKmsCost;
            },
          },
        },
        {
          name: "ExtraHrCost",
          label: "Extra Hr Cost",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "T_ExtraHrCost",
          label: "Target Extra Hr Cost",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return this.state.vendorRowData[dataIndex].T_ExtraHrCost == null
                ? "-"
                : this.state.vendorRowData[dataIndex].T_ExtraHrCost;
            },
          },
        },
        {
          name: "HaltingCharges",
          label: "Halting Charges",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "T_HaltingCharges",
          label: "Target Halting Charges",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return this.state.vendorRowData[dataIndex].T_HaltingCharges ==
                null
                ? "-"
                : this.state.vendorRowData[dataIndex].T_HaltingCharges;
            },
          },
        },
      ],
    };

    this.autocompleteRefs = this.state.autocompleteIds.reduce((acc, id) => {
      acc[id] = React.createRef();
      return acc;
    }, {});
  }

  componentDidMount = async () => {
    window.addEventListener("load", this.loadMap);
  };

  getClientRateCard2 = async () => {
    await axios
      .post(`${Config.hostName}/cm/getClientRateCard`, {
        userid: this.state.userID,
      })
      .then(
        async (res) =>
          await this.setState({
            rowData: res.data.data,
          })
      )
      .catch((err) => {
        console.log(console.log(err));
      });
  };

  componentWillMount = async () => {
    await this.setState({ style: {} });

    const getUserID = localStorage.getItem("persist:root");
    const parseUserID = JSON.parse(getUserID);
    const auth = parseUserID.auth;
    const parseAuth = JSON.parse(auth);
    const userID = parseAuth.USERID;
    const isClientRate = parseAuth.isClientRate;
    const isVendorRate = parseAuth.isVendorRate;

    await this.setState({
      isClientRate: isClientRate,
      isVendorRate: isVendorRate,
      userID: userID,
    });
    console.log(this.state.isClientRate);
    await axios
      .post(`${Config.hostName}/cm/getVendorRateCard`, {
        userid: "",
      })
      .then((res) =>
        this.setState({
          vendorRowData: res.data.data,
        })
      )
      .catch((err) => {
        console.log(console.log(err));
      });

    await this.getClientRateCard2();

    axios
      .get(`${Config.hostName}/unique/locations`)
      .then((res) => {
        if (res.data.data.length > 0) {
          this.setState({
            unique_locationids: res.data.data,
            style: { display: "none" },
          });
        }
      })
      .catch((error) => {
        alert(error);
      });

    axios
      .get(`${Config.hostName}/vms/get/unique/vehicle/sizes/dropdown`)
      .then(
        async (res) => (
          console.log(res.data),
          await this.setState({
            unique_vehicleSizes: res.data.data,
            vehicleSize: "",
          })
        )
      )
      .catch((err) => console.log(err));
  };

  handleMapChanged = async () => {
    console.log("ff");
    if (this.state.finalArray.length - 1 > 1) {
      // console.log(this.state.addNewAutoComplete[0]["name"]);
      const directionsService = new window.google.maps.DirectionsService();
      // const d = new window.google.maps.LatLng('37.7683909618184', '-122.51089453697205')
      const locations = this.state.finalArray;
      // console.log(locations);
      const origin = locations[0]["lat"] + "," + locations[0]["lng"];
      const destination =
        locations[locations.length - 2]["lat"] +
        "," +
        locations[locations.length - 2]["lng"];

      // console.log(origin, destination);

      const citrus = locations.slice(1, [locations.length - 2]);
      let waypoints = [];
      if (citrus.length > 0) {
        citrus.map((e) => {
          waypoints.push({ location: `${e.lat}, ${e.lng}` });
        });
      }

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          waypoints: waypoints,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            // console.log(result, "fsdjk");
            var legs = result.routes[0].legs;
            var totalDistance = 0;
            var totalDuration = 0;
            for (var i = 0; i < legs.length; ++i) {
              totalDistance += legs[i].distance.value;
              totalDuration += legs[i].duration.value;
            }
            // console.log((totalDistance / 1000).toFixed(2), " KMS");
            this.setState({
              directions: result,
              distance: (totalDistance / 1000).toFixed(2),
            });
          } else {
            // console.error(`error fetching directions ${result}`);
            alert(`Directions request returned no results.`);
          }
        }
      );
    }

    console.log(this.state.addNewAutoComplete);
  };

  handleChangeLocationWithLatLng = async (index, e) => {
    const latRegex = /^[-]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
    const lngRegex = /^[-]?((1[0-7]|[1-9])?\d(\.\d+)?|180(\.0+)?)$/;

    const val = e.target.value;
    let locations = val.split(",");

    if (locations[0]) {
      const lat = locations[0];
      const lng = locations[1];
      if (latRegex.test(lat) && lngRegex.test(lng)) {
        const a = this.state.finalArray;
        a[index]["nickName"] = "";
        a[index]["lat"] = parseInt(lat);
        a[index]["lng"] = parseInt(lng);

        if (index + 1 == this.state.addNewAutoComplete.length) {
          a.push({ lat: "", lng: "", nickName: "" });
          let addNewAutoComplete = this.state.addNewAutoComplete;
          addNewAutoComplete.push(1);

          let auto = this.state.autocompleteIds;
          auto.push(index + 1);
          console.log(auto, "auuuu");

          this.setState({
            autocompleteIds: auto,
            addNewAutoComplete: addNewAutoComplete,
          });
          this.autocompleteRefs = this.state.autocompleteIds.reduce(
            (acc, id) => {
              acc[id] = React.createRef();
              return acc;
            },
            {}
          );
        }

        this.setState({
          finalArray: a,
        });
        this.handleMapChanged();
        console.log(this.state.finalArray);
      } else {
        console.log("Invalid lat,lng");
      }
    }
  };

  handleChangeNickName = async (index, e) => {
    const a = this.state.finalArray;
    a[index]["nickName"] = e.target.value.toUpperCase();
    console.log(a);
    console.log(e.target.value);
    this.setState({
      finalArray: a,
    });
  };

  onPlaceChanged1 = (id) => {
    if (!this.autocompleteRefs[id].current) {
      return;
    }
    const place = this.autocompleteRefs[
      id
    ].current.state.autocomplete.getPlace();
    console.log(place);
    const a = this.state.finalArray;
    a[0]["lat"] = place.geometry.location.lat();
    a[0]["lng"] = place.geometry.location.lng();
    a[0]["nickName"] = place.name.toUpperCase();

    if (this.state.addNewAutoComplete.length == 1) {
      const addNewAutoComplete = this.state.addNewAutoComplete;
      addNewAutoComplete.push(1);
      this.setState({
        addNewAutoComplete: addNewAutoComplete,
      });
    } else {
      if (a[0]["lat"] != "" && a[1]["lat"] != "") {
        this.handleMapChanged();
      }
    }

    this.setState({
      finalArray: a,
    });
  };

  onPlaceChanged = (id) => {
    if (!this.autocompleteRefs[id].current) {
      return;
    }
    const place = this.autocompleteRefs[
      id
    ].current.state.autocomplete.getPlace();
    console.log(place);
    let a = this.state.finalArray;
    a[id]["lat"] = place.geometry.location.lat();
    a[id]["lng"] = place.geometry.location.lng();
    a[id]["nickName"] = place.name.toUpperCase();

    const addNewAutoComplete = this.state.addNewAutoComplete;
    console.log(addNewAutoComplete, "autocompjljj");
    if (id + 1 == addNewAutoComplete.length) {
      a.push({ lat: "", lng: "", nickName: "" });
      addNewAutoComplete.push(1);

      let auto = this.state.autocompleteIds;
      auto.push(id + 1);
      console.log(auto, "auuuu");

      this.setState({
        autocompleteIds: auto,
        addNewAutoComplete: addNewAutoComplete,
      });
      this.autocompleteRefs = this.state.autocompleteIds.reduce((acc, id) => {
        acc[id] = React.createRef();
        return acc;
      }, {});
    }

    this.setState({
      finalArray: a,
    });
    this.handleMapChanged();

    // const a = this.state.addNewAutoComplete;
    // a.push(ob);
    // this.setState({
    //   addNewAutoComplete: a,
    // });
    // var latitude = ob.geometry.location.lat();
    // var longitude = ob.geometry.location.lng();
    // console.log(latitude, 'lat');
    // this.handleMapChanged(latitude,);
  };

  onLoad = (autocomplete) => {
    if (window.google && window.google.maps) {
      this.autocomplete = autocomplete;
    } else {
      setTimeout(this.loadMap, 100);
    }
  };

  loadMap = () => {
    // Check if the google.maps namespace is available
    if (window.google && window.google.maps) {
      this.setState({
        isLoad: true,
      });
      const directionsService = new window.google.maps.DirectionsService();

      // Create a new DirectionsService instance
      //   directionsService = new window.google.maps.DirectionsService();
      // Call a function to use the DirectionsService instance
      //   this.calculateRoute();

      /*global google*/
      // const d = new window.google.maps.LatLng('37.7683909618184', '-122.51089453697205')
      // const origin = 'San Francisco, CA';
      // const destination = 'Los Angeles, CA';

      // directionsService.route({
      //   origin: origin,
      //   destination: destination,
      //   waypoints: [
      //     { location: "36.156400, -115.166191" },
      //     // { location: "Monterey" },
      //   ],
      //   travelMode: window.google.maps.TravelMode.DRIVING,
      // }, (result, status) => {
      //   if (status === window.google.maps.DirectionsStatus.OK) {
      //     console.log(result, 'fsdjk');
      //     var legs = result.routes[0].legs;
      //     var totalDistance = 0;
      //     var totalDuration = 0;
      //     for (var i = 0; i < legs.length; ++i) {
      //       totalDistance += legs[i].distance.value;
      //       totalDuration += legs[i].duration.value;
      //     }
      //     console.log((totalDistance / 1000).toFixed(2), ' KMS');
      //     this.setState({
      //       directions: result
      //     });
      //   } else {
      //     console.error(`error fetching directions ${result}`);
      //   }
      // });
    } else {
      // Retry loading the map if the namespace is not available
      setTimeout(this.loadMap, 100);
    }
  };

  changeLocation = async (event) => {
    this.setState({
      unique_segments: [],
      unique_clients: [],
      ClientName: "",
      CCxrefID: "",
    });
    event.preventDefault();
    await this.setState({ Locationid: event.target.value });
    let { Locationid } = this.state;
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: Locationid,
        clientName: "",
      })
      .then(async (res) => {
        await this.setState({
          unique_clients: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  changeClient = async (event) => {
    event.preventDefault();
    await this.setState({
      unique_segments: [],
      ClientName: event.target.value,
      CCxrefID: "",
    });
    let { ClientName, Locationid } = this.state;
    await axios
      .post(`${Config.hostName}/unique/segments`, {
        locationId: Locationid,
        clientName: ClientName,
      })
      .then(async (res) => {
        await this.setState({
          unique_segments: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  getClientRateCard = async (e) => {
    await this.setState({
      ccxrefID: e.target.value,
    });
    await axios
      .post(`${Config.hostName}/cm/getClientRateCard`, {
        ccxrefID: e.target.value,
      })
      .then(
        async (res) =>
          await this.setState({
            rowData: res.data.data,
          })
      )
      .catch((err) => {
        console.log(console.log(err));
      });
  };

  changeFormLocation = async (event) => {
    this.setState({
      unique_form_segments: [],
      unique_form_clients: [],
      form_ClientName: "",
      form_CCxrefID: "",
      segmentName: "",
      routeCode: "",
      unique_routes: [],
    });
    // event.preventDefault();
    await this.setState({ form_Locationid: event });
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: this.state.form_Locationid,
        clientName: "",
      })
      .then(async (res) => {
        await this.setState({
          unique_form_clients: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  changeFormClient = async (event) => {
    await this.setState({
      unique_form_segments: [],
      form_ClientName: event,
      form_CCxrefID: "",
      segmentName: "",
      routeCode: "",
      unique_routes: [],
    });
    let { form_ClientName, form_Locationid } = this.state;
    await axios
      .post(`${Config.hostName}/unique/segments`, {
        locationId: form_Locationid,
        clientName: form_ClientName,
      })
      .then(async (res) => {
        await this.setState({
          unique_form_segments: res.data.data,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeSegment = async (e) => {
    let segmentName = "";
    let ccxref = e;
    await this.state.unique_form_segments.map((e, key) => {
      if (e.CCxrefID == ccxref) {
        segmentName = e.Segment;
      }
    });

    await this.setState({
      segmentName: segmentName,
      form_CCxrefID: e,
      routeCode: "",
      unique_routes: [],
    });

    await axios
      .post(`${Config.hostName}/route-master/get-route-code`, {
        locationId: this.state.form_Locationid,
        clientName: this.state.form_ClientName,
        segment: this.state.form_CCxrefID,
        serviceType: this.state.ServiceType,
      })
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_routes: res.data.data,
            routeCode: "",
          });
        } else {
          await this.setState({
            unique_routes: [],
            routeCode: "",
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeServiceType = async (e) => {
    if (this.state.form_CCxrefID != "") {
      this.setState({
        ServiceType: e.target.value,
      });

      await axios
        .post(`${Config.hostName}/route-master/get-route-code`, {
          locationId: this.state.form_Locationid,
          clientName: this.state.form_ClientName,
          segment: this.state.form_CCxrefID,
          serviceType: e.target.value,
        })
        .then(async (res) => {
          if (res.data.data.length > 0) {
            await this.setState({
              unique_routes: res.data.data,
              routeCode: "",
            });
          } else {
            await this.setState({
              unique_routes: [],
              routeCode: "",
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      alert("Select Segment First");
    }
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleCloseFullModal = async (e) => {
    await this.setState({
      openFullModal: false,
    });
  };

  handleOpenClientModal = async (method, index) => {
    if (method == "add") {
      await this.setState({
        openFullModal: true,
        form_Locationid: "",
        form_ClientName: "",
        form_CCxrefID: "",
        ClientRatecardID: "",
        routeCode: 0,
        vehicleSize: "",
        chargetype: "",
        ServiceType: "ADHOC",
        servicehrs: "",
        form_clientCost: {
          clientCost: 0,
          fixedkms: 0,
          toll: 0,
          haltingCharges: 0,
          extraTonsCost: 0,
          fixedTons: 0,
          extraHrsCost: 0,
          extraKmsCost: 0,
        },
        target_haltingCharges: 0,
        target_extraTonsCost: 0,
        target_fixedTons: 0,
        target_extraHrsCost: 0,
        target_extraKmsCost: 0,
        target_fixedKms: 0,
        target_fixedCost: 0,
        commision: { 10: "outlined", 15: "outlined" },
        toll: "",
        effStartDate: "",
        effEndDate: "",
        form_CCxrefID: "",
        isCPS: 1,
      });
    } else {
      let data = this.state.rowData[index];
      console.log(data);
      await this.setState({
        openFullModal: true,
        ClientRatecardID: data.ClientRatecardID,
        chargetype: data.ChargeType,
        effEndDate: data.EffectiveEndDate
          ? data.EffectiveEndDate.slice(0, 10)
          : "",
        effStartDate: data.EffectiveStartDate
          ? data.EffectiveStartDate.slice(0, 10)
          : "",
        form_Locationid: data.Locationid,
        servicehrs: data.ServiceHrs,
        ServiceType: data.ServiceType,

        vehicleSize: data.VehicleSize,
        form_clientCost: {
          clientCost: data.FixedCost,
          fixedkms: data.FixedKms,
          toll: data.Toll,
          haltingCharges: data.HaltingCharges,
          extraTonsCost: data.ExtraTonCost,
          fixedTons: data.FixedTons ? data.FixedTons : 0,
          extraHrsCost: data.ExtraHrCost,
          extraKmsCost: data.ExtraKmsCost,
        },
        target_haltingCharges: data.T_HaltingCharges,
        target_extraTonsCost: data.T_ExtraHrCost,
        target_fixedTons: data.T_FixedTons ? data.T_FixedTons : "",
        target_extraHrsCost: data.T_ExtraHrCost,
        target_extraKmsCost: data.T_ExtraKmsCost,
        target_fixedKms: data.T_FixedKms,
        target_fixedCost: data.T_FixedCost,
        commision: { 10: "outlined", 15: "outlined" },
        isCPS: data.isCPS,
      });

      await this.changeFormLocation(this.state.form_Locationid);
      await this.setState({
        form_ClientName: data.ClientName,
      });

      await this.changeFormClient(data.ClientName);

      let segmentName = data.Segment;
      // let CCxrefID = '';
      // await this.state.unique_form_segments.map((e, key) => {
      //   if (e.Segment == segmentName) {
      //     CCxrefID = e.CCxrefID;
      //   }
      // });
      await this.setState({
        form_CCxrefID: segmentName,
      });

      await this.handleChangeSegment(this.state.form_CCxrefID);
      this.setState({
        routeCode: data.RouteID,
      });
    }
  };

  addNewAutoComplete = async (method, index) => {
    const a = this.state.addNewAutoComplete;
    const ob = { lat: "", lng: "" };
    a.push(ob);
    this.setState({
      addNewAutoComplete: a,
    });
  };

  handleChangeClientCost = async (key, e) => {
    const name = key;
    const value = e.target.value;
    const form_clientCost = this.state.form_clientCost;
    form_clientCost[name] = value;
    await this.setState({
      form_clientCost: form_clientCost,
    });

    await this.handleChangeCommision(this.state.form_commision);
  };
  handleChangeCommision = async (com) => {
    let commision = { 10: "outlined", 15: "outlined" };
    if (com == 10) {
      commision = { 10: "", 15: "outlined" };
    } else if (com == 15) {
      commision = { 10: "outlined", 15: "" };
    }

    let com2 = 0.01 * com;
    let target_fixedCost =
      this.state.form_clientCost["clientCost"] -
      this.state.form_clientCost["clientCost"] * com2;
    let target_fixedKms =
      this.state.form_clientCost["fixedkms"] -
      this.state.form_clientCost["fixedkms"] * com2;
    let target_extraKmsCost =
      this.state.form_clientCost["extraKmsCost"] -
      this.state.form_clientCost["extraKmsCost"] * com2;
    let target_extraHrsCost =
      this.state.form_clientCost["extraHrsCost"] -
      this.state.form_clientCost["extraHrsCost"] * com2;
    let target_fixedTons =
      this.state.form_clientCost["fixedTons"] -
      this.state.form_clientCost["fixedTons"] * com2;
    let target_extraTonsCost =
      this.state.form_clientCost["extraTonsCost"] -
      this.state.form_clientCost["extraTonsCost"] * com2;
    let target_haltingCharges =
      this.state.form_clientCost["haltingCharges"] -
      this.state.form_clientCost["haltingCharges"] * com2;

    await this.setState({
      commision: commision,
      form_commision: com,
      target_fixedCost: target_fixedCost.toFixed(0),
      target_fixedKms: target_fixedKms.toFixed(0),
      target_extraKmsCost: target_extraKmsCost.toFixed(0),
      target_extraHrsCost: target_extraHrsCost.toFixed(0),
      target_fixedTons: target_fixedTons.toFixed(0),
      target_extraTonsCost: target_extraTonsCost.toFixed(0),
      target_haltingCharges: target_haltingCharges.toFixed(0),
    });
  };

  handleSubmitCostManagement = async (e) => {
    e.preventDefault();
    if (this.state.form_clientCost["clientCost"] != 0) {
      let CCxrefID = '';
      await this.state.unique_form_segments.map((e, key) => {
        if (e.Segment == this.state.form_CCxrefID) {
          CCxrefID = e.CCxrefID;
        }
      });
      let jdata = `{"userID":"${this.state.userID}","RouteID":"${this.state.routeCode}","VehicleSize":"${this.state.vehicleSize}","ChargeType":"${this.state.chargetype}","ServiceType":"${this.state.ServiceType}","ServiceHrs":"${this.state.servicehrs}","FixedCost":"${this.state.form_clientCost["clientCost"]}","FixedKms":"${this.state.form_clientCost["fixedkms"]}","ExtraKmsCost":"${this.state.form_clientCost["extraKmsCost"]}","ExtraHrCost":"${this.state.form_clientCost["extraHrsCost"]}","FixedTons":"${this.state.form_clientCost["fixedTons"]}","ExtraTonCost":"${this.state.form_clientCost["extraTonsCost"]}","HaltingCharges":"${this.state.form_clientCost["haltingCharges"]}","T_FixedCost":"${this.state.target_fixedCost}","T_FixedKms":"${this.state.target_fixedKms}","T_ExtraKmsCost":"${this.state.target_extraKmsCost}","T_ExtraHrCost":"${this.state.target_extraHrsCost}","T_FixedTons":"${this.state.target_fixedTons}","T_ExtraTonCost":"${this.state.target_extraTonsCost}","T_HaltingCharges":"${this.state.target_haltingCharges}","Toll":"${this.state.toll}","EffectiveStartDate":"${this.state.effStartDate}","EffectiveEndDate":"${this.state.effEndDate}","ccxrefID":"${CCxrefID}","isCPS":"${this.state.isCPS}"}`;
      // let jdata = `{"RouteID":"${this.state.routeCode}", "userID":"${this.state.userID}"}`;
      await axios
        .post(`${Config.hostName}/cm/manageClientRateCard`, {
          clientRatecardID: `${this.state.ClientRatecardID}`,
          jdata: jdata,
          userid: `${this.state.userID}`,
        })
        .then(async (res) => {
          if (res.data.success == false) {
            alert(res.data.message);
          } else {
            if (this.state.ClientRatecardID != "") {
              alert("Updated Successfully");
            } else {
              alert("Added");
            }
            await this.getClientRateCard2();
            await this.setState({
              openFullModal: false,
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      alert('Fixed Cost cannot be 0');
    }

  };

  handleSubmitRouteManagement = async (e) => {
    e.preventDefault();
    let jdata = `{"totalKms":${this.state.estdistance}}`;

    await axios
      .post(`${Config.hostName}/route-master/addRoutemasterNew`, {
        Client: this.state.form_ClientName,
        Segment: this.state.form_CCxrefID,
        Locationid: this.state.form_Locationid,
        CRouteCode: this.state.clientRouteCode,
        ServiceType: this.state.ServiceType,
        Startingpt: this.state.startPoint,
        Endingpt: this.state.endPoint,
        Lane: this.state.clientRouteCode,
        jdata: jdata,
      })
      .then(async (res) => {
        console.log(res);
        if (res.data.success == false) {
          alert(res.data.message);
        } else {
          alert("Added");
          await this.setState({
            openFullModalRoute: false,
          });

          await axios
            .post(`${Config.hostName}/route-master/get-route-code`, {
              locationId: this.state.form_Locationid,
              clientName: this.state.form_ClientName,
              segment: this.state.form_CCxrefID,
              serviceType: this.state.ServiceType,
            })
            .then(async (res) => {
              if (res.data.data.length > 0) {
                await this.setState({
                  unique_routes: res.data.data,
                  routeCode: "",
                });
              } else {
                await this.setState({
                  unique_routes: [],
                  routeCode: "",
                });
              }
            })
            .catch((err) => {
              alert(err);
            });
        }

        console.log(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleSubmitAddNewRoute = async (e) => {
    const finalArray = this.state.finalArray;
    let lane = "";
    // {"destin":"JG4H+Q8F, Gosaiguda, Telangana 501401, India","destinlnl":"17.60626,78.530115","dist":"618128"}
    let destin = [];
    finalArray.map((e, key) => {
      if (key != 0 && key < finalArray.length - 1) {
        lane = lane + "-" + e.nickName;

        destin.push({ destin: e.nickName, destinlnl: e.lat, dist: e.lng });
      } else {
        if (key == 0) {
          lane = e.nickName;
        }
      }
    });
    let distances = this.state.distances;

    let jdata = `{"source":"${finalArray[0].nickName}","srclnl":"${finalArray[0].lat
      },${finalArray[0].lng}","destins":${JSON.stringify(destin)},"totalKms":${this.state.distance
      },"totalStops":${this.state.addNewAutoComplete.length - 1}}`;
    console.log(finalArray[finalArray.length - 2].nickName);
    await axios
      .post(`${Config.hostName}/route-master/addRoutemasterNew`, {
        Client: this.state.form_ClientName,
        Segment: this.state.form_CCxrefID,
        Locationid: this.state.form_Locationid,
        CRouteCode: "",
        ServiceType: this.state.ServiceType,
        Startingpt: finalArray[0].nickName,
        Endingpt: finalArray[finalArray.length - 2].nickName,
        Lane: lane,
        jdata: jdata,
      })
      .then(async (res) => {
        console.log(res);
        if (res.data.success == false) {
          alert(res.data.message);
        } else {
          alert("Added");
          await this.setState({
            openFullModalRoute: false,
          });

          await axios
            .post(`${Config.hostName}/route-master/get-route-code`, {
              locationId: this.state.form_Locationid,
              clientName: this.state.form_ClientName,
              segment: this.state.form_CCxrefID,
              serviceType: this.state.ServiceType,
            })
            .then(async (res) => {
              if (res.data.data.length > 0) {
                await this.setState({
                  unique_routes: res.data.data,
                  routeCode: "",
                });
              } else {
                await this.setState({
                  unique_routes: [],
                  routeCode: "",
                });
              }
            })
            .catch((err) => {
              alert(err);
            });
        }

        console.log(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeRouteCode = async (e) => {
    if (e.target.value != "add") {
      this.setState({ routeCode: e.target.value });
    } else {
      if (this.state.form_CCxrefID != "") {
        this.setState({
          isLoad: false,
        });

        this.setState({
          openFullModalRoute: true,
          directions: null,
          distances: [],
          distance: 0,
          locations: [],
          addNewAutoComplete: [1],
          autocompleteIds: [0, 1],
          finalArray: [
            { lat: "", lng: "", nickName: "" },
            { lat: "", lng: "", nickName: "" },
          ],
        });

        window.addEventListener("load", this.loadMap);
        this.autocompleteRefs = this.state.autocompleteIds.reduce((acc, id) => {
          acc[id] = React.createRef();
          return acc;
        }, {});

        setInterval(() => {
          this.setState({
            isLoad: true,
          });
        }, 2000);
      } else {
        alert("Select Segment First");
      }
    }
  };

  handleCloseFullModalRoute = async (e) => {
    this.setState({ openFullModalRoute: false });
  };

  handleRemove = (index) => {
    let locationArray = this.state.addNewAutoComplete;
    let finalArray = this.state.finalArray;
    let auto = this.state.autocompleteIds;

    if (locationArray.length == 0) {
      alert("You have removed all destination locations.");
    }

    locationArray.splice(index, 1);
    finalArray.splice(index, 1);
    auto.pop();
    console.log(locationArray, finalArray, auto);
    this.setState({
      addNewAutoComplete: locationArray,
      finalArray: finalArray,
      auto,
      auto,
    });
    this.autocompleteRefs = this.state.autocompleteIds.reduce((acc, id) => {
      acc[id] = React.createRef();
      return acc;
    }, {});

    this.handleMapChanged();
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  render() {
    let style = this.state.style;
    const { markers, distances } = this.state;

    return (
      <div>
        <div id="cover-spin" style={style}></div>

        <div id="cover-spin" style={this.state.style}></div>

        <Grid pt={3} container spacing={1}>
          {/* <Grid item md={3} className=" ">
                <FormControl
                  sx={{ m: 1 }}
                  style={{ color: "#666666" }}
                  fullWidth
                >
                  <InputLabel id="viewInvoiceBilling">
                    Select Location
                  </InputLabel>
                  <Select
                    labelId="viewInvoiceBilling"
                    id="viewInvoiceBilling2"
                    value={this.state.Locationid}
                    onChange={this.changeLocation}
                    label="Select Location"
                    style={{ color: "#666666", height: "48px" }}
                  >
                    <MenuItem disabled>
                      <em>Select Location</em>
                    </MenuItem>
                    {this.state.unique_locationids.map((eachItem, key) => (
                      <MenuItem value={eachItem.Locationid} key={key}>
                        {eachItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

          {/* <Grid item md={3} className=" ">
                <FormControl
                  sx={{ m: 1 }}
                  style={{
                    color: "#666666",
                    height: "48px",
                    marginLeft: "15px",
                  }}
                  fullWidth
                >
                  <InputLabel id="viewInvoiceMonth">Select Client</InputLabel>
                  <Select
                    labelId="viewInvoiceMonth"
                    id="viewInvoiceMonth2"
                    value={this.state.ClientName}
                    onChange={this.changeClient}
                    label="Select Client"
                    displayEmpty
                    style={{ color: "#666666", height: "48px" }}
                  >
                    <MenuItem disabled>
                      <em>Select Client</em>
                    </MenuItem>

                    {this.state.unique_clients.map((eachItem, key) => (
                      <MenuItem value={eachItem.ClientName} key={key}>
                        {eachItem.ClientName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

          {/* <Grid item md={3} className=" ">
                <FormControl
                  sx={{ m: 1 }}
                  style={{
                    color: "#666666",
                    height: "48px",
                    marginLeft: "15px",
                  }}
                  fullWidth
                >
                  <InputLabel id="v">Select Segment</InputLabel>
                  <Select
                    labelId="v"
                    id="v2"
                    value={this.state.CCxrefID}
                    onChange={(e) => this.getClientRateCard(e)}
                    label="Select Segment"
                    style={{ color: "#666666", height: "48px" }}
                  >
                    <MenuItem disabled>
                      <em>Select Segment</em>
                    </MenuItem>

                    {this.state.unique_segments.map((eachItem, key) => (
                      <MenuItem value={eachItem.CCxrefID} key={key}>
                        {eachItem.Segment}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

          {/* <Grid item md={3} className=" ">
                  <Button
                    variant="contained"
                    sx={{
                      marginLeft: "8%",
                      marginTop: "5%",
                    }}
                  >
                    Add New
                  </Button>
                </Grid> */}
          <Grid item md={9}></Grid>
          <Grid style={{ float: "right", textAlign: "right" }} item md={3}>
            <Button
              onClick={() => this.handleOpenClientModal("add", "")}
              size="large"
              variant="contained"
              style={{
                marginTop: "14px",
                background: "rgb(234 67 68)",
                color: "white",
              }}
              endIcon={<PlusIcon />}
            >
              ADD
            </Button>
          </Grid>
        </Grid>

        <div>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="basic tabs example"
          >
            {this.state.isClientRate == 1 && (
              <Tab
                label="Target/Client Cost"
                {...a11yProps(0)}
                sx={{
                  backgroundColor: "#fff",
                  border: "0.5px solid silver",
                  borderTopLeftRadius: 10,
                }}
              />
            )}

            {this.state.isVendorRate == 1 && (
              <Tab
                label="Vendor Cost"
                {...a11yProps(1)}
                sx={{
                  backgroundColor: "#fff",
                  border: "0.5px solid silver",
                  borderTopRightRadius: 10,
                }}
              />
            )}
          </Tabs>
          <TabPanel value={this.state.value} index={0}>
            <Box
              sx={{
                paddingBottom: "8%",
              }}
            >
              <MUIDataTable
                title={"Target/Client Cost"}
                data={this.state.rowData}
                columns={this.state.columns}
                options={options}
                className="client-cost-table"
              />
            </Box>
          </TabPanel>
          <TabPanel value={this.state.value} index={1}>
            <Box
              sx={{
                marginBottom: "10%",
              }}
            >
              <Box
                sx={{
                  paddingBottom: "8%",
                }}
              >
                <MUIDataTable
                  title={"Vendor Cost"}
                  data={this.state.vendorRowData}
                  columns={this.state.vendorColumn}
                  options={options}
                  className="vendor-cost-table"
                />
              </Box>
            </Box>
          </TabPanel>
        </div>
        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseFullModal}
          open={this.state.openFullModal}
        // open={true}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "inherit",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseFullModal}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseFullModal}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            <form
              style={{ width: "100%" }}
              onSubmit={(e) => this.handleSubmitCostManagement(e)}
              method="POST"
            >
              <Typography
                variant="h4"
                style={{
                  textAlign: "center",
                  marginTop: "2%",
                }}
              >
                Manage Client Rate Card
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{ marginTop: "10px", padding: "24px" }}
                >
                  <Paper
                    elevation={3}
                    style={{ padding: "20px", height: "100%" }}
                  >
                    <Typography variant="h5">Project Details:</Typography>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{ marginLeft: "0px", width: "60%" }}
                    >
                      <InputLabel id="viewInvoiceBilling">
                        Select Location
                      </InputLabel>
                      <Select
                        labelId="viewInvoiceBilling"
                        id="viewInvoiceBilling2"
                        value={this.state.form_Locationid}
                        onChange={(e) =>
                          this.changeFormLocation(e.target.value)
                        }
                        label="Select Location"
                        style={{ color: "#666666", height: "48px" }}
                      required
                      >
                        <MenuItem disabled>
                          <em>Select Location</em>
                        </MenuItem>
                        {this.state.unique_locationids.map((eachItem, key) => (
                          <MenuItem value={eachItem.Locationid} key={key}>
                            {eachItem.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{ marginLeft: "0px", width: "60%" }}
                    >
                      <InputLabel id="viewInvoiceMonth">
                        Select Client
                      </InputLabel>
                      <Select
                        labelId="viewInvoiceMonth"
                        id="viewInvoiceMonth2"
                        value={this.state.form_ClientName}
                        onChange={(e) => this.changeFormClient(e.target.value)}
                        label="Select Client"
                        displayEmpty
                        style={{ color: "#666666", height: "48px" }}
                      required
                      >
                        <MenuItem disabled>
                          <em>Select Client</em>
                        </MenuItem>

                        {this.state.unique_form_clients.map((eachItem, key) => (
                          <MenuItem value={eachItem.ClientName} key={key}>
                            {eachItem.ClientName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{ marginLeft: "0px", width: "60%" }}
                    >
                      <InputLabel id="viewSegment">Select Segment</InputLabel>
                      <Select
                        labelId="viewSegment"
                        id="viewSegment2"
                        value={this.state.form_CCxrefID}
                        onChange={(e) =>
                          this.handleChangeSegment(e.target.value)
                        }
                        label="Select Segment"
                        style={{ color: "#666666", height: "48px" }}
                      required
                      >
                        <MenuItem disabled>
                          <em>Select Segment</em>
                        </MenuItem>

                        {this.state.unique_form_segments.map(
                          (eachItem, key) => (
                            <MenuItem value={eachItem.Segment} key={key}>
                              {eachItem.Segment}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{ marginLeft: "0px", width: "60%" }}
                    >
                      <InputLabel id="location">Service Type</InputLabel>
                      <Select
                        labelId="Location"
                        id="State2"
                        label="Location"
                        required
                        value={this.state.ServiceType}
                        onChange={(e) => this.handleChangeServiceType(e)}
                      >
                        <MenuItem value="ADHOC">ADHOC</MenuItem>
                        <MenuItem value="REGULAR">REGULAR</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{ marginLeft: "0px", width: "60%" }}
                    >
                      <InputLabel id="routecode">Route Code</InputLabel>
                      <Select
                        labelId="routecode"
                        id="routecode2"
                        label="RouteCode"
                        required
                        value={this.state.routeCode}
                        onChange={(e) => this.handleChangeRouteCode(e)}
                      >
                        <MenuItem value="">
                          <em>Select RouteCode</em>
                        </MenuItem>
                        {this.state.unique_routes.map((eachItem, key) => (
                          <MenuItem value={eachItem.Routeid} key={key}>
                            {eachItem.Lane}
                          </MenuItem>
                        ))}
                        {this.state.unique_routes != [] && (
                          <MenuItem value="add">
                            <b> Add New Route </b>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ marginTop: "10px", padding: "24px" }}
                >
                  <Paper
                    elevation={3}
                    style={{ padding: "20px", height: "100%" }}
                  >
                    <Typography variant="h5">Service Details:</Typography>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{
                        marginLeft: "0px",
                        width: "60%",
                        marginTop: "4%",
                      }}
                    >
                      <InputLabel id="vehiclesize">Vehicle Size</InputLabel>
                      <Select
                        labelId="vehiclesize"
                        id="vehiclesize2"
                        label="Vehicle Size"
                        required
                        value={this.state.vehicleSize}
                        onChange={(e) =>
                          this.setState({ vehicleSize: e.target.value })
                        }
                      >
                        <MenuItem value="">
                          <em>Select Vehicle Size</em>
                        </MenuItem>

                        {this.state.unique_vehicleSizes.map((eachItem, key) => (
                          <MenuItem value={eachItem.vehicleSize} key={key}>
                            {eachItem.vehicleSize}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{
                        m: 1,
                        marginLeft: "0px",
                        width: "60%",
                        marginTop: "4%",
                      }}
                    >
                      <InputLabel id="servicehr">Service Hrs</InputLabel>
                      <Select
                        labelId="servicehr"
                        id="servicehr2"
                        label="Service Hrs"
                        required
                        value={this.state.servicehrs}
                        onChange={(e) =>
                          this.setState({ servicehrs: e.target.value })
                        }
                      >
                        <MenuItem disabled>
                          <em>Select Service Hrs</em>
                        </MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={48}>48</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{
                        marginLeft: "0px",
                        width: "60%",
                        marginTop: "4%",
                      }}
                    >
                      <InputLabel id="chargetype">Charge Type</InputLabel>
                      <Select
                        labelId="chargetype"
                        id="chargetype2"
                        label="Charge Type"
                        required
                        value={this.state.chargetype}
                        onChange={(e) =>
                          this.setState({ chargetype: e.target.value })
                        }
                      >
                        <MenuItem value="">
                          <em>Charge Type</em>
                        </MenuItem>
                        <MenuItem value={"FOZ"}>FOZ</MenuItem>
                        <MenuItem value={"FWZ"}>FWZ</MenuItem>
                        <MenuItem value={"FAT"}>FAT</MenuItem>
                        <MenuItem value={"FBK"}>FAK</MenuItem>
                        <MenuItem value={"FBT"}>FATON</MenuItem>
                        <MenuItem value={"FBS"}>FAS</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{
                        marginLeft: "0px",
                        width: "60%",
                        marginTop: "4%",
                      }}
                    >
                      Effective Start Date:
                      <TextField
                        id="standard-basic"
                        labelId="start-date"
                        placeholder="Effective Start Date"
                        variant="standard"
                        type="date"
                        required
                        value={this.state.effStartDate}
                        onChange={(e) =>
                          this.setState({ effStartDate: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      sx={{ m: 1 }}
                      style={{
                        marginLeft: "0px",
                        width: "60%",
                        marginTop: "4%",
                      }}
                    >
                      Effective End Date:
                      <TextField
                        id="standard-basic"
                        labelId="end-date"
                        placeholder="Effective End Date"
                        variant="standard"
                        type="date"
                        required
                        value={this.state.effEndDate}
                        onChange={(e) =>
                          this.setState({ effEndDate: e.target.value })
                        }
                      />
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>

              <Box
                style={{
                  padding: 30,
                }}
              >
                <Paper elevation={3}>
                  <div
                    style={{
                      padding: 20,
                    }}
                  >
                    <Typography variant="h5">Client Cost :</Typography>
                    <Grid pt={1} container>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="clientCost"
                            label="Fixed Cost"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.form_clientCost["clientCost"]}
                            onChange={(e) =>
                              this.handleChangeClientCost("clientCost", e)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="fixedkms"
                            label="Fixed Kms"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.form_clientCost["fixedkms"]}
                            onChange={(e) =>
                              this.handleChangeClientCost("fixedkms", e)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="extraKmsCost"
                            label="Extra Kms Cost"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.form_clientCost["extraKmsCost"]}
                            onChange={(e) =>
                              this.handleChangeClientCost("extraKmsCost", e)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="extraHrsCost"
                            label="Extra Hrs Cost"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.form_clientCost["extraHrsCost"]}
                            onChange={(e) =>
                              this.handleChangeClientCost("extraHrsCost", e)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="fixedTons"
                            label="Fixed Tons"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.form_clientCost["fixedTons"]}
                            onChange={(e) =>
                              this.handleChangeClientCost("fixedTons", e)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="extraTonsCost"
                            label="Extra Tons Cost"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.form_clientCost["extraTonsCost"]}
                            onChange={(e) =>
                              this.handleChangeClientCost("extraTonsCost", e)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="haltingCharges"
                            label="Halting Charges"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.form_clientCost["haltingCharges"]}
                            onChange={(e) =>
                              this.handleChangeClientCost("haltingCharges", e)
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="toll"
                            label="Toll"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.toll}
                            onChange={(e) =>
                              this.setState({ toll: e.target.value })
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      padding: 20,
                    }}
                  >
                    <Grid pt={1} container>
                      <Grid item md={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.isCPS}
                              onChange={(e) =>
                                this.setState({ isCPS: !this.state.isCPS })
                              }
                            />
                          }
                          label="isCPS"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Box>

              <Box
                style={{
                  padding: 30,
                }}
              >
                <Paper elevation={3}>
                  <div
                    style={{
                      padding: 20,
                      paddingTop: "32px",
                    }}
                  >
                    <div className="row">
                      <div className="col-2">
                        <Typography variant="h5">Target Cost :</Typography>
                      </div>

                      <div className="col-2">
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{
                            marginLeft: "0px",
                            width: "20ch",
                            marginTop: "-20px",
                          }}
                        >
                          <TextField
                            id="standard-basic"
                            label="Profit Margin"
                            variant="standard"
                            type="number"
                            value={this.state.form_commision}
                            onChange={(e) =>
                              this.handleChangeCommision(e.target.value)
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="col-1">
                        <Chip
                          label="10 %"
                          color="primary"
                          onClick={(e) => this.handleChangeCommision(10)}
                          variant={this.state.commision[10]}
                        />
                      </div>

                      <div className="col-1">
                        <Chip
                          label="15 %"
                          color="primary"
                          onClick={(e) => this.handleChangeCommision(15)}
                          variant={this.state.commision[15]}
                        />
                      </div>
                    </div>

                    <Grid pt={1} container>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="target_fixedCost"
                            label="Target Fixed Cost"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.target_fixedCost}
                            onChange={(e) =>
                              this.setState({
                                target_fixedCost: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="target_fixedKms"
                            label="Target Fixed Kms"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.target_fixedKms}
                            onChange={(e) =>
                              this.setState({ target_fixedKms: e.target.value })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="target_extraKmsCost"
                            label="Target Extra Kms Cost"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.target_extraKmsCost}
                            onChange={(e) =>
                              this.setState({
                                target_extraKmsCost: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="target_extraHrsCost"
                            label="Target Extra Hrs Cost"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.target_extraHrsCost}
                            onChange={(e) =>
                              this.setState({
                                target_extraHrsCost: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="target_fixedTons"
                            label="Target Fixed Tons"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.target_fixedTons}
                            onChange={(e) =>
                              this.setState({
                                target_fixedTons: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="target_extraTonsCost"
                            label="Target Extra Ton Cost"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.target_extraTonsCost}
                            onChange={(e) =>
                              this.setState({
                                target_extraTonsCost: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          sx={{ m: 1 }}
                          style={{ marginLeft: "0px", width: "30ch" }}
                        >
                          <TextField
                            id="target_haltingCharges"
                            label="Target Halting Charges"
                            variant="standard"
                            type="number"
                            required
                            value={this.state.target_haltingCharges}
                            onChange={(e) =>
                              this.setState({
                                target_haltingCharges: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Box>

              <div
                style={{
                  marginTop: "3%",
                  marginBottom: "10%",
                }}
              >
                <Grid
                  mt={2}
                  item
                  md={3}
                  style={{ marginBottom: "27px", textAlign: "center" }}
                >
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    style={{
                      marginBottom: "18px",
                      marginTop: "-5px",
                      height: "49px",
                      background: "rgb(234 67 68)",
                      color: "white",
                    }}
                    endIcon={<SendIcon />}
                  >
                    Update Rate Card
                  </Button>
                </Grid>
              </div>
            </form>
          </Container>
        </Dialog>

        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseFullModalRoute}
          open={this.state.openFullModalRoute}
        // open={true}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "inherit",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseFullModalRoute}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseFullModalRoute}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            {/* <form
              style={{ width: "100%" }}
              onSubmit={(e) => this.handleSubmitCostManagement(e)}
              method="POST"
            > */}
            <div
              style={{
                padding: 0,
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={5}>
                  <div
                    style={{
                      padding: 20,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#FF4040",
                        padding: 1,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: "center",
                          color: "#fff",
                        }}
                      >
                        Add New Route
                      </Typography>
                    </Box>

                    {/* <ul>
                        {distances.map((distance, index) => (
                          <li key={index}>
                            Distance {index + 1}: {distance.toFixed(2)} km
                          </li>
                        ))}
                      </ul> */}
                    <Grid pt={1} style={{ paddingLeft: "38px" }} container>
                      <Grid item md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.isClientRoute}
                              onChange={(e) =>
                                this.setState({
                                  isClientRoute: !this.state.isClientRoute,
                                })
                              }
                            />
                          }
                          label="Enter Client Route Code"
                        />
                      </Grid>
                    </Grid>
                    {this.state.isClientRoute ? (
                      <Grid container style={{ paddingLeft: "38px" }}>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={(e) => this.handleSubmitRouteManagement(e)}
                        >
                          <Grid item md={12}>
                            <FormControl
                              variant="standard"
                              fullWidth={true}
                              sx={{ m: 1 }}
                              style={{ marginLeft: "0px", width: "30ch" }}
                            >
                              <TextField
                                id="StartingPoint"
                                label="Starting Point"
                                variant="standard"
                                type="text"
                                required
                                value={this.state.startPoint}
                                onChange={(e) =>
                                  this.setState({ startPoint: e.target.value })
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={12}>
                            <FormControl
                              variant="standard"
                              fullWidth={true}
                              sx={{ m: 1 }}
                              style={{ marginLeft: "0px", width: "30ch" }}
                            >
                              <TextField
                                id="endPoint"
                                label="Ending Point"
                                variant="standard"
                                type="text"
                                required
                                value={this.state.endPoint}
                                onChange={(e) =>
                                  this.setState({ endPoint: e.target.value })
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={12}>
                            <FormControl
                              variant="standard"
                              fullWidth={true}
                              sx={{ m: 1 }}
                              style={{ marginLeft: "0px", width: "30ch" }}
                            >
                              <TextField
                                id="ClientRoute"
                                label="Client Route Code"
                                variant="standard"
                                type="text"
                                required
                                value={this.state.clientRouteCode}
                                onChange={(e) =>
                                  this.setState({
                                    clientRouteCode: e.target.value,
                                  })
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <FormControl
                              variant="standard"
                              fullWidth={true}
                              sx={{ m: 1 }}
                              style={{ marginLeft: "0px", width: "30ch" }}
                            >
                              <TextField
                                id="clientCost2"
                                label="Total Distance (kms)"
                                variant="standard"
                                type="number"
                                required
                                value={this.state.estdistance}
                                onChange={(e) =>
                                  this.setState({ estdistance: e.target.value })
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid style={{ text: "center" }} item md={12}>
                            <Button
                              variant="contained"
                              sx={{
                                display: "block",
                                margin: "auto",
                                width: "50%",
                                backgroundColor: "#FF4040",
                              }}
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Grid>
                        </form>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div>
                              <MyLocationIcon
                                sx={{
                                  color: "#5251FA",
                                  marginTop: "45%",
                                }}
                              />
                            </div>
                            <div>
                              {this.state.isLoad == true && (
                                <>
                                  <Autocomplete
                                    onLoad={(autocomplete) =>
                                      console.log(
                                        `Autocomplete loaded for 0`,
                                        autocomplete
                                      )
                                    }
                                    onPlaceChanged={() =>
                                      this.onPlaceChanged1(0)
                                    }
                                    key={0}
                                    ref={this.autocompleteRefs[0]}
                                    id={0}
                                    fields={[
                                      "address_components",
                                      "geometry",
                                      "name",
                                    ]}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Select Location"
                                      style={{
                                        width: "290%",
                                        height: "50px",
                                        paddingLeft: "10px",
                                        marginLeft: "10%",
                                        backgroundColor: "#F4F4F4",
                                        border: "none",
                                      }}
                                      onChange={(e) =>
                                        this.handleChangeLocationWithLatLng(
                                          0,
                                          e
                                        )
                                      }
                                    />
                                  </Autocomplete>
                                </>
                              )}

                              {this.state.isLoad == true && (
                                <input
                                  type="text"
                                  placeholder="NickName"
                                  required
                                  value={this.state.finalArray[0].nickName}
                                  onChange={(e) =>
                                    this.handleChangeNickName(0, e)
                                  }
                                  style={{
                                    width: "248.5%",
                                    height: "50px",
                                    paddingLeft: "10px",
                                    marginLeft: "51.5%",
                                    backgroundColor: "rgb(244, 244, 244)",
                                    border: "0.5px solid silver",
                                    borderTopColor: "silver",
                                    borderLeftColor: "#F4F4F4",
                                    borderRightColor: "#F4F4F4",
                                    borderBottomColor: "#F4F4F4",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          {this.state.addNewAutoComplete != [] &&
                            this.state.addNewAutoComplete.map((e, key) => (
                              <Grid item xs={12} key={key}>
                                {this.state.isLoad == true && (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "2%",
                                      }}
                                    >
                                      {key != 0 && (
                                        <div>
                                          <LocationOnIcon
                                            sx={{
                                              color: "#FF4040",
                                              marginTop: "45%",
                                            }}
                                          />
                                        </div>
                                      )}
                                      {key != 0 && (
                                        <>
                                          <div
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            <Autocomplete
                                              onLoad={(autocomplete) =>
                                                console.log(
                                                  `Autocomplete loaded for 0`,
                                                  autocomplete
                                                )
                                              }
                                              onPlaceChanged={() =>
                                                this.onPlaceChanged(key)
                                              }
                                              key={key}
                                              ref={this.autocompleteRefs[key]}
                                              id={key}
                                              fields={[
                                                "address_components",
                                                "geometry",
                                                "name",
                                              ]}
                                              options={{ types: ["geocode"] }}
                                            >
                                              <input
                                                type="text"
                                                placeholder="Select Location"
                                                style={{
                                                  width: "99.5%",
                                                  height: "50px",
                                                  paddingLeft: "10px",
                                                  marginLeft: "2.5%",
                                                  backgroundColor: "#F4F4F4",
                                                  border: "none",
                                                }}
                                                onChange={(e) =>
                                                  this.handleChangeLocationWithLatLng(
                                                    key,
                                                    e
                                                  )
                                                }
                                              />
                                            </Autocomplete>
                                            <input
                                              type="text"
                                              placeholder="NickName"
                                              required
                                              style={{
                                                width: "85.5%",
                                                height: "50px",
                                                paddingLeft: "10px",
                                                marginLeft: "16.5%",
                                                backgroundColor: "#F4F4F4",
                                                border: "0.5px solid silver",
                                                borderTopColor: "silver",
                                                borderLeftColor: "#F4F4F4",
                                                borderRightColor: "#F4F4F4",
                                                borderBottomColor: "#F4F4F4",
                                              }}
                                              onChange={(e) =>
                                                this.handleChangeNickName(
                                                  key,
                                                  e
                                                )
                                              }
                                              value={
                                                this.state.finalArray[key]
                                                  .nickName
                                              }
                                            />
                                          </div>

                                          <div>
                                            <Button
                                              onClick={() =>
                                                this.handleRemove(key)
                                              }
                                              disabled={
                                                key == 1 ? true : false
                                              }
                                            >
                                              <CancelOutlinedIcon
                                                sx={{
                                                  color: key == 1 ? "#fff" : "#323232",

                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </Grid>
                            ))}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            marginTop: "3%",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "grey",
                              height: "1px",
                            }}
                          ></div>

                          {this.state.distance == 0 &&
                            this.state.finalArray[0].lat != "" &&
                            this.state.finalArray[1].lat != "" ? (
                            <Grid
                              container
                              sx={{
                                marginTop: "1%",
                              }}
                            >
                              <Grid item m={2} xs={4}>
                                <FormControl
                                  variant="standard"
                                  fullWidth={true}
                                  sx={{ m: 1 }}
                                  style={{ marginLeft: "0px", width: "30ch" }}
                                >
                                  <TextField
                                    id="clientCost2"
                                    label="Total Distance (kms)"
                                    variant="standard"
                                    type="number"
                                    required
                                    value={this.state.estdistance}
                                    onChange={(e) =>
                                      this.setState({
                                        estdistance: e.target.value,
                                      })
                                    }
                                  />
                                </FormControl>

                                {/* <input
                                     type="text"
                                     placeholder="Enter Total Distance"
                                     style={{
                                       width: "210.5%",
                                       height: "50px",
                                       paddingLeft: "10px",
                                       marginLeft: "2.5%",
                                       backgroundColor: "#F4F4F4",
                                       border: "none",
                                     }}
                                     onChange={(e) => this.setState({ distances: e.target.value })}
                                     required
                                   /> */}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid
                              container
                              sx={{
                                marginTop: "1%",
                              }}
                            >
                              <Grid item xs={4}>
                                <Box>
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {" "}
                                    Total Kms : {this.state.distance} km
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box>
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {" "}
                                    Duration : {this.state.distance} hrs
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box>
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {" "}
                                    Number of Stops :{" "}
                                    {this.state.addNewAutoComplete.length - 1}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          )}

                          <Box
                            sx={{
                              marginTop: "5%",
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                display: "block",
                                margin: "auto",
                                width: "50%",
                                backgroundColor: "#FF4040",
                              }}
                              disabled={
                                this.state.finalArray[0].lat == "" &&
                                  this.state.finalArray[1].lat == ""
                                  ? true
                                  : false
                              }
                              onClick={(e) => this.handleSubmitAddNewRoute()}
                            >
                              Submit
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{
                    marginLeft: "-15px",
                  }}
                >
                  <LoadScript
                    googleMapsApiKey={Config.apiKey}
                    libraries={["places"]}
                  >
                    <GoogleMap
                      mapContainerStyle={{
                        height: "800px",
                        width: "100%",
                        marginTop: "2.5%",
                      }}
                      zoom={10}
                      center={this.state.markers[0]}
                    >
                      {/* {markers.map((marker, index) => (
                              <Marker key={index} position={marker} />
                            ))} */}
                      {this.state.directions && (
                        <DirectionsRenderer
                          directions={this.state.directions}
                        />
                      )}
                    </GoogleMap>
                  </LoadScript>
                </Grid>
              </Grid>
            </div>
            {/* </form> */}
          </Container>
        </Dialog>
      </div>
    );
  }
}

export default CostManagement;
