import React, { Component } from "react";
import './TripMaster.css';
import axios from "axios";
import PropTypes from "prop-types";
import {
    Button, FormGroup, Input, Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/loader-spinner.css"
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import XLSX from "xlsx";


class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            modal: false,
            locations: [],
            unique_locationids: [],
            unique_segments: [],
            unique_clients: [],
            anotherModal: false,
            index: null,
            AdvpayDate: new Date(),
            description: "",
            startDate: "",
            endDate: "",
            Locationid: null,
            Segment: null,
            ServiceType: "",
            ClientName: null,
            IsVerified: "",
            IsVerifiedValue: "",
            Trip: "",
            selectedArray: [],
            AdvPay: 0,
            activePage: 1,
            itemsCountPerPage: 30,
            totalItemsCount: 5000,
            pageRangeDisplayed: 5,
            columnDefs: [
                // {
                //     headerName: "Update", field: "Update", minWidth: 130,
                //     cellRendererFramework: (params) => {
                //         // console.log(params);

                //         // console.log(params.node.data);

                //         return <Button
                //             color="primary"
                //             value={params.node.data.TripID}
                //             onClick={this.onSubmit}
                //         >
                //             Update
                //               </Button>

                //     }
                // },
                {
                    headerName: "Trip Id", field: "TripID", sortable: true, filter: true, minWidth: 80
                },
                {
                    headerName: "City", field: "LocationID", sortable: true, filter: true
                },
                {
                    headerName: "Trip Date", field: "TripDate", sortable: true, filter: true
                },
                {
                    headerName: "Vehicle No", field: "Vehiclenumber", sortable: true, filter: true, minWidth: 110
                },
                {
                    headerName: "Vehicle Size", field: "VehicleSize", sortable: true, filter: true, minWidth: 110
                },
                // {
                //     headerName: "Is Processed", field: "IsProcessed", sortable: true, filter: true
                // },
                {
                    headerName: "IsProcessed",
                    field: "IsProcessed", sortable: true, filter: true,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.IsProcessed;
                        const StatusColor = status == "YES" ? "#4caf50" : "red";
                        return (
                            <button type="button" className="btn " style={{ color: `${StatusColor}` }} >{status}</button>
                        )
                    }

                },

                {
                    headerName: "Service Type", field: "ServiceType", sortable: true, filter: true
                },
                {
                    headerName: "Created By", field: "CreatedBy", sortable: true, filter: true
                },
                // {
                //     headerName: "Start ODO", field: "StartODO",
                //     sortable: true, filter: true, minWidth: 200, autoHeight: true,

                //     cellRendererFramework: (params) => {
                //         // console.log(params.data.StartODO);

                //         return (

                //             // <div class="wraper">
                //             //     <div class="zoom_area">
                //             //         <div class="preview">
                //             //             <img src={params.data.StartODO} id="hover-img-zoom" alt="No img " />    </div>
                //             //     </div>
                //             // </div>

                //             // <div className="tiles">
                //             //     <div className="tile" data-scale="1.1" data-image={params.data.StartODO}></div>
                //             // </div>

                //             // <div className="container mt-5 b-5">
                //             //     <div className="row">
                //             //         <img src={params.data.StartODO} id="hover-img-zoom" alt="No img " />
                //             //         {/* <div className="col-md-6"></div> */}
                //             //     </div>
                //             // </div>

                //             <div className="image-zoom">
                //                 <img src={params.data.StartODO} alt="No img " />
                //             </div>
                //         )
                //     }
                // },
                // {
                //     headerName: "ODO End", field: "ODOEnd", sortable: true, filter: true
                // },

                // {
                //     headerName: "End ODO", field: "EndODO", sortable: true, filter: true, minWidth: 200,

                //     cellRendererFramework: (params) => {
                //         // console.log(params.data.EndODO);

                //         return (
                //             <div className="image-zoom">
                //                 <img src={params.data.EndODO} alt="No img " />
                //             </div>
                //         )
                //     }
                // },
                {
                    headerName: "View", field: "View", minWidth: 150, width: 200, maxWidth: 300,
                    cellRendererFramework: (params) => {
                        return <Link to={`/admin/tms/trip-master/show-more/${params.node.data.TripID}`}>
                            <Button

                                color="primary"
                                onClick={() =>
                                    this.setState({
                                        modal: !this.state.modal
                                    })
                                }
                                disabled={params.node.data.IsProcessed == "NO"}
                            >
                                <ViewComfyIcon /> View

                            </Button>
                        </Link>
                    },
                }
            ],

            rowData: [],
            search: false,
            domLayout: "autoHeight",
            uniqueDateRanges: [],
            dateRangeSelected: "",

            tmsExportData: [],
            tripIds: ""

        };
    }

    componentDidMount = () => {
        axios.get(`${Config.hostName}/unique/locations`)
            .then(res => {
                // console.log(res.data);

                this.setState({ unique_locationids: res.data.data });
            })
            .catch(error => {
                console.log(error);
            });
    };

    changeLocation = async event => {
        this.setState({ style: {}, unique_segments: [], unique_clients: [] });
        event.preventDefault();
        await this.setState({ Locationid: event.target.value });
        let { Locationid } = this.state;
        await axios
            .post(`${Config.hostName}/unique/clients`, {
                locationId: Locationid,
                clientName: ""
            })
            .then(res => {
                // console.log(res.data);
                this.setState({
                    unique_clients: res.data.data,
                    style: { display: 'none' }

                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    changeClient = async event => {
        // console.log(event.target.value);
        event.preventDefault();
        await this.setState({ style: {}, unique_segments: [], ClientName: event.target.value });
        let { ClientName, Locationid } = this.state;
        await axios.post(`${Config.hostName}/unique/segments`, {
            locationId: Locationid,
            clientName: ClientName
        })
            .then(res => {
                // console.log(res);
                this.setState({
                    unique_segments: res.data.data,
                    style: { display: 'none' }

                });
            })
            .catch(err => {
                alert(err);
                console.log(err);
            });
        console.log(this.state.unique_segments);
    };

    handlePageChange = async (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        // await this.setState({ activePage: pageNumber, style: {} });
        // await this.componentWillMount();
        console.log(`active page is ${pageNumber}`);
        await this.setState({ activePage: pageNumber, style: {} });
        if (this.state.search === false)
            await this.componentWillMount();
        else
            await this.handleSearchShipment();

    }



    componentWillMount = async () => {
        await this.setState({ search: false })
        let { activePage } = this.state;
        await this.getUniqueDateRanges();
        const result = await axios.get(`${Config.hostName}/tms/details/${activePage}`)
        // console.log(result.data, "data");
        await this.setState({
            locations: result.data.data,
            tmsExportData: result.data.data

        });
        await this.setState({
            style: { display: 'none' },
            rowData: this.state.locations.map(eachItem => {
                return {
                    TripID: eachItem.TripID,
                    LocationID: eachItem.city,
                    TripDate: eachItem.TripDate,
                    ReportingTime: moment(eachItem.ReportingTime).format("YYYY-MM-DD hh:mm"),
                    Vehiclenumber: eachItem.VehicleNumber,
                    // ODOStart: eachItem.ODOStart,
                    // ODOEnd: eachItem.ODOEnd,
                    // StartODO: eachItem.ODOStarturl,
                    // EndODO: eachItem.ODOEndurl,
                    IsProcessed: eachItem.IsProcessed == 1 ? "YES" : "NO",
                    IsVerified: eachItem.IsVerified,
                    VehicleSize: eachItem.VehicleSize,
                    VendorName: eachItem.VendorName,
                    ServiceType: eachItem.ServiceType,
                    CreatedBy: (eachItem.ServiceType === "Regular") ? "Admin" : eachItem.CreatedBy
                }
            })
        });
        // console.log(this.state.locations);
    };

    // handleTripChangeStart = date => {
    //     // console.log(date);

    //     this.setState({
    //         startDate: date
    //     });
    // };

    // handleTripChange = date => {
    //     // console.log(date);

    //     this.setState({
    //         AdvpayDate: date
    //     });

    // };

    // handleTripChangeEnd = date => {
    //     this.setState({
    //         endDate: date
    //     });
    // };

    handleCheckVerify = index => {
        // console.log(index);

        let selectedArray = this.state.locations;
        selectedArray.forEach((item) => {
            item.selected = false
        }
        )
        selectedArray[index].selected = !this.state.locations[index].selected;
        this.setState({
            locations: selectedArray
        });
        // console.log(selectedArray[index]);
        this.setState({
            index: index
        })

    };

    getUniqueDateRanges = async () => {
        await axios
            .get(
                `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
            )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                        startDate: res.data.data.startDate,
                        endDate: res.data.data.endDate
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };

    handleSearchShipment = async () => {
        try {
            console.log(this.state.startDate, this.state.endDate)
            await this.setState({ style: {}, search: true })
            let result = await axios.post(
                `${Config.hostName}/tms/search`,

                {
                    tripStartDate: this.state.startDate || "",
                    tripEndDate: this.state.endDate || "",
                    locationId: this.state.Locationid,
                    clientName: this.state.ClientName,
                    segment: this.state.Segment,
                    pageNumber: this.state.activePage,
                    tripdIds: this.state.tripIds
                }
            );
            console.log(result.data);

            if (result.data.success) {


                await this.setState({
                    style: { display: 'none' },
                    locations: result.data.data,
                    tmsExportData: result.data.data,
                    rowData: result.data.data.map(eachItem => {
                        return {
                            TripID: eachItem.TripID,
                            LocationID: eachItem.city,
                            TripDate: eachItem.TripDate,
                            ReportingTime: moment(eachItem.ReportingTime).format("YYYY-MM-DD hh:mm"),
                            IsProcessed: eachItem.IsProcessed == 1 ? "YES" : "NO",
                            Vehiclenumber: eachItem.VehicleNumber,
                            ServiceType: eachItem.ServiceType,
                            IsVerified: eachItem.IsVerified,
                            VehicleSize: eachItem.VehicleSize,
                            VendorName: eachItem.VendorName,
                            CreatedBy: eachItem.CreatedBy,

                        }
                    })
                });
            }
            else {
                alert(result.data.message)
                await this.setState({
                    style: { display: 'none' },
                    locations: [],
                    tmsExportData: [],

                });
            }
        } catch (e) { }
    };

    handleAdvPay = (index, value) => {
        // console.log(index, value);
        let selectedArray = this.state.locations;

        selectedArray[index].AdvPay = value;
        this.setState({
            locations: selectedArray
        });
        // console.log(selectedArray[index]);


    }

    handleRemarks = (index, value) => {
        // console.log(index, value);
        let selectedArray = this.state.locations;

        selectedArray[index].Remarks = value;
        this.setState({
            locations: selectedArray
        });
        // console.log(selectedArray[index]);

    }

    onSubmit = async e => {
        e.preventDefault();
        // console.log(this.state.index, "index");

        // console.log(e.target.value);
        let data = e.target.value;

        // let result = this.state.locations[this.state.index]

        try {
            await this.setState({ style: {} })
            // eslint-disable-next-line
            const res = await axios.post(`${Config.hostName}/tms/verify`, {

                tripId: data,
                user: this.props.auth.user.name,
                isVerified: 1,
                comments: null,
                advPay: null,
                advPayDate: null
            }).then(async (response) => {
                // console.log(response.data, "response");
                if (response.data.success === false) {
                    this.props.setAlert(response.data.message, "danger");

                }
                else {
                    await this.componentWillMount()
                    await this.props.setAlert(`Approved Trip ${data} `, "success");
                }

            }
            ).catch((error) => {
                console.log(error, 'error');

            }
            )



        } catch (error) {
            const errors = error.response.data.errors;
            if (errors) {
                errors.forEach(error => this.props.setAlert(error.msg, "danger"));
            }
        }

    };

    handleChangeDateRange = async (e) => {
        console.log(e.target.value);
        let value = e.target.value;
        // console.log();2222
        if (value == 2222) {
            await this.setState({
                dateRangeSelected: e.target.value,
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    console.log("true", eachDate);
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: moment(eachDate.startDate).format("YYYY-MM-DD"),
                        endDate: moment(eachDate.endDate).format("YYYY-MM-DD"),
                    });
                }
            });
        }
    };

    toggleCloseModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: "",
        });
    };

    toggleConfirmModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: 2222,
        });
    };

    handleExportExcel = async (e) => {
        e.preventDefault();
        var workSheet = XLSX.utils.json_to_sheet(this.state.tmsExportData);
        var workBook = XLSX.utils.book_new();
        var wscols = [
            { wch: 6 },
            { wch: 35 },
            { wch: 15 },
            { wch: 15 },
            { wch: 35 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 }
        ];
        // workSheet['!cols'] = wscols;
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
        XLSX.writeFile(workBook, 'TMS_Report' + ".xlsx");
    }


    render() {
        let { style, modal } = this.state
        const defaultColDef = {
            flex: 1,
            minWidth: 100,
            // height: 100,
            resizable: true
        }

        // const getRowHeight = {
        //     getRowHeight: 50
        // }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>

                <div id='cover-spin' style={style}></div>
                {/* <Breadcrumb link="ImportantDates" parent="Admin" /> */}
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />

                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="dateRange"
                                                    value={this.state.dateRangeSelected}
                                                    id="dateRange"
                                                    onChange={this.handleChangeDateRange}
                                                >
                                                    <option className="bms-dashboaord-date-range" value={""} disabled>
                                                        Select Date Range
                                                    </option>

                                                    {this.state.uniqueDateRanges.map((eachItem, i) => (
                                                        <option value={i} key={i}>
                                                            {eachItem.title}
                                                        </option>
                                                    ))}
                                                    <option value={2222}>
                                                        {/* <Button onClick={this.toggle}> */}
                                                        Custom Date Range
                                                        {/* </Button> */}
                                                    </option>
                                                    <div>
                                                        <Modal
                                                            isOpen={modal}
                                                        // toggle={this.toggle}
                                                        >
                                                            <ModalHeader>Select Start and End date</ModalHeader>
                                                            <ModalBody>
                                                                <div className="row" style={{ minHeight: "1px" }}>
                                                                    <div className="col float-left">
                                                                        <div
                                                                            className="cal-datepicker"
                                                                            style={{ height: "100%" }}
                                                                        >
                                                                            <div
                                                                                className="datepicker-here text-center"
                                                                                style={{ height: "100%" }}
                                                                                data-language="en"
                                                                            >
                                                                                <DatePicker
                                                                                    height="400"
                                                                                    placeholderText="Select Start Date"
                                                                                    id="startDateInput"
                                                                                    inline
                                                                                    selectsRange
                                                                                    selected={this.state.startDate}
                                                                                    startDate={this.state.startDate}
                                                                                    onChange={(date) => {
                                                                                        this.setState({
                                                                                            startDate: date,
                                                                                        });
                                                                                    }}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col float-left">
                                                                        <div className="cal-datepicker">
                                                                            <div
                                                                                className="datepicker-here text-center"
                                                                                data-language="en"
                                                                            >
                                                                                <DatePicker
                                                                                    height="400"
                                                                                    id="endDateInput"
                                                                                    inline
                                                                                    placeholderText="Select End Date"
                                                                                    selected={this.state.endDate}
                                                                                    onChange={(date) => {
                                                                                        this.setState({
                                                                                            endDate: date,
                                                                                        });
                                                                                    }}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button color="info"
                                                                    onClick={this.toggleConfirmModal}

                                                                >Confirm</Button>{" "}
                                                                <Button
                                                                    color="danger"
                                                                    onClick={this.toggleCloseModal}
                                                                >
                                                                    Close
                                                                </Button>{" "}
                                                            </ModalFooter>
                                                        </Modal>
                                                    </div>
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                {/* <Label>Location ID</Label> */}
                                                <Input
                                                    type="select"
                                                    name="locationid"
                                                    value={this.state.Locationid}
                                                    id="locationid"
                                                    onChange={this.changeLocation}
                                                >
                                                    <option value={""} disabled selected>Select Location</option>

                                                    {this.state.unique_locationids.map(locationid => (
                                                        <option value={locationid.Locationid}>
                                                            {locationid.name}

                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                {/* <Label>Client Name</Label> */}
                                                <Input
                                                    type="select"
                                                    name="clientName"
                                                    value={this.state.ClientName}
                                                    id="clientName"
                                                    onChange={this.changeClient}
                                                >
                                                    <option value={""} disabled selected>Select Client</option>

                                                    {this.state.unique_clients.map(client => (
                                                        <option value={client.ClientName}>
                                                            {client.ClientName}
                                                        </option>
                                                    ))}
                                                    {/*<option value={0}>All Shipments</option>*/}
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="routeNo"
                                                    value={this.state.Segment}
                                                    id="routeNo"
                                                    onChange={event => {
                                                        this.setState({ Segment: event.target.value });
                                                    }}
                                                >
                                                    <option value={""} disabled selected>Select Segment</option>

                                                    {this.state.unique_segments.map(segment => (
                                                        <option value={segment.Segment}>
                                                            {segment.Segment}
                                                        </option>
                                                    ))}

                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="tripId"
                                                    value={this.state.tripIds}
                                                    // style={{ borderColor: "#FF4848" }}
                                                    style={{
                                                        fontFamily: 'Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',
                                                        fontVariantNumber: 'tabular-nums'
                                                    }}
                                                    id="tripId"
                                                    placeholder="TripIDs"
                                                    onChange={(e) => {
                                                        this.setState({ tripIds: e.target.value })
                                                    }}
                                                >

                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                // type="submit"
                                                // className="btn btn-secondary"
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.handleSearchShipment}
                                            >
                                                Search
                                            </button>
                                        </div>


                                        <div className="col-sm-2">
                                            {this.state.tmsExportData.length > 0 && (
                                                // <CSVLink data={this.state.exportData}>
                                                <button
                                                    className={(!this.state.tmsExportData.length > 0 && !this.state.tmsExportData.length > 0) ? 'btn btn-danger w-100 overflow-hidden mb-3 px-0 disabled' : 'btn btn-danger w-100 overflow-hidden mb-3 px-0'}
                                                    onClick={this.handleExportExcel}
                                                    disabled={!this.state.tmsExportData && !this.state.tmsExportData.length > 0}
                                                >
                                                    <i
                                                        className="fa fa-file-excel-o"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    Export
                                                </button>
                                                // </CSVLink>
                                            )}
                                        </div>

                                    </div>
                                </nav>



                                <div className="card-body">

                                    <div
                                        className="ag-theme-balham"
                                        style={{
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={this.state.rowData}
                                            defaultColDef={defaultColDef}
                                            onGridReady={this.onGridReady}
                                            // rowStyle={{ background: 'yellow' }}
                                            enableCellTextSelection={true}
                                            gridAutoHeight={true}
                                            domLayout={this.state.domLayout}
                                            rowHeight={45}
                                            // enableCellTextSelection={true}
                                            suppressAutoSize={true}
                                        // getRowHeight={getRowHeight}
                                        // getRowStyle={(params) => {
                                        //     return {
                                        //         background: (params.data.IsVerified === true) ? "#bbf58c" :
                                        //             (params.data.IsVerified === false) ? "#f7a681" :
                                        //                 "white"
                                        //     }
                                        // }}
                                        >
                                        </AgGridReact>
                                    </div>
                                    <div style={{ margin: "9vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                                            onChange={this.handlePageChange.bind(this)}
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
