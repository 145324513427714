import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
// import { Table, Button, FormGroup, Form, Label, Col } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import TextField from '@material-ui/core/TextField';

// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../../common/Alert";
import { setAlert } from "../../../../../redux/actions/alert.actions";
import Config from "../../../../../config";
import moment from 'moment'
import "../../../../../assets/customCssFiles/loader-spinner.css"

class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Tripid: "",
            AdvPay: "",
            AdvPaymentDate: new Date(),
            VendorID: "",
            Vendorname: "",
            Accountnumber: "",
            IFSCCode: "",
            Remarks: "",
            style: {},
            loading: false
        }
        this.validator = new SimpleReactValidator();

    }

    componentWillMount = async () => {
        let id = Number(this.props.id)
        let result = await axios.get(
            `${Config.hostName}/tms/get/details/payment/${id}`
        );
        if (result.data.data.length > 0) {

            // console.log(result.data[0]);
            let data = result.data.data[0];


            await this.setState({
                style: { display: 'none' },

                Tripid: data.Tripid,
                AdvPay: data.AdvPay,
                AdvPaymentDate: moment(data.AdvPaymentDate).format('YYYY-MM-DD'),
                VendorID: data.VendorID,
                Vendorname: data.Vendorname,
                Accountnumber: data.Accountnumber,
                IFSCCode: data.IFSCCode,
                Remarks: data.Remarks


            });
        }
        else {
            await this.setState({
                style: { display: 'none' },
            })
            alert("No Trip Found")
        }

    }

    onSubmit = async e => {
        e.preventDefault();


        if (this.validator.allValid()) {
            try {
                // eslint-disable-next-line
                await this.setState({ loading: true });

                await axios.post(`${Config.hostName}/tms/update/trip/trip-payment/${this.state.Tripid}`, {
                    advPay: this.state.AdvPay,
                    advPaymentDate: this.state.AdvPaymentDate,
                    vendorId: this.state.VendorID,
                    vendorName: this.state.Vendorname,
                    accountNumber: this.state.Accountnumber,
                    ifscCode: this.state.IFSCCode,
                    remarks: this.state.Remarks
                })
                    .then(async (response) => {
                        // console.log(response.data, "response");
                        if (response.data.success === false) {
                            await this.setState({ loading: false });

                            this.props.setAlert(response.data.message, "danger");
                        }
                        else {
                            await this.setState({ loading: false });

                            this.props.setAlert("Payment Updated", "success");
                            await this.componentWillMount();

                        }

                    }
                    ).catch((error) => {
                        console.log(error, 'error');

                    }
                    )
            }
            catch (error) {
                const errors = error.response.data.errors;
                if (errors) {
                    errors.forEach(error => this.props.setAlert(error.msg, "danger"));
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };


    render() {
        let { loading, style } = this.state;


        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;

        return (
            <div>
                <div id='cover-spin' style={style}></div>
                {/*Container-fluid starts*/}
                {/*Container-fluid Ends*/}

                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <div className="card">

                                    <div className="card-body">

                                        <form>
                                            <div
                                                class="col-lg-12 text-right"
                                            ></div>
                                            <div class="col-lg-12">
                                                <div class="grid">
                                                    <div class="grid-body">
                                                        <div class="item-wrapper">
                                                            <div class="row mb-3">
                                                                <div class="col-md-12 mx-auto">
                                                                    <div className="mb-3">
                                                                        <button type="button" className="btn badge-info" disabled>
                                                                            Trip ID : {this.state.Tripid} <span className="badge badge-pill badge-light mr-0">

                                                                            </span>
                                                                        </button>
                                                                    </div>

                                                                    {/* <div class="form-row">
                                                                        <div class="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div class="col-md-4 showcase_text_area">
                                                                                <label>Advance&nbsp;Paid</label>
                                                                            </div>
                                                                            <div class="col-md-8 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter Advance Pay"
                                                                                    formControlName="AdvPay"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.AdvPay}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            AdvPay: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group col-md-6 row showcase_row_area">
                                                                            <div class="col-md-4 showcase_text_area">
                                                                                <label>Payment&nbsp;Date</label>
                                                                            </div>
                                                                            <div class="col-md-8 showcase_text_area">
                                                                               
                                                                                <TextField
                                                                                    id="date"
                                                                                    type="date"
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}

                                                                                    value={this.state.AdvPaymentDate}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            AdvPaymentDate: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}

                                                                    <div class="form-row">
                                                                        <div class="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div class="col-md-4 showcase_text_area">
                                                                                <label>Vendor&nbsp;ID</label>
                                                                            </div>
                                                                            <div class="col-md-8 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter VendorID"
                                                                                    formControlName="VendorID"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.VendorID}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            VendorID: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group col-md-6 row showcase_row_area">
                                                                            <div class="col-md-4 showcase_text_area">
                                                                                <label>Vendor&nbsp;Name</label>
                                                                            </div>
                                                                            <div class="col-md-8 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter Vendor Name"
                                                                                    formControlName="Vendorname"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.Vendorname}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            Vendorname: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-row">
                                                                        <div class="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div class="col-md-4 showcase_text_area">
                                                                                <label>Account&nbsp;No</label>
                                                                            </div>
                                                                            <div class="col-md-8 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter Account Number"
                                                                                    formControlName="Accountnumber"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.Accountnumber}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            Accountnumber: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group col-md-6 row showcase_row_area">
                                                                            <div class="col-md-4 showcase_text_area">
                                                                                <label>IFSC&nbsp;Code</label>
                                                                            </div>
                                                                            <div class="col-md-8 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter IFSC Code"
                                                                                    formControlName="IFSCCode"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.IFSCCode}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            IFSCCode: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-row">
                                                                        <div class="form-group col-md-12 row showcase_row_area">
                                                                            <div class="col-md-2 showcase_text_area">
                                                                                <label>Remarks</label>
                                                                            </div>
                                                                            <div class="col-md-10 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter Remarks"
                                                                                    formControlName="Remarks"
                                                                                    type="textarea"
                                                                                    width="100"
                                                                                    class="form-control"
                                                                                    value={this.state.Remarks}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            Remarks: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <button
                                                                        type="submit"
                                                                        class="btn btn-outline-primary btn-lg"
                                                                        style={{ float: "right" }}
                                                                        onClick={e => this.onSubmit(e)}
                                                                        disabled={loading}

                                                                    >
                                                                        {loading && (
                                                                            <i
                                                                                className="fa fa-refresh fa-spin"
                                                                                style={{ marginRight: "5px" }}
                                                                            />
                                                                        )}
                                                                        {loading && <span>Processing ...</span>}
                                                                        {!loading && <span>Update</span>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}




NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
