/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import UserMenu from "./user-menu.component";
import PropTypes from "prop-types";
import { FormGroup, Input, Card, CardHeader } from "reactstrap";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import logo from "./royaloak-logo.png";
import logicartsLogo from "./logicarts-logo.png";
import "./header.css";
import { setAlert } from "../../../redux/actions/alert.actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      sidebar: true,
      navMenus: false,
      ccid: 1,
    };
  }

  openCloseSidebar = () => {
    if (this.state.sidebar) {
      this.setState({ sidebar: false });
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    } else {
      this.setState({ sidebar: true });
      document
        .querySelector(".page-body-wrapper")
        .classList.remove("sidebar-close");
    }
  };

  toggle() {
    this.setState((prevState) => ({
      navMenus: !prevState.navMenus,
    }));
  }

  componentWillMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth <= 991) {
      this.setState({ sidebar: false });
    }
  }

  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth <= 991) {
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    }
  }

  render() {
    return (
      <div className="page-main-header">
        <div className="main-header-left">
          <div className="logo-wrapper">
            {/* <Link to="#"> */}
            {this.props.auth.isAuthenticated &&
            this.props.auth.user.user_type &&
            (this.props.auth.user.user_type == "ROAdmin" ||
              this.props.auth.user.user_type == "ROSuperAdmin" ||
              this.props.auth.user.user_type == "ROSupervisor") ? (
              <img
                src={logo}
                alt="ROYAL OAK"
                width="200"
                height="100"
                style={{
                  marginTop: "0vh",
                  marginBottom: "1vh",
                  marginLeft: "1vh",
                }}
              />
            ) : (
              // <h4>LOGICARTS</h4>
              <img
                src={logicartsLogo}
                alt="Logicarts"
                width="200"
                height="100"
                style={{
                  marginTop: "1vh",
                  marginBottom: "1vh",
                  marginLeft: "1vh",
                }}
              />
            )}
            {/* </Link> */}
          </div>
        </div>
        <div
          className="main-header-right row"
          style={{ marginTop: "0vh", marginBottom: "2vh" }}
        >
          <div className="mobile-sidebar">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <input
                  type="checkbox"
                  id="sidebar-toggle"
                  defaultChecked={this.state.sidebar}
                  onClick={this.openCloseSidebar}
                />
                <span
                  className="switch-state"
                  style={{ backgroundColor: "#ea4444" }}
                ></span>
              </label>
            </div>
          </div>
          <div className="nav-right col" style={{ height: "100%" }}>
            <ul
              className={"nav-menus " + (this.state.navMenus ? "open" : "")}
              style={{ height: "100%" }}
            >
              <UserMenu />
            </ul>
            <div
              className="d-lg-none mobile-toggle"
              onClick={() => this.toggle()}
            >
              <i className="icon-more"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
//   setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Header);

// export default Header;
