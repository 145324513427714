import React, {
  Component
  // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import "../../../../assets/customCssFiles/viewVehiclesVms.css";
import VehicleIcon from "../../../../assets/customImages/newTruckTms.png";


class RouteAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      unique_locationids: [],
      Locationid: "",
      Firstname: "",
      Lastname: "",
      VendorName: "",
      Mobile: "",
      Contact2: "",
      Contact3: "",
      AadharNum: "",
      PanNumber: "",
      Comments: "",
      BankName: "",
      AccountNo: "",
      IFSC: "",
      BenificaryName: "",
      IsPrimary: "",
      IsPrimaryValue: "",
      VendorID: "",
      style: {}
    };
    this.validator = new SimpleReactValidator();
  }



  async componentWillMount() {
    let result = await axios.get(
      `${Config.hostName}/vms/vendors/${this.props.match.params.VendorID}`
    );
    console.log(result.data[0]);
    let data = result.data.data[0]
    await this.setState({
      VendorID: data.VendorID,
      Locationid: data.Locationid,
      Firstname: data.firstname,
      Lastname: data.lastname,
      VendorName: data.TransportName,
      Mobile: data.Mobile,
      Contact2: data.Contact2,
      Contact3: data.Contact3,
      AadharNum: data.aadhar_num,
      PanNumber: data.pan_num,
      Comments: data.Comments,
      BankName: data.BankName,
      AccountNo: data.AccountNumber,
      IFSC: data.IFSC,
      BenificaryName: data.BenificaryName,
      IsPrimaryValue: data.IsPrimary,
      style: { display: "none" }
    });
  }

  render() {
    const { style, Locationid, VendorName, Mobile, AadharNum, PanNumber, BankName, AccountNo, IFSC, BenificaryName, IsPrimaryValue } = this.state;

    return (
      <div style={{ height: "82vh" }}>
        <div id="cover-spin" style={style}> </div>

        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />

                <div className="row vmsViewVehiclesMainCardContainer">
                  <div className="card col-sm-5 vmsViewVehiclesMainCard">
                    <div className="row ">
                      <div className="vmsViewVehiclesMainCardLeftView">
                        {/* <img className="vmsViewVehiclesMainCardLeftViewVehicleIcon" src={VehicleIcon} alt="Vehicle" width="100" height="100" /> */}

                      </div>
                      <div className="vmsViewVehiclesMainCardRightView">
                        <span className="vmsViewVehiclesMainCardVehicleNumber">Name : {VendorName}</span>
                        <span className="vmsViewVehiclesMainCardVehicleSize">Mobile: {Mobile ? Mobile : "-"}</span>
                        <span className="vmsViewVehiclesMainCardVehicleSize">AadharNum: {AadharNum ? AadharNum : "-"}</span>
                        <span className="vmsViewVehiclesMainCardVehicleSize">PanNumber: {PanNumber ? PanNumber : "-"}</span>
                        <span className="vmsViewVehiclesMainCardVehicleSize">BankName: {BankName ? BankName : "-"}</span>
                        <span className="vmsViewVehiclesMainCardVehicleSize">BenificaryName: {BenificaryName ? BenificaryName : "-"}</span>
                        <span className="vmsViewVehiclesMainCardVehicleSize">AccountNo: {AccountNo ? AccountNo : "-"}</span>
                        <span className="vmsViewVehiclesMainCardVehicleSize">IFSC: {IFSC ? IFSC : "-"}</span>
                        <span className="vmsViewVehiclesMainCardIsGPSInstalled">
                          IsPrimary: <span className="vmsViewVehiclesMainCardIsGPSInstalledValue" style={{ color: IsPrimaryValue == 1 ? "green" : "red" }}>
                            {IsPrimaryValue == 1 ? "YES" : "NO"}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {/* <Link
                  to={`/admin/vms/vehicles/add/${this.props.match.params.VendorID}`}
                >
                  <button
                    type="submit"
                    style={{ marginBottom: "8vh" }}
                    onClick={() =>
                      this.setState({
                        anotherModal: !this.state.anotherModal
                      })
                    }
                    className="btn btn-success"
                  >
                    Add New
                      </button>
                </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RouteAddForm.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
