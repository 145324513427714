
import React, { Component } from "react";
import axios from "axios";
// import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, Label, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import { AgGridReact } from 'ag-grid-react';
import "../../../../assets/customCssFiles/loader-spinner.css"
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';

class NoticeAdd extends Component {
    state = {
        link: "",
        modal: false,
        locations: [],
        description: "",
        message: "",
        style: {},
        unique_locationids: [],
        unique_vendors: [],
        anotherModal: false,
        Locationid: "",
        VendorName: "",
        Contact2: "",
        Contact3: "",
        page: 1,
        showText: false,
        columnDefs: [
            {
                headerName: "PMS ID", field: "PMSID", sortable: true, filter: true, minWidth: 90, maxWidth: 90
            },
            {
                headerName: "Name", field: "fullName", sortable: true, filter: true, minWidth: 450, maxWidth: 450
            },
            {
                headerName: "City", field: "city", sortable: true, filter: true, minWidth: 120, maxWidth: 120
            },
            {
                headerName: "PMS Type", field: "PMSType", sortable: true, filter: true, minWidth: 150, maxWidth: 150
            },
            {
                headerName: "Mobile", field: "Mobile", sortable: true, filter: true, minWidth: 150, maxWidth: 150
            },

            {
                headerName: "Actions", field: "Actions", minWidth: 100,
                cellRendererFramework: (params) => {
                    const PMSID = params.node.data.PMSID;
                    const payeeName = params.node.data.fullName;
                    const PMSType = params.node.data.PMSType;
                    const Mobile = params.node.data.Mobile;
                    const city = params.node.data.city;
                    const Locationid = params.node.data.Locationid;
                    const PMSTypeID = params.node.data.PMSTypeID;

                    return <div>
                        <span
                            style={{ cursor: "pointer" }}
                        >
                            {/* <ViewComfyIcon /> */}
                            <i class="fa fa-user" aria-hidden="true"
                                onClick={() => this.toggleOpenPayeeDetailsModal(PMSID, payeeName, PMSType, Mobile, city, Locationid, PMSTypeID)}
                            >
                                &nbsp;
                                {/* View Banks */}
                            </i>
                        </span> |
                        <Link to={`/admin/pms/view/banks/${PMSID}`} >
                            <span
                                style={{ cursor: "pointer", color: "black" }}>
                                &nbsp;
                                <i class="fa fa-university" aria-hidden="true">
                                    &nbsp;
                            </i>
                            </span>

                        </Link>
                        |
                            <span
                            style={{ cursor: "pointer" }}

                        >
                            &nbsp;
                                <i class="fa fa-trash" onClick={() => this.toggleOpenDeleteRequestModal(PMSID, payeeName)} aria-hidden="true">
                                &nbsp;
                                {/* <EditIcon /> */}
                                {/* delete */}
                            </i>
                        </span>
                    </div>
                },
            },

        ],
        rowData: [],
        activePage: 1,
        itemsCountPerPage: 30,
        totalItemsCount: 2000,
        pageRangeDisplayed: 5,
        domLayout: "autoHeight",

        pmsTableData: [],
        pmsCitiesDropDown: [],
        pmsPayeeTypeDropDown: [],
        uniqueLocationsDropDown: [],
        pmsLocationId: "",
        pmsTypeId: "",

        isAddPayeeModalOpen: false,
        isAddPayeeBankModalOpen: false,
        isEditPayeeBankModalOpen: false,

        addPayeeName: "",
        addPayeeEmail: "",
        addPayeeContactNumber: "",
        addPayeeContactNumberAlternate: "",
        addPayeeAttachments: [],
        addPayeeBankName: "",
        addPayeeAccountNumber: "",
        addPayeeIfsc: "",
        addPayeeBeneficiaryName: "",
        handleCheckCompany: "",
        addPayeeBankICompany: "",
        selectedPayeeDropDownName: "",
        selectedLocationPayeeDropDown: "",
        selectedPayeeTypeDropDown: "",

        addPayeeIsCompany: false,
        addPayeeTds: "",

        editBankModalDetails: [],

        stringVer: true,

        isDeleteRequestModal: false,
        isDeleteRequestModalLoading: false,
        pmsId: "",
        pmsDeleteName: "",

        pmsPayeeDetailsObject: {},
        isPayeeDetailsModal: false,

        payeeDetailsModalPmsId: "",
        payeeDetailsModalPayeeName: "",
        payeeDetailsModalCity: "",
        payeeDetailsModalMobile: "",
        payeeDetailsModalPmsType: "",


    };

    toggleAddPayeeModal = async () => {
        await this.setState({
            isAddPayeeModalOpen: !this.state.isAddPayeeModalOpen,
            addPayeeName: "",
            addPayeeEmail: "",
            addPayeeContactNumber: "",
            addPayeeContactNumberAlternate: "",
            addPayeeAttachments: "",
            addPayeeBankName: "",
            addPayeeAccountNumber: "",
            addPayeeIfsc: "",
            addPayeeBeneficiaryName: "",
            handleCheckCompany: "",
            addPayeeBankICompany: "",
            selectedPayeeDropDownName: "",
            selectedLocationPayeeDropDown: "",
            selectedPayeeTypeDropDown: "",

            addPayeeIsCompany: false,
            addPayeeTds: "",
        });
    }

    deletePayeeBank = async (value) => {
        // await this.setState({ style: {} });
        // await this.getUniquePayeeBanksDropDown();
        const { selectedBanksList } = this.state;
        const banKList = selectedBanksList.filter((bankItem, i) => {
            // if(bankItem.payeebankID==value){
            // return selectedBanksList.pop(bankItem);
            return bankItem.payeebankID != value;

            // }
        })

        await this.setState({
            selectedBanksList: banKList,
            // style: { display: "none" }
        }
        );
    };

    editPayeeBank = async (value) => {
        const { selectedBanksList } = this.state;
        const reqObj = [];
        const banKList = selectedBanksList.filter((bankItem, i) => {
            // return bankItem.payeebankID != value;
            if (bankItem.payeebankID == value) {
                return reqObj.push(bankItem)
            }
        })
        await this.setState({
            isEditPayeeBankModalOpen: true,
            editBankModalDetails: reqObj,
            addPayeeBankBeneficiaryName: reqObj[0].fullname,
            addPayeeBankAccountNumber: reqObj[0].AccountNumber,
            addPayeeBankBankName: reqObj[0].bankname,
            addPayeeBankIfsc: reqObj[0].IFSC,
            addPayeeBankIsPrimary: reqObj[0].IsPrimary,
            addPayeeBankTds: reqObj[0].tds,
            selectedPayeeDropDown: reqObj[0].pmsid,
            selectedPayeeDropDownName: reqObj[0].fullname,
            selectedPayeeBankDropDown: value


        })

    };

    editPayeeBankAmount = async (value) => {
        const { selectedBanksList } = this.state;
        const reqObj = [];
        const banKList = selectedBanksList.filter((bankItem, i) => {
            // return bankItem.payeebankID != value;
            if (bankItem.payeebankID == value) {
                return reqObj.push(bankItem)
            }
        })
        await this.setState({
            isEditPayeeBankAmountEditModalOpen: true,
            selectedPayeeDropDownName: reqObj[0].fullname,
            selectedPayeeBankAmountValue: reqObj[0].amount,
            selectedPayeeBankAmountValueObject: reqObj
        })

    };

    createPayeeBank = async (e) => {
        e.preventDefault();
        await this.addPayeeBankApiCall();
    }

    addPayeeApiCall = async () => {
        await this.setState({
            style: {}
        })
        const {
            addPayeeName, addPayeeEmail, addPayeeContactNumber, addPayeeContactNumberAlternate, addPayeeIsCompany, addPayeeTds, addPayeeAttachments,
            addPayeeBeneficiaryName, addPayeeAccountNumber, addPayeeBankName, addPayeeIfsc, selectedPayeeTypeDropDown, selectedLocationPayeeDropDown } = this.state;
        await axios.post(`${Config.hostName}/pms/add/payee`, {
            "payeeName": addPayeeName,
            "payeeTypeId": selectedPayeeTypeDropDown,
            "locationId": selectedLocationPayeeDropDown,
            "bankName": addPayeeBankName,
            "bankAccountNo": addPayeeAccountNumber,
            "ifsc": addPayeeIfsc,
            "beneficaryName": addPayeeBeneficiaryName,
            "tds": addPayeeTds,
            "isCompany": addPayeeIsCompany === false ? 0 : 1,
            "email": addPayeeEmail,
            "contactNumber": addPayeeContactNumber,
            "contactNumber02": addPayeeContactNumberAlternate,
            "attachments": addPayeeAttachments

        })
            .then(async res => {
                console.log(res.data);
                if (res.data.success == true) {
                    await this.toggleAddPayeeModal();
                    await this.componentWillMount();
                    alert("Succesfully Added");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert(res.data.message);

                }
            })
            .catch(async err => {
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
                // console.log(err);
            });
    }

    createPayee = async (e) => {
        e.preventDefault();
        await this.addPayeeApiCall();

    }

    savePayeeBankApiCall = async () => {
        await this.setState({
            style: {}
        })
        const neededBank = this.state.selectedPayeeBankDropDown;
        await axios.post(`${Config.hostName}/pms/update/bank/payee`, {
            "pmsId": this.state.selectedPayeeDropDown,
            "payeeBankId": this.state.selectedPayeeBankDropDown,
            "bankName": this.state.addPayeeBankBankName,
            "bankAccountNo": this.state.addPayeeBankAccountNumber,
            "ifsc": this.state.addPayeeBankIfsc,
            "beneficaryName": this.state.addPayeeBankIfsc,
            "tds": "",
            "isPrimary": 0
        })
            .then(async res => {
                if (res.data.success == true) {
                    await this.setState({ style: {} });
                    await this.getUniquePayeeBanksDropDown();
                    const { selectedBanksList, uniquePayeeBanksDropDown } = this.state;
                    const tempBankList = [];

                    uniquePayeeBanksDropDown.filter((bankItem, i) => {
                        if (bankItem.payeebankID == neededBank) {
                            tempBankList.push(bankItem);
                        }
                    })

                    selectedBanksList.filter((bank => {
                        if (bank.payeebankID == neededBank) {
                            // return bank = tempBankList[0]
                            return (
                                bank.AccountNumber = tempBankList[0].AccountNumber,
                                bank.IFSC = tempBankList[0].IFSC,
                                bank.IsPrimary = tempBankList[0].IsPrimary,
                                // bank.amount = tempBankList[0].amount,
                                bank.bankname = tempBankList[0].bankname,
                                bank.fullname = tempBankList[0].fullname,
                                bank.payeebankID = tempBankList[0].payeebankID,
                                bank.pmsid = tempBankList[0].pmsid,
                                bank.tds = tempBankList[0].tds
                            )
                        }
                    }))
                    await this.setState({
                        selectedBanksList: selectedBanksList,
                    });
                    await this.setState({
                        style: { display: "none" },
                        selectedPayeeBankDropDown: ""
                    })
                    await this.toggleEditPayeeBankModal();
                    alert("Succesfully Updated");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert(res.data.message);

                }
            })
            .catch(async err => {
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
                // console.log(err);
            });
    }

    savePayeeBankDetails = async (e) => {
        e.preventDefault();
        await this.savePayeeBankApiCall();
    }

    savePayeeBankAmountDetails = async (e) => {
        e.preventDefault();
        const { selectedBanksList, selectedPayeeBankAmountValueObject, selectedPayeeBankAmountValue } = this.state;
        // console.log("addPayeeBank", selectedPayeeBankAmountValue, selectedBanksList, selectedPayeeBankAmountValueObject);
        const map = selectedBanksList.map(item => {
            if (item.payeebankID == selectedPayeeBankAmountValueObject[0].payeebankID) {
                // console.log(selectedPayeeBankAmountValue, "selectedPayeeBankAmountValue", item)
                return item.amount = selectedPayeeBankAmountValue
            }
        })
        const res = await Promise.all(map);
        // console.log(selectedBanksList, "selectedBanksList")
        await this.setState({
            selectedBanksList: selectedBanksList,
            isEditPayeeBankAmountEditModalOpen: false,
            selectedPayeeBankAmountValue: ""
        })
        // console.log(this.state.selectedBanksList, "this.state.selectedBanksList")
    }

    getUrlToAttachmentsAddPayee = async (url, name) => {
        const { addPayeeAttachments } = this.state;
        addPayeeAttachments.filter(attachment => {
            if (attachment.name == name) {
                return attachment.AttachmentUrl = url
            }
        })
        await this.setState({
            addPayeeAttachments: addPayeeAttachments
        })
    }

    uploadFileAndGetUrlAddPayee = async (formData) => {
        // console.log("uploading formdata")
        const fileData = await axios({
            method: "POST",
            url: `${Config.hostName}/unique/upload/any-file`,
            timeout: 3 * 60 * 1000,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            data: formData,
        });
        return fileData;
    }

    singleUploadFileAddPayee = async (file) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = async (e) => {
            await this.setState({
                selectedFile: [reader.result],
            });
        }
        // console.log(url); // Would see a path?
        const fileType = file.type.substring(0, 5);
        await this.setState({ selectedFileAddPayee: file, selectedFileTypeAddPayee: fileType });
        const formData = new FormData();
        formData.append(
            "media",
            this.state.selectedFileAddPayee,
            this.state.selectedFileAddPayee.name
        );
        // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
        // console.log(formData, "formData", fileType);
        const res = await this.uploadFileAndGetUrlAddPayee(formData);
        const urlFromAws = res.data.url;
        // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
        await this.getUrlToAttachmentsAddPayee(urlFromAws, file.name);
        const { addPayeeAttachments } = this.state;
        setTimeout(async () => {
            addPayeeAttachments.filter(att => {
                if (att.name == file.name && att.isUploaded == false) {
                    return att.isUploaded = true
                }
            })
            await this.setState({
                addPayeeAttachments: addPayeeAttachments
            })
        }, 2000);
    }

    onFileUploadAddPayee = async (event) => {
        // console.log(event.target.files, "event.target.files");
        const filesObj = event.target.files;
        const filesObjKeys = Object.keys(filesObj);
        const { addPayeeAttachments } = this.state;
        const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
            addPayeeAttachments.push({
                name: filesObj[fileKey].name,
                AttachmentUrl: "",
                isUploaded: false
            })
        })
        const multiPromiseFileNames = await Promise.all(multiMapFileNames);
        await this.setState({
            addPayeeAttachments: addPayeeAttachments
        })
        for (let i = 0; i < filesObjKeys.length; i++) {
            // console.log(i, "i")
            const item = filesObj[i];
            await this.singleUploadFileAddPayee(item);
        }

    };

    deleteAttachmentAddPayee = async (name) => {
        const { addPayeeAttachments } = this.state;
        // console.log(name, "deleteAttachment");
        addPayeeAttachments.filter((item) => {

            if (item.name == name) {
                const index = addPayeeAttachments.indexOf(item);
                if (index > -1) {
                    addPayeeAttachments.splice(index, 1);
                }
            }
        })
        await this.setState({
            addPayeeAttachments: addPayeeAttachments
        })
    }

    createNewPayeeSelectedBankCard = async (e) => {
        e.preventDefault();
        if (this.state.selectedPayeeBankDropDown.length == 0) {
            return alert("Please Select Bank Details to continue")
        }
        if (this.state.selectedPayeeBankAmountValue.length == 0) {
            return alert("Please Enter Amount to continue")
        }
        else {
            await this.setState({ style: {} });
            const { selectedBanksList, uniquePayeeBanksDropDown, selectedPayeeBankDropDown, selectedPayeeBankAmountValue } = this.state;
            uniquePayeeBanksDropDown.filter((bankItem, i) => {
                if (bankItem.payeebankID == selectedPayeeBankDropDown) {
                    bankItem.amount = selectedPayeeBankAmountValue;
                    selectedBanksList.push(bankItem)
                }
            })

            await this.setState({
                selectedBanksList: selectedBanksList,
                uniquePayeeTypesDropDown: [],
                uniquePayeesDropDown: [],
                uniquePayeeBanksDropDown: [],
                /** Drop Downs Selected Value  */
                selectedPayeeTypeDropDown: "",
                selectedPayeeDropDownName: "",
                selectedPayeeDropDown: "",
                selectedLocationPayeeDropDown: "",
                selectedPayeeBankDropDown: "",
                selectedPayeeBankAmountValue: "",
                style: { display: "none" }
            });
        }
    };

    toggleAddPayeeBankModal = async () => {
        await this.setState({
            isAddPayeeBankModalOpen: !this.state.isAddPayeeBankModalOpen
        });
    }

    toggleEditPayeeBankModal = async () => {
        await this.setState({
            isEditPayeeBankModalOpen: !this.state.isEditPayeeBankModalOpen
        });
    }

    toggleEditPayeeBankAmountModal = async () => {
        await this.setState({
            isEditPayeeBankAmountEditModalOpen: !this.state.isEditPayeeBankAmountEditModalOpen,
            selectedPayeeBankAmountValue: ""
        });
    }



    handleCheckPrimary = async () => {

        await this.setState({
            addPayeeBankIsPrimary: !this.state.addPayeeBankIsPrimary
        });

    }

    handleCheckCompany = async () => {

        await this.setState({
            addPayeeBankIsCompany: !this.state.addPayeeBankIsCompany
        });
    }

    getUniqueLocationsDropDown = async () => {
        await axios.get(`${Config.hostName}/unique/locations`)
            .then(async (res) => {
                await this.setState({ pmsCitiesDropDown: res.data.data })
            })
            .catch((error) => {
                alert("getUniqueLocationsDropDown : ", error);
            });
    }

    getUniquePayeeTypesDropDown = async () => {
        await axios
            .get(`${Config.hostName}/pms/get/payeeType/dropdown`)
            .then(async res => {
                await this.setState({
                    pmsPayeeTypeDropDown: res.data.data,
                });
            })
            .catch(err => {
                alert(err);
            });
    }

    getPmsPayeeData = async (location, payeeType) => {
        await axios
            .get(`${Config.hostName}/pms/get/payee/dropdown/${location}/${payeeType}`)
            .then(async res => {
                await this.setState({
                    pmsTableData: res.data.data,
                });
            })
            .catch(err => {
                alert(err);
            });
    }

    handleSearchShipment = async () => {
        await this.setState({
            style: {}
        })
        await this.getPmsPayeeData(this.state.pmsLocationId, this.state.pmsTypeId);

        await this.setState({
            rowData: this.state.pmsTableData.map(eachItem => {
                return {
                    PMSID: eachItem.PMSID,
                    PMSType: eachItem.PMSType,
                    Mobile: eachItem.contactNumber,
                    fullName: eachItem.fullName,
                    Locationid: eachItem.Locationid,
                    PMSTypeID: eachItem.PMSTypeID,
                    city: eachItem.name
                }
            }),
            style: { display: 'none' }
        });
    }

    componentWillMount = async () => {

        await this.getUniqueLocationsDropDown();
        const locations = this.state.pmsCitiesDropDown;
        const locationIds = [];
        locations.map((eachLocation, i) => {
            locationIds.push(eachLocation.Locationid)
        })
        const locationString = locationIds.toString();
        await this.getUniquePayeeTypesDropDown();
        const payeeTypes = this.state.pmsPayeeTypeDropDown;
        const payeeTypeIds = [];
        payeeTypes.map((eachLocation, i) => {
            payeeTypeIds.push(eachLocation.PMStypeID)
        })
        const payeeTypesString = payeeTypeIds.toString();
        await this.getPmsPayeeData(locationString, payeeTypesString);

        await this.setState({
            rowData: this.state.pmsTableData.map(eachItem => {
                return {
                    PMSID: eachItem.PMSID,
                    PMSType: eachItem.PMSType,
                    Mobile: eachItem.contactNumber,
                    fullName: eachItem.fullName,
                    Locationid: eachItem.Locationid,
                    PMSTypeID: eachItem.PMSTypeID,
                    city: eachItem.name
                }
            }),
            style: { display: 'none' }
        });
    }

    changeLocationPayee = async event => {
        event.preventDefault();
        await this.setState({
            selectedLocationPayeeDropDown: event.target.value,
        });
    };

    changePayeeType = async event => {
        event.preventDefault();
        await this.setState({ selectedPayeeTypeDropDown: event.target.value });
    };

    toggleOpenDeleteRequestModal = async (value, payeeName) => {
        await this.setState({
            isDeleteRequestModal: true,
            pmsId: value,
            pmsDeleteName: payeeName
        })
    }

    toggleCloseDeleteRequestModal = async () => {
        await this.setState({
            isDeleteRequestModal: false,
            pmsId: "",
            pmsDeleteName: ""
        })
    }

    toggleOpenPayeeDetailsModal = async (PMSID, payeeName, PMSType, Mobile, city, Locationid, PMSTypeID) => {
        await this.setState({
            isPayeeDetailsModal: true,
            payeeDetailsModalPmsId: PMSID,
            payeeDetailsModalPayeeName: payeeName,
            payeeDetailsModalCity: Locationid,
            payeeDetailsModalMobile: Mobile,
            payeeDetailsModalPmsType: PMSTypeID
        })
    }


    toggleClosePayeeDetailsModal = async () => {
        await this.setState({
            isPayeeDetailsModal: false,
            payeeDetailsModalPmsId: "",
            payeeDetailsModalPayeeName: "",
            payeeDetailsModalCity: "",
            payeeDetailsModalMobile: "",
            payeeDetailsModalPmsType: ""
        })
    }


    savePayeeDetailsModalApiCall = async () => {
        await this.setState({
            style: {}
        })
        await axios.post(`${Config.hostName}/pms/update/payee`, {
            "pmsId": this.state.payeeDetailsModalPmsId,
            "fullName": this.state.payeeDetailsModalPayeeName,
            "pmsTypeId": this.state.payeeDetailsModalPmsType,
            "locationId": this.state.payeeDetailsModalCity,
            "isCompany": "",
            "email": "",
            "contactNumber": this.state.payeeDetailsModalMobile,
            "contactNumber02": "",
            "attachments": "",
            "isActive": ""
        })
            .then(async res => {
                if (res.data.success == true) {
                    await this.componentWillMount();
                    await this.setState({
                        style: { display: "none" }
                    })
                    await this.toggleClosePayeeDetailsModal();
                    alert("Succesfully Updated");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert(res.data.message);

                }
            })
            .catch(async err => {
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
                // console.log(err);
            });
    }

    savePayeeDetailsModal = async (e) => {
        e.preventDefault();
        await this.savePayeeDetailsModalApiCall();
    }

    deleteRequestApiCall = async () => {
        if (this.state.pmsId) {
            await this.setState({
                isDeleteRequestModalLoading: true
            })
            const data = await axios.post(`${Config.hostName}/pms/delete/payee`, {
                pmsId: this.state.pmsId
            })
                .then(async (res) => {
                    const data = res.data;
                    if (data.success) {
                        await this.componentWillMount();
                        await this.setState({
                            isDeleteRequestModal: false,
                            isDeleteRequestModalLoading: false,
                            pmsId: ""
                        })
                        alert("Deleted Succesfully")
                    }
                    else {
                        await this.setState({
                            isDeleteRequestModalLoading: false,
                            pmsId: ""
                        })
                        alert(data.message)
                    }
                }).catch((err) => {
                    alert(err)
                });
        }
    }

    render() {
        let style = this.state.style;

        const {
            isAddPayeeModalOpen, addPayeeAttachments, isDeleteRequestModal, isDeleteRequestModalLoading, isPayeeDetailsModal
        } = this.state;

        const defaultColDef = {
            flex: 1,
            minWidth: 90,
            resizable: true
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;


        return (
            <div>

                <div id='cover-spin' style={style}></div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="locationid"
                                                    value={this.state.pmsLocationId}
                                                    id="locationid"
                                                    onChange={event => {
                                                        this.setState({ pmsLocationId: event.target.value });
                                                    }}                                                >
                                                    <option value={""} disabled selected >Select Location</option>
                                                    {this.state.pmsCitiesDropDown.map(locationid => (
                                                        <option value={locationid.Locationid}>
                                                            {locationid.name}
                                                        </option>
                                                    ))}
                                                    <option value={2222}  >All Cities</option>

                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="VendorName"
                                                    value={this.state.pmsTypeId}
                                                    id="VendorName"
                                                    onChange={event => {
                                                        this.setState({ pmsTypeId: event.target.value });
                                                    }}
                                                >
                                                    <option value={""} disabled selected  >Select Payee Type</option>
                                                    {this.state.pmsPayeeTypeDropDown.map(vendor => (
                                                        <option value={vendor.PMStypeID}>
                                                            {vendor.PMSType}
                                                        </option>
                                                    ))}
                                                    <option value={2222}  >All</option>

                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                // type="submit"
                                                // className="btn btn-secondary"
                                                className={(!this.state.pmsTypeId || !this.state.pmsLocationId) ? 'btn btn-danger w-100 overflow-hidden mb-3 px-0 disabled' : 'btn btn-danger w-100 overflow-hidden mb-3 px-0'}
                                                // "btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.handleSearchShipment}
                                                disabled={!this.state.pmsTypeId || !this.state.pmsLocationId}
                                            >
                                                Search
                      </button>
                                        </div>

                                        <div className="col-sm-2">
                                            {/* <Link to={"/admin/vms/vendors/add-vendor"}> */}
                                            <button
                                                type="submit"
                                                onClick={() =>
                                                    this.setState({
                                                        isAddPayeeModalOpen: true
                                                    })
                                                }
                                                // className="btn btn-secondary"
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                            >
                                                Add Payee
                        </button>
                                            {/* </Link> */}
                                        </div>

                                    </div>
                                </nav>

                                <div className="card-body">
                                    <div
                                        className="ag-theme-balham"
                                        style={{
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={this.state.rowData}
                                            defaultColDef={defaultColDef}
                                            onGridReady={this.onGridReady}
                                            enableCellTextSelection={true}
                                            rowHeight={50}
                                            domLayout={this.state.domLayout}
                                            // enableCellTextSelection={true}
                                            suppressAutoSize={true}
                                            gridAutoHeight={true}
                                        >
                                        </AgGridReact>
                                    </div>
                                    {/* <div style={{ margin: "5vh auto", marginLeft: "25%", justifyContent: "center" }}> */}

                                    <div style={{ margin: "10vh auto", marginLeft: "25%", justifyContent: "center" }}>


                                    </div>
                                </div>

                                {/* Modal For Add Payee */}

                                <div>
                                    {isAddPayeeModalOpen && <Modal
                                        // size="lg"
                                        style={{ maxWidth: '1600px', width: '70%' }}
                                        centered={true}
                                        isOpen={isAddPayeeModalOpen}
                                    // toggle={this.toggleAddPayeeBankModal}
                                    >

                                        <ModalFooter>

                                            <div className="col-sm-3">
                                                <span className="bms-view-req-modal-header-labelAddPayee">Add Payee Details</span>
                                                {/* <span className="bms-view-req-modal-header-label">PmsTypeId : {this.state.selectedPayeeTypeDropDown}</span> */}
                                            </div>

                                            <div className="col-sm-3 ml-auto">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Button
                                                            color="danger"
                                                            onClick={this.toggleAddPayeeModal}
                                                        >
                                                            <CloseIcon />
                                                        </Button>{" "}
                                                    </div>
                                                </div>
                                            </div>

                                        </ModalFooter>

                                        <ModalBody>
                                            <div className="bms-view-req-modal-body">

                                                <form >
                                                    <div
                                                        className="col-lg-12 text-right"
                                                        style={{ paddingTop: "4vh" }}
                                                    >
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="grid">
                                                            <div className="grid-body">
                                                                <div className="item-wrapper">
                                                                    <div className="row mb-3">
                                                                        <div className="col-md-12 mx-auto">
                                                                            <div className="form-row">

                                                                                {/** Select Payee Location */}
                                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>City</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <FormGroup>
                                                                                            <Input
                                                                                                type="select"
                                                                                                name="locationid"
                                                                                                value={this.state.selectedLocationPayeeDropDown}
                                                                                                id="locationid"
                                                                                                onChange={this.changeLocationPayee}
                                                                                            >
                                                                                                <option value={""} disabled selected>Select Payee Location</option>

                                                                                                {this.state.pmsCitiesDropDown.length > 1 && this.state.pmsCitiesDropDown.map(locationid => (

                                                                                                    <option value={locationid.Locationid}>
                                                                                                        {locationid.name}

                                                                                                    </option>
                                                                                                ))}
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </div>

                                                                                {/** Select Payee Location */}
                                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Payee Type</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <Input
                                                                                            type="select"
                                                                                            name="payeeType"
                                                                                            value={this.state.selectedPayeeTypeDropDown}
                                                                                            id="payeeType"
                                                                                            onChange={this.changePayeeType}
                                                                                        >
                                                                                            <option value={""} disabled selected>Select Payee Type</option>
                                                                                            {this.state.pmsPayeeTypeDropDown.map(payeeTypeItem => (
                                                                                                <option value={payeeTypeItem.PMStypeID}>
                                                                                                    {payeeTypeItem.PMSType}
                                                                                                </option>
                                                                                            ))}

                                                                                        </Input>
                                                                                    </div>
                                                                                </div>



                                                                            </div>
                                                                            {/** Name, Email */}
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Name"
                                                                                            formControlName="addPayeeName"
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={this.state.addPayeeName}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    addPayeeName: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Email</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Email"
                                                                                            formControlName="addPayeeEmail"
                                                                                            type="email"
                                                                                            className="form-control"
                                                                                            value={this.state.addPayeeEmail}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    addPayeeEmail: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/** Contact, Contact2 */}
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Mobile</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Mobile No"
                                                                                            formControlName="addPayeeContactNumber"
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={this.state.addPayeeContactNumber}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    addPayeeContactNumber: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Contact</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Alternate Mobile No"
                                                                                            formControlName="addPayeeContactNumberAlternate"
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={this.state.addPayeeContactNumberAlternate}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    addPayeeContactNumberAlternate: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Attachments Details */}

                                                                            <div>

                                                                                <div className="row ">

                                                                                    <div className="bms-create-req-attachments-main">

                                                                                        <span className="bms-create-req-attachments-label">Attachments : </span>

                                                                                        <label className="custom-file-upload">
                                                                                            {/* <button
                                                                type="submit"
                                                                className="btn bms-attachments-card-button ml-auto"
                                                            > */}
                                                                                            <input
                                                                                                name="Upload"
                                                                                                type="file"
                                                                                                className="bms-attachments-card-button ml-auto"
                                                                                                multiple
                                                                                                onChange={this.onFileUploadAddPayee}
                                                                                            />
                                                                                            <CloudUploadIcon />  Upload

                                                        {/* </button> */}
                                                                                        </label>

                                                                                    </div>

                                                                                </div>

                                                                                <div className="row">

                                                                                    <div className="bms-create-request-attachments">
                                                                                        {addPayeeAttachments.length > 0 && addPayeeAttachments.map((item, i) => {
                                                                                            return <div key={i} className="row bms-create-request-attachments-main-card">
                                                                                                <div key={i} className="bms-attachments-card">
                                                                                                    <span className="bms-attachments-card-item">

                                                                                                        <div className='bms-attachments-card-item-file-name-main'>
                                                                                                            <span className="bms-attachments-card-item-file-name">{item.name}</span>
                                                                                                            <span
                                                                                                                className="bms-attachments-card-button-delete"
                                                                                                                onClick={() => this.deleteAttachmentAddPayee(item.name)}
                                                                                                            >
                                                                                                                <DeleteIcon />
                                                                                                            </span>
                                                                                                        </div>

                                                                                                        {!item.isUploaded &&
                                                                                                            <span className="bms-attachments-card-progress-bar-loader">
                                                                                                                <LinearProgress />
                                                                                                            </span>
                                                                                                        }

                                                                                                        {item.isUploaded && <span className="bms-attachments-card-item-file-success-status">File Uploaded Succesfully</span>}
                                                                                                    </span>

                                                                                                </div>
                                                                                            </div>

                                                                                        })}

                                                                                    </div>
                                                                                </div>

                                                                            </div>


                                                                            {/** Bank Details */}
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Bank Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Bank Name"
                                                                                            formControlName="addPayeeBankName"
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={this.state.addPayeeBankName}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    addPayeeBankName: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Account No</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Account No"
                                                                                            formControlName=" addPayeeAccountNumber"
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={this.state.addPayeeAccountNumber}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    addPayeeAccountNumber: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/** Bank Details */}
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>IFSC Code</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter IFSC Code"
                                                                                            formControlName="addPayeeIfsc"
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={this.state.addPayeeIfsc}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    addPayeeIfsc: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Benificary Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Benificary Name"
                                                                                            formControlName="addPayeeBeneficiaryName"
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={this.state.addPayeeBeneficiaryName}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    addPayeeBeneficiaryName: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-outline-primary btn-lg"
                                                                                style={{ float: "right" }}
                                                                                onClick={e => this.createPayee(e)}
                                                                            >
                                                                                Add
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </ModalBody>


                                    </Modal>
                                    }
                                </div>

                                {/* Modal For Save Payee Details */}

                                <div>
                                    {isPayeeDetailsModal && <Modal
                                        size="lg"
                                        style={{ maxWidth: '1600px', width: '70%' }}
                                        centered={true}
                                        isOpen={isPayeeDetailsModal}
                                    // toggle={this.toggleAddPayeeBankModal}
                                    >
                                        <ModalFooter>

                                            <div className="col-sm-3 ml-auto">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Button
                                                            color="danger"
                                                            onClick={this.toggleClosePayeeDetailsModal}
                                                        >
                                                            <CloseIcon />
                                                        </Button>{" "}
                                                    </div>
                                                </div>
                                            </div>


                                        </ModalFooter>

                                        <ModalBody>
                                            <div className="bms-view-req-modal-body">

                                                <form >
                                                    <div
                                                        className="col-lg-12 text-right"
                                                        style={{ paddingTop: "4vh" }}
                                                    >
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="grid">
                                                            <div className="grid-body">
                                                                <div className="item-wrapper">
                                                                    <div className="row mb-3">
                                                                        <div className="col-md-12 mx-auto">

                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Payee Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Payee Name"
                                                                                            formControlName="addPayeeBankBankName"
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={this.state.payeeDetailsModalPayeeName}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    payeeDetailsModalPayeeName: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Mobile No</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <input
                                                                                            placeholder="Enter Mobile No"
                                                                                            formControlName=" addPayeeBankAccountNumber"
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={this.state.payeeDetailsModalMobile}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    payeeDetailsModalMobile: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>Payee Type</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <Input
                                                                                            placeholder="Enter IFSC Code"
                                                                                            formControlName="addPayeeBankIfsc"
                                                                                            type="select"
                                                                                            className="form-control"
                                                                                            value={this.state.payeeDetailsModalPmsType}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    payeeDetailsModalPmsType: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <option value={""} disabled selected  >Select Payee Type</option>
                                                                                            {this.state.pmsPayeeTypeDropDown.map(vendor => (
                                                                                                <option value={vendor.PMStypeID}>
                                                                                                    {vendor.PMSType}
                                                                                                </option>
                                                                                            ))}
                                                                                        </Input>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col-md-6 row showcase_row_area">
                                                                                    <div className="col-md-3 showcase_text_area">
                                                                                        <label>City</label>
                                                                                    </div>
                                                                                    <div className="col-md-9 showcase_text_area">
                                                                                        <Input
                                                                                            placeholder="Enter City Name"
                                                                                            formControlName="addPayeeBankBeneficiaryName"
                                                                                            type="select"
                                                                                            className="form-control"
                                                                                            value={this.state.payeeDetailsModalCity}
                                                                                            onChange={event => {
                                                                                                this.setState({
                                                                                                    payeeDetailsModalCity: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <option value={""} disabled selected >Select Location</option>
                                                                                            {this.state.pmsCitiesDropDown.map(locationid => (
                                                                                                <option value={locationid.Locationid}>
                                                                                                    {locationid.name}
                                                                                                </option>
                                                                                            ))}
                                                                                        </Input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-sm-3">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-danger overflow-hidden mb-3 px-auto editPayeeBankModalSaveButton"
                                                                                        onClick={e => this.savePayeeDetailsModal(e)}
                                                                                    >
                                                                                        Update
                                                                                        </button>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </ModalBody>


                                    </Modal>
                                    }
                                </div>

                                {/** Modal For Delete Payee */}
                                <div>
                                    <Modal
                                        size="lg"
                                        // style={{ maxWidth: '1600px', width: '70%' }}
                                        centered={true}
                                        isOpen={isDeleteRequestModal}
                                    >
                                        <ModalHeader>Do you want to delete Payee {this.state.pmsDeleteName} ?</ModalHeader>
                                        <ModalFooter>
                                            <Button
                                                color="success"
                                                disabled={isDeleteRequestModalLoading}
                                                onClick={this.deleteRequestApiCall}
                                            >
                                                {isDeleteRequestModalLoading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {isDeleteRequestModalLoading && <span>Deleting ...</span>}
                                                {!isDeleteRequestModalLoading && <span>Delete</span>}
                                            </Button>{" "}
                                            <Button
                                                color="danger"
                                                onClick={this.toggleCloseDeleteRequestModal}
                                                disabled={isDeleteRequestModalLoading}
                                            >
                                                No
                                                </Button>{" "}
                                        </ModalFooter>

                                    </Modal>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}

            </div>
        );
    }


}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);

