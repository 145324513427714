import React, { Component } from "react";
import "../../../../../assets/customCssFiles/tmshomepage.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../../redux/actions/alert.actions";
import ChartistGraph from "react-chartist";
// import Chartist from "chartist";
// import * as ChartistTooltips from 'chartist-plugin-tooltips';


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cmtGraphData: {
                labels: [],
                series: [],
            },
            data: [],
            isCmtGraphData: false
        };
    }

    componentWillMount = async () => {
        console.log(this.props.data);
        if (this.props.data) {
            await this.setState({
                data: this.props.data,
                isCmtGraphData: true
            })
        }

    }


    render() {
        const { isCmtGraphData } = this.state;
        const { cmtGraphData } = this.state;
        let cmtGraphOptions;

        if (isCmtGraphData) {
            const cmtFirstGraphData = this.state.data[0]
            // console.log(cmtFirstGraphData, "currentMonthTrendByServiceType");
            let seriesArrayAdhoc = [];
            let seriesArrayRegular = []
            cmtFirstGraphData.map((item, i) => {
                if (item.serviceType === "Adhoc") {
                    return (
                        cmtGraphData.labels.push(item.Tripdate),
                        seriesArrayAdhoc.push(item.tripCount)
                    )
                }
                else {
                    return seriesArrayRegular.push(item.tripCount)
                }
            })
            cmtGraphData.series.push(seriesArrayAdhoc, seriesArrayRegular)
            console.log(cmtGraphData, "cmtGraphData");

            cmtGraphOptions = {
                fullWidth: true,
                height: "300px",
                low: 0,
                axisX: {
                    offset: 20,
                    labelInterpolationFnc: function (value, index) {
                        return index % 1 === 0 ? value : null;
                    },
                },
                axisY: {
                    onlyInteger: true,
                    offset: 20,
                },



            };
        }

        // let plugins= [ChartistTooltips]
        // plugins: [
        //     Chartist.plugins.ctPointLabels({
        //         textAnchor: 'middle',
        //         labelInterpolationFnc: function (value) {
        //             console.log("i was called");
        //             return '$' + value
        //         }
        //     }),
        // Chartist.plugins.tooltips(),
        // Chartist.plugins.ctThreshold({
        //     threshold: 4
        // })

        //     Chartist.plugins.tooltip({

        //     })
        // ]

        return (
            <div className="main-home-page" >
                <div className="container-fluid">
                    <div className="row" style={{ height: "100%" }}>

                        {isCmtGraphData &&
                            <div className="col-xl-12 col-lg-12 mt-5">
                                <div
                                    className="card"
                                    id="card-for-graph-homepage"
                                    style={{ borderRadius: "0.5rem" }}
                                >
                                    <div
                                        className="card-header p-0"
                                        style={{
                                            width: "90%",
                                            position: "absolute",
                                            top: "-20px",
                                            right: "0",
                                            borderRadius: "0.5rem",
                                            boxShadow: "0px 0px 5px 0px #888888",
                                            marginLeft: "5%",
                                            marginRight: "5%",
                                            height: "300px",
                                            maxHeight: "300px",
                                        }}
                                    >
                                        <div
                                            className="top-sale-chart"
                                            style={{ height: "100%", maxHeight: "100%" }}
                                        >
                                            <ChartistGraph
                                                Line
                                                data={cmtGraphData}
                                                options={cmtGraphOptions}
                                                type={"Line"}

                                            />
                                        </div>
                                    </div>
                                    <div className="card-body p-1">
                                        <h4 style={{ color: "#01CBC6", fontWeight: "bold" }}>
                                            Current Month Trend
                  </h4>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
