import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import './layout.css';
import Select from '@mui/material/Select';
import { Card, Snackbar, Paper, Collapse, Alert, AlertTitle, Toolbar, AppBar, Dialog, Slide, Tooltip, IconButton, Grid, Container, Typography, TextField, Button, FormControl, MenuItem, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlusIcon from '@mui/icons-material/Add';

import MUIDataTable from "mui-datatables";
import { FormatColorResetOutlined } from "@material-ui/icons";

import {
    withGoogleMap,
    Polygon,
    withScriptjs,
    GoogleMap,
    Marker,
    InfoWindow,
    Geocoder
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";

import mapStyles from "./Mapstyles";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager"



const Item3 = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "806px",
        },
    },
};

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

const options = {
    filterType: 'multiselect',
    // filterOptions: {fullWidth: true},
    selectableRows: 'none',
    setRowProps: row => {
        if (row[0] === "New") {
            return {
                style: { background: "snow" }
            };
        }
    },
    draggableColumns: { enabled: true },
    pagination: true,
    // resizableColumns: true,
    // responsive: "standard",
    fixedHeader: false,
    downloadOptions: {
        filterOptions: {
            useDisplayedRowsOnly: true,
        }
    }
};



class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            selectedDate: new Date(),
            heading: '',
            unique_states: [],
            // stateValue: '',
            // unique_clientNames: [],
            // clientID: '',
            // clientName: '',
            // clientNameState: 'newClientAdd',
            // clientNameDropDown: '',
            // billingName: '',
            // address: '',
            // gst: '',
            submitButton: '',
            // successText: '',
            // alertState: false,
            // rowData: [],
            shape: [],
            lat: 12.988937,
            long: 77.689320,
            zoom: 18,
            zoomMain: 11,
            searchValue: '',
            isPolygon: 2,
            markerPoints: [],
            polygonCoordinates: [],
            uniqueLocationsDropDown: [],
            addLocation: '',
            unique_hubTypes: [],
            hubType: '',
            hubName: '',
            vertical: 'top',
            horizontal: 'center',
            snackBar: false,
            type: '',
            message: '',
            geoID: '',
            locationID: '',
            unique_goefence: [],
            viewPolygon: [],
            viewMarkers: [],
            viewLat: 12.959660559534667,
            viewLng: 77.58151665527346,
            searchLatLng: '',


        };
        this.validator = new SimpleReactValidator();
    }

    delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    getUniqueLocationsDropDown = async () => {
        await axios.get(`${Config.hostName}/unique/locations`)
            .then(async (res) => {
                await this.setState({
                    uniqueLocationsDropDown: res.data.data
                })
            })
            .catch((error) => {
                alert("getUniqueLocationsDropDown : ", error);
            });
    }

    searchLatLngButton  = async (e) => {
        
        const newArray = this.state.searchLatLng.split(",");
        const lat = Number(newArray[0].replace(" ", ""));
        const lng = Number(newArray[1].replace(" ", ""));


        console.log(newArray[0].replace(" ", ""));
        console.log(newArray[1].replace(" ", ""));

          await this.setState({
                lat: lat,
                long: lng,
                markerPoints: [{
                    lat: lat,
                    lng: lng,
                    timeStamp: new Date()
                }]
            })
            console.log(this.state.markerPoints);
    }

    handleChangelocationID = async (e) => {
        await this.setState({
            style: {}
        });
        await this.setState({
            locationID: e.target.value
        });
        await this.getNewGeofence();
        await this.setState({
            style: {
                display: "none"
            }
        });
    }

    markPoints = async (i) => {
        let data = this.state.unique_goefence;
        let viewPolygon = [];
        let viewMarkers = [];
        if (i == 1) {
            data.map((e) => {
                if (e.GeoType == 'Polygon') {
                    let add = [];
                    const split = e.latlong.split(", ");
                    split.map((e) => {
                        const latlng = e.split(" ");
                        // console.log(latlng, 'split');
                        const lat = Number(latlng[0]);
                        const lng = Number(latlng[1]);
                        const addnew = { lat: lat, lng: lng };
                        add.push(addnew);
                        // add = [{ lat: latlng[0], lng: latlng[1] }];
                    })
                    viewPolygon.push(add);
                    // console.log(add, 'add');

                } else {
                    const latlng = e.latlong.split(" ");
                    const lat = Number(latlng[0]);
                    const lng = Number(latlng[1]);
                    const addnew = { lat: lat, lng: lng };
                    viewMarkers.push(addnew);
                }
            })
            this.setState({
                viewPolygon: viewPolygon,
                viewMarkers: viewMarkers
            })
            // console.log(this.state.viewMarkers, 'view POLI');
        } else {
            data.map((e) => {
                if (e.isSelected) {


                    if (e.GeoType == 'Polygon') {
                        let add = [];
                        const split = e.latlong.split(", ");
                        split.map((e) => {
                            const latlng = e.split(" ");
                            // console.log(latlng, 'split');
                            const lat = Number(latlng[0]);
                            const lng = Number(latlng[1]);
                            const addnew = { lat: lat, lng: lng };
                            add.push(addnew);
                            // add = [{ lat: latlng[0], lng: latlng[1] }];
                        })
                        viewPolygon.push(add);
                        // console.log(add, 'add');

                    } else {
                        const latlng = e.latlong.split(" ");
                        const lat = Number(latlng[0]);
                        const lng = Number(latlng[1]);
                        const addnew = { lat: lat, lng: lng };
                        viewMarkers.push(addnew);
                    }
                }
            })

            this.setState({
                viewPolygon: viewPolygon,
                viewMarkers: viewMarkers
            })
            // console.log(this.state.viewMarkers, 'view POLI');
        }
    }

    getNewGeofence = async () => {
        const res = await axios.post(
            `${Config.hostName}/geofencing/getGeoFence`,
            {
                locationID: this.state.locationID,
                geoID: this.state.geoID,
            }
        )
            .then(async (res) => {
                console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_goefence: res.data.data.map(eachItem => {
                            return {
                                City: eachItem.City,
                                GeoID: eachItem.GeoID,
                                GeoType: eachItem.GeoType,
                                Hubname: eachItem.Hubname,
                                Hubtype: eachItem.Hubtype,
                                createddate: eachItem.createddate,
                                latlong: eachItem.latlong,
                                isSelected: false,
                            }
                        }),
                    });
                    await this.markPoints(1);

                } else {
                    await this.setState({
                        snackBar: true,
                        message: 'No records found!!',
                        type: 'error',
                    })

                }
            })
            .catch((error) => {
                alert(error);
            });
    }


    handleChangeViewGeoFence = async (index, isSelected) => {
        console.log(index, 'index');
        let arr = this.state.unique_goefence;
        arr[index]['isSelected'] = !isSelected;
        await this.setState({
            unique_goefence: arr
        })
        await this.markPoints(2);

    }



    getUniqueHubType = async () => {
        const res = await axios.get(
            `${Config.hostName}/geofencing/hubTypeDD`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_hubTypes: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');

                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    handleCloseSnackBar = async () => {
        await this.setState({
            snackBar: false,
        })
    }



    async componentDidMount() {
        await this.setState({
            style: {}
        });
        await this.getNewGeofence();
        this.getUniqueLocationsDropDown();
        this.getUniqueHubType();
        await this.setState({
            style: {
                display: "none"
            }
        });
    }



    handleOpenFullModal = async (data, e) => {
        if (data == 'add') {
            await this.setState({
                heading: 'Add New Geofence Location',
                submitButton: 'Submit',
                openFullModal: true,
                clientID: '',
                clientName: '',
                billingName: '',
                stateValue: '',
                address: '',
                gst: '',
            });
        } else {
            console.log(this.state.rowData[data]);
            await this.setState({

            });
        }
        await this.setState({

            openFullModal: true,
        });
    };

    handleCloseFullModal = async (e) => {
        await this.setState({
            openFullModal: false,
        });
    };

    onSubmitModal = async (e) => {
        e.preventDefault();

        await this.setState({
            submitButton: 'Please wait...',
        });
        const locationID = this.state.addLocation;
        const geoTypeID = this.state.isPolygon;
        const hubName = this.state.hubName;
        const hubType = this.state.hubType;

        console.log(this.state.isPolygon, 'geoID');
        console.log(this.state.markerPoints, 'marker');
        console.log(this.state.polygonCoordinates, 'polygon');

        let latlong = '';
        let count = 1;
        let first = '';
        if (geoTypeID == 2) {
            this.state.polygonCoordinates.map((e, key) => {
                if (count == 1) {
                    latlong = `${e.lat} ${e.lng}`;
                    first = `${e.lat} ${e.lng}`;
                } else {
                    latlong = `${latlong}, ${e.lat} ${e.lng}`;
                }
                count++;
            })
            latlong = `${latlong}, ${first}`;
            if (latlong != ', ') {
                const res = await axios.post(
                    `${Config.hostName}/geofencing/manageGeoFencing`,
                    {
                        locationID: locationID,
                        latlong: latlong,
                        geoTypeID: geoTypeID,
                        hubName: hubName,
                        hubType: hubType,
                        userID: this.props.auth.user.USERID,
                        geoID: "",

                    }
                )
                    .then(async (res) => {
                        console.log(res);
                        if (res.data.success != false) {
                            this.setState({
                                openFullModal: false,
                            })
                            this.getNewGeofence();
                            this.setState({
                                submitButton: 'Submit',
                                addLocation: "",
                                hubName: '',
                                hubType: '',
                                isPolygon: 2,
                                polygonCoordinates: [],
                                markerPoints: [],
                                snackBar: true,
                                message: 'Coordinates Added',
                                type: 'success',
                            });



                        } else {

                            await this.setState({
                                submitButton: 'Submit',
                                snackBar: true,
                                message: 'Please Mark Coordinates on Map!!!',
                                type: 'error',
                            });

                        }


                    })
                    .catch((error) => {
                        alert(error);
                    });
            } else {
                this.setState({
                    snackBar: true,
                    message: 'Please Mark Coordinates on Map!!!',
                    type: 'error',
                    submitButton: 'Submit'
                })
            }
        } else {
            await this.state.markerPoints.map(async (e, key) => {
                latlong = `${e.lat} ${e.lng} `;
            })
            if (latlong != '') {
                const res = await axios.post(
                    `${Config.hostName}/geofencing/manageGeoFencing`,
                    {
                        locationID: locationID,
                        latlong: latlong,
                        geoTypeID: geoTypeID,
                        hubName: hubName,
                        hubType: hubType,
                        userID: this.props.auth.user.USERID,
                        geoID: "",

                    }
                )
                    .then(async (res) => {
                        console.log(res);
                        if (res.data.success != false) {
                            this.setState({
                                submitButton: 'Submit',
                                addLocation: "",
                                hubName: '',
                                hubType: '',
                                isPolygon: 2,
                                polygonCoordinates: [],
                                markerPoints: [],
                                snackBar: true,
                                message: 'Coordinates Added',
                                type: 'success',
                            });
                            await this.delay(300);
                            this.setState({
                                openFullModal: false,
                            })

                        } else {

                            await this.setState({
                                submitButton: 'Submit',
                                snackBar: true,
                                message: 'Please Mark Coordinates on Map!!!',
                                type: 'error',
                            });

                        }


                    })
                    .catch((error) => {
                        alert(error);
                    });
            } else {
                this.setState({
                    snackBar: true,
                    message: 'Please Mark Coordinates on Map!!!',
                    type: 'error',
                    submitButton: 'Submit'

                })
            }
        }


    };

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    deleteShapes() {
        this.shapes.forEach(shape => shape.setMap(null));
    }


    handleSearchLocationView = async (e) => {
        if (e.geometry != null) {
            this.setState({
                viewLat: e.geometry.location.lat(),
                viewLng: e.geometry.location.lng(),
                zoom: 13,
            })
        }

        this.setState({
            searchValue: e.formatted_address
        })


    }

    handleSearchLocation = async (e) => {
        console.log(e);
        // const res = await axios.get(
        //     `https://maps.googleapis.com/maps/api/place/details/json?placeid=${e.place_id}&key=AIzaSyASWTjcuExA_q23BLOOB9dMuiitr4tAgiE`,
        //     {
        //         headers: {
        //             "Access-Control-Allow-Origin":'*',
        //             "Content-Type": "application/json"
        //         },
        //     }

        // )
        //     .then(async (res) => {
        //         console.log(res.geometry);
        //     })


        //    console.log(e.geometry.location.lat());
        //    console.log(e.geometry.location.long());
        if (e.geometry != null) {
            this.setState({
                lat: e.geometry.location.lat(),
                long: e.geometry.location.lng(),
                zoom: 13,
            })
        }

        this.setState({
            searchValue: e.formatted_address
        })


    }

    handleChangeIsPolygon = async (e) => {
        await this.setState({
            isPolygon: e.target.value
        })
        if (e.target.value == 2) {
            await this.setState({
                markerPoints: [],
                lat: 12.988937,
                long: 77.689320,
                searchLatLng: '',
            })
        } else {
            await this.setState({
                polygonCoordinates: [],
                
            })
        }

    }

    handleOverlayComplete = e => {
        // this.setState({ shape: [] });
        // this.setState({
        //     lat:17,
        //     long: 20
        // })

        const coords = e
            .getPath()
            .getArray()
            .map((coord) => {
                return {
                    lat: coord.lat(),
                    lng: coord.lng(),
                };
            });
        console.log('OpenModal', coords);
        console.log('OpenModal', this.state.shape);
        this.setState({
            polygonCoordinates: coords
        })
    }

    handleMarkerChange = (e) => {

        this.setState({
            markerPoints: [{
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
                timeStamp: new Date()
            }]
        });
        console.log(this.state.markerPoints);
    }

    MapView = () => {

        return (
            <GoogleMap
                defaultZoom={this.state.zoomMain}
                defaultCenter={{ lat: this.state.viewLat, lng: this.state.viewLng }}
            // defaultOptions={{ styles: mapStyles }}

            >


                <Polygon
                    paths={this.state.viewPolygon}
                    key={1}
                    editable={false}
                    options={{
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35
                    }}
                />

                {this.state.viewMarkers.map((e, key) => (
                    <Marker key={key} position={{ lat: e.lat, lng: e.lng }} >
                    </Marker>
                ))}




                <Autocomplete
                    className='searchMain'
                    onPlaceSelected={(place) => {
                        this.handleSearchLocationView(place);
                    }}
                    defaultValue={this.state.searchValue}
                    type={['(regions)']}
                />


            </GoogleMap>
        );
    }

    Map = () => {

        return (
            <GoogleMap
                defaultZoom={this.state.zoom}
                defaultCenter={{ lat: this.state.lat, lng: this.state.long }}
                // defaultOptions={{ styles: mapStyles }}
                onClick={
                    this.handleMarkerChange
                }
            >

                {this.state.markerPoints.map((e) => (
                    <Marker key={e.timeStamp.toISOString()} position={{ lat: e.lat, lng: e.lng }} >
                    </Marker>
                ))}




                {/* <Autocomplete
                    className='searchMarker'
                    onPlaceSelected={(place) => {
                        this.handleSearchLocation(place);
                    }}
                    defaultValue={this.state.searchValue}
                    type={['(regions)']}
                /> */}


            </GoogleMap>
        );
    }

    MapPolygon = () => {

        return (
            <GoogleMap
                defaultZoom={this.state.zoom}
                defaultCenter={{ lat: this.state.lat, lng: this.state.long }}
            // defaultOptions={{ styles: mapStyles }}
            >
                <Polygon
                    path={this.state.polygonCoordinates}
                    key={1}
                    editable={true}
                    options={{
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35
                    }}
                />

                <Autocomplete
                    className='search'
                    onPlaceSelected={(place) => {
                        this.handleSearchLocation(place);
                    }}
                    defaultValue={this.state.searchValue}
                    type={['(regions)']}
                />

                {this.state.polygonCoordinates != [] &&
                    <DrawingManager
                        defaultDrawingMode='polygon'
                        defaultOptions={{
                            drawingControl: true,
                            drawingControlOptions: {
                                draggable: true,
                                // position: google.maps.ControlPosition.TOP_CENTER,
                                drawingModes: [
                                    window.google.maps.drawing.OverlayType.POLYGON,
                                    // window.google.maps.drawing.OverlayType.RECTANGLE,
                                    // window.google.maps.drawing.OverlayType.CIRCLE,
                                    // window.google.maps.drawing.OverlayType.POLYLINE,
                                ],
                            },
                            polygonOptions: {
                                editable: true,

                            },
                        }}
                        onPolygonComplete={this.handleOverlayComplete}
                    />
                }

            </GoogleMap>
        );
    }

    render() {


        const MapWrappedView = withScriptjs(withGoogleMap(this.MapView));
        const MapWrapped = withScriptjs(withGoogleMap(this.Map));
        const MapWrappedPolygon = withScriptjs(withGoogleMap(this.MapPolygon));
        const { vertical, horizontal } = this.state;


        return (
            <div>

                <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "8%", marginTop: '7%' }} >
                    <div id='cover-spin' style={this.state.style}></div>

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={this.state.snackBar}
                        autoHideDuration={2000}
                        onClose={this.handleCloseSnackBar}
                        key={vertical + horizontal}
                    >
                        <Alert onClose={this.handleCloseSnackBar} severity={this.state.type} sx={{ width: '100%' }}>
                            {this.state.message}
                        </Alert>
                    </Snackbar>

                    <Grid pt={3} container>
                        <Grid item md={12} >
                            <Collapse in={this.state.alertState}>
                                <Alert onClose={() => {
                                    this.setState({
                                        successText: '',
                                        alertState: false,
                                    })
                                }} severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    {this.state.successText}
                                </Alert>
                            </Collapse>
                        </Grid>

                        <Grid style={{ float: 'left', textAlign: 'left', }} item md={2}>
                            <FormControl variant="standard" style={{ marginLeft: '0px', width: '100%' }}>
                                <InputLabel id="locationView">Location</InputLabel>
                                <Select
                                    labelId="locationView"
                                    id="locationView2"
                                    value={this.state.locationID}
                                    onChange={(e) => this.handleChangelocationID(e)}
                                    label="Billing Month"
                                    variant="standard"
                                >
                                    <MenuItem value="">
                                        <em>Location</em>
                                    </MenuItem>

                                    {this.state.uniqueLocationsDropDown.map((eachItem, key) => (
                                        <MenuItem value={eachItem.Locationid} key={key} >{eachItem.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item md={4}>

                        </Grid>

                        <Grid style={{ float: 'right', textAlign: 'right' }} item md={6}>
                            <Button onClick={(e) => this.handleOpenFullModal('add', e)} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<PlusIcon />}>
                                Add New
                            </Button>
                        </Grid>


                    </Grid>

                    <Grid pt={2} container>

                        <Grid style={{ overflowY: 'auto', maxHeight: "73vh", paddingRight: '12px', minHeight: "20vh", marginTop: '5px' }} item md={2}>

                            {this.state.unique_goefence.map((e, key) => (
                                <Paper elevation={1}>
                                    <Card onClick={(event) => { this.handleChangeViewGeoFence(key, e.isSelected) }} className={`${e.isSelected ? "active2" : 'details'} ${key != 0 && 'mt-2'}`} style={{ borderRadius: '4px' }}>
                                        <Item3 className='addPadding ' style={{ textAlign: 'left', boxShadow: 'none' }} >

                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <b>  {e.City} - {e.Hubname}</b><br />
                                                    <b > Geo Type: {e.GeoType}</b>
                                                </div>
                                            </div>
                                        </Item3>
                                    </Card>
                                </Paper>
                            ))}




                        </Grid>

                        <Grid item md={10}>

                            <div style={{ borderRadius: '4px', border: '1px solid black', width: "auto", height: "73vh" }}>
                                <MapWrappedView
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${Config.apiKey
                                        }`}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `100%` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                            </div>
                        </Grid>

                    </Grid>

                </Container>

                <Dialog fullScreen
                    TransitionComponent={this.Transition}
                    onClose={this.handleCloseFullModal} open={this.state.openFullModal}
                >
                    <Container maxWidth="xl" style={{ maxWidth: 'inherit', paddingLeft: '0px', paddingRight: '0px' }}>


                        <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleCloseFullModal}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                                <Button autoFocus color="inherit" onClick={this.handleCloseFullModal}>
                                    Close
                                </Button>
                            </Toolbar>
                        </AppBar>



                        {/* <Header order="rohan" /> */}
                        <Grid container style={{ background: '#fafafa' }}>
                            <Grid item md={12} style={{ padding: '0px 12px' }}>

                                <Grid pt={1} container>
                                    <Grid item md={12} style={{ marginTop: "2%" }} >
                                        <Paper elevation={8} >
                                            <Grid pt={1} container>
                                                <Grid item m={2} md={12}>
                                                    <Typography pt={2} style={{ paddingTop: '0px', textAlign: 'center' }} variant="h4"><b> {this.state.headingGenerate} Add GeoFencing
                                                    </b>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ Paper>

                                    </Grid>

                                    <Grid item md={12} style={{ marginTop: "23px" }} >

                                        <Paper elevation={8} style={{ padding: '25px', }}>
                                            <form onSubmit={this.onSubmitModal} method="POST">
                                                <Grid pt={1} container>
                                                    <Grid item md={2}>
                                                        <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                                                            <InputLabel id="statusUpdate">Select</InputLabel>
                                                            <Select
                                                                labelId="statusUpdate"
                                                                id="statusUpdate3"
                                                                value={this.state.isPolygon}
                                                                onChange={(e) => {
                                                                    this.handleChangeIsPolygon(e)
                                                                }}
                                                                label="Update Status"
                                                                variant="standard"
                                                                required
                                                            >
                                                                <MenuItem value="2">
                                                                    Polygon
                                                                </MenuItem>
                                                                <MenuItem value="1">
                                                                    Marker
                                                                </MenuItem>

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item className="formMargin" md={2}>
                                                        <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                                                            <InputLabel id="statusUpdate">Select Location</InputLabel>
                                                            <Select
                                                                labelId="statusUpdate"
                                                                id="statusUpdate3"
                                                                value={this.state.addLocation}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        addLocation: e.target.value
                                                                    })
                                                                }}
                                                                label="Select Location"
                                                                variant="standard"
                                                                required
                                                            >
                                                                <MenuItem value="">
                                                                    <em>Select Location</em>
                                                                </MenuItem>

                                                                {this.state.uniqueLocationsDropDown.map((eachItem, key) => (
                                                                    <MenuItem value={eachItem.Locationid} key={key} >{eachItem.name}</MenuItem>
                                                                ))}

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item className="formMargin" md={2}>
                                                        <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                                                            <InputLabel id="statusUpdate">Fence Type</InputLabel>
                                                            <Select
                                                                labelId="statusUpdate"
                                                                id="statusUpdate3"
                                                                value={this.state.hubType}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        hubType: e.target.value
                                                                    })
                                                                }}
                                                                label="Fence Type"
                                                                variant="standard"
                                                                required
                                                            >
                                                                <MenuItem value="">
                                                                    <em>Select Fence Type</em>
                                                                </MenuItem>

                                                                {this.state.unique_hubTypes.map((eachItem, key) => (
                                                                    <MenuItem value={eachItem.HubType} key={key} >{eachItem.HubType}</MenuItem>
                                                                ))}

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item className="formMargin" md={2}>
                                                        <TextField sx={{ width: '30ch' }} id="hubName" label="Hub Name"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    hubName: e.target.value,
                                                                });
                                                            }}
                                                            value={this.state.hubName}
                                                            variant="standard"
                                                            required
                                                        />
                                                    </Grid>


                                                    <Grid item md={2} style={{ textAlign: 'center' }}>
                                                        <Button type="submit" size="medium" variant="contained" style={{ marginTop: "16px", background: "rgb(234 67 68)", color: "white" }} endIcon={<SendIcon />}>
                                                            {this.state.submitButton}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Paper>
                                    </Grid>

                                    <Grid item md={12} style={{ marginTop: "23px" }} >

                                        <Paper elevation={8} style={{ padding: '25px', }}>
                                            <Grid pt={1} container>
                                                {/* <Grid item m={2} md={12}>
                                                    {this.state.isPolygon == 1 ? (
                                                        <>
                                                            Polygon Coordinates:
                                                            {this.state.polygonCoordinates.map((e) => (
                                                                <>
                                                                    <b style={{ fontFamily: 'sans-serif' }}>   &nbsp;  {e.lat} {e.lng}, </b>
                                                                </>
                                                            ))}
                                                        </>
                                                    ) :
                                                        (
                                                            <>
                                                                Marker Coordinates:
                                                                {this.state.markerPoints.map((e) => (
                                                                    <>
                                                                        <b style={{ fontFamily: 'sans-serif' }}>   &nbsp;  {e.lat} {e.lng}, </b>
                                                                    </>
                                                                ))}
                                                            </>
                                                        )
                                                    }


                                                </Grid> */}
                                                {this.state.isPolygon == 1 &&
                                                    <Grid item m={2} md={2}>
                                                        <TextField sx={{ width: '30ch' }} id="hubName" label="Lat, Lng"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    searchLatLng: e.target.value,
                                                                });
                                                            }}
                                                            value={this.state.searchLatLng}
                                                            variant="standard"
                                                        />
                                                    </Grid>
                                                }

                                                {this.state.isPolygon == 1 &&
                                                    <Grid item md={2} m={2} style={{ textAlign: 'center' }}>
                                                        <Button onClick={(e)=>{this.searchLatLngButton(e)}} type="button" size="medium" variant="contained" style={{ marginTop: "16px", background: "rgb(234 67 68)", color: "white" }} >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                }


                                                <Grid item m={2} md={12}>
                                                    {
                                                        this.state.isPolygon == 2 ?
                                                            <Grid item md={12}>
                                                                <div style={{ width: "auto", height: "60vh" }}>
                                                                    <MapWrappedPolygon
                                                                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${Config.apiKey
                                                                            }`}
                                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                                        containerElement={<div style={{ height: `100%` }} />}
                                                                        mapElement={<div style={{ height: `100%` }} />}
                                                                    />
                                                                </div>
                                                            </Grid> :
                                                            <Grid item md={12}>
                                                                <div style={{ width: "auto", height: "60vh" }}>
                                                                    <MapWrapped
                                                                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${Config.apiKey
                                                                            }`}
                                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                                        containerElement={<div style={{ height: `100%` }} />}
                                                                        mapElement={<div style={{ height: `100%` }} />}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                    }

                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>

                                </Grid>



                            </Grid>
                        </Grid>


                    </Container>

                </Dialog>







            </div>


        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(HomePage);
