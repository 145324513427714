/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import "../../../../../assets/customCssFiles/tmshomepage.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../../redux/actions/alert.actions";
import { Bar } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";

defaults.global.defaultFontFamily = "Arial";
defaults.global.defaultFontColor = "#808080";
defaults.global.defaultFontWeight = "200";
defaults.global.defaultLetterSpacing = "2px";
const arbitraryStackKey = "stack1";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barGraphByCityData: {
                labels: [],
                datasets: [
                    {
                        stack: arbitraryStackKey,
                        label: "",
                        backgroundColor: "",
                        maxBarThickness: "30",
                        borderColor: "",
                        borderWidth: 1,
                        fontSize: "12",
                        data: [],
                    }
                ]
            },
        };
    }

    componentWillMount = async () => {
        // console.log(this.props.data);
        const { data } = this.props;
        const { barGraphByCityData } = this.state;

        if (data) {
            let labels = [];
            let serviceTypesArrayBarStackGraph = [];
            data.map((barItem, i) => {
                return (
                    labels[i] = barItem.city,
                    serviceTypesArrayBarStackGraph[i] = barItem.serviceType
                )
            })
            let finalLabelsByCity = labels.filter((item, i, ar) => ar.indexOf(item) === i);
            let finalServiceTypesByCity = serviceTypesArrayBarStackGraph.filter((item, i, ar) => ar.indexOf(item) === i);
            barGraphByCityData.labels = finalLabelsByCity;
            finalServiceTypesByCity.map((serviceItem, i) => {
                let datasetsObj = {
                    stack: arbitraryStackKey,
                    label: "",
                    backgroundColor: "",
                    maxBarThickness: "30",
                    borderColor: "",
                    borderWidth: 1,
                    fontSize: "12",
                    data: [],
                };
                // console.log(serviceItem, i);
                let dataArray = [];
                data.filter(dataItem => {
                    if (dataItem.serviceType == serviceItem) {
                        dataArray.push(dataItem.tripCount)
                    }
                })
                if (serviceItem == "Adhoc") {
                    datasetsObj.backgroundColor = "#ff9999";
                    datasetsObj.borderColor = "#ff6666";
                }
                if (serviceItem == "Regular") {
                    datasetsObj.backgroundColor = "#9999ff";
                    datasetsObj.borderColor = "#6666ff";
                }
                datasetsObj.label = serviceItem;
                datasetsObj.data = dataArray;
                return barGraphByCityData.datasets[i] = datasetsObj;
            })
        }
        await this.setState({
            barGraphByCityData: barGraphByCityData
        })
    }

    render() {
        const { barGraphByCityData } = this.state;
        return (
            <div className="main-home-page" >
                <div className="container-fluid">
                    <div className="row" style={{ height: "100%" }}>
                        <div className="col-xl-12 col-lg-12 mt-5">
                            <div
                                className="card"
                                id="card-for-graph-homepage"
                                style={{ borderRadius: "0.5rem" }}
                            // style={{ backgroundImage: `url(${bgimage})` }}
                            >
                                <div
                                    className="card-header"
                                    style={{
                                        width: "90%",
                                        // height: "100px",
                                        position: "absolute",
                                        top: "-20px",
                                        // left: "10px",
                                        right: "0",
                                        paddingTop: "30px",
                                        borderRadius: "0.5rem",
                                        boxShadow: "0px 0px 5px 0px #888888",
                                        //justifyContent: "center",
                                        marginLeft: "5%",
                                        marginRight: "5%",
                                        height: "300px",
                                        maxHeight: "300px",
                                    }}
                                // style={{ backgroundColor: "#ffa726" }}
                                >
                                    <div
                                        className="top-sale-chart"
                                        id="tmsDashboardStackedBarChart"
                                        style={{ height: "100%", maxHeight: "100%" }}
                                    >
                                        {/* <CanvasJSChart options={options} /> */}
                                        {/* {(Chart.defaults.global.defaultFontColor = "red")} */}
                                        <Bar
                                            data={barGraphByCityData}
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "bottom",
                                                },
                                                axisY: {
                                                    stacked: true,
                                                },
                                                axisX: {
                                                    stacked: true,
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="card-body p-1">
                                    <h4 style={{ color: "#01CBC6", fontWeight: "bold" }}>
                                        By City
                  </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
