export const cardsData = [
  {
    vehicleNumber: "KA07UB6096",
    vehicleSize: "17",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Chennai, Tamil Nadu, India",
    speed: "60",
    stoppedSince: "1 hrs: 10 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6045",
    vehicleSize: "10",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Hyderabad, Telangana, India",
    speed: "65",
    stoppedSince: "1 hrs: 30 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6097",
    vehicleSize: "17",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Chennai, Tamil Nadu, India",
    speed: "60",
    stoppedSince: "1 hrs: 10 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6045",
    vehicleSize: "10",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Hyderabad, Telangana, India",
    speed: "65",
    stoppedSince: "1 hrs: 30 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6296",
    vehicleSize: "7",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Chennai, Tamil Nadu, India",
    speed: "60",
    stoppedSince: "1 hrs: 10 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6045",
    vehicleSize: "10",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Hyderabad, Telangana, India",
    speed: "65",
    stoppedSince: "1 hrs: 30 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6096",
    vehicleSize: "17",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Chennai, Tamil Nadu, India",
    speed: "60",
    stoppedSince: "1 hrs: 10 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6045",
    vehicleSize: "10",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Hyderabad, Telangana, India",
    speed: "65",
    stoppedSince: "1 hrs: 30 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6096",
    vehicleSize: "17",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Chennai, Tamil Nadu, India",
    speed: "60",
    stoppedSince: "1 hrs: 10 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
  {
    vehicleNumber: "KA07UB6145",
    vehicleSize: "22",
    startingPoint: "Bengaluru, Karnataka, India",
    endingPoint: "Hyderabad, Telangana, India",
    speed: "65",
    stoppedSince: "1 hrs: 30 min",
    driverName: "Siddaraju J",
    driverNumber: "9999999999",
  },
];


export const vehiclesData = ["V1", "V2", "V3", "V4", "V5", "V6"];