import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../../assets/customCssFiles/loader-spinner.css"


class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            active_tab: '1',
            loading: false
        }
    };

    componentDidMount() {
        setTimeout(function () {
            this.setState({ style: { display: 'none' } });
        }.bind(this), 1000)
    }

    toggle(tab) {
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }

    render() {
        let style = this.state.style;
        const backgroundHome = require('../../assets/images/landing/home/main_bg.jpg');

        return (
            <div>
                <div id='cover-spin' style={style}></div>

                {/*Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="page-body-wrapper landing-main">
                        <div className="landing-home" style={{ backgroundImage: "url(" + backgroundHome + ")" }}>
                            <div className="landing-header">
                                <div className="container-fluid">
                                    <nav className="navbar navbar-expand-lg">

                                        <a className="navbar-brand"
                                            href="https://logicarts.in/">
                                            <h3>Logicarts</h3>
                                        </a>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                            data-target="#collapsibleNavbar">
                                            <span className="navbar-toggler-icon">
                                                <i className="fa fa-bars"></i>
                                            </span>
                                        </button>
                                    </nav>
                                </div>
                            </div>
                            <div className="landing-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div
                                            className="col-lg-4 offset-lg-2 dashboard-image-pt-xs offset-md-1 col-md-5 offset-sm-0">
                                            <div className="landing-left">
                                                <div>
                                                    <h1>Logicarts<span className="txt-danger"> Admin </span>App
                                                    </h1>
                                                    <p className="pr-5">Please Login to Explore</p>

                                                    <Link to={`/login`}
                                                        className="btn btn-primary btn-lg"
                                                        data-original-title="btn btn-info btn-lg" title="">
                                                        Login
                                        </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pr-0 col-md-6">
                                            <img src={require('../../assets/images/logicarts.png')}
                                                className="img-fluid"
                                                alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tap-top">
                            <div>
                                <i className="fa fa-angle-double-up"></i>
                            </div>
                        </div>
                    </div>

                </div>
                {/*page-wrapper Ends*/}
            </div>
        )
    }
}


export default Landing;