import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  MENUITEMS,
  SUPERVISORMENUITEMS,
  FTSDMENUITEMS,
  ROYALOAKSFTSDMENUITEMS,
  ROYALOAKSUPERVISORMENUITEMS,
  CMSMENUITEMS,
  BMSMENUITEMS,
  LC_EMPLOYEE_MENU_ITEMS,
  LC_MANAGER_MENU_ITEMS,
  HR_MENU_ITEMS,
  TMSMENUITEMS,
  MIS2,
  MIS,
  LC_MANAGER_MENU_ITEMS_TMS
} from "../../../redux/constants/menu";
import "./sidebar.css";

// Import custom components
// import UserPanel from "./user-panel.component";

class Sidebar extends Component {
  state = { selectedPath: "1", mainmenu: [] };
  onItemSelection = (arg, e) => {
    this.setState({ selectedPath: arg.path });
  };

  async componentWillMount() {

    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "admin"
    ) {
      this.setState({
        mainmenu: MENUITEMS,
      });
    } else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "superAdmin"
    ) {
      this.setState({
        mainmenu: MENUITEMS,
      });
    } else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "supervisor"
    ) {
      this.setState({
        mainmenu: SUPERVISORMENUITEMS,
      });
    } else if (
      this.props.auth.isAuthenticated &&
      // this.props.auth.user.CCID == 1 &&
      this.props.auth.user.user_type === "ftsd"
    ) {
      this.setState({
        mainmenu: FTSDMENUITEMS,
      });
    } else if (
      this.props.auth.isAuthenticated &&
      (this.props.auth.user.user_type === "ROAdmin" ||
        this.props.auth.user.user_type === "ROSuperAdmin")
    ) {
      this.setState({
        mainmenu: ROYALOAKSFTSDMENUITEMS,
      });
    }
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "ROSupervisor"
    ) {
      this.setState({
        mainmenu: ROYALOAKSUPERVISORMENUITEMS,
      });
    } else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "cms"
    ) {
      this.setState({
        mainmenu: CMSMENUITEMS,
      });
    }

    else if (
      this.props.auth.isAuthenticated && (this.props.auth.user.user_type === "accounts" ||
        this.props.auth.user.user_type === "superAdmin")
    ) {
      this.setState({
        mainmenu: BMSMENUITEMS
      });
    }
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "TMS"
    ) {
      this.setState({
        mainmenu: TMSMENUITEMS
      });
    }
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "hr"
    ) {
      this.setState({
        mainmenu: HR_MENU_ITEMS
      });
    }
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "mis2"
    ) {
      this.setState({
        mainmenu: MIS2
      });
    }
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "mis"
    ) {
      this.setState({
        mainmenu: MIS
      });
    }
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "Manager" && (
        this.props.auth.user.USERID == "113" || this.props.auth.user.USERID == "244" ||
        this.props.auth.user.USERID == "1350")

    ) {
      this.setState({
        mainmenu: LC_MANAGER_MENU_ITEMS_TMS
      });
    }
    // 1350,113,244 USERID

    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "Employee"
    ) {

      if (this.props.auth.user.USERID == '1914' || this.props.auth.user.USERID == '2343') {
        LC_EMPLOYEE_MENU_ITEMS.push({
          path: "/admin/billing/upload-mis",
          title: "Upload MIS",
          type: "link",
          icon: "cloud-up",
        })
      }
      this.setState({
        mainmenu: LC_EMPLOYEE_MENU_ITEMS
      });
    }
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "Manager"
    ) {
      this.setState({
        mainmenu: LC_MANAGER_MENU_ITEMS
      });
    }

    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type === "staff"
    ) {
      let menuitemelist = [
        {
          path: "/staff/queries",
          title: "Queries",
          icon: "announcement",
          type: "link",
        },
      ];
      this.setState({
        mainmenu: menuitemelist,
      });
    }
  }

  componentDidMount() {
    let currentUrl = window.location.pathname;
    // eslint-disable-next-line
    this.state.mainmenu.filter((items) => {
      if (!items.children) {
        if (items.path === currentUrl) this.setNavActive(items);
        return false;
      }
      // eslint-disable-next-line
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) this.setNavActive(subItems);
        if (!subItems.children) return false;
        // eslint-disable-next-line
        subItems.children.filter((subSubItems) => {
          // eslint-disable-next-line
          if (subSubItems.path === currentUrl) this.setNavActive(subSubItems);
        });
      });
    });

    let emsKey = '';
    let billingKey = '';
    let reportTeamKey = '';
    let childrenKey = '';
    let newee = this.state.mainmenu;

    console.log(this.props.auth.user);
    console.log(this.state.mainmenu, 'mainMenu');

    if (this.props.auth.user.ismgr != "1") {

      let newMenu = this.state.mainmenu.filter((items, key) => {
        if (items.path == "/admin/ems") {
          emsKey = key;
          items.children.filter((item, keys) => {
            if (item.path == "/admin/ems/manager-view") {
              childrenKey = keys;
            }
            if (item.path == "/admin/ems/reporting-team") {
              reportTeamKey = keys;
            }
          });
        }

      })
      // console.log(newee[emsKey]);
      // console.log(newee[emsKey]);
      // delete (newee[emsKey]);
      delete (newee[emsKey]);
      // delete (newee[emsKey].children[childrenKey]);
      // delete (newee[emsKey].children[reportTeamKey]);
      this.setState({
        mainmenu: newee
      })
    }

    if(this.props.auth.user.isVendorRate == null && this.props.auth.user.isClientRate == null){
      let newMenu = this.state.mainmenu.filter((items, key) => {
        if (items.path == "/admin/tms") {
          emsKey = key;
          items.children.filter((item, keys) => {
            if (item.path == "/admin/tms/cost-management") {
              childrenKey = keys;
            }
          });
        }

      })
      // console.log(newee[emsKey]);
      // console.log(newee[emsKey]);
      // delete (newee[emsKey]);
      // delete (newee[emsKey]);
      delete (newee[emsKey].children[childrenKey]);
      // delete (newee[emsKey].children[reportTeamKey]);
      this.setState({
        mainmenu: newee
      })
    }

    if (this.props.auth.user.user_type == 'accounts' || this.props.auth.user.user_type == 'mis' || this.props.auth.user.user_type == 'mis2') {

    } else if (this.props.auth.user.ismgr == "1") {

    } else {
      let newMenu = this.state.mainmenu.filter((items, key) => {
        if (items.path == "/admin/billing") {
          billingKey = key;

        }

      })
      console.log(newee[billingKey]);
      console.log(newee[billingKey]);
      // delete (newee[emsKey]);
      delete (newee[billingKey]);
      // delete (newee[emsKey].children[childrenKey]);
      // delete (newee[emsKey].children[reportTeamKey]);
      this.setState({
        mainmenu: newee
      })
    }

    // console.log(this.state.mainmenu);
  }

  setNavActive(item) {
    let mainmenu = this.state.mainmenu;
    // eslint-disable-next-line
    mainmenu.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        // eslint-disable-next-line
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item))
            menuItem.active = true;
          if (submenuItems !== item) submenuItems.active = false;
        });
      }
    });
    item.active = !item.active;

    this.setState({
      mainmenu,
    });
  }

  render() {
    const mainmenu = this.state.mainmenu.map((menuItem, i) => (
      <li className={`${menuItem.active ? "active" : ""}`} key={i}>
        {menuItem.sidebartitle ? (
          <div className="sidebar-title">{menuItem.sidebartitle}</div>
        ) : (
          ""
        )}
        {menuItem.type === "sub" ? (
          <a
            className="sidebar-header mb-1"
            style={{ fontSize: "17px" }}

            onClick={() => this.setNavActive(menuItem)}
          >
            <i
              className={`icon-${menuItem.icon}`}
              style={{ color: "white" }}
            ></i>
            <span className="text-white text-capitalize">{menuItem.title}</span>
            <i className="fa fa-angle-right pull-right text-white"></i>
          </a>
        ) : (
          ""
        )}
        {menuItem.type === "link" ? (
          <Link
            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
            // className={`sidebar-header ${menuItem.active ? "Active" : ""}`}
            className={`sidebar-header text-capitalize rounded p-2 mb-3 ${menuItem.active ? "Active" : ""
              }`}
            style={{
              backgroundColor: `${menuItem.active ? "#ea4444" : ""}`,
              fontSize: "17px",
            }} //changed-now
            onClick={() => this.setNavActive(menuItem)}
          >
            <i
              className={`icon-${menuItem.icon}`}
              style={{ color: "white" }}
            ></i>
            <span className="text-white">{menuItem.title}</span>
            {menuItem.children ? (
              <i className="fa fa-angle-right pull-right text-white"></i>
            ) : (
              ""
            )}
          </Link>
        ) : (
          ""
        )}
        {menuItem.children ? (
          <ul
            className={`sidebar-submenu text-white ${menuItem.active ? "menu-open" : ""
              }`}
            style={
              menuItem.active
                ? { opacity: 1, transition: "opacity 500ms ease-in" }
                : {}
            }
          >
            {menuItem.children.map((childrenItem, index) => (


              <li
                key={index}
                className={`p-1 rounded mb-0 ${childrenItem.children
                  ? childrenItem.active
                    ? "active"
                    : ""
                  : ""
                  }`}
                style={{
                  color: "white",
                  fontSize: "17px",
                  // backgroundColor: `${childrenItem.active ? "#08b3dd" : ""}`,
                }}
              >
                {childrenItem.type === "sub" ? (
                  <a

                    onClick={() => this.setNavActive(childrenItem)}
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    <i className="fa fa-angle-right text-white"></i>
                    {childrenItem.title}
                  </a>
                ) : (
                  ""
                )}

                {childrenItem.type === "link" ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                    className={`sidebar-header text-white p-2 rounded ${childrenItem.active ? "active" : ""
                      }`}
                    style={{
                      fontSize: "17px",
                      backgroundColor: `${childrenItem.active ? "#ea4444" : ""
                        }`,
                    }}
                    onClick={() => this.setNavActive(childrenItem)}
                  >
                    {/* <i className="fa fa-angle-right text-white"></i> */}
                    <i
                      className={`icon-${childrenItem.icon}`}
                      style={{ color: "white" }}
                    ></i>
                    <span className="text-white"> {childrenItem.title} </span>
                    {/* {childrenItem.title}{" "} */}
                  </Link>
                ) : (
                  ""
                )}
                {childrenItem.children ? (
                  <ul
                    className={`sidebar-submenu text-white ${childrenItem.active ? "menu-open" : ""
                      }`}
                  >
                    {childrenItem.children.map((childrenSubItem, key) => (
                      <li
                        className={`${childrenSubItem.active ? "active" : ""}`}
                        // style={{
                        //   backgroundColor: `${
                        //     childrenSubItem.active ? "#08b3dd" : ""
                        //   }`,
                        // }}
                        key={key}
                      >
                        {childrenSubItem.type === "link" ? (
                          <Link
                            to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                            className={`text-white p-2 rounded ${childrenItem.active ? "menu-open" : ""
                              } ${childrenSubItem.active ? "active" : ""}`}
                            style={{
                              color: "white",
                              backgroundColor: `${childrenSubItem.active ? "#ea4444" : ""
                                }`,
                            }}
                          >
                            <i className="fa fa-angle-right text-white"></i>
                            {childrenSubItem.title}
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>

            ))}
          </ul>
        ) : (
          ""
        )}
      </li>
    ));

    return (

      <div
        className="page-sidebar custom-scrollbar page-sidebar-open"
        id="sidebar-bg"
      // style={styleNew}
      >
        {/* <div className="page-sidebar custom-scrollbar page-sidebar-open navigation-bordered"> */}

        <ul
          className="sidebar-menu text-white overflow-auto"
          style={{ maxHeight: "49%", paddingTop: "18px" }}
        >
          {mainmenu}
        </ul>
        <div
          className="sidebar-widget text-center fixed-bottom"
          style={{ maxHeight: "45%", marginBottom: "5vh" }}
        >
          <div className="sidebar-widget-top">
            <h6 className="mb-2 fs-14 text-white">Need Help</h6>
            <i className="icon-bell" />
          </div>
          <div className="sidebar-widget-bottom p-20 m-20">
            <p>+91 8309723656 admin@logicarts.in</p>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  application: state.application,
});

export default connect(mapStateToProps, {})(Sidebar);
