import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import "../../../../assets/customCssFiles/viewVehiclesVms.css";
import VehicleIcon from "../../../../assets/customImages/newTruckTms.png";

class RouteAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      Vendorid: "",
      Vehiclenumber: "",
      VehicleSize: "",
      RocNumber: "",
      ISGps: "",
      ISGpsValue: "",
      style: {}
    };
    this.validator = new SimpleReactValidator();
  }

  async componentWillMount() {
    let result = await axios.get(
      `${Config.hostName}/vms/vehicle-details/${this.props.match.params.VendorID}`
    );
    await this.setState({
      style: { display: 'none' }
    })
    if (result.data.success === false) {
      await this.props.setAlert(`No Vehicles Found `, "danger");
      await this.props.history.push("/admin/vms/vehicles");
    }
    else
      await this.setState({
        locations: result.data.data
      });

    // VehicleId
    // VehicleNumber
    // VehicleSize
    // ROCNumber
    // IsGPSInstalled

  }

  render() {
    let style = this.state.style;

    return (
      <div style={{ height: "82vh" }}>
        <div id="cover-spin" style={style}> </div>

        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />

                <div className="row vmsViewVehiclesMainCardContainer">
                  {this.state.locations.map((location, index) => (
                    <div key={index} className="card col-sm-5 vmsViewVehiclesMainCard">
                      <div className="row ">
                        <div className="vmsViewVehiclesMainCardLeftView">
                          <img className="vmsViewVehiclesMainCardLeftViewVehicleIcon" src={VehicleIcon} alt="Vehicle" width="100" height="100" />

                        </div>
                        <div className="vmsViewVehiclesMainCardRightView">
                          <span className="vmsViewVehiclesMainCardVehicleNumber">Vehicle No : {location.VehicleNumber}</span>
                          <span className="vmsViewVehiclesMainCardVehicleSize">Vehicle Size : {location.VehicleSize}</span>
                          <span className="vmsViewVehiclesMainCardIsGPSInstalled">
                            GPS: <span className="vmsViewVehiclesMainCardIsGPSInstalledValue" style={{ color: location.IsGPSInstalled == 1 ? "green" : "red" }}>
                              {location.IsGPSInstalled == 1 ? "YES" : "NO"}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}


                </div>
              </div>
              <Link
                to={`/admin/vms/vehicles/add/${this.props.match.params.VendorID}`}
              >
                <button
                  type="submit"
                  style={{ marginBottom: "8vh" }}
                  onClick={() =>
                    this.setState({
                      anotherModal: !this.state.anotherModal
                    })
                  }
                  className="btn btn-success"
                >
                  Add New
                    </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RouteAddForm.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
