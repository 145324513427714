/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/*global google*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import Config from "../../../../config";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
  InfoWindow,
} from "react-google-maps";
// import { DeleteIcon } from "@material-ui/icons";
import "../../../../assets/customCssFiles/loader-spinner.css";
import "../../../../assets/customCssFiles/gpsTrackingDashboard.css";
import { cardsData, vehiclesData } from "./GpsTrackingCardsData";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import LocationSearchingRoundedIcon from "@material-ui/icons/LocationSearchingRounded";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
// import LeftCardTracking from "./TrackingComponents/LeftCardDrivers";
import LeftCardTracking from "./TrackingLeftCardView";
import RightCardTopViewTracking from "./TrackingRightCardTopView";
import { FormGroup, Input, Label } from "reactstrap";
// import { DeleteIcon } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import "../../../../assets/customCssFiles/loader-spinner.css";
import "../../../../assets/customCssFiles/driverTracking.css";
import VehicleIcon from "../../../../assets/customImages/truck.png";
import DriverIcon from "../../../../assets/customImages/driver.png";
import OdometerIcon from "../../../../assets/customImages/odometer.png";
import LocationIcon from "../../../../assets/customImages/location.png";
import StatusIcon from "../../../../assets/customImages/status.png";
import EditIcon from "../../../../assets/customImages/editGpsTrackingDashboard.png";
import AddIcon from "../../../../assets/customImages/addGpsTrackingDashboard.png";
import EditDeleteIcon from "../../../../assets/customImages/editOrDeleteGpsTrackingDashboard.png";
import GetAppIcon from '@material-ui/icons/GetApp';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import ListIcon from '@material-ui/icons/List';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import SortIcon from '@material-ui/icons/Sort';
import { Button } from "@material-ui/core";
// import { DeleteIcon } from "@material-ui/icons";
import "../../../../assets/customCssFiles/loader-spinner.css";
import "../../../../assets/customCssFiles/driverTracking.css";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";


class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directions: [],
      routes: [],
      anotherRoutes: [],
      drivers: [],
      style: {},
      selectedPlace: {},

      inilat: "12.9889486",
      inilng: "77.6871443",
      routeno: 0,
      showingInfoWindow: false,
      activeMarker: {},

      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      alert_message: "",
      isRouteMarkerVisible: true,
      isDriverMarkerVisible: true,
      isRouteLineVisible: true,
      isPlayAnimation: false,

      dLat: "",
      dLng: "",
      position: "",
      driverName: "Sandeep",
      data: [],

      driverPosition: {},
      driverData: [
        {
          "DriverLat": "12.9889486",
          "Driverlong": "77.6871443",
          "DriverContact": "9999999999",
          "DriverName": "Spandan"
        },
        {
          "DriverLat": "12.8889486",
          "Driverlong": "77.7871443",
          "DriverContact": "9999999999",
          "DriverName": "Fuzail"
        }
      ],
      checkedArray: [],
      uniqueRouteNosMain: [],
      isCheckAll: false,
      filterStyle: { display: "none" },
      filters1OptionActive: false,
      filters2OptionActive: false,
      filters3OptionActive: false,
      filterOpen: false,
      playButtonText: "Play"
    };
  }

  getInitialData = async (id, route) => {

    let royaloak = String(this.props.auth.user.CCID) + ",499";
    let paramsValue = this.props.auth.user.CCID == 4 ? royaloak : this.props.auth.user.CCID;
    const res = await axios.post(`${Config.hostName}/shipment/tracking/route/data/${id}/${paramsValue}`,
      {
        vehicles: "v2",
      }
    )
      .then(async (res) => {
        console.log(res.data);
        await this.setState({
          data: [...res.data.data, ...res.data.data, ...res.data.data, ...res.data.data],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount = async () => {
    await this.setState({
      checkedArray: [],
      uniqueRouteNosMain: [],
      showingInfoWindow: false,
      activeMarker: null,
      data: [],
      style: {},
    });

    await this.setState({
      style: { display: "none" },
    });
  };

  onMarkerClickN = async (props, marker, e) => {

    console.log(props, marker, e)
    await this.setState({
      showingInfoWindow: false
    })
    await this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    })
    // console.log(driver, "clicked marker", this.state.showingInfoWindow);

    // console.log(this.state.showingInfoWindow, "this.state.showingInfoWindow")
    // await this.setState({
    //   position: {
    //     lat: Number(driver.DriverLat),
    //     lng: Number(driver.Driverlong),
    //   },
    //   showingInfoWindow: true,
    //   driverName: driver.DriverName,
    // });
  };

  onMarkerClick = async (driver) => {
    await this.setState({
      position: {
        lat: Number(driver.DriverLat),
        lng: Number(driver.Driverlong),
      },
      showingInfoWindow: true,
      driverName: driver.DriverName,
    });
  }

  onCloseInfoWindow = () => {
    this.setState({
      showingInfoWindow: false
    });
  }

  onMapClicked = async (props) => {
    console.log(props, "Map clicked", this.state.showingInfoWindow)
    if (this.state.showingInfoWindow) {
      await this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  searchByRouteCheckBox = async () => {
    let newRouteArray = this.state.uniqueRouteNosMain;
    let VehicleIDsArray = "";
    let aop;
    newRouteArray.filter((arr) => {
      if (arr.checkedObj.isChecked) {
        let num = arr.checkedObj.VehicleID;
        VehicleIDsArray = VehicleIDsArray + num + ",";
      }
      aop = VehicleIDsArray.toString();
    });
    let str = aop.replace(/,\s*$/, "");
    await this.setState({ directions: [], driverData: [], checkedArray: [] });
    await this.setState({
      style: { display: "none" },
    });
  };

  onClickFilterButton = async (e) => {
    e.preventDefault();
    if (this.state.playButtonText === "Play") {
      await this.setState({
        playButtonText: "Pause",
      });
    } else {
      await this.setState({
        playButtonText: "Play",
      });
    }
  }


  GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap
      google={this.props.google}
      onClick={props.onClick}
      className={"map"}
      initialCenter={{
        lat: Number(this.state.inilat),
        lng: Number(this.state.inilng),
      }}
      defaultCenter={{
        lat: Number(this.state.inilat),
        lng: Number(this.state.inilng),
      }}
      zoom={8}
    >
      {props.children}
    </GoogleMap>
  ));

  render() {
    let { style, filterStyle, filtersOptionStyle } = this.state;


    return (
      <div style={{ height: "82vh" }}>
        <div id="cover-spin" style={style}></div>
        <div className="driver-tracking-main-div">

          <div
            className="driver-tracking-left-div"
          >
            {/* <LeftCardTracking /> */}

            <div className="gpsTrackingDashboardCardViewUltraMain">
              {cardsData.map((Item, key) => {
                return (
                  <div className="card gpsTrackingDashboardCardViewMain" key={key}>
                    <div className="gpsTrackingDashboardCardViewTop">
                      <div className="gpsTrackingDashboardCardViewTopLeft">
                        <img className="gpsTrackingDashboardCardViewTopLeftVehicleIcon" src={VehicleIcon} alt={Item.vehicleNumber} width="100" height="60" />
                        <span className="gpsTrackingDashboardCardViewTopLeftVehicleNumber">{Item.vehicleNumber}</span>
                        <span className="gpsTrackingDashboardCardViewTopLeftVehicleTime">6 Jun20,11:30 AM</span>
                      </div>
                      <div className="gpsTrackingDashboardCardViewTopRight">
                        <div className="gpsTrackingDashboardCardViewTopRightLocation">
                          <span className="gpsTrackingDashboardCardViewTopRightLocationLogo">
                            <img src={LocationIcon} alt={Item.startingPoint} width="30" height="30" />
                          </span>
                          <span>{Item.startingPoint}</span>
                        </div>
                        <div className="gpsTrackingDashboardCardViewTopRightStatus">
                          <span className="gpsTrackingDashboardCardViewTopRightStatusLogo">
                            <img src={StatusIcon} alt={Item.stoppedSince} width="30" height="30" />
                          </span>
                          <span>Stopped Since : {Item.stoppedSince}</span>
                        </div>
                        <div className="gpsTrackingDashboardCardViewTopRightSpeed">
                          <span className="gpsTrackingDashboardCardViewTopRightSpeedLogo">
                            <img src={OdometerIcon} alt={Item.speed} width="30" height="30" />
                          </span>
                          <span>{Item.speed} Km, Today</span>
                        </div>
                        <div className="gpsTrackingDashboardCardViewTopRightDriverName">
                          <span className="gpsTrackingDashboardCardViewTopRightDriverNameLogo">
                            <img src={DriverIcon} alt={Item.driverNumber} width="30" height="30" />
                          </span>
                          <span>{Item.driverName} / {Item.driverNumber}</span>
                        </div>
                      </div>

                    </div>
                    <div className="gpsTrackingDashboardCardViewBottom">
                      <div className="gpsTrackingDashboardCardViewBottomIgnitionStatus">
                        <span>Ignition</span><br />
                        <span className="gpsTrackingDashboardCardViewBottomIgnitionStatusName">OFF</span>
                      </div>
                      <div className="gpsTrackingDashboardCardViewBottomCrud">
                        <div className="gpsTrackingDashboardCardViewBottomCrudEdit">
                          <span className="gpsTrackingDashboardCardViewBottomCrudEditLogo">
                            <img src={EditIcon} alt={Item.startingPoint} width="20" height="20" />
                          </span>
                          <span>Driver</span>
                        </div>
                        {/* <span> | </span> */}
                        <div className="gpsTrackingDashboardCardViewBottomCrudEditOrDelete">
                          <span className="gpsTrackingDashboardCardViewBottomCrudEditOrDeleteLogo">
                            <img src={EditDeleteIcon} alt={Item.startingPoint} width="20" height="20" />
                          </span>
                          <span>Edit</span>
                        </div>
                      </div>
                    </div>

                  </div>
                );
              })}
            </div>

          </div>

          <div className="driver-tracking-right-div" >

            <div className="row driver-tracking-right-div-top-card">
              {/* <RightCardTopViewTracking /> */}
              <div className="gpsTrackingDashboardFunKeysMain">
                <div className="gpsTrackingDashboardFunKeysSearchBox">
                  {/* <FormGroup>
                        <Input
                            type="search"
                            name="search"
                            id="exampleSearch"
                            className="gpsTrackingDashboardFunKeysSearchBoxFormInput"
                            placeholder="Search by Vehicle No"
                        />
                    </FormGroup> */}
                  {/* <div className="input-group">
                        <input className="form-control py-2 border-right-0 border" type="search" value="search" id="example-search-input" />
                        <span className="input-group-append">
                            <button className="btn btn-outline-secondary border-left-0 border" type="button">
                                <i className="fa fa-search"></i>
                            </button>
                        </span>
                    </div> */}
                  <div className="input-group">
                    <input className="form-control py-2 border-right-0 border gpsTrackingDashboardFunKeysSearchBoxFormInput" type="search" id="example-search-input"
                      // className="gpsTrackingDashboardFunKeysSearchBoxFormInput"
                      placeholder="Search by Vehicle No"


                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent"><i className="fa fa-search"></i></div>
                    </span>
                  </div>
                </div>

                <div className="gpsTrackingDashboardFunKeysListView">
                  <FormGroup>
                    <button className="btn gpsTrackingDashboardFunKeysListViewButton">
                      <span className="gpsTrackingDashboardFunKeysListViewButtonIcon">
                        <ListIcon color="disabled" />
                        &nbsp;
                      </span>
                      <span className="gpsTrackingDashboardFunKeysListViewButtonText">List View</span>
                    </button>
                  </FormGroup>
                </div>

                <div className="gpsTrackingDashboardFunKeysFilters">
                  <FormGroup>
                    <button className="btn gpsTrackingDashboardFunKeysFiltersButton"
                      onClick={() =>
                        this.setState({
                          filterOpen: !this.state.filterOpen,
                        })
                      }
                    >
                      <span className="gpsTrackingDashboardFunKeysFiltersButtonIcon">
                        <FilterListIcon color="disabled" />
                        &nbsp;
                      </span>
                      <span className="gpsTrackingDashboardFunKeysFiltersButtonText">Filters</span>

                    </button>

                  </FormGroup>
                </div>

                <div className="gpsTrackingDashboardFunKeysDownload">
                  <FormGroup>
                    <button className="btn gpsTrackingDashboardFunKeysDownloadButton">
                      <span className="gpsTrackingDashboardFunKeysDownloadButtonIcon">
                        <GetAppIcon color="disabled" />
                        &nbsp;
                      </span>
                      <span className="gpsTrackingDashboardFunKeysDownloadButtonText">Download</span>

                    </button>
                  </FormGroup>
                </div>

              </div >

            </div>

            <this.GoogleMapExample
              {...this.props}
              onClick={this.onMapClicked}
              containerElement={
                <div style={{ height: `90vh` }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
              loadingElement={<div style={{ height: `100%` }} />}
              className="driver-tracking-right-div-map-card"
            >

              {this.state.driverData.map((driver, index) => {
                return (
                  <Fragment>
                    <Marker
                      animation={google.maps.Animation.DROP}
                      icon={{
                        url: require("../../../../assets/customImages/truck.png"),
                        scaledSize: new google.maps.Size(50, 50),
                      }}
                      visible={this.state.isDriverMarkerVisible}
                      key={index}
                      position={{
                        lat: Number(driver.DriverLat),
                        lng: Number(driver.Driverlong),
                      }}
                      onClick={() => this.onMarkerClick(driver)}
                    // labelOrigin={{ x: 10, y: 50 }}
                    // anchorPoint={new google.maps.Point(32, 65)}
                    // label={{
                    //   text: `${driver.DriverName}`,
                    //   fontFamily: "Arial",
                    //   fontSize: "20px",
                    //   color: "black",
                    // }}
                    ></Marker>
                  </Fragment>
                );
              })}

              {this.state.showingInfoWindow && (
                <InfoWindow
                  position={this.state.position}
                  // marker={this.state.activeMarker}
                  visible={this.state.showingInfoWindow}
                  // onMouseover={this.onMarkerClick}
                  // onMouseout={this.onMapClicked}
                  onCloseClick={this.onCloseInfoWindow}
                >
                  <div>
                    <span className="infoWindowDriverName">Name : {this.state.driverName}</span><br />
                    <span className="infoWindowDriverNo">Mobile No : 9876543210</span><br />
                    <span className="infoWindowVehicleNo">Vehicle No : KA52A 7890</span>
                  </div>
                </InfoWindow>
              )}
            </this.GoogleMapExample>

          </div>

          <div
            className={`filter-div overflow-auto ${!this.state.filterOpen &&
              "filtersDivOpen"}`}
          >
            <div className="row m-0">
              <div className="col-12 mb-4">
                <span style={{ fontWeight: "600" }}>FILTERS</span>
                <span
                  className={`float-right fa fa-times mr-1  ${!this.state.filterOpen && "filtersDivOpen"}`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      filterOpen: !this.state.filterOpen,
                    })
                  }
                ></span>
              </div>
              <div
                className="col-12 pb-3 mb-4 driver-tracking-filter-custom-row w-100"
                onClick={() =>
                  this.setState({
                    filters1OptionActive: !this.state.filters1OptionActive,
                  })
                }
              >
                <span>Hide/Show filters</span>
                <span className="float-right fa fa-chevron-down mr-1"></span>
              </div>

              <div
                className={`col-12 mb-3 ${!this.state.filters1OptionActive &&
                  "filtersCardActive"}`}
              >
                <input
                  type="checkbox"
                  //   className="custom-control-input"
                  id="customSwitch1"
                  value={this.state.isRouteMarkerVisible}
                  onChange={(e) => {
                    this.setState({
                      isRouteMarkerVisible: !this.state.isRouteMarkerVisible,
                      showingInfoWindow: false,
                      activeMarker: null,
                    });
                  }}
                />
                <label className="ml-1" htmlFor="customSwitch1">
                  Hide Shipments
                </label>
                <br />
                <input
                  type="checkbox"
                  // className="custom-control-input"
                  id="customSwitch2"
                  value={this.state.isDriverMarkerVisible}
                  onChange={(e) => {
                    this.setState({
                      isDriverMarkerVisible: !this.state.isDriverMarkerVisible,
                      showingInfoWindow: false,
                      activeMarker: null,
                    });
                  }}
                />
                <label className="ml-1" htmlFor="customSwitch2">
                  Hide Drivers
                </label>
                <br />
                <input
                  type="checkbox"
                  id="customSwitch3"
                  value={this.state.isRouteLineVisible}
                  onChange={(e) => {
                    this.setState({
                      isRouteLineVisible: !this.state.isRouteLineVisible,
                      showingInfoWindow: false,
                      activeMarker: null,
                    });
                  }}
                />
                <label className="ml-1" htmlFor="customSwitch3">
                  Hide Routes
                </label>
                <br />
              </div>
              <div
                className="col-12 pb-3 mb-4 driver-tracking-filter-custom-row w-100"
                onClick={() =>
                  this.setState({
                    filters2OptionActive: !this.state.filters2OptionActive,
                  })
                }
              >
                <span>Filter by Vehicle</span>
                <span className="float-right fa fa-chevron-down mr-1"></span>
              </div>

              <div
                className={`col-12 mb-3 ${!this.state.filters2OptionActive &&
                  "filtersCardActive"}`}
              >
                {this.state.uniqueRouteNosMain.sort().map((route) => {
                  return (
                    <div>
                      <input type="checkbox" id={`vehicle${route.checkedObj.VehicleID}`} />
                      <label className="ml-1" htmlFor={`vehicle${route.checkedObj.VehicleID}`}>
                        {route.checkedObj.VehicleID}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-outline-info p-1 m-2 driver-tracking-reset-and-apply-button"
                >
                  Reset
                </button>
                <button
                  type="button"
                  class="btn btn-info p-1 m-2 driver-tracking-reset-and-apply-button"
                  onClick={() =>
                    this.setState({
                      filterOpen: !this.state.filterOpen,
                    })
                  }
                >
                  Apply
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default Map;
