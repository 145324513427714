/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { Form, FormGroup, Label, Col, Input } from "reactstrap";
import { connect } from "react-redux";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import "../../../../assets/customCssFiles/loader-spinner.css"
import MultiSelect from "@khanacademy/react-multi-select";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

class PostalCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      value: "",
      uniqueVehicles: [],
      selectedVehicle: "",
      isToggle: false,
      trackings: {},
      isData: false,
      trackingVehiclesList: [],
      uniqueVehiclesCount: "",
      dropDownPendingList: [],
      selectedPendingVehicle: "",
      selected: "",
      searchApi: "",
      vehicle: "",
      selectedListForDropDown: [],
      isDisplayData: false,
      driverDetailsDropDown: [],
      driverName: "",
      driverNumber: "",
      vehicleNumber: "",
      saveDriverDetailsArray: [],
      ftsdVehSeggDetailsArray: [],
      saveDriverDetailsApiVehicleId: "",
      saveDriverDetailsApiVehicleNumber: "",
      saveDriverDetailsApiDriverName: "",
      saveDriverDetailsApiDriverNumber: "",
      isSavingDriverDetailsOn: false,
      isSavingDriverDetailsVehicleID: "",
      isToggleAssignDriver: false
    };
    this.validator = new SimpleReactValidator();
  }

  getDriverContactDetails = async () => {
    const result = await axios.get(`${Config.hostName}/driver/get/contacts/drop-down`)
      .then(async (res) => {
        // console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            driverDetailsDropDown: res.data.data
          });
        } else {
          this.setState({ driverDetailsDropDown: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFtsdVehSeggDetails = async () => {

    const result = await axios.get(`${Config.hostName}/shipment/get-ftsd-vehicles/${this.props.auth.user.CCID}`)
      .then(async (res) => {
        // console.log(res.data.data, "sandeep");
        if (res.data.data.length > 0) {
          await this.setState({
            ftsdVehSeggDetailsArray: res.data.data
          });
        } else {
          this.setState({ ftsdVehSeggDetailsArray: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getPendingListDropDown = async () => {
    const result = await axios.get(`${Config.hostName}/shipment/vehicle-new-segg/get/pending-list/dropdown/${this.props.auth.user.CCID}`)
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.data.length > 0) {
          await this.setState({
            dropDownPendingList: res.data.data.map(eachItem => {
              return {
                selected: false, ...eachItem
              }
            })
          });
        } else {
          this.setState({ dropDownPendingList: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getUniqueVehicles = async () => {
    await axios
      .get(
        `${Config.hostName}/shipment/get/vehicles-drop-down/${this.props.auth.user.CCID}`
      )
      .then(async (res) => {
        // console.log(res.data.data, "veh dropdown");

        if (res.data.data.length > 0) {
          await this.setState({
            uniqueVehicles: res.data.data,
            uniqueVehiclesCount: res.data.data.length,
          });
        } else {
          this.setState({ uniqueVehicles: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllDataByVehicles = async () => {
    // console.log(this.props.auth.user.CCID, "this.props.auth.user.CCID");

    await axios
      .get(
        `${Config.hostName}/shipment/vehicle-new-segg/show-orders/vehicle/${this.props.auth.user.CCID}`
      )
      .then(async (res) => {
        // console.log(res.data);
        const data = res.data.data;

        let trackings = {};
        const uniqueArray = [
          ...new Set(await data.map((item) => item.VehicleID)),
        ];
        const uniqueArr = uniqueArray.sort((a, b) => a - b);
        // uniqueArr.filter(eachItem => {
        //     return (eachItem)
        // })
        // console.log(uniqueArr, "uniqueArr");
        // myArray = myArray.map((str, index) => ({ value: str, id: index + 1 }));
        const newArray = uniqueArr.map((it) => ({
          vehicleId: it,
          isToggle: false,
          driverName: "",
          driverNo: "",
          vehicleNo: "",
          driverId: ""

        }));
        uniqueArr.map((item) => {
          trackings[item] = [];
        });
        const requests = data.map((item) => {
          trackings[item.VehicleID].push(item);
        });

        const resp = await Promise.all(requests)

          .then(async () => {
            // console.log(trackings, "trackings");
            // if (this.state.isData) {
            await this.setState({
              trackings: trackings,
              trackingVehiclesList: newArray,
              saveDriverDetailsArray: newArray
            });
            // console.log(this.state.trackingVehiclesList, "trackingVehiclesList");

            // }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDataByClickingOnVehicle = async () => {
    console.log(this.state.selectedVehicle, "this.state.selectedVehicle");
    await axios
      .get(
        `${Config.hostName}/shipment/display/data/vehicle-new-segg/${this.props.auth.user.CCID}/${this.state.selectedVehicle}`
      )
      .then(async (res) => {
        const { data } = res.data;
        let str = "";
        const mapStr = data.map((item) => {
          str = str + "\n" + String(item.TrackingID);
        });
        const promStr = await Promise.all(mapStr);
        await this.setState({
          value: str.replace(" "),
          vehicle: ""
          // selectedVehicle: ""
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  componentWillMount = async () => {
    // await this.setState({
    //   style: {},
    // });
    await this.getDriverContactDetails();
    await this.getAllDataByVehicles();
    await this.getPendingListDropDown();
    await this.getUniqueVehicles();
    await this.getFtsdVehSeggDetails();
    // console.log(this.state.dropDownPendingList, "dropDownPendingList");

    await this.setState({
      isDisplayData: true,
      style: { display: "none" },
    });
  };

  handleSearchShipment = async () => {
    const value = this.state.value;

    const modifiedValue = value.replace(/\s/g, ",");

    if (modifiedValue) {
      await this.setState({
        style: {},
      });
      const result = await axios.post(
        `${Config.hostName}/shipment/transfer/vehicle-new-segg`,
        {
          ccid: this.props.auth.user.CCID,
          toVehID: this.state.selectedVehicle,
          ship2Transfer: modifiedValue,
        }
      );
      await this.setState({
        value: "",
        vehicle: "",
        selectedVehicle: "",
        style: { display: "none" }
      });
      await this.getUniqueVehicles();
      await this.getAllDataByVehicles();
      await this.props.setAlert("Transferred Shipments Succesfully", "success");
      // console.log(this.state, "state");
    } else {
      alert("Please Enter Tracking Numbers");
    }
  };

  handleChangeVehicleDropDown = async (event) => {
    console.log(event.target.value, "dropdown clicked");
    event.preventDefault();
    // await this.setState({
    // vehicle: "",
    // selectedVehicle: ""
    // });
    // console.log(event.target.value, "Vehicle")
    await this.setState({
      vehicle: event.target.value,
      selectedVehicle: event.target.value,
      style: {},
    });
    await this.getDataByClickingOnVehicle();
    await this.setState({
      style: { display: "none" }
    });
    // console.log(this.state.vehicle, "Vehicle")

  };

  adjustVehicleOrder = async () => {
    await this.setState({
      style: {},
    });
    const data = await axios
      .get(
        `${Config.hostName}/shipment/vehicle-new-segg/adjust/vehicles/${this.props.auth.user.CCID}`
      )
      .then(async (res) => {
        // console.log(res.data);

        if (res.data.success) {
          await this.setState({
            style: { display: "none" },
          });
          await this.getUniqueVehicles();
          await this.getAllDataByVehicles();
          // await this.props.setAlert("Sorting Done Succesfully", "success");
          alert("Vehicles Order Modified Succesfully");
        } else {
          await this.setState({
            style: { display: "none" },
          });
          await this.props.setAlert(res.data.message, "danger");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  contentChangeOnClick = async (e) => {
    // console.log(e.target.value, "item");
    const { trackingVehiclesList } = this.state;
    trackingVehiclesList.filter((item) => {
      if (item.vehicleId == e.target.value) {
        return (item.isToggle = !item.isToggle);
      }
    });
    await this.setState({
      isToggle: !this.state.isToggle,
      trackingVehiclesList: trackingVehiclesList,
    });
  };

  addNewVehicle = async () => {
    const { uniqueVehicles } = this.state;
    // console.log(uniqueVehicles, "uniqueVehicles");
    let vehicleNumbers = [];
    const arrayMap = uniqueVehicles.map(item => {
      // console.log(item.vehicleID);

      if (item.vehicleID !== 'V99') {
        vehicleNumbers.push(parseInt(item.vehicleID.substring(1)))
      }
    })
    const promiseArrayMap = await Promise.all(arrayMap)
    // console.log(vehicleNumbers, "vehicleNumbers");

    const number = vehicleNumbers[vehicleNumbers.length - 1];
    // console.log(number);

    uniqueVehicles.push({
      vehicleID: `V${number + 1}`,
      vehno: number + 1,
    });
    await this.setState({
      uniqueVehicles: uniqueVehicles,
    });
    await alert("vehicle added successfully");
  };

  handleChangePendingVehicles = async (selected) => {
    // console.log(selected, "selected");

    await this.setState({
      selected: selected
    })


  }

  functionForFilteringDropDown = async () => {
    let { dropDownPendingList, selected } = this.state;
    let selectedList = [];
    if (selected.length > 0) {
      selected.map(selectedItem => {
        dropDownPendingList.map((item, i) => {
          if (selectedItem === i) {
            // console.log(selectedItem, i, "selectedItem,i");
            return item.selected = true
          }
          else {
            return item.selected = false
          }
        })
      })
    }
    else {
      dropDownPendingList.map(item => {
        return item.selected = false
      })
    }

    const pushSelectedArray = selected.map((selItem => {
      selectedList.push(dropDownPendingList[selItem].vehicle)
    }))

    const promiseMapSelectedArray = await Promise.all(pushSelectedArray);

    let stringApi = "";
    const pushStringApi = selectedList.map(item => {
      return stringApi = stringApi + "," + item
    })
    const promiseMapString = await Promise.all(pushStringApi);

    // console.log(stringApi, "stringApi");
    await this.setState({
      searchApi: stringApi
    })

  }

  loadPendingListData = async () => {
    await axios.post(`${Config.hostName}/shipment/vehicle-new-segg/load/pending-list/data/${this.props.auth.user.CCID}`, {
      search: this.state.searchApi
    }).then(async (res) => {
      // console.log(res);
      if (res.data.success == false) {
        await this.props.setAlert(res.data.message, "danger");
      }
    }).catch(async (err) => {
      await this.props.setAlert(err.message, "danger");

    })

  }

  handleSearchPendingVehicle = async () => {

    await this.setState({
      style: {},
      uniqueVehicles: [],
      selectedVehicle: "",
      trackings: {},
      trackingVehiclesList: [],
      uniqueVehiclesCount: "",
      searchApi: "",
      saveDriverDetailsArray: []
    })
    // console.log(this.state.selected.length, "this.state.searchApi");
    if (this.state.selected.length > 0) {

      await this.functionForFilteringDropDown();
      await this.loadPendingListData();
      await this.getUniqueVehicles();
      await this.getAllDataByVehicles();
      // console.log(this.state.searchApi, "this.state.searchApi");
      await this.setState({
        isDisplayData: true,
        style: { display: "none" }
      })

    }
    else {
      alert("Please Select minimum One Vehicle")
      await this.setState({
        isDisplayData: false,
        style: { display: "none" }
      })
    }


  }

  saveDriverFtsdDetails = async () => {
    // console.log(this.state, "ohio");
    const name = this.state.saveDriverDetailsApiDriverName;
    const mobile = this.state.saveDriverDetailsApiDriverNumber;
    if (name.length > 0 && mobile.length > 0) {
      await this.setState({
        isSavingDriverDetailsOn: true
      });
      await axios.post(`${Config.hostName}/shipment/update/ftsd/vehicles`, {
        "vehicleId": this.state.saveDriverDetailsApiVehicleId,
        "vehicleNumber": this.state.saveDriverDetailsApiVehicleNumber,
        "driverName": this.state.saveDriverDetailsApiDriverName,
        "driverNumber": this.state.saveDriverDetailsApiDriverNumber,
        "userId": this.props.auth.user.Access
      })
        .then(async (res) => {
          // console.log(res);
          if (res.data.success == false) {
            await this.props.setAlert(res.data.message, "danger");
          }
          await this.getFtsdVehSeggDetails();
          await this.getDriverContactDetails();

          alert(`Successfully Assigned to ${this.state.saveDriverDetailsApiVehicleId}`)
          await this.setState({
            isSavingDriverDetailsOn: false,
            saveDriverDetailsApiVehicleId: "",
            saveDriverDetailsApiVehicleNumber: "",
            saveDriverDetailsApiDriverName: "",
            saveDriverDetailsApiDriverNumber: ""
          });
        }).catch(async (err) => {
          await this.props.setAlert(err.message, "danger");
        })
    }
    else {
      alert("Please Enter Driver Details Or Change existing Details")
    }
  }

  handleChangeDriverNameDropDown = async (e, eachVehicle) => {

    // console.log(e.target.value, eachVehicle);
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const optionElementId = optionElement.getAttribute('id');
    // console.log(optionElementId, "optionElementId");
    const driverDetails = [];
    this.state.driverDetailsDropDown.map(driver => {
      if (driver.driverid == optionElementId) {
        return driverDetails.push(driver);
      }
    })
    this.state.ftsdVehSeggDetailsArray.filter((item => {
      if (item.VehicleID == eachVehicle) {
        return (
          item.DriverName = driverDetails[0].name,
          item.DriverNumber = driverDetails[0].mobile ? driverDetails[0].mobile : "",
          item.VehicleNumber = driverDetails[0].vehicleNo ? driverDetails[0].vehicleNo : ""
        );
      }
    }))
    await this.setState({
      saveDriverDetailsApiDriverName: driverDetails[0].name,
      saveDriverDetailsApiDriverNumber: driverDetails[0].mobile,
      saveDriverDetailsApiVehicleNumber: driverDetails[0].vehicleNo,
      saveDriverDetailsApiVehicleId: eachVehicle,
      isSavingDriverDetailsVehicleID: eachVehicle
    })

  }

  handleChangeDriverNumber = async (e, eachVehicle) => {
    this.state.ftsdVehSeggDetailsArray.filter((item => {
      if (item.VehicleID == eachVehicle) {
        return (
          item.DriverNumber = e.target.value
        );
      }
    }))
    await this.setState({
      saveDriverDetailsApiDriverNumber: e.target.value,
    })
  }

  handleChangeDriverName = async (e, eachVehicle) => {
    this.state.ftsdVehSeggDetailsArray.filter((item => {
      if (item.VehicleID == eachVehicle) {
        return (
          item.DriverName = e.target.value
        );
      }
    }))
    await this.setState({
      saveDriverDetailsApiDriverName: e.target.value,
    })
  }

  handleChangeVehicleNumber = async (e, eachVehicle) => {
    this.state.ftsdVehSeggDetailsArray.filter((item => {
      if (item.VehicleID == eachVehicle) {
        return (
          item.VehicleNumber = e.target.value
        );
      }
    }))
    await this.setState({
      saveDriverDetailsApiVehicleNumber: e.target.value,
    })
  };

  contentChangeOnClickAssignDriver = async (e) => {
    // console.log(e.target.value, "item");

    await this.setState({
      isToggleAssignDriver: !this.state.isToggleAssignDriver,
    });
  };

  render() {
    let { style, selected } = this.state;
    const options = this.state.dropDownPendingList.map((item, i) => (
      {
        value: i,
        label: item.vehicle + " " + item.cnt
      }
    ))
    return (
      <div style={{ height: "100vh", marginTop: "5vh", maxHeight: "100%" }}>

        <div id="cover-spin" style={style}></div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="row">
                <Alert />
                <div className="col-sm-12">
                  {this.props.auth.user.CCID != 1 &&
                    <nav
                      className="navbar navbar-light "
                      style={{ backgroundColor: "none" }}
                    >
                      <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                        <div className="col-sm-3">
                          <FormGroup>
                            <MultiSelect
                              options={options}
                              selected={selected}
                              onSelectedChanged={this.handleChangePendingVehicles}
                              overrideStrings={{
                                selectSomeItems: "Select Vehicle To Load",
                                allItemsAreSelected: "All Vehicles are Selected",
                                selectAll: "Select All",
                                search: "Search",
                              }}
                            >

                            </MultiSelect>
                          </FormGroup>
                        </div>

                        <div className="col-sm-2">
                          <button
                            type="submit"
                            className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                            onClick={this.handleSearchPendingVehicle}
                          >
                            Load&nbsp;Data
                  </button>
                        </div>

                      </div>
                    </nav>
                  }

                  {this.state.isDisplayData && <div className="card-body">

                    <div className="row">

                      <div className="col-sm-2" style={{ width: "12.499999995%" }}>
                        <FormGroup>
                          <Label><span><strong>Tracking Nos</strong>- <span className="text-danger"><strong>{this.state.selectedVehicle}</strong></span></span></Label>
                          <Input
                            type="textarea"
                            placeholder="Paste Tracking Nos here"
                            name="value"
                            value={this.state.value}
                            id="value"
                            style={{
                              height: "70vh",
                            }}
                            onChange={(e) => {
                              // console.log(e.target.value);

                              this.setState({
                                value: e.target.value,
                              });
                            }}
                          ></Input>
                        </FormGroup>
                      </div>

                      <div className="col-sm-1">
                        <FormGroup>
                          <Label><span><strong>Select&nbsp;Vehicle</strong></span></Label>

                          <Input
                            type="select"
                            name="vehicle"
                            size={35}
                            value={this.state.vehicle}
                            id="vehicle"
                            onChange={this.handleChangeVehicleDropDown}
                          >
                            <option key={""} value={""} style={{ backgroundColor: "white !important", color: "white !important", textDecoration: "none" }} disabled></option>
                            {this.state.uniqueVehicles.map((item, i) => (
                              <option
                                style={{
                                  backgroundColor:
                                    parseInt(i + 2) % 2 === 0
                                      ? "#EAF0F1"
                                      : "#F5BCBA",
                                }}
                                key={item.vehicleID}
                                value={item.vehicleID}
                              >
                                {item.vehicleID}
                              </option>
                            ))}

                          </Input>
                        </FormGroup>
                      </div>

                      <div className="col-sm-2" style={{ marginTop: "5vh" }}>
                        <button
                          type="submit"
                          className="btn btn-info"
                          onClick={this.handleSearchShipment}
                          style={{ padding: "0.25rem 1rem" }}
                        >
                          Transfer
                        </button>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{
                            marginTop: "10px",
                            maxWidth: "100%",
                            height: "auto",
                            textAlign: "center",
                            padding: "0.25rem 0.4rem",
                          }}
                          onClick={this.addNewVehicle}
                        >
                          Add New Vehicle
                        </button>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-success"
                          style={{
                            marginTop: "10px",
                            maxWidth: "100%",
                            height: "auto",
                            textAlign: "center",
                            padding: "0.25rem 0.4rem",
                          }}
                          onClick={this.adjustVehicleOrder}
                        >
                          Adjust&nbsp;Vehicle&nbsp;Order
                        </button>
                        <br />
                      </div>

                      <div className="col-sm-7"
                        style={{ marginBottom: "10rem" }}
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="row">

                              <div className="col-sm-6">
                                <span><strong>Out For Delivery</strong></span>

                                {/* <p>Out For Delivery</p> */}
                              </div>

                              <div className="col-sm-3">
                                {/* <span><strong>Assign Drivers</strong></span>
                                 */}
                                {/* <Button */}
                                <button
                                  type="submit"
                                  className="btn btn-info w-100 overflow-hidden mb-3 px-0"
                                  id={`togglerAssignDrivers`}
                                  // value={eachVehicle.vehicleId}
                                  // style={{ marginBottom: "1rem" }}
                                  // style={{
                                  //   marginRight: "0.25rem",
                                  //   padding: "0rem 0.2rem",
                                  // ↑↓
                                  //   marginBottom: "0.2rem",
                                  // }}
                                  onClick={(e) =>
                                    this.contentChangeOnClickAssignDriver(e)
                                  }
                                >
                                  {/* Assign Drivers */}
                                  {this.state.isToggleAssignDriver ? "Assigning Drivers ↑" : "Assign Drivers ↓"}
                                  {/* {this.state.isToggleAssignDriver ? <RemoveIcon /> : <AddIcon />} */}
                                </button>

                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4" style={{ marginTop: "2vh" }}>
                            {this.state.trackingVehiclesList.map(
                              (eachVehicle, i) => {
                                return (
                                  <div className="row">
                                    {/* <div className="col-sm-4"> */}
                                    <div style={{ marginBottom: "1rem" }}>
                                      <Button
                                        id={`toggler${i}`}
                                        value={eachVehicle.vehicleId}
                                        // style={{ marginBottom: "1rem" }}
                                        style={{
                                          marginRight: "0.25rem",
                                          padding: "0rem 0.2rem",
                                          marginBottom: "0.2rem",
                                        }}
                                        onClick={(e) =>
                                          this.contentChangeOnClick(e)
                                        }
                                      >
                                        {/* {eachVehicle.isToggle ? <RemoveIcon /> : <AddIcon />} */}
                                        {eachVehicle.isToggle ? "−" : "＋"}
                                      </Button>
                                      {eachVehicle.vehicleId}&nbsp;-&nbsp;&nbsp;
                                    <span className="text-danger">
                                        <strong>
                                          {
                                            this.state.trackings[
                                              eachVehicle.vehicleId
                                            ].length
                                          }
                                      &nbsp;
                                      </strong>
                                      </span>
                                    shipments

                                      <UncontrolledCollapse
                                        toggler={`#toggler${i}`}
                                        style={{ marginTop: "0.2rem" }}
                                      >
                                        <Card>
                                          <CardBody
                                            style={{ fontFamily: "Arial" }}
                                          >
                                            {this.state.trackings[
                                              eachVehicle.vehicleId
                                            ].map((item) => {
                                              return (
                                                <p>
                                                  {item.TrackingID} <span className="text-danger">{item.cxCode}</span>
                                                  <br />
                                                </p>
                                              );
                                            })}
                                          </CardBody>
                                        </Card>
                                      </UncontrolledCollapse>

                                    </div>
                                    {/* </div> */}
                                  </div>
                                )

                              })}
                          </div>

                          <div className="col-sm-8" style={{ marginTop: "2vh" }}>
                            <UncontrolledCollapse
                              toggler={`#togglerAssignDrivers`}
                            // style={{ marginTop: "0.2rem" }}
                            >
                              {/* <Card> */}
                              {/* style={{ fontFamily: "Arial" }} */}

                              {this.state.ftsdVehSeggDetailsArray.map(
                                (eachVehicle, i) => {

                                  return (

                                    <div className="row">
                                      {/* <div className="col-sm-12"> */}

                                      <div className="col-sm-3">

                                        <FormGroup>
                                          {/* <Label >{eachVehicle.VehicleID}</Label> */}
                                          {/* <span>{eachVehicle.VehicleID}</span> */}

                                          <Input
                                            type="select"
                                            name={eachVehicle.DriverName}
                                            value={eachVehicle.DriverName}
                                            // value={}
                                            // style={{ borderColor: "#FF4848" }}
                                            id={eachVehicle.VehicleID}
                                            onChange={(e) => { this.handleChangeDriverNameDropDown(e, eachVehicle.VehicleID) }}
                                          >
                                            <option value={null} >
                                              Select Driver
                        </option>
                                            {this.state.driverDetailsDropDown.map((eachItem) => (
                                              <option value={eachItem.name ? eachItem.name : null} id={eachItem.driverid} key={eachItem.driverid}>
                                                {eachItem.name ? eachItem.name : null}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </div>

                                      <div className="col-sm-3">
                                        <FormGroup>
                                          <Input
                                            type="text"
                                            // name="driverNumber"
                                            name={eachVehicle.DriverName}
                                            value={eachVehicle.DriverName}
                                            onChange={(e) => { this.handleChangeDriverName(e, eachVehicle.VehicleID) }}

                                          >
                                          </Input>
                                        </FormGroup>
                                      </div>

                                      <div className="col-sm-2">
                                        <FormGroup>
                                          <Input
                                            type="text"
                                            name="driverNumber"
                                            value={eachVehicle.DriverNumber}
                                            placeholder="Mobile"
                                            // style={{ borderColor: "#FF4848" }}
                                            id={eachVehicle.DriverNumber}
                                            onChange={(e) => { this.handleChangeDriverNumber(e, eachVehicle.VehicleID) }}

                                          >
                                          </Input>
                                        </FormGroup>
                                      </div>

                                      <div className="col-sm-2">
                                        <FormGroup>
                                          <Input
                                            type="text"
                                            name="vehicleNumber"
                                            placeholder="Vehicle No"
                                            value={eachVehicle.VehicleNumber}
                                            // style={{ borderColor: "#FF4848" }}
                                            id={eachVehicle.VehicleNumber}
                                            onChange={(e) => { this.handleChangeVehicleNumber(e, eachVehicle.VehicleID) }}

                                          >
                                          </Input>
                                        </FormGroup>
                                      </div>

                                      <div className="col-sm-2">
                                        <button
                                          type="submit"
                                          className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                          onClick={this.saveDriverFtsdDetails}
                                        >
                                          {
                                            this.state.isSavingDriverDetailsVehicleID == eachVehicle.VehicleID
                                            &&
                                            this.state.isSavingDriverDetailsOn && (
                                              <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                              />
                                            )}
                                            Assign to <span><strong>{eachVehicle.VehicleID}</strong></span>
                                          {/* {
                                            this.state.isSavingDriverDetailsVehicleID == eachVehicle.VehicleID
                                            && this.state.isSavingDriverDetailsOn
                                            && <span>Saving ...</span>}
                                          {
                                            this.state.isSavingDriverDetailsVehicleID == eachVehicle.VehicleID &&
                                            !this.state.isSavingDriverDetailsOn && <span>Save</span>} */}
                                        </button>
                                      </div>

                                      {/* </div> */}
                                      {/* </div> */}
                                    </div>

                                  )

                                })}
                              {/* </Card> */}
                            </UncontrolledCollapse>

                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PostalCodeForm.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(PostalCodeForm);
