/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import axios from "axios";
import Config from "../../../../config";
import {
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
    Marker,
    InfoWindow,
} from "react-google-maps";
import { Button } from "@material-ui/core";
// import { DeleteIcon } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import "../../../../assets/customCssFiles/loader-spinner.css";
import "../../../../assets/customCssFiles/driverTracking.css";
import { cardsData, vehiclesData } from "./GpsTrackingCardsData";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import LocationSearchingRoundedIcon from "@material-ui/icons/LocationSearchingRounded";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import VehicleIcon from "../../../../assets/customImages/truck.png";
import DriverIcon from "../../../../assets/customImages/driver.png";
import OdometerIcon from "../../../../assets/customImages/odometer.png";
import LocationIcon from "../../../../assets/customImages/location.png";
import StatusIcon from "../../../../assets/customImages/status.png";
import EditIcon from "../../../../assets/customImages/editGpsTrackingDashboard.png";
import AddIcon from "../../../../assets/customImages/addGpsTrackingDashboard.png";
import EditDeleteIcon from "../../../../assets/customImages/editOrDeleteGpsTrackingDashboard.png";


export default class leftCardDrivers extends Component {
    render() {
        return (
            <div className="gpsTrackingDashboardCardViewUltraMain">
                {cardsData.map((Item, key) => {
                    return (
                        <div className="card gpsTrackingDashboardCardViewMain" key={key}>
                            <div className="gpsTrackingDashboardCardViewTop">
                                <div className="gpsTrackingDashboardCardViewTopLeft">
                                    <img className="gpsTrackingDashboardCardViewTopLeftVehicleIcon" src={VehicleIcon} alt={Item.vehicleNumber} width="100" height="60" />
                                    <span className="gpsTrackingDashboardCardViewTopLeftVehicleNumber">{Item.vehicleNumber}</span>
                                    <span className="gpsTrackingDashboardCardViewTopLeftVehicleTime">6 Jun20,11:30 AM</span>
                                </div>
                                <div className="gpsTrackingDashboardCardViewTopRight">
                                    <div className="gpsTrackingDashboardCardViewTopRightLocation">
                                        <span className="gpsTrackingDashboardCardViewTopRightLocationLogo">
                                            <img src={LocationIcon} alt={Item.startingPoint} width="30" height="30" />
                                        </span>
                                        <span>{Item.startingPoint}</span>
                                    </div>
                                    <div className="gpsTrackingDashboardCardViewTopRightStatus">
                                        <span className="gpsTrackingDashboardCardViewTopRightStatusLogo">
                                            <img src={StatusIcon} alt={Item.stoppedSince} width="30" height="30" />
                                        </span>
                                        <span>Stopped Since : {Item.stoppedSince}</span>
                                    </div>
                                    <div className="gpsTrackingDashboardCardViewTopRightSpeed">
                                        <span className="gpsTrackingDashboardCardViewTopRightSpeedLogo">
                                            <img src={OdometerIcon} alt={Item.speed} width="30" height="30" />
                                        </span>
                                        <span>{Item.speed} Km, Today</span>
                                    </div>
                                    <div className="gpsTrackingDashboardCardViewTopRightDriverName">
                                        <span className="gpsTrackingDashboardCardViewTopRightDriverNameLogo">
                                            <img src={DriverIcon} alt={Item.driverNumber} width="30" height="30" />
                                        </span>
                                        <span>{Item.driverName} / {Item.driverNumber}</span>
                                    </div>
                                </div>

                            </div>
                            <div className="gpsTrackingDashboardCardViewBottom">
                                <div className="gpsTrackingDashboardCardViewBottomIgnitionStatus">
                                    <span>Ignition</span><br />
                                    <span className="gpsTrackingDashboardCardViewBottomIgnitionStatusName">OFF</span>
                                </div>
                                <div className="gpsTrackingDashboardCardViewBottomCrud">
                                    <div className="gpsTrackingDashboardCardViewBottomCrudEdit">
                                        <span className="gpsTrackingDashboardCardViewBottomCrudEditLogo">
                                            <img src={EditIcon} alt={Item.startingPoint} width="20" height="20" />
                                        </span>
                                        <span>Driver</span>
                                    </div>
                                    {/* <span> | </span> */}
                                    <div className="gpsTrackingDashboardCardViewBottomCrudEditOrDelete">
                                        <span className="gpsTrackingDashboardCardViewBottomCrudEditOrDeleteLogo">
                                            <img src={EditDeleteIcon} alt={Item.startingPoint} width="20" height="20" />
                                        </span>
                                        <span>Edit</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    );
                })}
            </div>
        )
    }
}
