/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import axios from "axios";
import Config from "../../../../../config";
import {
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
    Marker,
    InfoWindow,
} from "react-google-maps";
import { Button } from "@material-ui/core";
// import { DeleteIcon } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import "../../../../../assets/customCssFiles/loader-spinner.css";
import "../../../../../assets/customCssFiles/driverTracking.css";
import { cardsData, vehiclesData } from "../driverTrackingCardsData";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import LocationSearchingRoundedIcon from "@material-ui/icons/LocationSearchingRounded";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
export default class leftCardDrivers extends Component {
    render() {
        return (
            <div>
                {cardsData.map((Item, key) => {
                    //   console.log(Item);
                    //   console.log(key);
                    return (
                        <div className="card mx-auto my-3 ftsd-driver-tracking-card">
                            <div className="row w-100 m-auto">
                                <div className="col-7 mt-3">
                                    <p style={{ fontSize: "17px" }}>
                                        <strong>{Item.vehicleNumber}</strong>
                                    </p>
                                </div>
                                <div className="col-5 mt-3">
                                    <p
                                        className="rounded-pill text-white text-center"
                                        style={{ backgroundColor: "red" }}
                                    >
                                        {Item.vehicleSize} Feet
                      </p>
                                </div>
                            </div>
                            <div className="row w-100 m-auto">
                                <div className="col-1">
                                    <span className="fa fa-map-marker ftsd-driver-tracking-icon"></span>
                                </div>
                                <div className="col-10">
                                    <p className="mb-0 ftsd-driver-tracking-from-to-text">
                                        From:
                      </p>
                                    <p>{Item.startingPoint}</p>
                                </div>
                            </div>
                            <div className="row w-100 m-auto">
                                <div className="col-1">
                                    <span className="fa fa-map-marker ftsd-driver-tracking-icon"></span>
                                </div>
                                <div className="col-10">
                                    <p className="mb-0 ftsd-driver-tracking-from-to-text">
                                        To:
                      </p>
                                    <p>{Item.endingPoint}</p>
                                </div>
                            </div>
                            <div className="row w-100 m-auto">
                                <div className="col-1">
                                    <span className="fa fa-info-circle ftsd-driver-tracking-icon-second"></span>
                                </div>
                                <div className="col-10 ftsd-driver-tracking-from-to-text">
                                    <p>
                                        {Item.speed} kmph (Stopped since {Item.stoppedSince})
                      </p>
                                </div>
                            </div>
                            <div className="row w-100 m-auto">
                                <div className="col-1">
                                    <span className="fa fa-user ftsd-driver-tracking-icon-second"></span>
                                </div>
                                <div className="col-10" style={{ color: "green" }}>
                                    <p>Driver Name : {Item.driverName}</p>
                                </div>
                            </div>
                            <div className="row w-100 m-auto">
                                <div
                                    className="col-11 m-auto pt-3"
                                    style={{ borderTop: "1px solid #999999" }}
                                >
                                    <p className="float-right">
                                        <span className="fa fa-phone"></span>
                                        &nbsp; Call
                      </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div style={{ height: "8rem" }}></div>
            </div>
        )
    }
}
