import React, {
  Component,
  // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from "reactstrap";
// import {Link} from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import "./layout.css";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Autocomplete,
  ListItemText,
  OutlinedInput,
  FormControlLabel,
  Backdrop,
  Fade,
  Modal,
  Chip,
  Stack,
  Box,
  Checkbox,
  IconButton,
  Grid,
  Container,
  Typography,
  Avatar,
  Badge,
  TextField,
  Card,
  CardMedia,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "406px",
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class RegisterEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      selectedDate: new Date(),
      unique_cities: [],
      locationId: "",
      unique_project: [],
      projectName: [],
      projectID: [],
      unique_reportTo: [],
      reportTo: [],
      unique_departments: [],
      department: "",
      unique_designations: [],
      designation: "",
      userType: "",
      unique_access: [],
      access: [],
      profile: "",
      fullName: "",
      fullNameValidate: false,
      mobile: "",
      mobileValidate: false,
      email: "",
      doj: today,
      employeeType: "",
      personName: [],
      employmentType: "",
      bankName: "",
      bankNameValidate: false,
      accountNo: "",
      accountNoValidate: false,
      confirmAccountNo: "",
      confirmAccountNoValidate: "",
      accountNoColor: "primary",
      accFocused: false,
      ifsc: "",
      ifscValidate: false,
      panNo: "",
      panNoValidate: false,
      pancardImage: "",
      addPancardImage: [],
      open: false,
      dob: today,
      dobValidate: false,
      panImageText: "Attach",
      color: "",
      addProfileAttachments: [],
      monthlyCTC: "",
      inHand: false,
      pfNo: "",
      uanNo: "",
      esicNo: "",
      bloodGroup: "",
      aadharFrontText: "Attach",
      aadharFrontcolor: "",
      aadharFrontImage: "",
      aadharBackText: "Attach",
      aadharBackcolor: "",
      aadharBackImage: "",
      modalImage: "",
      unique_geofence: [],
      geofence: "",
      geofenceAlertDisplay: "none",
      geofenceAlertBorder: "2px solid #fff",
    };
    this.validator = new SimpleReactValidator();
  }

  delay = async (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  getUniqueCities = async () => {
    await axios
      .get(`${Config.hostName}/unique/locations`)
      .then((res) => {
        if (res.data.data.length > 0) {
          this.setState({
            unique_cities: res.data.data,
          });
        } else {
          this.setState({
            unique_cities: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  departmentDropDown = async () => {
    await axios
      .get(`${Config.hostName}/ems/departmentDropDown`)
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_departments: res.data.data,
          });
        } else {
          this.setState({
            unique_departments: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  designation = async () => {
    await axios
      .get(`${Config.hostName}/ems/designation`)
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_designations: res.data.data,
          });
        } else {
          this.setState({
            unique_designations: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  accessDropDown = async () => {
    await axios
      .get(`${Config.hostName}/ems/accessDropDown`)
      .then(async (res) => {
        const newArray2 = res.data.data.map((it) => ({
          accessTypeid: it.accessTypeid,
          AccessType: it.AccessType,
          isChecked: false,
        }));
        if (res.data.data.length > 0) {
          await this.setState({
            unique_access: newArray2,
          });

          await this.state.unique_access.filter(async (eachItem, key) => {
            if (
              eachItem.accessTypeid == 1 ||
              eachItem.accessTypeid == 29 ||
              eachItem.accessTypeid == 4 ||
              eachItem.accessTypeid == 19 ||
              eachItem.accessTypeid ==47
            ) {
              let items = [...this.state.unique_access];
              let item = { ...items[key] };
              item.isChecked = true;
              items[key] = item;

              await this.setState({
                unique_access: items,
              });
            }
          });
        } else {
          this.setState({
            unique_access: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  reportTODropDown = async () => {
    await axios
      .get(`${Config.hostName}/ems/reportTODropDown`)
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_reportTo: res.data.data,
          });
        } else {
          this.setState({
            unique_reportTo: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  projectDropDown = async () => {
    const res = await axios
      .post(`${Config.hostName}/ems/projectDropDown`, {
        locationID: this.state.locationId,
      })
      .then(async (res) => {
        console.log(res.data.data, "project");
        if (res.data.data.length > 0) {
          await this.setState({
            unique_project: res.data.data,
          });
        } else {
          this.setState({
            unique_project: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  geofenceDropDown = async () => {
    const res = await axios
      .post(`${Config.hostName}/geofencing/getGeoFence`, {
        locationID: this.state.locationId,
        geoID: "",
      })
      .then(async (res) => {
        console.log(res.data.data, "geofence");
        if (res.data.data.length > 0) {
          const newArray2 = res.data.data.map((it) => ({
            GeoID: it.GeoID,
            Hubname: it.Hubname,
            isChecked: false,
          }));

          await this.setState({
            unique_geofence: newArray2,
          });
        } else {
          this.setState({
            unique_geofence: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  async componentDidMount() {
    await this.setState({
      style: {},
    });
    await this.getUniqueCities();
    await this.reportTODropDown();
    await this.departmentDropDown();
    await this.designation();
    await this.accessDropDown();
    await this.setState({
      style: {
        display: "none",
      },
    });
  }

  handleAccessChange = async (event) => {
    console.log(event);
    if (event == 1 || event == 29 || event == 4 || event == 19||event == 47) {
      alert("This option is Mandatory !!");
    } else {
      await this.state.unique_access.filter(async (eachItem, key) => {
        if (eachItem.accessTypeid == event) {
          let items = [...this.state.unique_access];
          let item = { ...items[key] };
          item.isChecked = !eachItem.isChecked;
          items[key] = item;

          await this.setState({
            unique_access: items,
          });
        }
      });
    }

    console.log(this.state.unique_access);
  };

  handleGeofenceChange = async (event) => {
    await this.state.unique_geofence.filter(async (eachItem, key) => {
      if (eachItem.GeoID == event) {
        let items = [...this.state.unique_geofence];
        let item = { ...items[key] };
        item.isChecked = !eachItem.isChecked;
        items[key] = item;

        await this.setState({
          unique_geofence: items,
        });
        await this.setState({
          geofenceAlertDisplay: "none",
          geofenceAlertBorder: "2px solid #fff",
        });
      }
    });
  };

  getUrlToAttachments = async (url, name) => {
    const { addProfileAttachments } = this.state;
    addProfileAttachments.filter((addProfileAttachments) => {
      if (addProfileAttachments.name == name) {
        return (addProfileAttachments.AttachmentUrl = url);
      }
    });
    await this.setState({
      addProfileAttachments: addProfileAttachments,
      profile: addProfileAttachments[0].AttachmentUrl,
    });
    console.log(this.state.addProfileAttachments);
  };

  uploadFileAndGetUrl = async (formData) => {
    // console.log("uploading formdata")
    const fileData = await axios({
      method: "POST",
      url: `${Config.hostName}/unique/upload/any-file`,
      timeout: 3 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: formData,
    });
    return fileData;
  };

  singleUploadFile = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    await this.getUrlToAttachments(urlFromAws, file.name);
    await this.setState({
      profile: urlFromAws,
    });
    const { addProfileAttachments } = this.state;
    setTimeout(async () => {
      addProfileAttachments.filter((att) => {
        if (att.name == file.name && att.isUploaded == false) {
          return (att.isUploaded = true);
        }
      });
      await this.setState({
        addProfileAttachments: addProfileAttachments,
      });
    }, 4000);
    // console.log(this.state.attachments, "data")
  };

  onFileUpload = async (event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { addProfileAttachments } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      addProfileAttachments.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false,
      });
    });
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    // await this.setState({
    //   style: {}
    // });
    await this.setState({
      addProfileAttachments: addProfileAttachments,
    });
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.singleUploadFile(item);
    }
    await this.delay(2000);
    // await this.setState({
    //   style: {
    //     display: "none"
    //   }
    // });
  };

  getPanCardUrl = async (url, name) => {
    const { addPancardImage } = this.state;
    addPancardImage.filter((addPancardImage) => {
      if (addPancardImage.name == name) {
        return (addPancardImage.AttachmentUrl = url);
      }
    });
    await this.setState({
      addPancardImage: addPancardImage,
      pancardImage: addPancardImage[0].AttachmentUrl,
    });
  };

  pancardUploadFile = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    await this.getPanCardUrl(urlFromAws, file.name);
    await this.setState({
      pancardImage: urlFromAws,
    });

    const { addPancardImage } = this.state;
    setTimeout(async () => {
      addPancardImage.filter((att) => {
        if (att.name == file.name && att.isUploaded == false) {
          return (att.isUploaded = true);
        }
      });
      await this.setState({
        addPancardImage: addPancardImage,
      });
    }, 4000);
    // console.log(this.state.attachments, "data")
  };

  onPanUpload = async (event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { addPancardImage } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      addPancardImage.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false,
      });
    });
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    await this.setState({
      panImageText: "Uploading..",
    });
    await this.setState({
      addPancardImage: addPancardImage,
    });
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.pancardUploadFile(item);
    }
    await this.delay(2000);
    await this.setState({
      panImageText: "Change",
      color: "addColorBlue",
    });
  };

  // getPanCardUrl = async (url, name) => {
  //   const { addPancardImage } = this.state;
  //   addPancardImage.filter(addPancardImage => {
  //     if (addPancardImage.name == name) {
  //       return addPancardImage.AttachmentUrl = url
  //     }
  //   })
  //   await this.setState({
  //     addPancardImage: addPancardImage,
  //     pancardImage: addPancardImage[0].AttachmentUrl
  //   })
  // }

  aadharUploadFile = async (adharPos, file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    if (adharPos == 1) {
      await this.setState({
        aadharFrontImage: urlFromAws,
      });
    } else {
      await this.setState({
        aadharBackImage: urlFromAws,
      });
    }
  };

  onAadharUpload = async (res, event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);

    if (res == 1) {
      await this.setState({
        aadharFrontText: "Uploading..",
      });
    } else {
      await this.setState({
        aadharBackText: "Uploading..",
      });
    }

    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.aadharUploadFile(res, item);
    }
    await this.delay(1000);

    if (res == 1) {
      await this.setState({
        aadharFrontText: "Change",
        aadharFrontcolor: "addColorBlue",
      });
    } else {
      await this.setState({
        aadharBackText: "Change",
        aadharBackcolor: "addColorBlue",
      });
    }
  };

  handleLocationChange = async (e) => {
    e.preventDefault();
    await this.setState({
      locationId: e.target.value,
      projectID: [],
      projectName: [],
    });
    await this.projectDropDown();
    await this.geofenceDropDown();
  };

  handleDepartmentChange = async (e) => {
    e.preventDefault();
    await this.setState({
      department: e.target.value,
    });
  };

  handleReportToChange = async (e, newValue) => {
    const value = newValue;
    console.log(value);
    await this.setState({
      reportTo: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleProjectChange = async (e) => {
    const value = e.target.value;
    // console.log(value);
    await this.setState({
      projectName: typeof value === "string" ? value.split(",") : value,
    });
  };

  // handleReportTo = async (e) => {
  //   e.preventDefault();
  //   await this.setState({ reportTo: e.target.value, });
  // }

  handleDesignationChange = async (e) => {
    e.preventDefault();
    await this.setState({ designation: e.target.value });
  };

  handleTypeChange = async (e) => {
    e.preventDefault();
    await this.setState({ employeeType: e.target.value });
  };

  handleEmploymentChange = async (e) => {
    e.preventDefault();
    await this.setState({ employmentType: e.target.value });
  };

  handleOpenModal = async (e) => {
    if (e == 1) {
      await this.setState({ modalImage: this.state.pancardImage });
    } else if (e == 2) {
      await this.setState({ modalImage: this.state.aadharFrontImage });
    } else {
      await this.setState({ modalImage: this.state.aadharBackImage });
    }
    await this.setState({ open: true });
  };

  handleCloseModal = async (e) => {
    await this.setState({ open: false });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.bankName == "") {
      alert("Bank Name Required..!!!");
      return;
    } else if (this.state.profile == "") {
      alert("Profile Pic Required..!!!");
      return;
    } else if (this.state.mobile == "") {
      alert("Mobile Number Required..!!!");
      return;
    } else if (this.state.employmentType == "") {
      alert("Employment Type Required..!!!");
      return;
    } else if (this.state.mobile.length != 10) {
      alert("Mobile Number Invalid, Should be of 10 Digits..!!!");
      return;
    } else if (this.state.accountNo == "") {
      alert("Account NO. Required..!!!");
      return;
    } else if (this.state.accountNo != this.state.confirmAccountNo) {
      alert("Account NO. not matched..!!!");
      return;
    } else if (this.state.ifsc == "") {
      alert("IFSC code Required..!!!");
      return;
    } else if (this.state.fullName == "") {
      alert("Name cannot be empty..!!!");
      return;
    } else if (this.state.pancardImage == "") {
      alert("Pan Card Image Required...");
      return;
    } else if (this.state.aadharFrontImage == "") {
      alert("AadharCard Front Image Required...");
      return;
    } else if (this.state.aadharBackImage == "") {
      alert("AadharCard Back Image Required...");
      return;
    } else if (this.state.unique_geofence.length === 0) {
      alert("Geofence Selection Required...");
      return;
    }

    const designation = this.state.designation.split("&&");

    let access = [];
    this.state.unique_access.map((item) => {
      if (item.isChecked == true) {
        access.push(item.accessTypeid);
      }
    });

    let geoID = [];
    this.state.unique_geofence.map((item) => {
      if (item.isChecked == true) {
        geoID.push(item.GeoID);
      }
    });

    if (geoID.toString() == "") {
      // alert("Geo Location Required..!!!");
    await this.setState({
        geofenceAlertBorder: "2px solid red",
        geofenceAlertDisplay: "block",
      });
      window.scrollTo({
        top: 400,
        behavior: "smooth",
      });
      return;
    }

    let inHand = 0;
    if (this.state.inHand) {
      inHand = 1;
    }
    await this.setState({
      style: {},
    });

    let reportToFinal = [];
    const testReportTo = await this.state.reportTo.map(
      async (filterReportTo) => {
        const filterNew = filterReportTo;
        console.log(filterReportTo, "rohan");
        console.log(filterNew, "rohan");
        const reportTo = await this.state.unique_reportTo.filter(
          async (value) => {
            if (value.Firstname == filterNew) {
              reportToFinal.push(value.UserID);
            }
          }
        );
      }
    );
    // console.log(reportToFinal.toString());

    // console.log(geoID.toString(), 'geoID');
    const projectCnt = this.state.projectName.length;

    const acc = `{"BeneficiaryName":"${this.state.bankName}","AccountNo":"${this.state.accountNo}","IFSC":"${this.state.ifsc}" }`;
    const jdata = `{"geoID":"${geoID.toString()}","email":"${
      this.state.email
    }","EmploymentType":"${this.state.employmentType}","Department":"${
      this.state.department
    }","ProjectCnt":"${projectCnt}","DoB":"${this.state.dob}"}`;
    const SalaryDetails = `{"MonthlyCTC":"${this.state.monthlyCTC}","InHand":"${inHand}","PFno":"${this.state.pfNo}","UANno":"${this.state.uanNo}","ESICno":"${this.state.esicNo}"}`;
    console.log(jdata);
    const res = await axios
      .post(`${Config.hostName}/ems/registerEmployee`, {
        locationid: this.state.locationId,
        name: this.state.fullName,
        mobile: this.state.mobile,
        access: access.toString(),
        designation: designation[0],
        startDate: this.state.doj,
        userType: designation[1],
        isattendance: "0",
        adharfront: this.state.aadharFrontImage,
        adharback: this.state.aadharBackImage,
        pan: this.state.pancardImage,
        isOnroll: this.state.employeeType,
        Projects: this.state.projectName.toString(),
        userID: this.props.auth.user.USERID,
        reportto: reportToFinal.toString(),
        bloodGroup: this.state.bloodGroup,
        BankAccountDeatils: acc,
        EmployeePicture: this.state.profile,
        jdata: jdata,
        SalaryDetails: SalaryDetails,
      })
      .then(async (res) => {
        await this.setState({
          style: {
            display: "none",
          },
        });
        if (res.data.success) {
          alert("Record Sent to HR for Verification");
          // window.location.reload(false);
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmit} action="/" method="POST">
          <Container
            pt={3}
            style={{
              maxWidth: "fit-content",
              background: "white",
              paddingBottom: "12%",
              marginTop: "7%",
            }}
          >
            <div id="cover-spin" style={this.state.style}></div>
            <Typography
              pt={2}
              style={{ textAlign: "center", color: "#333333" }}
              variant="h4"
            >
              {" "}
              <b>
                <u> Employee Registration</u>
              </b>
            </Typography>

            <Typography pt={2} variant="h6">
              {" "}
              <b> Personal Info</b>
            </Typography>
            <Grid pt={1} container>
              <Grid item md={4} sm={12}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      onChange={this.onFileUpload}
                      type="file"
                    />
                    <IconButton aria-label={100} component="span">
                      <Badge
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={"+"}
                        color="primary"
                        style={{ borderRadius: "0px" }}
                      >
                        {this.state.profile == "" ? (
                          <Avatar
                            src={require("./user-icon2.png")}
                            alt="E"
                            sx={{ width: 100, height: 100 }}
                          />
                        ) : (
                          <Avatar
                            src={this.state.profile}
                            style={{ borderRadius: "0px" }}
                            alt="E"
                            sx={{ width: 100, height: 100 }}
                          />
                        )}
                      </Badge>
                    </IconButton>
                  </label>
                </FormControl>
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="fullName"
                  label="Full Name"
                  required
                  variant="standard"
                  placeholder="As per on Aadhar Card"
                  focused
                  onChange={(e) => {
                    if (e.target.value != "") {
                      this.setState({
                        fullName: e.target.value,
                        fullNameValidate: false,
                      });
                    } else {
                      this.setState({
                        fullNameValidate: true,
                      });
                    }
                  }}
                  error={this.state.fullNameValidate}
                  style={{ marginTop: "11%" }}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="mobile"
                  label="Mobile"
                  required
                  variant="standard"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => {
                    if (e.target.value != "") {
                      this.setState({
                        mobile: e.target.value,
                        mobileValidate: false,
                      });
                    } else {
                      this.setState({
                        mobileValidate: true,
                      });
                    }
                  }}
                  error={this.state.mobileValidate}
                  style={{ marginTop: "11%" }}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  sx={{ m: 1 }}
                  id="email"
                  label="Email"
                  required
                  variant="standard"
                  value={this.state.email}
                  type="email"
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  sx={{ m: 1 }}
                  name="someDate"
                  variant="standard"
                  label="Date of Joining"
                  InputLabelProps={{ shrink: true, required: true }}
                  type="date"
                  defaultValue={this.state.doj}
                  onChange={(e) => {
                    if (e.target.value != "") {
                      this.setState({
                        doj: e.target.value,
                      });
                    } else {
                    }
                  }}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  sx={{ m: 1 }}
                  name="dob"
                  variant="standard"
                  label="Date of Birth"
                  InputLabelProps={{ shrink: true, required: true }}
                  type="date"
                  defaultValue={this.state.dob}
                  onChange={(e) => {
                    if (e.target.value != "") {
                      this.setState({
                        dob: e.target.value,
                        dobValidate: false,
                      });
                    } else {
                      this.setState({
                        dobValidate: true,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid item md={3}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 165 }}
                  style={{ marginLeft: "0px" }}
                >
                  <InputLabel id="Employment-Type">Employment Type</InputLabel>
                  <Select
                    required
                    labelId="Employment-Type"
                    id="Employment2"
                    value={this.state.employmentType}
                    onChange={this.handleEmploymentChange}
                    label="Employment Type"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Probation">Probation</MenuItem>
                    <MenuItem value="OffRole">OffRole</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 165 }}
                  style={{ marginLeft: "0px" }}
                >
                  <InputLabel id="department">Department</InputLabel>
                  <Select
                    labelId="department"
                    id="department2"
                    value={this.state.department}
                    onChange={this.handleDepartmentChange}
                    label="Department"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Department</em>
                    </MenuItem>

                    {this.state.unique_departments.map((eachItem, key) => (
                      <MenuItem value={eachItem.Department} key={key}>
                        {eachItem.Department}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 165 }}
                  style={{ marginLeft: "0px" }}
                >
                  <InputLabel id="designation">Designation</InputLabel>
                  <Select
                    labelId="designation"
                    id="
                    2"
                    value={this.state.designation}
                    onChange={this.handleDesignationChange}
                    label="Designation"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Designation</em>
                    </MenuItem>

                    {this.state.unique_designations.map((eachItem, key) => (
                      <MenuItem
                        value={eachItem.Designation + "&&" + eachItem.userType}
                        key={key}
                      >
                        {eachItem.Designation}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 165 }}
                  style={{ marginLeft: "0px" }}
                >
                  <InputLabel id="employeetype">Employee Type</InputLabel>
                  <Select
                    labelId="employeetype"
                    id="employeeType"
                    value={this.state.employeeType}
                    onChange={this.handleTypeChange}
                    label="Employee Type"
                  >
                    <MenuItem value="0">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="1">OnRole</MenuItem>
                    <MenuItem value="0">OffRole</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 165 }}
                  style={{ marginLeft: "0px" }}
                >
                  <InputLabel id="bloodGroup">Blood Group</InputLabel>
                  <Select
                    labelId="bloodGroup"
                    id="bloodGroup2"
                    value={this.state.bloodGroup}
                    onChange={(e) => {
                      this.setState({
                        bloodGroup: e.target.value,
                      });
                    }}
                    label="Blood Group"
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="A+">A-positive (A+)</MenuItem>
                    <MenuItem value="A-">A-negative (A-)</MenuItem>
                    <MenuItem value="B+">B-positive (B+)</MenuItem>
                    <MenuItem value="B-">B-negative (B-)</MenuItem>
                    <MenuItem value="AB+">AB-positive (AB+)</MenuItem>
                    <MenuItem value="AB-">AB-negative (AB-)</MenuItem>
                    <MenuItem value="O+">O-positive (O+)</MenuItem>
                    <MenuItem value="O-">O-negative (O-)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 165 }}
                  style={{ marginLeft: "0px" }}
                >
                  <InputLabel id="location">Work Location</InputLabel>
                  <Select
                    labelId="location"
                    id="location2"
                    value={this.state.locationId}
                    onChange={this.handleLocationChange}
                    label="Work Location"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Location</em>
                    </MenuItem>

                    {this.state.unique_cities.map((eachItem, key) => (
                      <MenuItem value={eachItem.Locationid} key={key}>
                        {eachItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={5}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 165 }}
                  style={{ marginLeft: "0px", display: "flex" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Project
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    multiple
                    value={this.state.projectName}
                    onChange={(e) => {
                      this.handleProjectChange(e);
                    }}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    label="Project"
                  >
                    {this.state.unique_project.map((name, key) => (
                      <MenuItem key={key} value={name.projectName}>
                        <Checkbox
                          checked={
                            this.state.projectName.indexOf(name.projectName) >
                            -1
                          }
                        />
                        <ListItemText primary={name.projectName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}></Grid>

              <Grid item md={5}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 165 }}
                  style={{ marginLeft: "0px", display: "flex" }}
                >
                  {/* <InputLabel id="reportTo">Report To</InputLabel>
                  <Select
                    labelId="reportTo"
                    id="unique_reportTo2"
                    value={this.state.reportTo}
                    onChange={this.handleReportTo}
                    label="Report To"
                  >
                    <MenuItem value="">
                      <em>Select Report To</em>
                    </MenuItem>

                    {this.state.unique_reportTo.map((eachItem, key) => (
                      <MenuItem value={eachItem.UserID} key={key} >{eachItem.Firstname}</MenuItem>
                    ))}
                  </Select> */}

                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    value={this.state.reportTo}
                    options={this.state.unique_reportTo.map(
                      (option) => option.Firstname
                    )}
                    disableCloseOnSelect
                    onChange={(e, newValue) => {
                      this.handleReportToChange(e, newValue);
                    }}
                    renderOption={(props, options, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {options}
                      </li>
                    )}
                    required
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Report To"
                        variant="standard"
                        placeholder="Select Report To..."
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid pt={4} item md={12}>
                <Typography pt={1} pb={2} variant="h6">
                  <b>Access</b>
                </Typography>

                <Stack direction="row" style={{ display: "block" }} spacing={1}>
                  {this.state.unique_access.map((eachItem, key) => {
                    return eachItem.isChecked == false ? (
                      <Chip
                        key={eachItem.accessTypeid}
                        label={eachItem.AccessType}
                        onClick={(e) => {
                          this.handleAccessChange(eachItem.accessTypeid);
                        }}
                        style={{ borderRadius: "0px", margin: "10px" }}
                      />
                    ) : (
                      <Chip
                        key={eachItem.accessTypeid}
                        color="primary"
                        label={eachItem.AccessType}
                        onClick={(e) => {
                          this.handleAccessChange(eachItem.accessTypeid);
                        }}
                        style={{ borderRadius: "0px", margin: "10px" }}
                      />
                    );
                  })}
                </Stack>
              </Grid>

              <Grid pt={4} item md={12}>
                <div
                  style={{
                    border: `${this.state.geofenceAlertBorder}`,
                    padding: "10px",
                  }}
                >
                  <Typography pt={1} pb={2} variant="h6">
                    <b>Geofence</b>
                  </Typography>

                  <Stack
                    direction="row"
                    style={{ display: "block" }}
                    spacing={1}
                  >
                    {this.state.unique_geofence.map((eachItem, key) => {
                      return eachItem.isChecked == false ? (
                        <Chip
                          key={eachItem.GeoID}
                          label={eachItem.Hubname}
                          onClick={(e) => {
                            this.handleGeofenceChange(eachItem.GeoID);
                          }}
                          style={{ borderRadius: "0px", margin: "10px" }}
                        />
                      ) : (
                        <Chip
                          key={eachItem.GeoID}
                          color="primary"
                          label={eachItem.Hubname}
                          onClick={(e) => {
                            this.handleGeofenceChange(eachItem.GeoID);
                          }}
                          style={{ borderRadius: "0px", margin: "10px" }}
                        />
                      );
                    })}
                  </Stack>
                  <p
                    style={{
                      color: "red",
                      fontWeight: 500,
                      display: `${this.state.geofenceAlertDisplay}`,
                    }}
                  >
                    Geofence selection is required
                  </p>
                </div>
              </Grid>
            </Grid>

            <Typography pt={4} variant="h6">
              <b> Bank Details</b>
            </Typography>
            <Grid pt={1} container>
              <Grid item md={4}>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  id="bankName"
                  label="Beneficiary Name"
                  variant="standard"
                  required
                  onChange={(e) => {
                    if (e.target.value != "") {
                      this.setState({
                        bankName: e.target.value,
                        bankNameValidate: false,
                      });
                    } else {
                      this.setState({
                        bankNameValidate: true,
                      });
                    }
                  }}
                  error={this.state.bankNameValidate}
                />
              </Grid>

              <Grid item md={4}>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  id="accNo"
                  label="Account No."
                  variant="standard"
                  required
                  autoComplete="new-password"
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  type="password"
                  onChange={(e) => {
                    if (e.target.value != "") {
                      this.setState({
                        accountNo: e.target.value,
                        accountNoValidate: false,
                        accFocused: true,
                      });
                    } else {
                      this.setState({
                        accountNoValidate: true,
                        confirmAccountNoValidate: true,
                      });
                    }
                  }}
                  color={this.state.accountNoColor}
                  focused={this.state.accFocused}
                  error={this.state.accountNoValidate}
                />
              </Grid>

              <Grid item md={4}>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  id="confirmAccNo"
                  label="Confirm Account No"
                  variant="standard"
                  required
                  onChange={(e) => {
                    if (e.target.value != "") {
                      if (e.target.value != this.state.accountNo) {
                        this.setState({
                          accountNoColor: "primary",
                          accountNoValidate: true,
                          confirmAccountNoValidate: true,
                        });
                      } else {
                        this.setState({
                          accountNoColor: "success",
                          accountNoValidate: false,
                          confirmAccountNoValidate: false,
                        });
                      }
                      this.setState({
                        confirmAccountNo: e.target.value,
                        accFocused: true,
                      });
                    } else {
                      this.setState({
                        accountNoColor: "primary",
                        confirmAccountNoValidate: true,
                      });
                    }
                  }}
                  focused={this.state.accFocused}
                  color={this.state.accountNoColor}
                  error={this.state.confirmAccountNoValidate}
                />
              </Grid>

              <Grid item md={4}>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  id="ifsc"
                  label="IFSC"
                  variant="standard"
                  required
                  onChange={(e) => {
                    if (e.target.value != "") {
                      this.setState({
                        ifsc: e.target.value,
                        ifscValidate: false,
                      });
                    } else {
                      this.setState({
                        ifscValidate: true,
                      });
                    }
                  }}
                  error={this.state.ifscValidate}
                />
              </Grid>

              <Grid item md={4}></Grid>

              <Grid item md={3}></Grid>

              <Grid pt={2} item md={6}>
                <label style={{ marginLeft: "2%" }}>
                  <b>PanCard </b>
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Button
                  color="primary"
                  variant="contained"
                  component="label"
                  onChange={this.onPanUpload}
                  style={{
                    marginLeft: "115px",
                    background: "rgb(234 67 68)",
                    color: "white",
                  }}
                  className={this.state.color}
                >
                  {this.state.panImageText}
                  <input type="file" hidden accept="image/*" />
                </Button>
              </Grid>

              <Grid pt={2} item md={6}>
                {this.state.pancardImage != "" && (
                  <Card style={{ width: "80px" }} className="pan-image">
                    {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}

                    <CardMedia
                      component="img"
                      height="auto"
                      image={this.state.pancardImage}
                      onClick={(e) => {
                        this.handleOpenModal(1);
                      }}
                      alt="Error"
                    />
                  </Card>
                )}
              </Grid>

              <Grid pt={2} item md={6}>
                <label style={{ marginLeft: "2%" }}>
                  <b>Aadhar Front </b>
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Button
                  color="primary"
                  variant="contained"
                  component="label"
                  onChange={(e) => {
                    this.onAadharUpload("1", e);
                  }}
                  style={{
                    marginLeft: "86px",
                    background: "rgb(234 67 68)",
                    color: "white",
                  }}
                  className={this.state.aadharFrontcolor}
                >
                  {this.state.aadharFrontText}
                  <input type="file" hidden accept="image/*" />
                </Button>
              </Grid>

              <Grid pt={2} item md={6}>
                {this.state.aadharFrontImage != "" && (
                  <Card style={{ width: "80px" }} className="pan-image">
                    {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}
                    <CardMedia
                      component="img"
                      height="auto"
                      image={this.state.aadharFrontImage}
                      onClick={(e) => {
                        this.handleOpenModal(2);
                      }}
                      alt="Error"
                    />
                  </Card>
                )}
              </Grid>

              <Grid pt={2} item md={6}>
                <label style={{ marginLeft: "2%" }}>
                  <b>Aadhar Back &nbsp;</b>
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Button
                  color="primary"
                  variant="contained"
                  component="label"
                  onChange={(e) => {
                    this.onAadharUpload("2", e);
                  }}
                  style={{
                    marginLeft: "86px",
                    background: "rgb(234 67 68)",
                    color: "white",
                  }}
                  className={this.state.aadharBackcolor}
                >
                  {this.state.aadharBackText}
                  <input type="file" hidden accept="image/*" />
                </Button>
              </Grid>

              <Grid pt={2} item md={6}>
                {this.state.aadharBackImage != "" && (
                  <Card style={{ width: "80px" }} className="pan-image">
                    {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}

                    <CardMedia
                      component="img"
                      height="auto"
                      image={this.state.aadharBackImage}
                      onClick={(e) => {
                        this.handleOpenModal(3);
                      }}
                      alt="Error"
                    />
                  </Card>
                )}
              </Grid>
            </Grid>

            <Typography pt={4} variant="h6">
              {" "}
              <b> Salary Details</b>
            </Typography>

            <Grid pt={1} container>
              <Grid item md={12}>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  id="monthlyCTC"
                  required
                  label="Monthly CTC"
                  onChange={(e) => {
                    this.setState({
                      monthlyCTC: e.target.value,
                    });
                  }}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="standard"
                />
                <FormControlLabel
                  sx={{ m: 1 }}
                  control={
                    <Checkbox
                      defaultChecked={this.state.inHand}
                      onChange={(e) => {
                        this.setState({
                          inHand: !this.state.inHand,
                        });
                      }}
                    />
                  }
                  label="In Hand"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  sx={{ m: 1 }}
                  id="pfNo"
                  label="PF No."
                  onChange={(e) => {
                    this.setState({
                      pfNo: e.target.value,
                    });
                  }}
                  variant="standard"
                />
              </Grid>

              <Grid item md={4}>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  id="uanNo"
                  label="UAN No."
                  onChange={(e) => {
                    this.setState({
                      uanNo: e.target.value,
                    });
                  }}
                  variant="standard"
                />
              </Grid>

              <Grid item md={4}>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  id="esicNo"
                  label="ESIC No."
                  onChange={(e) => {
                    this.setState({
                      esicNo: e.target.value,
                    });
                  }}
                  variant="standard"
                />
              </Grid>

              <Grid item md={12} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  style={{
                    marginTop: "18px",
                    background: "rgb(234 67 68)",
                    color: "white",
                  }}
                  endIcon={<SendIcon />}
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Box sx={style2}>
              <Card style={{}} className="pan-image">
                {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}

                <CardMedia
                  component="img"
                  height="auto"
                  image={this.state.modalImage}
                  alt="Error"
                />
              </Card>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

RegisterEmployee.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(RegisterEmployee);
