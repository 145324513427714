import React, { Component } from "react";
import axios from "axios";
import Config from "../../../../config";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";


import "../../../../assets/customCssFiles/loader-spinner.css";

import {
    Container,
    Card,
    CardContent,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Tooltip,
    Dialog,
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Slide,
    Modal,
    Fade,
    Box,
    Typography,
    Alert,
    Snackbar,
    Paper,
} from "@mui/material";
import "./billing.css";
import MUIDataTable from "mui-datatables";


import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "../../../../assets/customImages/excel.jpg";
import CloseIcon from "@material-ui/icons/Close";
import FileDownloadOffTwoToneIcon from "@mui/icons-material/FileDownloadOffTwoTone";
import GetAppIcon from "@mui/icons-material/GetApp";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import AttachmentIcon from "@material-ui/icons/Attachment";
import InfoIcon from "@material-ui/icons/Info";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';




const options = {
    filterType: "multiselect",
    // filterOptions: {fullWidth: true},
    selectableRows: "none",
    setRowProps: (row) => {
        if (row[0] === "New") {
            return {
                style: { background: "snow" },
            };
        }
    },
    draggableColumns: { enabled: true },
    pagination: true,
    // resizableColumns: true,
    // responsive: "standard",
    fixedHeader: false,
};

const style4 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },

    },
    palette: {
        primary: {
            light: '#757ce8',
            main: '#f44336',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});


export class BillingDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiData: [],
            alertSnackbar: false,
            vertical: "top",
            horizontal: "center",
            uniqueDateRanges: [],
            startDate: '',
            endDate: '',
            dateRangeSelected: '',
            uniqueMonthRanges: [],
            billingMonth: '',
            eagleViewBillingMonth: '',
            reportID: 1,
            finalReportID: 1,
            invoiceDetailsTotal: 0,
            invoiceDetailsTotalEagle: 0,
            eagleViewStatsTotal: 0,
            openInvStatsDetails: false,
            openInvStatsItemDetails: false,
            openEagleView: false,
            downloadFiles: [],
            openDownloadFilesModal: false,
            eagleViewStats: [],
            rowData: [],
            rowDataDetails: [],
            rowItemDetailData: [],
            invoiceStatusDD: [],
            clickedStatus: '',
            columnsUser: [
                {
                    name: "firstname",
                    label: "User Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "billingmonth",
                    label: "Billing Month",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "invcnt",
                    label: "Invoice Count",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <a className="invcnt"
                                    onClick={() => this.handlenvOpenInvStatsModal('user', tableMeta.rowIndex)}
                                >
                                    <u>{value} </u>
                                </a>
                            )
                        }
                    },
                },
                {
                    name: "Total",
                    label: "Billed Amount",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <span>  ₹{" "}{new Intl.NumberFormat("en-IN").format(value)}</span>
                            )
                        }
                    },
                },
                {
                    name: "invcnt",
                    label: "Expenses",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <>
                                    -
                                </>
                            )
                        }
                    },
                },
                {
                    name: "invcnt",
                    label: "Margins",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <>
                                    -</>
                            )
                        }
                    },
                },
            ],
            columnsClient: [
                {
                    name: "clientName",
                    label: "Client Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "placeofService",
                    label: "Place of Service",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "billingmonth",
                    label: "Billing Month",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "invcnt",
                    label: "Invoice Count",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <a className="invcnt"
                                    onClick={() => this.handlenvOpenInvStatsModal('client', tableMeta.rowIndex)}
                                >
                                    <u>{value} </u>
                                </a>
                            )
                        }
                    },
                },
                {
                    name: "Total",
                    label: "Billed Amount",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <span>  ₹{" "}{new Intl.NumberFormat("en-IN").format(value)}</span>
                            )
                        }
                    },
                },
                {
                    name: "invcnt",
                    label: "Expenses",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <>
                                    -
                                </>
                            )
                        }
                    },
                },
                {
                    name: "invcnt",
                    label: "Margins",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <>
                                    -</>
                            )
                        }
                    },
                },
            ],
            columnsInvDetailsEagle: [
                {
                    name: "createdOn",
                    label: "Created Date",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "projectName",
                    label: "Project Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "invoiceno",
                    label: "Invoice No.",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "ZonalManager",
                    label: "Zonal Manager",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "billingmonth",
                    label: "Billing Month",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "grandTotal",
                    label: "Grand Total",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <span>  ₹{" "}{new Intl.NumberFormat("en-IN").format(value)}</span>
                            )
                        }
                    },
                },
                {
                    name: "status",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            console.log(value);

                            return (
                                <>
                                    <FormControl sx={{ m: 1 }} style={{ width: '25ch' }} fullWidth>
                                        <InputLabel id="viewInvoiceMonth">Select Status</InputLabel>
                                        <Select
                                            labelId="viewInvoiceMonth"
                                            id="viewInvoiceMonth2"
                                            value={value.toLowerCase()}
                                            onChange={(e) =>
                                                this.handleChangeBillingStatus(
                                                    tableMeta.rowIndex, e.target.value
                                                )
                                            }
                                            label="Select Status"
                                            displayEmpty

                                        >
                                            {this.state.invoiceStatusDD.map((eachItem, key) => (
                                                <MenuItem value={eachItem.Status.toLowerCase()} key={key}>
                                                    {eachItem.Status}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </>
                            )
                        }
                    },
                },
                {
                    name: "attachements",
                    label: "Action",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            let attachementsParse = [];
                            if (value != undefined || value != null) {
                                attachementsParse = JSON.parse(
                                    value
                                );
                            }


                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    {this.state.rowDataDetails[tableMeta.rowIndex].invURL == "" || this.state.rowDataDetails[tableMeta.rowIndex].invURL == null || this.state.rowDataDetails[tableMeta.rowIndex].invURL == undefined ? (
                                        <div>
                                            <Tooltip title=" No Invoice">
                                                <FileDownloadOffTwoToneIcon />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div>
                                            <Tooltip title="Download Invoice">
                                                <a
                                                    // component={RouterLink}
                                                    size="small"
                                                    target="_blank"
                                                    href={this.state.rowDataDetails[tableMeta.rowIndex].invURL}
                                                    style={{ color: "black", minWidth: "40px" }}
                                                // variant="outlined"
                                                >
                                                    <GetAppIcon />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        {value != '' && value != null && attachementsParse[0].DocLink != '' ?
                                            <Tooltip title="Download">
                                                <Button
                                                    // component={RouterLink}
                                                    size="small"
                                                    // to={{
                                                    //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                                                    //   orders: this.state.orders
                                                    // }}
                                                    onClick={(e) => this.handleOpenAttachmentsModal(value, e)}

                                                    style={{ color: 'black' }}
                                                // variant="outlined"
                                                >
                                                    <FileCopyIcon />
                                                </Button>
                                            </Tooltip> :

                                            <Tooltip title="Download">
                                                <Button
                                                    size="small"

                                                    style={{ color: 'black' }}
                                                >
                                                    <FolderOffOutlinedIcon />
                                                </Button>
                                            </Tooltip>
                                        }

                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <Tooltip title="Item Details">
                                            <InfoIcon
                                                onClick={() =>
                                                    this.handleOpenInvStatsItemDetailsModal(
                                                        tableMeta.rowIndex
                                                    )
                                                }
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            );
                        },
                    },
                },
            ],
            columnsInvDetails: [
                {
                    name: "createdOn",
                    label: "Created Date",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "projectName",
                    label: "Project Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "billingmonth",
                    label: "Billing Month",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "grandTotal",
                    label: "Grand Total",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <span>  ₹{" "}{new Intl.NumberFormat("en-IN").format(value)}</span>
                            )
                        }
                    },
                },
                {
                    name: "status",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "attachements",
                    label: "Action",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            let attachementsParse = [];
                            if (value != undefined || value != null) {
                                attachementsParse = JSON.parse(
                                    value
                                );
                            }
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    {this.state.rowDataDetails[tableMeta.rowIndex].invURL == "" || this.state.rowDataDetails[tableMeta.rowIndex].invURL == null || this.state.rowDataDetails[tableMeta.rowIndex].invURL == undefined ? (
                                        <div>
                                            <Tooltip title=" No Invoice">
                                                <FileDownloadOffTwoToneIcon />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div>
                                            <Tooltip title="Download Invoice">
                                                <a
                                                    // component={RouterLink}
                                                    size="small"
                                                    target="_blank"
                                                    href={this.state.rowDataDetails[tableMeta.rowIndex].invURL}
                                                    style={{ color: "black", minWidth: "40px" }}
                                                // variant="outlined"
                                                >
                                                    <GetAppIcon />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        {value != '' && value != null && attachementsParse[0].DocLink != '' ?
                                            <Tooltip title="Download">
                                                <Button
                                                    // component={RouterLink}
                                                    size="small"
                                                    // to={{
                                                    //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                                                    //   orders: this.state.orders
                                                    // }}
                                                    onClick={(e) => this.handleOpenAttachmentsModal(value, e)}

                                                    style={{ color: 'black' }}
                                                // variant="outlined"
                                                >
                                                    <FileCopyIcon />
                                                </Button>
                                            </Tooltip> :

                                            <Tooltip title="Download">
                                                <Button
                                                    size="small"

                                                    style={{ color: 'black' }}
                                                >
                                                    <FolderOffOutlinedIcon />
                                                </Button>
                                            </Tooltip>
                                        }
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <Tooltip title="Item Details">
                                            <InfoIcon
                                                onClick={() =>
                                                    this.handleOpenInvStatsItemDetailsModal(
                                                        tableMeta.rowIndex
                                                    )
                                                }
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            );
                        },
                    },
                },
            ],
            columnsInvItemDetails: [
                {
                    name: "Description",
                    label: "Description",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },

                {
                    name: "SAC",
                    label: "SAC",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "iGST",
                    label: "iGST",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "iGSTAmount",
                    label: "iGST Amount",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "cGST",
                    label: "cGST",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "cGSTAmount",
                    label: "cGST Amount",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "sGST",
                    label: "sGST",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "sGSTAmount",
                    label: "sGST Amount",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "grandTotal",
                    label: "Grand Total",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
            ],





        }
    }

    toggle = async () => {
        await this.setState({
            modal: !this.state.modal,
        });
    };

    getUniqueDateRanges = async () => {
        await axios
            .get(
                `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
            )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                        dateRangeSelected: 8,
                        startDate: res.data.data[8].startDate,
                        endDate: res.data.data[8].endDate,
                    });
                } else {
                    await this.setState({
                        alertSnackbar: true,
                    })
                }
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                })
            });
    };

    getUniqueMonthRanges = async () => {
        await axios
            .get(`${Config.hostName}/bs/dashboard/billingMonthDD`)
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueMonthRanges: res.data.data,
                    });
                } else {
                    await this.setState({
                        alertSnackbar: true,
                    })
                }
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                })
            });
    };

    getInvDetails = async (reportID) => {
        this.setState({
            finalReportID: reportID
        })
        const res = await axios
            .post(`${Config.hostName}/bs/dashboard/invStats`, {
                reportID: reportID,
                billingmonth: this.state.billingMonth,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            })
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    let total = 0;
                    await res.data.data.map(async (e, key) => {
                        total += e.Total;
                    })
                    await this.setState({
                        invoiceDetailsTotal: total,
                        rowData: res.data.data,
                    })
                } else {
                    await this.setState({
                        alertSnackbar: true,
                        invoiceDetailsTotal: 0,
                        rowData: [],
                    })
                }
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                })
            });
    }





    componentWillMount = async () => {
        await this.setState({
            style: {},
        });
        await this.getUniqueDateRanges();
        await this.getUniqueMonthRanges();
        await this.getInvDetails(4);
        await this.setState({
            style: { display: "none" },
        });
    };

    handleChangeDateRange = async (e) => {
        let value = e.target.value;

        if (value == 2222) {
            await this.setState({
                dateRangeSelected: e.target.value,
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            await this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: eachDate.startDate,
                        endDate: eachDate.endDate,
                        billingMonth: '',
                    });
                }
            });
        }
        await this.getInvDetails(this.state.reportID == 0 ? 3 : 4)
    };


    handleChangeBillingMonth = async (e) => {
        await this.setState({
            startDate: "",
            endDate: "",
            dateRangeSelected: "",
            billingMonth: e.target.value
        });
        await this.getInvDetails(this.state.reportID == 0 ? 1 : 2)

    };

    handleChangeToggle = async (e) => {
        await this.setState({
            reportID: !this.state.reportID
        })
        if (this.state.billingMonth != "") {
            await this.getInvDetails(this.state.reportID == 0 ? 1 : 2)
        } else {
            await this.getInvDetails(this.state.reportID == 0 ? 3 : 4)
        }

    }

    handleChangeBillingStatus = async (data, status) => {
        await this.setState({
            style: {},
        });

        if (window.confirm("Are you sure you want to update the status?")) {
            // Save it!
            const userData = localStorage.getItem('persist:root');
            const auth = JSON.parse(userData).auth;

            let StatusID = '';
            await this.state.invoiceStatusDD.map((each) => {
                if (each.Status.toLowerCase() == status.toLowerCase()) {
                    StatusID = each.StatusID;
                    return 1;
                }
            })


            const res = await axios
                .post(`${Config.hostName}/billing/updatecontentDashboard`, {
                    invID: this.state.rowDataDetails[data].invID,
                    jdata: `{"billingmonth":"${this.state.eagleViewBillingMonth}","contentID":"1","statusID":"${StatusID}","userID":"${JSON.parse(auth).USERID}","ccxrefID":"${this.state.rowDataDetails[data].ccxrefid}"}`

                })
                .then(async (res) => {
                    if (res.data.success == true) {
                        console.log(res);
                        alert('Status Updated');
                    } else {
                        alert(res.data.message);
                    }
                    await this.getEagelViewStats();
                    await this.changeEageViewTable(this.state.clickedStatus);
                })
                .catch(async (err) => {
                    await this.setState({
                        alertSnackbar: true,
                    })
                });
        } else {
            // Do nothing!
            console.log('cancel');
        }
        await this.setState({
            style: { display: "none" },

        })

    }

    handlenvOpenInvStatsModal = async (client, e) => {
        // console.log(e);
        // console.log(this.state.finalReportID);
        await this.setState({
            style: {},
        });

        let jdata = '';

        if (client == 'client') {
            jdata = `{"clientName":"${this.state.rowData[e].clientName}","placeofService": "${this.state.rowData[e].placeofService}","startDate":"${this.state.startDate}","endDate":"${this.state.endDate}"}`
        } else {
            console.log(this.state.rowData[e].userid);
            jdata = `{"userid":"${this.state.rowData[e].userid}","startDate":"${this.state.startDate}","endDate":"${this.state.endDate}"}`
        }
        const res = await axios
            .post(`${Config.hostName}/bs/dashboard/invStatsDetails`, {
                reportID: this.state.finalReportID,
                billingmonth: this.state.rowData[e].billingmonth,
                jdata: jdata,
            })
            .then(async (res) => {
                console.log(res.data);
                if (res.data.data.length > 0) {
                    await this.setState({
                        rowDataDetails: res.data.data,
                        openInvStatsDetails: true
                    })
                } else {
                    await this.setState({
                        rowDataDetails: [],
                        alertSnackbar: true,
                    });
                }
                await this.setState({
                    style: { display: "none" },
                });
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                    style: { display: "none" },
                })
            });
        ;
    }

    handleOpenInvStatsItemDetailsModal = async (i) => {
        if (this.state.rowDataDetails[i].itemDetails != undefined || this.state.rowDataDetails[i].itemDetails != null) {
            let itemDetails = this.state.rowDataDetails[i].itemDetails;
            const itemDetailParsed = JSON.parse(itemDetails);
            await this.setState({
                openInvStatsItemDetails: true,
                rowItemDetailData: itemDetailParsed,
            })
        } else {
            await this.setState({
                alertSnackbar: true,
            })
        }
    }

    handleOpenAttachmentsModal = async (value, e) => {


        await this.setState({
            downloadFiles: JSON.parse(value),
            openDownloadFilesModal: true
        })
    }

    handleCloseAttachmentsModal = async () => {
        await this.setState({
            downloadFiles: [],
            openDownloadFilesModal: false
        })
    }

    handleCloseAttachModal = async (e) => {
        await this.setState({ openAttachModal: false });
    };

    handleOpenAttachModal = async (value) => {
        const extension = value
            .split(".")
            .pop()
            .toLowerCase();
        console.log(extension);

        if (extension == "xlsx" || extension == "xls") {
            return await this.setState({ attachExcel: value, openAttachModal: true });
        }

        if (extension == "pdf") {
            return await this.setState({ attachPDF: value, openAttachModal: true });
        } else {
            return await this.setState({ attachImage: value, openAttachModal: true });
        }
    };

    getEagelViewStats = async () => {
        await this.setState({
            style: {},
        });
        const res = await axios
            .post(`${Config.hostName}/bs/dashboard/eagleViewStats`, {
                billingMonth: this.state.eagleViewBillingMonth,
            })
            .then(async (res) => {
                console.log(res.data.data);
                if (res.data.data.length > 0) {
                    let eagleViewStatsTotal = 0;
                    await res.data.data.map(async (e, key) => {
                        eagleViewStatsTotal += e.grandTotal;
                    })

                    await this.setState({
                        eagleViewStats: res.data.data,
                        eagleViewStatsTotal: eagleViewStatsTotal
                    })
                } else {
                    await this.setState({
                        alertSnackbar: true,
                    })
                }
                await this.setState({
                    style: { display: "none" },
                });
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                    style: { display: "none" },
                })

            });
    }

    getStatusEagleView = async () => {
        const res = await axios
            .post(`${Config.hostName}/billing/invoiceStatusDD`, {

            })
            .then(async (res) => {
                await this.setState({
                    invoiceStatusDD: res.data.data
                })
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                    style: { display: "none" },

                })
            });
    }

    changeEageViewTable = async (status) => {
        await this.setState({
            style: {},
            clickedStatus: status
        });
        await this.getStatusEagleView();
        const res = await axios
            .post(`${Config.hostName}/bs/dashboard/eagleView`, {
                billingMonth: this.state.eagleViewBillingMonth,
                status: status,
            })
            .then(async (res) => {
                console.log(res.data.data);
                if (res.data.data.length > 0) {
                    let grandTotal = 0;
                    await res.data.data.map(async (e, key) => {
                        grandTotal += e.grandTotal;
                    })
                    await this.setState({
                        rowDataDetails: res.data.data,
                        invoiceDetailsTotalEagle: grandTotal,
                    })
                } else {
                    await this.setState({
                        alertSnackbar: true,
                    })
                }
                await this.setState({
                    style: { display: "none" },
                });
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                    style: { display: "none" },

                })
            });
    }

    handlOpenEagleView = async () => {
        await this.setState({
            eagleViewBillingMonth: this.state.uniqueMonthRanges[0].billingmonth,
            openEagleView: true,
            invoiceDetailsTotalEagle: 0,
            rowDataDetails: [],
        })
        this.getEagelViewStats();

    }

    handleCloseInvStatsItemDetailsModal = async () => {
        await this.setState({
            openInvStatsItemDetails: false,
        })
    }

    handleCloseInvStatsDetails = async (client, e) => {
        await this.setState({
            openInvStatsDetails: false
        })
    }

    handleCloseEagleView = async (client, e) => {
        await this.setState({
            openEagleView: false
        })
    }

    handleAlertCloseSnackBar = async () => {
        await this.setState({
            alertSnackbar: false,
        });
    };

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    render() {
        const { vertical, horizontal } = this.state;

        return (
            <>
                <ThemeProvider theme={theme}>
                    <Container pt={3} style={{ maxWidth: "inherit", paddingBottom: "12%", paddingTop: "2%", marginTop: "7%", paddingLeft: "0px", paddingRight: "0px", marginBottom: "2vh", }}>
                        <div id="cover-spin" style={this.state.style}></div>
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={this.state.alertSnackbar}
                            autoHideDuration={5000}
                            onClose={this.handleAlertCloseSnackBar}
                            key={vertical + horizontal}
                            style={{ fontSize: '22px' }}
                        >
                            <Alert
                                onClose={this.handleAlertCloseSnackBar}
                                severity="error"
                                sx={{ width: "100%" }}
                                style={{ fontSize: '22px' }}
                            >
                                No Records Found!
                            </Alert>
                        </Snackbar>


                        <Card>
                            <CardContent>

                                <Grid pt={3} container spacing={1}>
                                    <Grid item md={3} className="mt-2">
                                        <FormControl sx={{ m: 1 }} style={{ color: "#666666", }} fullWidth>
                                            <InputLabel id="viewInvoiceBilling">Select Calendar Date</InputLabel>
                                            <Select
                                                labelId="viewInvoiceBilling"
                                                id="viewInvoiceBilling2"
                                                value={this.state.dateRangeSelected}
                                                onChange={this.handleChangeDateRange}
                                                label="Select Calendar Date"
                                                displayEmpty
                                                style={{ color: "#666666", height: "48px", }}
                                            >
                                                <MenuItem disabled>
                                                    <em>Select Calendar Date</em>
                                                </MenuItem>

                                                {this.state.uniqueDateRanges.map((eachItem, key) => (
                                                    <MenuItem value={key} key={key}>
                                                        {eachItem.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={3} className="mt-2">
                                        <FormControl sx={{ m: 1 }} style={{ color: "#666666", height: "48px", marginLeft: '15px' }} fullWidth>
                                            <InputLabel id="viewInvoiceMonth">Select Billing Month</InputLabel>
                                            <Select
                                                labelId="viewInvoiceMonth"
                                                id="viewInvoiceMonth2"
                                                value={this.state.billingMonth}
                                                onChange={this.handleChangeBillingMonth}
                                                label="Select Calendar Date"
                                                displayEmpty
                                                style={{ color: "#666666", height: "48px", }}
                                            >
                                                <MenuItem disabled>
                                                    <em>Select Billing Month</em>
                                                </MenuItem>

                                                {this.state.uniqueMonthRanges.map((eachItem, key) => (
                                                    <MenuItem value={eachItem.billingmonth} key={key}>
                                                        {eachItem.billingmonth}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Grid>

                                    <Grid item md={2} className="mt-2">
                                        <FormControl variant="standard" sx={{ width: "19vh" }} style={{ marginLeft: "80px", marginTop: "17px" }}>
                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                <Grid item>Client</Grid>
                                                <Grid item>
                                                    <Switch
                                                        checked={this.state.reportID}
                                                        onChange={this.handleChangeToggle}
                                                        value="1"
                                                        color="primary"
                                                    />
                                                </Grid>
                                                <Grid item>User</Grid>
                                            </Grid>
                                        </FormControl>

                                    </Grid>
                                    <Grid item md={2} className="mt-2">
                                    </Grid>

                                    <Grid item md={2} className="mt-2">
                                        <FormControl variant="standard" sx={{ width: "19vh" }} style={{ marginLeft: "43px", marginTop: '10px', }}>

                                            <Tooltip title="Eagle View">
                                                <a
                                                    onClick={this.handlOpenEagleView}
                                                    style={{
                                                        cursor: "pointer",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img src={require("./eagle.svg")} className="eagle-img" />
                                                </a>
                                            </Tooltip>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>


                        <Grid mt={3}>
                            <Grid item md={12}>
                                <MUIDataTable
                                    title={`Grand Total - ₹ ${new Intl.NumberFormat("en-IN").format(this.state.invoiceDetailsTotal)}`}
                                    data={this.state.rowData}
                                    columns={this.state.reportID == 1 ? this.state.columnsUser : this.state.columnsClient}
                                    options={options}
                                />
                            </Grid>
                        </Grid>
                    </Container>

                    <Dialog
                        fullScreen
                        TransitionComponent={this.Transition}
                        onClose={this.handleCloseInvStatsDetails}
                        open={this.state.openInvStatsDetails}
                    >
                        <Container
                            maxWidth="xl"
                            style={{
                                maxWidth: "initial",
                                paddingLeft: "0px",
                                paddingRight: "0px",

                            }}
                        >
                            <AppBar
                                style={{
                                    height: "auto",
                                    backgroundColor: "white",
                                    color: "black",
                                }}
                                sx={{ position: "relative" }}
                            >
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={this.handleCloseInvStatsDetails}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>

                                    <Button
                                        autoFocus
                                        color="inherit"
                                        onClick={this.handleCloseInvStatsDetails}
                                    >
                                        Close
                                    </Button>
                                </Toolbar>
                            </AppBar>

                            <div style={{ margin: "26px" }}>

                                <MUIDataTable
                                    title={"Invoice Stats Details"}
                                    data={this.state.rowDataDetails}
                                    columns={this.state.columnsInvDetails}
                                    options={options}
                                />
                            </div>
                        </Container>
                    </Dialog>


                    <Dialog
                        fullScreen
                        TransitionComponent={this.Transition}
                        onClose={this.handleCloseEagleView}
                        open={this.state.openEagleView}
                    >
                        <Container
                            maxWidth="xl"
                            style={{
                                maxWidth: "initial",
                                paddingLeft: "0px",
                                paddingRight: "0px",

                            }}
                        >
                            <AppBar
                                style={{
                                    height: "auto",
                                    backgroundColor: "white",
                                    color: "black",
                                }}
                                sx={{ position: "relative" }}
                            >
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={this.handleCloseEagleView}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>

                                    <Button
                                        autoFocus
                                        color="inherit"
                                        onClick={this.handleCloseEagleView}
                                    >
                                        Close
                                    </Button>
                                </Toolbar>
                            </AppBar>

                            <div style={{ margin: "26px" }}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" color="inherit" component="div" style={{ textAlign: 'center' }}>
                                            <b><u> Eagle View for Billing Month</u></b>
                                        </Typography>
                                        <br />
                                        <Grid pt={1} container spacing={1}>

                                            <Grid item md={12} className="mt-2">
                                                <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '29px' }}>
                                                    {this.state.eagleViewStatsTotal != 0 && <>
                                                        Grand Total :- <b style={{ color: 'red' }}> ₹ {new Intl.NumberFormat("en-IN").format(this.state.eagleViewStatsTotal)}</b>
                                                    </>

                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item md={2} className="mt-2">

                                                <FormControl variant="standard" sx={{ width: "21vh" }}>
                                                    <InputLabel id="viewInvoiceMonth">Select Billing Month</InputLabel>
                                                    <Select
                                                        labelId="viewInvoiceMonth"
                                                        id="viewInvoiceMonth2"
                                                        value={this.state.eagleViewBillingMonth}
                                                        onChange={(e) => { this.setState({ eagleViewBillingMonth: e.target.value }); this.getEagelViewStats(); }}
                                                        label="Billing Month"
                                                        variant="standard"
                                                    >
                                                        <MenuItem disabled>
                                                            <em>Select Billing Month</em>
                                                        </MenuItem>

                                                        {this.state.uniqueMonthRanges.map((eachItem, key) => (
                                                            <MenuItem value={eachItem.billingmonth} key={key}>
                                                                {eachItem.billingmonth}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={10} className="mt-2">

                                                <Grid pt={1} container spacing={1}>

                                                    {/* here */}
                                                    {this.state.eagleViewStats != [] && this.state.eagleViewStats.map((eachItem, key) => (
                                                        <Grid item md={3} className="mt-2">
                                                            <Paper elevation={6}>
                                                                <Card
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={(e) => { this.changeEageViewTable(eachItem.status) }}
                                                                >
                                                                    <CardContent
                                                                        style={{
                                                                            textAlign: "center",
                                                                            maxHeight: "135px"
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            mb={2}
                                                                            style={{
                                                                                fontSize: "12px",
                                                                                fontWeight: 600,
                                                                            }}
                                                                        >
                                                                            {eachItem.status}
                                                                        </Typography>
                                                                        <Grid container spacing={2} mb={2}>
                                                                            <Grid item xs={6}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontWeight: 600,
                                                                                        color: "#03039e9e",
                                                                                        fontSize: "14px",
                                                                                    }}
                                                                                >
                                                                                    {eachItem.ProjectCnt}
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontWeight: 300,
                                                                                    }}
                                                                                >
                                                                                    Project Count
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={6}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontWeight: 600,
                                                                                        color: "#ff4700c9",
                                                                                        fontSize: "14px",
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    ₹ {new Intl.NumberFormat("en-IN").format(eachItem.grandTotal)}
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontWeight: 300,
                                                                                    }}
                                                                                >
                                                                                    Grand Total
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </CardContent>
                                                                </Card>
                                                            </Paper>
                                                        </Grid>
                                                    ))}


                                                </Grid>

                                            </Grid>
                                            <Grid item md={12} style={{ marginTop: '3vh', marginBottom: '10vh' }}>
                                                <Paper elevation={6}>
                                                    <MUIDataTable
                                                        title={`Grand Total - ₹ ${new Intl.NumberFormat("en-IN").format(this.state.invoiceDetailsTotalEagle)}`}
                                                        data={this.state.rowDataDetails}
                                                        columns={this.state.columnsInvDetailsEagle}
                                                        options={options}
                                                    />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Grid mt={3}>

                                </Grid>
                            </div>
                        </Container>
                    </Dialog>


                    <Modal
                        open={this.state.openInvStatsItemDetails}
                        onClose={this.handleCloseInvStatsItemDetailsModal}
                        closeAfterTransition
                        style={{
                            left: "-72%",
                            width: "250%",
                        }}
                    >
                        <Fade in={this.state.openInvStatsItemDetails}>
                            <Box sx={style2}>
                                <Card style={{}} className="pan-image">
                                    <CardContent>
                                        <MUIDataTable
                                            title={"Item Details"}
                                            data={this.state.rowItemDetailData}
                                            columns={this.state.columnsInvItemDetails}
                                            options={options}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Fade>
                    </Modal>

                    <Modal
                        open={this.state.openDownloadFilesModal}
                        onClose={this.handleCloseAttachmentsModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style4}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Download Attachments
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <Grid container>
                                    {
                                        this.state.downloadFiles.map((e, key) => (
                                            <>
                                                {e.DocLink.split('.').pop().toLowerCase() != "pdf" && e.DocLink.split('.').pop().toLowerCase() != "xlsx" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid item m={1} md={3}>
                                                            <img className="hoverImage" style={{ width: '100%' }} onClick={(event) => { window.open(e.DocLink) }} src={e.DocLink} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.DocLink.split('.').pop().toLowerCase() == "pdf" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid className="margin0" item m={1} md={3}>
                                                            <img onClick={(event) => { window.open(e.DocLink) }} style={{ width: '100%' }} className="bms-view-request-img" src={pdfIcon} alt="No Image" />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.DocLink.split('.').pop().toLowerCase() == "xlsx" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid item m={1} className="margin0" md={3}>
                                                            <img className="bms-view-request-img" onClick={(event) => { window.open(e.DocLink) }} src={excelIcon} alt="No Image" key={key} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                            </>
                                        ))
                                    }

                                </Grid>


                            </Typography>
                        </Box>
                    </Modal>


                </ThemeProvider>
            </>
        );
    }
}
export default BillingDashboard;
