import React, {
  Component
  // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from "reactstrap";

// import {Link} from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import './layout.css';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Autocomplete, Toolbar, AppBar, Dialog, Slide, Tooltip, ListItemText, OutlinedInput, FormControlLabel, Backdrop, Fade, Modal, Chip, Stack, Box, Checkbox, IconButton, Grid, Container, Typography, Avatar, Badge, TextField, Card, CardMedia, Button, FormControl, MenuItem, InputLabel } from '@mui/material';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import SendIcon from '@mui/icons-material/Send';


import MUIDataTable from "mui-datatables";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const FileSaver = require('file-saver');
const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "406px",
    },
  },
};

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

const options = {
  filterType: 'multiselect',
  // filterOptions: {fullWidth: true},
  selectableRows: 'none',
  setRowProps: row => {
    if (row[0] === "New") {
      return {
        style: { background: "snow" }
      };
    }
  },
  draggableColumns: { enabled: true },
  pagination: true,
  // resizableColumns: true,
  // responsive: "standard",
  fixedHeader: false
};



class ManagerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      selectedDate: new Date(),
      unique_cities: [],
      locationId: "",
      unique_project: [],
      projectName: [],
      projectID: [],
      unique_reportTo: [],
      reportTo: [],
      unique_departments: [],
      department: '',
      unique_designations: [],
      designation: "",
      unique_status: [],
      status: '',
      useridDashboard: '',
      userType: '',
      unique_access: [],
      access: [],
      index: '',
      profile: '',
      fullName: '',
      fullNameValidate: false,
      mobile: '',
      mobileValidate: false,
      email: '',
      doj: today,
      employeeType: '',
      personName: [],
      employmentType: '',
      bankName: '',
      bankNameValidate: false,
      accountNo: '',
      accountNoValidate: false,
      confirmAccountNo: '',
      confirmAccountNoValidate: '',
      accountNoColor: 'primary',
      accFocused: false,
      ifsc: '',
      ifscValidate: false,
      panNo: '',
      panNoValidate: false,
      pancardImage: '',
      addPancardImage: [],
      open: false,
      dob: today,
      dobValidate: false,
      panImageText: 'Change',
      color: 'addColorBlue',
      addProfileAttachments: [],
      monthlyCTC: '',
      inHand: false,
      pfNo: '',
      uanNo: '',
      esicNo: '',
      bloodGroup: '',
      aadharFrontText: 'Change',
      aadharFrontcolor: 'addColorBlue',
      aadharFrontImage: '',
      aadharBackText: 'Change',
      aadharBackcolor: 'addColorBlue',
      aadharBackImage: '',
      modalImage: '',
      idCard: '',
      columns: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar onClick={(e) => { this.handleOpenModal(this.state.rowData[dataIndex].Picture, e) }} className="profilePic" src={this.state.rowData[dataIndex].Picture} style={{ float: "left" }} alt="E" sx={{ width: 38, height: 38 }} />
                    <div style={{ marginLeft: '51px', paddingTop: '8px', }}>
                      {this.state.rowData[dataIndex].FullName}
                    </div>
                  </div>

                </div>

              )
            }
          },

        },
        {
          name: "EmployeeID",
          label: "Employee ID",
          options: {
            filter: false,
            sort: true,
          }
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "WorkingBranch",
          label: "Working Branch",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "isOnroll",
          label: "Onrole",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              var newsss = this.state.rowData[dataIndex].isOnroll;
              var onRole = 'No';
              var colorNew = 'primary';
              if (newsss == "1") {
                colorNew = 'primary';
                onRole = 'Yes';
              }
              return (
                <Chip style={{ borderRadius: '4px', height: '25px' }} label={onRole} />
              )

            }
          }
        },
        {
          name: "DoB",
          label: "Date of Birth",
          options: {
            filter: false,
            sort: true,
          }
        },
        {
          name: "DoJ",
          label: "Date of Joining",
          options: {
            filter: false,
            sort: true,
          }
        },
        {
          name: "Status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusName = this.state.rowData[dataIndex].Status;
              var colorNew = '';
              if (statusName === "Verified") {
                return (
                  <Chip color="success"  style={{  backgroundColor: '#3c9b41',borderRadius: '4px', height: '25px' }} label={this.state.rowData[dataIndex].Status} />
                )
              }else if(statusName === "Profile Updated"){
                return (
                  <Chip color="primary"  style={{backgroundColor: "#ffa500",  borderRadius: '4px', height: '25px' }} label={this.state.rowData[dataIndex].Status} />
                )
              }else if(statusName === "Issued Offer letter"){
                return (
                  <Chip color="primary"  style={{backgroundColor: "#fff900",  borderRadius: '4px', height: '25px' }} label={this.state.rowData[dataIndex].Status} />
                )
              }else{
                return (
                  <Chip style={{  borderRadius: '4px', height: '25px', color : "black" }} label={this.state.rowData[dataIndex].Status} />
                )
              }
            }
          },
        },
        {
          name: "Projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div style={{ textAlign: 'center' }}>
                  {this.state.rowData[dataIndex].ProjectCnt}
                </div>
              )
            },
          }
        },
        {
          name: "Reportto",
          label: "Report To",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "IDCard",
          label: "Id Card",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <Tooltip title="View ID Card">
                    <Button
                      // component={RouterLink}
                      size="small"
                      onClick={(e) => this.handleOpenModal(this.state.rowData[dataIndex].IDCard)}

                      style={{ color: 'black', minWidth: '40px' }}
                    // variant="outlined"
                    >
                      <RemoveRedEyeIcon />

                    </Button>
                  </Tooltip>

                  <Tooltip title="Download ID Card">
                    <Button
                      // component={RouterLink}
                      size="small"
                      onClick={(e) => this.handleDownloadIdCard(this.state.rowData[dataIndex].IDCard, this.state.rowData[dataIndex].userid, this.state.rowData[dataIndex].FullName)}

                      style={{ color: 'black', minWidth: '40px' }}
                    // variant="outlined"
                    >
                      <GetAppIcon />

                    </Button>
                  </Tooltip>


                </div>

              )

            }
          }
        },
        {
          name: "userid",
          label: "View More",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <Tooltip title="View">
                  <Button
                    // component={RouterLink}
                    size="small"
                    // to={{
                    //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                    //   orders: this.state.orders
                    // }}
                    onClick={(e) => this.handleOpenFullModal(dataIndex, e)}

                    style={{ color: 'black' }}
                  // variant="outlined"
                  >
                    <ViewComfyIcon />

                  </Button>
                </Tooltip>
              )

            }
          }
        },
      ],
      unique_newRequests: [],
      rowData: [],
      openFullModal: false,
      Basic: '',
      HRA: '',
      CA: '',
      MA: '',
      LTA: '',
      SA: '',
      GE: '',
      CTC: '',
      NSP: '',
      statusText: '',
      submitModal: '',



    };
    this.validator = new SimpleReactValidator();
  }

  delay = async (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  getUniqueCities = async () => {
    await axios
      .get(
        `${Config.hostName}/unique/locations`
      )
      .then((res) => {
        if (res.data.data.length > 0) {
          this.setState({
            unique_cities: res.data.data,
          });
        } else {
          this.setState({
            unique_cities: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  departmentDropDown = async () => {
    await axios
      .get(
        `${Config.hostName}/ems/departmentDropDown`
      )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_departments: res.data.data,
          });
        } else {
          this.setState({
            unique_departments: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  designation = async () => {
    await axios
      .get(
        `${Config.hostName}/ems/designation`
      )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_designations: res.data.data,
          });
        } else {
          this.setState({
            unique_designations: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  accessDropDown = async () => {
    await axios
      .get(
        `${Config.hostName}/ems/accessDropDown`
      )
      .then(async (res) => {
        const newArray2 = res.data.data.map((it) => (
          {
            accessTypeid: it.accessTypeid,
            AccessType: it.AccessType,
            isChecked: false,
          }
        ));
        if (res.data.data.length > 0) {
          await this.setState({
            unique_access: newArray2,
          });

          await this.state.unique_access.filter(async (eachItem, key) => {
            if (eachItem.accessTypeid == 1 || eachItem.accessTypeid == 29 || eachItem.accessTypeid == 4 || eachItem.accessTypeid == 19||   eachItem.accessTypeid ==47) {
              let items = [...this.state.unique_access];
              let item = { ...items[key] };
              item.isChecked = true;
              items[key] = item;

              await this.setState({
                unique_access: items
              })
            }

          });


        } else {
          this.setState({
            unique_access: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  reportTODropDown = async () => {
    await axios
      .get(
        `${Config.hostName}/ems/reportTODropDown`
      )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_reportTo: res.data.data,
          });
        } else {
          this.setState({
            unique_reportTo: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  projectDropDown = async () => {
    const res = await axios.post(
      `${Config.hostName}/ems/projectDropDown`,
      {
        locationID: this.state.locationId
      }
    )
      .then(async (res) => {
        // console.log(res.data.data, 'project');
        if (res.data.data.length > 0) {
          await this.setState({
            unique_project: res.data.data,
          });
        } else {
          this.setState({
            unique_project: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  getUniqueStatus = async () => {
    const res = await axios.post(
      `${Config.hostName}/ems/statusDropDown`,
      {

      }
    )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_status: res.data.data,
          });
        } else {
          this.setState({
            unique_status: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  getNewRequests = async () => {
    const res = await axios.post(
      `${Config.hostName}/ems/managerGetEmployeeDetails`,
      {
        ManagerID: this.props.auth.user.USERID
      }
    )
      .then(async (res) => {
        console.log(res.data.data, 'project');
        if (res.data.data.length > 0) {
          await this.setState({
            rowData: res.data.data.map(eachItem => {
              return {
                AdharBack: eachItem.AdharBack,
                AdharFront: eachItem.AdharFront,
                BankDetails: eachItem.BankDetails,
                Department: eachItem.Department,
                Designation: eachItem.Designation,
                DoB: eachItem.DoB,
                DoJ: eachItem.DoJ,
                EmployeeID: eachItem.EmployeeID,
                FullName: eachItem.FullName,
                IDCard: eachItem.IDCard,
                Picture: eachItem.Picture,
                ProjectCnt: eachItem.ProjectCnt,
                Projects: eachItem.Projects,
                Reportto: eachItem.Reportto,
                SalaryDetails: eachItem.SalaryDetails,
                Status: eachItem.Status,
                WorkingBranch: eachItem.WorkingBranch,
                bloodGroup: eachItem.bloodGroup,
                isOnroll: eachItem.isOnroll,
                pan: eachItem.pan,
                userid: eachItem.userid,
                EmploymentType: eachItem.EmploymentType,
                email: eachItem.email,
                Mobile: eachItem.Mobile,
                Locationid: eachItem.Locationid,
                Access: eachItem.Access,
              }
            })
          });
        } else {
          alert('No Recoords Found... !!');
          await this.setState({
            rowData: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }


  async componentDidMount() {
    await this.setState({
      style: {}
    });
    await this.getNewRequests();
    this.reportTODropDown();
    this.departmentDropDown();
    this.designation();
    this.accessDropDown();
    this.getUniqueCities();
    this.getUniqueStatus();
    await this.setState({
      style: {
        display: "none"
      }
    });
  }

  handleAccessChange = async (event) => {
    console.log(event);
    if (event == 1 || event == 29 || event == 4 || event == 19||event == 47) {
      alert('This option is Mandatory !!');
    } else {
      await this.state.unique_access.filter(async (eachItem, key) => {
        if (eachItem.accessTypeid == event) {
          let items = [...this.state.unique_access];
          let item = { ...items[key] };
          item.isChecked = !eachItem.isChecked;
          items[key] = item;

          await this.setState({
            unique_access: items
          })
        }
      })
    }

    console.log(this.state.unique_access);

  };

  handleDownloadIdCard = async (url, userID, fullName) => {
    let extension = '';
    if (url) {
      extension = url;
      extension = extension.substr(extension.length - 3);
    }

    const response = await axios({
      method: 'GET',
      url: url,
      responseType: 'blob'
    });
    const pdfBlob = new Blob([response.data], { type: `image/${extension}` });
    const fileName = `${fullName}_${userID}.${extension}`;

    FileSaver.saveAs(pdfBlob, fileName);
  }


  getUrlToAttachments = async (url, name) => {
    const { addProfileAttachments } = this.state;
    addProfileAttachments.filter(addProfileAttachments => {
      if (addProfileAttachments.name == name) {
        return addProfileAttachments.AttachmentUrl = url
      }
    })
    await this.setState({
      addProfileAttachments: addProfileAttachments,
      profile: addProfileAttachments[0].AttachmentUrl
    })
    // console.log(this.state.addProfileAttachments);
  }

  uploadFileAndGetUrl = async (formData) => {
    // console.log("uploading formdata")
    const fileData = await axios({
      method: "POST",
      url: `${Config.hostName}/unique/upload/any-file`,
      timeout: 3 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*"
      },
      data: formData,
    });
    return fileData;
  }

  singleUploadFile = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    }
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    await this.getUrlToAttachments(urlFromAws, file.name);
    await this.setState({
      profile: urlFromAws
    });
    const { addProfileAttachments } = this.state;
    setTimeout(async () => {
      addProfileAttachments.filter(att => {
        if (att.name == file.name && att.isUploaded == false) {
          return att.isUploaded = true
        }
      })
      await this.setState({
        addProfileAttachments: addProfileAttachments,
      })
    }, 4000);
    // console.log(this.state.attachments, "data")
  }

  onFileUpload = async (event) => {

    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { addProfileAttachments } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      addProfileAttachments.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false
      })
    })
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    await this.setState({
      style: {}
    });
    await this.setState({
      addProfileAttachments: addProfileAttachments
    })
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.singleUploadFile(item);
    }
    await this.delay(2000);
    await this.setState({
      style: {
        display: "none"
      }
    });
  };


  getPanCardUrl = async (url, name) => {
    const { addPancardImage } = this.state;
    addPancardImage.filter(addPancardImage => {
      if (addPancardImage.name == name) {
        return addPancardImage.AttachmentUrl = url
      }
    })
    await this.setState({
      addPancardImage: addPancardImage,
      pancardImage: addPancardImage[0].AttachmentUrl
    })
  }

  pancardUploadFile = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    }
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    await this.getPanCardUrl(urlFromAws, file.name);
    await this.setState({
      pancardImage: urlFromAws
    })

    const { addPancardImage } = this.state;
    setTimeout(async () => {
      addPancardImage.filter(att => {
        if (att.name == file.name && att.isUploaded == false) {
          return att.isUploaded = true
        }
      })
      await this.setState({
        addPancardImage: addPancardImage,
      })
    }, 4000);
    // console.log(this.state.attachments, "data")
  }

  onPanUpload = async (event) => {

    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { addPancardImage } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      addPancardImage.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false
      })
    })
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    await this.setState({
      panImageText: 'Uploading..',
    });
    await this.setState({
      addPancardImage: addPancardImage
    })
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.pancardUploadFile(item);
    }
    await this.delay(2000);
    await this.setState({
      panImageText: 'Change',
      color: 'addColorBlue'
    });
  };


  // getPanCardUrl = async (url, name) => {
  //   const { addPancardImage } = this.state;
  //   addPancardImage.filter(addPancardImage => {
  //     if (addPancardImage.name == name) {
  //       return addPancardImage.AttachmentUrl = url
  //     }
  //   })
  //   await this.setState({
  //     addPancardImage: addPancardImage,
  //     pancardImage: addPancardImage[0].AttachmentUrl
  //   })
  // }

  aadharUploadFile = async (adharPos, file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    }
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    if (adharPos == 1) {
      await this.setState({
        aadharFrontImage: urlFromAws
      })
    } else {
      await this.setState({
        aadharBackImage: urlFromAws
      })
    }
  }


  onAadharUpload = async (res, event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);

    if (res == 1) {
      await this.setState({
        aadharFrontText: 'Uploading..',
      });
    } else {
      await this.setState({
        aadharBackText: 'Uploading..',
      });
    }


    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.aadharUploadFile(res, item);
    }
    await this.delay(1000);

    if (res == 1) {
      await this.setState({
        aadharFrontText: 'Change',
        aadharFrontcolor: 'addColorBlue'
      });
    } else {
      await this.setState({
        aadharBackText: 'Change',
        aadharBackcolor: 'addColorBlue'
      });
    }

  };

  handleLocationChange = async (e) => {
    e.preventDefault();
    await this.setState({
      locationId: e.target.value,
      projectID: [],
      projectName: [],
    });
    await this.projectDropDown();
  }


  handleDepartmentChange = async (e) => {
    e.preventDefault();
    await this.setState({
      department: e.target.value,
    });
  }

  handleStatusChange = async (e) => {
    e.preventDefault();
    let statusText = '';
    await this.state.unique_status.filter(item => {
      if (item.StatusID == e.target.value) {
        statusText = item.Status
      }
    })

    await this.setState({
      status: e.target.value,
      statusText: statusText
    });
  }

  handleReportToChange = async (e, newValue) => {
    const value = newValue;
    console.log(value);
    await this.setState({
      reportTo: typeof value === 'string' ? value.split(',') : value,
    })

  }

  handleProjectChange = async (e) => {
    const value = e.target.value;
    // console.log(data);
    await this.setState({
      projectName: typeof value === 'string' ? value.split(',') : value,
    })

  }

  handleReportTo = async (e) => {
    e.preventDefault();
    await this.setState({ reportTo: e.target.value, });
  }

  handleDesignationChange = async (e) => {
    e.preventDefault();
    await this.setState({ designation: e.target.value });
  }

  handleTypeChange = async (e) => {
    e.preventDefault();
    await this.setState({ employeeType: e.target.value, });
  }

  handleEmploymentChange = async (e) => {
    e.preventDefault();
    await this.setState({ employmentType: e.target.value, });
  }

  handleOpenModal = async (url, e) => {
    await this.setState({ modalImage: url });

    await this.setState({ open: true, });
  }

  handleCloseModal = async (e) => {
    await this.setState({ open: false, });
  }

  onSubmitModal = async (e) => {
    e.preventDefault();
    if (this.state.bankName == '') {
      alert('Bank Name Required..!!!');
      return;
    } else if (this.state.profile == '') {
      alert('Profile Pic Required..!!!');
      return;
    } else if (this.state.accountNo == '') {
      alert('Account NO. Required..!!!');
      return;
    } else if (this.state.accountNo != this.state.confirmAccountNo) {
      alert('Account NO. not matched..!!!');
      return;
    } else if (this.state.ifsc == '') {
      alert('IFSC code Required..!!!');
      return;
    } else if (this.state.fullName == '') {
      alert('Name cannot be empty..!!!');
      return;
    } else if (this.state.mobile == '') {
      alert('Mobile Number Required..!!!');
      return;
    }
    await this.setState({ submitModal: true, });
  }

  handleCloseSubmitModal = async (e) => {
    await this.setState({ submitModal: false, });
  }

  onSubmit = async e => {
    e.preventDefault();
    if (this.state.bankName == '') {
      alert('Bank Name Required..!!!');
      return;
    } else if (this.state.accountNo == '') {
      alert('Account NO. Required..!!!');
      return;
    } else if (this.state.accountNo != this.state.confirmAccountNo) {
      alert('Account NO. not matched..!!!');
      return;
    } else if (this.state.ifsc == '') {
      alert('IFSC code Required..!!!');
      return;
    } else if (this.state.fullName == '') {
      alert('Name cannot be empty..!!!');
      return;
    } else if (this.state.mobile == '') {
      alert('Mobile Number Required..!!!');
      return;
    }

    const designation = this.state.designation.split('&&');

    let access = [];
    await this.state.unique_access.map((item) => {
      if (item.isChecked == true) {
        access.push(item.accessTypeid);
      }
    })

    let inHand = 0;
    if (this.state.inHand) {
      inHand = 1;
    }
    await this.setState({
      style: {}
    });

    let reportToFinal = [];
    const testReportTo = await this.state.reportTo.map(async(filterReportTo)=>{
      const filterNew = filterReportTo;
      console.log(filterReportTo,'rohan');
      console.log(filterNew,'rohan');
      const reportTo = await this.state.unique_reportTo.filter(async(value) => {
        if(value.Firstname == filterNew){
          reportToFinal.push(value.UserID);
        }
      });
    })


    const projectCnt = this.state.projectName.length;

    const acc = `{"BeneficiaryName":"${this.state.bankName}","AccountNo":"${this.state.accountNo}","IFSC":"${this.state.ifsc}" }`;
    const jdata = `{"StatusID":"${this.state.status}","email":"${this.state.email}","EmploymentType":"${this.state.employmentType}","Department":"${this.state.department}","ProjectCnt":"${projectCnt}","DoB":"${this.state.dob}"}`;
    const SalaryDetails = `{"MonthlyCTC":"${this.state.monthlyCTC}","InHand":"${inHand}","PFno":"${this.state.pfNo}","UANno":"${this.state.uanNo}","ESICno":"${this.state.esicNo}","Basic":"${this.state.Basic}","HRA":"${this.state.HRA}","CA":"${this.state.CA}","MA":"${this.state.MA}","LTA":"${this.state.LTA}","SA":"${this.state.SA}","GE":"${this.state.GE}","CTC":"${this.state.CTC}","NSP":"${this.state.NSP}"}`;
    const res = await axios.post(
      `${Config.hostName}/ems/registerEmployee`,
      {
        locationid: this.state.locationId,
        name: this.state.fullName,
        mobile: this.state.mobile,
        access: access.toString(),
        designation: designation[0],
        startDate: this.state.doj,
        userType: designation[1],
        isattendance: "0",
        adharfront: this.state.aadharFrontImage,
        adharback: this.state.aadharBackImage,
        pan: this.state.pancardImage,
        isOnroll: this.state.employeeType,
        Projects: this.state.projectName.toString(),
        userID: this.state.useridDashboard,
        reportto: reportToFinal.toString(),
        bloodGroup: this.state.bloodGroup,
        BankAccountDeatils: acc,
        EmployeePicture: this.state.profile,
        jdata: jdata,
        SalaryDetails: SalaryDetails
      }
    )
      .then(async (res) => {
        await this.setState({
          style: {
            display: "none"
          }
        });
        if (res.data.success) {
          await this.getNewRequests();
          alert("Status Updated");
          await this.setState({
            openFullModal: false,
            submitModal: false,
          });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });


  };

  handleOpenFullModal = async (dataIndex, e) => {

    const data = this.state.rowData[dataIndex];
    const BankDetails = JSON.parse(this.state.rowData[dataIndex].BankDetails);
    const SalaryDetails = JSON.parse(this.state.rowData[dataIndex].SalaryDetails);

    let MonthlyCTC = '';
    let InHand = '';
    let PFno = '';
    let UANno = '';
    let ESICno = '';

    let BeneficiaryName = '';
    let AccountNo = '';
    let IFSC = '';
    if (SalaryDetails != null) {
      if (SalaryDetails.MonthlyCTC != null) {
        MonthlyCTC = SalaryDetails.MonthlyCTC;
      }

      if (SalaryDetails.InHand != null) {
        InHand = SalaryDetails.InHand;
      }

      if (SalaryDetails.PFno != null) {
        PFno = SalaryDetails.PFno;
      }

      if (SalaryDetails.UANno != null) {
        UANno = SalaryDetails.UANno;
      }

      if (SalaryDetails.ESICno != null) {
        ESICno = SalaryDetails.ESICno;
      }
    }

    if (BankDetails != null) {
      if (BankDetails.BeneficiaryName != null) {
        BeneficiaryName = BankDetails.BeneficiaryName;
      }

      if (BankDetails.AccountNo != null) {
        AccountNo = BankDetails.AccountNo;
      }

      if (BankDetails.IFSC != null) {
        IFSC = BankDetails.IFSC;
      }

    }







    let designation = '';
    let reportTo = [];
    let statusNew = '';

    await this.state.unique_designations.filter(item => {
      if (item.Designation == data.Designation) {
        designation = item.Designation + "&&" + item.userType;
      }
    })

    if (data.Reportto) {
      reportTo = data.Reportto.split(',');
    }

    // await this.state.unique_reportTo.filter(item => {
    //   if (item.Firstname === data.Reportto) {
    //     reportTo = item.UserID;
    //   }
    // })

    let statusText = '';
    await this.state.unique_status.filter(item => {
      if (item.Status === data.Status) {
        statusNew = item.StatusID;
        statusText = item.Status;
      }
    })
    let projectName = [];
    let access = [];

    if (data.Projects) {
      projectName = data.Projects.split(',');
    }
    if (data.Access) {
      access = data.Access.split(',');
    }
    // let accessNews = Object.values(access);

    await this.state.unique_access.filter(async (eachItem, key) => {
      let items = [...this.state.unique_access];
      let item = { ...items[key] };
      item.isChecked = true;
      if (access.includes(`${eachItem.accessTypeid}`)) {
        items[key] = item;
        await this.delay(500);
        await this.setState({
          unique_access: items
        })
      }
    })


    await this.setState({
      aadharBackImage: data.AdharBack,
      aadharFrontImage: data.AdharFront,
      fullName: data.FullName,
      mobile: data.Mobile,
      email: data.email,
      locationId: data.Locationid,
      designation: designation,
      doj: data.DoJ,
      dob: data.DoB,
      department: data.Department,
      isattendance: "0",
      pancardImage: data.pan,
      employeeType: data.isOnroll,
      employmentType: data.EmploymentType,
      monthlyCTC: MonthlyCTC,
      inHand: InHand,
      pfNo: PFno,
      uanNo: UANno,
      esicNo: ESICno,
      reportTo: reportTo,
      bloodGroup: data.bloodGroup,
      profile: data.Picture,
      index: dataIndex,
      idCard: data.IDCard,
      projectName: projectName,
      bankName: BeneficiaryName,
      accountNo: AccountNo,
      confirmAccountNo: AccountNo,
      ifsc: IFSC,
      openFullModal: true,
      accountNoColor: 'success',
      accFocused: true,
      status: statusNew,
      statusText: statusText,
      useridDashboard: data.userid,
      EmployeeID: data.EmployeeID,
    });

    await this.projectDropDown();
  };

  handleCloseFullModal = async (e) => {
    await this.setState({
      openFullModal: false,
    });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  render() {


    return (
      <div>

        <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '7%' }} >
          <div id='cover-spin' style={this.state.style}></div>

          <Grid pt={3} container>

            <Grid item md={3}>

            </Grid>
          </Grid>

          <Grid mt={3}>
            <Grid item md={12}>
              <MUIDataTable
                title={"Manager View"}
                data={this.state.rowData}
                columns={this.state.columns}
                options={options}
              />
            </Grid>
          </Grid>

        </Container>

        <Dialog fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseFullModal} open={this.state.openFullModal}
        >
          <Container maxWidth="xl" style={{ paddingLeft: '0px', paddingRight: '0px' }}>


            <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseFullModal}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Sound
                  </Typography> */}
                <Button autoFocus color="inherit" onClick={this.handleCloseFullModal}>
                  Close
                </Button>
              </Toolbar>
            </AppBar>



            {/* <Header order="rohan" /> */}
            <Grid style={{ background: '#e0e1e0', }} container>
              <Grid item md={9} style={{ padding: '0px 12px' }}>
                <form onSubmit={this.onSubmitModal} action='/' method="POST">
                  <Container pt={3} style={{ maxWidth: 'fit-content', background: 'white', paddingBottom: "12%", marginTop: '2%' }} >
                    <Typography pt={2} style={{ textAlign: 'center', color: '#333333' }} variant="h4"> <b><u> Employee Profile</u></b></Typography>


                    <Typography pt={2} style={{ display: 'inline', }} variant="h6"> <b> Personal Info</b></Typography>
                    <Typography pt={2} style={{ display: 'inline', float: 'right', color: '#333333' }} variant="h6">EmployeeID : <b style={{ color: 'red', }}>{this.state.EmployeeID}</b></Typography>

                    <Grid pt={1} container>
                      <Grid item md={4} sm={12}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <label htmlFor="icon-button-file">

                            <Input accept="image/*" id="icon-button-file" onChange={this.onFileUpload} type="file" />
                            <IconButton aria-label={100} component="span">
                              <Badge
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                badgeContent={'+'}
                                color="primary"
                                style={{ borderRadius: '0px' }}
                              >

                                {this.state.profile == '' ?
                                  <Avatar src={require('./user-icon2.png')} alt="E" sx={{ width: 100, height: 100 }} />
                                  :
                                  <Avatar src={this.state.profile} style={{ borderRadius: '0px' }} alt="E" sx={{ width: 100, height: 100 }} />
                                }
                              </Badge>
                            </IconButton>
                          </label>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <TextField id="fullName" label="Full Name" required variant="standard"
                          placeholder="As per on Aadhar Card"
                          focused
                          value={this.state.fullName}
                          onChange={(e) => {
                            if (e.target.value != '') {
                              this.setState({
                                fullName: e.target.value,
                                fullNameValidate: false,
                              });
                            } else {
                              this.setState({
                                fullNameValidate: true,
                              });
                            }

                          }}
                          error={this.state.fullNameValidate}
                          style={{ marginTop: '11%' }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField id="mobile" label="Mobile" required variant="standard"
                          type='number'
                          InputProps={{ inputProps: { min: 0 } }}
                          value={this.state.mobile}
                          onChange={(e) => {
                            if (e.target.value != '') {
                              this.setState({
                                mobile: e.target.value,
                                mobileValidate: false,
                              });
                            } else {
                              this.setState({
                                mobileValidate: true,
                              });
                            }

                          }}
                          error={this.state.mobileValidate}
                          style={{ marginTop: '11%' }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1 }} id="email" label="Email" required variant="standard"
                          type='email'
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value
                            })
                          }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1 }}
                          name="someDate"
                          variant="standard"
                          label="Date of Joining"
                          InputLabelProps={{ shrink: true, required: true }}
                          type="date"
                          defaultValue={this.state.doj}
                          onChange={(e) => {
                            if (e.target.value != '') {
                              this.setState({
                                doj: e.target.value,
                              });
                            } else {

                            }

                          }}
                        />

                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1 }}
                          name="dob"
                          variant="standard"
                          label="Date of Birth"
                          InputLabelProps={{ shrink: true, required: true }}
                          type="date"
                          defaultValue={this.state.dob}
                          onChange={(e) => {
                            if (e.target.value != '') {
                              this.setState({
                                dob: e.target.value,
                                dobValidate: false,
                              });
                            } else {
                              this.setState({
                                dobValidate: true,
                              });
                            }

                          }}
                        />

                      </Grid>



                      <Grid item md={3}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginLeft: '0px' }}>
                          <InputLabel id="Employment-Type">Employment Type</InputLabel>
                          <Select
                            labelId="Employment-Type"
                            id="Employment2"
                            value={this.state.employmentType}
                            onChange={this.handleEmploymentChange}
                            label="Employment Type"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value='Full Time'>Full Time</MenuItem>
                            <MenuItem value='Probation'>Probation</MenuItem>
                            <MenuItem value='OffRole'>OffRole</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginLeft: '0px' }}>
                          <InputLabel id="department">Department</InputLabel>
                          <Select
                            labelId="department"
                            id="department2"
                            value={this.state.department}
                            onChange={this.handleDepartmentChange}
                            label="Department"
                            required
                          >
                            <MenuItem value="">
                              <em>Select Department</em>
                            </MenuItem>

                            {this.state.unique_departments.map((eachItem, key) => (
                              <MenuItem value={eachItem.Department} key={key} >{eachItem.Department}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginLeft: '0px' }}>
                          <InputLabel id="designation">Designation</InputLabel>
                          <Select
                            labelId="designation"
                            id="designation2"
                            value={this.state.designation}
                            onChange={this.handleDesignationChange}
                            label="Designation"
                            required
                          >
                            <MenuItem value="">
                              <em>Select Designation</em>
                            </MenuItem>

                            {this.state.unique_designations.map((eachItem, key) => (
                              <MenuItem value={eachItem.Designation + "&&" + eachItem.userType} key={key} >{eachItem.Designation}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>



                    


                      <Grid item md={3}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginLeft: '0px' }}>
                          <InputLabel id="employeetype">Employee Type</InputLabel>
                          <Select
                            labelId="employeetype"
                            id="employeeType"
                            value={this.state.employeeType}
                            onChange={this.handleTypeChange}
                            label="Employee Type"
                          >
                            <MenuItem value="0">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value='1'>OnRole</MenuItem>
                            <MenuItem value='0'>OffRole</MenuItem>

                          </Select>
                        </FormControl>
                      </Grid>


                      <Grid item md={3}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginLeft: '0px' }}>

                          <InputLabel id="bloodGroup">Blood Group</InputLabel>
                          <Select
                            labelId="bloodGroup"
                            id="bloodGroup2"
                            value={this.state.bloodGroup}
                            onChange={(e) => {
                              this.setState({
                                bloodGroup: e.target.value
                              })
                            }}
                            label="Blood Group"
                            required
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="A+">A-positive (A+)</MenuItem>
                            <MenuItem value="A-">A-negative (A-)</MenuItem>
                            <MenuItem value="B+">B-positive (B+)</MenuItem>
                            <MenuItem value="B-">B-negative (B-)</MenuItem>
                            <MenuItem value="AB+">AB-positive (AB+)</MenuItem>
                            <MenuItem value="AB-">AB-negative (AB-)</MenuItem>
                            <MenuItem value="O+">O-positive (O+)</MenuItem>
                            <MenuItem value="O-">O-negative (O-)</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>

                        <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginLeft: '0px' }}>
                          <InputLabel id="location">Work Location</InputLabel>
                          <Select
                            labelId="location"
                            id="location2"
                            value={this.state.locationId}
                            onChange={this.handleLocationChange}
                            label="Work Location"
                            required
                          >
                            <MenuItem value="">
                              <em>Select Location</em>
                            </MenuItem>

                            {this.state.unique_cities.map((eachItem, key) => (
                              <MenuItem value={eachItem.Locationid} key={key} >{eachItem.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>


                      <Grid item md={5}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginLeft: '0px', display: 'flex', }}>
                          <InputLabel id="demo-simple-select-standard-label">Project</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            multiple
                            value={this.state.projectName}
                            onChange={(e) => { this.handleProjectChange(e) }}
                            // input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            label="Project"
                          >
                            {this.state.unique_project.map((name, key) => (
                              <MenuItem key={key} value={name.projectName}>
                                <Checkbox checked={this.state.projectName.indexOf(name.projectName) > -1} />
                                <ListItemText primary={name.projectName} />
                              </MenuItem>
                            ))}

                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={4}>
                      </Grid>

                      <Grid item md={5}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginLeft: '0px', display: 'flex', }}>

                          {/* <InputLabel id="reportTo">Report To</InputLabel>
                          <Select
                            labelId="reportTo"
                            id="unique_reportTo2"
                            value={this.state.reportTo}
                            onChange={this.handleReportTo}
                            label="Report To"
                          >
                            <MenuItem value="">
                              <em>Select Report To</em>
                            </MenuItem>

                            {this.state.unique_reportTo.map((eachItem, key) => (
                              <MenuItem value={eachItem.UserID} key={key} >{eachItem.Firstname}</MenuItem>
                            ))}
                          </Select> */}

<Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    value={this.state.reportTo}
                    options={this.state.unique_reportTo.map((option) => option.Firstname)}
                    disableCloseOnSelect
                    onChange={(e, newValue) => { this.handleReportToChange(e, newValue) }}
                    renderOption={(props, options, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {options}
                      </li>
                    )}
                    required
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField {...params} label="Report To" variant="standard" placeholder="Select Report To..." />
                    )}
                  />
                        </FormControl>
                      </Grid>

                      <Grid pt={4} item md={12}>
                        <Typography pt={1} pb={2} variant="h6"><b>Projects</b></Typography>

                        <Stack direction="row" style={{ display: 'block' }} spacing={1}>
                          {this.state.projectName.map((eachItem, key) => {
                            return <>
                              <Chip key={eachItem} color='primary' label={eachItem} style={{ borderRadius: '0px', margin: '10px' }} />
                            </>
                          }
                          )}

                        </Stack>

                      </Grid>

                      <Grid pt={4} item md={12}>
                        <Typography pt={1} pb={2} variant="h6"><b>Access</b></Typography>

                        <Stack direction="row" style={{ display: 'block' }} spacing={1}>
                          {this.state.unique_access.map((eachItem, key) => {
                            return eachItem.isChecked == false ?
                              <Chip key={eachItem.accessTypeid} label={eachItem.AccessType} onClick={(e) => { this.handleAccessChange(eachItem.accessTypeid) }} style={{ borderRadius: '0px', margin: '10px' }} />
                              :
                              <Chip key={eachItem.accessTypeid} color='primary' label={eachItem.AccessType} onClick={(e) => { this.handleAccessChange(eachItem.accessTypeid) }} style={{ borderRadius: '0px', margin: '10px' }} />
                          }
                          )}

                        </Stack>

                      </Grid>

                    </Grid>


                  </Container>
                </form>
              </Grid>
              <Grid item md={3} style={{ paddingRight: "8px", paddingTop: "18px" }}>
                {/* <Grid container style={{ background: '#e0e1e0', }}> */}
                <Grid item md={12}>
                  <Card >
                    <Typography style={{ marginLeft: "12px", marginTop: '3px' }} variant="h6"> Pan :</Typography>
                    <div style={{ padding: '4px 22px', marginBottom: '14px' }}>
                      <CardMedia
                        component="img"
                        className="pan-image"
                        height="194"
                        image={this.state.pancardImage}
                        onClick={(e) => { this.handleOpenModal(this.state.pancardImage, e) }}
                        alt="Pan"
                        style={{ border: '1px solid black', objectFit: 'contain' }}
                      />
                    </div>
                  </Card>
                  <Card style={{ marginTop: '12px' }}>
                    <Typography style={{ marginLeft: "12px", marginTop: '3px' }} variant="h6"> Aadhar Front :</Typography>
                    <div style={{ padding: '4px 22px', marginBottom: '14px' }}>
                      <CardMedia
                        component="img"
                        className="pan-image"

                        height="194"
                        image={this.state.aadharFrontImage}

                        onClick={(e) => { this.handleOpenModal(this.state.aadharFrontImage, e) }}

                        alt="Aadhar Front"
                        style={{ border: '1px solid black', objectFit: 'contain' }}
                      />
                    </div>
                  </Card>
                  <Card style={{ marginTop: '12px' }}>
                    <Typography style={{ marginLeft: "12px", marginTop: '3px' }} variant="h6"> Aadhar Back :</Typography>
                    <div style={{ padding: '4px 22px', marginBottom: '14px' }}>
                      <CardMedia
                        component="img"
                        className="pan-image"

                        height="194"
                        image={this.state.aadharBackImage}

                        onClick={(e) => { this.handleOpenModal(this.state.aadharBackImage, e) }}

                        alt="Aadhar Back"
                        style={{ border: '1px solid black', objectFit: 'contain' }}
                      />
                    </div>
                  </Card>
                  <Card style={{ marginTop: '12px' }}>
                    <Typography style={{ marginLeft: "12px", marginTop: '3px' }} variant="h6"> ID Card :</Typography>
                    <div style={{ padding: '4px 22px', marginBottom: '14px' }}>
                      <CardMedia
                        component="img"
                        className="pan-image"

                        height="194"
                        image={this.state.idCard}

                        onClick={(e) => { this.handleOpenModal(this.state.idCard, e) }}

                        alt="ID Card"
                        style={{ border: '1px solid black', objectFit: 'contain' }}
                      />
                    </div>
                  </Card>
                </Grid>
                {/* </Grid> */}
              </Grid>
            </Grid>


          </Container>

        </Dialog>



        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Box sx={style2}>
              <Card style={{}} className="pan-image">
                {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}

                <CardMedia
                  component="img"
                  height='auto'
                  image={this.state.modalImage}
                  alt="Error"
                />

              </Card>

            </Box>
          </Fade>
        </Modal>

        <Modal
          open={this.state.submitModal}
          onClose={this.handleCloseSubmitModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Status To: <b>{this.state.statusText}</b>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            </Typography>
            <Button
              style={{ float: 'right', background: "#fb5f79", marginLeft: "3%", color: "white" }}
              onClick={this.onSubmit}
              size="large"
              variant="contained"
            >
              <span> Confirm</span>
            </Button>
          </Box>
        </Modal>

      </div>


    );
  }
}

ManagerView.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(ManagerView);
