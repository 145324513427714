/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import axios from "axios";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap';
import SimpleReactValidator from "simple-react-validator";
import Config from "../../../../config"
import SweetAlert from 'react-bootstrap-sweetalert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RoomIcon from '@material-ui/icons/Room';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import "../../../../assets/customCssFiles/loader-spinner.css"

class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: [],
            inilat: "",
            inilng: "",
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            unique_routes: [],
            unique_dates: [],
            selected_date: "",
            selected_route: "",
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            alert_message: "",
            style: {},
            isOpen: false,
            modal: false,
            nestedModal: false,
            closeAll: false,
            pincode: undefined,
            index: undefined,
            tracking_num: "",
            isSelectRouteOpen: true,
            markerLat: null,
            markerLng: null,
            tracking_number: "",
            showno: false,
            detailsData: [],
            isClicked: false,
            clickedNumber: ""

        };
        this.validator = new SimpleReactValidator();
    }

    onMarkerDragEnd = async (coord, index, location) => {
        // console.log(index, location.pincode, location.trackingNumber);
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        await this.setState({
            tracking_number: location.trackingNumber,
            pincode: location.pincode,
            index: index,
            markerLat: lat,
            markerLng: lng
        })

        await this.setState(prevState => {
            const mapData = [...this.state.mapData];
            mapData[index] = { ...mapData[index], lat: lat, long: lng };
            return { mapData };
        });
        this.toggle()
    };

    handleRouteChange = async () => {
        // console.log(this.state.selected_route, "this.state.selected_route");

        let route = (this.state.selected_route == 0) ? "" : this.state.selected_route
        let result;
        result = await axios.post(`${Config.hostName}/shipment/get/all/data/map-view/new/${this.props.auth.user.CCID}`, {
            date: this.state.selected_date,
            trackingId: this.state.tracking_num,
            routeCode: route

        })
        // console.log(result);

        await this.setState({
            mapData: result.data.data,
            detailsData: result.data.data,
            inilat: result.data.data[0] && result.data.data[0].latitude,
            inilng: result.data.data[0] && result.data.data[0].longitude
        });
    };

    handleChangeShipment = async () => {
        await this.setState({
            style: {}
        })
        await this.handleRouteChange();
        await this.setState({
            style: { display: "none" }
        })
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    onMapClicked = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    };

    changePincodeForSelectedMarker = async () => {
        let { tracking_number, markerLat, markerLng } = this.state;
        try {
            // eslint-disable-next-line
            const res = await axios.post(`${Config.hostName}/shipment/update/coordinates/${this.props.auth.user.CCID}`, {
                lat: markerLat,
                long: markerLng,
                pincode: "",
                trackingNum: tracking_number
            }).then(async (response) => {
                // console.log(response.data, "response");

                if (response.data.success === false) {
                    this.setState({ show: true, modal: !this.state.modal, basicType: 'danger', basicTitle: 'Success Alert', alert_message: "Error Occurred!" })
                }
                else {
                    if (this.state.isClicked) {
                        await this.handleChangeShipment()

                        await this.onClickingTrackNoMethod(this.state.clickedNumber)

                    }
                    else {
                        await this.handleChangeShipment()
                    }
                    await this.setState({ show: true, modal: !this.state.modal, basicType: 'success', basicTitle: 'Success Alert', alert_message: "Updated!" })

                }

            }
            ).catch((error) => {
                console.log(error, 'error');

            }
            )
        } catch (error) {
            console.log("----");
            console.log(error);
            console.log("----");
            this.setState({ show: true, basicType: 'danger', basicTitle: 'Success Alert', alert_message: "Error Occurred!" })
        }
    }

    toggleNested = () => {
        this.setState({
            nestedModal: !this.state.nestedModal,
            closeAll: false
        })
    }

    toggleAll = () => {
        this.setState({
            nestedModal: !this.state.nestedModal,
            closeAll: true
        })
    }

    toggleUpdatePincode = async () => {
        let pin = this.state.pincode;
        let index = this.state.index;
        let number = this.state.tracking_number;
        if (this.validator.allValid()) {
            try {
                // eslint-disable-next-line
                const res = await axios.post(
                    `${Config.hostName}/shipment/update/coordinates/${this.props.auth.user.CCID}`,
                    {
                        pincode: this.state.pincode,
                        lat: this.state.markerLat,
                        long: this.state.markerLng,
                        trackingNum: number


                    }
                ).then(async (response) => {
                    // console.log(response.data, "response");
                    if (response.data.success === false) {
                        this.props.setAlert(response.data.message, "danger");
                    }
                    else {
                        if (this.state.isClicked) {
                            await this.setState({ selected_route: "" })
                            await this.handleChangeShipment()
                            await this.state.mapData.filter(async data => {
                                if (data.trackingNumber === number) {
                                    await this.setState({
                                        selected_route: data.routeCode
                                    })
                                }
                            })
                            await this.handleChangeShipment()
                            await this.onClickingTrackNoMethod(this.state.clickedNumber)
                        }

                        else {
                            await this.setState({ selected_route: "" })
                            await this.handleChangeShipment()
                            await this.state.mapData.filter(async data => {
                                if (data.trackingNumber === number) {
                                    await this.setState({
                                        selected_route: data.routeCode
                                    })
                                }
                            })
                            await this.handleChangeShipment()
                        }
                        await this.setState({
                            show: true, basicType: 'success', basicTitle: 'Success Alert', alert_message: "Updated!",
                        })
                        await this.toggleAll();
                    }
                }).catch((error) => {
                    console.log(error, 'error');
                })
            } catch (error) {
                const errors = error.response.data.errors;
                if (errors) {
                    errors.forEach(error => this.props.setAlert(error.msg, "danger"));
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    }

    getData = async () => {

        await this.handleRouteChange(this.state.selected_route);

        await axios.get(`${Config.hostName}/shipment/unique/date/${this.props.auth.user.CCID}`)
            .then(async (res) => {
                // console.log(res.data);

                if (res.data.data.length > 0) {
                    await this.setState({ unique_dates: res.data.data })
                }
                else {
                    await this.setState({ unique_dates: [] })

                }
            })
            .catch((error) => {
                console.log(error)
            });

        await axios.get(`${Config.hostName}/shipment/unique/route/${this.props.auth.user.CCID}`)
            .then(async (res) => {
                // console.log(res);

                if (res.data.data.length > 0) {
                    await this.setState({ unique_routes: res.data.data, style: { display: 'none' } })
                }
                else {
                    await this.setState({ unique_routes: [], style: { display: 'none' } })

                }
                // this.setState({ unique_routes: res.data, style: { display: 'none' } })
            })
            .catch((error) => {
                console.log(error)
            });
    }

    async componentDidMount() {
        await this.getData();
    }

    onSubmit = async e => {
        e.preventDefault();
        try {
            // eslint-disable-next-line
            const res = await axios.post(`${Config.hostName}/shipment/update`, {
                shipments: this.state.mapData
            }).then(async (response) => {
                // console.log(response.data, "response");
                if (response.data.success === false) {
                    this.setState({ show: true, basicType: 'danger', basicTitle: 'Success Alert', alert_message: "Error Occurred!" })
                }
                else {
                    await this.setState({ show: true, basicType: 'success', basicTitle: 'Success Alert', alert_message: "Updated!" })
                }

            }
            ).catch((error) => {
                console.log(error, 'error');

            }
            )
            // await axios.post(`${Config.hostName}/shipment/update/route/changing-pincode`)
            // await this.setState({ show: true, basicType: 'success', basicTitle: 'Success Alert', alert_message: "Updated!" })
            // this.props.setAlert("Query Added", "success");
        } catch (error) {
            console.log("----");
            console.log(error);
            console.log("----");
            this.setState({ show: true, basicType: 'danger', basicTitle: 'Success Alert', alert_message: "Error Occurred!" })
        }
    };

    onClickingTrackNo = async (e) => {
        e.preventDefault();
        let num = e.target.value;
        await this.setState({
            isClicked: true,
            clickedNumber: num
        })
        await this.onClickingTrackNoMethod(num)
    }


    onClickingTrackNoMethod = async (num) => {
        let data = [];
        await this.state.detailsData.filter(loc => {
            if (loc.trackingNumber == num) {
                return data.push(loc);
            }
        })
        await this.setState({
            mapData: data
        })
    }

    render() {
        let { style, modal, closeAll, nestedModal } = this.state;
        return (
            <div style={{ height: "60%" }}>

                <div id='cover-spin' style={style}>
                </div>

                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                    {this.state.alert_message}
                </SweetAlert>

                <div className="card" >
                    <div className="row" style={{ backgroundColor: "#1C6EA4" }}>
                        <div style={{ display: "flex", width: "100%" }}>

                            <div style={{ flex: 1, width: "100%", marginLeft: "3%", marginTop: "2%" }} >
                                <FormGroup>
                                    <Input
                                        type="select"
                                        name="selected_date"
                                        value={this.state.selected_date}
                                        id="selected_date"
                                        onChange={(e) => {
                                            this.setState({ selected_date: e.target.value })
                                        }}
                                    >
                                        <option value={""} disabled>Select Date</option>
                                        {this.state.unique_dates.map(date => (
                                            <option key={date.Loaddate} value={date.Loaddate}>{date.Loaddate}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>

                            <div style={{ flex: 1, width: "100%", marginLeft: "3%", marginTop: "2%" }}>
                                <FormGroup>
                                    <Input
                                        type="number"
                                        placeholder="Tracking No"
                                        name="tracking_num"
                                        value={this.state.tracking_num}
                                        id="tracking_num"
                                        onChange={(e) => {
                                            this.setState({
                                                tracking_num: e.target.value
                                            })
                                        }}
                                    >
                                    </Input>
                                </FormGroup>
                            </div>

                            <div style={{ flex: 1, width: "100%", marginLeft: "3%", marginTop: "2%" }} >
                                <FormGroup>
                                    <Input
                                        type="select"
                                        name="selected_route"
                                        value={this.state.selected_route}
                                        id="selected_route"
                                        onChange={async e =>
                                            await this.setState({
                                                selected_route: e.target.value
                                            })
                                        }
                                    >
                                        <option value={""} disabled>Select Route</option>
                                        {this.state.unique_routes.map(route => (
                                            route.route &&
                                            <option key={route.route} value={route.route}>{route.route}</option>
                                        ))}
                                        <option value={0}>All Routes</option>
                                    </Input>
                                </FormGroup>
                            </div>

                            <div className="col-sm-2" style={{ flex: 1, width: "100%", marginLeft: "3%", marginTop: "2%" }}>
                                <button
                                    type="submit"
                                    // className="btn btn-secondary"
                                    className="btn btn-success"
                                    onClick={this.handleChangeShipment}
                                >
                                    Search
                      </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card" >
                    <div className="row" style={{ marginTop: "0px", height: "100%" }}>
                        <div style={{ display: "flex", width: "100%", height: "100%" }}>
                            <div style={{ flex: 1, overflowY: "scroll", border: "0.5px outset black", height: "75vh" }}>
                                <Card >
                                    <div style={{}}>
                                        {this.state.detailsData && this.state.detailsData.map(no => {
                                            return (
                                                <div
                                                    style={{ position: "sticky" }}
                                                    key={no.id}
                                                >
                                                    <div
                                                        style={{ backgroundColor: "#fdf656", color: "#2E86C1", overflow: "auto", display: 'flex' }}
                                                    >
                                                        <button
                                                            value={no.trackingNumber}
                                                            className="btn"
                                                            style={{ width: "50%", backgroundColor: "#fdf656", color: "#1C6EA4" }}
                                                            onClick={(e) => this.onClickingTrackNo(e)}
                                                        >

                                                            {no.trackingNumber}&nbsp;
                                                            {no.Iscorrected ?
                                                                <CheckCircleIcon htmlColor="#1C6EA4" /> : <span>{""}</span>
                                                            }
                                                            &nbsp; <span style={{ fontSize: "0.70rem" }}>{no.routeCode}</span>

                                                        </button>

                                                    </div>
                                                    <p><strong>Address : </strong>{no.address} <br />{no.Iscorrected && <span><strong>Updated Pincode:</strong>{no.pincode}</span>}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Card>
                            </div>
                            <div style={{ flex: 4, height: "75vh", border: "0.5px outset black", borderLeft: "0px" }}>
                                <div className="row " style={{ width: "100%", height: "80vh" }}>
                                    <div className="col-xl-12" style={{ width: "100%", height: "80vh" }}>
                                        <Map
                                            google={this.props.google}
                                            style={{
                                                width: "100%",
                                                height: "80vh",
                                                position: "fixed"
                                            }}
                                            className={"map"}
                                            initialCenter={{
                                                lat: this.state.inilat,
                                                lng: this.state.inilng
                                            }}
                                            center={{
                                                lat: this.state.inilat,
                                                lng: this.state.inilng
                                            }}
                                            zoom={4}
                                            onClick={this.onMapClicked}
                                        >
                                            {this.state.mapData.map((location, index) => {
                                                let markerColor = (location.Iscorrected == 1) ? "blue" : "red"

                                                return (
                                                    <Marker
                                                        key={location.id}
                                                        // icon=<RoomIcon />

                                                        icon={`http://maps.google.com/mapfiles/ms/icons/${markerColor}-dot.png`}
                                                        title={
                                                            `Tracking Number : ${location.trackingNumber},\nAddress : ${location.address},\n Pincode:${location.pincode}`
                                                        }
                                                        position={{
                                                            lat: location.latitude,
                                                            lng: location.longitude
                                                        }}
                                                        draggable={true}
                                                        onDragend={(t, map, coord) =>
                                                            this.onMarkerDragEnd(coord, index, location)
                                                        }
                                                        labelOrigin={{ x: 10, y: 0 }}
                                                        name={
                                                            <div>
                                                                <p>
                                                                    Tracking Number :{" "}
                                                                    {location.trackingNumber}
                                                                    <br />
                                                                    Address : {location.address}
                                                                    <br />
                                                                    Pincode:{location.pincode}
                                                                </p>
                                                            </div>
                                                        }
                                                        // onMouseover={this.onMarkerClick}
                                                        onClick={this.onMarkerClick}
                                                    />
                                                )
                                            })}
                                            <InfoWindow
                                                onMouseover={this.onMarkerClick}
                                                onMouseout={this.onMapClicked}
                                                marker={this.state.activeMarker}
                                                visible={this.state.showingInfoWindow}
                                            >
                                                <div>
                                                    <p
                                                        style={{
                                                            width: "40vh",
                                                            fontSize: "0.4rem",
                                                            height: "70%"
                                                        }}
                                                    >
                                                        <strong>{this.state.selectedPlace.name}</strong>
                                                    </p>
                                                </div>
                                            </InfoWindow>
                                        </Map>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Modal isOpen={modal} toggle={this.toggle} >
                        <ModalHeader toggle={this.toggle}>Change Pincode</ModalHeader>
                        <ModalBody>
                            Do you want to change the pincode ?
                                                <Modal isOpen={nestedModal} toggle={this.toggleNested} onClosed={closeAll ? this.toggle : undefined}>
                                <ModalHeader>Postal Form</ModalHeader>
                                <ModalBody>
                                    <div className="form-group">
                                        <label className="form-label">Pincode</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="pincode"
                                            name="pincode"
                                            value={this.state.pincode}
                                            onChange={async event =>
                                                await this.setState({
                                                    pincode: event.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </ModalBody>

                                <ModalFooter>
                                    <Button color="primary" onClick={this.toggleNested}>Cancel & Go Back</Button>{' '}
                                    <Button color="secondary" onClick={this.toggleUpdatePincode}>Change & Update Location</Button>
                                </ModalFooter>
                            </Modal>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.toggleNested}>Yes</Button>{' '}
                            <Button color="secondary" onClick={this.changePincodeForSelectedMarker}>No, Update Location</Button>
                        </ModalFooter>
                    </Modal>

                </div>

            </div>
        );
    }
}

const MapComponent = GoogleApiWrapper({
    apiKey: String(Config.apiKey),
    v: "3"
})(MapContainer);

// export default 


MapContainer.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(MapComponent);
