/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "axios";
import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import { Button, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/loader-spinner.css"


class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            locations: [],
            style: {},
            anotherModal: false,
            unique_route: [],
            rePincode: null,
            columnDefs: [
                {
                    headerName: "Pincode", field: "Pincode", sortable: true, filter: true
                },
                {
                    headerName: "Route Name", field: "RouteName", sortable: true, filter: true
                },
                {
                    headerName: "Route No", field: "RouteNo", sortable: true, filter: true
                },
                {
                    headerName: "Area", field: "Area", sortable: true, filter: true
                },
                {
                    headerName: "Edit", field: "Edit", editable: true,
                    cellRendererFramework: (params) => {
                        return <Link to={"/admin/route-mapping/update/" + params.node.data.ID}>
                            <Button
                                color="primary"
                                onClick={() =>
                                    this.setState({
                                        modal: !this.state.modal
                                    })
                                }
                            >
                                Change
        </Button>
                        </Link>
                    },
                }
            ],
            rowData: [],
            activePage: 1,
            itemsCountPerPage: 25,
            totalItemsCount: 1000,
            pageRangeDisplayed: 5,
            page: 1,
            search: false
        };
    }


    handlePageChange = async (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        await this.setState({ activePage: pageNumber, style: {} });
        if (this.state.search === false)
            await this.componentWillMount();
        else
            await this.handleSearchShipment();
    }


    componentWillMount = async () => {
        await this.setState({ search: false })

        let { activePage } = this.state;

        let result = await axios.get(`${Config.hostName}/route-map/get/all/${activePage}/${this.props.auth.user.CCID}`);
        console.log(result.data);

        await this.setState({
            locations: result.data.data,
            style: { display: 'none' }
        });
        await this.setState({
            rowData: this.state.locations.map(eachItem => {
                return {
                    ID: eachItem.id,
                    Pincode: eachItem.pincode,
                    RouteName: eachItem.routeName,
                    RouteNo: eachItem.routeNo,
                    Area: eachItem.area
                }
            })
        });
    }

    handleSearchShipment = async () => {
        let { activePage } = this.state;

        let pin = this.state.rePincode;
        console.log(pin);

        if (pin.length > 1) {

            try {
                await this.setState({ search: true, style: {} })

                let result = await axios.get(`${Config.hostName}/route-map/get/pincode/${this.state.rePincode}/${activePage}/${this.props.auth.user.CCID}`)
                console.log(result.data);

                await this.setState({
                    locations: result.data.data,
                    style: { display: "none" }
                });
                await this.setState({
                    rowData: this.state.locations.map(eachItem => {
                        return {
                            ID: eachItem.id,
                            Pincode: eachItem.pincode,
                            RouteName: eachItem.routeName,
                            RouteNo: eachItem.routeNo,
                            Area: eachItem.area,
                        }
                    })
                });
            } catch (e) {
                console.log(e);
            }
        }
        else {
            this.componentWillMount()
        }
    }


    render() {
        let style = this.state.style;

        const defaultColDef = {
            flex: 1,
            minWidth: 90,
            resizable: true
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id='cover-spin' style={style}>
                </div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-3">
                                            <FormGroup >
                                                <Input
                                                    type="number"
                                                    name="pincode"
                                                    id="pincode"
                                                    placeholder="Enter Pincode"
                                                    value={this.state.rePincode}
                                                    onChange={(e) => {
                                                        this.setState({ rePincode: e.target.value });

                                                    }}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                onClick={this.handleSearchShipment}
                                                // className="btn btn-primary"
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"

                                            >
                                                Search Routes
                                            </button>
                                        </div>

                                        <div className="col-sm-2">

                                            <Link to={"/admin/route-mapping/add-new"}>
                                                <button
                                                    type="submit"
                                                    onClick={() =>
                                                        this.setState({
                                                            anotherModal: !this.state.anotherModal
                                                        })
                                                    }
                                                    // className="btn btn-primary" >
                                                    className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                >
                                                    Add New
                                            </button>
                                            </Link>
                                        </div>

                                        {/* <div className="col-sm-3">
                                            {this.state.locations.length > 0 && (
                                                <CSVLink data={this.state.locations}>
                                                    <Button>Export to CSV</Button>
                                                </CSVLink>
                                            )}
                                        </div> */}

                                        <div className="col-sm-2 ml-auto">
                                            {this.state.locations.length > 0 && (
                                                <CSVLink data={this.state.locations}>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                                                    // className="btn btn-primary"

                                                    >Export
                      </button>
                                                </CSVLink>
                                            )}

                                        </div>

                                    </div>

                                </nav>

                                <div className="card-body">

                                    <div
                                        className="ag-theme-balham"
                                        style={{
                                            height: '90vh',
                                            width: '100%'
                                        }}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={this.state.rowData}
                                            defaultColDef={defaultColDef}
                                            rowHeight={40}

                                        >
                                        </AgGridReact>
                                    </div>
                                    <div style={{ margin: "5vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                                            onChange={this.handlePageChange.bind(this)}
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
