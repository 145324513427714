import React, { Component } from "react";

import CountUp from "react-countup";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import "../../../../assets/customCssFiles/tmshomepage.css";
import "react-datepicker/dist/react-datepicker.css";
import totalVendorsBgImg from "../../../../assets/customImages/Total_customers.png";
import totalVehiclesBgImg from "../../../../assets/customImages/Total_Orders.png";
import salesReportsBgImg from "../../../../assets/customImages/Sales_Reports.png";

class GeneralWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      modal: false,
      anotherModal: false,
      locations: [],
      selected_date: "",
      style: {},
      userCcId: null,
      ccid: "",
      unique_cities: [],
      totalTrips: [],
      totalVendors: [],
      totalVehicles: [],
      statusReport: [],
      startDate: "",
      endDate: "",
      isData: false,
    };
  }

  setTimer = async () => {

    if (this.props.data.isData) {
      await this.setState({
        totalTrips: this.props.data.totalTrips[0]
          ? this.props.data.totalTrips[0]
          : 0,
        totalVendors:
          this.props.data.totalVendors[0].length > 0
            ? this.props.data.totalVendors[0]
            : [],
        totalVehicles:
          this.props.data.totalVehicles[0].length > 0
            ? this.props.data.totalVehicles[0]
            : [],
        statusReport: 0,
        startDate: "",
        endDate: "",
        isData: true,
      });
    }
  };

  timerFunction = () => {
    setTimeout(async () => {
      await this.setTimer();
    }, 5000);
  };

  componentWillMount = async () => {
    await this.setState({
      locations: this.props.data,
    });
    await this.setTimer();
  };

  cardImageStyle = {
    width: "100px",
    height: "100px",
    position: "absolute",
    top: "-20px",
    left: "10px",
    right: "0",
    borderRadius: "0.25rem",
    boxShadow: "0px 0px 3px 0px #888888",
  };

  tmsCardTextStyle = {
    fontSize: "18px",
    height: "auto",
    // marginTop: "65px",
    //marginLeft: "80px",
    // color: "#666666",
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-sm-6 col-xl-3 col-lg-3 mt-5"
              style={{ paddingLeft: "7px", paddingRight: "7px" }}
            >
              <div className="card" style={{ borderRadius: "0.5rem" }}>
                <img
                  src={totalVendorsBgImg}
                  style={this.cardImageStyle}
                  alt="total customers"
                />

                <div
                  className="b-r-4 card-body"
                  style={{
                    minHeight: "250px",
                  }}
                >
                  <div className="media static-top-widget m-1">
                    <div className="align-self-center text-center"></div>
                    <div
                      className="media-body pl-0"
                      style={this.tmsCardTextStyle}
                    >
                      <div
                        className="text-center"
                        style={{ marginTop: "58px" }}
                      >
                        <span
                          className="my-0 font-weight-bold"
                          style={{
                            color: "#808080",
                            fontSize: "1.3rem",
                            // marginLeft: "100px",
                          }}
                        >
                          Total Trips
                        </span>
                        <h4
                          className="counter mt-0"
                          style={{
                            color: "#4d4d4d",
                            fontSize: "1.6rem",
                            marginBottom: "15px",
                          }}
                        >
                          <CountUp
                            end={
                              this.state.isData && this.state.totalTrips
                                ? this.state.totalTrips[0].totalTrips
                                : 0
                            }
                          />
                        </h4>
                      </div>
                      <div
                        className="row text-center tms-cards-count-div"
                        style={{ fontFamily: "Arial", marginTop: "0.5rem" }}
                      >
                        {this.state.totalTrips.map((eachTrip) => {
                          return (
                            <div className="col">
                              <span
                                className="m-0 font-weight-bold"
                                style={{ color: "#808080", fontSize: "1.2rem" }}
                              >
                                {eachTrip.serviceType}
                              </span>
                              <h4
                                className="counter mt-0"
                                style={{
                                  color: "#4d4d4d",
                                  fontSize: "1.6rem",
                                  marginBottom: "10px",
                                }}
                              >
                                <CountUp end={eachTrip.stripCount} />
                              </h4>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-sm-6 col-xl-3 col-lg-3 mt-5"
              style={{ paddingLeft: "7px", paddingRight: "7px" }}
            >
              <div className="card" style={{ borderRadius: "0.5rem" }}>
                <img src={totalVehiclesBgImg} style={this.cardImageStyle} alt="totalVehicles" />
                <div
                  className="b-r-4 card-body"
                  style={{
                    minHeight: "250px",
                    paddingLeft: "13.5px",
                    paddingRight: "13.5px",
                  }}
                >
                  <div className="media static-top-widget m-1">
                    <div className="align-self-center text-center">
                      {/* <i className="icon-package"></i> */}
                    </div>
                    <div
                      className="media-body text-center pl-0"
                      style={this.tmsCardTextStyle}
                    >
                      <div
                        className="text-center"
                        style={{ marginTop: "58px" }}
                      >
                        <span
                          className="m-0 font-weight-bold"
                          style={{ color: "#808080", fontSize: "1.3rem" }}
                        >
                          Total Vendors
                        </span>
                        <h4
                          className="counter mt-0"
                          style={{
                            color: "#4d4d4d",
                            fontSize: "1.6rem",
                            marginBottom: "15px",
                          }}
                        >
                          <CountUp
                            end={
                              this.state.isData &&
                                this.state.totalVendors[0].totalVndCount
                                ? this.state.totalVendors[0].totalVndCount
                                : 0
                            }
                          />
                        </h4>
                      </div>
                      {/* <div
                        className="row text-center tms-cards-count-div"
                        style={{
                          fontFamily: "Arial",
                          paddingRight: "14px",
                          paddingLeft: "14px",
                          marginTop: "0.5rem",
                        }}
                      >
                        {this.state.totalVendors.map((vendor) => {
                          return (
                            <div
                              className="col"
                              style={{
                                paddingLeft: "6px",
                                paddingRight: "6px",
                              }}
                            >
                              <span
                                className="m-0 font-weight-bold"
                                style={{ color: "#808080", fontSize: "1.2rem" }}
                              >
                                {vendor.city}
                              </span>
                              <h4
                                className="counter mt-0"
                                style={{
                                  color: "#4d4d4d",
                                  fontSize: "1.6rem",
                                  marginBottom: "10px",
                                }}
                              >
                                <CountUp end={vendor.cityCount} />
                              </h4>
                            </div>
                          );
                        })}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className=" col-sm-6 col-xl-6 col-lg-6 mt-5"
              style={{ paddingLeft: "7px", paddingRight: "7px" }}
            >
              <div className="card" style={{ borderRadius: "0.5rem" }}>
                <img src={salesReportsBgImg} style={this.cardImageStyle} alt="salesReport" />

                <div className="b-r-4 card-body" style={{ minHeight: "250px" }}>
                  <div className="media static-top-widget m-1">
                    <div className="align-self-center text-center"></div>
                    <div
                      className="media-body text-center pl-0"
                      style={this.tmsCardTextStyle}
                    >
                      <div
                        className="text-center"
                        id="total-vehicles-heading-div"
                        style={{ marginTop: "-9px" }}
                      // style={{
                      //   marginLeft: "92px",
                      //   marginTop: "10px",
                      //   marginBottom: "10px",
                      // }}
                      >
                        <span
                          className="m-0 font-weight-bold"
                          style={{ color: "#808080", fontSize: "1.3rem" }}
                        >
                          Total Vehicles
                        </span>
                        {/* <span
                          style={{
                            marginLeft: "5px",
                            fontSize: "1.6rem",
                            color: "#4d4d4d",
                            fontFamily: "Open Sans",
                          }}
                        >
                          <CountUp
                            end={
                              this.state.isData &&
                              this.state.totalVehicles[0].totalVehCount
                                ? this.state.totalVehicles[0].totalVehCount
                                : 0
                            }
                          />
                        </span> */}

                        <h4
                          className="counter mt-0"
                          style={{
                            color: "#4d4d4d",
                            fontSize: "1.6rem",
                            marginBottom: "14px",
                          }}
                        >
                          <CountUp
                            end={
                              this.state.isData &&
                                this.state.totalVehicles[0].totalVehCount
                                ? this.state.totalVehicles[0].totalVehCount
                                : 0
                            }
                          />
                        </h4>
                      </div>
                      {/* <div
                        className="row text-center"
                        style={{ fontFamily: "Arial", marginTop: "0.5rem" }}
                      >
                        {this.state.totalVehicles.map((eachVehicle) => {
                          return (
                            <div
                              className="col tms-db-total-vehicles-count"
                              style={{
                                paddingRight: "10px",
                                paddingLeft: "10px",
                              }}
                            >
                              <span
                                className="m-0 font-weight-bold"
                                style={{ color: "#808080", fontSize: "1.2rem" }}
                              >
                                {eachVehicle.VehicleSize}
                              </span>
                              <h4
                                className="counter mt-0"
                                style={{
                                  color: "#4d4d4d",
                                  fontSize: "1.6rem",
                                  marginBottom: "10px",
                                }}
                              >
                                <CountUp end={eachVehicle.vehicleCount} />
                              </h4>
                            </div>
                          );
                        })}
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="crm-overall">

                                        <div className="flot-chart-container-small donut-chart">
                                            <DonutChart className="flot-chart-placeholder"
                                                height={483}
                                                data={[{
                                                    value: 30,
                                                    label: "USA"
                                                },
                                                {
                                                    value: 50,
                                                    label: "India"
                                                },
                                                {
                                                    value: 10,
                                                    label: "Canada"
                                                },
                                                {
                                                    value: 10,
                                                    label: "UK"
                                                }]}
                                                colors={["#26C6DA", "#AB8DE4", "#FF5370", "#4099FF"]} />
                                        </div>
                                    </div>
                               */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GeneralWidget.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(GeneralWidget);

// export default GeneralWidget;
