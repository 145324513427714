import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
// import { Table, Button, FormGroup, Form, Label, Col } from "reactstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../../common/Alert";
import { setAlert } from "../../../../../redux/actions/alert.actions";
import Config from "../../../../../config";
import "../../../../../assets/customCssFiles/loader-spinner.css"

class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: "",
            style: {},

            loading: false,
            C_FixedCost: undefined,
            C_FixedCostPerDay: "",
            C_FixedKms: "",
            C_ExtraHrCost: "",
            C_ExtraKmsCost: "",
            C_FixedTons: "",
            C_ExtraTonCost: "",
            C_HaltingCharges: "",
            C_Toll: "",
            C_TotalAmt: "",
            V_FixedCost: "",
            V_FixedCostPerDay: "",
            V_FixedKms: "",
            V_ExtraHrCost: "",
            V_ExtraKmsCost: "",
            V_FixedTons: "",
            V_ExtraTonCost: "",
            V_HaltingCharges: "",
            V_Toll: "",
            V_TotalAmt: "",
            TripID: ""
        };
        this.validator = new SimpleReactValidator();

    }

    async componentWillMount() {
        let id = Number(this.props.id)
        let result = await axios.get(
            `${Config.hostName}/tms/get/details/pricing/${id}`
        );
        console.log(result.data);
        if (result.data.data.length > 0) {

            let data = result.data.data[0];

            await this.setState({
                style: { display: 'none' },

                TripID: id,
                C_FixedCost: data.C_FixedCost,
                C_FixedCostPerDay: data.C_FixedCostPerDay,
                C_FixedKms: data.C_FixedKms,
                C_ExtraHrCost: data.C_ExtraHrCost,
                C_ExtraKmsCost: data.C_ExtraKmsCost,
                C_FixedTons: data.C_FixedTons,
                C_ExtraTonCost: data.C_ExtraTonCost,
                C_HaltingCharges: data.C_HaltingCharges,
                C_Toll: data.C_Toll,
                C_TotalAmt: data.C_TotalAmt,
                V_FixedCost: data.V_FixedCost,
                V_FixedCostPerDay: data.V_FixedCostPerDay,
                V_FixedKms: data.V_FixedKms,
                V_ExtraHrCost: data.V_ExtraHrCost,
                V_ExtraKmsCost: data.V_ExtraKmsCost,
                V_FixedTons: data.V_FixedTons,
                V_ExtraTonCost: data.V_ExtraTonCost,
                V_HaltingCharges: data.V_HaltingCharges,
                V_Toll: data.V_Toll,
                V_TotalAmt: data.V_TotalAmt


            });
        }
        else {
            await this.setState({
                style: { display: 'none' },
            })
            alert("No Trip Found")
        }

    }

    onSubmit = async e => {
        e.preventDefault();

        if (this.validator.allValid()) {
            try {
                // eslint-disable-next-line
                await this.setState({ loading: true });

                await axios.post(`${Config.hostName}/tms/update/trip/trip-pricing/${this.state.TripID}`, {
                    cFixedCost: this.state.C_FixedCost,
                    cFixedCostPerDay: this.state.C_FixedCostPerDay,
                    cFixedKms: this.state.C_FixedKms,
                    cExtraHrCost: this.state.C_ExtraHrCost,
                    cExtraKmsCost: this.state.C_ExtraKmsCost,
                    cFixedTons: this.state.C_FixedTons,
                    cExtraTonCost: this.state.C_ExtraTonCost,
                    cHaltingCharges: this.state.C_HaltingCharges,
                    cToll: this.state.C_Toll,
                    cTotalAmt: this.state.C_TotalAmt,
                    vFixedCost: this.state.V_FixedCost,
                    vFixedCostPerDay: this.state.V_FixedCostPerDay,
                    vFixedKms: this.state.V_FixedKms,
                    vExtraHrCost: this.state.V_ExtraHrCost,
                    vExtraKmsCost: this.state.V_ExtraKmsCost,
                    vFixedTons: this.state.V_FixedTons,
                    vExtraTonCost: this.state.V_ExtraTonCost,
                    vHaltingCharges: this.state.V_HaltingCharges,
                    vToll: this.state.V_Toll,
                    vTotalAmt: this.state.V_TotalAmt
                })
                    .then(async (response) => {
                        // console.log(response.data, "response");
                        if (response.data.success === false) {
                            await this.setState({ loading: false });

                            this.props.setAlert(response.data.message, "danger");
                        }
                        else {
                            await this.setState({ loading: false });

                            this.props.setAlert("Pricing Updated", "success");
                            await this.componentWillMount();

                        }

                    }
                    ).catch((error) => {
                        console.log(error, 'error');

                    }
                    )
            }
            catch (error) {
                const errors = error.response.data.errors;
                if (errors) {
                    errors.forEach(error => this.props.setAlert(error.msg, "danger"));
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };
    render() {
        let { loading, style } = this.state;

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;

        return (
            <div>
                <div id='cover-spin' style={style}></div>
                {/*Container-fluid starts*/}
                {/*Container-fluid Ends*/}

                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <div className="card">

                                    <div className="card-body">

                                        <form>
                                            <div
                                                class="col-lg-12 text-right"
                                            // style={{ paddingTop: "10vh" }}
                                            ></div>
                                            <div className="col-lg-12">
                                                <div className="grid">
                                                    <div className="grid-body">
                                                        <div className="item-wrapper">
                                                            <div className="row mb-3">
                                                                <div className="col-md-12 mx-auto">
                                                                    <div className="mb-3">
                                                                        <button type="button" className="btn badge-info" disabled>
                                                                            Trip ID : {this.state.TripID} <span className="badge badge-pill badge-light mr-0">

                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="form-row" >
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className=" showcase_text_area">
                                                                                <p className="h5 txt-primary" style={{ marginLeft: "3vh" }}>Client&nbsp;<span>Pricing</span></p>

                                                                                {/* <h5>Client&nbsp;Pricing</h5> */}
                                                                                {/* <label> */}
                                                                                {/* <span className="">Client Pricing</span> */}
                                                                                {/* </label> */}
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className=" showcase_text_area">
                                                                                <p className="h5 txt-primary " style={{ marginLeft: "3vh" }}>Vendor&nbsp;<span>Pricing</span></p>

                                                                                {/* <h5>Vendor&nbsp;Pricing</h5> */}
                                                                                {/* <label>Vendor Pricing</label> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Fixed&nbsp;Cost</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Fixed Cost"
                                                                                    formControlName="C_FixedCost"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_FixedCost}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_FixedCost: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Fixed&nbsp;Cost</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Fixed Cost"
                                                                                    formControlName="V_FixedCost"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_FixedCost}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_FixedCost: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Fixed&nbsp;Cost/Day</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Fixed Cost Per Day"
                                                                                    formControlName="C_FixedCostPerDay"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_FixedCostPerDay}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_FixedCostPerDay: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Fixed&nbsp;Cost/Day</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Fixed CostPerDay"
                                                                                    formControlName="V_FixedCostPerDay"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_FixedCostPerDay}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_FixedCostPerDay: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Fixed&nbsp;Kms</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Fixed Kms"
                                                                                    formControlName="C_FixedKms"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_FixedKms}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_FixedKms: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Fixed&nbsp;Kms</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Fixed Kms"
                                                                                    formControlName="V_FixedKms"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_FixedKms}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_FixedKms: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Extra&nbsp;Kms&nbsp;Cost</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Extra Kms Cost"
                                                                                    formControlName="C_ExtraKmsCost"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_ExtraKmsCost}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_ExtraKmsCost: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Extra&nbsp;Kms&nbsp;Cost</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Extra Cost"
                                                                                    formControlName="V_ExtraKmsCost"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_ExtraKmsCost}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_ExtraKmsCost: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Extra&nbsp;Hr&nbsp;Cost</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Extra Hr Cost"
                                                                                    formControlName="C_ExtraHrCost"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_ExtraHrCost}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_ExtraHrCost: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Extra&nbsp;Hr&nbsp;Cost</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Extra Hr Cost"
                                                                                    formControlName="V_ExtraHrCost"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_ExtraHrCost}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_ExtraHrCost: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Fixed&nbsp;Tons</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Fixed Tons"
                                                                                    formControlName="C_FixedTons"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_FixedTons}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_FixedTons: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Fixed&nbsp;Tons</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Fixed Tons"
                                                                                    formControlName="V_FixedTons"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_FixedTons}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_FixedTons: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Extra&nbsp;Ton&nbsp;Cost</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Extra Ton Cost"
                                                                                    formControlName="C_ExtraTonCost"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_ExtraTonCost}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_ExtraTonCost: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Extra&nbsp;Ton&nbsp;Cost</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Extra TOn Cost"
                                                                                    formControlName="V_ExtraTonCost"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_ExtraTonCost}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_ExtraTonCost: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Halting&nbsp;Charges</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Halting Charges"
                                                                                    formControlName="C_HaltingCharges"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_HaltingCharges}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_HaltingCharges: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Halting&nbsp;Charges</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Halting Charges"
                                                                                    formControlName="V_HaltingCharges"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_HaltingCharges}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_HaltingCharges: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Toll&nbsp;Charges</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_Toll Charges"
                                                                                    formControlName="C_Toll"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_Toll}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_Toll: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Toll&nbsp;Charges</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_Toll Charges"
                                                                                    formControlName="V_Toll"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_Toll}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_Toll: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Total&nbsp;Amount</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter C_TotalAmt"
                                                                                    formControlName="C_TotalAmt"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.C_TotalAmt}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            C_TotalAmt: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-6 row showcase_row_area">
                                                                            <div className="col-md-4 showcase_text_area">
                                                                                <label>Total&nbsp;Amount</label>
                                                                            </div>
                                                                            <div className="col-md-6 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter V_TotalAmt"
                                                                                    formControlName="V_TotalAmt"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.V_TotalAmt}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            V_TotalAmt: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <button
                                                                        type="submit"
                                                                        class="btn btn-outline-primary btn-lg"
                                                                        style={{ float: "right" }}
                                                                        onClick={e => this.onSubmit(e)}
                                                                        disabled={loading}

                                                                    >
                                                                        {loading && (
                                                                            <i
                                                                                className="fa fa-refresh fa-spin"
                                                                                style={{ marginRight: "5px" }}
                                                                            />
                                                                        )}
                                                                        {loading && <span>Processing ...</span>}
                                                                        {!loading && <span>Update</span>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}




NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
