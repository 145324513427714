import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config"
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from "moment";
import "../../../../assets/customCssFiles/loader-spinner.css"

class PostalCodeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            location: "",
            postal: "",
            city: "",
            customer_address_line: "",
            customer_name: "",
            estimate_arrival_date: "",
            schedule_time: "",
            height: "",
            length: "",
            width: "",
            phone: "",
            weight: "",
            startTime: "",
            endTime: "",
            trackingNum: "",
            no: "",
            state: "",
            orderNo: ""
        };
        this.validator = new SimpleReactValidator();
    }

    async componentWillMount() {
        var result = await axios.get(
            `${Config.hostName}/shipment/get/table-view/one/${this.props.match.params.id}/${this.props.auth.user.CCID}`
        );
        // console.log(moment(result.data.data[0].schedule_time.substring(0, 11)).format("DD/MM/YYYY"));
        // console.log(result.data);
        let data = result.data.data[0]

        await this.setState({
            location: data,
            trackingNum: data.tracking_num,
            postal: data.postal,
            city: data.city,
            customer_address_line: data.customer_address_line,
            customer_name: data.customer_name,
            estimate_arrival_date: data.estimate_arrival_date,
            schedule_time: moment(data.schedule_time.substring(0, 11)).format("YYYY-MM-DD"),
            height: data.height,
            length: data.length,
            width: data.width,
            phone: data.phone,
            weight: data.weight,
            startTime: data.schedule_time.substring(12, 17),
            endTime: data.schedule_time.substring(20, 25),
            state: data.state,
            no: data.no,
            orderNo: data.Orderno,
            style: { display: 'none' }
        });
    }

    onSubmit = async e => {
        e.preventDefault();
        // console.log(this.state.schedule_time, "time");

        if (this.validator.allValid()) {
            try {
                const a = moment(this.state.schedule_time).format("DD-MMM-YYYY") + " ";
                const b = this.state.startTime + " - "
                // eslint-disable-next-line
                const res = await axios.post(
                    `${Config.hostName}/shipment/update/data/` + this.state.trackingNum,
                    {

                        no: this.state.no,
                        estimateArrivalDate: this.state.estimate_arrival_date,
                        scheduleTime: a.concat(b, this.state.endTime),
                        city: this.state.city,
                        customerAddressLine: this.state.customer_address_line,
                        postal: this.state.postal,
                        customerName: this.state.customer_name,
                        phone: this.state.phone,
                        state: this.state.state,
                        length: this.state.length,
                        width: this.state.width,
                        height: this.state.height,
                        weight: this.state.weight,

                    }
                );
                // await axios.get(`${Config.hostName}/shipment/route-update-by-postal`);
                await this.props.setAlert("Shipment updated", "success");
                await this.props.history.push("/admin/shipments/table-view");
            } catch (error) {
                const errors = error.response.data.errors;
                if (errors) {
                    errors.forEach(error => this.props.setAlert(error.msg, "danger"));
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        // let hrefLink = '/#';
        let style = this.state.style;
        // const backgroundHome = require('../../assets/images/landing/home/main_bg.jpg');

        return (
            <div style={{ height: "100vh" }}>
                <div id='cover-spin' style={style}>
                </div>
                <Breadcrumb title="Postal Form" parent="Table View" />
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="row">
                                <Alert />
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            {/* <h5>Edit Form</h5> */}
                                            {
                                                (this.props.auth.user.user_type === "ROAdmin" ||
                                                    this.props.auth.user.user_type === "ROSuperAdmin") ?
                                                    <p>Order No :
                                               &nbsp; <strong>{this.state.location.Orderno}</strong>
                                                    </p> :
                                                    <p>Tracking No :
                                               &nbsp; <strong>{this.state.location.tracking_num}</strong>
                                                    </p>}
                                        </div>

                                        <div className="card-body">

                                            <div className="form-row">

                                                <div className="form-group col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>City</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Contact No"
                                                            formControlName="city"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.city}
                                                            onChange={event => {
                                                                this.setState({
                                                                    city: event.target.value
                                                                });
                                                            }}

                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>State</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter State"
                                                            formControlName="state"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.state}
                                                            onChange={event => {
                                                                this.setState({
                                                                    state: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>PinCode</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Mobile No"
                                                            formControlName="postal"
                                                            type="number"
                                                            required
                                                            className="form-control"
                                                            value={this.state.postal}
                                                            onChange={event => {
                                                                this.setState({
                                                                    postal: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-row">

                                                <div className="form-group col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Customer Name</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Aadhar Number"
                                                            formControlName="customer_name"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.customer_name}
                                                            onChange={event => {
                                                                this.setState({
                                                                    customer_name: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* </div> */}


                                                {/* <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Customer Address</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Alternate Contact No"
                                                            formControlName="customer_address_line"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.customer_address_line}
                                                            onChange={event => {
                                                                this.setState({
                                                                    customer_address_line: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div> */}

                                                {/* <div className="form-row"> */}

                                                <div className="form-group col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Customer Number</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Benificary Name"
                                                            formControlName="phone"
                                                            type="number"
                                                            className="form-control"
                                                            value={this.state.phone}
                                                            onChange={event => {
                                                                this.setState({
                                                                    phone: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Arrival Date</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter PAN Number"
                                                            formControlName="estimate_arrival_date"
                                                            type="date"
                                                            className="form-control"
                                                            value={this.state.estimate_arrival_date}
                                                            onChange={event => {
                                                                this.setState({
                                                                    estimate_arrival_date: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Length</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Bank Name"
                                                            formControlName="length"
                                                            type="number"
                                                            className="form-control"
                                                            value={this.state.length}
                                                            onChange={event => {
                                                                this.setState({
                                                                    length: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Width</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Account No"
                                                            formControlName="width"
                                                            type="number"
                                                            className="form-control"
                                                            value={this.state.width}
                                                            onChange={event => {
                                                                this.setState({
                                                                    width: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-4  row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Height</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter IFSC Code"
                                                            formControlName="height"
                                                            type="number"
                                                            className="form-control"
                                                            value={this.state.height}
                                                            onChange={event => {
                                                                this.setState({
                                                                    height: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="form-row">

                                                <div className="form-group col-md-4 col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Scheduled Date</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Comments"
                                                            formControlName="schedule_time"
                                                            // type="datetime-local"
                                                            type="date"
                                                            className="form-control"
                                                            value={this.state.schedule_time}
                                                            onChange={event => {
                                                                // console.log(event.target.value);

                                                                this.setState({
                                                                    schedule_time: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="form-group col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>Start Time</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Comments"
                                                            formControlName="startTime"
                                                            // type="datetime-local"
                                                            type="time"
                                                            className="form-control"
                                                            value={this.state.startTime}
                                                            onChange={event => {
                                                                // console.log(event.target.value);

                                                                this.setState({
                                                                    startTime: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="form-group col-md-4 row showcase_row_area">
                                                    <div className="col-md-3 showcase_text_area">
                                                        <label>End Time</label>
                                                    </div>
                                                    <div className="col-md-9 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Comments"
                                                            formControlName="endTime"
                                                            // type="datetime-local"
                                                            type="time"
                                                            className="form-control"
                                                            value={this.state.endTime}
                                                            onChange={event => {
                                                                // console.log(event.target.value);

                                                                this.setState({
                                                                    endTime: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                            </div>

                                            <AvForm>
                                                <div className="col-md-12">
                                                    <AvField
                                                        name="CustomerAddress"
                                                        label="Customer Address"
                                                        value={this.state.customer_address_line}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                customer_address_line: e.target.value
                                                            })
                                                        }}
                                                        type="textarea"
                                                        placeholder="customer_address_line"
                                                    />
                                                </div>
                                            </AvForm>


                                        </div>

                                        <div className="card-footer" >
                                            <Button
                                                style={{ float: "right" }}
                                                onClick={e => this.onSubmit(e)}
                                                color='primary'
                                            >Update
                                             </Button>{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PostalCodeForm.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(PostalCodeForm);
