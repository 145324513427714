import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
// import { Table, Button, FormGroup, Form, Label, Col } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../../common/Alert";
import { setAlert } from "../../../../../redux/actions/alert.actions";
import Config from "../../../../../config";
// import moment from 'moment';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import IconButton from '@material-ui/core/IconButton';
// import InfoIcon from '@material-ui/icons/Info';
import '../TripMaster.css';
import "../../../../../assets/customCssFiles/loader-spinner.css"


class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Tripid: Number(this.props.id),
            AdvPay: "",
            AdvPaymentDate: new Date(),
            ODOStart: "",
            ODOEnd: "",
            ODOStarturl: "",
            ODOEndurl: "",
            Remarks: "",
            IsVerified: false,
            style: {},
            loading: false
        }
        this.validator = new SimpleReactValidator();

    }

    componentWillMount = async () => {
        let id = this.state.Tripid
        let result = await axios.get(
            `${Config.hostName}/tms/get/details/info/${id}`
        );
        if (result.data.data.length > 0) {

            // console.log(result.data[0]);
            const data = result.data.data[0];

            await this.setState({
                style: { display: 'none' },
                ODOStart: data.ODOStart,
                ODOEnd: data.ODOEnd,
                ODOStarturl: data.ODOStarturl,
                ODOEndurl: data.ODOEndurl,
                IsVerified: data.IsVerified
            });
            // console.log(this.state.IsVerified);
        }
        else {
            await this.setState({
                style: { display: 'none' },
            })
            alert("No Trip Found")
        }

    }

    onSubmit = async e => {
        e.preventDefault();


        if (this.validator.allValid()) {
            try {
                // eslint-disable-next-line
                await this.setState({ loading: true });

                await axios.post(`${Config.hostName}/tms/trip-master/verify`, {

                    tripId: this.state.Tripid,
                    user: this.props.auth.user.name,
                    isVerified: 1,
                    comments: null,
                    advPay: null,
                    advPayDate: null

                })
                    .then(async (response) => {
                        // console.log(response.data, "response");
                        if (response.data.success === false) {
                            await this.setState({ loading: false });

                            this.props.setAlert(response.data.message, "danger");
                        }
                        else {
                            await this.setState({ loading: false });

                            this.props.setAlert("Trip Approved", "success");
                            await this.componentWillMount();

                        }

                    }
                    ).catch((error) => {
                        console.log(error, 'error');

                    }
                    )
            }
            catch (error) {
                const errors = error.response.data.errors;
                if (errors) {
                    errors.forEach(error => this.props.setAlert(error.msg, "danger"));
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };


    render() {
        let { loading, style, IsVerified } = this.state;


        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;

        return (
            <div>
                <div id='cover-spin' style={style}></div>
                {/*Container-fluid starts*/}
                {/*Container-fluid Ends*/}

                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <div className="card">

                                    <div className="card-body">

                                        <form>
                                            <div
                                                class="col-lg-12 text-right"
                                            ></div>
                                            <div class="col-lg-12">
                                                <div class="grid">
                                                    <div class="grid-body">
                                                        <div class="item-wrapper">
                                                            <div class="row mb-3">
                                                                <div class="col-md-12 mx-auto">
                                                                    <div className="form-row">
                                                                        <div className="col-md-4">
                                                                            <button type="button"
                                                                                className="btn badge-info"
                                                                                disabled>
                                                                                Trip ID : {this.state.Tripid} <span className="badge badge-pill badge-light mr-0">

                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <button type="button" className={IsVerified ? 'btn badge-success' : 'btn badge-danger'}
                                                                                disabled>

                                                                                {IsVerified ? 'Approved' : 'Not Approved'}
                                                                                <span className="badge badge-pill badge-light mr-0">

                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>


                                                                    <div class="form-row" style={{ marginTop: "5vh" }}>
                                                                        <div class="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                            <div class="col-md-4 showcase_text_area">
                                                                                <label>Start&nbsp;ODO</label>
                                                                            </div>
                                                                            <div class="col-md-8 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter ODO Start"
                                                                                    formControlName="ODOStart"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.ODOStart}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            ODOStart: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group col-md-6 row showcase_row_area">
                                                                            <div class="col-md-4 showcase_text_area">
                                                                                <label>End&nbsp;ODO</label>
                                                                            </div>
                                                                            <div class="col-md-8 showcase_text_area">
                                                                                <input
                                                                                    placeholder="Enter ODO End"
                                                                                    formControlName="ODOEnd"
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    value={this.state.ODOEnd}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            ODOEnd: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div  >

                                                                        <GridList  >

                                                                            <GridListTile style={{ borderColor: "blue" }} >
                                                                                <div className="image-zoom">

                                                                                    <img src={this.state.ODOStarturl} width="80" alt="No Img" />
                                                                                </div>
                                                                            </GridListTile>
                                                                            <GridListTile >
                                                                                <div className="image-zoom">

                                                                                    <img src={this.state.ODOEndurl} width="80" alt="No Img" />
                                                                                </div>
                                                                            </GridListTile>

                                                                        </GridList>

                                                                    </div>



                                                                    <button
                                                                        type="submit"
                                                                        class="btn btn-outline-primary btn-lg"
                                                                        style={{ float: "right", marginTop: "5vh" }}
                                                                        onClick={e => this.onSubmit(e)}
                                                                        disabled={loading}

                                                                    >
                                                                        {loading && (
                                                                            <i
                                                                                className="fa fa-refresh fa-spin"
                                                                                style={{ marginRight: "5px" }}
                                                                            />
                                                                        )}
                                                                        {loading && <span>Approving ...</span>}
                                                                        {!loading && <span>Approve</span>}
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}




NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
