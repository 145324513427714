import React, { Component } from "react";
import "./TripMaster.css";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/loader-spinner.css";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import EditIcon from "@material-ui/icons/Edit";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import XLSX from "xlsx";
import CloseIcon from "@material-ui/icons/Close";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "../../../../assets/customImages/excel.jpg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Grid, Container, Typography } from "@mui/material";
import Button2 from "@mui/material/Button";

class NoticeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      modal: false,
      locations: [],
      unique_locationids: [],
      unique_segments: [],
      unique_clients: [],
      anotherModal: false,
      index: null,
      AdvpayDate: new Date(),
      description: "",
      startDate: "2021-12-31T23:59:59.000Z",
      endDate: "2021-12-01T00:00:00.000Z",
      Locationid: null,
      Segment: null,
      ServiceType: "",
      ClientName: null,
      IsVerified: "",
      IsVerifiedValue: "",
      Trip: "",
      selectedArray: [],
      modalImagesArray: [],
      AdvPay: 0,
      activePage: 1,
      itemsCountPerPage: 30,
      totalItemsCount: 5000,
      pageRangeDisplayed: 5,
      columnDefs: [
        {
          headerName: "City",
          field: "LocationID",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Intent No",
          field: "intentno",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Intent Date",
          field: "intentDate",
          sortable: true,
          filter: true,
          minWidth: 110,
        },
        {
          headerName: "Station Name",
          field: "stationName",
          sortable: true,
          filter: true,
          minWidth: 110,
        },
        {
          headerName: "Vehicle No",
          field: "vehicleno",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Client Name",
          field: "clientName",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Amount",
          field: "amount",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Created By",
          field: "createdby",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Trip Id",
          field: "tripId",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Status",
          field: "Status",
          sortable: true,
          filter: true,
          cellRendererFramework: (params) => {
            const status = params.node.data.Status;
            let StatusColor = "";
            if (status == "Bill not Uploaded") {
              StatusColor = "text-danger";
            } else if (status == "Rejected") {
              StatusColor = "text-danger";
            } else {
              StatusColor = "text-success";
            }

            // const text = isVerified === true ? "YES" : "NO";
            // const StatusColor = (isVerified === true) ? "text-success" : "text-danger";
            return (
              <span className={StatusColor} style={{ fontWieght: "bold" }}>
                {status}
              </span>
            );
          },
        },
        {
          headerName: "IsVerified",
          field: "IsVerified",
          filter: true,
          cellRendererFramework: (params) => {
            const isVerified = params.node.data.IsVerified;
            const text = isVerified === true ? "YES" : "NO";
            const StatusColor =
              isVerified === true ? "text-success" : "text-danger";
            return (
              <span className={StatusColor} style={{ fontWieght: "bold" }}>
                {text}
              </span>
            );
          },
        },
        {
          headerName: "View",
          field: "View",
          minWidth: 70,
          cellRendererFramework: (params) => {
            return (
              <RemoveRedEyeIcon
                onClick={() =>
                  this.onClickOpenViewReqModal(params.node.data.intentno)
                }
              />
            );
          },
        },
        {
          headerName: "Edit",
          field: "Edit",
          minWidth: 50,
          cellRendererFramework: (params) => {
            return (
              <EditIcon
                onClick={() =>
                  this.onClickOpenEditModal(params.node.data.intentno)
                }
              />
            );
          },
        },
        {
          headerName: "Approve",
          field: "Approve",
          minWidth: 140,
          cellRendererFramework: (params) => {
            const isVerified = params.node.data.IsVerified;

            const text = isVerified === true ? "danger" : "primary";
            if (text == "danger") {
              return (
                <Button
                  color="danger"
                  value={params.node.data.fid}
                  onClick={this.handleDisapproveFuelIntent}
                >
                  Disapprove
                </Button>
              );
            } else {
              return (
                <Button
                  color="primary"
                  value={params.node.data.fid}
                  onClick={this.handleApproveFuelIntent}
                >
                  Approve
                </Button>
              );
            }
          },
        },
      ],

      rowData: [],
      search: false,
      domLayout: "autoHeight",
      uniqueDateRanges: [],
      dateRangeSelected: "",

      tmsExportData: [],
      tripIds: "",
      tripId: "",
      search_val: "",
      vehicle_no: "",
      stationName: "",
      rotation: 0,

      popupTableData: [],
      popupTableDataKeys: [],
      toggleTripModal: false,
      isViewRequestPageData: false,
      isEditPageData: false,
      imageAttachmentModal: false,
      isImageAttachmentModal: false,
      imageUrlModal: "",
      intentno: "",
      vehicleno: "",
      remarks: "",
      tripid: "",
      oneStatus: "",
      uploadBillLinkImage: [],
      uploadBillLink: "",
      uploadBillLinkText: "Upload",
      status: [],
    };
  }

  toggle = async () => {
    await this.setState({
      modal: !this.state.modal,
    });
  };

  viewReqModalFunction = async (intentno) => {
    console.log(intentno);
    const viewReqData = [];
    await this.state.locations.filter((item) => {
      console.log(item, "item");
      if (item.intentno == intentno) {
        return viewReqData.push(item);
      }
    });
    if (viewReqData.length > 0) {
      const selectedRow = viewReqData[0];
      const billLinks = JSON.parse(selectedRow.Jdata).billLink;
      console.log(billLinks, "billLinks");
      await this.setState({
        intentno,
        modalImagesArray: billLinks,
      });
      await this.toggleOpenViewRequestsModal();
    } else {
      await this.setState({
        style: { display: "none" },
      });
      await this.toggleOpenViewRequestsModal();
    }
  };

  editModalFunction = async (intentno) => {
    const viewReqData = [];
    await this.state.locations.filter((item) => {
      if (item.intentno == intentno) {
        return viewReqData.push(item);
      }
    });
    if (viewReqData.length > 0) {
      const selectedRow = viewReqData[0];
      const billLinks = JSON.parse(selectedRow.Jdata).billLink;
      if (selectedRow.Status == "Bill not Uploaded") {
        this.setState({ oneStatus: "1" });
      } else if (selectedRow.Status == "Bill Uploaded") {
        this.setState({ oneStatus: "2" });
      } else if (selectedRow.Status == "Verified") {
        this.setState({ oneStatus: "3" });
      } else if (selectedRow.Status == "Rejected") {
        this.setState({ oneStatus: "4" });
      }
      await this.setState({
        intentno,
        fid: selectedRow.fid,
        intentDate: selectedRow.intentDate,
        stationName: selectedRow.stationName,
        vehicleno: selectedRow.vehicleno,
        fuelCost: selectedRow.fuelCost,
        amount: selectedRow.amount,
        tripid: selectedRow.tripID,
        remarks: selectedRow.remarks,
        modalImagesArray: billLinks,
      });
      await this.toggleOpenEditsModal();
    } else {
      await this.setState({
        style: { display: "none" },
      });
      await this.toggleOpenEditsModal();
    }
  };

  onClickOpenViewReqModal = async (intentNo) => {
    console.log(intentNo, "intentNo");
    await this.viewReqModalFunction(intentNo);
  };

  onClickOpenEditModal = async (intentNo) => {
    console.log(intentNo, "intentNo");
    await this.editModalFunction(intentNo);
  };

  onClickLiveTrack = async (e) => {
    e.preventDefault();
    const intentNo = e.target.value;
    const selectedRow = this.state.locations.find(
      (item) => item.intentno == intentNo
    );
    const billLinks = JSON.parse(selectedRow.Jdata).billLink;
    const billLink = billLinks.length > 0 && billLinks[0].billLink;
    if (billLink) window.open(billLink, "_blank");
  };

  openImageAttachmentModal = async (url) => {
    // console.log(url, "url");
    if (url.length > 0) {
      if (
        url
          .split(".")
          .pop()
          .toLowerCase() != "pdf" &&
        url
          .split(".")
          .pop()
          .toLowerCase() != "xlsx"
      ) {
        await this.setState({
          isImageAttachmentModal: !this.state.isImageAttachmentModal,
          imageUrlModal: url,
        });
        await this.toggleOpenImageAttachmentModal();
      } else {
        window.open(url, "_blank");
      }
    }
  };

  closeImageAttachmentModal = async () => {
    await this.toggleOpenImageAttachmentModal();
    await this.setState({
      isImageAttachmentModal: !this.state.isImageAttachmentModal,
      imageUrlModal: "",
      rotation: 0,
    });
  };

  toggleOpenImageAttachmentModal = async () => {
    await this.setState({
      imageAttachmentModal: !this.state.imageAttachmentModal,
    });
  };

  toggleOpenViewRequestsModal = async () => {
    console.log("hi");
    await this.setState({
      isViewRequestPageData: !this.state.isViewRequestPageData,
    });
  };

  toggleOpenEditsModal = async () => {
    console.log("rohan");
    await this.setState({
      isEditPageData: !this.state.isEditPageData,
    });
  };

  componentDidMount = () => {
    axios
      .get(`${Config.hostName}/unique/locations`)
      .then((res) => {
        this.setState({ unique_locationids: res.data.data });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${Config.hostName}/fuelintent/getFuelIndentStatusDropdown`)
      .then((res) => {
        this.setState({
          status: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchByTripIdApiCall = async () => {
    const res = await axios
      .post(`${Config.hostName}/fuelintent/search`, {
        search: this.state.search_val,
      })
      .then(async (response) => {
        await this.setState({
          popupTableData: response.data.data,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleQuickSearchShipment = async () => {
    if (this.state.search_val.length > 0) {
      await this.setState({
        popupTableData: [],
        popupTableDataKeys: [],
        style: {},
      });
      await this.searchByTripIdApiCall();
      const data = this.state.popupTableData;
      if (data.length > 0) {
        const keys = Object.keys(data[0]);
        await this.toggleOpenTripModal();
        await this.setState({
          popupTableDataKeys: keys,
          style: {
            display: "none",
          },
        });
      } else {
        alert("No Data Found");
        await this.setState({
          style: {
            display: "none",
          },
        });
      }
    } else {
      alert("Please Enter Query to search");
    }
  };

  toggleOpenTripModal = async () => {
    await this.setState({
      toggleTripModal: true,
    });
  };

  toggleCloseTripModal = async () => {
    await this.setState({
      toggleTripModal: false,
    });
  };

  changeLocation = async (event) => {
    this.setState({ style: {}, unique_segments: [], unique_clients: [] });
    event.preventDefault();
    await this.setState({ Locationid: event.target.value });
    let { Locationid } = this.state;
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: Locationid,
        clientName: "",
      })
      .then((res) => {
        // console.log(res.data);
        this.setState({
          unique_clients: res.data.data,
          style: { display: "none" },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateLocation = async (event) => {
    this.setState({ style: {}, unique_segments: [], unique_clients: [] });
    event.preventDefault();
    await this.setState({ city: event.target.value });
    let { city } = this.state;
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: city,
        clientName: "",
      })
      .then((res) => {
        // console.log(res.data);
        this.setState({
          unique_clients: res.data.data,
          style: { display: "none" },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeClient = async (event) => {
    // console.log(event.target.value);
    event.preventDefault();
    await this.setState({
      style: {},
      unique_segments: [],
      ClientName: event.target.value,
    });
    let { ClientName, Locationid } = this.state;
    await axios
      .post(`${Config.hostName}/unique/segments`, {
        locationId: Locationid,
        clientName: ClientName,
      })
      .then((res) => {
        // console.log(res);
        this.setState({
          unique_segments: res.data.data,
          style: { display: "none" },
        });
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
    console.log(this.state.unique_segments);
  };

  updateClient = async (event) => {
    // console.log(event.target.value);
    event.preventDefault();
    await this.setState({
      style: {},
      unique_segments: [],
      clientName: event.target.value,
    });
    let { clientName, city } = this.state;
    await axios
      .post(`${Config.hostName}/unique/segments`, {
        locationId: city,
        clientName: clientName,
      })
      .then((res) => {
        // console.log(res);
        this.setState({
          unique_segments: res.data.data,
          style: { display: "none" },
        });
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
    console.log(this.state.unique_segments);
  };

  handlePageChange = async (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);
    // await this.setState({ activePage: pageNumber, style: {} });
    // await this.componentWillMount();
    console.log(`active page is ${pageNumber}`);
    await this.setState({ activePage: pageNumber, style: {} });
    if (this.state.search === false) await this.componentWillMount();
    else await this.handleSearchShipment();
  };

  componentWillMount = async () => {
    await this.setState({ search: false });
    await this.getUniqueDateRanges();
    await this.handleSearchShipment();
  };

  handleCheckVerify = (index) => {
    let selectedArray = this.state.locations;
    selectedArray.forEach((item) => {
      item.selected = false;
    });
    selectedArray[index].selected = !this.state.locations[index].selected;
    this.setState({
      locations: selectedArray,
    });
    this.setState({
      index: index,
    });
  };

  getUniqueDateRanges = async () => {
    await axios
      .get(
        `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
      )
      .then(async (res) => {
        console.log(res.data.data);
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueDateRanges: res.data.data,
            dateRangeSelected: res.data.data[3],
            startDate: moment(res.data.data[0].startDate).format("YYYY-MM-DD"),
            endDate: moment(res.data.data[0].endDate).format("YYYY-MM-DD"),
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleExportExcelTripSearch = async (e) => {
    e.preventDefault();
    const mainData = this.state.popupTableData;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(mainData);
    XLSX.utils.book_append_sheet(wb, ws, `TripData`);

    const nameToExcel = "Fuel_Intent_Details";
    XLSX.writeFile(wb, `${nameToExcel}.xlsx`);
  };

  handleApproveFuelIntent = async (e) => {
    e.preventDefault();
    console.log(e.target.value);
    await axios
      .post(`${Config.hostName}/fuelintent/modifyFuelIntent`, {
        fid: e.target.value,
        contentid: 3,
        content: e.target.value,
      })
      .then(async (response) => {
        if (response.data.success === false) {
          alert(response.data.message);
        } else {
          await this.handleSearchShipment();
          alert("Approved Succesfully");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  handleDisapproveFuelIntent = async (e) => {
    e.preventDefault();
    console.log(e.target.value);
    await axios
      .post(`${Config.hostName}/fuelintent/modifyFuelIntent`, {
        fid: e.target.value,
        contentid: 4,
        content: e.target.value,
      })
      .then(async (response) => {
        if (response.data.success === false) {
          alert(response.data.message);
        } else {
          await this.handleSearchShipment();
          alert("Disapproved Succesfully");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  handleSearchShipment = async () => {
    try {
      // console.log(this.state.startDate, this.state.endDate)
      await this.setState({ style: {}, search: true });
      let result = await axios.post(
        `${Config.hostName}/fuelintent/getFuelIntentReport`,
        {
          startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
          stationName: this.state.stationName,
          vehicleno: this.state.vehicle_no,
          clientName: this.state.ClientName,
          city: this.state.Locationid,
          tripId: this.state.tripId,
        }
      );
      console.log(result);
      if (result.data.success) {
        await this.setState({
          style: { display: "none" },

          locations: result.data.data,
          tmsExportData: result.data.data,
          rowData: result.data.data.map((eachItem) => {
            return {
              LocationID: eachItem.city,
              intentno: eachItem.intentno,
              intentDate: eachItem.intentDate,
              stationName: eachItem.stationName,
              vehicleno: eachItem.vehicleno,
              clientName: eachItem.clientName,
              amount: eachItem.amount,
              tripId: eachItem.tripID,
              createdby: eachItem.createdby,
              Status: eachItem.Status,
              fid: eachItem.fid,
              Jdata: eachItem.Jdata,
              IsVerified: eachItem.IsVerified,
            };
          }),
        });
      } else {
        alert(result.data.message);
        await this.setState({
          style: { display: "none" },
          locations: [],
          tmsExportData: [],
        });
      }
    } catch (e) {}
  };

  handleAdvPay = (index, value) => {
    let selectedArray = this.state.locations;
    selectedArray[index].AdvPay = value;
    this.setState({
      locations: selectedArray,
    });
  };

  handleRemarks = (index, value) => {
    let selectedArray = this.state.locations;
    selectedArray[index].Remarks = value;
    this.setState({
      locations: selectedArray,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let data = e.target.value;
    try {
      await this.setState({ style: {} });
      const res = await axios
        .post(`${Config.hostName}/tms/verify`, {
          tripId: data,
          user: this.props.auth.user.name,
          isVerified: 1,
          comments: null,
          advPay: null,
          advPayDate: null,
        })
        .then(async (response) => {
          if (response.data.success === false) {
            this.props.setAlert(response.data.message, "danger");
          } else {
            await this.handleSearchShipment();
            await this.props.setAlert(`Approved Trip ${data} `, "success");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
      }
    }
  };

  onSubmitUpdate = async (e) => {
    e.preventDefault();
    console.log("new");
    try {
      // eslint-disable-next-line
      const res = await axios
        .post(`${Config.hostName}/fuelintent/modifyFuelIntent`, {
          fid: this.state.fid,
          contentid: 1,
          content: [
            {
              intentno: this.state.intentno,
              intentDate: this.state.intentDate,
              stationName: this.state.stationName,
              vehicleno: this.state.vehicleno,
              fuelCost: this.state.fuelCost,
              amount: this.state.amount,
              tripID: this.state.tripid,
              statusID: this.state.oneStatus,
              Remarks: "",
            },
          ],
        })
        .then((response) => {
          console.log(response, "response");
          if (response.data.name) {
            this.props.setAlert(response.data.parent.message, "danger");
          } else {
            this.setState({
              isEditPageData: !this.state.isEditPageData,
            });
            this.handleSearchShipment();
            this.props.setAlert("Updated", "success");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });

      // console.log("Route added");
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
      }
    }
  };

  uploadFileAndGetUrl = async (formData) => {
    // console.log("uploading formdata")
    const fileData = await axios({
      method: "POST",
      url: `${Config.hostName}/unique/upload/any-file`,
      timeout: 3 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: formData,
    });
    return fileData;
  };

  billLinkUploadFile = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    // await this.getPanCardUrl(urlFromAws, file.name);
    await this.setState({
      uploadBillLink: urlFromAws,
    });

    // console.log(this.state.attachments, "data")
  };

  delay = async (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  onUpdateBillLink = async (event) => {
    event.preventDefault();

    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { uploadBillLinkImage } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      uploadBillLinkImage.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false,
      });
    });
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    await this.setState({
      uploadBillLinkText: "Uploading..",
      uploadBillLink: uploadBillLinkImage,
      style: {},
    });
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.billLinkUploadFile(item);
    }
    await this.delay(2000);
    await this.setState({
      uploadBillLinkText: "Uploading..",
    });

    try {
      // eslint-disable-next-line
      const res = await axios
        .post(`${Config.hostName}/fuelintent/modifyFuelIntent`, {
          fid: this.state.intentno,
          contentid: 2,
          content: [{ billLink: this.state.uploadBillLink }],
        })
        .then(async (response) => {
          console.log(response, "response");
          if (response.data.name) {
            this.props.setAlert(response.data.parent.message, "danger");
          } else {
            this.setState({
              uploadBillLinkText: "Uploaded..",
              isViewRequestPageData: !this.state.isViewRequestPageData,
              style: {
                display: "none",
              },
            });
            // await this.delay(2000);
            this.setState({
              uploadBillLinkText: "Upload",
            });

            this.handleSearchShipment();
            // this.props.setAlert("Attachment Updated", "success");
            alert("Attachment Updated!!");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });

      // console.log("Route added");
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
      }
    }
  };

  handleChangeDateRange = async (e) => {
    console.log(e.target.value);
    let value = e.target.value;
    if (value == 2222) {
      await this.setState({
        dateRangeSelected: e.target.value,
        startDate: "",
        endDate: "",
      });
      await this.toggle();
    } else {
      this.state.uniqueDateRanges.filter(async (eachDate, i) => {
        if (i == value) {
          console.log("true", eachDate);
          await this.setState({
            dateRangeSelected: e.target.value,
            startDate: moment(eachDate.startDate).format("YYYY-MM-DD"),
            endDate: moment(eachDate.endDate).format("YYYY-MM-DD"),
          });
        }
      });
    }
  };

  toggleCloseModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: "",
    });
  };

  toggleConfirmModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: 2222,
    });
  };

  handleExportExcel = async (e) => {
    e.preventDefault();
    var workSheet = XLSX.utils.json_to_sheet(this.state.tmsExportData);
    var workBook = XLSX.utils.book_new();
    var wscols = [
      { wch: 6 },
      { wch: 35 },
      { wch: 15 },
      { wch: 15 },
      { wch: 35 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    // workSheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
    XLSX.writeFile(workBook, "Fuel_Intent_Report" + ".xlsx");
  };

  render() {
    let {
      style,
      modal,
      isViewRequestPageData,
      isEditPageData,
      modalImagesArray,
      toggleTripModal,
      popupTableDataKeys,
      popupTableData,
      isImageAttachmentModal,
      rotation,
      imageAttachmentModal,
    } = this.state;
    const defaultColDef = {
      flex: 1,
      minWidth: 100,
      // height: 100,
      resizable: true,
    };

    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;

    return (
      <div>
        <div id="cover-spin" style={style}></div>
        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />

                <nav
                  className="navbar navbar-light "
                  style={{ backgroundColor: "none" }}
                >
                  <div
                    className="row"
                    style={{ paddingTop: "8px", width: "100%" }}
                  >
                    {/* <div className="col-sm-2">
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="tripId"
                                                    value={this.state.tripIDs}
                                                    // style={{ borderColor: "#FF4848" }}
                                                    style={{
                                                        fontFamily: 'Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',
                                                        fontVariantNumber: 'tabular-nums'
                                                    }}
                                                    id="tripId"
                                                    placeholder="TripIDs"
                                                    onChange={(e) => {
                                                        this.setState({ tripIDs: e.target.value })
                                                    }}
                                                >
                                        
                                                </Input>
                                            </FormGroup>
                                        </div> */}

                    <div className="col-sm-2">
                      <FormGroup>
                        <Input
                          type="text"
                          name="search_val"
                          value={this.state.search_val}
                          // style={{ borderColor: "#FF4848" }}
                          style={{
                            fontFamily:
                              "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                            fontVariantNumber: "tabular-nums",
                          }}
                          id="search_val"
                          placeholder="Search"
                          onChange={(e) => {
                            this.setState({ search_val: e.target.value });
                          }}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <button
                        type="submit"
                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                        onClick={this.handleQuickSearchShipment}
                      >
                        <i
                          className="fa fa-search"
                          style={{ marginRight: "5px" }}
                        />
                        {/* Quick Trip Search */}
                        Search
                      </button>
                    </div>

                    <div className="col-sm-2 ml-auto">
                      {this.state.tmsExportData.length > 0 && (
                        <button
                          className={
                            !this.state.tmsExportData.length > 0 &&
                            !this.state.tmsExportData.length > 0
                              ? "btn btn-danger w-100 overflow-hidden mb-3 px-auto disabled"
                              : "btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                          }
                          onClick={this.handleExportExcel}
                          disabled={
                            !this.state.tmsExportData &&
                            !this.state.tmsExportData.length > 0
                          }
                        >
                          <i
                            className="fa fa-download"
                            style={{ marginRight: "5px" }}
                          />
                          Export
                        </button>
                      )}
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{ paddingTop: "8px", width: "100%" }}
                  >
                    <div className="col-sm-2">
                      <FormGroup>
                        <Input
                          type="select"
                          name="dateRange"
                          value={this.state.dateRangeSelected}
                          id="dateRange"
                          onChange={this.handleChangeDateRange}
                        >
                          <option
                            className="bms-dashboaord-date-range"
                            value={""}
                            disabled
                          >
                            Select Date Range
                          </option>

                          {this.state.uniqueDateRanges.map((eachItem, i) => (
                            <option value={i} key={i}>
                              {eachItem.title}
                            </option>
                          ))}
                          <option value={2222}>
                            {/* <Button onClick={this.toggle}> */}
                            Custom Date Range
                            {/* </Button> */}
                          </option>
                          <div>
                            <Modal
                              isOpen={modal}
                              // toggle={this.toggle}
                            >
                              <ModalHeader>
                                Select Start and End date
                              </ModalHeader>
                              <ModalBody>
                                <div
                                  className="row"
                                  style={{ minHeight: "1px" }}
                                >
                                  <div className="col float-left">
                                    <div
                                      className="cal-datepicker"
                                      style={{ height: "100%" }}
                                    >
                                      <div
                                        className="datepicker-here text-center"
                                        style={{ height: "100%" }}
                                        data-language="en"
                                      >
                                        <DatePicker
                                          height="400"
                                          placeholderText="Select Start Date"
                                          id="startDateInput"
                                          inline
                                          selectsRange
                                          selected={this.state.startDate}
                                          startDate={this.state.startDate}
                                          onChange={(date) => {
                                            this.setState({
                                              startDate: date,
                                            });
                                          }}
                                          dateFormat="yyyy-MM-dd"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col float-left">
                                    <div className="cal-datepicker">
                                      <div
                                        className="datepicker-here text-center"
                                        data-language="en"
                                      >
                                        <DatePicker
                                          height="400"
                                          id="endDateInput"
                                          inline
                                          placeholderText="Select End Date"
                                          selected={this.state.endDate}
                                          onChange={(date) => {
                                            this.setState({
                                              endDate: date,
                                            });
                                          }}
                                          dateFormat="yyyy-MM-dd"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="info"
                                  onClick={this.toggleConfirmModal}
                                >
                                  Confirm
                                </Button>{" "}
                                <Button
                                  color="danger"
                                  onClick={this.toggleCloseModal}
                                >
                                  Close
                                </Button>{" "}
                              </ModalFooter>
                            </Modal>
                          </div>
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <FormGroup>
                        {/* <Label>Location ID</Label> */}
                        <Input
                          type="select"
                          name="locationid"
                          value={this.state.Locationid}
                          id="locationid"
                          onChange={this.changeLocation}
                        >
                          <option value={""} disabled selected>
                            Select Location
                          </option>

                          {this.state.unique_locationids.map((locationid) => (
                            <option value={locationid.Locationid}>
                              {locationid.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <FormGroup>
                        {/* <Label>Client Name</Label> */}
                        <Input
                          type="select"
                          name="clientName"
                          value={this.state.ClientName}
                          id="clientName"
                          onChange={this.changeClient}
                        >
                          <option value={""} disabled selected>
                            Select Client
                          </option>

                          {this.state.unique_clients.map((client) => (
                            <option value={client.ClientName}>
                              {client.ClientName}
                            </option>
                          ))}
                          {/* <option value={0}>All Shipments</option> */}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <FormGroup>
                        <Input
                          type="text"
                          name="vehicle_no"
                          value={this.state.vehicle_no}
                          // style={{ borderColor: "#FF4848" }}
                          style={{
                            fontFamily:
                              "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                            fontVariantNumber: "tabular-nums",
                          }}
                          id="vehicle_no"
                          placeholder="Vehicle No"
                          onChange={(e) => {
                            this.setState({ vehicle_no: e.target.value });
                          }}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <button
                        // type="submit"
                        // className="btn btn-secondary"
                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                        onClick={this.handleSearchShipment}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </nav>

                <div className="card-body">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    {this.state.tmsExportData.length > 0 && (
                      <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        defaultColDef={defaultColDef}
                        onGridReady={this.onGridReady}
                        // rowStyle={{ background: 'yellow' }}
                        enableCellTextSelection={true}
                        gridAutoHeight={true}
                        domLayout={this.state.domLayout}
                        rowHeight={45}
                        // enableCellTextSelection={true}
                        suppressAutoSize={true}
                        // getRowHeight={getRowHeight}
                        // getRowStyle={(params) => {
                        //     return {
                        //         background: (params.data.IsVerified === true) ? "#bbf58c" :
                        //             (params.data.IsVerified === false) ? "#f7a681" :
                        //                 "white"
                        //     }
                        // }}
                      ></AgGridReact>
                    )}
                  </div>

                  <div
                    style={{
                      margin: "9vh auto",
                      marginLeft: "25%",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.tmsExportData.length > 0 && (
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </div>
                </div>

                <div>
                  <Modal
                    size="lg"
                    style={{ maxWidth: "1600px", width: "80%" }}
                    centered={true}
                    isOpen={isViewRequestPageData}
                  >
                    <ModalFooter>
                      <div className="col-sm-2">
                        <Button
                          color="danger"
                          onClick={this.toggleOpenViewRequestsModal}
                        >
                          <CloseIcon />
                        </Button>{" "}
                      </div>
                    </ModalFooter>
                    <ModalBody>
                      <div className="bms-view-req-modal-body">
                        <div>
                          <div
                            className="row bmsViewReqNewCardsForStatusMain"
                            style={{ display: "block" }}
                          >
                            <div className="row " style={{ marginLeft: "2%" }}>
                              <Typography pt={4} variant="h6">
                                {" "}
                                Attachments For Intent No:{" "}
                                <b>{this.state.intentno}</b>
                              </Typography>
                              <Grid pt={1} container>
                                <Grid
                                  pt={2}
                                  item
                                  md={12}
                                  style={{ float: "left", textAlign: "left" }}
                                >
                                  {modalImagesArray.length > 0 &&
                                    modalImagesArray.map((imageUrl, i) => {
                                      return (
                                        <div
                                          className="bms-open-attachment-modal-image"
                                          onClick={() =>
                                            this.openImageAttachmentModal(
                                              imageUrl.billLink
                                            )
                                          }
                                        >
                                          {imageUrl.billLink &&
                                            imageUrl.billLink
                                              .split(".")
                                              .pop()
                                              .toLowerCase() != "pdf" &&
                                            imageUrl.billLink
                                              .split(".")
                                              .pop()
                                              .toLowerCase() != "xlsx" && (
                                              <img
                                                className="bms-view-request-img"
                                                src={imageUrl.billLink}
                                                alt="No Image"
                                                key={i}
                                              />
                                            )}
                                          {imageUrl.billLink &&
                                            imageUrl.billLink
                                              .split(".")
                                              .pop()
                                              .toLowerCase() == "pdf" && (
                                              <img
                                                className="bms-view-request-img"
                                                src={pdfIcon}
                                                alt="No Image"
                                                key={i}
                                              />
                                            )}
                                          {imageUrl.billLink &&
                                            imageUrl.billLink
                                              .split(".")
                                              .pop()
                                              .toLowerCase() == "xlsx" && (
                                              <img
                                                className="bms-view-request-img"
                                                src={excelIcon}
                                                alt="No Image"
                                                key={i}
                                              />
                                            )}
                                        </div>
                                      );
                                    })}
                                </Grid>

                                <Grid
                                  pt={2}
                                  item
                                  md={12}
                                  style={{
                                    float: "right",
                                    textAlign: "right",
                                    marginRight: "3%",
                                  }}
                                >
                                  <Button2
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    component="label"
                                    onChange={this.onUpdateBillLink}
                                    style={{
                                      marginLeft: "115px",
                                      background: "rgb(234 67 68)",
                                      color: "white",
                                    }}
                                    className={this.state.color}
                                  >
                                    {this.state.uploadBillLinkText}
                                    <input
                                      type="file"
                                      hidden
                                      accept="image/*"
                                    />
                                  </Button2>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>

                <div>
                  <Modal
                    size="lg"
                    style={{ maxWidth: "1600px", width: "80%" }}
                    centered={true}
                    isOpen={isEditPageData}
                  >
                    <ModalFooter>
                      <div className="col-sm-2">
                        <Button
                          color="danger"
                          onClick={this.toggleOpenEditsModal}
                        >
                          <CloseIcon />
                        </Button>{" "}
                      </div>
                    </ModalFooter>
                    <ModalBody>
                      <div className="bms-view-req-modal-body">
                        <div>
                          <div className="row bmsViewReqNewCardsForStatusMain">
                            <div className="row bmsViewReqNewCardsForStatusFirst">
                              <div className="row">
                                <span className="bms-view-request-attachments">
                                  Update Intent No: {this.state.intentno}
                                </span>
                                <br />
                              </div>

                              <div className="row bmsViewReqFourthRowGallery">
                                <form style={{ width: "100%" }}>
                                  <div
                                    className="col-lg-12 text-right"
                                    style={{ marginTop: "0vh" }}
                                  ></div>
                                  <div className="col-lg-12">
                                    <div className="grid">
                                      <div className="grid-body">
                                        <div className="item-wrapper">
                                          <div className="row mb-3">
                                            <div className="col-md-12 mx-auto">
                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Intent No.</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      type="text"
                                                      name="intentno"
                                                      className="form-control"
                                                      readOnly
                                                      // valuedefault={this.state.intentno}
                                                      value={
                                                        this.state.intentno
                                                      }
                                                    />

                                                    <input
                                                      type="hidden"
                                                      name="fid"
                                                      className="form-control"
                                                      readOnly
                                                      // valuedefault={this.state.fid}
                                                      value={this.state.fid}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>
                                                      Station Name *
                                                    </label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Roc Number"
                                                      type="text"
                                                      name="stationName"
                                                      className="form-control"
                                                      value={
                                                        this.state.stationName
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          stationName:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>
                                                      Intent Date *{" "}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Intent Date"
                                                      type="date"
                                                      name="intentDate"
                                                      className="form-control"
                                                      value={
                                                        this.state.intentDate
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          intentDate:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Vehicle No. *</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Vechile No."
                                                      type="text"
                                                      name="vehicleno"
                                                      className="form-control"
                                                      value={
                                                        this.state.vehicleno
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          vehicleno:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Fuel Cost*</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Fuel Cost"
                                                      type="text"
                                                      name="fuelCost"
                                                      className="form-control"
                                                      value={
                                                        this.state.fuelCost
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          fuelCost:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Amount *</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Amount"
                                                      type="number"
                                                      name="amount"
                                                      className="form-control"
                                                      value={this.state.amount}
                                                      onChange={(event) => {
                                                        this.setState({
                                                          amount:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Trip Id*</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Trip ID"
                                                      type="text"
                                                      name="tripid"
                                                      className="form-control"
                                                      value={this.state.tripid}
                                                      onChange={(event) => {
                                                        this.setState({
                                                          tripid:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Status*</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <Input
                                                      type="select"
                                                      name="oneStatus"
                                                      id="status"
                                                      onChange={(event) => {
                                                        this.setState({
                                                          oneStatus:
                                                            event.target.value,
                                                        });
                                                      }}
                                                      value={
                                                        this.state.oneStatus
                                                      }
                                                    >
                                                      {this.state.status.map(
                                                        (stat) => (
                                                          <option
                                                            value={
                                                              stat.statusID
                                                            }
                                                            selected={
                                                              this.state
                                                                .oneStatus ==
                                                              stat.statusID
                                                            }
                                                          >
                                                            {stat.status}
                                                          </option>
                                                        )
                                                      )}
                                                    </Input>
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Remarks*</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <AvForm>
                                                      {/* <div className="col-md-12"> */}
                                                      <AvField
                                                        name="Remarks"
                                                        value={
                                                          this.state.remarks
                                                        }
                                                        onChange={(e) => {
                                                          this.setState({
                                                            remarks: e.target.value.replace(
                                                              /[^\w\s]/gi,
                                                              ""
                                                            ),
                                                          });
                                                        }}
                                                        type="textarea"
                                                        placeholder="Remarks"
                                                      />
                                                      {/* </div> */}
                                                    </AvForm>
                                                  </div>
                                                </div>
                                              </div>
                                              <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-lg"
                                                style={{ float: "right" }}
                                                onClick={(e) =>
                                                  this.onSubmitUpdate(e)
                                                }
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>

                <div>
                  {toggleTripModal && (
                    <Modal
                      size="lg"
                      style={{ maxWidth: "1600px", width: "80%" }}
                      centered={true}
                      isOpen={toggleTripModal}
                    >
                      <ModalFooter>
                        <div className="col-sm-3">
                          <span className="bms-view-req-modal-header-label">
                            Quick Trip Search
                          </span>
                        </div>

                        <div className="col-sm-3 ml-auto">
                          <div className="row">
                            <div className="col-sm-6">
                              <button
                                className={
                                  !this.state.popupTableData.length > 0 &&
                                  !this.state.popupTableData.length > 0
                                    ? "btn btn-danger w-100 overflow-hidden mb-3 px-auto disabled"
                                    : "btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                                }
                                onClick={this.handleExportExcelTripSearch}
                                disabled={
                                  !this.state.popupTableData &&
                                  !this.state.popupTableData.length > 0
                                }
                              >
                                <i
                                  className="fa fa-download"
                                  style={{ marginRight: "5px" }}
                                />
                                Export
                              </button>
                            </div>

                            <div className="col-sm-6">
                              <Button
                                color="danger"
                                onClick={this.toggleCloseTripModal}
                              >
                                <CloseIcon />
                              </Button>{" "}
                            </div>
                          </div>
                        </div>
                      </ModalFooter>
                      <ModalBody>
                        <div className="bms-view-req-modal-body">
                          <div>
                            <Table responsive className="styled-table">
                              <thead>
                                <tr className="styled-table-header-row">
                                  {popupTableDataKeys.map((headItem) => {
                                    return <th>{headItem}</th>;
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {popupTableData.map((item) => (
                                  <tr className="styled-table-row">
                                    {popupTableDataKeys.map((hkey) => {
                                      return (
                                        <td>{item[hkey] ? item[hkey] : "-"}</td>
                                      );
                                    })}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                  )}
                </div>

                <div>
                  {isImageAttachmentModal && (
                    <Modal
                      size="lg"
                      style={{ width: "70%", maxWidth: "100%" }}
                      centered={true}
                      isOpen={imageAttachmentModal}
                    >
                      <ModalFooter>
                        <div className="col-sm-3 ">
                          <Button
                            color="disabled"
                            onClick={() =>
                              this.setState({
                                rotation: this.state.rotation - 90,
                              })
                            }
                          >
                            <RotateLeftIcon />
                          </Button>{" "}
                          <Button
                            color="disabled"
                            onClick={() =>
                              this.setState({
                                rotation: this.state.rotation + 90,
                              })
                            }
                          >
                            <RotateRightIcon />
                          </Button>{" "}
                        </div>

                        <div className="col-sm-2 ml-auto">
                          <Button
                            color="danger"
                            onClick={this.closeImageAttachmentModal}
                          >
                            <CloseIcon />
                          </Button>{" "}
                        </div>
                      </ModalFooter>{" "}
                      <ModalBody>
                        <div className="bms-view-image-url-modal">
                          <img
                            src={this.state.imageUrlModal}
                            style={{ transform: `rotate(${rotation}deg)` }}
                            alt="No Image"
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="danger"
                          onClick={this.closeImageAttachmentModal}
                        >
                          Close
                        </Button>{" "}
                      </ModalFooter>
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NoticeAdd.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
