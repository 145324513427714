import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
// import Breadcrumb from "../common/breadcrumb.component";
import PropTypes from "prop-types";
// import {FormGroup, Label, Input} from "reactstrap";
import { connect } from "react-redux";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config"

class RouteAddForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: "",
            pincode: "",
            routeName: "",
            routeNo: "",
            area: ""
        };
        this.validator = new SimpleReactValidator();
    }


    onSubmit = async e => {
        e.preventDefault();
        if (this.validator.allValid()) {
            try {
                let { pincode, routeName, routeNo, area } = this.state;
                console.log(pincode, routeName, routeNo, area);

                // eslint-disable-next-line
                const res = await axios.post(`${Config.hostName}/route-map/add/${this.props.auth.user.CCID}`,
                    {
                        pincode: this.state.pincode,
                        routeName: this.state.routeName,
                        routeNo: this.state.routeNo,
                        area: this.state.area
                    }
                );
                console.log(res.data);

                await this.props.setAlert(`New Route ${this.state.routeName}  Added`, "success");
                await this.props.history.push("/admin/route-mapping");

            } catch (error) {
                const errors = error.response.data.errors;
                if (errors) {
                    errors.forEach(error => this.props.setAlert(error.msg, "danger"));
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        let hrefLink = '#';
        return (
            <div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <form className="card" >
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">Add New Route Mapping</h4>

                                        <Alert />
                                        <div className="card-options">
                                            <a
                                                href={hrefLink}
                                                className="card-options-collapse"
                                                data-toggle="card-collapse"
                                            >
                                                <i className="fe fe-chevron-up"></i>
                                            </a>
                                            <a
                                                href={hrefLink}

                                                className="card-options-remove"
                                                data-toggle="card-remove"
                                            >
                                                <i className="fe fe-x"></i>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* ID : {this.state.location.id} */}
                                            </div>
                                            <div className="col-md-12">

                                                <div className="form-group">
                                                    <label className="form-label">pincode</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="pincode"
                                                        name="pincode"
                                                        value={this.state.pincode}
                                                        onChange={async event =>
                                                            await this.setState({
                                                                pincode: event.target.value
                                                            })
                                                        }
                                                    />
                                                    <div className="text-danger">
                                                        {this.validator.message(
                                                            "pincode",
                                                            this.state.pincode,
                                                            "required|integer|min:6|max:6"
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">routeName</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="routeName"
                                                        name="routeName"
                                                        value={this.state.routeName}
                                                        onChange={async event =>
                                                            await this.setState({
                                                                routeName: event.target.value
                                                            })
                                                        }
                                                    />
                                                    <div className="text-danger">
                                                        {this.validator.message(
                                                            "pincode",
                                                            this.state.routeName,
                                                            "required"
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">routeNo</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="routeNo"
                                                        name="routeNo"
                                                        value={this.state.routeNo}
                                                        onChange={async event =>
                                                            await this.setState({
                                                                routeNo: event.target.value
                                                            })
                                                        }
                                                    />
                                                    <div className="text-danger">
                                                        {this.validator.message(
                                                            "pincode",
                                                            this.state.routeNo,
                                                            "required"
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">area</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="area"
                                                        name="area"
                                                        value={this.state.area}
                                                        onChange={async event =>
                                                            await this.setState({
                                                                area: event.target.value
                                                            })
                                                        }
                                                    />
                                                    <div className="text-danger">
                                                        {this.validator.message(
                                                            "area",
                                                            this.state.area,
                                                            "required"
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button type="submit"
                                            className="btn btn-primary"
                                            onClick={this.onSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

RouteAddForm.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
