/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Input, Button } from "reactstrap";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import { AgGridReact } from 'ag-grid-react';
import "../../../../assets/customCssFiles/loader-spinner.css"
import MultiSelect from "@khanacademy/react-multi-select";
import GeneralWidget from "./GeneralWidget";
import moment from "moment";
import { saveAs } from "file-saver";


class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            modal: false,
            anotherModal: false,
            locations: [],
            unique_route: [],
            uniqueClientSegments: [],
            description: "",
            message: "",
            selectedClientSegment: "",
            style: {},
            tracking_number: "",
            columnDefs: [
                {
                    headerName: "Tracking Id", field: "trackingId", sortable: true, filter: true, maxWidth: 180, minWidth: 180
                },
                {
                    headerName: "Order No", field: "orderNo", sortable: true, filter: true, maxWidth: 160, minWidth: 140
                },
                {
                    headerName: "Customer Code", field: "cxCode", sortable: true, filter: true, maxWidth: 200, minWidth: 140
                },
                {
                    headerName: "Vehicle No", field: "vehicleId", sortable: true, filter: true
                },

                {
                    headerName: "Volume", field: "volume", sortable: true, filter: true, maxWidth: 180, minWidth: 140
                },
                {
                    headerName: "Box Cnt", field: "boxCnt", sortable: true, filter: true
                },
                {
                    headerName: "Route", field: "route", sortable: true, filter: true, maxWidth: 200, minWidth: 140
                },
                {
                    headerName: "Item No", field: "itemNumber", sortable: true, filter: true, maxWidth: 180, minWidth: 140
                },
                {
                    headerName: "Description", field: "description", sortable: true, filter: true, maxWidth: 400, minWidth: 250
                },
                {
                    headerName: "ZipCode", field: "zipCode", sortable: true, filter: true, maxWidth: 200, minWidth: 140
                },
                {
                    headerName: "Address", field: "address", sortable: true, filter: true, maxWidth: 700, minWidth: 500
                },
                {
                    headerName: "Customer Name", field: "cxName", sortable: true, filter: true, maxWidth: 180, minWidth: 140
                },
                {
                    headerName: "Mobile", field: "cxNumberOne", sortable: true, filter: true, maxWidth: 190, minWidth: 140
                },
                {
                    headerName: "Alt Mobile", field: "cxNumberTwo", sortable: true, filter: true, maxWidth: 200, minWidth: 140
                },

                {
                    headerName: "Dispatch Scan", field: "dispatchScan", sortable: true, filter: true, minWidth: 140,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.dispatchScan;
                        const StatusColor = status == "N" ? "text-danger" : "text-success"
                        return (
                            <span className={StatusColor} >{status}</span>
                        )
                    }
                },
                {
                    headerName: "Receive Scan", field: "receiveScan", sortable: true, filter: true, minWidth: 140,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.receiveScan;
                        const StatusColor = status == "N" ? "text-danger" : "text-success"
                        return (
                            <span className={StatusColor} >{status}</span>
                        )
                    }
                },
                {
                    headerName: "OFD Scan", field: "ofdScan", sortable: true, filter: true, minWidth: 140,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.ofdScan;
                        const StatusColor = status == "N" ? "text-danger" : "text-success"
                        return (
                            <span className={StatusColor} >{status}</span>
                        )
                    }
                },

                {
                    headerName: "Latest Status", field: "latestStatus", sortable: true, filter: true, maxWidth: 400, minWidth: 200,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.latestStatus;
                        return (
                            <span className="text-primary" >{status}</span>
                        )
                    }
                },
                {
                    headerName: "Status Date", field: "statusDate", sortable: true, filter: true, maxWidth: 400, minWidth: 200
                }
            ],
            rowData: [],
            activePage: 1,
            itemsCountPerPage: 50,
            totalItemsCount: 5000,
            pageRangeDisplayed: 5,
            page: 1,
            search: false,
            userCcId: null,
            city: "",
            unique_cities: [],
            ccid: "",
            ccXrefId: "",
            searchApi: "",
            domLayout: "autoHeight",
            selected: "",
            dropDownPendingList: [],
            trackingVehiclesList: [],
            uniqueVehiclesCount: "",
            selectedPendingVehicle: "",
            selectedListForDropDown: [],
            isDisplayData: false,
            statsDataWidgets: [],
            exportDataCsv: [],
            isData: false,
            selectedDate: "",
            rowModelType: "infinite",
            rowSelection: 'multiple',
            rowGroupPanelShow: 'always',
            pivotPanelShow: 'always',
            isExportDataDownload: false

        };
    }

    getPendingListDropDown = async () => {
        const result = await axios.get(`${Config.hostName}/shipment/vehicle-new-segg/routing/get/date-vehicle/dropdown/${this.props.auth.user.CCID}`)
            .then(async (res) => {
                console.log(res.data.data);
                if (res.data.data.length > 0) {
                    await this.setState({
                        searchApi: res.data.data[0].dd,
                        // selected: res.data.data[0],
                        dropDownPendingList: res.data.data.map(eachItem => {
                            return {
                                selected: false, ...eachItem
                            }
                        })
                    });
                } else {
                    this.setState({ dropDownPendingList: [] });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getStatsDataForWidgets = async () => {
        await axios.post(`${Config.hostName}/shipment/vehicle-new-segg/routing/get/stats/data/${this.props.auth.user.CCID}`, {
            search: this.state.searchApi
        }
        )
            .then(async (res) => {
                console.log(res.data.data);
                await this.setState({
                    statsDataWidgets: res.data.data,
                    isData: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentWillMount = async () => {
        await this.getPendingListDropDown();
        // await this.functionForFilteringDropDown();
        await this.loadPendingListData();
        await this.getStatsDataForWidgets();
        await this.setState({
            isDisplayData: true,
            style: { display: "none" },
        });
    };

    handleChangePendingVehicles = async (selected) => {
        // console.log(selected, "selected");

        await this.setState({
            selected: selected
        })


    }

    functionForFilteringDropDown = async () => {
        let { dropDownPendingList, selected } = this.state;
        let selectedList = [];
        if (selected.length > 0) {
            selected.map(selectedItem => {
                dropDownPendingList.map((item, i) => {
                    if (selectedItem === i) {
                        // console.log(selectedItem, i, "selectedItem,i");
                        return item.selected = true
                    }
                    else {
                        return item.selected = false
                    }
                })
            })
        }
        else {
            dropDownPendingList.map(item => {
                return item.selected = false
            })
        }

        const pushSelectedArray = selected.map((selItem => {
            selectedList.push(dropDownPendingList[selItem].dd)
        }))

        const promiseMapSelectedArray = await Promise.all(pushSelectedArray);

        let stringApi = "";
        const pushStringApi = selectedList.map(item => {
            return stringApi = stringApi + "," + item
        })
        const promiseMapString = await Promise.all(pushStringApi);

        console.log(stringApi, "stringApi");

        await this.setState({
            searchApi: stringApi
        })

    }

    loadPendingListData = async () => {
        await axios.post(`${Config.hostName}/shipment/vehicle-new-segg/routing/get/details/${this.props.auth.user.CCID}`, {
            search: this.state.searchApi
        }).then(async (res) => {
            console.log(res.data.data);

            if (res.data.success == false) {
                await this.props.setAlert(res.data.message, "danger");
            }
            await this.setState({
                exportDataCsv: res.data.data,

                rowData: res.data.data.map(eachItem => {
                    return {
                        address: eachItem.Address,
                        boxCnt: eachItem.BoxCnt,
                        cxCode: eachItem.CxCode,
                        cxName: eachItem.CxName,
                        cxNumberOne: eachItem.CxNumber1,
                        cxNumberTwo: eachItem.CxNumber2,
                        description: eachItem.Description,
                        dispatchScan: eachItem.DispatchScan,
                        itemNumber: eachItem.ItemNumber,
                        ofdScan: eachItem.OFDScan,
                        orderNo: eachItem.Orderno,
                        receiveScan: eachItem.ReceiveScan,
                        route: eachItem.Route,
                        trackingId: eachItem.TrackingID,
                        vehicleId: eachItem.VehicleID,
                        zipCode: eachItem.ZipCode,
                        latestStatus: eachItem.latestStatus,
                        statusDate: eachItem.statusDate,
                        volume: eachItem.volume
                    }
                }),
            })
        }).catch(async (err) => {
            await this.props.setAlert(err.message, "danger");
        })

    }

    handleSearchPendingVehicle = async () => {

        await this.setState({
            style: {},
            uniqueVehicles: [],
            selectedVehicle: "",
            trackings: {},
            trackingVehiclesList: [],
            uniqueVehiclesCount: "",
            searchApi: "",
            isData: false
        })
        // console.log(this.state.selected.length, "this.state.searchApi");
        if (this.state.selected.length > 0) {

            await this.functionForFilteringDropDown();
            await this.loadPendingListData();
            // await this.getStatsDataForWidgets();
            // await this.getUniqueVehicles();
            await this.getStatsDataForWidgets();
            // console.log(this.state.searchApi, "this.state.searchApi");
            await this.setState({
                isDisplayData: true,
                style: { display: "none" }
            })

        }
        else {
            alert("Please Select minimum One Vehicle")
            await this.setState({
                isDisplayData: false,
                style: { display: "none" }
            })
        }


    }

    exportData = async (e) => {
        e.preventDefault();
        await this.setState({
            isExportDataDownload: true
        })
        await axios
            .post(`${Config.hostName}/shipment/vehicle-new-segg/send/excel/file/${this.props.auth.user.CCID}`, {
                search: this.state.searchApi
            }, {
                responseType: "blob",
            })
            .then(async (res) => {
                await this.setState({
                    isExportDataDownload: false
                })
                const pdfBlob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                console.log(pdfBlob, "pdf");
                saveAs(pdfBlob, `ROUTE_PLAN_${moment(new Date()).format("MMDD")}.xlsx`);


            }).catch(err => {
                console.log(err);

            })

    }

    render() {
        let { style, options, selected, statsDataWidgets, isData, isExportDataDownload } = this.state;
        const defaultColDef = {
            flex: 1,
            resizable: true,
            // editable: true,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            sortable: true,
            filter: true,
            minWidth: 100,
        }
        const optionsM = this.state.dropDownPendingList.map((item, i) => (
            {
                value: i,
                label: item.dd
            }
        ))
        statsDataWidgets = statsDataWidgets[0];
        const propsData = { ...statsDataWidgets, isData }
        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id="cover-spin" style={style}></div>


                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                {/* <div className="card"> */}
                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <MultiSelect
                                                    options={optionsM}
                                                    selected={selected}
                                                    onSelectedChanged={this.handleChangePendingVehicles}
                                                    overrideStrings={{
                                                        selectSomeItems: "Select Vehicle To Load",
                                                        allItemsAreSelected: "All Vehicles are Selected",
                                                        selectAll: "Select All",
                                                        search: "Search",
                                                    }}
                                                >

                                                </MultiSelect>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                type="submit"
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.handleSearchPendingVehicle}
                                            >
                                                Load&nbsp;Data
                  </button>
                                        </div>


                                        <div className="col-sm-2 ml-auto">
                                            {this.state.exportDataCsv.length > 0 &&

                                                <button
                                                    type="submit"
                                                    className="btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                                                    onClick={(e) => this.exportData(e)}
                                                    disabled={isExportDataDownload}
                                                >

                                                    {isExportDataDownload && (
                                                        <i
                                                            className="fa fa-refresh fa-spin"
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                    )}
                                                    {isExportDataDownload && <span>Processing ...</span>}
                                                    {!isExportDataDownload && <span>Export</span>}

                                                </button>
                                            }
                                        </div>

                                    </div>
                                </nav>

                                {this.state.isData && <GeneralWidget data={propsData} />}
                                <div className="card-body">

                                    <div
                                        className="ag-theme-balham"
                                        style={{
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={this.state.rowData}
                                            defaultColDef={defaultColDef}
                                            animateRows={true}
                                            rowHeight={40}
                                            enableCellTextSelection={true}
                                            suppressAutoSize={true}
                                            gridAutoHeight={true}
                                            domLayout={this.state.domLayout}
                                            suppressRowClickSelection={true}
                                            groupSelectsChildren={true}
                                            rowSelection={this.state.rowSelection}
                                            rowGroupPanelShow={this.state.rowGroupPanelShow}
                                        >
                                        </AgGridReact>
                                    </div>
                                    <div style={{ margin: "10vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                        {/* <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                                            onChange={this.handlePageChange.bind(this)}
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        /> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);