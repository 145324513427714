import React, { Component } from "react";
import axios from "axios";
import Config from "../../../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import {
    FormGroup, Input, Button, ModalBody, ModalHeader, Modal, ModalFooter, Table,
    Alert,

} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../assets/customCssFiles/tmshomepage.css";
import { defaults } from "react-chartjs-2";
import "../../../../assets/customCssFiles/loader-spinner.css"
import "../../../../assets/customCssFiles/tmsTripCount.css";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import './style.css';
import ReplyIcon from '@mui/icons-material/Reply';
import Tooltip from '@mui/material/Tooltip';


defaults.global.defaultFontSize = "12";
defaults.global.defaultFontFamily = "Arial";
defaults.global.defaultFontColor = "#808080";
defaults.global.defaultFontWeight = "200";
defaults.global.defaultLetterSpacing = "2px";

const userData = localStorage.getItem('persist:root');
let userID = '';
if (userData) {
    const auth = JSON.parse(userData).auth;
    userID = JSON.parse(auth).USERID;
}

let hid = true;
if (userID == 1695 || userID == 1 || userID == 285 || userID == 113) {
    hid = false;
}

defaults.global.hides = hid;


class HomePage extends Component {
    state = {
        hide: defaults.global.hides,
    }
    state = {
        apiData: [],
        startDate: "",
        endDate: "",
        reportId: "",
        style: {},
        isData: false,
        unique_cities: [],
        modal: false,
        uniqueDateRanges: [],
        dateRangeSelected: "",
        statusKeys: ["Regular", "Adhoc"],
        locationId: "",
        widgetData: [],
        clientData: [],
        uniqueClients: [],
        requiredClientData: [],
        uniqueRouteCodeFilter: [],
        clientName: "",
        segment: "",
        uniqueSegmentsFilter: [],
        uniqueClientsFilter: [],
        domLayout: "autoHeight",
        columnDefs: [

            {
                headerName: "City", field: "City", sortable: true, filter: true
            },
            {
                headerName: "Client Name", field: "ClientName", sortable: true, filter: true
            },
            {
                headerName: "Segment", field: "Segment", sortable: true, filter: true, minWidth: 50
            },
            {
                headerName: "Vendor Name", field: "VendorName", sortable: true, filter: true, minWidth: 110
            },
            {
                headerName: "Vehicle Size", field: "VehicleSize", sortable: true, filter: true, minWidth: 70
            },
            {
                headerName: "Vehicle Number", field: "VehicleNumber", sortable: true, filter: true, minWidth: 110,
                cellRendererFramework: (params) => {
                    return (<div onClick={e => this.toggleOpenFuelModal(params.node.data.VehicleNumber)} style={{ cursor: "pointer" }}>
                        {/* <Label color={"rgb(251 94 121)"} onClick={e => this.handleOpenView(params.node.data.RouteName)} style={{ cursor: "pointer" }}> */}
                        <u id='changeColor' > {params.node.data.VehicleNumber}</u>
                        {/* </Label> */}
                    </div>)

                }
            },
            {
                headerName: "Start Date", field: "StartDate", sortable: true, filter: true
            },
            {
                headerName: "Fuel Over Issued", field: "FuelOverIssued", sortable: true, filter: true
            },

            {

                headerName: "Requestor Name", field: "Requestor", sortable: true, filter: true, hide: this.state.hide
            },
            {
                headerName: "Requestor Mobile", field: "RequestorContact", sortable: true, filter: true, hide: this.state.hide
            },
            {
                headerName: "Action", field: "", sortable: true, filter: true, minWidth: 233,
                cellRendererFramework: (params) => {
                    const userID = this.state.userID;
                    if (userID == 1 || userID == 1695 || userID == 285 || userID == 113) {
                        if (params.node.data.isverified == false) {
                            return (

                                <>
                                    <Button
                                        color='danger'
                                        onClick={e => this.adminDettach(e, params.node.data.rtripID)}
                                    >
                                        Dettach
                                    </Button>
                                    {this.state.userType == 'mis' &&
                                          <Tooltip title="Transfer Indent" onClick={e => this.transferIndent(e, params.node.data)}>
                                          <Button
                                      color='primary' onClick={e => this.transferIndent(e, params.node.data)}>
                                          <ReplyIcon style={{ color: 'black', marginLeft: '37px', cursor: 'pointer', transform: 'scaleX(-1)' }} onClick={e => this.transferIndent(e, params.node.data)} />
                                          </Button>
                                      </Tooltip>
                                    }
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <Button
                                        color='primary'
                                    >
                                        Dettach
                                    </Button>
                                    {this.state.userType == 'mis' &&
                                        <Tooltip title="Transfer Indent" onClick={e => this.transferIndent(e, params.node.data)}>
                                            <Button
                                        color='primary' onClick={e => this.transferIndent(e, params.node.data)}>
                                            <ReplyIcon style={{ color: 'black', marginLeft: '37px', cursor: 'pointer', transform: 'scaleX(-1)' }} onClick={e => this.transferIndent(e, params.node.data)} />
                                            </Button>
                                        </Tooltip>
                                    }

                                </>)

                        }
                    } else {
                        if (params.node.data.isverified == false) {
                            return <Button
                                color='danger'
                            >
                                Pending
                            </Button>
                        } else {
                            return <Button
                                color='primary'
                                onClick={e => this.toggleOpenDettachModal(e, params.node.data.rtripID, params.node.data.StartDate)}
                            >
                                Request Dettach
                            </Button>
                        }

                    }

                }
            },
        ],

        columnDefsMis: [

            {
                headerName: "City", field: "City", sortable: true, filter: true
            },
            {
                headerName: "Client Name", field: "ClientName", sortable: true, filter: true
            },
            {
                headerName: "Segment", field: "Segment", sortable: true, filter: true, minWidth: 50
            },
            {
                headerName: "Vendor Name", field: "VendorName", sortable: true, filter: true, minWidth: 110
            },
            {
                headerName: "Vehicle Size", field: "VehicleSize", sortable: true, filter: true, minWidth: 70
            },
            {
                headerName: "Vehicle Number", field: "VehicleNumber", sortable: true, filter: true, minWidth: 110,
                cellRendererFramework: (params) => {
                    return (<div onClick={e => this.toggleOpenFuelModal(params.node.data.VehicleNumber)} style={{ cursor: "pointer" }}>
                        {/* <Label color={"rgb(251 94 121)"} onClick={e => this.handleOpenView(params.node.data.RouteName)} style={{ cursor: "pointer" }}> */}
                        <u id='changeColor' > {params.node.data.VehicleNumber}</u>
                        {/* </Label> */}
                    </div>)

                }
            },
            {
                headerName: "Start Date", field: "StartDate", sortable: true, filter: true
            },
            {
                headerName: "Dettach Date", field: "Dettachdate", sortable: true, filter: true
            },
            {
                headerName: "Fuel Over Issued", field: "FuelOverIssued", sortable: true, filter: true
            },

            {

                headerName: "Requestor Name", field: "Requestor", sortable: true, filter: true, hide: this.state.hide
            },
            {
                headerName: "Requestor Mobile", field: "RequestorContact", sortable: true, filter: true, hide: this.state.hide
            },
            {
                headerName: "Action", field: "", sortable: true, filter: true, minWidth: 233,
                cellRendererFramework: (params) => {
                    const userID = this.state.userID;
                    if (userID == 1 || userID == 1695 || userID == 285 || userID == 113) {
                        if (params.node.data.isverified == false) {
                            return (

                                <>
                                    <Button
                                        color='danger'
                                        onClick={e => this.adminDettach(e, params.node.data.rtripID)}
                                    >
                                        Dettach
                                    </Button>
                                    {this.state.userType == 'mis' &&
                                        <Tooltip title="Transfer Indent" >
                                            <ReplyIcon style={{ color: 'black', marginLeft: '37px', cursor: 'pointer', transform: 'scaleX(-1)' }} onClick={e => this.transferIndent(e, params.node.data)} />
                                        </Tooltip>
                                    }

                                </>
                            )
                        } else {
                            return (
                                <>
                                    <Button
                                        color='primary'
                                    >
                                        Dettach
                                    </Button>
                                    {this.state.userType == 'mis' &&
                                        <Tooltip title="Transfer Indent">
                                            <ReplyIcon style={{ color: 'black', marginLeft: '37px', cursor: 'pointer', transform: 'scaleX(-1)' }} onClick={e => this.transferIndent(e, params.node.data)} />
                                        </Tooltip>
                                    }


                                </>)

                        }
                    } else {
                        if (params.node.data.isverified == false) {
                            return <Button
                                color='danger'
                            >
                                Pending
                            </Button>
                        } else {
                            return <Button
                                color='primary'
                                onClick={e => this.toggleOpenDettachModal(e, params.node.data.rtripID, params.node.data.StartDate)}
                            >
                                Request Dettach
                            </Button>
                        }

                    }

                }
            },
        ],

        columnDefsFuel: [

            {
                headerName: "IntentNo", field: "IntentNo", sortable: true, filter: true
            },
            {
                headerName: "Intent Date", field: "IntentDate", sortable: true, filter: true
            },
            {
                headerName: "Station Name", field: "StationName", sortable: true, filter: true, minWidth: 110
            },
            {
                headerName: "Vehicle Number", field: "Vehicleno", sortable: true, filter: true, minWidth: 110,
            },
            {
                headerName: "Amount", field: "Amount", sortable: true, filter: true
            },
            {
                headerName: "IntentStatus", field: "IntentStatus", sortable: true, filter: true
            },
        ],

        transerIndent: [],
        openTranserIndentModal: false,
        vehiclenoTransfer: '',
        intentDateTransfer: '',
        alertValidation: '',
        alertTransfer: false,
        tripIDDataIndent: [],
        intentNoTransfer: [],
        userType: '',
        locationSandeep: "",
        popupTableData: [],
        popupTableDataKeys: [],
        toggleTripModal: false,
        rowData: [],
        rowDataFuel: [],
        firstTime: 0,
        userID: '',
        isDettachModal: false,
        dettachDate: '',
        rtripID: '',
        visible: false,
        isFuelModal: false,
        VehicleNumber: '',
        isAddModal: false,
        routeCode: '',
        serviceHrs: '',
        isODO: 0,
        isODOImage: 0,
        color: 'danger',
        alertText: '',
        unique_vendor: [],
        VendorID: '',
        vehicleSize: '',
        unique_vehicleSize: [],
        unique_vehicleNumber: [],
        vehicleNumber: '',
        vendorName: ''


    };

    toggle = async () => {
        await this.setState({
            modal: !this.state.modal,
        });
    };

    getUniqueCities = async () => {
        await axios
            .get(
                `${Config.hostName}/unique/locations`
            )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_cities: res.data.data,
                        locationId: res.data.data[0].Locationid,
                        locationSandeep: res.data.data[0].Locationid
                    });
                } else {
                    this.setState({
                        unique_cities: [],
                    });
                }
            })
            .catch((error) => {
                alert(error);
            });
    };




    getDataMain = async () => {


        if (this.state.firstTime == 0) {
            const userData = localStorage.getItem('persist:root');
            const auth = JSON.parse(userData).auth;
            await this.setState({
                firstTime: 1,
                userID: JSON.parse(auth).USERID,
                userType: JSON.parse(auth).user_type,
                locationId: JSON.parse(auth).locationid,
            })
        }

        if (this.state.userID == 1695 || this.state.userID == 1 || this.state.userID == 285 || this.state.userID == 113) {
            await this.setState({
                hide: false
            })
        }

        const res = await axios.post(
            `${Config.hostName}/regularTrips/getRegularTrips`,
            {
                locationID: this.state.locationId,
                clientName: this.state.clientName,
                segment: this.state.segment,
                userID: this.state.userID,

                // locationID: "1",
                // clientName: "Amazon",
                // segment: "FTSD",
                // userID: this.state.userID,
            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        style: { display: 'none' },
                        rowData: res.data.data.map(eachItem => {
                            return {
                                City: eachItem.City,
                                ClientName: eachItem.ClientName,
                                Segment: eachItem.Segment,
                                StartDate: eachItem.StartDate,
                                VehicleNumber: eachItem.VehicleNumber,
                                VehicleSize: eachItem.VehicleSize,
                                VendorName: eachItem.VendorName,
                                rtripID: eachItem.rtripID,
                                isverified: eachItem.isverified,
                                FuelOverIssued: eachItem.FuelOverIssued,
                                Requestor: eachItem.Requestor,
                                RequestorContact: eachItem.RequestorContact,
                                Dettachdate: eachItem.Dettachdate,
                            }
                        })
                    });
                } else {
                    await this.setState({

                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };


    componentDidMount = async () => {
        await this.setState({
            style: {}
        });
        await this.getUniqueCities();
        await this.getDataMain();
        await this.setState({
            isData: true,
            locationId: "",
            style: {
                display: "none"
            }
        });
    };

    transferIndent = async (e, data) => {

        await this.setState({
            transerIndent: data,
            openTranserIndentModal: true,
            alertValidation: "",
            alertTransfer: false,
            vehiclenoTransfer: '',
            intentDateTransfer: '',
            intentNoTransfer: [],
            rowDataFuel: [],
            tripIDDataIndent:[],

        })


    }

    onSubmitGetTripID = async (e) => {

        e.preventDefault();
        await this.setState({
            style: {}
        });
        if (this.state.vehiclenoTransfer == '') {

            this.setState({
                alertValidation: "Vehicle No. is Mandatory!",
                alertTransfer: true,
            })
        } else if (this.state.intentDateTransfer == '') {
            this.setState({
                alertValidation: "Intent Date is Mandatory!",
                alertTransfer: true,
            })
        } else {

            await axios.post(`${Config.hostName}/regularTrips/fuelIssuedByVehicle`, {
                VehicleNumber: this.state.transerIndent['VehicleNumber'],
            })
                .then(async (res) => {
                    if (res.data.data.length > 0) {
                        await this.setState({
                            style: { display: 'none' },
                            rowDataFuel: res.data.data.map(eachItem => {
                                return {
                                    IntentNo: eachItem.IntentNo,
                                    IntentDate: eachItem.IntentDate,
                                    StationName: eachItem.StationName,
                                    Vehicleno: eachItem.Vehicleno,
                                    Amount: eachItem.Amount,
                                    IntentStatus: eachItem.IntentStatus,
                                    check: false,
                                }
                            })
                        });
                    } else {
                        await this.setState({
                            rowDataFuel: []
                        });
                    }
                })
                .catch(async (err) => {
                    console.log(err)
                    alert(err);
                });

            const res = await axios.post(
                `${Config.hostName}/fuelintent/transferIndent_checkTmsfortrip`,
                {
                    Vehicleno: this.state.vehiclenoTransfer,
                    intentDate: this.state.intentDateTransfer,
                }
            )
                .then(async (res) => {

                    console.log(res.data);
                    if (res.data.success == true) {
                        await this.setState({
                            alertValidation: "",
                            alertTransfer: false,
                            tripIDDataIndent: res.data.data[0],

                        })
                    } else {
                        alert(res.data.message);
                        await this.setState({
                            alertValidation: "",
                            alertTransfer: false,
                            tripIDDataIndent: [],
                            rowDataFuel: []
                        })
                    }
                })
                .catch((err) => {
                    alert(err);
                    this.setState({
                        alertValidation: "",
                        alertTransfer: false,
                        tripIDDataIndent: [],
                        rowDataFuel: []
                    })
                });

        }
        await this.setState({
            style: {
                display: "none"
            }
        });
    }

    selectVehicle = async (e) => {
        let rowDataFuel = this.state.rowDataFuel;
        await rowDataFuel.map((each, key) => {
            rowDataFuel[key].check = false
        });
        rowDataFuel[e].check = !this.state.rowDataFuel[e].check;
        await this.setState({
            rowDataFuel: rowDataFuel,
            intentNoTransfer: rowDataFuel[e]
        })
    }

    onSubmitTransferIntent = async (e) => {
        await this.setState({
            style: {}
        });

        const res = await axios.post(
            `${Config.hostName}/fuelintent/transferIntent`,
            {
                intentno: this.state.intentNoTransfer['IntentNo'],
                rtripID: this.state.transerIndent['rtripID'],
                tripID: this.state.tripIDDataIndent['TripID'],
                userid: this.state.userID,
                amount: this.state.intentNoTransfer['Amount'],
            }
        )
            .then(async (res) => {
                console.log(res.data.data[0]);
                await this.setState({
                    transerIndent: '',
                    tripIDDataIndent: '',
                    openTranserIndentModal: false,
                    alertValidation: "",
                    alertTransfer: false,
                    vehiclenoTransfer: '',
                    intentDateTransfer: '',
                    intentNoTransfer: [],
                    rowDataFuel: []
                })
                await this.getDataMain();
                alert('Intent Transfer Successfull');
            })
            .catch((err) => {
                alert(err);
                this.setState({
                    transerIndent: '',
                    tripIDDataIndent: '',
                    openTranserIndentModal: false,
                    alertValidation: "",
                    alertTransfer: false,
                    vehiclenoTransfer: '',
                    intentDateTransfer: '',
                    intentNoTransfer: [],
                    rowDataFuel: []
                })
            });
        await this.setState({
            style: {
                display: "none"
            }
        });
    }

    toggleCloseTranserIndentModal = async (e) => {
        await this.setState({
            openTranserIndentModal: false,
            openSubmitTranserIndentModal: false,
            alertValidation: "",
            alertTransfer: false,
        })
    }

    handleChangeDateRange = async (e) => {
        let value = e.target.value;
        if (value == 2222) {
            await this.setState({
                dateRangeSelected: e.target.value,
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: eachDate.startDate,
                        endDate: eachDate.endDate,
                    });
                }
            });
        }
    };


    handleSearchShipment = async () => {

        await this.setState({
            apiData: [],
            clientData: [],
            style: {}
        });
        await this.getDataMain();
        await this.setState({
            isData: true,
            style: {
                display: "none"
            }
        });
    };


    handleChangeCity = async (e) => {
        e.preventDefault();
        console.log(e);
        await this.setState({ locationId: e.target.value, style: {}, unique_vendor: [], VendorID: '', unique_vehicleSize: [], vehicleSize: '', unique_vehicleNumber: [], vehicleNumber: '', uniqueRouteCodeFilter: [], routeCode: '', uniqueSegmentsFilter: [], uniqueClientsFilter: [] });
        let { locationId } = this.state;
        await axios
            .post(`${Config.hostName}/unique/clients`, {
                locationId: locationId,
                clientName: ""
            })
            .then(async (res) => {
                await this.getVendorName();
                await this.setState({
                    uniqueClientsFilter: res.data.data,
                    style: { display: 'none' }
                });
            })
            .catch(err => {
                alert(err);
            });
    };

    handleChangeClient = async event => {
        event.preventDefault();
        await this.setState({ style: {}, uniqueRouteCodeFilter: [], routeCode: '', uniqueSegmentsFilter: [], clientName: event.target.value });
        let { clientName, locationId } = this.state;
        await axios.post(`${Config.hostName}/unique/segments`, {
            locationId: locationId,
            clientName: clientName
        })
            .then(async (res) => {
                await this.setState({
                    uniqueSegmentsFilter: res.data.data,
                    style: { display: 'none' }
                });
            })
            .catch(async (err) => {
                await this.setState({
                    style: { display: 'none' }
                });
                console.log(err)
                alert(err);
            });
    };

    handleChangeVendorID = async event => {
        event.preventDefault();
        await this.setState({ style: {}, unique_vehicleSize: [], vehicleSize: '', unique_vehicleNumber: [], vehicleNumber: '', VendorID: event.target.value });
        let { VendorID } = this.state;
        let newArray = this.state.VendorID.split(",");
        await this.setState({
            VendorID: newArray[1],
            vendorName: newArray[0]
        })
        await axios.post(`${Config.hostName}/vms/get/vehicles/size/vender`, {
            vendorID: this.state.VendorID
        })
            .then(async (res) => {
                await this.setState({
                    unique_vehicleSize: res.data.data,
                    style: { display: 'none' }
                });
            })
            .catch(async (err) => {
                await this.setState({
                    style: { display: 'none' }
                });
                console.log(err)
                alert(err);
            });
    }

    handleChangeVehicleSize = async event => {
        event.preventDefault();
        await this.setState({ style: {}, unique_vehicleNumber: [], vehicleNumber: '', vehicleSize: event.target.value });
        let { VendorID, vehicleSize } = this.state;
        await axios.post(`${Config.hostName}/vms/get/vehicles/details`, {
            vendorID: this.state.VendorID,
            vehicleSize: this.state.vehicleSize,
        })
            .then(async (res) => {
                await this.setState({
                    unique_vehicleNumber: res.data.data,
                    style: { display: 'none' }
                });
            })
            .catch(async (err) => {
                await this.setState({
                    style: { display: 'none' }
                });
                console.log(err)
                alert(err);
            });
    }

    handleChangeSegment = async event => {
        event.preventDefault();
        await this.setState({ style: {}, uniqueRouteCodeFilter: [], routeCode: '', segment: event.target.value });
        let { segment, clientName, locationId } = this.state;
        await axios
            .post(`${Config.hostName}/regulartrips/getRouteCode`, {
                Locationid: locationId,
                Client: clientName,
                Segment: segment,
                ServiceType: "regular",
            })
            .then(res => {
                console.log(res.data);
                this.setState({
                    uniqueRouteCodeFilter: res.data.data,
                    style: { display: 'none' }
                });
            })
            .catch(err => {
                alert(err);
            });
    };

    onDismiss = async (e) => {
        this.setState({
            visible: false
        })
    }

    onSubmitUpdate = async (e) => {
        e.preventDefault();
        if (this.state.dettachDate == '') {
            this.setState({
                visible: true
            })
        }

        await axios.post(`${Config.hostName}/regularTrips/dettachRequest`, {
            rtripID: this.state.rtripID,
            userID: this.state.userID,
            dettachDate: this.state.dettachDate
        })
            .then(async (res) => {
                await this.getDataMain();
                await this.setState({
                    rtripID: '',
                    dettachDate: '',
                    isDettachModal: false,
                });
            })
            .catch(async (err) => {
                console.log(err)
                alert(err);
            });
    }

    delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    onSubmitAddRegularTrip = async () => {
        let startDatse = this.state.startDate;
        let year = startDatse.slice(0, 4);
        let month = startDatse.slice(5, 7);
        let date = startDatse.slice(8, 10);

        let serviceHrs = this.state.serviceHrs;
        let routeCode = this.state.routeCode;
        let isOdo = 0;
        let isOdoImage = 0;

        if (this.state.IsODO) {
            isOdo = 1;
        }
        if (this.state.IsOdOImage) {
            isOdoImage = 1;
        }

        if (startDatse == '' || serviceHrs == '' || routeCode == '' || this.state.vehicleNumber == '') {
            this.setState({
                visible: true,
                color: 'danger',
                alertText: 'Fill All Fields!!!'
            })
        } else {
            await axios.post(`${Config.hostName}/regularTrips/addRegularTrip`, {
                Client: this.state.clientName,
                Segment: this.state.segment,
                Locationid: this.state.locationId,
                Servicehrs: serviceHrs,
                RouteCode: this.state.routeCode,
                ChargeType: "fat",
                StartDate: `${year}-${month}-${date}`,
                ReportingTime: `${year}-${month}-${date}T00:00:00`,
                IsODO: isOdo,
                VendorName: this.state.vendorName,
                VehicleSize: this.state.vehicleSize,
                VehicleNumber: this.state.vehicleNumber,
                IsOdOImage: isOdoImage,
                userID: this.state.userID,
                jdata: "{}"
            })
                .then(async (res) => {

                    if (res.data.success == true) {
                        await this.setState({
                            color: 'success',
                            visible: true,
                            alertText: 'Regular Trip Addedd Succesfully!!',
                        });
                        await this.delay(5000);
                        await this.onDismiss();
                    } else {
                        await this.setState({
                            color: 'danger',
                            visible: true,
                            alertText: res.data.message,
                        });
                        await this.delay(5000);
                    }

                })
                .catch(async (err) => {
                    console.log(err)
                    alert(err);
                });
        }

    }

    toggleOpenDettachModal = async (e, rtripID, dettachDate) => {
        await this.setState({
            isDettachModal: true,
            rtripID: rtripID
        })
    }

    adminDettach = async (e, rtripID) => {
        await axios.post(`${Config.hostName}/regularTrips/adminDettachRequest`, {
            rtripID: rtripID,
            userID: this.state.userID,
        })
            .then(async (res) => {
                await this.getDataMain();
                await this.setState({
                    rtripID: '',
                });
            })
            .catch(async (err) => {
                console.log(err)
                alert(err);
            });
    }

    toggleCloseDettachModal = async () => {
        await this.setState({
            isDettachModal: false,
        })
    }

    toggleOpenFuelModal = async (VehicleNumber) => {
        console.log(VehicleNumber);
        await this.setState({
            isFuelModal: true,
            VehicleNumber: VehicleNumber,
        })
        await axios.post(`${Config.hostName}/regularTrips/fuelIssuedByVehicle`, {
            VehicleNumber: this.state.VehicleNumber,
        })
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        style: { display: 'none' },
                        rowDataFuel: res.data.data.map(eachItem => {
                            return {
                                IntentNo: eachItem.IntentNo,
                                IntentDate: eachItem.IntentDate,
                                StationName: eachItem.StationName,
                                Vehicleno: eachItem.Vehicleno,
                                Amount: eachItem.Amount,
                                IntentStatus: eachItem.IntentStatus,
                            }
                        })
                    });
                } else {
                    await this.setState({

                    });
                }
            })
            .catch(async (err) => {
                console.log(err)
                alert(err);
            });


    }

    toggleCloseFuelModal = async () => {
        await this.setState({
            isFuelModal: false,
        })
    }

    getVendorName = async (e) => {
        await axios
            .get(`${Config.hostName}/vms/get/unique-vendors/${this.state.locationId}`)
            .then(res => {
                console.log(res.data);
                if (res.data.data.length > 0) {
                    this.setState({
                        unique_vendor: res.data.data,
                        // clientLabel: 'Select Client'
                    });
                } else {


                }

            })
            .catch(err => {
                console.log(err);
            });
    }


    handleExportShipment = async (e) => {
        console.log(this.state.rowData);
        e.preventDefault();
        if (this.state.rowData.length > 0) {
            var workSheet = XLSX.utils.json_to_sheet(this.state.rowData);
            var workBook = XLSX.utils.book_new();
            var wscols = [
                { wch: 6 },
                { wch: 35 },
                { wch: 15 },
                { wch: 15 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 }
            ];
            // workSheet['!cols'] = wscols;
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
            XLSX.writeFile(workBook, `${this.state.clientName}_${this.state.segment}_Regular_Trips_Report` + ".xlsx");
        } else {
            alert('No Records found to Export!')
        }

    }

    handleOpenAddModal = async () => {
        await this.getVendorName();
        await this.setState({
            isAddModal: true,
        })
    }

    handleCloseAddModal = async () => {
        await this.setState({
            isAddModal: false,
        })
    }







    render() {
        const { isData, style, modal, widgetData, uniqueClients, clientData, requiredClientData, toggleTripModal, popupTableDataKeys, popupTableData } = this.state;
        const defaultColDef = {
            flex: 1,
            minWidth: 100,
            // height: 100,
            resizable: true
        }
        return (
            <div style={{ height: "82vh" }}>
                <div id="cover-spin" style={style}> </div>

                <div className="container-fluid" style={{ marginBottom: "5vh" }}>
                    <div className="col-xl-12">

                        <nav
                            className="navbar navbar-light"
                            style={{ backgroundColor: "none" }}
                        >
                            <div className="row" style={{ paddingTop: "8px", width: "100%" }}>


                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="city"
                                            value={this.state.locationId}
                                            // style={{ borderColor: "#FF4848" }}
                                            id="city"
                                            onChange={this.handleChangeCity}
                                        >
                                            <option value={""} disabled>
                                                Select City
                                            </option>

                                            {this.state.unique_cities.map((eachItem) => (
                                                <option value={eachItem.Locationid}>{eachItem.name}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="clientName"
                                            value={this.state.clientName}
                                            id="clientName"
                                            onChange={this.handleChangeClient}
                                        >
                                            <option value={""} selected>Select Client</option>

                                            {this.state.uniqueClientsFilter.map(client => (
                                                <option value={client.ClientName}>
                                                    {client.ClientName}
                                                </option>
                                            ))}

                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="segment"
                                            value={this.state.segment}
                                            id="segment"
                                            onChange={this.handleChangeSegment}

                                        >
                                            <option value={""} selected>Select Segment</option>

                                            {this.state.uniqueSegmentsFilter.length > 0 && this.state.uniqueSegmentsFilter.map(segment => (
                                                <option value={segment.Segment}>
                                                    {segment.Segment}
                                                </option>
                                            ))}

                                            {/* <option value={0}>All Segments</option> */}
                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-2">
                                    <button
                                        type="submit"
                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                        onClick={this.handleSearchShipment}
                                    >
                                        <i
                                            className="fa fa-search"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Search
                                    </button>
                                </div>
                                <div className="col-sm-2">
                                    <button
                                        type="submit"
                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                        onClick={this.handleExportShipment}
                                    >
                                        <i
                                            className="fa fa-file-excel-o"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Export
                                    </button>
                                </div>

                                {/* <div className="col-sm-2">
                                </div> */}
                                <div className="col-sm-2">
                                    <button
                                        type="submit"
                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                        onClick={this.handleOpenAddModal}
                                    >
                                        <i
                                            className="fa fa-plus"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Add
                                    </button>
                                </div>


                            </div>

                        </nav>








                        <div className="card-body" style={{ marginBottom: '15vh' }}>

                            <div
                                className="ag-theme-balham"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    marginBottom: '15vh'
                                }}
                            >
                                {this.state.userType == 'mis' ?
                                    <AgGridReact
                                        columnDefs={this.state.columnDefsMis}
                                        rowData={this.state.rowData}
                                        defaultColDef={defaultColDef}
                                        onGridReady={this.onGridReady}
                                        enableCellTextSelection={true}
                                        gridAutoHeight={true}
                                        domLayout={this.state.domLayout}
                                        rowHeight={45}
                                        suppressAutoSize={true}
                                    >
                                    </AgGridReact>
                                    :
                                    <AgGridReact
                                        columnDefs={this.state.columnDefs}
                                        rowData={this.state.rowData}
                                        defaultColDef={defaultColDef}
                                        onGridReady={this.onGridReady}
                                        enableCellTextSelection={true}
                                        gridAutoHeight={true}
                                        domLayout={this.state.domLayout}
                                        rowHeight={45}
                                        suppressAutoSize={true}
                                    >
                                    </AgGridReact>
                                }
                            </div>

                            {/* <div style={{ margin: "9vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                {this.state.rowData.length > 0 && (
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                                        onChange={this.handlePageChange.bind(this)}
                                        prevPageText='prev'
                                        nextPageText='next'
                                        firstPageText='first'
                                        lastPageText='last'
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                )}
                            </div> */}

                        </div>


                        <div>
                            <Modal
                                size="lg"
                                style={{ maxWidth: '1600px', width: '80%' }}
                                centered={true}
                                isOpen={this.state.isDettachModal}
                            >
                                <ModalFooter>

                                    <div className="col-sm-2">
                                        <Button
                                            color="danger"
                                            onClick={this.toggleCloseDettachModal}
                                        >
                                            <CloseIcon />
                                        </Button>{" "}
                                    </div>

                                </ModalFooter>
                                <ModalBody>
                                    <div>
                                        <Alert color="danger" isOpen={this.state.visible} toggle={this.onDismiss}>
                                            Please Select Date!!
                                        </Alert>
                                    </div>
                                    <div className="bms-view-req-modal-body">
                                        <div>

                                            <div className="row bmsViewReqNewCardsForStatusMain">

                                                <div className="row bmsViewReqNewCardsForStatusFirst">

                                                    <div className="row">

                                                        <span className="bms-view-request-attachments">Dettach Vechile</span><br />
                                                    </div>

                                                    <div className="row bmsViewReqFourthRowGallery">

                                                        <form style={{ width: "100%" }}>
                                                            <div className="col-lg-12 text-right" style={{ marginTop: "0vh" }}></div>
                                                            <div className="col-lg-12">

                                                                <div className="grid">
                                                                    <div className="grid-body">
                                                                        <div className="item-wrapper">
                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 mx-auto">


                                                                                    <div className="form-row">
                                                                                        <div className="form-group col-md-7 col-md-7 row showcase_row_area">
                                                                                            <div className="col-md-3 showcase_text_area">
                                                                                                <label>Start Date * </label>
                                                                                            </div>
                                                                                            <div className="col-md-9 showcase_text_area">
                                                                                                <input
                                                                                                    placeholder="Enter Start Date"
                                                                                                    type="date"
                                                                                                    name="startDate"
                                                                                                    className="form-control"
                                                                                                    value={this.state.dettachDate}
                                                                                                    onChange={event => {
                                                                                                        this.setState({
                                                                                                            dettachDate: event.target.value
                                                                                                        });
                                                                                                    }}
                                                                                                />

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-outline-primary btn-lg"
                                                                                        style={{ float: "right" }}
                                                                                        onClick={e => this.onSubmitUpdate(e)}
                                                                                    >
                                                                                        Update
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </ModalBody>

                            </Modal>
                        </div>


                        <div>
                            <Modal
                                size="lg"
                                style={{ maxWidth: '1600px', width: '80%', fontFamily: 'system-ui' }}
                                centered={true}
                                isOpen={this.state.openTranserIndentModal}
                            >
                                <ModalFooter>




                                    <div className="col-sm-12">
                                        <div style={{ marginLeft: '3%', textAlign: 'left', fontSize: '19px', marginLeft: '3%', display: 'inline-table', float: 'left' }}>
                                            Transfer Vehicle No :  <b> <br /></b>
                                        </div>
                                        <div style={{ float: 'right' }}>

                                            <Button
                                                color="danger"
                                                onClick={this.toggleCloseTranserIndentModal}
                                            >
                                                <CloseIcon />
                                            </Button>{" "}
                                        </div>
                                    </div>

                                </ModalFooter>
                                <ModalBody>
                                    <div style={{ minHeight: '83vh' }}>

                                        <div className="row" style={{ textAlign: 'left' }}>

                                            <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                                <Alert color="danger" isOpen={this.state.alertTransfer} toggle={this.onDismiss}>
                                                    <b> {this.state.alertValidation}</b>
                                                </Alert>
                                            </div>



                                            {/* <div className="col-sm-3" style={{ paddingLeft: '53px' }}>
                                                <b> Vehicle No. <span style={{ color: 'red' }}>*</span></b>
                                            </div> */}
                                            <div className="col-sm-3" style={{ marginLeft: '50px' }} >
                                                <label>New Vehicle No.</label>
                                                <input
                                                    placeholder="Enter Vehicle No."
                                                    type="text"
                                                    name="vehcileNo"
                                                    className="form-control"
                                                    value={this.state.vehiclenoTransfer}
                                                    onChange={event => {
                                                        this.setState({
                                                            vehiclenoTransfer: event.target.value
                                                        });
                                                    }}
                                                    style={{ width: '86%' }}
                                                />
                                            </div>
                                            {/* <div className="col-sm-5">

                                            </div> */}

                                            {/* <div className="col-sm-3" style={{ paddingLeft: '53px', marginTop: '15px' }}>
                                                <b> Intent Date <span style={{ color: 'red' }}>*</span></b>
                                            </div> */}
                                            <div className="col-sm-3" >
                                                <label>Intent Date</label>
                                                <input
                                                    type="date"
                                                    name="amount"
                                                    className="form-control"
                                                    value={this.state.intentDateTransfer}
                                                    onChange={event => {
                                                        this.setState({
                                                            intentDateTransfer: event.target.value
                                                        });
                                                    }}
                                                    style={{ width: '86%' }}
                                                />
                                            </div>
                                            <div className="col-sm-3" style={{ marginTop: '28px' }}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger btn-lg"
                                                    style={{ float: "left" }}
                                                    onClick={e => this.onSubmitGetTripID(e)}
                                                >
                                                    Search Trip
                                                </button>
                                            </div>

                                            {this.state.tripIDDataIndent['ClientName'] != null &&
                                                <div class=" col-sm-12" style={{ marginLeft: '0px', marginTop: '36px', marginBottom: '22px' }}>
                                                    <table style={{ width: '100%' }}>
                                                        <tr>
                                                            <th>
                                                                Client Name
                                                            </th>
                                                            <th>
                                                                Lane
                                                            </th>
                                                            <th>
                                                                Segment
                                                            </th>
                                                            <th>
                                                                TripID
                                                            </th>
                                                            <th>
                                                                Vendor Name
                                                            </th>
                                                            <th>
                                                                Created Date
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {this.state.tripIDDataIndent['ClientName']}
                                                            </td>
                                                            <td>
                                                                {this.state.tripIDDataIndent['Lane']}
                                                            </td>
                                                            <td>
                                                                {this.state.tripIDDataIndent['Segment']}
                                                            </td>
                                                            <td>
                                                                {this.state.tripIDDataIndent['TripID']}
                                                            </td>
                                                            <td>
                                                                {this.state.tripIDDataIndent['VendorName']}
                                                            </td>
                                                            <td>
                                                                {this.state.tripIDDataIndent['createdDate']}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            }
                                            {this.state.tripIDDataIndent['ClientName'] != null &&
                                                <div className="col-sm-12" >
                                                    <h6 style={{ fontSize: "17px", marginBottom: '11px' }}>Select Vehicle</h6>
                                                </div>
                                            }
                                            {this.state.tripIDDataIndent['ClientName'] != null &&
                                                <div className="col-sm-12" style={{ height: '42vh', overflow: 'scroll' }} >
                                                    <table style={{ width: '100%' }}>
                                                        <tr>
                                                            <th>
                                                                Action
                                                            </th>
                                                            <th>
                                                                IntentNo
                                                            </th>
                                                            <th>
                                                                Intent Date
                                                            </th>
                                                            <th>
                                                                Station Name
                                                            </th>
                                                            <th>
                                                                Vehicle Number
                                                            </th>
                                                            <th>
                                                                Amount
                                                            </th>
                                                            <th>
                                                                IntentStatus
                                                            </th>
                                                        </tr>
                                                        {this.state.rowDataFuel.map((each, key) => (
                                                            <tr className={each.check == true ? 'addBackground' : ''}>
                                                                <td className={each.check == true ? 'addBackground' : ''}>
                                                                    <input
                                                                        type="checkbox"
                                                                        name="check"
                                                                        className="form-control"
                                                                        value="1"
                                                                        checked={each.check}
                                                                        onChange={event => { this.selectVehicle(key) }}
                                                                        style={{ fontSize: '0.1rem' }}
                                                                    />
                                                                </td>
                                                                <td className={each.check == true ? 'addBackground' : ''}>
                                                                    {each.IntentNo}
                                                                </td>
                                                                <td className={each.check == true ? 'addBackground' : ''}>
                                                                    {each.IntentDate}
                                                                </td>
                                                                <td className={each.check == true ? 'addBackground' : ''}>
                                                                    {each.StationName}
                                                                </td>
                                                                <td className={each.check == true ? 'addBackground' : ''}>
                                                                    {each.Vehicleno}
                                                                </td>
                                                                <td className={each.check == true ? 'addBackground' : ''}>
                                                                    {each.Amount}
                                                                </td>
                                                                <td className={each.check == true ? 'addBackground' : ''}>
                                                                    {each.IntentStatus}
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </table>
                                                </div>
                                            }


                                            {this.state.intentNoTransfer != '' &&
                                                <div className="col-sm-12" style={{ marginTop: '15px' }}>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger btn-lg"
                                                        style={{ float: "right" }}
                                                        onClick={e => this.onSubmitTransferIntent(e)}
                                                    >
                                                        Transfer
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </ModalBody>

                            </Modal>
                        </div>

                        <div>
                            <Modal
                                size="lg"
                                style={{ maxWidth: '1600px', width: '80%' }}
                                centered={true}
                                isOpen={this.state.isFuelModal}
                            >
                                <ModalFooter>

                                    <div className="col-sm-2">
                                        <Button
                                            color="danger"
                                            onClick={this.toggleCloseFuelModal}
                                        >
                                            <CloseIcon />
                                        </Button>{" "}
                                    </div>

                                </ModalFooter>
                                <ModalBody>
                                    <div className="row" style={{ marginLeft: '3%' }}>

                                        Fuel Issued By Vehicle :  <span className="bms-view-request-attachments">{this.state.VehicleNumber}</span><br />
                                    </div>
                                    <div className="bms-view-req-modal-body">


                                        <div>
                                            <div className="card-body">

                                                <div
                                                    className="ag-theme-balham"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',

                                                    }}
                                                >

                                                    <AgGridReact
                                                        columnDefs={this.state.columnDefsFuel}
                                                        rowData={this.state.rowDataFuel}
                                                        defaultColDef={defaultColDef}
                                                        onGridReady={this.onGridReady}
                                                        enableCellTextSelection={true}
                                                        gridAutoHeight={true}
                                                        domLayout={this.state.domLayout}
                                                        rowHeight={45}
                                                        suppressAutoSize={true}
                                                    >
                                                    </AgGridReact>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </ModalBody>

                            </Modal>
                        </div>

                        <div>
                            <Modal
                                size="lg"
                                style={{ maxWidth: '1600px', width: '80%' }}
                                centered={true}
                                isOpen={this.state.isAddModal}
                            >
                                <ModalFooter>

                                    <div className="col-sm-2">
                                        <Button
                                            color="danger"
                                            onClick={this.handleCloseAddModal}
                                        >
                                            <CloseIcon />
                                        </Button>{" "}
                                    </div>

                                </ModalFooter>
                                <ModalBody>
                                    <div>
                                        <Alert color={this.state.color} isOpen={this.state.visible} toggle={this.onDismiss}>
                                            {this.state.alertText}
                                        </Alert>
                                    </div>
                                    <div className="row" style={{ marginLeft: '3%' }}>

                                        Add Regular Trip<br />
                                    </div>
                                    <div className="bms-view-req-modal-body">


                                        <div>
                                            <div className="card-body">
                                                <div className="row" style={{ paddingTop: "8px", width: "100%" }}>


                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="city"
                                                                value={this.state.locationId}
                                                                // style={{ borderColor: "#FF4848" }}
                                                                id="city"
                                                                onChange={this.handleChangeCity}
                                                            >
                                                                <option value={""} disabled>
                                                                    Select City
                                                                </option>

                                                                {this.state.unique_cities.map((eachItem) => (
                                                                    <option value={eachItem.Locationid}>{eachItem.name}</option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>
                                                    {/* <div className="col-sm-1">
                                                    </div> */}

                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="clientName"
                                                                value={this.state.clientName}
                                                                id="clientName"
                                                                onChange={this.handleChangeClient}
                                                            >
                                                                <option value={""} selected>Select Client</option>

                                                                {this.state.uniqueClientsFilter.map(client => (
                                                                    <option value={client.ClientName}>
                                                                        {client.ClientName}
                                                                    </option>
                                                                ))}

                                                            </Input>
                                                        </FormGroup>
                                                    </div>
                                                    {/* <div className="col-sm-1">
                                                    </div> */}

                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="segment"
                                                                value={this.state.segment}
                                                                id="segment"
                                                                onChange={this.handleChangeSegment}
                                                            >
                                                                <option value={""} selected>Select Segment</option>

                                                                {this.state.uniqueSegmentsFilter.map(segment => (
                                                                    <option value={segment.Segment}>
                                                                        {segment.Segment}
                                                                    </option>
                                                                ))}

                                                                {/* <option value={0}>All Segments</option> */}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>







                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="routeNo"
                                                                value={this.state.routeCode}
                                                                id="routeNo"
                                                                onChange={event => {
                                                                    this.setState({ routeCode: event.target.value });
                                                                }}
                                                            >
                                                                <option value={""} selected>Route Code</option>

                                                                {this.state.uniqueRouteCodeFilter.length > 0 && this.state.uniqueRouteCodeFilter.map(Routecode => (
                                                                    <option value={Routecode.Routecode}>
                                                                        {Routecode.Lane}
                                                                    </option>
                                                                ))}

                                                                {/* <option value={0}>All Segments</option> */}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="VendorID"
                                                                value={this.state.VendorID}
                                                                id="VendorID"
                                                                onChange={this.handleChangeVendorID}
                                                            >
                                                                <option value={""} selected>Select Vendor</option>

                                                                {this.state.unique_vendor.map(vendor => (
                                                                    <option value={`${vendor.TransportName},${vendor.VendorID}`}>
                                                                        {vendor.TransportName + " ( " + vendor.VendorID + ")"}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="vehicleSize"
                                                                value={this.state.vehicleSize}
                                                                id="vehicleSize"
                                                                onChange={this.handleChangeVehicleSize}
                                                            >
                                                                <option value={""} selected>Select Vechile Size</option>

                                                                {this.state.unique_vehicleSize.map(vehicleSize => (
                                                                    <option value={vehicleSize.VehicleSize}>
                                                                        {vehicleSize.VehicleSize}
                                                                    </option>
                                                                ))}

                                                                {/* <option value={0}>All Segments</option> */}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="vehicleNumber"
                                                                value={this.state.vehicleNumber}
                                                                id="vehicleNumber"
                                                                onChange={event => {
                                                                    this.setState({ vehicleNumber: event.target.value });
                                                                }}
                                                            >
                                                                <option value={""} selected>Select Vehicle Number</option>

                                                                {this.state.unique_vehicleNumber.length > 0 && this.state.unique_vehicleNumber.map(vehicleNumber => (
                                                                    <option value={vehicleNumber.VehicleNumber}>
                                                                        {vehicleNumber.VehicleNumber}
                                                                    </option>
                                                                ))}

                                                                {/* <option value={0}>All Segments</option> */}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-sm-9">
                                                    </div>


                                                    <div className="col-sm-3" style={{ marginLeft: "6px", textAlign: 'left' }}>
                                                        <label><b></b> Service hrs. *</label>
                                                    </div>
                                                    <div className="col-sm-4" style={{ marginLeft: '4px' }}>
                                                        <input
                                                            placeholder="Enter Service Hrs"
                                                            type="text"
                                                            name="serviceHrs"

                                                            className="form-control"
                                                            value={this.state.serviceHrs}
                                                            onChange={event => {
                                                                this.setState({
                                                                    serviceHrs: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="col-sm-4">
                                                    </div>

                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-sm-3" style={{ marginLeft: "6px", textAlign: 'left' }}>
                                                        <label><b></b> Start Date *</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input
                                                            placeholder="Enter Start Date"
                                                            type="date"
                                                            name="startDate"
                                                            className="form-control"
                                                            dateFormat="DD-MM-YYYY"
                                                            value={this.state.startDate}
                                                            onChange={event => {
                                                                this.setState({
                                                                    startDate: event.target.value
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="col-sm-4">
                                                    </div>

                                                    <div className="col-sm-3 mt-3" style={{ textAlign: "left", marginLeft: "28px" }}>
                                                        <label>
                                                            <Input
                                                                type="checkbox"
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        isODO: !this.state.isODO,
                                                                    })}
                                                            />
                                                            Is ODO
                                                        </label>

                                                    </div>

                                                    <div className="col-sm-3 mt-3" style={{ textAlign: "left", marginLeft: "16px" }}>
                                                        <label>
                                                            <Input
                                                                type="checkbox"
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        isODOImage: !this.state.isODOImage,
                                                                    })}
                                                            />
                                                            Is ODO Image
                                                        </label>

                                                    </div>


                                                </div>

                                                <button
                                                    type="submit"
                                                    className="btn btn-outline-primary btn-lg"
                                                    style={{ float: "right" }}
                                                    onClick={e => this.onSubmitAddRegularTrip(e)}
                                                >
                                                    Update
                                                </button>




                                            </div>

                                        </div>
                                    </div>
                                </ModalBody>

                            </Modal>
                        </div>


                    </div>
                </div>

            </div>
        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
