
import React, { Component } from "react";
import axios from "axios";
// import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, Label, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import { AgGridReact } from 'ag-grid-react';
import "../../../../assets/customCssFiles/loader-spinner.css"
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';

import { ButtonExportExcel } from '@alckor127/react-button-export-excel'
import '@alckor127/react-button-export-excel/dist/index.css'
import XLSX from "xlsx";
class NoticeAdd extends Component {

    state = {
        link: "",
        modal: false,
        locations: [],
        description: "",
        message: "",
        style: {},
        unique_locationids: [],
        unique_vendors: [],
        anotherModal: false,
        Locationid: "",
        VendorName: "",
        Contact2: "",
        Contact3: "",
        page: 1,
        showText: false,
        columnDefs: [
            {
                headerName: "ID", field: "PMSID", sortable: true, filter: true, minWidth: 60, width: 60,
            },
            {
                headerName: "Name", field: "fullName", sortable: true, filter: true,
            },
            {
                headerName: "Mobile", field: "Mobile", sortable: true, filter: true,
            },
            {
                headerName: "City", field: "city", sortable: true, filter: true,
            },
            {
                headerName: "Payee Type", field: "PMSType", sortable: true, filter: true,
            },
            {
                headerName: "Beneficiary", field: "BeneficaryName", sortable: true, filter: true,
            },
            {
                headerName: "Account No", field: "AccountNumber", sortable: true, filter: true, minWidth: 90, width: 90,
            },
            {
                headerName: "Bank", field: "BankName", sortable: true, filter: true,
            },
            {
                headerName: "IFSC", field: "IFSC", sortable: true, filter: true,
            },
        ],
        rowData: [],
        activePage: 1,
        itemsCountPerPage: 30,
        totalItemsCount: 2000,
        pageRangeDisplayed: 5,
        domLayout: "autoHeight",

        pmsTableData: [],
        pmsCitiesDropDown: [],
        pmsPayeeTypeDropDown: [],
        uniqueLocationsDropDown: [],
        pmsLocationId: "",
        pmsTypeId: "",

        addPayeeTds: "",

        editBankModalDetails: [],

        stringVer: true,

        pmsId: "",
        pmsDeleteName: "",

        pmsExportData: [],
        selectedLocationPayeeDropDown: "",
        selectedPayeeTypeDropDown: "",


        locationMainString: "",
        payeeMainString: "",
    };

    getUniqueLocationsDropDown = async () => {
        await axios.get(`${Config.hostName}/unique/locations`)
            .then(async (res) => {
                await this.setState({ pmsCitiesDropDown: res.data.data })
            })
            .catch((error) => {
                alert("getUniqueLocationsDropDown : ", error);
            });
    }

    getUniquePayeeTypesDropDown = async () => {
        await axios
            .get(`${Config.hostName}/pms/get/payeeType/dropdown`)
            .then(async res => {
                await this.setState({
                    pmsPayeeTypeDropDown: res.data.data,
                });
            })
            .catch(err => {
                alert(err);
            });
    }

    getPmsPayeeData = async (location, payeeType) => {
        await axios
            .post(`${Config.hostName}/pms/getPayeePrimaryBankDetails`, {
                "pmsTypeId": payeeType,
                "locationId": location
            })
            .then(async res => {
                const tempData = res.data.data;
                const realData = []
                tempData.forEach((v) => {
                    const obj = {
                        "ID": v.PMSID,
                        "Name": v.fullName,
                        // "Mobile": v.contactNumber,
                        "City": v.city,
                        "Designation": v.PMSType,
                        "Beneficiary Name": v.BeneficaryName,
                        "Account No": `\t${v.AccountNumber}`,
                        "Bank": v.BankName,
                        "IFSC": v.IFSC
                    }
                    realData.push(obj)
                });
                // tempData.forEach((v) => {
                //     v.AccountNumber = `\t${v.AccountNumber}`
                //     delete v.PMSTypeID
                //     delete v.Locationid
                // }); 

                await this.setState({
                    pmsTableData: res.data.data,
                    pmsExportData: realData
                });
            })
            .catch(err => {
                alert(err);
            });
    }

    supplyDataAgGrid = async () => {
        await this.setState({
            rowData: this.state.pmsTableData.map(eachItem => {
                return {
                    PMSID: eachItem.PMSID,
                    PMSType: eachItem.PMSType,
                    Mobile: eachItem.contactNumber,
                    fullName: eachItem.fullName,
                    Locationid: eachItem.Locationid,
                    PMSTypeID: eachItem.PMSTypeID,
                    BeneficaryName: eachItem.BeneficaryName,
                    BankName: eachItem.BankName,
                    IFSC: eachItem.IFSC,
                    AccountNumber: eachItem.AccountNumber,
                    city: eachItem.city
                }
            }),
            style: { display: 'none' }
        });
    }

    handleSearchShipment = async () => {
        await this.setState({
            style: {}
        })
        const { pmsLocationId, pmsTypeId, locationMainString, payeeMainString } = this.state;
        const location = (pmsLocationId == 2222) ? locationMainString : pmsLocationId;
        const payeeType = (pmsTypeId == 2222) ? payeeMainString : pmsTypeId;
        await this.getPmsPayeeData(location, payeeType);
        await this.supplyDataAgGrid()

    }

    getLocationPayeeStrings = async () => {
        await this.getUniqueLocationsDropDown();
        const locations = this.state.pmsCitiesDropDown;
        const locationIds = [];
        locations.map((eachLocation, i) => {
            locationIds.push(eachLocation.Locationid)
        })
        const locationString = locationIds.toString();
        await this.getUniquePayeeTypesDropDown();
        const payeeTypes = this.state.pmsPayeeTypeDropDown;
        const payeeTypeIds = [];
        payeeTypes.map((eachLocation, i) => {
            payeeTypeIds.push(eachLocation.PMStypeID)
        })
        const payeeTypesString = payeeTypeIds.toString();
        await this.setState({
            locationMainString: locationString,
            payeeMainString: payeeTypesString,
        })
    }

    componentWillMount = async () => {
        await this.getLocationPayeeStrings()
        await this.getPmsPayeeData(this.state.locationMainString, this.state.payeeMainString);
        await this.supplyDataAgGrid()

    }

    changeLocationPayee = async event => {
        event.preventDefault();
        await this.setState({
            selectedLocationPayeeDropDown: event.target.value,
        });
    };

    changePayeeType = async event => {
        event.preventDefault();
        await this.setState({ selectedPayeeTypeDropDown: event.target.value });
    };

    handleExportExcel = async (e) => {
        // alert(navigator.platform)
        e.preventDefault();
        var workSheet = XLSX.utils.json_to_sheet(this.state.pmsExportData);
        var workBook = XLSX.utils.book_new();
        var wscols = [
            { wch: 6 },
            { wch: 35 },
            { wch: 15 },
            { wch: 15 },
            { wch: 35 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 }
        ];
        workSheet['!cols'] = wscols;

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
        XLSX.writeFile(workBook, 'PayeeDetails' + ".xlsx");


    }

    render() {
        let style = this.state.style;

        const defaultColDef = {
            flex: 1,
            minWidth: 90,
            resizable: true
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;


        return (
            <div>

                <div id='cover-spin' style={style}></div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />
                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="locationid"
                                                    value={this.state.pmsLocationId}
                                                    id="locationid"
                                                    onChange={event => {
                                                        this.setState({ pmsLocationId: event.target.value });
                                                    }}                                                >
                                                    <option value={""} disabled selected >Select Location</option>
                                                    {this.state.pmsCitiesDropDown.map(locationid => (
                                                        <option value={locationid.Locationid}>
                                                            {locationid.name}
                                                        </option>
                                                    ))}
                                                    <option value={2222}  >All Cities</option>

                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="VendorName"
                                                    value={this.state.pmsTypeId}
                                                    id="VendorName"
                                                    onChange={event => {
                                                        this.setState({ pmsTypeId: event.target.value });
                                                    }}
                                                >
                                                    <option value={""} disabled selected  >Select Payee Type</option>
                                                    {this.state.pmsPayeeTypeDropDown.map(vendor => (
                                                        <option value={vendor.PMStypeID}>
                                                            {vendor.PMSType}
                                                        </option>
                                                    ))}
                                                    <option value={2222}  >All</option>

                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                // type="submit"
                                                // className="btn btn-secondary"
                                                className={(!this.state.pmsTypeId || !this.state.pmsLocationId) ? 'btn btn-danger w-100 overflow-hidden mb-3 px-0 disabled' : 'btn btn-danger w-100 overflow-hidden mb-3 px-0'}
                                                // "btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.handleSearchShipment}
                                                disabled={!this.state.pmsTypeId || !this.state.pmsLocationId}
                                            >
                                                Search
                      </button>
                                        </div>

                                        {this.state.pmsExportData.length > 0 &&
                                            <div className="col-sm-2">
                                                <button
                                                    className={(!this.state.pmsExportData.length > 0 && !this.state.pmsExportData.length > 0) ? 'btn btn-danger w-100 overflow-hidden mb-3 px-0 disabled' : 'btn btn-danger w-100 overflow-hidden mb-3 px-0'}
                                                    onClick={this.handleExportExcel}
                                                    disabled={!this.state.pmsExportData && !this.state.pmsExportData.length > 0}
                                                >
                                                    Export
                      </button>
                                                {/* <ButtonExportExcel
                                                    style={{ color: "red" }}
                                                    // className={'btn btn-danger w-100 overflow-hidden mb-3 px-0'}
                                                    data={this.state.pmsExportData}
                                                    filename={`PayeeDetails`}
                                                >
                                                    Export
                                                </ButtonExportExcel> */}
                                            </div>
                                        }

                                    </div>
                                </nav>

                                <div className="card-body">
                                    <div
                                        className="ag-theme-balham"
                                        style={{
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={this.state.rowData}
                                            defaultColDef={defaultColDef}
                                            onGridReady={this.onGridReady}
                                            enableCellTextSelection={true}
                                            rowHeight={50}
                                            domLayout={this.state.domLayout}
                                            // enableCellTextSelection={true}
                                            suppressAutoSize={true}
                                            gridAutoHeight={true}
                                        >
                                        </AgGridReact>
                                    </div>
                                    {/* <div style={{ margin: "5vh auto", marginLeft: "25%", justifyContent: "center" }}> */}

                                    <div style={{ margin: "10vh auto", marginLeft: "25%", justifyContent: "center" }}>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}

            </div>
        );
    }


}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);

